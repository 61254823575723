/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import {} from "./const";
import { rtkSwitcher } from "./hooks";
import useFilterManager from "../../../common/hooks/useFilterManager";
import FilterControl from "../../../common/components/extra/filter/FilterControl";

const COMMON_PROPS = {
    style: { minWidth: "12rem", height: "2rem", maxWidth: "12rem" },
    styles: { control: { borderRadius: "25rem" } },
    menuPortalTarget: document.body,
    isClearable: true
};

function Filter({ onFilter, isLoading, isHoliday }) {
    const [filterConfig] = useState({});

    const { defaultFilter, resetFilter, selectFilter, setFilter } = rtkSwitcher({ isHoliday });

    const recordFilter = useAppSelector(selectFilter) || {};

    const [filter, { isClearDisabled, isFilterDisabled, getValueFromOpt, handleChange, handleReset, handleCustomDateChange, handleFilter }] =
        useFilterManager({ onFilter, filterConfig, defaultFilter, filter: recordFilter, isLoading, setFilter, resetFilter });

    return (
        <div className="tk-filter flex gap-05 w100" style={{ alignItems: "center" }}>
            <div className="flex gap-05 wrap" style={{ alignItems: "center" }}></div>
            <FilterControl
                isClearDisabled={isClearDisabled}
                isFilterDisabled={isFilterDisabled}
                onReset={handleReset}
                onFilter={handleFilter}
                isLoading={isLoading}
            />
        </div>
    );
}

export default Filter;

Filter.propTypes = {
    onFilter: PropTypes.func,
    isLoading: PropTypes.bool,
    isHoliday: PropTypes.bool
};
