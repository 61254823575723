import React from "react";
import PropTypes from "prop-types";
import { addCommasToMoney } from "../../../utilities/helper";
import Tag from "../Tag";

function TotalRecords({ value, style, isLoading }) {
    return (
        <div className="flex gap-05 center total-records" style={style}>
            <span className="fade small-font semi-bold" style={{ whiteSpace: "nowrap", lineHeight: ".5" }}>
                Total Record(s):
            </span>
            <Tag>
                <span className="bold small-font">{isLoading ? 0 : addCommasToMoney(value)}</span>
            </Tag>
        </div>
    );
}

export default TotalRecords;

TotalRecords.propTypes = {
    value: PropTypes.any,
    style: PropTypes.object,
    isLoading: PropTypes.bool
};
