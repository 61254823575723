import React from "react";
import CompanySubscriptions from "../../features/admin/companySubscriptions/CompanySubscriptions";
import { selectLoading } from "../../features/admin/companySubscriptions/slice";
import CardPage from "../../common/components/extra/CardPage";
import { useAppSelector } from "../../common/hooks/reduxHooks";
import Loader from "../../common/components/extra/Loader";

function CompanySubscriptionPage() {
    const loading = useAppSelector(selectLoading);
    return (
        <div className="tk-company-subscription tk-page-type-01">
            {loading && <Loader />}
            <div className="tk-company-subscription__inner">
                <CardPage title="Company Subscriptions" contentStyle={{ padding: 0, height: "calc(100vh - 12.3rem)" }}>
                    <div className="content">
                        <div className="control">
                            <div className="search"></div>
                        </div>
                        <div className="tk-company-subscription__body">
                            <CompanySubscriptions />
                        </div>
                    </div>
                </CardPage>
            </div>
        </div>
    );
}

export default CompanySubscriptionPage;
