import React, { useEffect } from "react";
import { Outlet, useLocation, Navigate } from "react-router-dom";
import Navigation from "../../classes/Navigation";
import { useAppSelector } from "../../hooks/reduxHooks";
import { selectUser } from "../../../features/common/slice";
import { resetAll } from "../../../app/store";

function UnauthLayout() {
    const location = useLocation();
    const isUnprotectedRoute = location.pathname.includes("/verify");
    const user = useAppSelector(selectUser);

    // make sure rtk global state is cleared
    useEffect(() => {
        if (!user?.id) {
            resetAll();
        }
    }, []);

    if (user && !isUnprotectedRoute) {
        return <Navigate to={location?.state?.from?.pathname || Navigation.Routes.DASHBOARD.path} state={{ from: location }} replace />;
    }

    return (
        <div className="tk-unauth-layout">
            <Outlet />
        </div>
    );
}

export default UnauthLayout;
