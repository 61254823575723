import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import PreviewIcon from "@mui/icons-material/Visibility";
import {
    createConfirmAlert,
    renderNA,
    sanitizeTimestamp,
    sanitizeWords,
    toProperMoneyFormat,
    toReadableFromDate,
    toTimeWithTimeZone
} from "../../../common/utilities/helper";
import Tag from "../../../common/components/extra/Tag";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectUser } from "../../common/slice";
import { TABLE_ACTION_TYPE_COMMON } from "../../../common/utilities/const";
import { createStatusTag } from "./helper";
import LetteredAvatar from "../../../common/components/extra/avatar/LetteredAvatar";

const HEADERS = {
    EMPLOYEE: "label",
    DATE: "start_date",
    CONFIMED_DATE: "confirmed_date",
    UPDATED_AT: "updatedAt",
    CREATED_AT: "createdAt",
    ACTIONS: "actions"
};

const HEADERS_TO_ARRAY = Object.values(HEADERS);

const CreateTableHeaders = ({ onAction }) => {
    const user = useAppSelector(selectUser);
    const setting = user.Setting;
    return HEADERS_TO_ARRAY.map((head) => {
        const temp = { key: head, style: {} };
        switch (head) {
            case HEADERS.EMPLOYEE: {
                temp.sortKey = "index1";
                temp.label = "Employee";
                temp.style.margin = ".5rem 0";
                temp.style.width = "20rem";
                temp.fixed = true;
                temp.render = (_, row) => {
                    const fullName = row.employee.first_name + " " + row.employee.last_name;
                    const photo = row.employee.photo;
                    return (
                        <>
                            <div className="flex gap-1" style={{ alignItems: "center" }}>
                                <LetteredAvatar name={fullName} src={photo} size={30} small />
                                <div className="flex column gap-05">
                                    <div style={{ fontWeight: "bold" }}>{sanitizeWords(fullName)}</div>
                                    <div className="flex gap-05 wrap">
                                        <span className="fade small-font flex" style={{ alignItems: "center" }}>
                                            Status:
                                        </span>
                                        <div className="flex gap-05">{createStatusTag(row.status)}</div>
                                    </div>
                                    <div className="flex gap-05 wrap small-font" style={{ alignItems: "center" }}>
                                        <span className="small-font fade">Amount:</span>
                                        <span className="text-ellipsis semi-bold">{toProperMoneyFormat(row.amount, setting.currency)}</span>
                                    </div>
                                    <div className="flex gap-05 wrap small-font" style={{ alignItems: "center" }}>
                                        <span className="small-font fade">Type:</span>
                                        <div className="flex gap-05 wrap">
                                            <Tag>{row.income_type}</Tag>
                                            <Tag>{sanitizeWords(row.label)}</Tag>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    );
                };
                break;
            }
            case HEADERS.DATE: {
                temp.sortKey = "index2";
                temp.label = "Starts In";
                temp.render = (_, row) => {
                    const timezone = setting.timezone;
                    const startTime = row.start_date && toTimeWithTimeZone(row.start_date, timezone);

                    const startFormat = startTime && startTime.format("MMM YYYY");

                    return (
                        <div className="flex column gap-05 overflow-hidden">
                            <span className="text-ellipsis flex gap-05">
                                <span className="flex">
                                    <span className="semi-bold">{startFormat}</span>
                                </span>
                            </span>
                        </div>
                    );
                };
                break;
            }
            case HEADERS.CONFIMED_DATE: {
                temp.sortKey = "index3";
                temp.label = "Confirmed Date";
                temp.render = (_, row) => {
                    const timezone = setting.timezone;
                    const startTime = row.confirmed_date && toTimeWithTimeZone(row.confirmed_date, timezone);

                    return (
                        <div className="flex column gap-05 overflow-hidden">
                            <span className="text-ellipsis flex gap-05">
                                {startTime ? (
                                    <span className="text-ellipsis small-font">{toReadableFromDate(startTime, setting.timezone) || renderNA()}</span>
                                ) : (
                                    <span className="small-font">{renderNA("Not Yet Confirmed")}</span>
                                )}
                            </span>
                        </div>
                    );
                };
                break;
            }
            case HEADERS.UPDATED_AT: {
                temp.sortKey = "updatedAt";
                temp.label = "Updated";
                temp.render = (_, row) => (
                    <div className="flex column gap-05 overflow-hidden fade">
                        <span className="text-ellipsis small-font">
                            {sanitizeTimestamp(row.updatedAt, row.createdAt, setting.timezone) || renderNA()}
                        </span>
                    </div>
                );
                break;
            }
            case HEADERS.CREATED_AT: {
                temp.sortKey = "createdAt";
                temp.label = "Created";
                temp.render = (val) => (
                    <div className="flex column gap-05 overflow-hidden fade">
                        <span className="text-ellipsis small-font">{toReadableFromDate(val, setting.timezone)}</span>
                    </div>
                );
                break;
            }
            case HEADERS.ACTIONS: {
                temp.childStyle = {
                    display: "flex",
                    gap: ".5rem",
                    justifyContent: "end",
                    paddingRight: "1rem",
                    alignItems: "center"
                };
                temp.render = (_, row) => (
                    <>
                        <PreviewIcon onClick={() => onAction(row, TABLE_ACTION_TYPE_COMMON.PREVIEW)} />
                        {!row.confirmed_date && (
                            <DeleteIcon
                                style={{ color: "red" }}
                                className="hover-svg"
                                onClick={async () => {
                                    createConfirmAlert({
                                        title: "Remove Other Income",
                                        content: "Are you sure you want to remove this record? This cannot be undone.",
                                        onConfirm: (close) => {
                                            close();
                                            typeof onAction === "function" && onAction(row, TABLE_ACTION_TYPE_COMMON.REMOVE);
                                        }
                                    });
                                }}
                            />
                        )}
                    </>
                );
                break;
            }
            default:
                break;
        }
        return temp;
    });
};

export default CreateTableHeaders;
