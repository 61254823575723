export const getBoundingBox = function (maps, current) {
    if (!maps) {
        return { lat: 0, lng: 0 };
    }
    const bounds = new maps.LatLngBounds();
    current.getPath().forEach(function (element) {
        bounds.extend(element);
    });
    return bounds;
};

/**@description Calculating the center that avoids the hole of a polygon and make the center always inside of a polygon */
export const getApproximateCenter = function (maps, current) {
    if (!maps) {
        return { lat: 0, lng: 0 };
    }
    let boundsHeight = 0,
        boundsWidth = 0,
        centerPoint,
        heightIncr = 0,
        maxSearchLoops,
        maxSearchSteps = 10,
        n = 1,
        northWest,
        polygonBounds = getBoundingBox(maps, current),
        testPos,
        widthIncr = 0;
    // Get polygon Centroid
    centerPoint = polygonBounds.getCenter();
    if (maps.geometry.poly.containsLocation(centerPoint, current)) {
        // Nothing to do Centroid is in polygon use it as is
        return { lat: centerPoint.lat(), lng: centerPoint.lng() };
    } else {
        maxSearchLoops = maxSearchSteps / 2;
        // Calculate NorthWest point so we can work out height of polygon NW->SE
        northWest = new maps.LatLng(polygonBounds.getNorthEast().lat(), polygonBounds.getSouthWest().lng());
        // Work out how tall and wide the bounds are and what our search increment will be
        boundsHeight = maps.geometry.spherical.computeDistanceBetween(northWest, polygonBounds.getSouthWest());
        heightIncr = boundsHeight / maxSearchSteps;
        boundsWidth = maps.geometry.spherical.computeDistanceBetween(northWest, polygonBounds.getNorthEast());
        widthIncr = boundsWidth / maxSearchSteps;

        // Expand out from Centroid and find a point within polygon at 0, 90, 180, 270 degrees
        for (; n <= maxSearchLoops; n++) {
            // Test point North of Centroid
            testPos = maps.geometry.spherical.computeOffset(centerPoint, heightIncr * n, 0);
            if (maps.geometry.poly.containsLocation(testPos, current)) {
                break;
            }
            // Test point East of Centroid
            testPos = maps.geometry.spherical.computeOffset(centerPoint, widthIncr * n, 90);
            if (maps.geometry.poly.containsLocation(testPos, current)) {
                break;
            }
            // Test point South of Centroid
            testPos = maps.geometry.spherical.computeOffset(centerPoint, heightIncr * n, 180);
            if (maps.geometry.poly.containsLocation(testPos, current)) {
                break;
            }
            // Test point West of Centroid
            testPos = maps.geometry.spherical.computeOffset(centerPoint, widthIncr * n, 270);
            if (maps.geometry.poly.containsLocation(testPos, current)) {
                break;
            }
        }
        return { lat: testPos.lat(), lng: testPos.lng() };
    }
};

/**@description Just calculate the average center not minding if the center is a whole of a polygon */
export const calculateCoordinatesCenter = (coordinates = []) => {
    if (coordinates.length === 0) {
        return null;
    }
    let sumLat = 0;
    let sumLng = 0;
    // Calculate sum of latitudes and longitudes
    for (let i = 0; i < coordinates.length; i++) {
        sumLat += coordinates[i].lat;
        sumLng += coordinates[i].lng;
    }
    // Calculate average latitudes and longitudes
    const avgLat = sumLat / coordinates.length;
    const avgLng = sumLng / coordinates.length;
    return { lat: avgLat, lng: avgLng };
};

/**@description get paths from polygons returns an mvcArray. We can use this function to extract just the lat long */
export const mvcArrayGeoValues = (mvcPath = []) => {
    const coordinates = [];
    mvcPath.forEach((path) => coordinates.push(path.toJSON()));
    return coordinates;
};

export const getBoundsLiteral = (maps, newpaths) => {
    if (!maps || !newpaths || (newpaths && !newpaths.length)) {
        return null;
    }
    const bounds = new maps.LatLngBounds();
    newpaths.forEach((coordinate) => bounds.extend(coordinate));
    return bounds;
};
