import { useEffect, useState } from "react";
import { selectData, selectTableConfig, setState } from "./slice";
import { useLoadCompanySubscriptionsByAdminMutation } from "./api";
import { TOAST_TYPE, createToast } from "../../../common/utilities/helper";
import { useAppDispatch, useAppSelector } from "../../../common/hooks/reduxHooks";

export const usePaginateCompanySubscriptions = () => {
    const [fetching, setFetching] = useState(true);

    const dispatch = useAppDispatch();
    const data = useAppSelector(selectData);
    const tableConfig = useAppSelector(selectTableConfig);

    const [load] = useLoadCompanySubscriptionsByAdminMutation();

    const fetch = async (config) => {
        if (!fetching) {
            setFetching(true);
        }
        const response = await load({ params: { ...tableConfig, ...(config || {}) } });
        if (response.data && response.data.data) {
            if (typeof setState === "function") {
                const result = response.data.data;
                const oldConfig = { ...tableConfig, ...(config || {}) };
                dispatch(
                    setState({
                        data: result.data,
                        tableConfig: { ...oldConfig, totalPage: result.totalPage }
                    })
                );
            }
        }
        if (response.error) {
            createToast("Failed to fetch data. Please try again later.", TOAST_TYPE.ERROR);
        }
        setFetching(false);
        return response;
    };

    useEffect(() => {
        if (!data.length) {
            fetch();
        } else {
            setFetching(false);
        }
    }, []);

    return [data, fetching, fetch];
};
