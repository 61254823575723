import React from "react";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import PaymentIcon from "@mui/icons-material/Payment";
import Button from "./Button";
import { useAppSelector } from "../../hooks/reduxHooks";
import { selectUser } from "../../../features/common/slice";
import useNavigate from "../../hooks/useNavigate";
import Navigation from "../../classes/Navigation";

function ButtonUpgrade() {
    const user = useAppSelector(selectUser);
    const isPendingPayment = user?.is_pending_payment;
    const navigate = useNavigate();

    const handleUpgrade = () => {
        if (isPendingPayment) {
            navigate(Navigation.Routes.PENDING_PAYMENT.path);
        } else {
            navigate(Navigation.Routes.SUBSCRIPTION_UPGRADE.path);
        }
    };

    const buttonProps = {
        onClick: handleUpgrade,
        options: { style: { height: "2.2rem" } },
        small: true
    };

    if (isPendingPayment) {
        return (
            <Button className="secondary with-shadow" {...buttonProps}>
                <PaymentIcon />
                <span>Update Payment</span>
            </Button>
        );
    }

    return (
        <Button className="primary with-shadow" {...buttonProps}>
            <AutoAwesomeIcon style={{ color: "yellow" }} />
            <span>Upgrade</span>
        </Button>
    );
}

export default ButtonUpgrade;
