import React from "react";

function MobileNumberInput() {
    return (
        <div className="mobile-input mobile-input--text-input">
            <div className="input-box flex center">
                <span className="fade italic">Number</span>
            </div>
        </div>
    );
}

export default MobileNumberInput;
