import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apiSlice } from "../../../app/apiSlice";

const BASE_PATH = "/api/admin";

const END_POINTS = {
    login: { method: "post", path: "auth/login" },
    adminChangePassword: { method: "put", path: "change-password" },
    resetPassword: { method: "post", path: "reset-password/:id/:token" },
    resetPasswordRequest: { method: "post", path: "reset-password-request" },
    updateAdminProfile: { method: "put", path: "update-admin" },
    adminDetails: { method: "get", path: "admin-details" }
};

export const superAdminAPI = apiSlice.injectEndpoints({
    reducerPath: "superAdminAPI",
    baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_SERVER_URI }),
    endpoints: (builder) => {
        const temp = {};
        Object.entries(END_POINTS).forEach(([key, config]) => {
            temp[key] = builder.mutation({
                query: (queryParams = {}) => {
                    const path = `${BASE_PATH}/${config.path}`;
                    const conf = {
                        url: path,
                        method: config.method,
                        credentials: "include"
                    };
                    if (queryParams.extraPath) conf.url = `${path}/${queryParams.extraPath}`;
                    if (queryParams.params) conf.params = queryParams.params;
                    if (queryParams.body) conf.body = queryParams.body;
                    return conf;
                }
            });
        });
        return temp;
    }
});

export const {
    useLoginMutation,
    useResetPasswordMutation,
    useResetPasswordRequestMutation,
    useUpdateAdminProfileMutation,
    useAdminChangePasswordMutation,
    useAdminDetailsMutation
} = superAdminAPI;

export default superAdminAPI.reducer;
