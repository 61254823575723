import React from "react";
import PreviewIcon from "@mui/icons-material/Visibility";
import { renderNA, sanitizeWords, toReadableFromDate } from "../../../common/utilities/helper";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import LetteredAvatar from "../../../common/components/extra/avatar/LetteredAvatar";
import { selectUser } from "../../common/slice";
import { SUBMITTED_FORM_STATUS, TABLE_ACTION_TYPE_COMMON } from "../../../common/utilities/const";
import Tag, { TAG_TYPE } from "../../../common/components/extra/Tag";

const HEADERS = {
    NAME: "name",
    FOLLOW_UP_AT: "status",
    UPDATED_AT: "updatedAt",
    CREATED_AT: "createdAt",
    ACTIONS: "actions"
};

const HEADERS_TO_ARRAY = Object.values(HEADERS);

const CreateTableHeaders = ({ onAction }) => {
    const user = useAppSelector(selectUser);
    const setting = user.Setting;
    return HEADERS_TO_ARRAY.map((head) => {
        const temp = { key: head, style: {} };
        switch (head) {
            case HEADERS.NAME: {
                temp.childStyle = {
                    alignItems: "center",
                    gap: "1rem",
                    margin: ".5rem 0"
                };
                temp.label = "Employee";
                temp.style.width = "20rem";
                temp.fixed = true;
                temp.render = (_, row) => {
                    const fullName = row.Employee.first_name + " " + row.Employee.last_name;
                    const photo = row.Employee.photo;
                    const isPending = row.status == SUBMITTED_FORM_STATUS.PENDING;
                    const isApproved = row.status == SUBMITTED_FORM_STATUS.APPROVED;
                    const isRejected = row.status == SUBMITTED_FORM_STATUS.REJECTED;
                    return (
                        <>
                            <div className="flex gap-1" style={{ alignItems: "center" }}>
                                <LetteredAvatar name={fullName} src={photo} size={30} small />
                                <div className="flex column gap-05 overflow-hidden">
                                    <div style={{ fontWeight: "bold" }}>{sanitizeWords(fullName)}</div>
                                    <div className="flex gap-05 wrap">
                                        <span className="fade small-font flex" style={{ alignItems: "center" }}>
                                            Status:
                                        </span>
                                        <Tag
                                            type={isPending ? TAG_TYPE.PENDING : isApproved ? TAG_TYPE.VERIFIED : isRejected ? TAG_TYPE.REJECTED : ""}
                                        >
                                            <span className="text-ellipsis">{sanitizeWords(row.status)}</span>
                                        </Tag>
                                    </div>
                                    <div className="flex gap-05 wrap center">
                                        <span className="fade small-font flex center">Form:</span>
                                        <span className="text-ellipsis small-font semi-bold">{sanitizeWords(row.formName)}</span>
                                    </div>
                                </div>
                            </div>
                        </>
                    );
                };
                break;
            }
            case HEADERS.FOLLOW_UP_AT: {
                temp.sortKey = "last_follow_up_at";
                temp.label = <span style={{ whiteSpace: "nowrap" }}>Last Follow up</span>;
                temp.render = (_, row) => (
                    <div className="flex column gap-05 overflow-hidden fade">
                        <span className="text-ellipsis small-font">{toReadableFromDate(row.last_follow_up_at, setting.timezone) || renderNA()}</span>
                    </div>
                );
                break;
            }
            case HEADERS.UPDATED_AT: {
                temp.sortKey = "updatedAt";
                temp.label = "Updated";
                temp.render = (_, row) => (
                    <div className="flex column gap-05 overflow-hidden fade">
                        <span className="text-ellipsis small-font">
                            {toReadableFromDate(
                                toReadableFromDate(row.updatedAt, setting.timezone) == toReadableFromDate(row.createdAt, setting.timezone)
                                    ? ""
                                    : row.updatedAt,
                                setting.timezone
                            ) || renderNA()}
                        </span>
                    </div>
                );
                break;
            }
            case HEADERS.CREATED_AT: {
                temp.sortKey = "createdAt";
                temp.label = "Created";
                temp.render = (_, row) => (
                    <div className="flex column gap-05 overflow-hidden fade">
                        <span className="text-ellipsis small-font">{toReadableFromDate(row.createdAt, setting.timezone)}</span>
                    </div>
                );
                break;
            }
            case HEADERS.ACTIONS: {
                temp.childStyle = {
                    display: "flex",
                    gap: ".5rem",
                    justifyContent: "flex-end",
                    paddingRight: "1rem",
                    overflow: "unset"
                };
                temp.render = (_, row) => {
                    return (
                        <>
                            <PreviewIcon onClick={() => onAction(row, TABLE_ACTION_TYPE_COMMON.PREVIEW)} />
                        </>
                    );
                };
                break;
            }
            default:
                break;
        }
        return temp;
    });
};

export default CreateTableHeaders;
