import { useState, useEffect } from "react";
import { useBeforeUnload } from "react-router-dom";

const DEFAULT_TIMEOUT = 60 * 1000;

function useTimer(duration = DEFAULT_TIMEOUT, onTimerEnd) {
    let interval = null;

    const [timer, setTimer] = useState(0);
    const [timerEnded, setTimerEnded] = useState(false);

    const clearTimer = () => {
        clearInterval(interval);
    };

    const startTimer = (newTimer) => {
        setTimer(newTimer || duration);
        interval = setInterval(
            () =>
                setTimer((prevTimer) => {
                    const newTimer = prevTimer - 1000;

                    if (newTimer <= 0) {
                        setTimerEnded(true);
                        clearTimer();
                        return 0;
                    }
                    return newTimer;
                }),
            1000
        );
    };

    useEffect(() => {
        if (timerEnded) {
            typeof onTimerEnd == "function" && onTimerEnd();
        }
    }, [timerEnded]);

    useEffect(() => {
        startTimer();
        return () => clearTimer();
    }, []);

    useBeforeUnload(() => {
        clearTimer();
    });

    return [timer, startTimer, clearTimer];
}

export default useTimer;
