import React from "react";
import moment from "moment-timezone";
import { useAppDispatch, useAppSelector } from "../../../common/hooks/reduxHooks";
import useTimer from "../../../common/hooks/useTimer";
import { selectUser, updateUser } from "../../common/slice";
import { formatTimeToTwoDigits } from "../../../common/utilities/helper";

const extractTime = (dura) => {
    const duration = moment.duration(dura);
    // Extract months, days, hours, and minutes
    const months = Math.floor(duration.asMonths());
    duration.subtract(moment.duration(months, "months"));
    const days = Math.floor(duration.asDays());
    duration.subtract(moment.duration(days, "days"));
    const hours = Math.floor(duration.asHours());
    duration.subtract(moment.duration(hours, "hours"));
    const minutes = Math.floor(duration.asMinutes());
    duration.subtract(moment.duration(minutes, "minutes"));
    const seconds = Math.floor(duration.asSeconds());
    return {
        months,
        days,
        hours,
        minutes,
        seconds
    };
};

export const useGetSubscriptionTimeRemaining = () => {
    const dispatch = useAppDispatch();
    const user = useAppSelector(selectUser) || {};

    const defaultRemainingWarningCount = Number(process.env.REACT_APP_SUBSCRIPTION_TIME_REMAINING_WARNING);
    const remainingSubscriptionTime = user.remainingSubscriptionTime || {};
    const remainingDay = remainingSubscriptionTime.day;
    const remainingMonths = remainingSubscriptionTime.months;
    const showWarning = remainingSubscriptionTime.day <= defaultRemainingWarningCount;

    const [time] = useTimer(!showWarning ? 0 : remainingSubscriptionTime.duration, () => !remainingDay && dispatch(updateUser({ is_expired: true })));

    const duration = moment.duration(time);
    const readableForm = `${duration.hours()}:${duration.minutes()}:${duration.seconds()}`;

    return {
        showWarning,
        text: (
            <span style={{ color: "red" }}>
                {`${duration.days()} Day(s)`} & {formatTimeToTwoDigits(readableForm)}
            </span>
        ),
        isLessThanOneMonth: remainingMonths < 1,
        date: extractTime(remainingSubscriptionTime.duration),
        ...remainingSubscriptionTime
    };
};
