import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import isEqual from "lodash/isEqual";
import Checkbox from "./Checkbox";
import { FALSE_VALUES } from "../../utilities/const";
import { toObjectWithValues } from "../../utilities/helper";

function AdvanceList({
    head = { extra: "", label: "", render: "" },
    data,
    onChange,
    hasCheckbox,
    isArray,
    values,
    styles = { parent: {}, head: {}, content: {} },
    disabledKeys
}) {
    const [checks, setChecks] = useState([]);

    useEffect(() => {
        if (!isEqual(checks, values)) {
            setChecks(values);
        }
    }, [values]);

    const handleCheckChange = (checked, key) => {
        let temp = [];
        if (checked) {
            temp = [...new Set([...checks, key])];
        } else {
            temp = checks.filter((c) => c !== key);
        }
        setChecks(temp);
        typeof onChange === "function" && onChange(toObjectWithValues(temp, data), key, checked);
    };

    const handleCheckAll = (checked) => {
        let temp = [];
        if (checked) {
            temp = Object.keys(data);
        }
        setChecks(temp);
        typeof onChange === "function" && onChange(toObjectWithValues(temp, data), null, checked);
    };

    const renderArray = isArray ? data : Object.entries(data);

    return (
        <div className="tk-adv-list" style={styles.parent}>
            <div className="tk-adv-list__head" style={styles.head}>
                {head.extra && <div className="tk-adv-list__head__extra">{head.extra}</div>}
                <div className={`tk-adv-list__item head ${hasCheckbox ? "has-checkbox" : ""}`.trim()}>
                    {!isArray && hasCheckbox && (
                        <Checkbox
                            onChange={handleCheckAll}
                            checked={Object.entries(data)
                                .filter(([, val]) => !FALSE_VALUES.includes(val))
                                .map(([key]) => key)
                                .every((key) => checks.includes(key))}
                        />
                    )}
                    {typeof head.label === "function" ? head.label() : head.label}
                </div>
            </div>
            <ul className="tk-adv-list__content" style={styles.content}>
                {renderArray.map((d, idx) => {
                    let key, value;
                    if (isArray) {
                        key = idx;
                        value = d;
                    } else {
                        key = d[0];
                        value = d[1];
                    }
                    return (
                        <li key={key} className={`tk-adv-list__item ${hasCheckbox ? "has-checkbox" : ""}`.trim()}>
                            {!isArray && hasCheckbox && !FALSE_VALUES.includes(value) && (
                                <Checkbox
                                    onChange={(bool) => handleCheckChange(bool, key)}
                                    checked={!!checks.includes(key) && !disabledKeys.includes(key)}
                                    disabled={disabledKeys.includes(key)}
                                />
                            )}
                            {typeof head.render === "function" ? head.render(value, key) : head.render}
                        </li>
                    );
                })}
            </ul>
        </div>
    );
}

AdvanceList.propTypes = {
    styles: PropTypes.shape({
        parent: PropTypes.object,
        head: PropTypes.object,
        content: PropTypes.object
    }),
    head: PropTypes.shape({
        extra: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node, PropTypes.func]),
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node, PropTypes.func]),
        render: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node, PropTypes.func])
    }),
    data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    onChange: PropTypes.func,
    hasCheckbox: PropTypes.bool,
    isArray: PropTypes.bool,
    values: PropTypes.array,
    disabledKeys: PropTypes.array
};

export default AdvanceList;
