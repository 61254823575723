import { useState, useEffect } from "react";
import { useAppDispatch } from "./reduxHooks";
import { setViewport } from "../../features/common/slice";

function useViewport() {
    const [mounted, setMounted] = useState(false);
    const [viewport, setVP] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    const dispatch = useAppDispatch();

    const getFlags = (obj = {}) => {
        const isMobile = obj.width <= 768;
        const isTablet = obj.width > 768 && viewport.width <= 1024;
        const isPC = obj.width > 1024;
        return {
            ...obj,
            isMobile,
            isTablet,
            isPC
        };
    };

    useEffect(() => {
        setMounted(true);
    }, []);

    useEffect(() => {
        const handleResize = () => {
            const newvp = { width: window.innerWidth, height: window.innerHeight };
            setVP(newvp);
            dispatch(setViewport(getFlags(newvp)));
        };
        if (mounted) {
            handleResize();
            window.addEventListener("resize", handleResize);
        }
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [mounted]);

    return getFlags(viewport);
}

export default useViewport;
