import React from "react";
import VerifyCompanyEmail from "../../features/company/auth/VerifyCompanyEmail";

function VerifyCompanyEmailPage() {
    return (
        <div className="tk-verify-company-email">
            <VerifyCompanyEmail />
        </div>
    );
}

export default VerifyCompanyEmailPage;
