import React from "react";
import { PENALTY_STATUS } from "./const";
import Tag from "../../../common/components/extra/Tag";
import { sanitizeWords } from "../../../common/utilities/helper";

export const createStatusTag = (status) => {
    const isCanceled = status == PENALTY_STATUS.CANCELED;
    const isDeducted = status == PENALTY_STATUS.DEDUCTED;
    return <Tag className={`${isCanceled ? "red" : isDeducted ? "green" : "brown"}`}>{sanitizeWords(status)}</Tag>;
};
