import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import { ReactComponent as LogoSVG } from "../../../assets/images/logo.svg";
import Navigation from "../../classes/Navigation";

const BASE_CLASS = "tk-company-unauthlayout";
const createClass = (newStr) => `${BASE_CLASS}${newStr}`;

function CompanyUnauthlayout() {
    const location = useLocation();
    const isSignUp = location.pathname === Navigation.Routes.SIGNUP.path;

    return (
        <div className={BASE_CLASS}>
            <div className={createClass("__inner")}>
                <div className={createClass("__left")}>
                    <div className={createClass("__logo-info")}>
                        <span>{process.env.REACT_APP_NAME}</span>
                        <h3>Company Portal</h3>
                    </div>
                    <div className={createClass("__logo")}>
                        <LogoSVG />
                    </div>
                </div>
                <div className={createClass(`__right ${(isSignUp && "signup") || ""}`.trim())}>
                    <Outlet />
                </div>
            </div>
        </div>
    );
}

export default CompanyUnauthlayout;
