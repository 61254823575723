import React from "react";
import moment from "moment-timezone";
import { createTextFromDate, hoursDiff, roundToNearestTenth, sanitizeWords } from "../../../common/utilities/helper";
import { SHIFT_TYPE } from "../../../common/utilities/const";
import { TIME_TYPE, TABS, FIELDS } from "./const";
import WorkShift from "./WorkShift";

const TAB_COMPONENT_STYLES = {
    pagination: {},
    table: {}
};

export const createWorkShiftTabs = (tabs, props, activeTab) => {
    return Object.values(tabs)
        .filter((tab) => tab.isActive)
        .map((tab) => {
            switch (tab.id) {
                case TABS.REGULAR_WORKSHIFT.id: {
                    tab.component = activeTab == tab.id && <WorkShift {...props} styles={TAB_COMPONENT_STYLES} height={29} />;
                    break;
                }
                case TABS.HOLIDAY_WORKSHIFT.id: {
                    tab.component = activeTab == tab.id && <WorkShift {...props} styles={TAB_COMPONENT_STYLES} height={29} isHoliday />;
                    break;
                }
                default: {
                    break;
                }
            }
            return tab;
        });
};

export const isReqHoursSatisfied = (reqTime = null, form) => {
    if (!reqTime) return null;
    const maxBreakHours = form[FIELDS.MAX_BREAK_DURATION.name];

    const startTimeOne = form[FIELDS.START_TIME.name];
    const endTimeOne = form[FIELDS.END_TIME.name];
    const breakOne = form[FIELDS.BREAK_TIME.name];
    const breakOneEnd = form[FIELDS.BREAK_END_TIME.name];

    const startTimeTwo = form[FIELDS.START_TIME_2.name];
    const endTimeTwo = form[FIELDS.END_TIME_2.name];
    const breakTwo = form[FIELDS.BREAK_TIME_2.name];
    const breakTwoEnd = form[FIELDS.BREAK_END_TIME_2.name];

    const shift1Diff = roundToNearestTenth(hoursDiff(startTimeOne, endTimeOne)) || 0;
    const shift2Diff = roundToNearestTenth(hoursDiff(startTimeTwo, endTimeTwo)) || 0;
    const breakDiff = roundToNearestTenth(hoursDiff(breakOne, breakOneEnd)) || 0;
    const break2Diff = roundToNearestTenth(hoursDiff(breakTwo, breakTwoEnd)) || 0;
    const totalBreakDiff = maxBreakHours ? Number(maxBreakHours) : breakDiff + break2Diff;
    const total = shift1Diff + shift2Diff;

    return roundToNearestTenth(total) - roundToNearestTenth(totalBreakDiff) >= reqTime;
};

export const isBreakSatisfied = (form) => {
    const maxBreakHours = form[FIELDS.MAX_BREAK_DURATION.name];

    if (!maxBreakHours) {
        return { isSatisfied: true };
    }

    const breakOne = form[FIELDS.BREAK_TIME.name];
    const breakOneEnd = form[FIELDS.BREAK_END_TIME.name];

    const breakTwo = form[FIELDS.BREAK_TIME_2.name];
    const breakTwoEnd = form[FIELDS.BREAK_END_TIME_2.name];

    if (!breakOne && !breakTwo) {
        return { isSatisfied: true };
    }

    const breakDiff = roundToNearestTenth(hoursDiff(breakOne, breakOneEnd)) || 0;
    const break2Diff = roundToNearestTenth(hoursDiff(breakTwo, breakTwoEnd)) || 0;
    const totalBreak = roundToNearestTenth(breakDiff + break2Diff);

    const isSatisfied = totalBreak == maxBreakHours;
    const isNotMet = totalBreak < maxBreakHours;
    const isExceeded = totalBreak > maxBreakHours;

    if (isSatisfied) {
        return { isSatisfied: true };
    }

    return { isSatisfied, isNotMet, isExceeded };
};

export const getTimeRangeValues = (form = {}, isSplit, timezone) => {
    const shiftOne = {
        range: {
            start: (form.start_time && moment.tz(form.start_time, timezone)) || null,
            end: (form.end_time && moment.tz(form.end_time, timezone)) || null
        },
        constraint: {}
    };
    const shiftTwo = {
        range: {
            start: (form.start_time_2 && moment.tz(form.start_time_2, timezone)) || null,
            end: (form.end_time_2 && moment.tz(form.end_time_2, timezone)) || null
        },
        constraint: {
            start: {
                min: shiftOne.range.end || null
            }
        }
    };
    const breakTime = {
        range: {
            start: (form.break_time && moment.tz(form.break_time, timezone)) || null,
            end: (form.break_end_time && moment.tz(form.break_end_time, timezone)) || null
        },
        constraint: {
            start: {
                min: shiftOne.range.start || null,
                max: shiftOne.range.end || null
            },
            end: {
                min: (form.break_time && moment.tz(form.break_time, timezone)) || null,
                max: shiftOne.range.end || null
            }
        }
    };
    const breakTimeTwo = {
        range: {
            start: (form.break_time_2 && moment.tz(form.break_time_2, timezone)) || null,
            end: (form.break_end_time_2 && moment.tz(form.break_end_time_2, timezone)) || null
        },
        constraint: {
            start: {
                min: shiftTwo.range.start || null,
                max: shiftTwo.range.end || null
            },
            end: {
                min: (form.break_time_2 && moment.tz(form.break_time_2, timezone)) || null,
                max: shiftTwo.range.end || null
            }
        }
    };

    return {
        [TIME_TYPE.SHIFT_ONE]: shiftOne,
        [TIME_TYPE.SHIFT_TWO]: shiftTwo,
        [TIME_TYPE.BREAK]: breakTime,
        [TIME_TYPE.BREAK_TWO]: breakTimeTwo
    };
};

export const isNextDay = (shift1, shift2, isSplit) => {
    if (!shift1.startTimeOne || !shift1.endTimeOne) {
        return false;
    }

    const startTimeOne = moment(shift1.startTimeOne);
    const endTimeOne = moment(shift1.endTimeOne);
    const startTimeOneIso = startTimeOne.format("YYYY-MM-DD");
    const endTimeOneIso = endTimeOne.format("YYYY-MM-DD");

    if (isSplit) {
        if (!shift2.startTimeTwo || !shift2.endTimeTwo) {
            return false;
        }
        const startTimeTwo = moment(shift2.startTimeTwo);
        const endTimeTwo = moment(shift2.endTimeTwo);
        const startTimeTwoIso = startTimeTwo.format("YYYY-MM-DD");
        const endTimeTwoIso = endTimeTwo.format("YYYY-MM-DD");
        if (startTimeTwoIso != endTimeOneIso || endTimeTwoIso !== endTimeOneIso) {
            return true;
        }
        if (startTimeTwo.isBefore(endTimeOne) || startTimeTwo.isBefore(startTimeOne)) {
            return true;
        }
    } else {
        if (startTimeOneIso != endTimeOneIso) {
            return true;
        }
    }
    return false;
};

export const createWorkShiftInfo = (workShift, timezone) => {
    return {
        title: sanitizeWords(workShift.title),
        date: workShift.start_time && createTextFromDate(workShift.start_time, workShift.end_time_2, timezone).date,
        shiftOne: workShift.start_time && createTextFromDate(workShift.start_time, workShift.end_time, timezone).time,
        breakOne: workShift.break_time && createTextFromDate(workShift.break_time, workShift.break_end_time, timezone).time,
        shiftTwo: workShift.start_time_2 && createTextFromDate(workShift.start_time_2, workShift.end_time_2, timezone).time,
        breakTwo: workShift.break_time_2 && createTextFromDate(workShift.break_time_2, workShift.break_end_time_2, timezone).time,
        shiftType: sanitizeWords(workShift.shift_type),
        isSplit: workShift.shift_type == SHIFT_TYPE.SPLIT,
        maxOT: workShift.max_overtime,
        maxBreak: workShift.max_break_duration,
        requiredShiftTime: workShift.required_shift_time
    };
};
