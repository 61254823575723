import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import cloneDeep from "lodash/cloneDeep";
import ResetDeviceIcon from "@mui/icons-material/PhonelinkErase";
import FormSection from "../../../common/components/extra/FormSection";
import { selectCurrent, setCurrent } from "./slice";
import { useEmployeeDetailsMutation } from "./api";
import useFetchCountries from "../../../common/hooks/useFetchCountries";
import { useAppDispatch, useAppSelector } from "../../../common/hooks/reduxHooks";
import Input, { INPUT_TYPE } from "../../../common/components/extra/form/Input";
import {
    TOAST_TYPE,
    createConfirmAlert,
    createTimeFromDate,
    createToast,
    renderNA,
    sanitizeTimestamp,
    sanitizeWords,
    separateLastCharacters,
    toReadableFromDate,
    toReadablePhone,
    toStandardDate
} from "../../../common/utilities/helper";
import Button from "../../../common/components/extra/Button";
import ViewModalSub from "./ViewModalSub";
import { VIEW_MODAL_TYPE } from "./const";
import ViewGeozonesModal from "../companySites/ViewGeozonesModal";
import ViewWorkDetailsModal from "../employeeWorkDetails/ViewWorkDetailsModal";
import EmployeeAvatar from "../../../common/components/extra/avatar/EmployeeAvatar";
import BaseViewModal from "../../../common/components/layout/modalViewUpdateLayout/BaseViewModal";
import Tag, { TAG_TYPE } from "../../../common/components/extra/Tag";
import { ROLE_LEVEL, ROLE_TYPE } from "../../../common/utilities/const";

const { CONTRACT_COPY, PROJECT, JOB_DESCRIPTION, SALARY, GEOZONE } = VIEW_MODAL_TYPE;

function ViewModal({ open, onChange, onClose, onEdit, data, setting, cachedCurrent = true, onResetDevice, readOnly }) {
    const [isDeviceReseting, setDeviceReseting] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const [viewObject, setViewObject] = useState({ type: null, data: null });
    const [getDetails] = useEmployeeDetailsMutation();
    const countries = useFetchCountries();
    const current = useAppSelector(selectCurrent);
    const dispatch = useAppDispatch();

    const handleViewChange = (newObject = {}) => setViewObject({ ...viewObject, ...newObject });

    const transformViewData = (row) => {
        const details = cloneDeep(row || {});
        details.gender = sanitizeWords(details.gender);
        details.designation = details.CompanyDesignation?.title ? sanitizeWords(details.CompanyDesignation.title) : renderNA();
        details.department = details.CompanyDepartment?.title ? sanitizeWords(details.CompanyDepartment.title) : renderNA();
        details.visaID_expiration = details.visaID_expiration ? toStandardDate(details.visaID_expiration, setting.timezone) : "";
        details.residenceID_expiration = details.residenceID_expiration ? toStandardDate(details.residenceID_expiration, setting.timezone) : "";
        details.birthdate = details.birthdate ? toStandardDate(details.birthdate, setting.timezone) : "";
        details.marital_status && (details.marital_status = sanitizeWords(details.marital_status));
        details.EmployeeContract.joining_date = details.EmployeeContract?.joining_date
            ? toStandardDate(details.EmployeeContract.joining_date, setting.timezone)
            : "";
        details.EmployeeContract.joining_date = details.EmployeeContract?.joining_date
            ? toStandardDate(details.EmployeeContract.joining_date, setting.timezone)
            : "";
        details.EmployeeContract.hiring_date = details.EmployeeContract?.hiring_date
            ? toStandardDate(details.EmployeeContract.hiring_date, setting.timezone)
            : "";
        details.EmployeeContract.end_date = details.EmployeeContract?.end_date
            ? toStandardDate(details.EmployeeContract.end_date, setting.timezone)
            : "";
        details.EmployeeContract && (details.EmployeeContract.salary_frequency = sanitizeWords(details.EmployeeContract.salary_frequency));
        details.EmployeeContract && (details.EmployeeContract.contract_type = sanitizeWords(details.EmployeeContract.contract_type));
        details.EmployeeContract && (details.EmployeeContract.contract_term = sanitizeWords(details.EmployeeContract.contract_term));
        details.schedule_type && (details.schedule_type = sanitizeWords(details.schedule_type));
        Array.isArray(details.off_days) && (details.off_days = details.off_days.map((off) => sanitizeWords(off)));
        return details;
    };

    const fetch = async () => {
        if (!data) {
            setLoading(false);
            return;
        }
        if (cachedCurrent && current && current.id === data.id) {
            setLoading(false);
            return Promise.resolve();
        }
        const res = await getDetails({ extraPath: data.id });
        if (res.error) {
            createToast("Failed to fetch employees. Please try again later or contact support", TOAST_TYPE.ERROR);
        }
        if (res.data) {
            dispatch(setCurrent(res.data.data));
        }
        setLoading(false);
        return res;
    };

    useEffect(() => {
        fetch();
    }, []);

    const row = (current && Object.values(current).length && transformViewData(current)) || {};
    const joiningDate = row.EmployeeContract?.joining_date;
    const IDExpirationDate = row.residenceID_expiration;
    const visaIDExpirationDate = row.visaID_expiration;
    const hiringDate = row.EmployeeContract?.hiring_date;
    const contractExiryDate = row.EmployeeContract?.end_date;
    const nationality = row.nationality ? countries.find((ctr) => ctr.cca2 == row.nationality) : "";
    const dailyWorkHours = row.EmployeeWorkShift?.required_shift_time ? `${row.EmployeeWorkShift?.required_shift_time} hours` : renderNA();
    const maxOTHours = row.EmployeeWorkShift?.max_overtime ? `${row.EmployeeWorkShift?.max_overtime} hours` : renderNA();
    const shiftTime = createTimeFromDate(row.EmployeeWorkShift?.start_time, row.EmployeeWorkShift?.end_time, setting.timezone);
    const breakTime = createTimeFromDate(row.EmployeeWorkShift?.break_time, row.EmployeeWorkShift?.break_end_time, setting.timezone);
    const showModalSub = viewObject.type && ![GEOZONE, PROJECT].includes(viewObject.type);
    const showGeo = viewObject.type === GEOZONE;
    const showWorkTypes = viewObject.type === PROJECT;
    const fullName = `${row.first_name || ""} ${row.last_name || ""}`.trim();
    const isSuperVisor = row.Role && row.Role?.type == ROLE_TYPE.EMPLOYEE && row.Role?.level == ROLE_LEVEL.HIGH;

    const renderViewButton = (type, data) => (
        <div>
            <Button
                options={{ style: { textAlign: "right", paddingRight: 0, textDecoration: "underline" } }}
                onClick={() => handleViewChange({ type, data })}
                transparent
                small
            >
                View
            </Button>
        </div>
    );

    const COMMON_INPUT_PROPS = { type: INPUT_TYPE.TEXT, isLoading, readOnly: true };

    return (
        <BaseViewModal
            headExtra={
                !readOnly &&
                row.device && (
                    <Button
                        onClick={() => {
                            createConfirmAlert({
                                title: "Reset Device",
                                content: (
                                    <div className="flex column gap-05">
                                        <span>Are you sure you want to reset this device:</span>
                                        <div className="flex gap-05">
                                            <span className="fade">Name:</span>
                                            <span>{row.device?.device_name}</span>
                                        </div>
                                        <div className="flex gap-05">
                                            <span className="fade">Model:</span>
                                            <span>{row.device?.model}</span>
                                        </div>
                                    </div>
                                ),
                                onConfirm: async (close) => {
                                    close();
                                    if (typeof onResetDevice === "function") {
                                        setDeviceReseting(true);
                                        await onResetDevice(row);
                                        setDeviceReseting(false);
                                    }
                                }
                            });
                        }}
                        options={{ style: { height: "2rem" } }}
                        isLoading={isDeviceReseting}
                        afterExtra={<ResetDeviceIcon />}
                        className="danger"
                        small
                    >
                        Reset Device
                    </Button>
                )
            }
            open={open}
            onChange={onChange}
            onClose={onClose}
            onEdit={onEdit}
        >
            <div className="tk-employees__modal-content-view">
                <EmployeeAvatar
                    src={row?.photoSrc}
                    filename={row?.photoFileName}
                    fullname={fullName}
                    designation={row.designation}
                    department={row.department}
                    isLoading={isLoading}
                />
                <div className="employee-extra flex gap-05" style={{ alignItems: "center" }}>
                    <span className="fade small-font">Status:</span>
                    <Tag type={row.isOnShift ? TAG_TYPE.ON_SHIFT : TAG_TYPE.AVAILABLE} />
                    {isSuperVisor && <Tag type={TAG_TYPE.APP_SUPERVISOR} />}
                </div>
                <div className="employee-extra flex gap-05" style={{ alignItems: "center" }}>
                    <span className="fade small-font">Used Password:</span>
                    <Tag className="brown">{separateLastCharacters(sanitizeWords(row.passwordKeyToUse), 2, "id") || "N/A"}</Tag>
                </div>
                <FormSection header="Personal Information">
                    <Input label="Employee No." renderValue={row.companyID || data?.id} {...COMMON_INPUT_PROPS} />
                    <Input label="Residence ID" renderValue={row.residenceID} {...COMMON_INPUT_PROPS} />
                    <Input label="Visa ID" renderValue={row.visaID} {...COMMON_INPUT_PROPS} />
                    <Input label="Name" renderValue={sanitizeWords(`${row.first_name || ""} ${row.last_name || ""}`)} {...COMMON_INPUT_PROPS} />
                    <Input label="Gender" renderValue={row.gender} {...COMMON_INPUT_PROPS} />
                    <Input label="Nationality" renderValue={nationality?.demonyms?.eng?.m || nationality?.name?.common} {...COMMON_INPUT_PROPS} />
                    <Input label="Birthdate" renderValue={row.birthdate} {...COMMON_INPUT_PROPS} />
                    <Input label="Marital Status" renderValue={row.marital_status} {...COMMON_INPUT_PROPS} />
                    <Input label="Mobile No." renderValue={toReadablePhone(row.mobile_number)} {...COMMON_INPUT_PROPS} />
                    <Input label="Email Address" renderValue={row.email_address} {...COMMON_INPUT_PROPS} />
                    <Input label="ID Expiration" renderValue={IDExpirationDate} {...COMMON_INPUT_PROPS} />
                    <Input label="Visa ID Expiration" renderValue={visaIDExpirationDate} {...COMMON_INPUT_PROPS} />
                    <Input label="Passport No." renderValue={row.passportID} {...COMMON_INPUT_PROPS} />
                    <Input label="Salary Frequency" renderValue={row.EmployeeContract?.salary_frequency} {...COMMON_INPUT_PROPS} />
                    <Input label="Number of Working Days" renderValue={row.totalWorkingDays} {...COMMON_INPUT_PROPS} afterExtra={renderNA("Days")} />
                </FormSection>
                <FormSection header="Work Details">
                    <Input label="Work Types" renderValue={renderViewButton(PROJECT, row?.work_detail_ids || [])} {...COMMON_INPUT_PROPS} />
                    <Input
                        label="Geozones"
                        renderValue={renderViewButton(GEOZONE, row?.CompanySites?.map((cs) => cs.id) || [])}
                        {...COMMON_INPUT_PROPS}
                    />
                    <Input label="Department" renderValue={row.department} {...COMMON_INPUT_PROPS} />
                    <Input label="Designation" renderValue={row.designation} {...COMMON_INPUT_PROPS} />
                    <Input label="Grade" renderValue={row.grade || renderNA()} {...COMMON_INPUT_PROPS} />
                </FormSection>
                <FormSection header="Work Shift">
                    <Input label="Daily Work Hours" renderValue={dailyWorkHours} {...COMMON_INPUT_PROPS} />
                    <Input label="Max OT hours/day" renderValue={maxOTHours} {...COMMON_INPUT_PROPS} />
                    <Input label="Number of Weekly Off Days" renderValue={row.number_weekly_off_days || renderNA()} {...COMMON_INPUT_PROPS} />
                    <Input label="Off Days" renderValue={row.off_days || renderNA()} {...COMMON_INPUT_PROPS} />
                    <Input label="Shift" renderValue={shiftTime} {...COMMON_INPUT_PROPS} />
                    <Input label="Break" renderValue={breakTime} {...COMMON_INPUT_PROPS} />
                    <Input label="Schedule" renderValue={row.schedule_type} {...COMMON_INPUT_PROPS} />
                </FormSection>
                <FormSection header="Contract Details">
                    <Input label="Contract Type" renderValue={row.EmployeeContract?.contract_type} {...COMMON_INPUT_PROPS} />
                    <Input label="Contract Term" renderValue={row.EmployeeContract?.contract_term} {...COMMON_INPUT_PROPS} />
                    <Input label="Hiring Date" renderValue={hiringDate} {...COMMON_INPUT_PROPS} />
                    <Input label="Joining Date" renderValue={joiningDate} {...COMMON_INPUT_PROPS} />
                    <Input label="Contract Expiry Date" renderValue={contractExiryDate} {...COMMON_INPUT_PROPS} />
                    <Input
                        label="Period"
                        renderValue={row.EmployeeContract?.period || ""}
                        afterExtra={renderNA("Month(s)")}
                        {...COMMON_INPUT_PROPS}
                    />
                    <Input
                        label="Probation Period"
                        renderValue={row.EmployeeContract?.probation_period || ""}
                        afterExtra={renderNA("Month(s)")}
                        {...COMMON_INPUT_PROPS}
                    />
                    <Input label="Contract Copy" renderValue={renderViewButton(CONTRACT_COPY, row)} {...COMMON_INPUT_PROPS} />
                    <Input label="Salary Details" renderValue={renderViewButton(SALARY, row)} {...COMMON_INPUT_PROPS} />
                    <Input label="Job Description" renderValue={renderViewButton(JOB_DESCRIPTION, row)} {...COMMON_INPUT_PROPS} />
                    <Input label="Annual Leave" renderValue={row.annualLeave ? row.annualLeave : renderNA("Not Eligible")} {...COMMON_INPUT_PROPS} />
                </FormSection>
                <FormSection header="Bank Details">
                    <Input label="Short Name" renderValue={row.EmployeeBankDetail?.short_name} {...COMMON_INPUT_PROPS} />
                    <Input label="Account #" renderValue={row.EmployeeBankDetail?.account_number} {...COMMON_INPUT_PROPS} />
                    <Input label="IBAN" renderValue={row.EmployeeBankDetail?.iban} {...COMMON_INPUT_PROPS} />
                </FormSection>
                <FormSection header="Device">
                    <Input label="Name" renderValue={row.device?.device_name} {...COMMON_INPUT_PROPS} />
                    <Input label="Model" renderValue={row.device?.model} {...COMMON_INPUT_PROPS} />
                    <Input label="IP" renderValue={row.device?.device_ip} {...COMMON_INPUT_PROPS} />
                    <Input label="MAC Address" renderValue={row.device?.mac_address} {...COMMON_INPUT_PROPS} />
                </FormSection>
                <FormSection header="Record Details">
                    <Input label="Created At" renderValue={current && toReadableFromDate(row?.createdAt, setting.timezone)} {...COMMON_INPUT_PROPS} />
                    <Input
                        label="Updated At"
                        renderValue={sanitizeTimestamp(row?.updatedAt, row?.createdAt, setting.timezone)}
                        {...COMMON_INPUT_PROPS}
                    />
                </FormSection>
            </div>
            {!!showModalSub && (
                <ViewModalSub
                    open={!!viewObject.type}
                    onChange={(bool) => handleViewChange({ type: bool ? viewObject.type : null })}
                    type={viewObject.type}
                    data={viewObject.data}
                    countries={countries}
                />
            )}
            {!!showGeo && (
                <ViewGeozonesModal open={showGeo} onChange={(bool) => handleViewChange({ type: bool ? GEOZONE : null })} data={viewObject.data} />
            )}
            {!!showWorkTypes && (
                <ViewWorkDetailsModal
                    open={showWorkTypes}
                    onChange={(bool) => handleViewChange({ type: bool ? PROJECT : null })}
                    data={viewObject.data}
                />
            )}
        </BaseViewModal>
    );
}

ViewModal.propTypes = {
    open: PropTypes.bool,
    isLoading: PropTypes.bool,
    onClose: PropTypes.func,
    data: PropTypes.object,
    setting: PropTypes.object,
    onChange: PropTypes.func,
    onResetDevice: PropTypes.func,
    onEdit: PropTypes.func,
    cachedCurrent: PropTypes.bool,
    readOnly: PropTypes.bool
};

export default ViewModal;
