import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apiSlice } from "../../../app/apiSlice";

const BASE_PATH = "/api/admin/company-subscription";

const END_POINTS = {
    loadCompanySubscriptionsByAdmin: { method: "get", path: "load-all" },
    getCompanySubscriptionDetailsByAdmin: { method: "get", path: "details" },
    updateCompanySubscriptionByAdmin: { method: "put", path: "update" },
    createCompanySubscriptionByAdmin: { method: "post", path: "create" }
};

export const superAdminCompanySubscriptionsAPI = apiSlice.injectEndpoints({
    reducerPath: "superAdminCompanySubscriptionsAPI",
    baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_SERVER_URI }),
    endpoints: (builder) => {
        const temp = {};
        Object.entries(END_POINTS).forEach(([key, config]) => {
            temp[key] = builder.mutation({
                query: (queryParams = {}) => {
                    const path = `${BASE_PATH}/${config.path}`;
                    const conf = {
                        url: path,
                        method: config.method,
                        credentials: "include"
                    };
                    if (typeof queryParams.extraPath === "string" || typeof queryParams.extraPath === "number")
                        conf.url = `${path}/${queryParams.extraPath}`;
                    if (Array.isArray(queryParams.extraPaths)) conf.url = `${path}/${queryParams.extraPaths.join("/")}`;
                    if (queryParams.params) conf.params = queryParams.params;
                    if (queryParams.body) conf.body = queryParams.body;
                    return conf;
                }
            });
        });
        return temp;
    }
});

export const {
    useLoadCompanySubscriptionsByAdminMutation,
    useGetCompanySubscriptionDetailsByAdminMutation,
    useUpdateCompanySubscriptionByAdminMutation,
    useCreateCompanySubscriptionByAdminMutation
} = superAdminCompanySubscriptionsAPI;

export default superAdminCompanySubscriptionsAPI.reducer;
