import { useEffect, useState } from "react";
import cloneDeep from "lodash/cloneDeep";
import { useAppDispatch, useAppSelector } from "../../../common/hooks/reduxHooks";
import { TOAST_TYPE, createToast } from "../../../common/utilities/helper";
import {
    useCreateSubcriptionMutation,
    useDeleteSubcriptionMutation,
    useGetAllSubscriptionMutation,
    useGetSubscriptionDetailsMutation,
    useUpdateSubcriptionMutation
} from "./api";
import { selectCurrent, selectData, selectTableConfig, selectUpgradeSelections, setCurrent, setState, setUpgradeSelections } from "./slice";
import { FIELDS } from "./const";
import { useGetUpgradeSelectionsMutation } from "../../company/companySubscription/api";

const INITIAL_UPDATE_FORM = Object.values(FIELDS).reduce(
    (prev, curr) => ({
        ...prev,
        [curr.name]: curr.defaultValue
    }),
    {}
);

export const usePaginateSubscriptions = () => {
    const [fetching, setFetching] = useState(true);

    const dispatch = useAppDispatch();
    const data = useAppSelector(selectData);
    const tableConfig = useAppSelector(selectTableConfig);

    const [load] = useGetAllSubscriptionMutation();

    const fetch = async (config) => {
        if (!fetching) {
            setFetching(true);
        }
        const response = await load({ params: { ...tableConfig, ...(config || {}) } });
        if (response.data && response.data.data) {
            if (typeof setState === "function") {
                const result = response.data.data;
                const oldConfig = { ...tableConfig, ...(config || {}) };
                dispatch(
                    setState({
                        data: result.data,
                        tableConfig: { ...oldConfig, totalPage: result.totalPage }
                    })
                );
            }
        }
        if (response.error) {
            createToast("Failed to fetch data. Please try again later.", TOAST_TYPE.ERROR);
        }
        setFetching(false);
        return response;
    };

    useEffect(() => {
        if (!data.length) {
            fetch();
        } else {
            setFetching(false);
        }
    }, []);

    return [data, fetching, fetch];
};

export const useFetchSubscription = ({ id, cached } = {}) => {
    const [isLoading, setLoading] = useState(true);

    const [getDetails] = useGetSubscriptionDetailsMutation();

    const dispatch = useAppDispatch();
    const current = useAppSelector(selectCurrent);
    const data = useAppSelector(selectCurrent);

    const fetch = async () => {
        if (cached && current && current.id === id) {
            setLoading(false);
            return Promise.resolve();
        }
        const res = await getDetails({ extraPath: id });
        if (res.error) {
            createToast("Failed to fetch subscriptions. Please try again later or contact support", TOAST_TYPE.ERROR);
        }
        if (res.data) {
            dispatch(setCurrent(res.data.data));
        }
        setLoading(false);
        return res;
    };

    useEffect(() => {
        fetch();
    }, []);

    return [data || {}, isLoading];
};

export const useUpsertSubscription = ({ id, initial = {} } = {}) => {
    const isCreate = !id;

    const [form, setForm] = useState({
        ...INITIAL_UPDATE_FORM,
        ...initial
    });

    const [create, { isLoading: createLoading }] = useCreateSubcriptionMutation();
    const [update, { isLoading: updateLoading }] = useUpdateSubcriptionMutation();

    const dispatch = useAppDispatch();

    const isLoading = createLoading || updateLoading;

    const onSave = async () => {
        let result = null;
        try {
            const clonedform = cloneDeep(form);
            if (isCreate) {
                result = await create({ body: clonedform });
            } else {
                result = await update({ body: clonedform, extraPath: id });
            }
            if (result.error) {
                throw new Error(result.error?.data?.message);
            }
            if (result.data) {
                if (result.data?.data) {
                    createToast(`Subscription ${isCreate ? "created" : "updated"} succesfully.`, TOAST_TYPE.SUCCESS);
                } else {
                    createToast(result.data.message, TOAST_TYPE.SUCCESS);
                }
            }
            dispatch(setCurrent(result.data.data));
            return result.data.data;
        } catch (error) {
            createToast(
                `Failed to ${!isCreate ? "update" : "create"} Subscription. ${error?.message || "Please try again later or contact support."} `,
                TOAST_TYPE.ERROR
            );
            return { error };
        }
    };

    return [form, setForm, isLoading, onSave];
};

export const useRemoveSubscription = () => {
    const [remove] = useDeleteSubcriptionMutation();

    const onRemove = async (id) => {
        if (!id) return;
        try {
            const result = await remove({ extraPath: id });
            if (result.error) {
                throw new Error(result.error?.data?.message);
            }
            createToast("Subscription successfully delete.", TOAST_TYPE.SUCCESS);
        } catch (error) {
            createToast(`Failed to remove Subscription. ${error?.message || "Please try again later or contact support."} `, TOAST_TYPE.ERROR);
            return { error };
        }
    };

    return [onRemove];
};

export const useGetUpgradeSelections = () => {
    const [fetching, setFetching] = useState(true);

    const [getSelections] = useGetUpgradeSelectionsMutation();

    const selections = useAppSelector(selectUpgradeSelections);
    const dispatch = useAppDispatch();

    const fetch = async () => {
        const response = await getSelections();
        if (response.error) {
            createToast("Failed to fetch subscriptions. Please try again later", TOAST_TYPE.ERROR);
        }
        setFetching(false);
        dispatch(setUpgradeSelections(response.data.data));
    };

    useEffect(() => {
        if (!selections.length) {
            fetch();
        } else {
            setFetching(false);
        }
    }, []);

    return [selections, fetching];
};
