export const BASE_CLASS = "tk-employee-other-incomes";

export const FIELD = {
    EMPLOYEE: "employee_id",
    INCOME_TYPE: "income_type",
    LABEL: "label",
    AMOUNT: "amount",
    START_DATE: "start_date",
    STATUS: "status",
    CONFIRMED_DATE: "confirmed_date",
    NOTES: "notes"
};

export const FILTER_TYPE = {
    EMPLOYEE: 0x1,
    TYPE: 0x2,
    STATUS: 0x3,
    LABEL: 0x4,
    START_DATE: 0x5,
    CONFIRMED_DATE: 0x6
};

export const OTHER_INCOME_TYPE = {
    BONUS: "BONUS",
    INCENTIVE: "INCENTIVE"
};

export const OTHER_INCOME_LABEL = {
    ANNUAL_BONUS: "ANNUAL_BONUS",
    HOLIDAY_BONUS: "HOLIDAY_BONUS",
    PERFORMANCE_BONUS: "PERFORMANCE_BONUS",
    RETENTION_BONUS: "RETENTION_BONUS",
    COMMISSION_BONUS: "COMMISSION_BONUS",
    SALARY_INCREASE: "SALARY_INCREASE",
    AWARD: "AWARD",
    TIPS: "TIPS",
    TRAINING_PROGRAM: "TRAINING_PROGRAM",
    WELLNESS_PROGRAM: "WELLNESS_PROGRAM",
    OTHERS: "OTHERS"
};

export const OTHER_INCOME_STATUS = {
    PENDING: "PENDING",
    APPROVED: "APPROVED",
    PAID: "PAID"
};
