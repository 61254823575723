import React from "react";
import PropTypes from "prop-types";
import Input, { INPUT_TYPE } from "../../../common/components/extra/form/Input";
import FormSection from "../../../common/components/extra/FormSection";
import { sanitizeWords } from "../../../common/utilities/helper";
import Tag from "../../../common/components/extra/Tag";
import Measures from "../../admin/penalties/Measures";
import { useFetchPenalty } from "./hooks";
import { FIELDS } from "./const";

const { TITLE, NOTES, DESCRIPTION, DEDUCTION_TYPE, PENALTY_MEASURES } = FIELDS;

function ViewModalContent({ data, onLoad, isByLaw, newData }) {
    const [result, isLoading] = useFetchPenalty(
        {
            id: data.id,
            onMount: onLoad,
            isByLaw
        },
        newData
    );

    return (
        <>
            <FormSection header="Penalty Details">
                <Input
                    type={INPUT_TYPE.TEXT}
                    label={TITLE.label}
                    renderValue={<span className="capitalize">{result[TITLE.name]}</span>}
                    isLoading={isLoading}
                    readOnly
                />
                <Input
                    type={INPUT_TYPE.TEXT}
                    label={DEDUCTION_TYPE.label}
                    renderValue={
                        <span className="capitalize">
                            <Tag>{sanitizeWords(result[DEDUCTION_TYPE.name])}</Tag>
                        </span>
                    }
                    isLoading={isLoading}
                    readOnly
                />
                <Input
                    type={INPUT_TYPE.TEXT}
                    label={DESCRIPTION.label}
                    renderValue={<p style={{ marginLeft: "3rem", fontWeight: "600" }}>{result[DESCRIPTION.name]}</p>}
                    isLoading={isLoading}
                    readOnly
                />
                <Input
                    type={INPUT_TYPE.TEXT}
                    label={NOTES.label}
                    renderValue={<p style={{ marginLeft: "3rem", fontWeight: "600" }}>{result[NOTES.name]}</p>}
                    isLoading={isLoading}
                    readOnly
                />
            </FormSection>
            <FormSection header="Measures">
                <Measures data={result[PENALTY_MEASURES.name]} isLoading={isLoading} readOnly />
            </FormSection>
        </>
    );
}

ViewModalContent.propTypes = {
    data: PropTypes.object,
    // use to override result
    newData: PropTypes.object,
    onLoad: PropTypes.func,
    isByLaw: PropTypes.bool
};

export default ViewModalContent;
