import React from "react";
import PropTypes from "prop-types";

function TableFilter({ filter, className, style }) {
    return (
        <div className={`tk-table-filter ${className || ""}`.trim()} style={style}>
            {filter}
        </div>
    );
}

export default TableFilter;

TableFilter.propTypes = {
    filter: PropTypes.any,
    style: PropTypes.object,
    className: PropTypes.string
};
