import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { VIEW_TYPES } from "../../../common/utilities/const";
import { useAppDispatch, useAppSelector } from "../../../common/hooks/reduxHooks";
import TableView from "../../../common/components/extra/table/TableView";
import ViewGeozonesModal from "../companySites/ViewGeozonesModal";
import { TOAST_TYPE, createToast } from "../../../common/utilities/helper";
import { selectUser } from "../../common/slice";
import { setClearCache } from "../employees/slice";
import CreateTableHeaders from "./CreateTableHeaders";
import UpdateModal from "./UpdateModal";
import { useDeleteWorkDetailsMutation } from "./api";
import { selectTableConfig, selectView } from "./slice";
import { usePaginateWorkDetails } from "./hooks";

function WorkDetails({ openAddModal, onAddModalClose }) {
    const [object, setObject] = useState({
        viewSites: false,
        openEditModal: openAddModal,
        selected: null
    });

    const [data, { isLoading: isPaginating, fetch }] = usePaginateWorkDetails();
    const [remove] = useDeleteWorkDetailsMutation();

    const dispatch = useAppDispatch();
    const tableConfig = useAppSelector(selectTableConfig);
    const view = useAppSelector(selectView);
    const user = useAppSelector(selectUser);

    const updateObject = (newObj = {}) => setObject((prev) => ({ ...prev, ...newObj }));
    const departments = (user.Setting && user.Setting.departments) || [];

    useEffect(() => {
        if (openAddModal && openAddModal !== object.openEditModal) {
            updateObject({ openEditModal: openAddModal });
        }
    }, [openAddModal]);

    const handleModalEdit = (row) => {
        updateObject({ openEditModal: true, selected: row });
    };

    const handleFinish = async () => {
        await fetch();
        updateObject({ selected: null, viewSites: false, openEditModal: false });
        dispatch(setClearCache());
        typeof onAddModalClose == "function" && onAddModalClose();
    };

    const handleDelete = async (row) => {
        const response = await remove({ extraPath: row.id });
        if (response.error) {
            createToast(response.error?.data?.message || "Failed to delete work type.", TOAST_TYPE.ERROR);
        } else {
            await fetch();
        }
    };

    const handleViewSite = (row) => {
        updateObject({ viewSites: true, selected: row });
    };

    return (
        <>
            {(() => {
                switch (view) {
                    default:
                    case VIEW_TYPES.TABLE:
                        return (
                            <TableView
                                data={data}
                                headers={CreateTableHeaders({
                                    departments,
                                    onEdit: handleModalEdit,
                                    onDelete: handleDelete,
                                    onViewSites: handleViewSite
                                })}
                                refetch={fetch}
                                isLoading={isPaginating}
                                tableConfig={tableConfig}
                            />
                        );
                }
            })()}
            {!!object.viewSites && (
                <ViewGeozonesModal
                    open={object.viewSites}
                    onChange={(bool) => updateObject({ viewSites: bool })}
                    data={object.selected?.CompanySites.map((cs) => cs.id) || []}
                />
            )}
            {object.openEditModal && (
                <UpdateModal
                    open={object.openEditModal}
                    onChange={(bool) => {
                        const temp = { openEditModal: bool };
                        if (!bool) {
                            temp.selected = null;
                            typeof onAddModalClose === "function" && onAddModalClose();
                        }
                        updateObject(temp);
                    }}
                    onFinish={handleFinish}
                    id={object?.selected?.id}
                />
            )}
        </>
    );
}

WorkDetails.propTypes = {
    openAddModal: PropTypes.bool,
    onAddModalClose: PropTypes.func
};

export default WorkDetails;
