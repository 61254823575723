import React from "react";
import PreviewIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import Tag from "../../../common/components/extra/Tag";
import { SHIFT_TYPE, WORK_HISTORY_STATUS, WORK_HISTORY_TYPE } from "../../../common/utilities/const";
import {
    createConfirmAlert,
    createTextFromDate,
    renderNA,
    sanitizeTimestamp,
    sanitizeWords,
    toReadableFromDate,
    toTimeWithTimeZone
} from "../../../common/utilities/helper";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectUser } from "../../common/slice";
import LetteredAvatar from "../../../common/components/extra/avatar/LetteredAvatar";
import { ACTION_TYPE, INCOMPLETE_STATUS_IDENTIFIER } from "./const";
import ShiftStatus from "./ShiftStatus";
import { chekIncStatus, createdUpdateStatus } from "./helper";

const HEADERS = {
    NAME: "name",
    TIME_IN: "time_in",
    TIME_OUT: "time_out",
    SHIFT_STATUS: "shift_status",
    UPDATED_AT: "updated_at",
    CREATED_AT: "created_at",
    ACTIONS: "actions"
};

const HEADERS_TO_ARRAY = Object.values(HEADERS);

const CreateTableHeaders = ({ onAction }) => {
    const user = useAppSelector(selectUser);
    const setting = user.Setting;
    const timezone = setting.timezone;

    const createTimeToRender = (time) => (time ? toTimeWithTimeZone(time, timezone).format("hh:mm A") : "--:--");

    return HEADERS_TO_ARRAY.map((head) => {
        const temp = { key: head, style: {} };
        switch (head) {
            case HEADERS.NAME: {
                temp.sortKey = "index1";
                temp.childStyle = {
                    alignItems: "center",
                    gap: "1rem",
                    margin: ".5rem 0"
                };
                temp.label = "Employee";
                temp.style.width = "12rem";
                temp.fixed = true;
                temp.render = (_, row) => {
                    const isPending = row.status == WORK_HISTORY_STATUS.PENDING;
                    const isRejected = row.status == WORK_HISTORY_STATUS.REJECTED;
                    const isOtOffDay = row.type == WORK_HISTORY_TYPE.OT_OFF_DAY;
                    const shiftOneDate = row.time_in_one && toTimeWithTimeZone(row.time_in_one, timezone).format("MMM DD YYYY");
                    const shiftTwoDate = row.time_out_two && toTimeWithTimeZone(row.time_out_two, timezone).format("MMM DD YYYY");
                    const dateText = createTextFromDate(shiftOneDate, shiftTwoDate, timezone).date;

                    return (
                        <>
                            <LetteredAvatar name={row.full_name} src={row.photo} size={30} small />
                            <div className="flex column gap-05 overflow-hidden">
                                <span style={{ fontWeight: "bold" }}>{sanitizeWords(row.full_name)}</span>
                                <div className="flex gap-05 wrap">
                                    <span className="fade small-font flex center">App:</span>
                                    <Tag>
                                        <span className="text-ellipsis">{row.is_supervisor ? "SUPERVISOR" : "EMPLOYEE"}</span>
                                    </Tag>
                                </div>
                                <div className="flex gap-05 small-font wrap">
                                    <span className="fade flex center">Work Type:</span>
                                    <span className="semi-bold">{sanitizeWords(row?.site_name)}</span>
                                </div>
                                <div className="flex gap-05 small-font wrap">
                                    <span className="fade flex center">Date:</span> <span className="semi-bold">{dateText}</span>
                                </div>
                                <div className="flex gap-05 wrap">
                                    <span className="fade small-font flex center">Status:</span>
                                    <Tag className={isPending ? "yellow" : isRejected ? "red" : "green"}>
                                        <span className="text-ellipsis">
                                            {sanitizeWords(isPending ? "PENDING" : isRejected ? "REJECTED" : "VERIFIED", "_")}
                                        </span>
                                    </Tag>
                                    {isOtOffDay && <Tag className="yellow">OFF-DAY OT</Tag>}
                                    {createdUpdateStatus(row)}
                                </div>
                            </div>
                        </>
                    );
                };
                break;
            }
            case HEADERS.TIME_IN: {
                temp.label = <span style={{ whiteSpace: "nowrap" }}>Time In</span>;
                temp.render = (_, row) => {
                    const isSplit = row?.shift_type == SHIFT_TYPE.SPLIT;
                    return (
                        <div className="flex column gap-05 overflow-hidden" style={{ fontWeight: "500" }}>
                            <div className="flex gap-05 column" style={{ justifyContent: "center" }}>
                                {row.time_in_one_site && <span className="fade small-font">{row.time_in_one_site}:</span>}
                                <span>
                                    {isSplit && <span className="fade small-font">Shift 1: </span>} {createTimeToRender(row.time_in_one)}
                                </span>
                            </div>
                            {isSplit && (
                                <div className="flex gap-05 column" style={{ justifyContent: "center" }}>
                                    {row.time_in_two_site && <span className="fade small-font">{row.time_in_two_site}:</span>}
                                    <span>
                                        <span className="fade small-font">Shift 2:</span> {createTimeToRender(row.time_in_two)}
                                    </span>
                                </div>
                            )}
                        </div>
                    );
                };
                break;
            }
            case HEADERS.TIME_OUT: {
                temp.label = <span style={{ whiteSpace: "nowrap" }}>Time Out</span>;
                temp.render = (_, row) => {
                    const isSplit = row?.shift_type == SHIFT_TYPE.SPLIT;

                    return (
                        <div className="flex column gap-05 overflow-hidden" style={{ fontWeight: "500" }}>
                            <span className="text-ellipsis">
                                {isSplit && <span className="fade small-font">Shift 1: </span>} {createTimeToRender(row.time_out_one)}
                            </span>
                            {isSplit && (
                                <span className="text-ellipsis">
                                    <span className="fade small-font">Shift 2:</span> {createTimeToRender(row.time_out_two)}
                                </span>
                            )}
                        </div>
                    );
                };
                break;
            }
            case HEADERS.SHIFT_STATUS: {
                temp.style.width = "13rem";
                temp.label = "Shift Status";
                temp.render = (_, row) => {
                    return (
                        <ShiftStatus
                            statuses={row.shift_status || []}
                            shiftType={row.shift_type}
                            shiftOne={{
                                timeInOne: row.time_in_one,
                                timeOutOne: row.time_out_one,
                                isIncomplete: chekIncStatus(row.time_in_one, row.time_out_one, timezone, INCOMPLETE_STATUS_IDENTIFIER)
                            }}
                            shiftTwo={{
                                timeInTwo: row.time_in_two,
                                timeOutTwo: row.time_out_two,
                                isIncomplete: chekIncStatus(row.time_in_two, row.time_out_two, timezone, INCOMPLETE_STATUS_IDENTIFIER)
                            }}
                        />
                    );
                };
                break;
            }
            case HEADERS.UPDATED_AT: {
                temp.sortKey = "updatedAt";
                temp.label = "Updated";
                temp.render = (_, row) => (
                    <div className="flex column gap-05 overflow-hidden fade">
                        <span className="text-ellipsis small-font">
                            {sanitizeTimestamp(row.updatedAt, row.createdAt, setting.timezone) || renderNA()}
                        </span>
                    </div>
                );
                break;
            }
            case HEADERS.CREATED_AT: {
                temp.sortKey = "createdAt";
                temp.label = "Created";
                temp.render = (_, row) => (
                    <div className="flex column gap-05 overflow-hidden fade">
                        <span className="text-ellipsis small-font">{toReadableFromDate(row.createdAt, setting.timezone)}</span>
                    </div>
                );
                break;
            }
            case HEADERS.ACTIONS: {
                temp.childStyle = {
                    display: "flex",
                    gap: ".5rem",
                    justifyContent: "flex-end",
                    paddingRight: "1rem"
                };
                temp.render = (_, row) => (
                    <>
                        <PreviewIcon onClick={() => onAction?.(row, ACTION_TYPE.VIEW)} />
                        {row.isCreatedByAdmin && !row.submittedForm && (
                            <DeleteIcon
                                style={{ color: "red" }}
                                className="hover-svg"
                                onClick={async () => {
                                    createConfirmAlert({
                                        title: "Remove Record",
                                        content: "Are you sure you want to remove this record? This cannot be undone.",
                                        onConfirm: async (close) => {
                                            close();
                                            onAction?.(row, ACTION_TYPE.REMOVE);
                                        }
                                    });
                                }}
                            />
                        )}
                    </>
                );

                break;
            }
            default:
                break;
        }
        return temp;
    });
};

export default CreateTableHeaders;
