import React from "react";
import { Navigate, useLocation } from "react-router-dom";

import SubscriptionPlan from "../../features/admin/subscriptions/SubscriptionPlan";
import { useAppSelector } from "../../common/hooks/reduxHooks";
import { selectUser } from "../../features/common/slice";
import useNavigate from "../../common/hooks/useNavigate";
import Navigation from "../../common/classes/Navigation";

function SubscriptionUpgrade() {
    const DASHBOARD_PATH = Navigation.Routes.DASHBOARD.path;

    const user = useAppSelector(selectUser);
    const location = useLocation();
    const navigate = useNavigate();

    const companySubscription = user.CompanySubscription;
    const hasPrevRoute = location.state?.from?.pathname;
    const prevPathIsSub =
        hasPrevRoute && Navigation.CompanySubscriptionUpgradeRoutes.some((r) => hasPrevRoute.includes(r.path.replace("/:token", "")));
    const pathname = prevPathIsSub || !hasPrevRoute ? DASHBOARD_PATH : -1;

    // its not allowed to view upgrade as long as there is pending payment
    if (user.is_pending_payment) {
        return <Navigate to={pathname} />;
    }

    return (
        <SubscriptionPlan
            onBack={() => navigate(user.is_expired ? Navigation.Routes.EXPIRED_SUBSCRIPTION.path : pathname)}
            companySubscription={companySubscription}
        />
    );
}

export default SubscriptionUpgrade;
