import React, { useState, useEffect, forwardRef } from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import Input, { INPUT_TYPE } from "../form/Input";
import { STANDARD_REF_DATE } from "../../../utilities/const";
import MiniLoader from "../MiniLoader";
import Text from "../typography/Text";

const TYPE = { START: 0x1, END: 0x2 };
const DATE_TYPE = INPUT_TYPE.TIME;
const COMMON_STYLE = { minWidth: "unset", width: "8rem" };
const FORMAT_DATE = "YYYY-MM-DD HH:mm:ssZ";

export const EVENT = { MOUNTING: "mounting", CHANGING: "changing" };

const changeToBaseDate = (baseDate, date) => {
    const base = moment(moment(baseDate).toDate());
    const tzdate = moment(moment(date).toDate());
    return base.set({
        hour: tzdate.hour(),
        minute: tzdate.minute(),
        second: tzdate.second(),
        millisecond: tzdate.millisecond()
    });
};

const getDateSelectionAvailability = (date, range) => {
    const fdatem = moment(date);
    const startMin = range?.min?.toDate?.();
    const startMax = range?.max?.toDate?.();
    if (!startMin && !startMax) {
        return true;
    }
    if (startMin && !startMax) {
        const dateWithBaseMin = changeToBaseDate(startMin, fdatem);
        const isAfter = dateWithBaseMin.isAfter(startMin);
        return isAfter;
    }
    if (!startMin && startMax) {
        const dateWithBaseMax = changeToBaseDate(startMax, fdatem);
        const isBefore = dateWithBaseMax.isAfter(startMax);
        return isBefore;
    }
    if (startMin && startMax) {
        const dateWithBaseMin = changeToBaseDate(startMin, fdatem);
        const dateWithBaseMax = changeToBaseDate(startMax, fdatem);
        const isNextDay = dateWithBaseMin.day() !== dateWithBaseMax.day();
        if (isNextDay) {
            const isBeforeOrAfter = dateWithBaseMax.isBefore(startMax) || dateWithBaseMin.isAfter(startMin);
            return isBeforeOrAfter;
        }
        const isAfterAndBefore = dateWithBaseMin.isAfter(startMin) && dateWithBaseMax.isBefore(startMax);
        return isAfterAndBefore;
    }
};

const checkIfEndIsForNextDay = ({ end, start }) => {
    const temp = {
        start: start || null,
        end: end || null
    };
    if (end && start && temp.end.isSameOrBefore(temp.start)) {
        temp.end = moment(end).add(1, "day");
    }
    return temp;
};

const TimePickerRangeMain = forwardRef(function TimePickerRangeMain(
    {
        range,
        onChange,
        disabled,
        constraint,
        timezone,
        styleDate,
        isPortal,
        menuPlacement,
        interval,
        isLoading,
        noResetOnChange,
        readOnly,
        baseDate = STANDARD_REF_DATE
    },
    hiddenRef
) {
    const [event, setEvent] = useState("");

    const [object, setObject] = useState({
        start: range?.start,
        end: range?.end
    });

    const startDate = range?.start?.toDate() || null;
    const endDate = range?.end?.toDate() || null;

    const updateObject = (newObject = {}) => setObject((prev) => ({ ...prev, ...newObject }));

    useEffect(() => {
        if (object?.start && object?.end) {
            hiddenRef.current.value = "value";
        }
    }, []);

    useEffect(() => {
        if (object?.start && object?.end) {
            hiddenRef.current.value = "value";
        } else {
            hiddenRef.current.value = "";
            if (object.start || object.end) {
                hiddenRef.current.required = true;
            } else {
                hiddenRef.current.required = false;
            }
        }
    }, [object?.start, object?.end]);

    useEffect(() => {
        if (typeof onChange === "function") {
            const temp = checkIfEndIsForNextDay({
                start: object?.start,
                end: object?.end
            });
            const start = (range.start && range.start.clone().format()) || null;
            const end = (range.end && range.end?.clone().format()) || null;
            const hasSameStart = start == (temp.start?.clone().format() || null);
            const hasSameEnd = end == (temp.end?.clone()?.format() || null);
            if (hasSameStart && hasSameEnd) {
                return;
            }
            const newObj = {
                start: temp?.start?.toDate() || null,
                end: temp?.end?.toDate() || null
            };
            onChange?.(newObj, event);
        }
    }, [object, event]);

    const handleChange = (time, type, evt) => {
        evt = !evt ? EVENT.CHANGING : EVENT.MOUNTING;
        const isMounting = evt == EVENT.MOUNTING;
        if (!time) {
            hiddenRef.current.value = "";
        }
        switch (type) {
            case TYPE.START: {
                if (isMounting) {
                    updateObject({ start: time });
                } else {
                    let config = { start: time };
                    // we reset end if we have a new start time
                    if ((!noResetOnChange && time && time.isSameOrAfter(object.end)) || !time) {
                        config.end = null;
                        onChange?.(config, evt);
                    }
                    updateObject(config);
                }
                break;
            }
            case TYPE.END: {
                updateObject({ end: time });
                break;
            }
            default:
                break;
        }
        setEvent(evt);
    };
    return (
        <>
            <Input
                type={DATE_TYPE}
                parentStyle={{ ...COMMON_STYLE, ...(styleDate || {}) }}
                onChange={(date, event) => handleChange(date && moment(moment(date).format(FORMAT_DATE)), TYPE.START, event)}
                selected={startDate}
                startDate={startDate}
                endDate={endDate}
                readOnly={readOnly}
                isFixed={isPortal}
                popperPlacement={menuPlacement}
                disabled={disabled}
                timezone={timezone}
                interval={interval}
                filterTime={(fdate) => getDateSelectionAvailability(fdate, constraint?.start)}
                baseDate={baseDate}
                isLoading={isLoading}
                constraint={constraint?.start}
                isClearable
                selectsStart
                useSimpleClearIcon
            />
            <span className="fade">to</span>
            <Input
                type={DATE_TYPE}
                parentStyle={{ ...COMMON_STYLE, ...(styleDate || {}) }}
                onChange={(date, event) => handleChange(date && moment(moment(date).format(FORMAT_DATE)), TYPE.END, event)}
                selected={endDate}
                startDate={startDate}
                endDate={endDate}
                readOnly={readOnly}
                disabled={!startDate || disabled}
                timezone={timezone}
                interval={interval}
                filterTime={(fdate) => getDateSelectionAvailability(fdate, constraint?.end)}
                baseDate={baseDate}
                isLoading={isLoading}
                constraint={constraint?.end}
                isClearable
                selectsEnd
                useSimpleClearIcon
            />
        </>
    );
});

function TimePickerRange(props) {
    const hiddenRef = React.useRef(null);
    const { label, required, noBorder, style, isLoading, subtext } = props;
    return (
        <>
            <div className="tk-timepicker-range" style={{ ...(style || {}), ...(noBorder && (!subtext || subtext.hide) ? { border: "none" } : {}) }}>
                <div className="tk-timepicker-range__inner">
                    {label && (
                        <span className="tk-timepicker-range__label">
                            {label}
                            {required && !props.readOnly && !props.disabled ? <span className="danger-color bold">*</span> : ""}
                        </span>
                    )}
                    <div className="flex gap-05 center">{isLoading ? <MiniLoader show /> : <TimePickerRangeMain ref={hiddenRef} {...props} />}</div>
                </div>
                <input key={required} className="hidden-input" ref={hiddenRef} name="" required={required} />
            </div>
            {subtext && !subtext.hide && (
                <Text style={{ marginLeft: ".5rem", ...(subtext.style || {}) }} className={subtext.className}>
                    {subtext.message}
                </Text>
            )}
        </>
    );
}

const props = {
    label: PropTypes.any,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    defaultRange: PropTypes.shape({
        start: PropTypes.instanceOf(moment),
        end: PropTypes.instanceOf(moment)
    }),
    range: PropTypes.shape({
        start: PropTypes.instanceOf(moment),
        end: PropTypes.instanceOf(moment)
    }),
    constraint: PropTypes.shape({
        start: PropTypes.shape({
            min: PropTypes.instanceOf(moment),
            max: PropTypes.instanceOf(moment)
        }),
        end: PropTypes.shape({
            min: PropTypes.instanceOf(moment),
            max: PropTypes.instanceOf(moment)
        })
    }),
    timezone: PropTypes.string,
    style: PropTypes.object,
    styleDate: PropTypes.object,
    noBorder: PropTypes.bool,
    isPortal: PropTypes.bool,
    menuPlacement: PropTypes.string,
    interval: PropTypes.number,
    virtualize: PropTypes.bool,
    isLoading: PropTypes.bool,
    noResetOnChange: PropTypes.bool,
    readOnly: PropTypes.bool,
    baseDate: PropTypes.string,
    subtext: PropTypes.shape({
        style: PropTypes.object,
        className: PropTypes.string,
        message: PropTypes.any,
        hide: PropTypes.bool
    })
};

TimePickerRange.propTypes = props;
TimePickerRangeMain.propTypes = props;

export default TimePickerRange;
