import React, { useState } from "react";
import moment from "moment-timezone";
import PropTypes from "prop-types";
import Text from "../../../common/components/extra/typography/Text";
import Button from "../../../common/components/extra/Button";
import { createClass, createConfirmAlert, toProperMoneyFormat } from "../../../common/utilities/helper";
import { BASE_CLASS, DOWNLOAD_TYPE } from "./const";
import Input, { INPUT_TYPE } from "../../../common/components/extra/form/Input";
import { useSalaryStateManager } from "./hooks";
import CreateTableHeaders from "./CreateTableHeaders";
import TableView from "../../../common/components/extra/table/TableView";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectUserSetting, selectViewport } from "../../common/slice";
import ExportWPSModal from "./ExportWPSModal";
import Tag from "../../../common/components/extra/Tag";

function EmployeesSalary({ ...rest }) {
    const [startWPSDl, setStartWPSDl] = useState(false);

    const [
        data,
        {
            date,
            setDate,
            isNotYetGenerated,
            isNotAllowed,
            onGeneratePreview,
            onConfirm,
            onDownload,
            isLoading,
            isLoadingMore,
            loadMore,
            isDownloading,
            isPending,
            isConfirmed,
            sort,
            tableConfig,
            isNoRecordsAvailable,
            isStrictChecking,
            setStrictChecking,
            isStrictCheckingChanged
        }
    ] = useSalaryStateManager();

    const viewport = useAppSelector(selectViewport);
    const setting = useAppSelector(selectUserSetting);

    const isPreview = !!data.length && !isNotYetGenerated;
    const confirmed = (isPreview && !isPending) || isConfirmed;
    const dbSalary = tableConfig.dbSalary;
    const totalSalaries = dbSalary && dbSalary.total_salaries;

    const getReadableDate = () => moment(date).format("MMM YYYY");

    const renderInputDate = () => {
        return (
            <div style={{ maxWidth: "10rem" }}>
                <Input
                    type={INPUT_TYPE.DATE}
                    label={<span style={{ whiteSpace: "nowrap" }}>See Month Of</span>}
                    onChange={setDate}
                    selected={date}
                    isMonthYear
                    isClearable
                />
            </div>
        );
    };

    const createEmpyMessage = () => {
        let content = <span>Select a Month to view a Salary Report.</span>;

        if (date) {
            if (isNotYetGenerated) {
                content = (
                    <>
                        <span>
                            Salary Report not yet Generated for this Month of <strong>{getReadableDate()}</strong>.
                        </span>
                        <div className="strict-checking">
                            <Input
                                type={INPUT_TYPE.CHECKBOX}
                                value={isStrictChecking}
                                label="Apply Strict Timing?"
                                parentStyle={{ overflow: "unset" }}
                                tooltip={
                                    <div style={{ maxWidth: "10rem", wordBreak: "break-word" }}>
                                        <p style={{ width: "100%", whiteSpace: "normal", margin: 0 }}>
                                            Any timing irregularities, including late arrivals, early time-outs, and extended breaks, will be
                                            reflected in payroll calculations.
                                        </p>
                                    </div>
                                }
                                onChange={(e) => setStrictChecking(e.target.checked)}
                                noBorder
                            />
                            <Text className="danger-color semi-bold">
                                Notice: When Strict timing is enabled. Any timing irregularities, including late arrivals, early time-outs, and
                                extended breaks, will be reflected in payroll calculations.
                            </Text>
                        </div>
                        <Button
                            className="primary"
                            onClick={() =>
                                createConfirmAlert({
                                    title: "Generate Salary Report",
                                    content: (
                                        <span>
                                            Are you sure this will create the salary report for the selected month with&nbsp;
                                            <strong>strict checking {isStrictChecking ? "enabled" : "disabled"}</strong>.
                                        </span>
                                    ),
                                    onConfirm: async (close) => {
                                        close();
                                        onGeneratePreview();
                                    }
                                })
                            }
                            small
                        >
                            Generate Preview
                        </Button>
                    </>
                );
            } else if (isNotAllowed) {
                content = (
                    <>
                        <span>
                            Not Allowed to view Salary Report for the Period of <strong>{getReadableDate()}</strong>.
                        </span>
                    </>
                );
            } else if (isNoRecordsAvailable) {
                content = (
                    <>
                        <span>
                            No available work history records to generate for the Period of <strong>{getReadableDate()}</strong>.
                        </span>
                    </>
                );
            } else {
                content = (
                    <>
                        <span>
                            No Data to view Salary Report for the Period of <strong>{getReadableDate()}</strong>.
                        </span>
                    </>
                );
            }
        }
        return <div className="custom-no-salary-message flex column gap-05 center">{content}</div>;
    };

    const createFooter = () => {
        if (!data.length || isLoading) {
            return <></>;
        }

        return (
            <div className={createClass("__footer flex gap-05 center wrap", BASE_CLASS)}>
                {!confirmed && (
                    <>
                        <Button
                            className={`${isStrictCheckingChanged ? "success" : "danger"} small-font`}
                            onClick={() =>
                                createConfirmAlert({
                                    title: "Regenerate Salary Report",
                                    content: "This will reload the report with up-to-date information.",
                                    onConfirm: async (close) => {
                                        close();
                                        onGeneratePreview();
                                    }
                                })
                            }
                            small
                            mini
                        >
                            Regenerate Salary Report
                        </Button>
                        <Button
                            className="primary small-font"
                            onClick={() =>
                                createConfirmAlert({
                                    title: "Confirm Salary Report",
                                    content:
                                        "Are you sure you want to confirm the salary report? Once confirmed, you cannot generate the report again.",
                                    onConfirm: async (close) => {
                                        close();
                                        onConfirm();
                                    }
                                })
                            }
                            disabled={isStrictCheckingChanged}
                            small
                            mini
                        >
                            Confirm Salary Report
                        </Button>
                    </>
                )}
                <div className="flex gap-05">
                    <Button className="secondary small-font" onClick={() => onDownload(DOWNLOAD_TYPE.SALARY)} isLoading={isDownloading} small mini>
                        Export Salary Copy
                    </Button>
                    <Button className="secondary small-font" onClick={() => setStartWPSDl(true)} isLoading={isDownloading} small mini>
                        Export WPS
                    </Button>
                </div>
            </div>
        );
    };

    const createBeforePaginationExtra = () => {
        if (!data.length) {
            return;
        }
        return (
            <div className="strict-checking" style={{ margin: 0, order: viewport.isMobile ? 2 : 1 }}>
                {confirmed ? (
                    <Input
                        renderValue={<span className="danger-color bold">{isStrictChecking ? "Yes" : "No"}</span>}
                        label={<span className="small-font">Strict Timing Applied</span>}
                        parentStyle={{ overflow: "unset", minWidth: "11.5rem" }}
                        tooltip={
                            <div style={{ maxWidth: "10rem", wordBreak: "break-word" }}>
                                <p style={{ width: "100%", whiteSpace: "normal", margin: 0 }}>
                                    Any timing irregularities, including late arrivals, early time-outs, and extended breaks, will be reflected in
                                    payroll calculations.
                                </p>
                            </div>
                        }
                        onChange={(e) => setStrictChecking(e.target.checked)}
                        readOnly
                        noBorder
                    />
                ) : (
                    <Input
                        type={INPUT_TYPE.CHECKBOX}
                        value={isStrictChecking}
                        label={
                            <span className="small-font semi-bold" style={{ whiteSpace: "nowrap" }}>
                                Apply Strict Timing?
                            </span>
                        }
                        parentStyle={{ overflow: "unset", minWidth: "11rem" }}
                        tooltip={
                            <div style={{ maxWidth: "10rem", wordBreak: "break-word" }}>
                                <p style={{ width: "100%", whiteSpace: "normal", margin: 0 }}>
                                    Any timing irregularities, including late arrivals, early time-outs, and extended breaks, will be reflected in
                                    payroll calculations.
                                </p>
                            </div>
                        }
                        onChange={(e) => setStrictChecking(e.target.checked)}
                        noBorder
                        disabled={confirmed}
                    />
                )}
            </div>
        );
    };

    return (
        <div className={createClass(" flex column gap-05 h100", BASE_CLASS)}>
            <div className={createClass("__header flex gap-05", BASE_CLASS)}>{renderInputDate()}</div>
            {date && (
                <div className="flex gap-05 wrap" style={{ justifyContent: "space-between", marginBottom: ".5rem" }}>
                    <div className={createClass("__title", BASE_CLASS)} style={{ marginBottom: 0 }}>
                        <h4>
                            {confirmed ? "Confirmed" : "Generated"} Salary Period for the Month of <strong>{getReadableDate()}</strong>
                        </h4>
                        {!isStrictCheckingChanged && !!data.length && !isNotYetGenerated && !isNotAllowed && !isNoRecordsAvailable ? (
                            !confirmed ? (
                                <Text className="danger-color bold">
                                    {` Click "Confirm" to finalize the report. You won't be able to regenerate after the confirmation.`}
                                </Text>
                            ) : (
                                <Text className="success-color bold">
                                    This salary sheet has been confirmed. Download a copy by clicking the button below.
                                </Text>
                            )
                        ) : isStrictCheckingChanged ? (
                            <Text className="success-color bold">
                                Click “Regenerate” to apply your change for Strict Timing in a new payroll calculation.
                            </Text>
                        ) : (
                            ""
                        )}
                    </div>
                    {totalSalaries && (
                        <div className="flex gap-05 center total-records" style={{ marginLeft: "1rem" }}>
                            <span className="fade small-font semi-bold" style={{ whiteSpace: "nowrap", lineHeight: ".5" }}>
                                Total Salary:
                            </span>
                            <Tag className="green">
                                <span className="small-font">{toProperMoneyFormat(totalSalaries, setting.currency)}</span>
                            </Tag>
                        </div>
                    )}
                </div>
            )}
            <TableView
                headers={CreateTableHeaders()}
                data={data}
                emptyProps={{
                    style: { flex: 1, margin: 0, marginTop: "-2rem" },
                    message: createEmpyMessage()
                }}
                onScrollEnd={loadMore}
                isLoading={isLoading}
                isLoadingMore={isLoadingMore}
                tableConfig={tableConfig}
                onSort={(sortBy, order) => sort({ sortBy, order })}
                beforePaginationExtra={createBeforePaginationExtra()}
                isRelativeEmpty
                isLazy
                {...rest}
                styles={{
                    ...(rest.styles || {}),
                    pagination: {
                        ...(rest.styles?.pagination || {}),
                        parent: {
                            ...(rest.styles?.pagination?.parent || {}),
                            margin: "0",
                            justifyContent: "space-between"
                        }
                    }
                }}
            />
            {createFooter()}
            {startWPSDl && <ExportWPSModal open={startWPSDl} onClose={() => setStartWPSDl(false)} />}
        </div>
    );
}

export default EmployeesSalary;
EmployeesSalary.propTypes = {
    height: PropTypes.number,
    styles: PropTypes.shape({
        pagination: PropTypes.shape({
            parent: PropTypes.object
        }),
        table: PropTypes.shape({
            parent: PropTypes.object,
            body: PropTypes.object
        })
    })
};
