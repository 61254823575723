import cloneDeep from "lodash/cloneDeep";
import { createSlice } from "@reduxjs/toolkit";

const SLICE_NAME_KEY = "department";

export const LOAD_MORE_OFFSET = 5;
export const DEFAULT_SIZE = 15;

export const defaultConfig = {
    pageSize: DEFAULT_SIZE,
    search: "",
    sortBy: "title",
    order: "ASC",
    cursor: "",
    more: LOAD_MORE_OFFSET,
    totalCount: 0
};

const initialState = {
    data: [],
    tableConfig: defaultConfig,
    current: null,
    isSearching: false,
    loading: false
};

export const designationSlice = createSlice({
    name: SLICE_NAME_KEY,
    initialState,
    reducers: {
        setSearching: (state, action) => {
            const clone = cloneDeep(state);
            clone.isSearching = action.payload;
            return clone;
        },
        setDesigData: (state, action) => {
            const clone = cloneDeep(state);
            const data = action.payload;
            Array.isArray(data) && (clone.data = data || []);
            return clone;
        },
        setDesigTableConfig: (state, action) => {
            const clone = cloneDeep(state);
            const tableConfig = action.payload;
            tableConfig && (clone.tableConfig = { ...state.tableConfig, ...tableConfig });
            return clone;
        },
        setDesigLoading: (state, action) => {
            const clone = cloneDeep(state);
            const loading = action.payload;
            typeof loading === "boolean" && loading !== state.loading && (clone.loading = loading);
            return clone;
        },
        setDesigCurrent: (state, action) => {
            const clone = cloneDeep(state);
            const current = action.payload;
            clone.current = current;
            return clone;
        },
        reset: () => initialState
    }
});

export const selectSearching = (state) => state[SLICE_NAME_KEY].isSearching;
export const selectDesigData = (state) => state[SLICE_NAME_KEY].data;
export const selectDesigTableConfig = (state) => state[SLICE_NAME_KEY].tableConfig;
export const selectDesigLoading = (state) => state[SLICE_NAME_KEY].loading;
export const selectDesigCurrent = (state) => state[SLICE_NAME_KEY].current;
export const { setSearching, setDesigData, setDesigTableConfig, setDesigLoading, setDesigCurrent, reset } = designationSlice.actions;
export default designationSlice.reducer;
