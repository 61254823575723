import React, { useState } from "react";
import PropTypes from "prop-types";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import { TOAST_TYPE, createConfirmAlert, createToast } from "../../../common/utilities/helper";
import Button from "../../../common/components/extra/Button";
import Card from "../../../common/components/extra/Card";
import Divider from "../../../common/components/extra/Divider";
import PasswordStrength from "../../../common/components/extra/passwordStrength/PasswordStrength";

const FIELDS = {
    NEW_PASSWORD: {
        name: "newPassword",
        label: "New Password"
    },
    CONFIRM_PASSWORD: {
        name: "confirmPassword",
        label: "Confirm Password"
    }
};

const INITIAL_FORM = Object.values(FIELDS).reduce((prev, curr) => ({ ...prev, [curr.name]: "" }), {});
const DESCRIPTION = `For enhanced account security, we recommend changing your password regularly.`;

function ChangePassword({ onCancel, onSubmit }) {
    const [loading, setLoading] = useState(false);
    const [config, setConfig] = useState(INITIAL_FORM);

    const updateConfig = (newConf = {}) => setConfig({ ...config, ...newConf });

    const validate = () => {
        try {
            if (!config[FIELDS.CONFIRM_PASSWORD.name]) {
                throw new Error("Confirm password is required.");
            }
            if (!config[FIELDS.NEW_PASSWORD.name]) {
                throw new Error("New password is required.");
            }
            if (config[FIELDS.NEW_PASSWORD.name] !== config[FIELDS.CONFIRM_PASSWORD.name]) {
                throw new Error("Password do not match.");
            }
            return true;
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
            return false;
        }
    };

    const handleSave = async () => {
        setLoading(true);
        try {
            if (typeof onSubmit === "function") {
                const response = await onSubmit({ newPassword: config.newPassword });
                if (response.error) {
                    throw new Error(response?.error?.error || response?.error?.data?.message || "Failed to change password. Please try again later.");
                }
            }
            typeof onCancel === "function" && onCancel();
            updateConfig(INITIAL_FORM);
            createToast("Password updated successfully.", TOAST_TYPE.SUCCESS);
        } catch (error) {
            createToast(error?.message, TOAST_TYPE.ERROR);
        } finally {
            setLoading(false);
        }
    };

    const handleChange = (e) => {
        const config = {};
        const name = e.target.name;
        const value = e.target.value;
        config[name] = value;
        updateConfig(config);
    };

    const handleCancel = () => {
        typeof onCancel === "function" && onCancel();
    };

    const renderControls = (
        <div className="flex gap-1" style={{ marginLeft: "auto" }}>
            {typeof onCancel === "function" && (
                <Button
                    options={{ type: "button" }}
                    beforeExtra={<CancelIcon style={{ color: "red" }} />}
                    onClick={handleCancel}
                    disabled={loading}
                    small
                >
                    Cancel
                </Button>
            )}
            <Button options={{ type: "submit" }} beforeExtra={<SaveIcon />} className="primary" disabled={loading} isLoading={loading} small>
                Save
            </Button>
        </div>
    );

    return (
        <Card className="box-shadow-mini">
            <form
                className="change-password flex column"
                style={{ gap: "2rem" }}
                onSubmit={(e) => {
                    e.preventDefault();
                    const isValid = validate();
                    if (isValid) {
                        createConfirmAlert({
                            title: "Save Profile",
                            content: "Are you certain about this action? It will result in password update, and once completed, cannot be reversed",
                            onConfirm: (onClose) => {
                                onClose();
                                handleSave();
                            }
                        });
                    }
                }}
            >
                <div className="flex column">
                    <Divider title="Change Password" />
                    <p className="fade small-font">{DESCRIPTION}</p>
                    <div className="flex column gap-1" style={{ gap: "2rem" }}>
                        <section className="flex column gap-05">
                            <PasswordStrength
                                name={FIELDS.NEW_PASSWORD.name}
                                label={FIELDS.NEW_PASSWORD.label}
                                value={config[FIELDS.NEW_PASSWORD.name]}
                                placeholder=""
                                onChange={handleChange}
                                isLarge={false}
                                autoFocus
                                required
                            />
                            <PasswordStrength
                                name={FIELDS.CONFIRM_PASSWORD.name}
                                label={FIELDS.CONFIRM_PASSWORD.label}
                                value={config[FIELDS.CONFIRM_PASSWORD.name]}
                                placeholder=""
                                onChange={handleChange}
                                isLarge={false}
                                required
                                noMeter
                            />
                        </section>
                    </div>
                </div>
                {renderControls}
            </form>
        </Card>
    );
}

export default ChangePassword;

ChangePassword.propTypes = {
    beforeExtra: PropTypes.any,
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func
};
