import React, { useState } from "react";
import PropTypes from "prop-types";
import { selectIndustries } from "../../common/slice";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import Input, { INPUT_TYPE } from "../../../common/components/extra/form/Input";
import Tag from "../../../common/components/extra/Tag";
import FormSection from "../../../common/components/extra/FormSection";
import useFetchCountries from "../../../common/hooks/useFetchCountries";
import Button from "../../../common/components/extra/Button";
import { STATUS_ACTIONS, UPDATE_FILE_TYPE, VIEW_MODAL_TYPE } from "./const";
import { selectCurrent } from "./slice";
import ViewModalSub from "./ViewModalSub";
import { COMPANY_FILES, COMPANY_STATUS } from "../../../common/utilities/const";
import { useFetchCompany } from "./hooks";
import { createConfirmAlert, sanitizeWords, toReadableFromDate, toReadablePhone } from "../../../common/utilities/helper";
import LetteredAvatar from "../../../common/components/extra/avatar/LetteredAvatar";
import BaseViewModal from "../../../common/components/layout/modalViewUpdateLayout/BaseViewModal";
import { checkCompanyPaymentStatus, checkCompanyStatus, createStatusActionItems, detectFileChanges } from "./helper";
import UpdateFiles from "./UpdateFiles";
import UpdateFilesModal from "./UpdateFilesModal";
import SectionCollapseWarning from "../../../common/components/extra/section/SectionCollapseWarning";

const { LICENSE, COMPANY_CONTRACT, ESTABLISHMENT_ID, LOGO } = COMPANY_FILES;

function ViewModal({ open, onClose, onEdit, data }) {
    const [openUpdateFilesModal, setOpenUpdateFilesModal] = useState(false);
    const [viewObject, setViewObject] = useState({ type: null, data: null });

    const fetchedCountries = useFetchCountries();
    const industries = useAppSelector(selectIndustries);

    const [, isLoading, { updateStatus }] = useFetchCompany({ id: data.id, cached: true });

    const current = useAppSelector(selectCurrent);
    const status = checkCompanyStatus(current?.status);
    const paymentStatus = checkCompanyPaymentStatus(current?.status);
    const detected = detectFileChanges(data);
    const timezone = current?.CompanySetting?.timezone;

    const createTimezonesDate = (date) => {
        const company = toReadableFromDate(date, timezone);
        const current = toReadableFromDate(date);
        if (company == current) {
            return { company };
        }
        return { company, current };
    };

    const COMMON_INPUT_PROPS = { type: INPUT_TYPE.TEXT, isLoading, readOnly: true };

    const createVars = (d) => {
        if (!d) return {};
        const industryType = industries.find((industry) => industry.id === d.industry_type) || {};
        const industryName = industryType.name || "";
        const country = fetchedCountries.find((fc) => fc.value === d.country);
        const license = d.uploads?.[LICENSE.key] || "";
        const logo = d.uploads?.[LOGO.key] || "";
        const isActive = d.status?.includes(COMPANY_STATUS.ACTIVE);
        const isVerified = d.status?.includes(COMPANY_STATUS.VERIFIED);
        const isPendingPayment = d.status?.includes(COMPANY_STATUS.PENDING_PAYMENT);
        return { industryName, country, license, logo, isActive, isVerified, isPendingPayment };
    };

    const { industryName, country, logo } = createVars(current || {});

    const handleViewChange = (newObject = {}) => setViewObject({ ...viewObject, ...newObject });

    const renderViewButton = (type, conf) => (
        <div>
            <Button
                options={{ style: { textAlign: "right", paddingRight: 0, textDecoration: "underline" } }}
                onClick={() => handleViewChange({ type, data: conf })}
                transparent
                small
            >
                View
            </Button>
        </div>
    );

    const createInputViewFile = (key, modalType) => {
        const filename = current?.uploads?.[key] || "";
        return (
            <Input
                label="File"
                renderValue={renderViewButton(modalType, {
                    id: data.id,
                    filename
                })}
                {...COMMON_INPUT_PROPS}
            />
        );
    };

    const renderExpiryDate = (date) => {
        return (
            <>
                <Input
                    label={createTimezonesDate(date).current ? "Expiry Date (Local)" : "Expiry Date (Same Timezone)"}
                    renderValue={createTimezonesDate(date).company}
                    {...COMMON_INPUT_PROPS}
                />
                {createTimezonesDate(date).current && (
                    <Input label={`Expiry Date (${timezone})`} renderValue={createTimezonesDate(date).current} {...COMMON_INPUT_PROPS} />
                )}
            </>
        );
    };

    const createWarningFileChanges = () => {
        if (!detected && !status.isWaitingForApproval) {
            return null;
        }
        return (
            <SectionCollapseWarning show alwaysOpen>
                <div className="flex gap-05 wrap">
                    <UpdateFiles
                        type={status.isWaitingForApproval ? UPDATE_FILE_TYPE.APPROVAL : detected.code}
                        data={current}
                        onClick={() => setOpenUpdateFilesModal(true)}
                        noInternalModal
                        forceInline
                    />
                </div>
            </SectionCollapseWarning>
        );
    };

    const handleActionItems = async (item) => {
        let newstatus = [...(current?.status || [])];
        let title = "";
        const { VERIFIED, ACTIVE, PENDING_PAYMENT } = COMPANY_STATUS;

        switch (item.id) {
            case STATUS_ACTIONS.ACTIVATE:
                newstatus.push(ACTIVE);
                title = "Activate Company";
                break;
            case STATUS_ACTIONS.DEACTIVATE:
                newstatus = newstatus.filter((stat) => stat != ACTIVE);
                title = "Deactivate Company";
                break;
            case STATUS_ACTIONS.DISAPPROVE:
                newstatus = newstatus.filter((stat) => stat != VERIFIED);
                title = "Disapprove Company";
                break;
            case STATUS_ACTIONS.COMPLETE_PAYMENT:
                newstatus = newstatus.filter((stat) => stat != PENDING_PAYMENT);
                title = "Complete Payment";
                break;
            case STATUS_ACTIONS.PENDING_PAYMENT:
                newstatus.push(PENDING_PAYMENT);
                title = "Pending Payment";
                break;
            default:
                break;
        }
        createConfirmAlert({
            title,
            content: "Are you sure? This cannot be undone.",
            onConfirm: (onClose) => {
                onClose();
                updateStatus(newstatus);
            }
        });
    };

    return (
        <BaseViewModal
            open={open}
            onClose={onClose}
            onEdit={onEdit}
            disableEdit={!current}
            moreProps={{
                items: createStatusActionItems({ statuses: current?.status || [] }),
                onSelect: handleActionItems
            }}
        >
            <div className="view-modal flex column gap-1">
                <div className="tk-employees__modal-content-view__avatar">
                    <div>
                        <LetteredAvatar
                            isLoading={isLoading}
                            name={current?.name}
                            fetchConfig={{
                                paths: [current?.id],
                                filename: logo
                            }}
                            size={60}
                            hasborder
                            isCompany
                        />
                        {!isLoading && (
                            <>
                                <div className="name flex gap-05 center">
                                    <span>{sanitizeWords(current?.name)}</span>
                                </div>
                                <Tag>{industryName}</Tag>
                            </>
                        )}
                    </div>
                </div>
                {createWarningFileChanges()}
                <div className="flex column gap-05">
                    <div className="flex gap-05 small-font" style={{ alignItems: "center" }}>
                        <span className="small-font fade">Status:</span>
                        <Tag className={status.color}>{status.label}</Tag>
                    </div>
                    {paymentStatus && (
                        <div className="flex gap-05 small-font" style={{ alignItems: "center" }}>
                            <span className="small-font fade">Payment:</span>
                            <Tag className={paymentStatus.color}>{paymentStatus.label}</Tag>
                        </div>
                    )}
                </div>
                <FormSection header="Company Details">
                    <Input label="Company ID" renderValue={current?.id} {...COMMON_INPUT_PROPS} />
                    <Input label="Email" renderValue={current?.email} {...COMMON_INPUT_PROPS} />
                    <Input label="Industry Type" renderValue={industryName} {...COMMON_INPUT_PROPS} />
                    <Input label="Employee Count" renderValue={current?.employee_count} {...COMMON_INPUT_PROPS} />
                    <Input label="Address" renderValue={current?.address} {...COMMON_INPUT_PROPS} />
                    <Input label="Country" renderValue={country?.name?.common || current?.country} {...COMMON_INPUT_PROPS} />
                </FormSection>
                <FormSection header="Contract Details">
                    <Input label="Number (ID)" renderValue={current?.contract_number} {...COMMON_INPUT_PROPS} />
                    {renderExpiryDate(current?.contract_expiry_date)}
                    {createInputViewFile(COMPANY_CONTRACT.key, VIEW_MODAL_TYPE.CONTRACT_COPY)}
                </FormSection>
                <FormSection header="License Details">
                    <Input label="Number (ID)" renderValue={current?.license_number} {...COMMON_INPUT_PROPS} />
                    {renderExpiryDate(current?.license_expiry_date)}
                    {createInputViewFile(LICENSE.key, VIEW_MODAL_TYPE.LICENSE_COPY)}
                </FormSection>
                <FormSection header="Establishment Details">
                    <Input label="Number (ID)" renderValue={current?.license_number} {...COMMON_INPUT_PROPS} />
                    {renderExpiryDate(current?.establishment_id_expiry_date)}
                    {createInputViewFile(ESTABLISHMENT_ID.key, VIEW_MODAL_TYPE.ESTABLISHMENT_ID_COPY)}
                </FormSection>
                <FormSection header="Contact Details">
                    <Input label="Contact Name" renderValue={sanitizeWords(current?.contact_name)} {...COMMON_INPUT_PROPS} />
                    <Input label="Contact Number" renderValue={toReadablePhone(current?.contact_number)} {...COMMON_INPUT_PROPS} />
                    <Input label="Contact Email" renderValue={current?.contact_email} {...COMMON_INPUT_PROPS} />
                    <Input label="Contact Position" renderValue={sanitizeWords(current?.contact_position)} {...COMMON_INPUT_PROPS} />
                </FormSection>
            </div>
            {!!viewObject.type && (
                <ViewModalSub
                    open={!!viewObject.type}
                    onChange={(bool) => handleViewChange({ type: bool ? viewObject.type : null })}
                    type={viewObject.type}
                    data={viewObject.data}
                />
            )}
            {openUpdateFilesModal && (
                <UpdateFilesModal
                    open={openUpdateFilesModal}
                    onClose={() => setOpenUpdateFilesModal(false)}
                    onFinish={() => setOpenUpdateFilesModal(false)}
                    data={current}
                    isApproval={status?.isWaitingForApproval}
                />
            )}
        </BaseViewModal>
    );
}

ViewModal.propTypes = {
    open: PropTypes.bool,
    data: PropTypes.object,
    onClose: PropTypes.func,
    onEdit: PropTypes.func
};

export default ViewModal;
