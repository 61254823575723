import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { createConfirmAlert, renderNA, sanitizeTimestamp, sanitizeWords, toReadableFromDate } from "../../../common/utilities/helper";
import Tag from "../../../common/components/extra/Tag";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectUser } from "../../common/slice";
import WarningColorSwitcher from "../../../common/components/extra/WarningColorSwitcher";

const HEADERS = {
    NAME: "name",
    TYPE: "type",
    UPDATED_AT: "updatedAt",
    CREATED_AT: "createdAt",
    ACTIONS: "actions"
};

const HEADERS_TO_ARRAY = Object.values(HEADERS);

const CreateTableHeaders = ({ onEdit, onDelete, onViewSites }) => {
    const user = useAppSelector(selectUser);
    const setting = user.Setting;
    return HEADERS_TO_ARRAY.map((head) => {
        const temp = { key: head, style: {} };
        switch (head) {
            case HEADERS.NAME: {
                temp.sortKey = "name";
                temp.label = "Name";
                temp.style.width = "20rem";
                temp.style.margin = ".5rem 0";
                temp.render = (_, row) => (
                    <div className="flex gap-1">
                        <div className="flex column gap-05 overflow-hidden">
                            <div style={{ fontWeight: "bold" }}>{sanitizeWords(row.name)}</div>
                            <div className="flex gap-05 wrap small-font" style={{ alignItems: "center" }}>
                                <span className="small-font fade">Code:</span>
                                <span className="text-ellipsis semi-bold">{row.code || renderNA("None")}</span>
                            </div>
                            <div className="flex gap-05 wrap small-font" style={{ alignItems: "center" }}>
                                <span className="small-font fade">Total Sites:</span>
                                <span className="text-ellipsis semi-bold">{row.CompanySites?.length || renderNA("None")}</span>
                            </div>
                        </div>
                        {!row.CompanySites?.length && (
                            <WarningColorSwitcher
                                tooltip={{
                                    message: "Missing sites!",
                                    className: "flex",
                                    style: { marginRight: "1rem" }
                                }}
                                warning
                            />
                        )}
                    </div>
                );
                break;
            }
            case HEADERS.TYPE: {
                temp.label = "Type";
                temp.render = (_, row) => (
                    <div className="flex column gap-05 overflow-hidden fade">
                        <Tag>{sanitizeWords(row.type)}</Tag>
                    </div>
                );
                break;
            }
            case HEADERS.UPDATED_AT: {
                temp.sortKey = "updatedAt";
                temp.label = "Updated";
                temp.render = (_, row) => (
                    <div className="flex column gap-05 overflow-hidden fade">
                        <span className="text-ellipsis small-font">
                            {sanitizeTimestamp(row.updatedAt, row.createdAt, setting.timezone) || renderNA()}
                        </span>
                    </div>
                );
                break;
            }
            case HEADERS.CREATED_AT: {
                temp.sortKey = "createdAt";
                temp.label = "Created";
                temp.render = (val) => (
                    <div className="flex column gap-05 overflow-hidden fade">
                        <span className="text-ellipsis small-font">{toReadableFromDate(val, setting.timezone)}</span>
                    </div>
                );
                break;
            }
            case HEADERS.ACTIONS: {
                temp.childStyle = {
                    display: "flex",
                    gap: ".5rem",
                    justifyContent: "flex-end",
                    paddingRight: "1rem",
                    alignItems: "center"
                };
                temp.render = (_, row) => (
                    <>
                        <EditIcon className="hover-svg" onClick={() => typeof onEdit === "function" && onEdit(row)} style={{ width: "1.4rem" }} />
                        <LocationOnIcon
                            className="hover-svg"
                            onClick={() => typeof onViewSites === "function" && onViewSites(row)}
                            style={{ width: "1.4rem" }}
                        />
                        <DeleteIcon
                            style={{ color: "red" }}
                            className="hover-svg"
                            onClick={async () => {
                                createConfirmAlert({
                                    title: "Remove Work Type",
                                    content: "Are you sure you want to remove this work type? This cannot be undone.",
                                    onConfirm: async (close) => {
                                        close();
                                        typeof onDelete === "function" && (await onDelete(row));
                                    }
                                });
                            }}
                        />
                    </>
                );
                break;
            }
            default:
                break;
        }
        return temp;
    });
};

export default CreateTableHeaders;
