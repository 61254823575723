import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apiSlice } from "../../../app/apiSlice";

const BASE_PATH = "/api/company/payment";

const END_POINTS = {
    getCompanyPayment: { method: "get", path: "get-company-payment" },
    loadAllCompanyPaymentLazy: { method: "post", path: "load-all-lazy" },
    verifyPaymentToken: { method: "post", path: "verify-token" },
    createPaymentToken: { method: "post", path: "create-token" },
    cancelPaymentToken: { method: "post", path: "cancel-token" },
    confirmPayment: { method: "post", path: "confirm-payment" },
    getSubscriptionByPayment: { method: "get", path: "get-subscription" },
    getBankTransferOptions: { method: "post", path: "bank-transfer-options" },
    getCancelBankPayment: { method: "get", path: "cancel-bank-payment" }
};

export const companyPaymentAPI = apiSlice.injectEndpoints({
    reducerPath: "companyPaymentAPI",
    baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_SERVER_URI }),
    endpoints: (builder) => {
        const temp = {};
        Object.entries(END_POINTS).forEach(([key, config]) => {
            temp[key] = builder.mutation({
                query: (queryParams = {}) => {
                    const path = `${BASE_PATH}/${config.path}`;
                    const conf = {
                        url: path,
                        method: config.method,
                        credentials: "include"
                    };
                    if (typeof queryParams.extraPath === "string" || typeof queryParams.extraPath === "number")
                        conf.url = `${path}/${queryParams.extraPath}`;
                    if (Array.isArray(queryParams.extraPaths)) conf.url = `${path}/${queryParams.extraPaths.join("/")}`;
                    if (queryParams.params) conf.params = queryParams.params;
                    if (queryParams.body) conf.body = queryParams.body;
                    return conf;
                }
            });
        });
        return temp;
    }
});

export const {
    useGetCompanyPaymentMutation,
    useLoadAllCompanyPaymentLazyMutation,
    useVerifyPaymentTokenMutation,
    useCreatePaymentTokenMutation,
    useCancelPaymentTokenMutation,
    useConfirmPaymentMutation,
    useGetBankTransferOptionsMutation,
    useGetSubscriptionByPaymentMutation,
    useGetCancelBankPaymentMutation
} = companyPaymentAPI;

export default companyPaymentAPI.reducer;
