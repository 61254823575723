import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useAppDispatch, useAppSelector } from "../../../common/hooks/reduxHooks";
import TableView from "../../../common/components/extra/table/TableView";
import { VIEW_TYPES } from "../../../common/utilities/const";
import { TOAST_TYPE, createToast } from "../../../common/utilities/helper";
import { selectUser, updateUser } from "../../common/slice";
import CreateTableHeaders from "./CreateTableHeaders";
import ViewModal from "./ViewModal";
import UpdateModal from "./UpdateModal";
import { useLoadEmployeesMutation, useDeleteEmployeeMutation, useResetDeviceMutation } from "./api";
import { selectTableConfig, selectData, setState, setLoading, selectCurrent, selectView, setCurrent, setSearching, selectSearching } from "./slice";

function Employees({ openAddModal, onAddModalClose, limitReached }) {
    const [fetching, setFetching] = useState(true);
    const [openViewModal, setOpenViewModal] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(openAddModal);
    const [selected, setSelected] = useState(null);

    const [loadEmployees] = useLoadEmployeesMutation();
    const [deleteEmployees, { isLoading: deleteEmployeesLoading }] = useDeleteEmployeeMutation();
    const [resetDevice, { isLoading: resetDeviceLoading }] = useResetDeviceMutation();

    const dispatch = useAppDispatch();
    const tableConfig = useAppSelector(selectTableConfig);
    const data = useAppSelector(selectData);
    const view = useAppSelector(selectView);
    const user = useAppSelector(selectUser);
    const current = useAppSelector(selectCurrent);
    const searching = useAppSelector(selectSearching);

    const isLoading = deleteEmployeesLoading || resetDeviceLoading;

    const fetch = async (config) => {
        if (searching) {
            return;
        }
        if (!fetching) {
            setFetching(true);
        }
        const response = await loadEmployees({ body: { ...tableConfig, ...(config || {}) } });
        if (response.data && response.data.data) {
            const totalEmployees = response.data.data.totalCount;
            dispatch(
                setState({
                    data: response.data.data.data,
                    tableConfig: {
                        ...tableConfig,
                        ...(config || {}),
                        totalPage: response.data.data.totalPage,
                        totalCount: totalEmployees
                    }
                })
            );
            // only update when we are not searching
            if (!tableConfig.search) {
                dispatch(updateUser({ totalEmployees }));
            }
        }
        if (response.error) {
            createToast("Failed to fetch data. Please try again later.", TOAST_TYPE.ERROR);
        }
        setFetching(false);
        return response;
    };

    const handleSearchFetching = async () => {
        try {
            dispatch(setSearching(true));
            await fetch();
        } finally {
            dispatch(setSearching(false));
        }
    };

    useEffect(() => {
        if (openAddModal && openAddModal !== openEditModal) {
            setOpenEditModal(openAddModal);
            setSelected(null);
            dispatch(setCurrent(null));
        }
    }, [openAddModal]);

    useEffect(() => {
        if (!data.length) fetch();
        else setFetching(false);
    }, []);

    useEffect(() => {
        handleSearchFetching();
    }, [tableConfig.search]);

    useEffect(() => {
        dispatch(setLoading(isLoading));
    }, [isLoading]);

    const handleModalBack = () => {
        setOpenViewModal(true);
        setOpenEditModal(false);
    };

    const handleModalEdit = () => {
        setOpenViewModal(false);
        setOpenEditModal(true);
    };

    const handleFinish = (newData) => {
        fetch();
        dispatch(setCurrent(newData));
        setSelected(newData);
        typeof onAddModalClose === "function" && onAddModalClose();
    };

    const handlePreview = (row) => {
        setOpenViewModal(true);
        setSelected(row);
    };

    const handleDelete = (row) => {
        deleteEmployees({ extraPath: row.id }).then(fetch);
    };

    const handleResetDevice = async (row) => {
        await resetDevice({ extraPath: row.id });
        await fetch();
        if (current && current.id == row.id) {
            dispatch(setCurrent({ ...current, device: null }));
        }
        createToast("Device has been resetted successfully", TOAST_TYPE.SUCCESS);
    };
    const handleViewModalClose = () => {
        setSelected(null);
        setOpenViewModal(false);
    };

    const handleUpdateModalClose = () => {
        typeof onAddModalClose === "function" && onAddModalClose();
        setOpenEditModal(false);
    };

    return (
        <>
            {(() => {
                switch (view) {
                    default:
                    case VIEW_TYPES.TABLE:
                        return (
                            <TableView
                                data={data}
                                headers={CreateTableHeaders({
                                    onPreview: handlePreview,
                                    onDelete: handleDelete,
                                    onResetDevice: handleResetDevice
                                })}
                                refetch={fetch}
                                height={(limitReached && 27) || null} // adjust height when limit reached to accomodate the size of the new element
                                isLoading={fetching}
                                tableConfig={tableConfig}
                            />
                        );
                }
            })()}
            {openViewModal && (
                <ViewModal
                    open={openViewModal}
                    onClose={handleViewModalClose}
                    data={selected}
                    onEdit={handleModalEdit}
                    setting={user.Setting}
                    onResetDevice={handleResetDevice}
                />
            )}
            {openEditModal && (
                <UpdateModal
                    photo={selected?.photo}
                    open={openEditModal}
                    onClose={handleUpdateModalClose}
                    onBack={handleModalBack}
                    onFinish={handleFinish}
                    data={current}
                    setting={user.Setting}
                />
            )}
        </>
    );
}

Employees.propTypes = {
    openAddModal: PropTypes.bool,
    limitReached: PropTypes.bool,
    onAddModalClose: PropTypes.func
};

export default Employees;
