import React from "react";
import { DEDUCTION_STATUS, FIELD } from "./const";
import Tag from "../../../common/components/extra/Tag";
import { toTimeWithTimeZone } from "../../../common/utilities/helper";
import moment from "moment-timezone";
import { PAYROLL_MONTH_OFFSET } from "../../../common/utilities/const";

export const createStatusTag = (status) => {
    const isCanceled = status == DEDUCTION_STATUS.CANCELED;
    const isApproved = status == DEDUCTION_STATUS.COMPLETED;
    return <Tag className={`${isCanceled ? "red" : isApproved ? "green" : "brown"}`}>{status}</Tag>;
};

export const isPastMonth = (current, timezone) => {
    if (!current) {
        return false;
    }

    const startDate = toTimeWithTimeZone(current[FIELD.START_DATE], timezone);
    const today = toTimeWithTimeZone(moment(), timezone);
    const isPastBothLess = startDate.month() + PAYROLL_MONTH_OFFSET < today.month() && startDate.year() < today.year();
    const isPastMonthLess = startDate.month() + PAYROLL_MONTH_OFFSET < today.month() && startDate.year() == today.year();
    return isPastBothLess || isPastMonthLess;
};
