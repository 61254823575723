export const FIELD = {
    NAME: "name",
    TYPE: "type",
    FIELDS: "fields",
    STATUS: "status"
};

export const FIELD_OBJECT = {
    [FIELD.NAME]: {
        label: "Name",
        default: "",
        required: true
    },
    [FIELD.TYPE]: {
        label: "Type",
        default: "",
        required: true
    },
    [FIELD.FIELDS]: {
        label: "Fields",
        default: "",
        required: true
    },
    [FIELD.STATUS]: {
        label: "Status",
        default: true,
        required: true
    }
};

export const VALIDATION_FIELD = {
    LABEL: "label",
    VALUE: "value",
    MIN: "min",
    MAX: "max",
    REQUIRED: "required"
};

export const VALIDATION_LABEL = {
    [VALIDATION_FIELD.LABEL]: "Label",
    [VALIDATION_FIELD.VALUE]: "Value",
    [VALIDATION_FIELD.MIN]: "Min",
    [VALIDATION_FIELD.MAX]: "Max",
    [VALIDATION_FIELD.REQUIRED]: "Required"
};
