import React from "react";
import PropTypes from "prop-types";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { v4 as uuidv4 } from "uuid";
import { getFieldIcon } from "./helper";
import { FORM_FIELD_TYPE } from "../../../common/utilities/const";
import SortableItem from "../../../common/components/extra/DND/SortableItem";

function Field({ children, type, data, normal }) {
    if (normal) {
        return (
            <div className="form-field" data={data}>
                <DragIndicatorIcon className="form-normal-drag-icon" />
                <div className="icon">{getFieldIcon(type)}</div>
                {children}
            </div>
        );
    }

    return (
        <SortableItem className="form-field" iconClassName="form-drag-icon" id={uuidv4()} data={data} hasOverlay>
            <div className="icon">{getFieldIcon(type)}</div>
            {children}
        </SortableItem>
    );
}

export default Field;

Field.propTypes = {
    children: PropTypes.any,
    data: PropTypes.object,
    type: PropTypes.oneOf(Object.values(FORM_FIELD_TYPE)),
    normal: PropTypes.bool
};
