import React, { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import App from "./app/App";

const strict = process.env.REACT_APP_STRICT_MODE == 1;
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    strict ? (
        <StrictMode>
            <App />
        </StrictMode>
    ) : (
        <App />
    )
);
