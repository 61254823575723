import React from "react";
import Input, { INPUT_TYPE } from "../../../../common/components/extra/form/Input";

function MobileToggle() {
    return (
        <div className="mobile-input mobile-input--toggle input-size">
            <Input type={INPUT_TYPE.TOGGLE} />
        </div>
    );
}

export default MobileToggle;
