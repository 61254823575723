import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { v4 as uuidV4 } from "uuid";
import { Map, useApiIsLoaded, useApiLoadingStatus, APILoadingStatus, useMap } from "@vis.gl/react-google-maps";
import { useAppSelector } from "../../../hooks/reduxHooks";
import { selectUser } from "../../../../features/common/slice";
import { TOAST_TYPE, createToast } from "../../../utilities/helper";
import PlaceSearch from "./PlaceSearch";
import Polygon from "./Polygon";
import DrawingManager from "./DrawingManager";
import CustomMapControls from "./CustomMapControls";
import { calculateCoordinatesCenter, getBoundsLiteral, mvcArrayGeoValues } from "./helper";
import { MAX_ZOOM_IN, MAP_TYPE_ID, OVERLAY_TYPE, COMPLETED_DRAWING_EVENTS, MAP_CONTROL_POSITION, MAP_EVENT } from "./const";
import useDetectOutsideClick from "../../../hooks/useDetectOutsideClick";
import Loader from "../Loader";

const CUSTOM_ID = uuidV4();

const DEFAULT_RPOPS = {
    zoom: MAX_ZOOM_IN - 13,
    styles: {
        parent: {
            height: "10rem",
            width: "100%"
        }
    }
};

const POLYGON_DEFAULT_OPTIONS = {
    geodesic: true,
    strokeColor: "#0052CC",
    fillColor: "#3083ff",
    strokeOpacity: 1.0,
    strokeWeight: 2
};

const FALLBACK_CENTER = {
    lat: 25.244526999999998,
    lng: 51.46556725
};

export const MAP_CHANGE_TYPE = {
    INTIIALIZED: "initialized",
    PATH_CHANGED: "path-changed"
};

function GoogleMap({ title, id, coordinates, styles, defaultZoom = 1, constraint = {}, editable, onChange, onBoundsChange, onIdle, onTilesLoaded }) {
    const ref = useRef(null);
    const ID = (id || CUSTOM_ID.split("-").shift()).toString();
    // create default center based on user country else fallback if none found
    const user = useAppSelector(selectUser);
    const latlng = { ...(user?.country?.latLng || {}) };
    if (!latlng.lat) latlng.lat = FALLBACK_CENTER.lat;
    if (!latlng.lng) latlng.lng = FALLBACK_CENTER.lng;

    // declare map variables
    const map = useMap(ID);
    const apiIsLoaded = useApiIsLoaded();
    const status = useApiLoadingStatus();
    const [isClickedOutside] = useDetectOutsideClick(ref);

    const [object, setObject] = useState({
        center: calculateCoordinatesCenter(coordinates || []) || latlng,
        map: null,
        maps: null,
        polygon: null,
        drawing: null,
        coordinates: coordinates || [],
        zoom: coordinates.length ? defaultZoom : DEFAULT_RPOPS.zoom,
        isMapLoaded: false
    });

    const POLYGON_OPTION = { ...POLYGON_DEFAULT_OPTIONS, editable, draggable: editable };
    const showDrawingManager = editable && !object.coordinates.length;
    const showPolygon = object.map && !!object.coordinates;

    const updateObject = (newObj = {}) => setObject((prev) => ({ ...prev, ...newObj }));

    useEffect(() => {
        if (!apiIsLoaded) return;
        if (!object.maps) {
            updateObject({ maps: window.google.maps });
        }
    }, [apiIsLoaded]);

    useEffect(() => {
        if (object.isMapLoaded) {
            object.coordinates.length ? goToBoundary(map) : null;
        }
    }, [object.isMapLoaded]);

    useEffect(() => {
        if (!map) return;
        if (!object.map) {
            /**
             * @description
             * BugFix: libraries such as AutoComplete dropdown is not appearing on fullscreen.
             * A quick workaround is to move the pac-container div inside the map div when entering full screen, and move it back on exit.
             * https://stackoverflow.com/questions/44850642/google-maps-autocomplete-dropdown-hidden-when-google-maps-full-screen-is-true/56712072#56712072
             */
            document.onfullscreenchange = function (event) {
                let target = event.target;
                let pacContainerElements = document.getElementsByClassName("pac-container");
                if (pacContainerElements.length > 0) {
                    let pacContainer = document.getElementsByClassName("pac-container")[0];
                    if (pacContainer.parentElement === target) {
                        document.getElementsByTagName("body")[0].appendChild(pacContainer);
                    } else {
                        target.appendChild(pacContainer);
                    }
                }
            };

            map.addListener(MAP_EVENT.BOUNDS_CHANGED, () => typeof onBoundsChange === "function" && onBoundsChange(map));
            map.addListener(MAP_EVENT.IDLE, () => typeof onIdle === "function" && onIdle(map));
            map.addListener(MAP_EVENT.TILES_LOADED, () => {
                updateObject({ isMapLoaded: true });
                typeof onTilesLoaded === "function" && onTilesLoaded(map);
            });
            updateObject({ map });
            typeof onChange === "function" &&
                onChange(
                    {
                        map,
                        maps: object.maps,
                        coordinates: object.coordinates,
                        latlangLiteral: getBoundsLiteral(object.maps, object.coordinates)
                    },
                    MAP_CHANGE_TYPE.INTIIALIZED
                );
        }
    }, [map]);

    useEffect(() => {
        if (status === APILoadingStatus.FAILED) {
            createToast("Failed to load map.", TOAST_TYPE.ERROR);
            return;
        }
    }, [status]);

    const handleDrawingComplete = (conf, type) => {
        switch (type) {
            case COMPLETED_DRAWING_EVENTS.POLYGON_COMPLETE: {
                const createdPaths = mvcArrayGeoValues(conf.getPath());
                conf.setMap(null); // remove drawn polygon
                object.drawing.setMap(null); // remove the drawing mode since we have a polygon already
                updateObject({ coordinates: createdPaths }); // add the newly created polygon
                break;
            }
            default: {
                break;
            }
        }
    };

    const handlePolygonChange = (instance, center, newpaths) => {
        const temp = { polygon: instance, coordinates: newpaths };
        if (center) temp.center = center; // when center becomes null that means polygon is being removed
        updateObject(temp);
        typeof onChange === "function" &&
            onChange(
                {
                    map: object.map,
                    coordinates: newpaths,
                    latlangLiteral: getBoundsLiteral(object.maps, newpaths)
                },
                MAP_CHANGE_TYPE.PATH_CHANGED
            );
    };

    const goToBoundary = (map) => {
        const latlangLiteral = getBoundsLiteral(object.maps, object.coordinates);
        (map || object.map).fitBounds(latlangLiteral);
        return latlangLiteral;
    };

    const handleZoomChange = (conf) => {
        // we only extracts the new zoom
        updateObject({ zoom: conf.detail.zoom });
    };

    return (
        <div ref={ref} className="tk-map" style={styles?.parent || DEFAULT_RPOPS.styles.parent}>
            {!object.isMapLoaded && <Loader hasOverlay />}
            <Map
                id={ID}
                mapId={ID}
                mapTypeId={MAP_TYPE_ID.HYBRID}
                defaultCenter={object.center}
                zoom={object.zoom}
                gestureHandling={editable ? "greedy" : "none"}
                disableDefaultUI={!editable}
                minZoom={constraint.zoomLimit}
                onZoomChanged={handleZoomChange}
                fullscreenControl
                disableDoubleClickZoom
            >
                {editable && <PlaceSearch map={object.map} maps={object.maps} />}
                {showDrawingManager && (
                    <DrawingManager
                        maps={object.maps}
                        map={object.map}
                        onChange={(instance) => updateObject({ drawing: instance })}
                        modes={[OVERLAY_TYPE.POLYGON]}
                        onComplete={handleDrawingComplete}
                        options={{
                            polygonOptions: POLYGON_OPTION
                        }}
                    />
                )}
                {showPolygon && (
                    <Polygon
                        map={object.map}
                        path={object.coordinates || []}
                        maps={object.maps}
                        onChange={handlePolygonChange}
                        options={POLYGON_OPTION}
                        enableContextMenu={editable}
                        centerTitle={title}
                        closeInfo={isClickedOutside}
                    />
                )}
                {editable && (
                    <CustomMapControls
                        position={MAP_CONTROL_POSITION.TOP_RIGHT}
                        map={object.map}
                        maps={object.maps}
                        onGoToBoundary={(!!object.coordinates.length && goToBoundary) || null}
                    />
                )}
            </Map>
        </div>
    );
}

GoogleMap.propTypes = {
    id: PropTypes.any,
    title: PropTypes.any,
    editable: PropTypes.bool,
    defaultZoom: PropTypes.number,
    onChange: PropTypes.func,
    onBoundsChange: PropTypes.func,
    onIdle: PropTypes.func,
    onTilesLoaded: PropTypes.func,
    styles: PropTypes.shape({
        parent: PropTypes.object,
        marker: PropTypes.object
    }),
    coordinates: PropTypes.arrayOf(
        PropTypes.shape({
            lat: PropTypes.number,
            lng: PropTypes.number,
            text: PropTypes.any
        })
    ),
    constraint: PropTypes.shape({
        zoomLimit: PropTypes.number
    })
};

export default GoogleMap;
