import React, { useState } from "react";
import PropTypes from "prop-types";
import { ReactComponent as ID_SVG } from "../../../assets/images/id-icon.svg";
import Modal from "../../../common/components/extra/Modal";
import Group from "../../../common/components/extra/Group";
import Input, { INPUT_TYPE } from "../../../common/components/extra/form/Input";
import { TOAST_TYPE, createConfirmAlert, createToast } from "../../../common/utilities/helper";
import ViewModalSub from "./ViewModalSub";
import { FIELDS, UPLOAD_FIELDS, VIEW_MODAL_TYPE } from "./const";
import { useUpdateCompanies, useUploadCompanyFiles } from "./hooks";
import { COMPANY_STATUS } from "../../../common/utilities/const";

const { LICENCE_NUMBER, LICENCE_EXPIRY, CONTRACT_NUMBER, CONTRACT_EXPIRY, ESTABLISHMENT_ID, ESTABLISHMENT_ID_EXPIRY } = FIELDS;
const { LICENSE: LICENSE_COPY, COMPANY_CONTRACT: COMPANY_CONTRACT_COPY, ESTABLISHMENT_ID: ESTABLISHMENT_ID_COPY } = UPLOAD_FIELDS;

function UpdateFilesModal({ open, onClose, onFinish, data = {}, isApproval, ...rest }) {
    const [viewObject, setViewObject] = useState({ type: null, data: null });

    const [form, setForm, updateCompanyLoading, onFormSave, setMobileCode, hasFormChanges] = useUpdateCompanies({
        id: data?.id,
        initial: data || {}
    });
    const [uploads, setUploads, uploadFilesLoading, onStartUpload, hasUploadChanges] = useUploadCompanyFiles({
        id: data?.id,
        initial: data?.uploads || {}
    });

    const isLoading = uploadFilesLoading || updateCompanyLoading;
    const timezone = data?.CompanySetting?.timezone;

    const handleUploadsChange = (e) => {
        setUploads({
            ...uploads,
            [e.target.name]: e.target?.files?.[0]
        });
    };

    const handleSave = async () => {
        let temp = {
            last_file_upload_date: null,
            last_renewal_date: null,
            status: [...form.status]
        };
        if (isApproval && !temp.status.includes(COMPANY_STATUS.VERIFIED)) {
            temp.status = [...temp.status, COMPANY_STATUS.VERIFIED];
        }
        try {
            const res1 = await onFormSave(temp);
            if (res1.error) {
                throw new Error(res1.error);
            }
            const res2 = await onStartUpload(res1);
            if (res2.error) {
                throw new Error(res2.error);
            }
            onFinish({ ...res1, uploads: res2 });
        } catch (error) {
            createToast(error.message || "Failed to update company. Please try again later or contact support.", TOAST_TYPE.ERROR);
        }
    };

    const handleViewChange = (newObject = {}) => setViewObject({ ...viewObject, ...newObject });

    const handleChange = (e) => {
        const target = e.target;
        const config = {
            ...form,
            [target.name]: target.value
        };
        if (target?.mobileCode) {
            setMobileCode(target?.mobileCode);
        }
        setForm(config);
    };

    const createInputFile = (fileObject = {}, modalType, icon = <ID_SVG />) => {
        const isNotFileObject = typeof uploads[fileObject.key] === "string";
        return (
            <Input
                type={INPUT_TYPE.UPLOAD}
                name={fileObject.key}
                icon={icon}
                accept={fileObject.accepts}
                sizeLimit={fileObject.size}
                label={fileObject.label}
                onChange={handleUploadsChange}
                onView={() =>
                    handleViewChange({
                        type: modalType,
                        data: {
                            id: data?.id,
                            src: (!isNotFileObject && uploads[fileObject.key] && URL.createObjectURL(uploads[fileObject.key])) || "",
                            filename: isNotFileObject ? uploads[fileObject.key] : uploads[fileObject.key]?.name
                        }
                    })
                }
                value={isNotFileObject ? true : uploads[fileObject.key]}
                hidelabel
                required
            />
        );
    };

    return (
        <Modal
            title="Update Company Files"
            open={open}
            onClose={onClose}
            onSave={() =>
                createConfirmAlert({
                    title: "Update Company Files",
                    content: "Are you sure you want to proceed? This cannot be undone.",
                    onConfirm: async (close) => {
                        close();
                        await handleSave();
                    }
                })
            }
            styles={{
                content: {
                    minWidth: "30vw",
                    margin: "1rem"
                }
            }}
            disableSave={(!hasFormChanges && !hasUploadChanges) || isLoading}
            isLoading={isLoading}
            saveLabel={isApproval ? "Approve" : "Save"}
            saveClassName={isApproval ? "success" : ""}
            isForm
            small
            isPortal
            {...rest}
        >
            <div className="update-modal">
                <div className="update-modal__inner flex column gap-1" style={{ padding: "1rem 0" }}>
                    <Group title="Contract Details">
                        <Input
                            type={INPUT_TYPE.TEXT}
                            label="Number (ID)"
                            name={CONTRACT_NUMBER.name}
                            value={form[CONTRACT_NUMBER.name]}
                            onChange={handleChange}
                            required={!isApproval && CONTRACT_NUMBER.required}
                        />
                        <Input
                            type={INPUT_TYPE.DATE}
                            label="Expiry"
                            name={CONTRACT_EXPIRY.name}
                            selected={form[CONTRACT_EXPIRY.name]}
                            onChange={(date) => handleChange({ target: { name: CONTRACT_EXPIRY.name, value: date } })}
                            required={!isApproval && CONTRACT_EXPIRY.required}
                            timezone={timezone}
                            subtext={{ message: <span className="fade">{`Timezone: ${timezone}`}</span> }}
                            noPast
                        />
                        {createInputFile(COMPANY_CONTRACT_COPY, VIEW_MODAL_TYPE.CONTRACT_COPY)}
                    </Group>
                    <Group title="License Details">
                        <Input
                            type={INPUT_TYPE.TEXT}
                            label="Number (ID)"
                            name={LICENCE_NUMBER.name}
                            value={form[LICENCE_NUMBER.name]}
                            onChange={handleChange}
                            required={LICENCE_NUMBER.required}
                        />
                        <Input
                            type={INPUT_TYPE.DATE}
                            label="Expiry"
                            name={LICENCE_EXPIRY.name}
                            selected={form[LICENCE_EXPIRY.name]}
                            onChange={(date) => handleChange({ target: { name: LICENCE_EXPIRY.name, value: date } })}
                            required={LICENCE_EXPIRY.required}
                            timezone={timezone}
                            subtext={{ message: <span className="fade">{`Timezone: ${timezone}`}</span> }}
                            noPast
                        />
                        {createInputFile(LICENSE_COPY, VIEW_MODAL_TYPE.LICENSE_COPY)}
                    </Group>
                    <Group title="Establishment Details">
                        <Input
                            type={INPUT_TYPE.TEXT}
                            label="Number (ID)"
                            name={ESTABLISHMENT_ID.name}
                            value={form[ESTABLISHMENT_ID.name]}
                            onChange={handleChange}
                            required={ESTABLISHMENT_ID.required}
                        />
                        <Input
                            type={INPUT_TYPE.DATE}
                            label="Expiry"
                            name={ESTABLISHMENT_ID_EXPIRY.name}
                            selected={form[ESTABLISHMENT_ID_EXPIRY.name]}
                            onChange={(date) => handleChange({ target: { name: ESTABLISHMENT_ID_EXPIRY.name, value: date } })}
                            required={ESTABLISHMENT_ID_EXPIRY.required}
                            timezone={timezone}
                            subtext={{ message: <span className="fade">{`Timezone: ${timezone}`}</span> }}
                            noPast
                        />
                        {createInputFile(ESTABLISHMENT_ID_COPY, VIEW_MODAL_TYPE.ESTABLISHMENT_ID_COPY)}
                    </Group>
                </div>
            </div>
            {!!viewObject.type && (
                <ViewModalSub
                    open={!!viewObject.type}
                    onChange={(bool) => handleViewChange({ type: bool ? viewObject.type : null })}
                    type={viewObject.type}
                    data={viewObject.data}
                />
            )}
        </Modal>
    );
}

UpdateFilesModal.propTypes = {
    open: PropTypes.bool,
    data: PropTypes.object,
    onClose: PropTypes.func,
    onFinish: PropTypes.func,
    onBack: PropTypes.func,
    isApproval: PropTypes.bool
};

export default UpdateFilesModal;
