import React from "react";
import useNavigate from "../../../common/hooks/useNavigate";
import { useLogoutMutation } from "../../common/api";
import FormAuthTemplate from "../../../common/components/layout/FormAuthTemplate";
import Navigation from "../../../common/classes/Navigation";

function WaitingApproval() {
    const navigate = useNavigate();
    const [logout] = useLogoutMutation();

    return (
        <div className="tk-waiting-approval">
            <div className="tk-waiting-approval__inner">
                <FormAuthTemplate
                    title="Waiting for Approval"
                    subtext="Kindly await confirmation of your account's approval."
                    footer={
                        <div className="tk-waiting-approval__footer flex gap-05 center">
                            Need help?
                            <strong>
                                <a href="mailto:support@time-keeper.app">Contact customer support</a>
                            </strong>
                        </div>
                    }
                    submitLabel="Goto Login"
                    onSubmit={() => logout().then(() => navigate(Navigation.Routes.SIGNIN.path))}
                ></FormAuthTemplate>
            </div>
        </div>
    );
}

export default WaitingApproval;
