import React from "react";
import PropTypes from "prop-types";
import { SHIFT_TYPE, WORK_HISTORY_SHIFT_STATUS } from "../../../common/utilities/const";
import Tag from "../../../common/components/extra/Tag";
import { sanitizeWords } from "../../../common/utilities/helper";

const {
    ON_TIME,
    LATE,
    FIRST_SHIFT_DONE,
    SECOND_SHIFT_DONE,
    ON_TIME_SECOND_SHIFT,
    LATE_SECOND_SHIFT,
    UNDERTIME,
    OVERTIME,
    OVERTIME_DONE,
    HALF_DAY,
    OVER_BREAK
} = WORK_HISTORY_SHIFT_STATUS;

export function Shift({ label, timeInOne, timeOutOne, isShiftDone, isOnTime, isLate, hide, isIncomplete, simple, dontShowEmpty }) {
    const status = isOnTime ? ON_TIME : isLate ? LATE : "";
    const className = status == ON_TIME ? "green" : "red";
    const noShift = !timeInOne && !timeOutOne;
    const parentClassName = noShift || isIncomplete ? "red" : !isShiftDone ? "yellow" : "";

    let firstText = isShiftDone ? `${label} Completed` : isIncomplete ? `Incomplete` : `Ongoing ${label}`;

    !timeInOne && !timeOutOne && (firstText = `No ${label}`);

    if (hide) {
        return <></>;
    }

    if (dontShowEmpty && !status) {
        return <></>;
    }

    if (simple && !noShift) {
        return (
            <>
                <Tag className={className}>
                    <span>{sanitizeWords(firstText)}</span>
                </Tag>
                <Tag className={className}>
                    <span>{sanitizeWords(status)}</span>
                </Tag>
            </>
        );
    }

    return (
        <Tag className={`flex gap-05 ${parentClassName}`.trim()} style={{ alignItems: "center" }}>
            <span className="small-font" style={{ textTransform: "capitalize" }}>
                {firstText}
            </span>
            {!noShift && (
                <>
                    <span>-</span>
                    <Tag className={className}>
                        <span>{sanitizeWords(status)}</span>
                    </Tag>
                </>
            )}
        </Tag>
    );
}

export function Overtime({ isOTStart, isOTDone, simple, transform }) {
    const firstText = "Overtime";
    let status = isOTStart && !isOTDone ? "Ongoing" : "Completed";
    const onGoingStatus = status == "Ongoing";
    const className = onGoingStatus ? "yellow" : "green";
    if (!isOTStart) {
        return <></>;
    }
    if (!status) {
        return <></>;
    }

    if (typeof transform == "function") {
        status = transform(status);
    }

    if (simple) {
        return (
            <Tag className={className}>
                <span>{sanitizeWords(status)}</span>
            </Tag>
        );
    }
    return (
        <Tag className="flex gap-05" style={{ alignItems: "center" }}>
            <span className="small-font" style={{ textTransform: "capitalize" }}>
                {firstText}
            </span>
            <span>-</span>
            <Tag className={className}>
                <span>{sanitizeWords(status)}</span>
            </Tag>
        </Tag>
    );
}

export function Others({ statuses }) {
    const items = statuses.map((status) => {
        let classname = "";
        switch (status) {
            default:
            case UNDERTIME:
            case HALF_DAY:
            case OVER_BREAK: {
                classname = "yellow";
                break;
            }
            case OVERTIME: {
                classname = "brown";
                break;
            }
        }
        return (
            <Tag key={status} className={classname}>
                <span className="text-ellipsis">{sanitizeWords(status)}</span>
            </Tag>
        );
    });
    return <div className="flex gap-05 wrap">{items}</div>;
}

function ShiftStatus({ style, className, statuses, shiftType, shiftOne, shiftTwo }) {
    const isSplit = shiftType == SHIFT_TYPE.SPLIT;
    const onGoingShiftOne = !shiftOne.isIncomplete && shiftOne.timeInOne && !statuses.includes(FIRST_SHIFT_DONE);

    return (
        <div className={`flex column gap-05 ${className}`} style={style}>
            <Shift
                label={isSplit ? "Shift 1" : "Shift"}
                timeInOne={shiftOne.timeInOne}
                timeOutOne={shiftOne.timeOutOne}
                isShiftDone={statuses.includes(FIRST_SHIFT_DONE)}
                isOnTime={statuses.includes(ON_TIME)}
                isLate={statuses.includes(LATE)}
                isIncomplete={shiftOne.isIncomplete}
            />
            {(!onGoingShiftOne || shiftOne.isIncomplete) && (
                <Shift
                    label={"Shift 2"}
                    timeInOne={shiftTwo.timeInTwo}
                    timeOutOne={shiftTwo.timeOutTwo}
                    isShiftDone={statuses.includes(SECOND_SHIFT_DONE)}
                    isOnTime={statuses.includes(ON_TIME_SECOND_SHIFT)}
                    isLate={statuses.includes(LATE_SECOND_SHIFT)}
                    isIncomplete={shiftTwo.isIncomplete}
                    hide={!isSplit}
                />
            )}
            <Overtime isOTStart={statuses.includes(OVERTIME)} isOTDone={statuses.includes(OVERTIME_DONE)} />
            <Others statuses={[...new Set(statuses.filter((status) => [HALF_DAY, OVER_BREAK, UNDERTIME].includes(status)))]} />
        </div>
    );
}

export default ShiftStatus;

ShiftStatus.propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
    statuses: PropTypes.arrayOf(PropTypes.oneOf(Object.values(WORK_HISTORY_SHIFT_STATUS))),
    shiftType: PropTypes.string,
    shiftOne: PropTypes.shape({
        timeInOne: PropTypes.any,
        timeOutOne: PropTypes.any,
        isIncomplete: PropTypes.bool
    }),
    shiftTwo: PropTypes.shape({
        timeInTwo: PropTypes.any,
        timeOutTwo: PropTypes.any,
        isIncomplete: PropTypes.bool
    })
};

Shift.propTypes = {
    label: PropTypes.string,
    timeInOne: PropTypes.any,
    timeOutOne: PropTypes.any,
    isShiftDone: PropTypes.bool,
    isOnTime: PropTypes.bool,
    isLate: PropTypes.bool,
    hide: PropTypes.bool,
    isIncomplete: PropTypes.bool,
    simple: PropTypes.bool,
    dontShowEmpty: PropTypes.bool
};

Overtime.propTypes = {
    isOTStart: PropTypes.bool,
    isOTDone: PropTypes.bool,
    simple: PropTypes.bool,
    transform: PropTypes.func
};

Others.propTypes = {
    statuses: PropTypes.arrayOf(PropTypes.oneOf(Object.values(WORK_HISTORY_SHIFT_STATUS)))
};
