import React from "react";
import CompanyPenalties from "../companyPenalties/CompanyPenalties";
import { ROUTE_TYPE } from "../companyPenalties/const";

function PenaltiesTab() {
    return (
        <div className="penalties-tab flex column gap-1">
            <CompanyPenalties type={ROUTE_TYPE.LAW} />
            <CompanyPenalties type={ROUTE_TYPE.CUSTOM} />
        </div>
    );
}

export default PenaltiesTab;
