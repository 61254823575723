import React, { useState } from "react";
import WorkHistories from "../../features/company/employeeWorkHistories/WorkHistories";
import { useAppDispatch, useAppSelector } from "../../common/hooks/reduxHooks";
import { selectLoading, selectTableConfig, setTableConfig } from "../../features/company/employeeWorkHistories/slice";
import { selectUser } from "../../features/common/slice";
import PageLayout from "../../common/components/layout/PageLayout";
import Filter from "../../features/company/employeeWorkHistories/Filter";
import { usePaginateWorkHistories } from "../../features/company/employeeWorkHistories/hooks";

function EmployeesWorkHistories() {
    const [openAddModal, setOpenAddModal] = useState(false);

    const [, { isSearching, onFilter }] = usePaginateWorkHistories();

    const dispatch = useAppDispatch();
    const loading = useAppSelector(selectLoading);
    const user = useAppSelector(selectUser);
    const config = useAppSelector(selectTableConfig);

    const handleSearch = (value) => dispatch(setTableConfig({ search: (value && value.toLowerCase().trim()) || "" }));
    const handleAdd = () => setOpenAddModal(true);

    const handleFilter = (filter) => onFilter(filter);

    return (
        <PageLayout
            className="tk-employees-work-history"
            title="Manage Work History"
            isLoading={loading}
            search={{ onSearch: handleSearch, value: config.search }}
            onAdd={handleAdd}
            filter={<Filter onFilter={handleFilter} isLoading={isSearching} />}
            styles={{
                body: {
                    height: "calc(100vh - 20.5rem)"
                }
            }}
            hasFilter
        >
            <WorkHistories user={user} openAddModal={openAddModal} onAddModalClose={() => setOpenAddModal(false)} />
        </PageLayout>
    );
}

export default EmployeesWorkHistories;
