import React from "react";
import Payments from "../companyPayment/Payments";
import SubscriptionInfo from "../companySubscription/SubscriptionInfo";

function BillingTab() {
    return (
        <div className="billing-tab flex column gap-1">
            <SubscriptionInfo />
            <Payments />
        </div>
    );
}

export default BillingTab;
