import React from "react";
import PropTypes from "prop-types";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import NormalCircleIcon from "@mui/icons-material/RadioButtonUnchecked";
import ErrorIcon from "@mui/icons-material/Cancel";
import CompletedIcon from "@mui/icons-material/Verified";
import { STEP_TYPE } from "./const";

function StepLine({ step, type = STEP_TYPE.NORMAL, hasNext, loading, active, styles = {} }) {
    const { ERROR, CHECK, NORMAL, COMPLETED } = STEP_TYPE;
    let classname = "",
        render = "";
    switch (type) {
        default:
        case NORMAL: {
            render = <NormalCircleIcon />;
            if (active) {
                classname = "checked";
            }
            break;
        }
        case CHECK: {
            render = <CheckCircleIcon />;
            classname = "checked";
            break;
        }
        case COMPLETED: {
            render = <CompletedIcon />;
            classname = "completed";
            break;
        }
        case ERROR: {
            render = <ErrorIcon />;
            classname = "error";
            break;
        }
    }
    return (
        <div className={`steps__item ${classname || ""}`.trim()} style={styles.item || {}}>
            <div className="steps__item__type">
                {render}
                {step && step.name && <span className="label">{step.name}</span>}
                {type === NORMAL && <span className="step-num">{step.stepNumber}</span>}
            </div>
            {hasNext && (
                <div className="steps__item__line">
                    <div className="loading" style={{ width: typeof loading === "number" ? loading + "%" : "0%" }}></div>
                    <div className="placeholder"></div>
                </div>
            )}
        </div>
    );
}

export default StepLine;

StepLine.propTypes = {
    step: PropTypes.shape({
        stepNumber: PropTypes.number,
        name: PropTypes.string
    }),
    type: PropTypes.oneOf(Object.values(STEP_TYPE)),
    hasNext: PropTypes.bool,
    active: PropTypes.bool,
    loading: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    styles: PropTypes.shape({
        item: PropTypes.object
    })
};
