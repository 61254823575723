import React, { useState } from "react";
import cloneDeep from "lodash/cloneDeep";
import StepWizard from "../../../common/components/extra/stepWizard/StepWizard";
import { useAppDispatch, useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectToken } from "../../common/slice";
import ExcelFile from "./BulkUploadExcelFile";
import RequiredFiles from "./BulkUploadRequiredFiles";
import CheckDuplicates from "./BulkUploadCheckDuplicates";
import Result from "./BulkUploadResult";
import { setCurrent, setData, selectData, selectCurrent } from "../employees/slice";

const STEP = {
    STEP_1: { name: "Upload Excel File", step: 1 },
    STEP_2: { name: "Upload Required Files", step: 2, optional: true, autoSkip: true },
    STEP_3: { name: "Checking for Duplicate", step: 3 },
    STEP_4: { name: "Show Result", step: 4 }
};

function BulkUpload() {
    const { STEP_1, STEP_2, STEP_3, STEP_4 } = STEP;

    const [step, setStep] = useState(null);
    const [bulkData, setBulkData] = useState({ 1: null, 2: null, 3: null, 4: null });
    const [step1ForceRendererKey, setStep1ForceRendererKey] = useState(0);
    const [step2ForceRendererKey, setStep2ForceRendererKey] = useState(0);
    const [ignoredRows, setIgnoredRows] = useState([]);

    const dispatch = useAppDispatch();
    const token = useAppSelector(selectToken);
    const employees = useAppSelector(selectData);
    const currentSelectedEmployee = useAppSelector(selectCurrent);
    const steps = Object.values(STEP);

    const forceRemount = (func) => func((prevKey) => prevKey + 1);
    const updateData = (obj = {}) => setBulkData({ ...bulkData, ...obj });

    const onStepWizardChange = (step) => {
        if (!bulkData[STEP_2.step] && step?.stepNumber === STEP_1.step) {
            // if there is no data on step 2 and we go back to step 1 we will remount step 2 so that it will clear if the user has unprocessed file
            forceRemount(setStep2ForceRendererKey);
        }
        setStep(step);
    };

    const handleBulk = (k, value) => {
        const isStepOne = k === STEP_1.step;
        const temp = cloneDeep(bulkData);
        temp[k] = isStepOne ? value.employees : value;
        const isSkipStepTwo = bulkData[STEP_2.step]?.skip;
        // wait for the process when no data that means this is a new data and a fresh one
        if (!bulkData[k] || isSkipStepTwo) {
            if (value.hasWarning) {
                updateStep({ warning: true });
            } else {
                updateStep({ isDone: true, cached: true });
            }
            updateData(temp);
        }
        if (isStepOne) {
            setIgnoredRows(value.ignoredEmployees);
        }
        if (k == STEP_4.step || isStepOne) {
            employees.length && dispatch(setData([]));
            currentSelectedEmployee && dispatch(setCurrent(null));
        }
    };

    const updateStep = (newStep = {}) => setStep((prev) => ({ ...prev, ...newStep }));

    const resetBulkData = () => setBulkData({ 1: null, 2: null, 3: null, 4: null });

    const handleRetryProcess = () => {
        updateStep({ retry: true });
        resetBulkData();
    };

    const handleExcelUpload = () => {
        // when the user upload an excel again we reset the data from step 2, 3 and 4.
        resetBulkData();
    };

    const handleProcessCancel = () => {
        resetBulkData();
        forceRemount(setStep1ForceRendererKey);
    };

    const handleRemoveUploads = (cur) => {
        updateData({ [cur]: null });
    };

    const renderSwitchStep = () => {
        return [
            <ExcelFile
                key={"s1" + step1ForceRendererKey}
                token={token}
                currentData={bulkData[STEP_1.step]}
                step={cloneDeep(step)}
                updateStep={updateStep}
                onSuccess={(value) => handleBulk(STEP_1.step, value)}
                active={step?.stepNumber === STEP_1.step}
                onUpload={handleExcelUpload}
                onRemoveUploads={() => handleRemoveUploads(STEP_1.step)}
            />,
            bulkData[STEP_1.step] && (
                <RequiredFiles
                    key={"s2" + step2ForceRendererKey}
                    token={token}
                    currentData={bulkData[STEP_2.step]}
                    step={cloneDeep(step)}
                    updateStep={updateStep}
                    employees={bulkData[STEP_1.step]}
                    onSuccess={(value) => handleBulk(STEP_2.step, value)}
                    active={step?.stepNumber === STEP_2.step}
                    onRemoveUploads={() => handleRemoveUploads(STEP_2.step)}
                />
            ),
            step?.stepNumber === STEP_3.step && (
                <CheckDuplicates
                    key="step3"
                    token={token}
                    currentData={bulkData[STEP_3.step]}
                    includeUploads={!bulkData[STEP_2.step]?.skip}
                    step={cloneDeep(step)}
                    updateStep={updateStep}
                    employees={bulkData[STEP_1.step]}
                    onSuccess={(value) => handleBulk(STEP_3.step, value)}
                    active={step?.stepNumber === STEP_3.step}
                />
            ),
            step?.stepNumber === STEP_4.step && (
                <Result
                    key="step4"
                    token={token}
                    currentData={bulkData[STEP_4.step]}
                    step={cloneDeep(step)}
                    updateStep={updateStep}
                    result={bulkData}
                    ignoredRows={ignoredRows}
                    onSuccess={(value) => handleBulk(STEP_4.step, value)}
                    onRetry={handleRetryProcess}
                    active={step?.stepNumber === STEP_4.step}
                />
            )
        ];
    };

    const handleSkip = (conf) => {
        updateData({ [conf.step.stepNumber]: { skip: true } });
    };

    return (
        <div className="tk-bulk-upload">
            <StepWizard
                step={step}
                steps={steps.map((st) => st.name)}
                onChange={onStepWizardChange}
                canNext={(step?.cached && !!bulkData[step?.stepNumber]) || step?.canNext}
                resetCached={!Object.values(bulkData).filter(Boolean).length}
                onCancel={handleProcessCancel}
                optionals={steps.filter((st) => st.optional).map((st) => st.step)}
                autoSkips={steps.filter((st) => st.autoSkip).map((st) => st.step)}
                onSkip={handleSkip}
            >
                {renderSwitchStep()}
            </StepWizard>
        </div>
    );
}

export default BulkUpload;
