import React from "react";
import { selectTableConfig, setCurrent } from "./slice";
import { useAppDispatch, useAppSelector } from "../../../common/hooks/reduxHooks";
import CreateTableHeaders from "./CreateTableHeaders";
import TableView from "../../../common/components/extra/table/TableView";
import { useDeleteCompanyForm, usePaginateCompanyForm } from "./hooks";
import { TABLE_ACTION_TYPE_COMMON } from "../../../common/utilities/const";
import useNavigate from "../../../common/hooks/useNavigate";
import Navigation from "../../../common/classes/Navigation";

function CompanyForms() {
    const [data, { isLoading: isPaginating, fetch }] = usePaginateCompanyForm();
    const [remove] = useDeleteCompanyForm();

    const dispatch = useAppDispatch();
    const tableConfig = useAppSelector(selectTableConfig);
    const navigate = useNavigate();

    const handleAction = async (row, actionType) => {
        switch (actionType) {
            case TABLE_ACTION_TYPE_COMMON.EDIT:
                dispatch(setCurrent(row));
                navigate(Navigation.Routes.COMPANY_FORM_UPDATE.path.replace(":id", row.id));
                break;
            case TABLE_ACTION_TYPE_COMMON.REMOVE:
                await remove(row.id).then(fetch);
                break;
            default:
                break;
        }
    };

    return (
        <>
            <TableView
                data={data}
                headers={CreateTableHeaders({ onAction: handleAction })}
                refetch={fetch}
                tableConfig={tableConfig}
                isLoading={isPaginating}
            />
        </>
    );
}

CompanyForms.propTypes = {};

export default CompanyForms;
