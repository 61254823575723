import firebase from "firebase/compat/app";
import "firebase/compat/database";

import firebaseConfigDev from "../config/firebaseConfig.dev.json";
import firebaseConfigProd from "../config/firebaseConfig.prod.json";

let firebaseConfig;
if (process.env.NODE_ENV === "production") {
    firebaseConfig = firebaseConfigProd;
} else {
    firebaseConfig = firebaseConfigDev;
}

firebase.initializeApp(firebaseConfig);
const database = firebase.database();
export default database;
