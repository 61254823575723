import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Select from "../select/Select";
import { DATE_FILTER } from "../../../utilities/const";
import { toFilterOptions } from "../../../utilities/helper";
import DatePickerRange from "../dateTime/DatePickerRange";

function DateFilter(props) {
    const [value, setValue] = useState("");
    const [options] = useState(
        toFilterOptions(DATE_FILTER).map((opt) => {
            if (!opt.value) {
                opt.label = "All Time";
            }
            return {
                value: opt.value,
                label: <span className="bold small-font">{opt.label}</span>
            };
        })
    );

    useEffect(() => {
        setValue(props.filterProps.value);
    }, [props.filterProps.value]);

    const isCustomFilter = value == DATE_FILTER.CUSTOM;

    const getValue = () => {
        const found = options.find((opt) => opt.value == value) || {};

        return {
            ...found,
            label: (
                <span className="bold small-font">
                    {found.label} {props.label && <span className="fade">({props.label})</span>}
                </span>
            )
        };
    };

    const handleChange = (newValue) => {
        setValue(newValue);
        typeof props.filterProps.onChange == "function" && props.filterProps.onChange(newValue);
    };

    return (
        <div className="tk-date-filter flex gap-05">
            <Select
                {...(props?.filterProps || {})}
                options={options}
                value={getValue()}
                onChange={(conf) => handleChange(conf.value)}
                placeholder="Date"
                isClearable={!!value}
            />
            {isCustomFilter && <DatePickerRange {...(props?.dateProps || {})} isFixed />}
        </div>
    );
}

export default DateFilter;

DateFilter.propTypes = {
    filterProps: PropTypes.object,
    dateProps: PropTypes.object,
    label: PropTypes.string,
    isMonthYear: PropTypes.bool
};
