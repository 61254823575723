import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { ReactComponent as CheckSVG } from "../../../assets/images/check-lg.svg";

function Checkbox({ name, checked, onChange, style, disabled, ...inputProps }) {
    const inputRef = useRef(null);
    const [isChecked, setChecked] = useState(false);

    useEffect(() => {
        if (isChecked !== checked) {
            setChecked(checked);
        }
    }, [checked]);

    const handleClick = () => {
        if (disabled) return;
        inputRef.current && inputRef.current.click();
    };

    const handleChange = (e) => {
        const checked = !!e.target.checked;
        setChecked(checked);
        typeof onChange === "function" && onChange(checked);
    };

    let className = "tk-checkbox flex center";
    if (isChecked) className = className + ((isChecked && " active") || "");
    if (disabled) className = className + (" disabled" || "");

    return (
        <div className={className} onClick={handleClick} style={style || {}} tabIndex={-1}>
            <input
                ref={inputRef}
                type="checkbox"
                name={name}
                onChange={handleChange}
                checked={!!isChecked}
                value={isChecked || ""}
                disabled={disabled}
                {...inputProps}
            />
            {isChecked && <CheckSVG color="white" />}
        </div>
    );
}

Checkbox.propTypes = {
    name: PropTypes.string,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    style: PropTypes.object
};

export default Checkbox;
