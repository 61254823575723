import React, { useState } from "react";
import PropTypes from "prop-types";
import cloneDeep from "lodash/cloneDeep";
import TableList from "../../../common/components/extra/table/TableList";
import Tag from "../../../common/components/extra/Tag";
import { SORT_ORDER } from "../../../common/utilities/const";
import Button from "../../../common/components/extra/Button";
import { flattenObject, isValid, performNativeSort } from "../../../common/utilities/helper";
import Checkbox from "../../../common/components/extra/Checkbox";
import SelectChangesModal from "./SelectChangesModal";
import { STATUS } from "../employees/const";

const statuses = Object.values(STATUS);

function ManageDuplicates({ employees = [], setEmployees, onKeepUploads, keepUploads, isAllKeepOriginal, toBeInserted, includeUploads }) {
    const [selected, setSelected] = useState(null);
    const [sort, setSort] = useState({ sortBy: "id", order: SORT_ORDER.ASC });

    const trackLogs = {
        keep: employees.filter((emp) => emp.keepOriginal).map((emp) => emp.id),
        modified: employees.filter((emp) => emp.modified).map((emp) => emp.id)
    };

    const handleCheckChange = (value, keys, newKeys, type, ids) => {
        let temp = newKeys;
        if (newKeys?.keep) temp = newKeys.keep;
        let newemps = employees.map((emp) => {
            if (!ids.includes(emp.id)) {
                return emp;
            }
            const keepOriginal = temp.includes(emp.id);
            const defDuplicateWithAccepted = emp.duplicates.map((dup) => {
                if (!dup?.accepted) dup = { ...dup, accepted: {} };
                const checked = !!value;
                if (!checked) {
                    // get the first duplicate changes as the default accepted values
                    const firstDuplicate = emp.duplicates?.[0] || {};
                    const accepted = flattenObject({
                        bank: firstDuplicate.bank.changes,
                        contract: firstDuplicate.contract.changes,
                        employee: firstDuplicate.employee.changes
                    });
                    const changes = flattenObject(
                        Object.entries(dup)
                            .map(([key, c]) => ({ [key]: c.changes }))
                            .reduce((prev, curr) => ({ ...prev, ...curr }), {})
                    );
                    delete changes.rowNum;
                    delete changes.duplicateBy;
                    delete changes.accepted;
                    Object.entries(accepted).forEach(([k, val]) => {
                        // we compare the accepted and changes properties for check all and uncheck all using the accepted key value pair
                        if (k && isValid(val) && isValid(changes[k]) && val.toString() === changes[k].toString()) {
                            dup.accepted[k] = val;
                        }
                    });
                    return dup;
                }
                return { ...dup, accepted: !keepOriginal ? dup.accepted : {} };
            });
            return {
                ...emp,
                duplicates: defDuplicateWithAccepted,
                keepOriginal,
                modified: !keepOriginal,
                // we ignore the current accepted it is mixed when there is a value on other object
                mixed: !keepOriginal && defDuplicateWithAccepted.filter((def) => Object.keys(def.accepted).length > 0).length > 1,
                selected: !value ? defDuplicateWithAccepted[0].accepted : {}
            };
        });
        if (type === "mounting") {
            newemps = performNativeSort(newemps, sort);
        }
        setEmployees(newemps);
    };

    const handleSelectChanges = (row) => {
        setSelected(row);
    };

    const handleFinish = (newEmployee) => {
        setEmployees(
            employees.map((emp) => {
                if (emp.original.id === newEmployee.original.id) return newEmployee;
                return emp;
            })
        );
        setSelected(null);
    };

    const handleSort = ({ sortBy, order }) => {
        const keys = sortBy.split(".");
        const cloned = cloneDeep(employees);
        cloned.sort((a, b) => {
            let valueA = a;
            let valueB = b;
            // Traverse through keys to access nested properties
            for (const key of keys) {
                valueA = valueA[key];
                valueB = valueB[key];
            }
            // Check if both values are numbers
            if (!isNaN(valueA) && !isNaN(valueB)) {
                // Convert values to numbers and compare
                return order === SORT_ORDER.ASC ? Number(valueA) - Number(valueB) : Number(valueB) - Number(valueA);
            } else {
                // convert arr to string
                if (sortBy === "duplicates") {
                    Array.isArray(valueA) && (valueA = valueA.map((rd) => rd.rowNum).join(","));
                    Array.isArray(valueB) && (valueB = valueB.map((rd) => rd.rowNum).join(","));
                }
                // If not numbers, compare them as strings
                return order === SORT_ORDER.ASC ? String(valueA).localeCompare(String(valueB)) : String(valueB).localeCompare(String(valueA));
            }
        });
        setEmployees(cloned);
        setSort({ sortBy, order });
    };

    return (
        <div className="manage-duplicates">
            <details className="manage-duplicates__header" open>
                <summary>Manage Duplicates</summary>
                <p>
                    {`  These are the existing records with differing values found in the spreadsheet. Select 'View Changes' to inspect modifications in
                    the selected row. You can choose to accept the new changes or opt to skip and retain the existing data. New changes are accepted
                    by default.`}
                </p>
            </details>
            <TableList
                headers={CreateTableHeaders({ onSelect: handleSelectChanges }).data}
                data={employees}
                onSort={handleSort}
                onCheckChange={handleCheckChange}
                activeSort={sort}
                uniqueKey="id"
                checked={{ keep: employees.filter((emp) => emp.keepOriginal).map((emp) => emp.id) }}
            />
            {!isAllKeepOriginal && (!!trackLogs.keep.length || !!trackLogs.modified.length) && (
                <details className="manage-duplicates__footer" open>
                    <summary>View Selection Details</summary>
                    <div className="manage-duplicates__footer__content">
                        {!!trackLogs.keep.length && (
                            <div className="md-group">
                                <span>Keeping Original Data:</span>
                                <ul>
                                    {trackLogs.keep.map((value, i) => (
                                        <li key={i}>
                                            <Tag>TABLE ID - {value}</Tag>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                        {!!trackLogs.modified.length && (
                            <div className="md-group">
                                <span>Will be Modified:</span>
                                <ul>
                                    {trackLogs.modified.map((value, i) => (
                                        <li key={i}>
                                            <Tag className={STATUS.CUSTOM.color}>TABLE ID - {value}</Tag>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                </details>
            )}
            {includeUploads && isAllKeepOriginal && !toBeInserted && (
                <div className="manage-duplicates__footer__keep flex gap-05">
                    <span>All existing employee records will be retained. Would you like to upload and update the employee files?</span>
                    <Checkbox onChange={onKeepUploads} checked={keepUploads} />
                </div>
            )}
            {isAllKeepOriginal && !!toBeInserted && (
                <div className="manage-duplicates__footer__keep flex gap-05">
                    <span>
                        All existing employee records will remain unchanged, and {toBeInserted} new record(s) to be inserted. Please proceed to the
                        next step to view the final results.
                    </span>
                </div>
            )}
            {!includeUploads && !toBeInserted && isAllKeepOriginal && (
                <div className="manage-duplicates__footer__keep flex gap-05">
                    <span>All existing employee records will be retained, and no files has been uploaded.</span>
                </div>
            )}
            {!!selected && (
                <SelectChangesModal open={!!selected} data={selected} onChange={(bool) => !bool && setSelected(null)} onFinish={handleFinish} />
            )}
        </div>
    );
}

const CreateTableHeaders = ({ onSelect }) => {
    const headers = {
        ID: {
            key: "id",
            sortKey: "id",
            label: <span style={{ whiteSpace: "nowrap" }}>Table ID</span>,
            width: "10%",
            render: (row) => <span className="bold">{row.id}</span>
        },
        ROW: {
            key: "row",
            sortKey: "duplicates",
            label: "Row",
            width: "10%",
            render: (row) => (
                <span className="bold text-ellipsis" style={{ display: "block", maxWidth: "4rem" }}>
                    {row.duplicates.map((rd) => rd.rowNum).join(", ")}
                </span>
            )
        },
        EMPLOYEE: {
            key: "employee",
            sortKey: "original.residenceID",
            label: "Employee",
            width: "30%",
            render: (row) => (
                <span className="text-ellipsis">
                    <span className="bold">{row.original.residenceID}</span>
                    <span className="fade">
                        &nbsp;- {row.original.first_name} {row.original.last_name}
                    </span>
                </span>
            )
        },
        ACTION: {
            key: "action",
            width: "25%",
            render: (row, _, trackCheck) => {
                const isKeep = trackCheck["keep"].includes(row.id);
                if (isKeep) {
                    return null;
                }
                return (
                    <Button onClick={() => typeof onSelect === "function" && onSelect(row)} options={{ style: { padding: 0 } }} transparent>
                        <span className="bold" style={{ textDecoration: "underline" }}>
                            Modify Selection
                        </span>
                    </Button>
                );
            }
        },
        STATUS: {
            key: "status",
            label: "Selected Row",
            width: "10%",
            render: (row, _, trackCheck) => {
                const dups = row.duplicates || [];
                const hasAcceptedDups =
                    dups.map((d) => ({ ...(d?.accepted || {}), row: d.rowNum })).filter((d) => Object.values(d).filter(Boolean).length > 1) || [];
                if (row.mixed) {
                    return <Tag className={STATUS.MIXED.color}>{STATUS.MIXED.label}</Tag>;
                }
                const findstatus = trackCheck["keep"].includes(row.id)
                    ? STATUS.ORIGINAL
                    : statuses.find((st) => st.key === row.status) || STATUS.CUSTOM;
                let label = findstatus && (findstatus.key === STATUS.CUSTOM.key ? `ROW ${row.duplicates[0].rowNum}` : findstatus.label);

                if (findstatus.key === STATUS.CUSTOM.key) {
                    // if status becomes custom it will contain 1 value anyway so we will get the first row only
                    label = "ROW " + hasAcceptedDups[0]?.row;
                }

                return <Tag className={(findstatus && findstatus.color) || ""}>{label}</Tag>;
            }
        },
        KEEP_ORIGINAL: {
            key: "keep",
            label: <span style={{ maxWidth: "min-content" }}>Keep Original</span>,
            width: "10%",
            isCheckbox: true,
            checked: true
        }
    };
    return { data: Object.values(headers), original: headers };
};

ManageDuplicates.propTypes = {
    employees: PropTypes.array,
    setEmployees: PropTypes.func,
    onKeepUploads: PropTypes.func,
    isAllKeepOriginal: PropTypes.bool,
    toBeInserted: PropTypes.number,
    keepUploads: PropTypes.bool,
    includeUploads: PropTypes.bool
};

export default ManageDuplicates;
