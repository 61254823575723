import React, { useState } from "react";
import PropTypes from "prop-types";
import { createClass, createConfirmAlert, createGroup, sanitizeWords } from "../../../common/utilities/helper";
import BaseUpdateModal from "../../../common/components/layout/modalViewUpdateLayout/BaseUpdateModal";
import { useUpsertEmployeeOtherIncomes } from "./hooks";
import { BASE_CLASS, FIELD } from "./const";
import Input, { INPUT_TYPE } from "../../../common/components/extra/form/Input";
import Select from "../../../common/components/extra/select/Select";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectUserSetting } from "../../common/slice";
import SectionCollapseError from "../../../common/components/extra/section/SectionCollapseError";
import { NOTES_MAX_LEN } from "../../../common/utilities/const";
import EmployeeSelectLazy from "../employees/EmployeeSelectLazy";

function UpdateModal({ open, onClose, onBack, onFinish, id }) {
    const isCreate = !id;

    const [form, updateForm, { upsert, hasChanges, isGettingRecord, isUpserting, config }] = useUpsertEmployeeOtherIncomes(id);
    const [error, setError] = useState({ all: null });

    const disableSave = isGettingRecord || (!isCreate && !hasChanges) || isUpserting;
    const setting = useAppSelector(selectUserSetting);
    const currency = setting.currency;

    const handleSave = async () => {
        const result = await upsert();
        if (!result.error) {
            if (error.all) {
                setError({ ...error, all: null });
            }
            typeof onFinish === "function" && onFinish(result);
        }
        if (result.error) {
            setError({ ...error, all: result.error.message });
        }
        return result.error;
    };

    const handleFormChange = ({ name, value } = {}) => {
        const temp = { [name]: value };
        updateForm(temp);
    };

    const createTitle = () => {
        return (
            <div className="flex gap-05">
                <span className={!isCreate ? "fade" : ""}>{isCreate ? "Create" : "Update"} Other Income</span>
                {!isCreate && (
                    <>
                        <span className="fade">-</span>
                        <span>{sanitizeWords(config?.employee?.fullName)}</span>
                    </>
                )}
            </div>
        );
    };

    return (
        <BaseUpdateModal
            open={open}
            onClose={onClose}
            onBack={(!isCreate && onBack) || null}
            onSave={(e) =>
                createConfirmAlert({
                    title: !isCreate ? "Update Record" : "Create Record",
                    content: `Are you sure you want to ${isCreate ? "create" : "update"} this record? This cannot be undone.`,
                    onConfirm: async (close) => {
                        close();
                        const hasError = await handleSave(e);
                        if (!hasError) {
                            onBack();
                        }
                    }
                })
            }
            disableSave={disableSave}
            isLoading={isUpserting}
            isForm
        >
            {createGroup({
                base: createClass("__modal-content-update", BASE_CLASS),
                title: createTitle(),
                body: (
                    <div className="flex column gap-05">
                        <div className="flex gap-1 wrap">
                            <div className="flex column gap-05" style={{ flex: 1 }}>
                                {isCreate && (
                                    <EmployeeSelectLazy
                                        label="Employee"
                                        value={config.employee}
                                        onChange={(target) => handleFormChange({ name: FIELD.EMPLOYEE, value: target })}
                                        isSearchable={true}
                                        isDisabled={!isCreate}
                                        required
                                        allowOnShift
                                        isOutlined
                                        disabledOutline
                                    />
                                )}
                                <Select
                                    label="Type"
                                    value={config[FIELD.INCOME_TYPE]}
                                    options={config.typeOpt}
                                    onChange={(target) => handleFormChange({ name: FIELD.INCOME_TYPE, value: target.value })}
                                    required
                                    isOutlined
                                    disabledOutline
                                />

                                <Select
                                    label="Label"
                                    value={config[FIELD.LABEL]}
                                    options={config.labelOpt}
                                    onChange={(target) => handleFormChange({ name: FIELD.LABEL, value: target.value })}
                                    required
                                    isOutlined
                                    disabledOutline
                                />
                                <Input
                                    type={INPUT_TYPE.NUMBER}
                                    name={FIELD.AMOUNT}
                                    value={form[FIELD.AMOUNT]}
                                    label="Amount"
                                    onChange={(e) => handleFormChange({ name: FIELD.AMOUNT, value: e.target.value })}
                                    afterExtra={<span>{currency}</span>}
                                    required
                                />
                            </div>
                            <div className="flex column gap-05" style={{ flex: 1 }}>
                                <Input
                                    type={INPUT_TYPE.MONTH_YEAR}
                                    name={FIELD.START_DATE}
                                    label={<span style={{ whiteSpace: "nowrap" }}>Starts In</span>}
                                    onChange={(e) => handleFormChange({ name: FIELD.START_DATE, value: e.target.value })}
                                    value={config[FIELD.START_DATE]}
                                    offset={-1}
                                    disabled={config.isPastMonth}
                                    uptoCurrent={!config.isPastMonth}
                                    required
                                />
                                {config.isPastMonth && (
                                    <span className="danger-color small-font" style={{ marginLeft: ".5rem" }}>
                                        Note: Changes to a {"record's"} date cannot be made after the specified month has elapsed.
                                    </span>
                                )}
                                <Input
                                    type={INPUT_TYPE.TOGGLE}
                                    name={FIELD.STATUS}
                                    value={config.status}
                                    label="Approve"
                                    onChange={(e) => handleFormChange({ name: FIELD.STATUS, value: e.target.checked })}
                                />
                            </div>
                        </div>
                        <div className="flex column" style={{ marginTop: "1rem" }}>
                            <Input
                                name={FIELD.NOTES}
                                label="Notes/Description"
                                type={INPUT_TYPE.TEXTAREA}
                                value={form[FIELD.NOTES]}
                                parentStyle={{ height: "10rem", minHeight: "5rem" }}
                                onChange={(e) => handleFormChange({ name: FIELD.NOTES, value: e.target.value })}
                                maxLength={NOTES_MAX_LEN}
                            />
                        </div>
                        <SectionCollapseError show={!!error.all}>{error.all}</SectionCollapseError>
                    </div>
                )
            })}
        </BaseUpdateModal>
    );
}

UpdateModal.propTypes = {
    photo: PropTypes.string,
    open: PropTypes.bool,
    id: PropTypes.any,
    onClose: PropTypes.func,
    onFinish: PropTypes.func,
    onBack: PropTypes.func
};

export default UpdateModal;
