import React, { useEffect, useState } from "react";
import cloneDeep from "lodash/cloneDeep";
import PropTypes from "prop-types";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import isEqual from "lodash/isEqual";
import { selectGeneral, setGeneral, updateGeneral } from "./slice";
import { useGetCompanySettingsMutation, useUpdateCompanySettingsMutation } from "./api";
import { useAppDispatch, useAppSelector } from "../../../common/hooks/reduxHooks";

import Input, { INPUT_TYPE } from "../../../common/components/extra/form/Input";
import Divider from "../../../common/components/extra/Divider";
import Button from "../../../common/components/extra/Button";
import Card from "../../../common/components/extra/Card";
import Select from "../../../common/components/extra/select/Select";
import SectionCollapseWarning from "../../../common/components/extra/section/SectionCollapseWarning";
import { MONTHLY_SALARY_DAYS, TIMESHEET_TYPE } from "../../../common/utilities/const";
import {
    TOAST_TYPE,
    convertObjectToString,
    convertToObject,
    createConfirmAlert,
    createMockData,
    createToast,
    getObjectChanges,
    renderNA,
    sanitizeWords,
    toOrdinal,
    transformStringToObject
} from "../../../common/utilities/helper";

const SALARY_FIELD = {
    MONTHLY_SALARY_DAYS: "salary.monthlySalaryDays",
    AIR_TICKET_ELIGIBILITY: "salary.airTicketEligibility",
    ANNUAL_LEAVE_ELIGIBILITY: "salary.annualLeaveEligibility",
    REGULAR_OT_RATE: "salary.regularOtRate",
    HOLDAY_OT_RATE: "salary.holidayOtRate",
    TIMESHEET_START_DAY: "salary.timesheetStartDay",
    TIMESHEET_END_DAY: "salary.timesheetEndDay"
};

const createOptions = () => {
    const label = (val, text) => (
        <div className="flex gap-05">
            {val} {renderNA(text)}
        </div>
    );
    return {
        fiveYearsOpt: createMockData(5, (idx) => ({ label: label(idx + 1, "year(s)"), value: idx + 1 })),
        daysOpt: createMockData(31, (idx) => {
            idx = idx + 1;
            const suffix =
                idx % 10 === 1 && idx % 100 !== 11
                    ? "st"
                    : idx % 10 === 2 && idx % 100 !== 12
                      ? "nd"
                      : idx % 10 === 3 && idx % 100 !== 13
                        ? "rd"
                        : "th";
            return { label: `Every ${idx}${suffix}`, value: idx };
        }),
        monthlySalaryDaysOpt: Object.values(MONTHLY_SALARY_DAYS),
        timesheetTypeOpt: Object.values(TIMESHEET_TYPE).map((ts) => ({ value: ts, label: sanitizeWords(ts) }))
    };
};

const SALARY_NUM_FIELDS = Object.values(SALARY_FIELD);

const DESCRIPTION = ` These settings govern the calculation of employee salaries, ensuring accuracy and fairness in compensation. Customize
parameters to align with your organization's policies and standards, enabling precise and consistent payroll management.`;

function SalarySettings({ setParentLoading, onLoading, readOnly }) {
    const [loading, setLoading] = useState(true);
    const [isEditing, setIsEditing] = useState(false);
    const [old, setOld] = useState(null);

    const dispatch = useAppDispatch();
    const [getDetails] = useGetCompanySettingsMutation();
    const [update] = useUpdateCompanySettingsMutation();
    const general = useAppSelector(selectGeneral);

    const { fiveYearsOpt, daysOpt, monthlySalaryDaysOpt, timesheetTypeOpt } = createOptions();

    const salary = general.salary;
    const findYearEligibility = (year) => fiveYearsOpt.find((opt) => opt.value == year) || "";
    const findDay = (day, opt = daysOpt) => opt.find((opt) => opt.value == day) || "";
    const holidayOTRate = typeof salary?.holidayOtRate === "number" ? salary?.holidayOtRate : "";
    const regularOtRate = typeof salary?.regularOtRate === "number" ? salary?.regularOtRate : "";
    const monthlySalaryDays = monthlySalaryDaysOpt.find((msd) => msd.value === salary?.monthlySalaryDays) || "";
    const timesheetType = salary?.timesheetEndDay == 31 ? TIMESHEET_TYPE.FULL_MONTH : TIMESHEET_TYPE.BY_CUTOFF;
    const isCutOff = timesheetType == TIMESHEET_TYPE.BY_CUTOFF;

    const fetchDetails = async () => {
        try {
            const response = await getDetails();
            if (response.error) {
                throw new Error(response.error?.data?.message);
            }
            const data = response.data?.data || {};
            dispatch(setGeneral(data));
            if (!old) {
                setOld(data);
            }
        } catch (error) {
            createToast("Something went wrong with the server. Please contact support.", TOAST_TYPE.ERROR);
        } finally {
            setLoading(false);
        }
    };

    const updateApi = async (body) => {
        typeof onLoading === "function" && onLoading(true);
        setParentLoading?.(true);
        const result = await update({ body });
        if (result.error) {
            setParentLoading?.(false);
            throw new Error(result?.error?.data?.message || "Something went wrong!. Please try again later.");
        }
        const data = result.data.data;
        setParentLoading?.(false);
        typeof onLoading === "function" && onLoading(false);
        return data;
    };

    useEffect(() => {
        if (!general?.id) {
            fetchDetails();
        } else {
            setOld(general);
            setLoading(false);
        }
    }, []);

    const handleChange = (e) => {
        let config = { ...general };
        const name = e.target.name;
        const value = e.target.value;
        const isChainedObject = name.split(".").length > 1;
        if (isChainedObject) {
            const parsedObject = transformStringToObject(name, value, general);
            config = parsedObject;
            if (name == SALARY_FIELD.TIMESHEET_END_DAY) {
                if (isCutOff) {
                    const nextDay = value + 1;
                    config.salary["timesheetStartDay"] = nextDay == 32 ? 1 : nextDay;
                    config.salary["timesheetEndDay"] = value;
                } else {
                    config.salary["timesheetStartDay"] = 1;
                    config.salary["timesheetEndDay"] = 31;
                }
            }
        } else {
            config[name] = value;
        }
        handleFormChange(config);
    };

    const handleFormChange = (config = {}) => dispatch(updateGeneral(config));

    const toggleEdit = ({ isCancel } = {}) => {
        if (isCancel && old) {
            dispatch(setGeneral(old));
        }
        setIsEditing((prev) => !prev);
    };

    const inputPropsValue = (value) => {
        if (isEditing && !readOnly) {
            return { value };
        } else {
            return { renderValue: value, readOnly: true };
        }
    };

    const convertStrToNum = (object) => {
        SALARY_NUM_FIELDS.forEach((field) => object[field] && (object[field] = parseFloat(object[field])));
        return object;
    };

    const handleSave = async () => {
        try {
            let changes = getObjectChanges(convertObjectToString(general), convertObjectToString(old));
            changes = convertStrToNum(changes);
            const result = await updateApi(convertToObject(changes));
            if (result.error) {
                throw new Error(result?.error?.data?.message || "Something went wrong!. Please try again later.");
            }
            handleFormChange(result);
            setOld(result);
            toggleEdit();
            createToast("Settings updated successfully.", TOAST_TYPE.SUCCESS);
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
        }
    };

    const checkHasChanges = () => {
        let cloneNew = convertObjectToString(cloneDeep(general));
        let cloneOld = convertObjectToString(cloneDeep(old || {}));
        cloneNew = convertStrToNum(cloneNew);
        cloneOld = convertStrToNum(cloneOld);
        return !!old && !isEqual(cloneNew, cloneOld);
    };

    const renderInput = ({ name, label, value, onChange, options, afterExtra, ...rest } = {}) => {
        if (isEditing && !readOnly) {
            return (
                <Select
                    options={options}
                    onChange={(val) => onChange({ target: { name, value: val.value } })}
                    value={value}
                    name={name}
                    label={label}
                    placeholder=""
                    style={{ flex: "30%" }}
                    isClearable={false}
                    menuPortalTarget={document.body}
                    isOutlined
                    disabledOutline
                    {...rest}
                />
            );
        }
        return (
            <Input
                type={INPUT_TYPE.TEXT}
                name={name}
                label={label}
                onChange={onChange}
                afterExtra={afterExtra}
                isLoading={loading}
                {...inputPropsValue(typeof value === "object" && "label" in value ? value.label : value)}
            />
        );
    };

    const renderControls = (
        <div className="flex gap-1" style={{ marginLeft: "auto" }}>
            {isEditing && (
                <Button
                    options={{ type: "button" }}
                    beforeExtra={<CancelIcon style={{ color: "red" }} />}
                    onClick={() => toggleEdit({ isCancel: true })}
                    disabled={loading}
                    small
                >
                    Cancel
                </Button>
            )}
            {!isEditing && (
                <Button options={{ type: "button" }} beforeExtra={<ModeEditIcon />} className="primary" onClick={toggleEdit} disabled={loading} small>
                    Edit
                </Button>
            )}
            {isEditing && (
                <Button options={{ type: "submit" }} beforeExtra={<SaveIcon />} className="primary" disabled={loading || !checkHasChanges()} small>
                    Save
                </Button>
            )}
        </div>
    );

    const handleTimeSheetChange = (e) => {
        const value = e.target.value;
        if (value == TIMESHEET_TYPE.BY_CUTOFF) {
            handleFormChange({ salary: { ...salary, timesheetStartDay: 3, timesheetEndDay: 2 } });
        }
        if (value == TIMESHEET_TYPE.FULL_MONTH) {
            handleFormChange({ salary: { ...salary, timesheetStartDay: 1, timesheetEndDay: 31 } });
        }
    };

    return (
        <Card className="salary-settings box-shadow-mini">
            {!readOnly && (
                <SectionCollapseWarning show>
                    This is your one chance to adjust the salary settings. Please double-check that the values you enter are accurate and final. This
                    will be used for the computation of salary.
                </SectionCollapseWarning>
            )}
            <form
                className="flex column"
                style={{ gap: "2rem" }}
                onSubmit={(e) => {
                    e.preventDefault();
                    createConfirmAlert({
                        title: "Save work",
                        content: "Are you sure this will update your work.",
                        onConfirm: (onClose) => {
                            onClose();
                            handleSave();
                        }
                    });
                }}
            >
                <div className="flex column">
                    <Divider title={`${!readOnly ? "Manage" : "View"} Salary Settings`} />
                    <p className="fade small-font">{DESCRIPTION}</p>
                    <div className="flex column" style={{ gap: "2rem" }}>
                        <div className="flex column gap-05">
                            <span className="bold" style={{ marginLeft: ".5rem" }}>
                                Monthly Timesheet
                            </span>
                            <div className="group flex column" style={{ marginLeft: "1rem" }}>
                                {renderInput({
                                    name: "timesheet",
                                    label: "Type",
                                    onChange: handleTimeSheetChange,
                                    value: timesheetTypeOpt.find((opt) => timesheetType == opt.value) || "",
                                    options: timesheetTypeOpt,
                                    required: true,
                                    autoFocus: true
                                })}
                                {isCutOff &&
                                    renderInput({
                                        name: SALARY_FIELD.TIMESHEET_END_DAY,
                                        label: "Cutoff Day",
                                        onChange: handleChange,
                                        value: findDay(salary?.timesheetEndDay || ""),
                                        options: daysOpt,
                                        required: true,
                                        menuPlacement: "top"
                                    })}

                                {salary?.timesheetStartDay &&
                                    (isCutOff ? (
                                        <span className="small-font fade" style={{ marginLeft: ".5rem", marginTop: ".5rem" }}>
                                            Note: The cutoff period spans from the{" "}
                                            <span className="bold primary-color">{toOrdinal(salary.timesheetStartDay)}</span>
                                            &nbsp;to the <span className="bold primary-color">{toOrdinal(salary.timesheetEndDay)}</span> day of the
                                            respective months.
                                        </span>
                                    ) : (
                                        <span className="small-font fade" style={{ marginLeft: ".5rem", marginTop: ".5rem" }}>
                                            Note: Starts on the <span className="bold primary-color">{toOrdinal(1)}</span> day of the month and
                                            concludes on the <span className="bold primary-color">Last Day of the month</span>.
                                        </span>
                                    ))}
                            </div>
                            <div className="flex column" style={{ marginLeft: "1rem" }}>
                                {renderInput({
                                    name: SALARY_FIELD.MONTHLY_SALARY_DAYS,
                                    label: "Monthly Salary Days",
                                    onChange: handleChange,
                                    value: monthlySalaryDays,
                                    options: monthlySalaryDaysOpt,
                                    required: true,
                                    autoFocus: true
                                })}
                                <span className="small-font fade" style={{ marginLeft: ".5rem", marginTop: ".5rem" }}>
                                    Note: Value will be used for OT and Salary Per Day computations.
                                </span>
                            </div>
                        </div>
                        <div className="flex column gap-05">
                            <span className="bold" style={{ marginLeft: ".5rem" }}>
                                OT Rates
                            </span>
                            <div className="group flex column" style={{ marginLeft: "1rem" }}>
                                <Input
                                    type={INPUT_TYPE.NUMBER}
                                    name={SALARY_FIELD.REGULAR_OT_RATE}
                                    label="Regular OT Rate"
                                    onChange={handleChange}
                                    constraint={{ min: 0, max: 5 }}
                                    isLoading={loading}
                                    {...inputPropsValue(regularOtRate)}
                                />
                                <Input
                                    type={INPUT_TYPE.NUMBER}
                                    name={SALARY_FIELD.HOLDAY_OT_RATE}
                                    label="Holiday OT Rate"
                                    onChange={handleChange}
                                    constraint={{ min: 0, max: 5 }}
                                    isLoading={loading}
                                    {...inputPropsValue(holidayOTRate)}
                                />
                                {isEditing && (
                                    <span className="small-font fade" style={{ marginLeft: ".5rem", marginTop: ".5rem" }}>
                                        Note: Value must be between 0 and 5
                                    </span>
                                )}
                            </div>
                        </div>
                        <div className="flex column gap-05">
                            <span className="bold" style={{ marginLeft: ".5rem" }}>
                                Eligibilities
                            </span>
                            {renderInput({
                                name: SALARY_FIELD.AIR_TICKET_ELIGIBILITY,
                                label: "Air Ticket Eligibility",
                                onChange: handleChange,
                                value: findYearEligibility(salary?.airTicketEligibility || ""),
                                options: fiveYearsOpt,
                                required: true
                            })}
                            {renderInput({
                                name: SALARY_FIELD.ANNUAL_LEAVE_ELIGIBILITY,
                                label: "Annual Leave Eligibility",
                                onChange: handleChange,
                                value: findYearEligibility(salary?.annualLeaveEligibility || ""),
                                options: fiveYearsOpt,
                                required: true
                            })}
                        </div>
                    </div>
                </div>
                {!readOnly && renderControls}
            </form>
        </Card>
    );
}

export default SalarySettings;

SalarySettings.propTypes = {
    loading: PropTypes.bool,
    readOnly: PropTypes.bool,
    setParentLoading: PropTypes.func,
    onLoading: PropTypes.func
};
