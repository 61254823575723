import React from "react";
import PropTypes from "prop-types";
import EditIcon from "@mui/icons-material/BorderColor";
import Modal from "../../extra/Modal";
import Button, { BTN_TYPE } from "../../extra/Button";
import More from "../../common/More";

function BaseViewModal({ open, onChange, onClose, children, onEdit, headExtra, disableEdit, moreProps, ...props }) {
    return (
        <Modal
            {...props}
            open={open}
            onChange={onChange}
            onClose={onClose}
            styles={{
                ...(props.styles || {}),
                content: {
                    width: "50vw",
                    minWidth: "30rem",
                    ...(props?.styles?.content || {})
                }
            }}
        >
            <div className="view-modal flex column gap-1" style={{ flexGrow: 1 }}>
                <section className="flex gap-05 wrap">
                    {onEdit && (
                        <Button className="primary" onClick={onEdit} disabled={disableEdit} mini small>
                            Edit
                            <EditIcon />
                        </Button>
                    )}
                    {headExtra}
                    {moreProps && !!moreProps.items?.length && <More isButton isVertical {...(moreProps || {})} />}
                </section>
                {children}
            </div>
        </Modal>
    );
}

BaseViewModal.propTypes = {
    open: PropTypes.bool,
    disableEdit: PropTypes.bool,
    onChange: PropTypes.func,
    onClose: PropTypes.func,
    onEdit: PropTypes.func,
    children: PropTypes.any,
    headExtra: PropTypes.any,
    styles: PropTypes.shape({
        parent: PropTypes.object,
        content: PropTypes.object,
        body: PropTypes.object,
        title: PropTypes.object,
        form: PropTypes.object,
        footer: PropTypes.object
    }),
    moreProps: PropTypes.shape({
        isVertical: PropTypes.bool,
        isButton: PropTypes.bool,
        items: PropTypes.arrayOf(
            PropTypes.shape({
                render: PropTypes.any
            })
        ),
        onSelect: PropTypes.func,
        styles: PropTypes.shape({
            parent: PropTypes.object,
            items: PropTypes.object
        }),
        buttonProps: PropTypes.shape({
            small: PropTypes.bool,
            transparent: PropTypes.bool,
            className: PropTypes.string,
            type: PropTypes.oneOf([0, ...Object.values(BTN_TYPE)]),
            children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
            beforeExtra: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
            afterExtra: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
            disabled: PropTypes.bool,
            isLoading: PropTypes.bool,
            onClick: PropTypes.func,
            options: PropTypes.object,
            mini: PropTypes.bool,
            noLabel: PropTypes.bool,
            tooltipProps: PropTypes.object
        })
    })
};

export default BaseViewModal;
