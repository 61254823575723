import React from "react";
import PropTypes from "prop-types";
import SalarySettings from "./SalarySettings";
import Departments from "../departments/Departments";
import Designations from "../designations/Designations";

function WorkTab({ loading, setParentLoading }) {
    return (
        <div className="work-tab flex column gap-1">
            <SalarySettings loading={loading} setParentLoading={setParentLoading} readOnly />
            <Departments loading={loading} setParentLoading={setParentLoading} />
            <Designations loading={loading} setParentLoading={setParentLoading} />
        </div>
    );
}

export default WorkTab;

WorkTab.propTypes = {
    loading: PropTypes.bool,
    setParentLoading: PropTypes.func
};
