import React from "react";
import PropTypes from "prop-types";
import { useBlocker } from "react-router-dom";
import Modal from "./Modal";

function BlockNavigation({ onLeave, disabled, message }) {
    const blocker = useBlocker(({ currentLocation, nextLocation }) => {
        return currentLocation.pathname !== nextLocation.pathname;
    });

    const blocked = blocker.state == "blocked";

    if (!blocked) return <></>;

    return (
        <Modal
            className="tk-confirm-nav"
            title={disabled ? "Payment Expired" : "Leaving Page?"}
            open={blocked}
            onChange={(bool) => {
                if (!bool) {
                    blocker.reset();
                }
            }}
            onSave={() => {
                blocker.proceed();
                typeof onLeave == "function" && onLeave();
            }}
            styles={{ title: { textAlign: "left" }, content: {} }}
            saveLabel="Confirm"
            hasHeaderStyle
            small
            isPortal
        >
            <div className="content" style={{ maxWidth: "25rem" }}>
                <p style={{ fontSize: ".9em" }}>
                    {!disabled && !message ? "Are you sure you want to leave? All your progress will be lost." : message}
                </p>
            </div>
        </Modal>
    );
}

export default BlockNavigation;

BlockNavigation.propTypes = {
    onLeave: PropTypes.func,
    disabled: PropTypes.bool,
    message: PropTypes.string
};
