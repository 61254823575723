import { useState } from "react";
import { useApplySubscriptionUpgradeMutation, useGetUpgradeAmountMutation } from "./api";

export const useApplySubscriptionUpgrade = () => {
    const [result, setResult] = useState(null);
    const [applyUpgrade, { isLoading }] = useApplySubscriptionUpgradeMutation();

    const apply = async (subscription_id, payment = {}) => {
        const response = await applyUpgrade({
            body: { subscription_id, payment }
        });
        if (response.error) {
            return {
                error: response.error?.data?.message || response.error?.message || "Failed to apply upgrade."
            };
        }
        setResult(response.data.data);
        return response.data.data;
    };

    return [apply, { result, isLoading }];
};

export const useGetUpgradeAmount = () => {
    const [result, setResult] = useState(null);
    const [getUpgradeAmount, { isLoading }] = useGetUpgradeAmountMutation();

    const func = async (newSubscriptionID) => {
        const response = await getUpgradeAmount({ extraPath: newSubscriptionID });
        if (response.error) {
            return {
                error: response.error?.data?.message || response.error?.message || "Failed to apply upgrade."
            };
        }
        setResult(response.data.data);
        return response.data.data;
    };

    return [func, { result, isLoading }];
};
