import { toFormFields } from "../../../common/utilities/helper";

export const BASE_CLASS = "tk-employees-work-histories__modal-content-update";

export const TIME_TYPE = {
    SHIFT_ONE: 0x1,
    SHIFT_TWO: 0x2,
    BREAK_ONE: 0x3,
    BREAK_TWO: 0x4,
    OVERTIME: 0x5
};

export const FIELDS = {
    TYPE: { name: "type", label: "Type", required: true, default: "" },
    IS_MANUAL_TIME_IN: { name: "isManualTimeIn", label: "Is Manual", required: true, default: "" },
    STATUS: { name: "status", label: "Status", required: true, default: "" },
    START_SHIFT_TIME: { name: "start_shift_time", label: "Shift One Start", required: true, default: "" },
    END_SHIFT_TIME: { name: "end_shift_time", label: "Shift One End", required: true, default: "" },
    START_SHIFT_TIME_TWO: { name: "start_shift_time_2", label: "Shift Two Start", required: true, default: "" },
    END_SHIFT_TIME_TWO: { name: "end_shift_time_2", label: "Shift Two End", required: true, default: "" },
    START_BREAK_TIME: { name: "start_break_time", label: "Shift One Break Start", required: false, default: "" },
    END_BREAK_TIME: { name: "end_break_time", label: "Shift One Break End", required: false, default: "" },
    START_BREAK_TIME_TWO: { name: "start_break_time_2", label: "Shift Two Break Start", required: false, default: "" },
    END_BREAK_TIME_TWO: { name: "end_break_time_2", label: "Shift Two Break End", required: false, default: "" },
    START_OVERTIME: { name: "start_overtime", label: "Overtime Start", required: false, default: "" },
    END_OVERTIME: { name: "end_overtime", label: "Overtime End", required: false, default: "" },
    NOTE: { name: "notes", label: "Notes", required: false, default: "" },
    EMPLOYEE_ID: { name: "employee_id", label: "Employee", required: true, default: "" },
    WORK_TYPE_ID: { name: "work_type_id", label: "Work Type", required: true, default: "" },
    WORK_SHIFT_ID: { name: "work_shift_id", label: "Work Shift", required: true, default: "" },
    SITE_ONE_ID: { name: "site_one_id", label: "Work Site", required: true, default: "" },
    SITE_TWO_ID: { name: "site_two_id", label: "Work Site", required: true, default: "" },
    OVERTIME_SITE_ID: { name: "overtime_site_id", label: "Overtime Site", required: true, default: "" }
};

export const DATE_FIELDS = [
    FIELDS.START_SHIFT_TIME.name,
    FIELDS.END_SHIFT_TIME.name,
    FIELDS.START_SHIFT_TIME_TWO.name,
    FIELDS.END_SHIFT_TIME_TWO.name,
    FIELDS.START_BREAK_TIME.name,
    FIELDS.END_BREAK_TIME.name,
    FIELDS.START_BREAK_TIME_TWO.name,
    FIELDS.END_BREAK_TIME_TWO.name,
    FIELDS.START_OVERTIME.name,
    FIELDS.END_OVERTIME.name
];

export const FORM_FIELDS = toFormFields(FIELDS, "default");

export const ACTION_TYPE = {
    VIEW: 0x1,
    REMOVE: 0x2
};

// TODO: Reminder for setting the incomplete status of history record: 1 day
export const INCOMPLETE_STATUS_IDENTIFIER = 1; // 1 day

export const FILTER_TYPE = {
    STATUS: 0x1,
    DATE: 0x2,
    APP_ROLE: 0x3,
    SITE: 0x4,
    EMPLOYEE: 0x5
};

export const APP_ROLE = {
    EMPLOYEE: "EMPLOYEE",
    SUPERVISOR: "SUPERVISOR"
};

export const EDIT_TYPE = {
    DEFAULT: 0x1,
    TIMING: 0x2,
    OVERTIME: 0x3,
    READ_ONLY: 0x4
};
