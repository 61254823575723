import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { RECURRENCE, RECURRENCE_WITH_VALUE } from "../../../common/utilities/const";
import Loader from "../../../common/components/extra/Loader";
import ButtonTab from "../../../common/components/extra/ButtonTab";
import Button from "../../../common/components/extra/Button";
import { ReactComponent as LeftArrow } from "../../../assets/images/arrow-left.svg";
import { addCommasToMoney, createConfirmAlert, createPromiseToast, sanitizeWords } from "../../../common/utilities/helper";
import useNavigate from "../../../common/hooks/useNavigate";
import Navigation from "../../../common/classes/Navigation";
import { useGetUpgradeSelections } from "./hooks";
import { useCreatePaymentToken } from "../../company/companyPayment/hooks";
import { useAppDispatch } from "../../../common/hooks/reduxHooks";
import { setSubscriptionToUpgradeId } from "../../company/companyPayment/slice";
import useLocalStorage from "../../../common/hooks/useLocalStorage";
import { LOCAL_STORAGE_KEYS } from "../../company/companyPayment/const";
import { useGetSubscriptionTimeRemaining } from "../../company/auth/hooks";
import { useRefresh } from "../../common/hooks";
import Banner, { BANNER_TYPE } from "../../../common/components/extra/Banner";
import { Link } from "react-router-dom";
import { TABS } from "../../../pages/Settings";

const RECURRENCES = Object.values(RECURRENCE);

function Plan({ data, onSelect, isCurrent, isLoading, disabled, title }) {
    return (
        <div className="tk-subscription-plan__item">
            <div className="tk-subscription-plan__item__inner">
                <div className="tk-subscription-plan__item__header">
                    <span className="tk-subscription-plan__item__title">{data.title}</span>
                </div>
                <div className="tk-subscription-plan__item__content">
                    <div className="tk-subscription-plan__item__price">
                        <h1 className="amount">{addCommasToMoney(data.amount)}</h1>
                        <span className="currency">{data.currency}</span>
                    </div>
                    <span className="tk-subscription-plan__item__description">
                        1 up to <span className="bold">{data.employee_limit}</span> users
                    </span>
                </div>
                <div className="tk-subscription-plan__item__footer">
                    <Button className={isCurrent ? "secondary" : "primary"} onClick={onSelect} disabled={isCurrent || disabled} isLoading={isLoading}>
                        {isCurrent ? "Current Plan" : title || "Upgrade"}
                    </Button>
                </div>
            </div>
        </div>
    );
}

function Feature({ items }) {
    return (
        <ul className="tk-subscription-plan__footer__feature">
            {items.map((item, i) => (
                <li key={i}>{item}</li>
            ))}
        </ul>
    );
}

function SubscriptionPlan({ onBack, companySubscription, isPromote, isEnded }) {
    const scrollParentRef = useRef(null);

    const [recurrence, setRecurence] = useState(RECURRENCE.MONTHLY);
    const [selectedUpgrade, setSelectedUpgrade] = useState(null);

    const [, setStorage] = useLocalStorage(LOCAL_STORAGE_KEYS.PAYMENT.key, LOCAL_STORAGE_KEYS.PAYMENT.default);

    const [subscriptions, getAllSubcriptionsLoading] = useGetUpgradeSelections();
    const [createToken, { isCreatingToken }] = useCreatePaymentToken();
    const timeRemaining = useGetSubscriptionTimeRemaining();
    const date = timeRemaining.date;
    const [refreshToken, isRefreshing] = useRefresh();

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const itemStyle = {};
    const isLoading = getAllSubcriptionsLoading || isRefreshing;
    const result = subscriptions.filter((plan) => (plan.recurrence !== !isPromote ? RECURRENCE.TRIAL : ""));
    const buttonTabs = RECURRENCES.filter((r) => r !== RECURRENCE.TRIAL && result.some((res) => res.recurrence === r));
    const trialResult = result.find((r) => r.recurrence === RECURRENCE.TRIAL);
    const activeTabSuscriptions = result.filter((r) => r.recurrence === recurrence);
    activeTabSuscriptions.sort((a, b) => a.amount * 1 - b.amount * 1);
    const subscription = companySubscription.Subscription;
    const currentPlanId = subscription.id;

    const createBanner = () => {
        const bannerMessage = [
            <React.Fragment key={1}>
                Notice: It is not possible to switch to a lower payment tier when the remaining duration exceeds one month.&nbsp;
            </React.Fragment>,
            <React.Fragment key={2}>
                Your subscription currently has&nbsp;
                <span className="strong primary-color">{`${date.months} Month(s) ${date.days ? `${date.days} Day(s)` : ""}`.trim()}</span>
                &nbsp; remaining.&nbsp;
            </React.Fragment>,
            <Link key={3} to={Navigation.Routes.SETTINGS.path + "#" + TABS.BILLING.id}>
                Please click for further details.
            </Link>
        ];

        return (
            <Banner style={{ margin: "0 auto" }} type={BANNER_TYPE.WARNING} show={!timeRemaining.isLessThanOneMonth}>
                <span className="small-font w100" style={{ textAlign: "center" }}>
                    {bannerMessage}
                </span>
            </Banner>
        );
    };

    if (isPromote) {
        itemStyle.justifyContent = "left";
        itemStyle.width = "calc(100vw - 12rem)";
    }

    if (isEnded) {
        itemStyle.width = "calc(100vw - 7rem)";
    }

    const updateSelectedUpgrade = (upgrade) => {
        setSelectedUpgrade(upgrade);
        dispatch(setSubscriptionToUpgradeId(upgrade ? upgrade.id : upgrade));
    };

    const handleSelectPlan = async (r) => {
        const onSuccess = (result) => {
            if (result.data.token) {
                updateSelectedUpgrade(r);
                navigate(Navigation.Routes.SUBSCRIPTION_PAY.path.replace(":token", result.data.token), null, true);
                setStorage({ key: result.data.token, expires: result.data.PTLexpires });
                return `Please Pay within the time limit of ${result.data.PTL} ${result.data.PTLtype}.`;
            } else {
                // if no token was found make it like the page is tempory not yet avalable
                return "Upgrade page is under maintenance, please try again later.";
            }
        };

        createPromiseToast(createToken(r.id), {
            render: {
                pending: () => `Confirming Upgrade to ${sanitizeWords(r.title)}...`,
                success: (result) => onSuccess(result),
                error: () => "Failed to go to upgrade page. Please try again later."
            }
        }).catch(() => updateSelectedUpgrade(null));
    };

    const getDisabledRecurrences = () => {
        const currentPlanRecurrence = subscription?.recurrence;
        const findCurrentPlanIdx = buttonTabs.findIndex((tab) => tab == currentPlanRecurrence);
        const lastIndex = buttonTabs.length - 1;

        if (!timeRemaining.isLessThanOneMonth) {
            return buttonTabs.filter((_, i) => (findCurrentPlanIdx > lastIndex ? lastIndex : findCurrentPlanIdx) > i);
        } else {
            return [];
        }
    };

    const getActivePlanIndex = () => {
        return activeTabSuscriptions.findIndex((plan) => plan.title == subscription.title && plan.employee_limit == subscription.employee_limit);
    };

    useEffect(() => {
        refreshToken();
    }, []);

    useEffect(() => {
        if (currentPlanId && scrollParentRef.current) {
            const activeElement = scrollParentRef.current.querySelector(`[data-id="${currentPlanId}"]`);
            if (activeElement) {
                activeElement.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
            }
        }
    }, [scrollParentRef.current]);

    return (
        <div className="tk-subscription-plan">
            {isLoading ? (
                <Loader absolute centered />
            ) : (
                <div className="tk-subscription-plan__inner">
                    <div className="tk-subscription-plan__header">
                        <div className="left">
                            {onBack && <LeftArrow onClick={onBack} />}
                            <span>
                                <span>Subscription Plan</span>
                                <span>/</span>
                                <span>Upgrade your Package</span>
                            </span>
                        </div>
                    </div>

                    <div className="tk-subscription-plan__control">
                        {!!result.length && (
                            <ButtonTab
                                header={
                                    <div className="discount">
                                        <span className="bold">GET 10% or 15% DISCOUNT</span>
                                    </div>
                                }
                                items={buttonTabs}
                                active={recurrence}
                                onSelect={(r) => setRecurence(r)}
                                onRender={(r) => RECURRENCE_WITH_VALUE[r].label}
                                style={{ borderColor: "#E7B400" }}
                                disabledTabs={getDisabledRecurrences()}
                            />
                        )}
                    </div>
                    <div className="tk-subscription-plan__items">
                        <ul ref={scrollParentRef} style={itemStyle}>
                            {isPromote && trialResult && (
                                <li>
                                    <Plan
                                        data={trialResult}
                                        recurrence={RECURRENCE.TRIAL}
                                        isCurrent={companySubscription.Subscription.recurrence === RECURRENCE.TRIAL}
                                    />
                                </li>
                            )}
                            {activeTabSuscriptions.map((r, i) => (
                                <li key={r.id} data-id={r.id}>
                                    <Plan
                                        data={r}
                                        recurrence={recurrence}
                                        onSelect={() =>
                                            createConfirmAlert({
                                                title: "Confirm Upgrade",
                                                content: "You will be redirected to the payment page.",
                                                onConfirm: (onClose) => {
                                                    onClose();
                                                    handleSelectPlan(r);
                                                }
                                            })
                                        }
                                        isCurrent={r.id === currentPlanId}
                                        isLoading={selectedUpgrade && selectedUpgrade.id == r.id && isCreatingToken}
                                        disabled={isCreatingToken || getActivePlanIndex() > i}
                                        title={getActivePlanIndex() == i ? "Update Recurrence" : "Upgrade"}
                                    />
                                </li>
                            ))}
                        </ul>
                    </div>
                    {createBanner()}
                    <div className="tk-subscription-plan__footer">
                        <div className="tk-subscription-plan__footer__features">
                            <h2 className="title">INCLUDED FEATURES</h2>
                            <div className="tk-subscription-plan__footer__features__group">
                                <Feature items={FEATURE_1} />
                                <Feature items={FEATURE_2} />
                                <Feature items={FEATURE_3} />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

SubscriptionPlan.propTypes = {
    onBack: PropTypes.func,
    companySubscription: PropTypes.object,
    onSubscribe: PropTypes.func,
    isPromote: PropTypes.bool,
    isEnded: PropTypes.bool
};

Plan.propTypes = {
    data: PropTypes.object,
    isActive: PropTypes.bool,
    isCurrent: PropTypes.bool,
    onSelect: PropTypes.func,
    isLoading: PropTypes.bool,
    disabled: PropTypes.bool,
    title: PropTypes.string
};

Feature.propTypes = {
    items: PropTypes.array
};

export default SubscriptionPlan;

const FEATURE_1 = ["Geo-fencing and LocationTracking", "Employee Management", "Time sheet (Daily, Weekly, Monthly)", "Attendance Tracking"];
const FEATURE_2 = ["Work and Site Management", "Shift Scheduling and Management", "Site Reports", "Monthly Salary Computation / Payroll"];
const FEATURE_3 = [
    "Holiday, Time Adjustment and Leave Management",
    "Activity Logs and Work Histories",
    "Employee Requests Management",
    "Real-time Chat"
];
