import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Loader from "../../../common/components/extra/Loader";
import { ReactComponent as ProceedCheckedSVG } from "../../../assets/images/proceed-checked.svg";
import { ReactComponent as WarningSVG } from "../../../assets/images/warning-icon.svg";
import { ReactComponent as SimpleSuccessSVG } from "../../../assets/images/simple-success-doc-icon.svg";
import { TOAST_TYPE, convertToObject, createPromiseToast, createToast } from "../../../common/utilities/helper";
import Checkbox from "../../../common/components/extra/Checkbox";
import ManageDuplicates from "./ManageDuplicates";
import { useCheckDuplicateMutation, useFinalizeBulkUploadMutation } from "../employees/api";

function Icon({ img, text }) {
    return (
        <div className="icon">
            {img}
            <div>{text}</div>
        </div>
    );
}

function BulkUploadCheckDuplicates({ updateStep, step, onSuccess, active, employees, includeUploads }) {
    const [checkDuplicate] = useCheckDuplicateMutation();
    const [finalizeBulk] = useFinalizeBulkUploadMutation();

    const [object, setObject] = useState({
        checking: true,
        employees: [],
        summary: { toBeInserted: 0, toBeUpdated: 0 },
        keepUploads: true
    });

    const isChecking = object.checking;
    const noDuplicates = object.employees && !object.employees.length;
    const hasInsert = !!object.summary?.toBeInserted;
    const hasError = !!step.error;
    const isAllKeepOriginal = object.employees?.every((emp) => !!emp.keepOriginal) || false;

    const updateObject = (newObj = {}) => setObject((prev) => ({ ...prev, ...newObj }));

    const finalizeBulkUpload = async () => {
        const result = await finalizeBulk({
            body: {
                // if show cancel is false that means uploads is included and always include uploads if has insert
                includeUploads: includeUploads && (hasInsert || !step.showCancel),
                changes: object.employees
                    .filter((emp) => !emp.keepOriginal)
                    .map((emp) => {
                        const selected = convertToObject(emp.selected);
                        selected.employee.residenceID = emp.original.residenceID; // make sure to always use the old residence ID since this cannot be updated
                        return { original: emp.original, selected };
                    }),
                residenceIDS: employees
            }
        });
        if (result.error) {
            throw new Error(result.error.data?.message || "Something went wrong, please try again later.");
        } else {
            return result.data?.data;
        }
    };

    useEffect(() => {
        // will run when no checkbox is shown and only insert will determine if cancel will be shown or not
        updateStep({ showCancel: !hasInsert && isAllKeepOriginal });
    }, [isAllKeepOriginal, object.summary?.toBeInserted]);

    useEffect(() => {
        const empnotkeeporiginal = object.employees.filter((emp) => !emp.keepOriginal);
        const isSkip = !includeUploads;
        const showCancel = isSkip ? !empnotkeeporiginal.length && object.keepUploads : !object.keepUploads;
        // will run during checkbox selection
        updateStep({ showCancel });
    }, [object.keepUploads, object?.employees.map((emp) => emp.id), includeUploads]);

    useEffect(() => {
        if (step && step.isAttemptingNext) {
            createPromiseToast(finalizeBulkUpload, {
                render: {
                    pending: () => "Generating result, please wait...",
                    success: (data) => {
                        typeof onSuccess === "function" &&
                            onSuccess({
                                ...(data?.data?.summary || {}),
                                keepOriginalRecords: object.employees.filter((emp) => emp.keepOriginal).length,
                                employees: object.employees.map((emp) => ({
                                    ...emp,
                                    id: emp.original.id, // set to actual id not the table ID so that we fetch the correct data when we view
                                    selected: { ...emp.selected, "employee.residenceID": emp.original.residenceID } // make sure to always use the old residence ID since this cannot be updated
                                })),
                                keepUploads: !step.showCancel
                            });
                        return "Result successfully generated.";
                    },
                    error: (data) => {
                        updateStep({ error: true, canNext: false });
                        return data?.message || "Error Occured.";
                    }
                }
            });
        }
    }, [step && step.isAttemptingNext]);

    const checkDuplicates = async () => {
        if (object.employees.length) return Promise.resolve(object.employees);
        const result = await checkDuplicate();
        if (result.error) {
            createToast(result.error?.data?.message || "Something went wrong, please try again later.", TOAST_TYPE.ERROR);
            updateStep({ error: true, canNext: false });
            return false;
        } else {
            const dups = result.data?.data?.duplicates || [];
            const summary = result.data?.data?.summary || { toBeInserted: 0, toBeUpdated: 0 };
            return { dups, summary };
        }
    };

    useEffect(() => {
        updateStep({ canNext: false });
        checkDuplicates()
            .then((res) => {
                const noDuplicates = res.dups && !res.dups.length;
                const hasInsert = !!res.summary?.toBeInserted;
                const hasInsertNoDup = noDuplicates && hasInsert;
                updateStep({ canNext: true, showCancel: !hasInsertNoDup });
                updateObject({ employees: res.dups, summary: res.summary, checking: false });
            })
            .catch(() => {
                updateObject({ employees: [], checking: false });
                updateStep({ error: true, canNext: false, showCancel: true });
            });
    }, []);

    const setEmployees = (newEmployees = []) => {
        updateObject({ employees: newEmployees });
    };

    const handleKeepUploads = (bool) => {
        updateObject({ keepUploads: bool });
    };

    const render = () => {
        const commonStyle = { maxWidth: "30rem", textAlign: "center" };
        if (isChecking) {
            return <Icon img={<Loader relative />} text="Checking for duplicates..." />;
        } else if (hasError) {
            return (
                <Icon
                    img={<WarningSVG color="#F33636" style={{ width: "7rem" }} />}
                    text={
                        <span style={{ maxWidth: "17rem", textAlign: "center", display: "inline-block" }}>
                            Unable to process request. Please try again later or contact support.
                        </span>
                    }
                />
            );
        } else if (!includeUploads && noDuplicates && !hasInsert) {
            return (
                <Icon
                    img={<ProceedCheckedSVG color="#10CC00" className="lg" />}
                    text={
                        <div className="flex gap-05 center" style={commonStyle}>
                            <span>No new records or changes were found.</span>
                        </div>
                    }
                />
            );
        } else if (includeUploads && noDuplicates && !hasInsert) {
            return (
                <Icon
                    img={<ProceedCheckedSVG color="#10CC00" className="lg" />}
                    text={
                        <div className="flex gap-05 center" style={commonStyle}>
                            <span>No new records or changes were found. Do you want to upload and update the employee files?</span>
                            <Checkbox onChange={handleKeepUploads} />
                        </div>
                    }
                />
            );
        } else if (noDuplicates && hasInsert) {
            return (
                <Icon
                    img={<SimpleSuccessSVG color="#10CC00" className="lg" />}
                    text={
                        <div className="flex center" style={commonStyle}>
                            <span>
                                No changes were found and <strong>{object.summary.toBeInserted}</strong> record(s) will be inserted. Click next to
                                proceed and show the final result.
                            </span>
                        </div>
                    }
                />
            );
        } else {
            return (
                <div className="tk-bulk-upload__check-duplicates__content">
                    <ManageDuplicates
                        employees={object.employees || []}
                        setEmployees={setEmployees}
                        onKeepUploads={handleKeepUploads}
                        isAllKeepOriginal={isAllKeepOriginal}
                        toBeInserted={object.summary?.toBeInserted}
                        keepUploads={object.keepUploads}
                        includeUploads={includeUploads}
                    />
                </div>
            );
        }
    };

    return (
        <div
            className="tk-bulk-upload__check-duplicates"
            style={{ pointerEvents: step.loading ? "none" : "auto", display: active ? "flex" : "none" }}
        >
            {render()}
        </div>
    );
}

BulkUploadCheckDuplicates.propTypes = {
    step: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        stepNumber: PropTypes.number,
        name: PropTypes.string,
        error: PropTypes.bool,
        isDone: PropTypes.bool,
        isAttemptingNext: PropTypes.bool,
        canNext: PropTypes.bool,
        showCancel: PropTypes.bool,
        loading: PropTypes.oneOfType([PropTypes.number, PropTypes.bool])
    }),
    token: PropTypes.string,
    onSuccess: PropTypes.func,
    updateStep: PropTypes.func,
    active: PropTypes.bool,
    currentData: PropTypes.any,
    employees: PropTypes.array,
    includeUploads: PropTypes.bool
};

Icon.propTypes = {
    img: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node])
};

export default BulkUploadCheckDuplicates;
