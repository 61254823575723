import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import isEqual from "lodash/isEqual";
import useFetchCountries from "../../../hooks/useFetchCountries";
import { isPhoneValid } from "../../../utilities/helper";
import Select from "../select/Select";

const isNumber = (v) => /^[0-9]*\.?[0-9]*$/.test(v);

function InputMobile({ icon, afterIcon, portalCodes, onChange, name, menuPlacement, ...inputProps }) {
    const [mobileCode, setMobileCode] = useState("");
    const [value, setValue] = useState("");

    const countries = useFetchCountries({ rtl: true, mobile: true });
    const codes = countries.codes;

    useEffect(() => {
        const phone = isPhoneValid(inputProps.value);
        const code = (phone.isValid && phone.countryCode) || "";
        const number = (phone.isValid && phone.phoneNumber.split(phone.countryCode).pop()) || inputProps.value;
        if (!isEqual(value, number)) {
            !mobileCode && setMobileCode(code);
            setValue(number);
        }
    }, [inputProps.value]);

    const handleChange = (e) => {
        let newval = e.target.value;
        if (!isNumber(newval)) newval = value;
        setValue(newval);
        typeof onChange === "function" &&
            onChange({
                target: {
                    name: e.target.name,
                    value: newval
                }
            });
    };

    return (
        <div className="tk-input__mobile">
            {icon && <div className="tk-input__icon-before">{icon}</div>}
            <div className="tk-input__inner">
                <div className="tk-input__mobile-code">
                    <Select
                        options={codes}
                        value={codes.find((code) => code.value === mobileCode) || ""}
                        onChange={(val) => {
                            onChange({ target: { name, value: inputProps.value, mobileCode: val.value } });
                            setMobileCode(val.value);
                        }}
                        {...(portalCodes ? { menuPortalTarget: document.body } : {})}
                        menuPlacement={menuPlacement}
                        placeholder="Code"
                        isOutlined
                        transparent
                        disabledOutline
                        noborder
                    />
                </div>
                <input {...inputProps} type="text" name={name} onChange={handleChange} value={value} minLength={8} />
            </div>
            {afterIcon && <div className="tk-input__icon-after">{afterIcon}</div>}
        </div>
    );
}

InputMobile.propTypes = {
    onChange: PropTypes.func,
    icon: PropTypes.any,
    afterIcon: PropTypes.any,
    name: PropTypes.string,
    portalCodes: PropTypes.bool,
    value: PropTypes.any,
    menuPlacement: PropTypes.string
};

export default InputMobile;
