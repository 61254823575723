import React from "react";
import { TABS } from "./const";
import EmployeeOtherIncomes from "../employeesOtherIncomes/EmployeeOtherIncomes";
import EmployeeDeductions from "../employeesDeductions/EmployeeDeductions";
import EmployeePenalties from "../employeesPenalties/EmployeePenalties";
import EmployeesSalary from "../employeesSalary/EmployeesSalary";

const TAB_COMPONENT_STYLES = {
    pagination: {},
    table: {}
};

export const createPayrollTabs = (tabs, props, activeTab) => {
    return Object.values(tabs)
        .filter((tab) => tab.isActive)
        .map((tab) => {
            switch (tab.id) {
                case TABS.OTHER_INCOMES.id: {
                    tab.component = activeTab == tab.id && <EmployeeOtherIncomes {...props} styles={TAB_COMPONENT_STYLES} height={29} />;
                    break;
                }
                case TABS.DEDUCTION.id: {
                    tab.component = activeTab == tab.id && <EmployeeDeductions {...props} styles={TAB_COMPONENT_STYLES} height={29} />;
                    break;
                }
                case TABS.PENALTIES.id: {
                    tab.component = activeTab == tab.id && <EmployeePenalties {...props} styles={TAB_COMPONENT_STYLES} height={29} />;
                    break;
                }
                case TABS.CALCULATION.id: {
                    tab.component = activeTab == tab.id && <EmployeesSalary {...props} styles={TAB_COMPONENT_STYLES} height={29} />;
                    break;
                }
                default: {
                    break;
                }
            }
            return tab;
        });
};
