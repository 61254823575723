import { SORT_ORDER } from "../../../utilities/const";

export const PAGE_OPTIONS = [10, 20, 30, 100];

export const PAGINATION_CONTROLS = {
    NEXT: 0x1,
    PREV: 0x2,
    END: 0x3,
    START: 0x4,
    GOTO: 0x5
};

export const TABLE_ORDER = SORT_ORDER;
