import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function SectionCollapse({ header, children, collapse, onCollapse, styles = {}, alwaysOpen }) {
    const [open, setOpen] = useState(collapse || alwaysOpen);

    useEffect(() => {
        if (open !== collapse) {
            setOpen(collapse);
        }
    }, [collapse]);

    useEffect(() => {
        if (!open) {
            typeof onCollapse === "function" && onCollapse();
        }
    }, [open]);

    const toggle = () => !alwaysOpen && setOpen(!open);

    const renderEmpty = <span>Empty</span>;

    return (
        <div className="tk-section-collapse" style={styles.parent || {}}>
            <div className="tk-section-collapse__header" style={styles.header || {}} onClick={toggle}>
                {!header ? "Section" : header}
                {!alwaysOpen && (
                    <div className={`tk-section-collapse__header__arrow ${open ? "active" : ""}`.trim()}>
                        <ExpandMoreIcon />
                    </div>
                )}
            </div>
            {open && (
                <div className="tk-section-collapse__body" style={styles.body || {}}>
                    <div className="tk-section-collapse__body__inner">{!children ? renderEmpty : children}</div>
                </div>
            )}
        </div>
    );
}

SectionCollapse.propTypes = {
    header: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
    collapse: PropTypes.bool,
    alwaysOpen: PropTypes.bool,
    onCollapse: PropTypes.func,
    styles: PropTypes.shape({
        header: PropTypes.object,
        body: PropTypes.object,
        parent: PropTypes.object
    })
};

export default SectionCollapse;
