export const BASE_CLASS = "tk-company-holidays";

export const FIELD = {
    NAME: "name",
    TYPE: "type",
    LEVEL: "level",
    CATEGORY: "category",
    START_DATE: "start_date",
    END_DATE: "end_date",
    IS_YEARLY: "is_yearly",
    NOTES: "notes",
    WORK_SHIFT: "work_shift_id",
    WORK_SITES: "work_site_ids",
    WORK_SHIFTS: "work_shift_ids",
    WORK_TYPES: "work_type_ids"
};

export const FILTER_TYPE = {
    CATEGORY: 0x1,
    LEVEL: 0x2,
    TYPE: 0x3,
    YEARLY: 0x4,
    DATE: 0x5,
    WORKSHIFT: 0x6
};

export const COMPANY_HOLIDAY_TYPE = {
    CHANGE_TIMING: "CHANGE_TIMING",
    OFF: "OFF"
};

export const COMPANY_HOLIDAY_CATEGORY = {
    NATIONAL: "NATIONAL",
    REGIONAL: "REGIONAL",
    RELIGIOUS: "RELIGIOUS",
    COMPANY_SPECIFIC: "COMPANY_SPECIFIC"
};

export const COMPANY_HOLIDAY_LEVEL = {
    COMPANY_WIDE: "COMPANY_WIDE", // normally, this will be used for National holidays as OFF, such as EID
    BY_WORK_TYPE: "BY_WORK_TYPE",
    BY_WORK_SITE: "BY_WORK_SITE",
    BY_WORK_SHIFT: "BY_WORK_SHIFT" //shift-
};
