import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import TableView from "../extra/table/TableView";
import { TABLE_ACTION_TYPE_COMMON, VIEW_TYPES } from "../../utilities/const";

const { PREVIEW, EDIT } = TABLE_ACTION_TYPE_COMMON;

/**
 * @description
 * A wrapper layout designed for pages that has pagination table and a common layout
 */
function PageWrapperLayout({ config, openAddModal, onAddModalClose, modal, onAction }) {
    const { data, refetch, tableConfig, view, fetching, CreateTableHeaders } = config || {};
    const { Update, View } = modal || {};

    const [openViewModal, setOpenViewModal] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(openAddModal);
    const [selected, setSelected] = useState(null);

    useEffect(() => {
        if (openAddModal && openAddModal !== openEditModal) {
            setOpenEditModal(openAddModal);
        }
    }, [openAddModal]);

    const handleModalBack = () => {
        setOpenViewModal(true);
        setOpenEditModal(false);
    };

    const handleModalEdit = () => {
        setOpenViewModal(false);
        setOpenEditModal(true);
    };

    const handleFinish = async (newData, isCreate) => {
        await refetch();
        setSelected(newData);
        if (isCreate) {
            setOpenEditModal(false);
        } else {
            handleModalBack();
        }
    };

    return (
        <>
            {(() => {
                switch (view) {
                    default:
                    case VIEW_TYPES.TABLE:
                        return (
                            <TableView
                                data={data}
                                headers={
                                    (CreateTableHeaders &&
                                        CreateTableHeaders({
                                            onAction: (row, type) => {
                                                setSelected(row);
                                                switch (type) {
                                                    case PREVIEW: {
                                                        setOpenViewModal(true);
                                                        break;
                                                    }
                                                    case EDIT: {
                                                        setOpenEditModal(true);
                                                        break;
                                                    }
                                                    default:
                                                        break;
                                                }
                                                typeof onAction == "function" && onAction(row, type);
                                            }
                                        })) ||
                                    []
                                }
                                refetch={refetch}
                                isLoading={fetching}
                                tableConfig={tableConfig}
                            />
                        );
                }
            })()}
            {openViewModal && View && (
                <View
                    open={openViewModal}
                    onClose={() => {
                        setSelected(null);
                        setOpenViewModal(false);
                    }}
                    data={selected}
                    onEdit={handleModalEdit}
                />
            )}
            {openEditModal && Update && (
                <Update
                    open={openEditModal}
                    onClose={() => {
                        setSelected(null);
                        setOpenEditModal(false);
                        typeof onAddModalClose === "function" && onAddModalClose();
                    }}
                    onBack={handleModalBack}
                    onFinish={handleFinish}
                    data={selected}
                />
            )}
        </>
    );
}

PageWrapperLayout.propTypes = {
    config: PropTypes.shape({
        data: PropTypes.array,
        refetch: PropTypes.func,
        tableConfig: PropTypes.object,
        view: PropTypes.string,
        fetching: PropTypes.bool,
        CreateTableHeaders: PropTypes.func,
        viewSubObj: PropTypes.shape({
            data: PropTypes.any,
            type: PropTypes.any
        })
    }),
    modal: PropTypes.shape({
        Update: PropTypes.any,
        View: PropTypes.any,
        ViewSub: PropTypes.any
    }),
    openAddModal: PropTypes.bool,
    onAddModalClose: PropTypes.func,
    onAction: PropTypes.func
};

export default PageWrapperLayout;
