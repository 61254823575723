import React, { useState, useRef, forwardRef } from "react";
import PropTypes from "prop-types";
import { getFilesFromDragEvent } from "html-dir-content";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import Loader from "../Loader";
import { TOAST_TYPE, acceptsToReadable, createToast } from "../../../utilities/helper";
import { FILE_MIME_TYPES } from "../../../utilities/const";
import { ERROR_TYPE } from "./const";

const DropZone = forwardRef(function DropZone(
    { onChange, htmlDirParams = { recursive: false, withFullPath: false, bail: 1000 }, isFolder, accepts = [], error, onError },
    ref
) {
    const [config, seConfig] = useState({
        isDraggingOver: false,
        dragTimer: null,
        gettingFiles: false
    });

    const internalRef = useRef(null);
    const inputRef = ref || internalRef;
    const readableAccepts = acceptsToReadable(accepts);
    const inputOtherAttr = isFolder ? { directory: "", webkitdirectory: "" } : {};

    const handleConfigChange = (newConfig = {}) => seConfig({ ...config, ...newConfig });

    const handleDrop = (e) => {
        e.preventDefault();
        handleConfigChange({ isDraggingOver: false, gettingFiles: true });
        getFilesFromDragEvent(e, { ...htmlDirParams, ...(isFolder ? { recursive: true, withFullPath: true } : {}) })
            .then((files) => {
                if (isFolder && !files.some((f) => f.hdcFullPath)) {
                    throw new Error("Only directory is allowed.");
                }
                typeof onChange === "function" && onChange(files);
                handleConfigChange({ isDraggingOver: false, gettingFiles: false });
            })
            .catch((error) => {
                createToast(error.message, TOAST_TYPE.ERROR);
                handleConfigChange({ isDraggingOver: false, gettingFiles: false });
                typeof onError === "function" &&
                    onError({
                        type: ERROR_TYPE.INVALID_DROP,
                        code: "invalid-drop",
                        message: error.message
                    });
            });
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        e.dataTransfer.dropEffect = "copy";
        clearTimeout(config.dragTimer);
    };

    const handleChange = (e) => {
        const files = e.target.files;
        const newFiles = [];
        // Rename each file
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            if (file?.webkitRelativePath) {
                const renamedFile = new File([file], file.webkitRelativePath, { type: file.type });
                renamedFile.hdcFullPath = file.webkitRelativePath;
                newFiles.push(renamedFile);
            } else {
                newFiles.push(file);
            }
        }
        typeof onChange === "function" && onChange(newFiles);
    };

    const handleDragEnter = (e) => {
        e.preventDefault();
        clearTimeout(config.dragTimer);
        handleConfigChange({ isDraggingOver: true });
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        handleConfigChange({ dragTimer: setTimeout(() => handleConfigChange({ isDraggingOver: false }), 200) });
    };

    return (
        <div
            className={`tk-file-upload__inner ${config.isDraggingOver ? "drag-active" : ""}`.trim()}
            style={error ? { borderColor: "red" } : {}}
            onDrop={handleDrop}
            tabIndex={-1}
            onDragOver={handleDragOver}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onClick={() => inputRef && inputRef.current && inputRef.current.click()}
        >
            {config.gettingFiles && <Loader />}
            <input ref={inputRef} type="file" style={{ display: "none" }} onChange={handleChange} {...inputOtherAttr} />
            <div className="icon">
                <FileUploadIcon />
            </div>
            <h3 className="title">
                {`Drag 'n' drop`} or <span className="focus">Choose a {isFolder ? "folder" : "file"}</span> to upload
            </h3>
            <span className="description">
                Supported Format(s): <span>{readableAccepts.join(", ")}</span>
            </span>
        </div>
    );
});

DropZone.propTypes = {
    htmlDirParams: PropTypes.shape({
        recursive: PropTypes.bool,
        withFullPath: PropTypes.bool,
        bail: PropTypes.number
    }),
    accepts: PropTypes.arrayOf(
        PropTypes.oneOf(
            Object.values(FILE_MIME_TYPES)
                .map((fm) => (typeof fm === "object" && Object.values(fm)) || fm)
                .flat()
        )
    ),
    error: PropTypes.bool,
    isFolder: PropTypes.bool,
    onChange: PropTypes.func,
    onError: PropTypes.func
};

export default DropZone;
