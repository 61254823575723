import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import PropTypes from "prop-types";
import { AUTH_RENDER_TYPE, useAuthSwitcher, useFetchSupportedGlobal, useLogout, useRefresh } from "../../../features/common/hooks";
import ModalSubscriptionPlan from "../../../features/admin/subscriptions/ModalSubscriptionPlan";
import { TOAST_TYPE, createToast } from "../../utilities/helper";
import { ReactComponent as LogoutSVG } from "../../../assets/images/logout.svg";
import useNavigate from "../../hooks/useNavigate";
import Loader from "../extra/Loader";
import Sidebar from "../extra/Sidebar";
import Logo from "../extra/Logo";
import RealTimeClock from "../extra/RealTimeClock";
import MyTooltip, { PLACES } from "../extra/Tooltip";
import Header from "../common/Header";
import RouteChecking from "../../../features/common/RouteChecking";
import Navigation from "../../classes/Navigation";
import useGetUserType from "../../hooks/useGetUserType";
import useViewport from "../../hooks/useViewport";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { selectSidebarCollapse, setSidebarCollapse } from "../../../features/common/slice";

function SidebarTop() {
    return (
        <div className="flex column gap-05">
            <Logo styles={{ parent: { marginLeft: "5px" } }} size={5.625} svg />
            <RealTimeClock isTwelveHour />
        </div>
    );
}

function SidebarFooter({ compact, logout }) {
    const isSidebarCollapsed = useAppSelector(selectSidebarCollapse);
    return (
        <div className="tk-auth-layout__sidebar-footer danger">
            <MyTooltip
                className="tk-auth-layout__sidebar-footer__inner"
                onClick={logout}
                role="button"
                tabIndex={-1}
                position={PLACES.TOP}
                message={isSidebarCollapsed ? "Logout" : ""}
            >
                <div className="tk-auth-layout__sidebar-footer__item">
                    <LogoutSVG style={isSidebarCollapsed ? { marginRight: ".5rem" } : {}} />
                    {!compact && (
                        <span
                            style={{
                                position: "relative",
                                display: "inline-block",
                                left: "9px"
                            }}
                        >
                            Logout
                        </span>
                    )}
                </div>
            </MyTooltip>
        </div>
    );
}

function DefaultLayout({ config, logout, compact, setCompactSidebar }) {
    const navigate = useNavigate();
    const viewport = useViewport();
    const dispatch = useAppDispatch();

    const renderSidebar = (
        <Sidebar
            head={<SidebarTop />}
            items={config.sidebarData.top}
            extraItems={config.sidebarData.bottom}
            footer={<SidebarFooter compact={compact} logout={logout} />}
            onItemSelect={(item) => navigate(item.path)}
            onChange={(val) => {
                setCompactSidebar(val);
                dispatch(setSidebarCollapse(val));
            }}
            isMobile={viewport.isMobile}
        />
    );

    const renderHeader = (
        <div
            className={`tk-auth-layout__header ${(config.isSettingSetupRoute && "has-shadow") || ""}`.trim()}
            style={config.isSettingSetupRoute ? { width: "100%" } : {}}
        >
            <Header user={config.user || {}} isSuperAdmin={config.isSuperAdmin} hasLogo={config.isSettingSetupRoute} />
        </div>
    );

    const renderInner = (
        <div className="tk-auth-layout__inner">
            <div className="tk-auth-layout__inner-wrapper">
                <Outlet />
            </div>
        </div>
    );

    const renderContent = config.isFullPage ? <Outlet /> : renderInner;

    if (!config.isFullPageWithHeader && !config.isFullPageWithSidebar) {
        return renderContent;
    } else if (config.isFullPageWithHeader && !config.isFullPageWithSidebar) {
        return (
            <div className="tk-auth-layout__content">
                {renderHeader}
                {renderContent}
            </div>
        );
    } else if (!config.isFullPageWithHeader && config.isFullPageWithSidebar) {
        return (
            <>
                {renderSidebar}
                <div className="tk-auth-layout__content">{renderContent}</div>
            </>
        );
    } else
        return (
            <>
                {renderSidebar}
                <div className="tk-auth-layout__content">
                    {renderHeader}
                    {renderContent}
                </div>
            </>
        );
}

function Authlayout() {
    const [compactSidebar, setCompactSidebar] = useState(false);
    const [openSubscriptionPlan, setOpenSubscriptionPlan] = useState(true);

    const config = useAuthSwitcher({ compactSidebar });
    const [logout, isLoggingOut] = useLogout();
    const [refresh] = useRefresh();
    const [, fetchingSupportedGlobals] = useFetchSupportedGlobal();
    const userType = useGetUserType({ isBoolean: true });

    const isLoading = fetchingSupportedGlobals || isLoggingOut;
    const isSettingsSetup = config.renderType == AUTH_RENDER_TYPE.SETTING_SETUP;

    useEffect(() => {
        // we need to refresh the token if we are close to expiring so that the time to expire is always updated
        if (config.isCloseToExpiring && !config.isExpired && config.isPrevPathDashboardRoute) {
            refresh();
        }
    }, [config.currentPath]);

    if (!config.hasUser) {
        return <Navigate to={userType.isAdmin ? Navigation.Routes.ADMIN_AUTH.path : Navigation.Routes.SIGNIN.path} />;
    }

    return (
        <div className="tk-auth-layout">
            {!userType.isAdmin && <RouteChecking />}
            {isLoading ? (
                <Loader />
            ) : (
                <div className="tk-auth-layout-container">
                    <DefaultLayout config={config} logout={logout} compact={compactSidebar} setCompactSidebar={setCompactSidebar} />
                </div>
            )}
            {config.showDailyPromotionUpgrade && openSubscriptionPlan && !isSettingsSetup && (
                <ModalSubscriptionPlan
                    open={openSubscriptionPlan}
                    onChange={setOpenSubscriptionPlan}
                    user={config.user}
                    onBack={() => {
                        createToast("Subscription updated successfully.", TOAST_TYPE.SUCCESS);
                        setOpenSubscriptionPlan(false);
                    }}
                    isPromote
                />
            )}
        </div>
    );
}

export default Authlayout;

SidebarFooter.propTypes = {
    compact: PropTypes.bool,
    logout: PropTypes.func
};

DefaultLayout.propTypes = {
    config: PropTypes.object,
    compact: PropTypes.bool,
    logout: PropTypes.func,
    setCompactSidebar: PropTypes.func
};
