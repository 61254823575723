import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import isEqual from "lodash/isEqual";

function InputPassword({ showPass, ...inputProps }) {
    const [value, setValue] = useState("");

    useEffect(() => {
        if (!isEqual(value, inputProps.value)) {
            setValue(inputProps.value);
        }
    }, [inputProps.value]);

    const handleChange = (e) => {
        const value = e.target.value;
        setValue(value);
        typeof inputProps.onChange === "function" && inputProps.onChange(e);
    };

    return <input {...inputProps} type={showPass ? "text" : "password"} onChange={handleChange} value={value} />;
}

InputPassword.propTypes = {
    onChange: PropTypes.func,
    showPass: PropTypes.bool
};

export default InputPassword;
