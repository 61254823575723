export const defaultColors = [
    "#e25f51", // A
    "#f26091", // B
    "#bb65ca", // C
    "#9572cf", // D
    "#7884cd", // E
    "#5b95f9", // F
    "#48c2f9", // G
    "#45d0e2", // H
    "#48b6ac", // I
    "#52bc89", // J
    "#9bce5f", // K
    "#d4e34a", // L
    "#feda10", // M
    "#f7c000", // N
    "#ffa800", // O
    "#ff8a60", // P
    "#c2c2c2", // Q
    "#8fa4af", // R
    "#a2887e", // S
    "#a3a3a3", // T
    "#afb5e2", // U
    "#b39bdd", // V
    "#c2c2c2", // W
    "#7cdeeb", // X
    "#bcaaa4", // Y
    "#add67d" // Z
];
