import cloneDeep from "lodash/cloneDeep";
import { createSlice } from "@reduxjs/toolkit";
import { PAGE_OPTIONS } from "../../../common/components/extra/table/const";
import { VIEW_TYPES } from "../../../common/utilities/const";

const SLICE_NAME_KEY = "subscriptions";

const initialState = {
    data: [],
    tableConfig: {
        page: 1,
        pageSize: PAGE_OPTIONS[0],
        search: "",
        sortBy: "title",
        order: "ASC",
        totalPage: 0
    },
    current: null,
    // when upgrading subscription update upgrade for the selected subscription
    upgradeSelections: [],
    loading: false,
    isSearching: false,
    view: VIEW_TYPES.TABLE
};

export const subscriptionsSlice = createSlice({
    name: SLICE_NAME_KEY,
    initialState,
    reducers: {
        setSearching: (state, action) => {
            const clone = cloneDeep(state);
            clone.isSearching = action.payload;
            return clone;
        },
        setUpgradeSelections: (state, action) => {
            const clone = cloneDeep(state);
            clone.upgradeSelections = Array.isArray(action.payload) ? action.payload : [];
            return clone;
        },
        setCurrent(state, action) {
            const clone = cloneDeep(state);
            clone.current = action.payload;
            return clone;
        },
        setData: (state, action) => {
            const clone = cloneDeep(state);
            const data = action.payload;
            Array.isArray(data) && (clone.data = data || []);
            return clone;
        },
        setTableConfig: (state, action) => {
            const clone = cloneDeep(state);
            const tableConfig = action.payload || {};
            tableConfig && (clone.tableConfig = { ...state.tableConfig, ...tableConfig });
            return clone;
        },
        setLoading: (state, action) => {
            const clone = cloneDeep(state);
            const loading = action.payload;
            typeof loading === "boolean" && loading !== state.loading && (clone.loading = loading);
            return clone;
        },
        setView: (state, action) => {
            const clone = cloneDeep(state);
            const view = action.payload;
            view && view !== state.view && (clone.view = view);
            return clone;
        },
        setState: (state, action) => {
            const clone = cloneDeep(state);
            const { tableConfig, view, data, loading, upgradeSelections } = action.payload;
            tableConfig && (clone.tableConfig = { ...state.tableConfig, ...tableConfig });
            data && (clone.data = data || []);
            view && view !== state.view && (clone.view = view);
            typeof loading === "boolean" && loading !== state.loading && (clone.loading = loading);
            Array.isArray(upgradeSelections) && (clone.upgradeSelections = upgradeSelections);
            return clone;
        },
        reset: () => initialState
    }
});

export const selectSearching = (state) => state[SLICE_NAME_KEY].isSearching;
export const selectCurrent = (state) => state[SLICE_NAME_KEY].current;
export const selectUpgradeSelections = (state) => state[SLICE_NAME_KEY].upgradeSelections;
export const selectData = (state) => state[SLICE_NAME_KEY].data;
export const selectTableConfig = (state) => state[SLICE_NAME_KEY].tableConfig;
export const selectLoading = (state) => state[SLICE_NAME_KEY].loading;
export const selectView = (state) => state[SLICE_NAME_KEY].view;
export const selectState = (state) => state[SLICE_NAME_KEY];
export const { setSearching, setUpgradeSelections, setCurrent, setData, setTableConfig, setLoading, setView, setState, setUpgrade, reset } =
    subscriptionsSlice.actions;
export default subscriptionsSlice.reducer;
