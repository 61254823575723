import React, { useState } from "react";
import PropTypes from "prop-types";
import PushPinIcon from "@mui/icons-material/PushPin";
import { useMarkerRef, AdvancedMarker, Pin, InfoWindow } from "@vis.gl/react-google-maps";

function Marker({ map, lat, lng, style, hover, isCenter, content, closeInfo }) {
    const [toggleWindow, setToggleWindow] = useState(false);
    const [markerRef, marker] = useMarkerRef();
    const handleClick = () => setToggleWindow((prev) => !prev);

    return (
        <div
            className={`tk-map-marker ${hover ? "tk-map-marker--hover" : ""} ${isCenter ? "tk-map-marker--centered" : ""}`.trim()}
            style={style || {}}
        >
            <AdvancedMarker map={map} ref={markerRef} position={{ lat, lng }} onClick={handleClick}>
                {isCenter ? <Pin background={"#FBBC04"} glyphColor={"#000"} borderColor={"#000"} /> : <PushPinIcon style={{ color: "#0052CC" }} />}
            </AdvancedMarker>
            {!closeInfo && toggleWindow && (
                <InfoWindow map={map} anchor={marker} onCloseClick={() => setToggleWindow(false)} shouldFocus>
                    {typeof content === "string" ? <span style={{ fontWeight: 600 }}>{content}</span> : content}
                </InfoWindow>
            )}
        </div>
    );
}

export default Marker;

Marker.propTypes = {
    map: PropTypes.object,
    maps: PropTypes.object,
    hover: PropTypes.bool,
    style: PropTypes.object,
    isCenter: PropTypes.bool,
    zoom: PropTypes.number,
    lat: PropTypes.number,
    lng: PropTypes.number,
    content: PropTypes.any,
    closeInfo: PropTypes.bool
};
