import React, { useState } from "react";
import PropTypes from "prop-types";
import { selectTableConfig } from "./slice";
import CreateTableHeaders from "./CreateTableHeaders";
import ViewModal from "./ViewModal";
import UpdateModal from "./UpdateModal";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { COMPANY_STATUS, VIEW_TYPES } from "../../../common/utilities/const";
import ViewModalSub from "./ViewModalSub";
import { MORE_ACTIONS, TABLE_ACTIONS, UPLOAD_FIELDS, VIEW_MODAL_TYPE } from "./const";
import PageWrapperLayout from "../../../common/components/layout/PageWrapperLayout";
import { usePaginateCompanies } from "./hooks";

function Companies({ view }) {
    const [viewObject, setViewObject] = useState({
        type: null,
        data: null
    });

    const tableConfig = useAppSelector(selectTableConfig);

    const [data, fetching, refetch, { updateStatus }] = usePaginateCompanies();

    const updateViewObject = (newObject = {}) => setViewObject({ ...viewObject, ...newObject });

    const handleAction = (row, type) => {
        if (!row) {
            return;
        }
        switch (type) {
            case TABLE_ACTIONS.APPROVE: {
                updateStatus(row.id, [...row.status, COMPANY_STATUS.VERIFIED]);
                break;
            }
            case TABLE_ACTIONS.CONTACT_DETAILS: {
                updateViewObject({
                    type: VIEW_MODAL_TYPE.CONTACT_DETAILS,
                    data: row
                });
                break;
            }
            case MORE_ACTIONS.VIEW_CONTRACT.id: {
                updateViewObject({
                    type: VIEW_MODAL_TYPE.CONTRACT_COPY,
                    data: {
                        filename: row.uploads[UPLOAD_FIELDS.COMPANY_CONTRACT.key],
                        id: row.id
                    }
                });
                break;
            }
            case MORE_ACTIONS.VIEW_EID.id: {
                updateViewObject({
                    type: VIEW_MODAL_TYPE.ESTABLISHMENT_ID_COPY,
                    data: {
                        filename: row.uploads[UPLOAD_FIELDS.ESTABLISHMENT_ID.key],
                        id: row.id
                    }
                });
                break;
            }
            case MORE_ACTIONS.VIEW_LICENSE.id: {
                updateViewObject({
                    type: VIEW_MODAL_TYPE.LICENSE_COPY,
                    data: {
                        filename: row.uploads[UPLOAD_FIELDS.LICENSE.key],
                        id: row.id
                    }
                });
                break;
            }
            default:
                break;
        }
    };

    return (
        <>
            <PageWrapperLayout
                config={{
                    data,
                    refetch,
                    tableConfig,
                    view,
                    fetching,
                    CreateTableHeaders
                }}
                modal={{
                    View: ViewModal,
                    Update: UpdateModal,
                    ViewSub: ViewModalSub
                }}
                onAction={handleAction}
            />
            {!!viewObject.type && (
                <ViewModalSub
                    open={!!viewObject.type}
                    onChange={(bool) => updateViewObject({ type: bool ? viewObject.type : null })}
                    type={viewObject.type}
                    data={viewObject.data}
                />
            )}
        </>
    );
}

Companies.propTypes = {
    view: PropTypes.oneOf(Object.values(VIEW_TYPES))
};

export default Companies;
