import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import debounce from "lodash/debounce";
import FormAuthTemplate from "../../../common/components/layout/FormAuthTemplate";
import { useSendVerifyEmailRequestMutation } from "./companyAPI";
import useTimer from "../../../common/hooks/useTimer";
import Loader from "../../../common/components/extra/Loader";

const DEFAULT_TIMEOUT = 60 * 1000;

function EmailVerification({ starting, email, token, company_id, role_id, buttonExtra, title, subtext }) {
    const [sendVerifyEmailRequest, { isLoading, error }] = useSendVerifyEmailRequestMutation();
    const [timer, startTimer] = useTimer(starting ? 0 : DEFAULT_TIMEOUT);

    useEffect(() => {
        if (error) toast.error("Something went wrong. Please try again later.");
    }, [error]);

    const handleSubmit = async () => {
        startTimer(DEFAULT_TIMEOUT);
        await sendVerifyEmailRequest({ body: { token, company_id, role_id, email } });
        toast.info("Check your inbox for an email with instructions to verify your email address.");
    };

    let label = isLoading ? "Resending email..." : "Resend Email";
    if (timer > 0) label = `${timer / 1000}s`;

    return (
        <div className="tk-verify-email" style={{ position: "relative" }}>
            {isLoading && <Loader />}
            <FormAuthTemplate
                title={title || "Verify your email address"}
                subtext={subtext || "To start using TimeKeeper confirm your email address with the email we sent to:"}
                submitLabel={label}
                onSubmit={debounce(handleSubmit, timer)}
                footer={
                    <div className="tk-verify-email__footer flex gap-05 center">
                        Need help?
                        <strong>
                            <a href="mailto:support@time-keeper.app">Contact customer support</a>
                        </strong>
                    </div>
                }
                buttonExtra={buttonExtra}
                disabled={!!timer || isLoading}
            >
                <h4>{email}</h4>
            </FormAuthTemplate>
        </div>
    );
}
EmailVerification.propTypes = {
    title: PropTypes.string,
    subtext: PropTypes.string,
    starting: PropTypes.bool,
    email: PropTypes.string,
    token: PropTypes.string,
    company_id: PropTypes.any,
    role_id: PropTypes.any,
    buttonExtra: PropTypes.any
};

export default EmailVerification;
