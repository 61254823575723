import React from "react";
import PropTypes from "prop-types";
import { CREDIT_CARD_FIELDS } from "./const";
import Input, { INPUT_TYPE } from "../../../common/components/extra/form/Input";

const { CARD_HOLDER_NAME, CARD_NUMBER, EXPIRY_DATE, SECURITY_CODE } = CREDIT_CARD_FIELDS;

function PaymentCreditCard({ onChange, isLoading, data }) {
    const handleChange = (e) => {
        typeof onChange === "function" && onChange(e.target.name, e.target.value);
    };

    return (
        <div className="tk-credit-card">
            <div className="tk-credit-card__icons flex gap-05">
                <img className="img-responsive" src="/images/mastercard-icon.svg" alt="" width={50} />
                <img className="img-responsive" src="/images/visa-icon.svg" alt="" width={55} />
            </div>
            <div className="tk-credit-card__content">
                <Input
                    name={CARD_HOLDER_NAME}
                    type={INPUT_TYPE.TEXT}
                    label="Card Holder Name"
                    value={data[CARD_HOLDER_NAME]}
                    onChange={(e) => handleChange(e)}
                    disabled={isLoading}
                    autoComplete="off"
                    required
                    autoFocus
                />
                <Input
                    name={CARD_NUMBER}
                    type={INPUT_TYPE.TEXT}
                    label="Card Number"
                    value={data[CARD_NUMBER]}
                    onChange={(e) => handleChange(e)}
                    disabled={isLoading}
                    minLength={14}
                    required
                />
                <Input
                    name={EXPIRY_DATE}
                    type={INPUT_TYPE.MONTH_YEAR}
                    label="Expiry Date"
                    value={data[EXPIRY_DATE]}
                    onChange={(e) => handleChange(e)}
                    disabled={isLoading}
                    required
                    uptoCurrent
                />
                <Input
                    name={SECURITY_CODE}
                    type={INPUT_TYPE.TEXT}
                    label="Security Code"
                    value={data[SECURITY_CODE]}
                    onChange={(e) => handleChange(e)}
                    maxLength={3}
                    disabled={isLoading}
                    onlyNumber
                    required
                />
            </div>
        </div>
    );
}

export default PaymentCreditCard;

PaymentCreditCard.propTypes = {
    onChange: PropTypes.func,
    isLoading: PropTypes.bool,
    data: PropTypes.object
};
