import React from "react";
import PropTypes from "prop-types";

function Card({ title, subtext, footerExtra, children, styles = {}, className }) {
    return (
        <div className={`tk-card ${className || ""}`.trim()} style={styles.parent}>
            <div className="tk-card__inner" style={styles.content}>
                <div className="tk-card__title">{title}</div>
                <div className="tk-card__subtext">{subtext}</div>
                <div className="tk-card__content">{children}</div>
                <div className="tk-card__footer">{footerExtra}</div>
            </div>
        </div>
    );
}

Card.propTypes = {
    className: PropTypes.string,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    subtext: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    footerExtra: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
    styles: PropTypes.shape({
        parent: PropTypes.object,
        content: PropTypes.object
    })
};

export default Card;
