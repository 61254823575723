import React from "react";
import PropTypes from "prop-types";
import Modal from "../../../common/components/extra/Modal";
import Input, { INPUT_TYPE } from "../../../common/components/extra/form/Input";
import Tag from "../../../common/components/extra/Tag";
import FormSection from "../../../common/components/extra/FormSection";
import { sanitizeWords, toReadableFromDate } from "../../../common/utilities/helper";
import { useFetchSubscription } from "./hooks";
import { FIELDS } from "./const";

const { TITLE, AMOUNT, DESCRIPTION, EMPLOYEE_LIMIT, RECURRENCE, IS_ACTIVE } = FIELDS;

function ViewModal({ open, onClose, onEdit, data }) {
    const [result, isLoading] = useFetchSubscription({
        id: data.id,
        cached: true
    });

    const statusTagClass = (val) => (!val && "red") || "green";

    return (
        <Modal open={open} onClose={onClose}>
            <div className="view-modal">
                <FormSection header="Subscription Details" onEdit={onEdit}>
                    <Input type={INPUT_TYPE.TEXT} label="Subscription ID" renderValue={result.id} isLoading={isLoading} readOnly />
                    <Input
                        type={INPUT_TYPE.TEXT}
                        label={TITLE.label}
                        renderValue={<span className="capitalize">{result[TITLE.name]}</span>}
                        isLoading={isLoading}
                        readOnly
                    />
                    <Input type={INPUT_TYPE.TEXT} label={AMOUNT.label} renderValue={result.amount} isLoading={isLoading} readOnly />
                    <Input type={INPUT_TYPE.TEXT} label={EMPLOYEE_LIMIT.label} renderValue={result.employee_limit} isLoading={isLoading} readOnly />
                    <Input
                        type={INPUT_TYPE.TEXT}
                        label={RECURRENCE.label}
                        renderValue={sanitizeWords(result.recurrence)}
                        isLoading={isLoading}
                        readOnly
                    />
                    <Input
                        type={INPUT_TYPE.TEXT}
                        label={DESCRIPTION.label}
                        renderValue={<p style={{ marginLeft: "3rem", fontWeight: "600" }}>{result.description}</p>}
                        isLoading={isLoading}
                        readOnly
                    />
                </FormSection>
                <FormSection header="Status" onEdit={onEdit}>
                    <Input
                        type={INPUT_TYPE.TEXT}
                        label={IS_ACTIVE.label}
                        renderValue={<Tag className={statusTagClass(result[IS_ACTIVE.name])}>{result[IS_ACTIVE.name] ? "active" : "inactive"}</Tag>}
                        isLoading={isLoading}
                        readOnly
                    />
                    <Input type={INPUT_TYPE.TEXT} label="Created" renderValue={toReadableFromDate(result.createdAt)} isLoading={isLoading} readOnly />
                    <Input type={INPUT_TYPE.TEXT} label="Updated" renderValue={toReadableFromDate(result.updatedAt)} isLoading={isLoading} readOnly />
                    <Input
                        type={INPUT_TYPE.TEXT}
                        label="Added By"
                        renderValue={result.added_by ? result.SuperAdmin && result.SuperAdmin.name : ""}
                        isLoading={isLoading}
                        readOnly
                    />
                </FormSection>
            </div>
        </Modal>
    );
}

ViewModal.propTypes = {
    open: PropTypes.bool,
    data: PropTypes.object,
    onClose: PropTypes.func,
    onEdit: PropTypes.func
};

export default ViewModal;
