import React from "react";
import PropTypes from "prop-types";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import { CURRENCIES, PAYMENT_STATUS, PAYMENT_TYPE, RECURRENCE } from "../../../common/utilities/const";
import { addCommasToMoney, sanitizeWords } from "../../../common/utilities/helper";
import { formatBankTransferDetails } from "../companyPayment/helper";

function Invoice({ data = { subscription: {}, payment: {} } }) {
    const createRow = (label, value) => (
        <div className="flex gap-05" style={{ justifyContent: "space-between" }}>
            <span className="fade">{label}:</span>
            <span
                className="bold"
                style={{
                    maxWidth: "10rem",
                    textAlign: "right"
                }}
            >
                {value}
            </span>
        </div>
    );

    return (
        <div className="tk-invoice flex column gap-1 center w100">
            <div className="flex column gap-1 center">
                <PendingActionsIcon className="pending-icon" />
                <h3>Pending Payment</h3>
                <h2>
                    {addCommasToMoney(data.subscription.amount)} {data.subscription.currency}
                </h2>
                <div className="current-plan">
                    <span>Plan</span>
                    <h2 className="primary-color" style={{ textTransform: "uppercase" }}>
                        {data.subscription.title}
                    </h2>
                </div>
                <p className="bold" style={{ marginTop: 0 }}>
                    Can add up to {data.subscription.employee_limit} users.
                </p>
            </div>
            <div className="line"></div>
            <div className="flex column gap-1 center w100">
                <div className="flex column gap-05 w100">
                    {createRow("Ref #", data.payment.ref_number)}
                    {createRow("Transfer Ref #", data.payment.trans_ref_number)}
                    {createRow("Recurrence", sanitizeWords(data.subscription.recurrence))}
                    {createRow("Payment Method", sanitizeWords(data.payment.type))}
                </div>
            </div>
            {data.payment.instructions && (
                <div className="flex column w100">
                    <div className="flex cemter w100 gap-05" style={{ alignItems: "center" }}>
                        <span className="fade" style={{ whiteSpace: "nowrap" }}>
                            Bank Transfer Instructions
                        </span>
                        <div className="line"></div>
                    </div>
                    <div className="instructions flex column" style={{ textAlign: "left", fontWeight: "500" }}>
                        {formatBankTransferDetails(data.payment.instructions)}
                    </div>
                </div>
            )}
            <div className="line solid"></div>
            <p className="fade">You may need to manually renew your subscription.</p>
        </div>
    );
}

export default Invoice;

Invoice.propTypes = {
    id: PropTypes.string,
    data: PropTypes.shape({
        subscription: PropTypes.shape({
            employee_limit: PropTypes.number,
            title: PropTypes.string,
            recurrence: PropTypes.oneOf(Object.values(RECURRENCE)),
            amount: PropTypes.number,
            currency: PropTypes.oneOf(Object.values(Object.keys(CURRENCIES)))
        }),
        payment: PropTypes.shape({
            ref_number: PropTypes.string,
            trans_ref_number: PropTypes.string,
            paid_date: PropTypes.string,
            type: PropTypes.oneOf(Object.values(PAYMENT_TYPE)),
            status: PropTypes.oneOf(Object.values(PAYMENT_STATUS)),
            instructions: PropTypes.string
        })
    }),
    user: PropTypes.shape({
        email: PropTypes.string,
        name: PropTypes.string
    })
};
