import React from "react";
import moment from "moment-timezone";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import isEqual from "lodash/isEqual";
import phone from "phone";
import merge from "lodash/merge";
import set from "lodash/set";
import cloneDeep from "lodash/cloneDeep";
import transform from "lodash/transform";
import isObject from "lodash/isObject";
import omitBy from "lodash/omitBy";
import assign from "lodash/assign";
import uniqueId from "lodash/uniqueId";
import flatMap from "lodash/flatMap";
import html2canvas from "html2canvas";
import parsePhoneNumber from "libphonenumber-js";
import { TinyColor } from "@ctrl/tinycolor";
import cardValidator from "card-validator";
import { COUNTRIES, FALSE_VALUES, FILE_MIME_TYPES, FILTER_ALL_VALUE, SORT_ORDER } from "./const";
import Button from "../components/extra/Button";

export const TOAST_TYPE = toast.TYPE;

/**
 * @param {String} message
 * @param {String} type
 * @param {Object} options
 * @returns {toast}
 */
export const createToast = (message, type = TOAST_TYPE.SUCCESS, options = {}) => {
    options = {
        type,
        ...(options || {})
    };
    // comment this out for now
    // const TOAST_ID = "tk-toast";
    // options.toastId = TOAST_ID;
    // const isActive = toast.isActive(TOAST_ID);
    // if (isActive) return;
    return toast(message, options);
};

export const createPromiseToast = (func, { render: { pending, success, error } = {} } = {}) => {
    return toast.promise(func, {
        pending: {
            render() {
                return (typeof pending === "function" && pending()) || "Processing, please wait...";
            }
        },
        success: {
            render(data) {
                return (typeof success === "function" && success(data)) || "Process complete.";
            }
        },
        error: {
            render(data) {
                return (typeof error === "function" && error(data)) || "Error occured.";
            }
        }
    });
};

export const capitalizeWords = (str, splitSymbol = " ") => {
    const capitalize = (s) => s.charAt(0).toUpperCase() + s.slice(1);
    const normalized = str
        .split(splitSymbol)
        .map((word) => capitalize(word))
        .join(" ");
    return normalized;
};

export const createConfirmAlert = ({ title, content, onConfirm, confirmLabel, rejectLabel, onCancel }) => {
    confirmAlert({
        customUI: ({ onClose }) => (
            <div className="tk-confirm-alert__container">
                <div className="tk-confirm-alert__header">
                    <h3>{title || "Are you sure?"}</h3>
                </div>
                <div className="tk-confirm-alert__content">{content}</div>
                <div className="tk-confirm-alert__footer">
                    <Button
                        onClick={() => {
                            onClose();
                            typeof onCancel == "function" && onCancel();
                        }}
                        small
                        transparent
                    >
                        {confirmLabel || "No"}
                    </Button>
                    <Button onClick={() => onConfirm(onClose)} className="primary" small>
                        {rejectLabel || "Yes"}
                    </Button>
                </div>
            </div>
        ),
        overlayClassName: "tk-confirm-alert"
    });
};

export const transformCountries = (countries, { isRight } = {}) => {
    const filterISOAlpha = ["ISR"]; // filter out israel
    const style = {};
    if (!isRight) {
        style.justifyContent = "flex-start";
        style.textAlign = "left";
    }
    return countries
        .filter((ctr) => !filterISOAlpha.includes(ctr.cca3))
        .map((ctr) => ({
            ...ctr,
            value: ctr.cca2,
            label: (
                <div className="flex gap-05" style={style}>
                    <img src={ctr.flags.png} alt="flag" width={5} height={5} style={{ width: "1.5rem", height: "auto" }} />
                    <span style={{ textAlign: "left" }}>{ctr.name.common}</span>
                </div>
            )
        }));
};

export const hasSpecialCharacter = (str) => {
    // eslint-disable-next-line no-useless-escape
    const specialCharRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/;
    return specialCharRegex.test(str);
};

export const sanitizeWords = (str, split = "_", lowerCase = true) => {
    if (!str) return "";
    str = str.trim();
    if (lowerCase) {
        str = str.toLowerCase();
    }
    str = str.split(split);
    const hasSlash = (s) => s.includes("/");
    const hasParenthesis = (s) => s.includes("(") && s.includes(")");

    if (str.some(hasSlash)) {
        str = str.map((s) => hasSlash(s) && s.replace(/\/\w/g, (match) => match.toUpperCase()));
    }
    if (str.some(hasParenthesis)) {
        str = str.map((s) => hasParenthesis(s) && s.replace(/\(([^)]+)\)/g, (match, p1) => `(${p1.toUpperCase()})`));
    }
    str = str.join(" ");
    return (typeof str === "string" && capitalizeWords(str)) || "";
};

export const toReadableSelectOptions = (obj = {}) => {
    const arr = Object.values(obj);
    return arr.map((value) => ({ value, label: typeof value == "string" ? sanitizeWords(value) : value.toString() }));
};

export const transformStringToObject = (stringifiedObject = "", value, origin = {}) => {
    if (!stringifiedObject) {
        return origin ? origin : {};
    }
    const clone = cloneDeep(origin);
    const toObj = cloneDeep(set({}, stringifiedObject, value));
    const merged = merge(clone, toObj);
    return merged;
};

export const convertObjectToString = (obj) => {
    function convert(obj, parentKey = "") {
        return flatMap(obj, (value, key) => {
            const newKey = parentKey ? `${parentKey}.${key}` : key;
            return isObject(value) ? convert(value, newKey) : { [newKey]: value };
        });
    }
    return merge({}, ...convert(obj));
};

export const trimToLowerJoin = (str = "", joinSymbol = "-") =>
    (typeof str === "string" && str.trim().toLowerCase().split(" ").join(joinSymbol)) || "";

export const megabytesToBytes = (megabytes = 1) => {
    // 1 MB = 1024 * 1024 bytes
    return megabytes * 1024 * 1024;
};

export const bytesToMegabytes = (bytes) => {
    // 1 MB = 1024 * 1024 bytes
    return bytes / (1024 * 1024);
};

export const bytesToReadable = (bytes) => {
    if (bytes < 1024) {
        return bytes + " bytes";
    } else if (bytes < 1024 * 1024) {
        return (bytes / 1024).toFixed(2) + " KB";
    } else {
        return (bytes / (1024 * 1024)).toFixed(2) + " MB";
    }
};

export const acceptsToReadable = (accepts = []) =>
    (
        accepts &&
        accepts.map((accept) =>
            accept.includes(".sheet")
                ? "xlsx"
                : accept.includes(".ms-excel")
                  ? "xls"
                  : accept.split("/").length > 1
                    ? accept.split("/").pop()
                    : accept
        )
    ).map((ac) => ac.toUpperCase()) || [];

export const getPreviousValue = (str, splitby = "/") => {
    const parts = str.split(splitby);
    if (parts.length < 2) {
        return null;
    }
    return parts[parts.length - 2];
};

export const isScrollAtBottom = (element, offset = 0) => {
    return Math.ceil(element.scrollTop + element.clientHeight + offset) >= element.scrollHeight;
};

export const createMockData = (length, transform, min = 1, max = length) => {
    if (min) min = min - 1; // treat as index so we deduct 1
    return Array.from({ length: Math.min(max, length) - min }, (_, index) => {
        const value = min + index;
        return (typeof transform === "function" && transform(value)) || value;
    });
};

export const flattenObject = (obj, prefix = "") => {
    return transform(
        obj,
        (result, value, key) => {
            const newKey = prefix ? `${prefix}.${key}` : key;
            if (isObject(value) && !Array.isArray(value)) {
                assign(result, flattenObject(value, newKey));
            } else {
                result[newKey] = value;
            }
        },
        {}
    );
};

export const createObjectFromKeys = (keys = [], source = {}, def = {}) => {
    return keys.reduce((prev, curr) => ({ ...prev, [curr]: FALSE_VALUES.includes(source?.[curr]) ? def?.[curr] : source?.[curr] }), {});
};

export const toObjectWithValues = (arr = [], sourceObject = {}) => arr.reduce((prev, curr) => ({ ...prev, [curr]: sourceObject[curr] }), {});

export const convertToObject = (data) => {
    let result = {};
    for (const key in data) {
        const keys = key.split(".");
        let currentObject = result;
        for (let i = 0; i < keys.length; i++) {
            const currentKey = keys[i];
            if (i === keys.length - 1) {
                currentObject[currentKey] = data[key];
            } else {
                currentObject[currentKey] = currentObject[currentKey] || {};
                currentObject = currentObject[currentKey];
            }
        }
    }
    return result;
};

export const createUniqueIDFromStr = (str = "") => uniqueId(trimToLowerJoin(str) + "-");

export const performNativeSort = (data, { sortBy, order } = {}) => {
    if (!data || !sortBy || !order || !Array.isArray(data)) {
        return [];
    }
    const cloned = cloneDeep(data);
    const keys = sortBy.split(".");
    cloned.sort((a, b) => {
        let valueA = a;
        let valueB = b;
        // Traverse through keys to access nested properties
        for (const key of keys) {
            valueA = valueA[key];
            valueB = valueB[key];
        }
        // Check if both values are numbers
        if (!isNaN(valueA) && !isNaN(valueB)) {
            // Convert values to numbers and compare
            return order === SORT_ORDER.ASC ? Number(valueA) - Number(valueB) : Number(valueB) - Number(valueA);
        } else {
            // If not numbers, compare them as strings
            return order === SORT_ORDER.ASC ? String(valueA).localeCompare(String(valueB)) : String(valueB).localeCompare(String(valueA));
        }
    });
    return cloned;
};

export const isValid = (val) => !FALSE_VALUES.includes(val);

export const waitForTimeout = (interval = 5000) => new Promise((resolve) => setTimeout(resolve, interval));

export const renderNA = (text) => (
    <span className="fade" style={{ fontWeight: "normal" }}>
        {text || "Not Specified"}
    </span>
);

export const screenshot = async (elem, scaleFactor = 1, quality = 0) => {
    // Calculate the dimensions of the element
    const { width, height } = elem.getBoundingClientRect();

    // Create a canvas with scaled dimensions
    const canvas = document.createElement("canvas");
    canvas.width = width * scaleFactor;
    canvas.height = height * scaleFactor;

    // Context for the canvas
    const context = canvas.getContext("2d", { willReadFrequently: true });
    context.scale(scaleFactor, scaleFactor);

    // Render the content of the element on the canvas
    await html2canvas(elem, { canvas, useCORS: true });

    // Convert the canvas to a base64 PNG image string
    const base64String = canvas.toDataURL(FILE_MIME_TYPES.IMAGE.PNG, quality);
    return base64String;
};

export const blobToBase64 = async (blob) => {
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = function () {
            resolve(reader.result);
        };
        reader.readAsDataURL(blob);
    });
};

export const binaryArrayToBase64 = (binaryArray = []) => {
    const uint8Array = new Uint8Array(binaryArray.data);
    // Convert the Uint8Array to a binary string
    let base64String = "";
    uint8Array.forEach((byte) => (base64String += String.fromCharCode(byte)));
    return base64String;
};

/**
 * @description
 * Effective for comparing object that has a date using recursion + lodash + moment
 * @param {Object} objA
 * @param {Object} objB
 * @returns {Boolean}
 */
export const isEqualWithDates = (objA = {}, objB = {}) => {
    if (!objA || !objB || typeof objA !== "object" || typeof objB !== "object") {
        return false;
    }
    let retval = true;
    const deepEqualWithDates = (valueA, valueB) => {
        const dateValA = new Date(valueA);
        const dateValB = new Date(valueB);
        if (moment(dateValA).isValid() && moment(dateValB).isValid()) {
            return moment(dateValA).isSame(dateValB);
        }
        if (typeof valueA === "object" && typeof valueB === "object") {
            return isEqualWithDates(valueA, valueB);
        }
        return isEqual(valueA, valueB);
    };
    for (const key in objA) {
        if (Object.hasOwnProperty.call(objA, key)) {
            const valueA = objA[key];
            const valueB = objB[key];
            // we immediately break if we find not equal value
            if (!deepEqualWithDates(valueA, valueB)) {
                retval = false;
                break;
            }
        }
    }
    return retval;
};

export const createFormData = (object) =>
    Object.keys(object || {}).reduce((formData, key) => {
        formData.append(key, object[key]);
        return formData;
    }, new FormData());

export const getCountryName = (cca2, isRaw) => {
    if (!cca2) return <></>;
    const found = COUNTRIES.find((country) => country.cca2.trim().toLowerCase() === cca2.trim().toLowerCase());
    if (isRaw) return found?.name?.common || "";
    return !found ? renderNA() : <span className="fade">{found.name.common}</span>;
};

/**
 * @description
 * Determine if the given color is light or dark
 * @param {String} hex
 * @returns {"dark"|"light"}
 */
export const contrast = (hex) => {
    if (!hex) return "dark";
    const color = new TinyColor(hex);
    if (!color.isValid) return "dark";
    const brightness = color.getBrightness();
    const isDark = brightness <= 150; // 0 darkest 255 lightest
    return isDark ? "dark" : "light";
};

export const isScrollbarVisible = (element) => {
    if (!element || !element.parentElement || element.offsetParent === null) {
        return { vertical: false, horizontal: false }; // Element doesn't exist or is not visible
    }
    const style = window.getComputedStyle(element);
    if (style.display === "none") {
        return { vertical: false, horizontal: false }; // Element or its ancestors are hidden
    }
    return {
        vertical: element.scrollHeight > element.clientHeight,
        horizontal: element.scrollWidth > element.clientWidth
    };
};

export const getObjectChanges = (object1, object2) => {
    return omitBy(object1, (value, key) => isEqual(value, object2[key]));
};

export const isFileObject = (obj) => obj instanceof File;

export const toOrdinal = (number) => {
    if (!number) return "";
    const suffixes = ["th", "st", "nd", "rd"];
    const remainder10 = number % 10;
    const remainder100 = number % 100;
    const suffix =
        suffixes[
            remainder10 === 1 && remainder100 !== 11
                ? 1
                : remainder10 === 2 && remainder100 !== 12
                  ? 2
                  : remainder10 === 3 && remainder100 !== 13
                    ? 3
                    : 0
        ];
    return number + suffix;
};

export const floatToFraction = (value, maxdenom) => {
    let best = { numerator: 1, denominator: 1, error: Math.abs(value - 1), fraction: 1 };
    if (!maxdenom) maxdenom = 10000;
    for (let denominator = 1; best.error > 0 && denominator <= maxdenom; denominator++) {
        let numerator = Math.round(value * denominator);
        let error = Math.abs(value - numerator / denominator);
        if (error >= best.error) continue;
        best.numerator = numerator;
        best.denominator = denominator;
        best.fraction = denominator == 1 ? numerator : `${numerator}/${denominator}`;
        best.error = error;
    }
    return best;
};

export const toFormObject = (keys) => Object.values(keys).reduce((prev, key) => ({ ...prev, [key]: "" }), {});

export const isValidHex = (str, length) => {
    // Regular expression to match hexadecimal characters
    const hexRegex = /^[0-9a-fA-F]+$/;
    // Check if the string matches the hexadecimal format and has the correct length
    if (length) {
        return hexRegex.test(str) && str.length === length;
    }
    return hexRegex.test(str);
};

export const checkCreditCardHolderNameValid = (cardHolder = "") => {
    if (!cardHolder) return false;
    return cardValidator.cardholderName(cardHolder).isValid;
};

export const checkCreditCardNumberValid = (number) => {
    if (!number) {
        return {
            valid: false,
            type: null
        };
    }
    const result = cardValidator.number(number);
    return {
        valid: result.isValid,
        type: result.card?.type
    };
};

export const toFormFields = (fields, defKey = "defaultValue") =>
    Object.values(fields).reduce(
        (prev, curr) => ({
            ...prev,
            [curr.name]: curr[defKey]
        }),
        {}
    );

export const arrayInsert = (arr, index, item) => {
    if (index < 0 || index > arr.length) {
        return arr;
    }
    const newArr = cloneDeep(arr);
    newArr.splice(index, 0, item);
    return newArr;
};

export const separateLastCharacters = (word, num = 2, keywords = "") => {
    if (word.length < num) {
        return word; // If the word has less than 2 characters, return it as is
    }
    const firstPart = word.slice(0, -num); // All characters except the last two
    const lastTwoCharacters = word.slice(-num); // The last two characters
    if (keywords && sanitizeWords(lastTwoCharacters) != sanitizeWords(keywords)) return word;
    return `${firstPart} ${lastTwoCharacters}`; // Concatenate with a space
};

export const roundToNearestTenth = (value) => {
    if (!value) return 0;
    return Math.round(value * 10) / 10;
};

// ############################################################################# MONEY HELPERS

export const toMoneyWithCurrency = (money, currency) => (money ? `${money} ${currency || ""}`.trim() : "");

export const addCommasToMoney = (number) => {
    if (!number) return "";
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const toProperMoneyFormat = (money, currency) => {
    const moneyWithComma = addCommasToMoney(money);
    return toMoneyWithCurrency(moneyWithComma, currency);
};

// ############################################################################# TIME RELATED HELPERS

export const millisecondsToMMSS = (totalMilliseconds) => {
    const totalSeconds = Math.floor(totalMilliseconds / 1000); // Convert milliseconds to seconds
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes}:${seconds.toString().padStart(2, "0")}`;
};

export const secondsToMs = (num) => num * 60 * 1000;

export const toReadableFromDate = (date, timezone, format) => {
    if (!date) return "";

    const datetime = toTimeWithTimeZone(date, timezone);
    const current = moment();
    const diffInDays = datetime.diff(current, "days", true);

    if (diffInDays >= 2 || diffInDays <= -2) {
        return datetime.format(format || "MMM DD, YYYY hh:mm A");
    } else {
        return sanitizeWords(datetime.fromNow());
    }
};

export const getTimeRemaining = (date, timezone) => {
    const expires = moment(date).tz(timezone);
    const difference = expires.diff(moment(), "milliseconds");
    return difference;
};

export const toUTCwithTz = (time = null, timezone) => {
    if (!time) return moment.utc().tz(timezone);
    return moment.utc(time).tz(timezone);
};

export const toDate = (date, timezone) => {
    if (!timezone) throw new Error("Timezone is required!");
    if (!date) return "";
    return toTimeWithTimeZone(date, timezone).startOf("day").format("YYYY-MM-DD HH:mm:ss.SSSZ");
};

export const toStandardDate = (date, timezone) => {
    if (!timezone) throw new Error("Timezone is required!");
    if (!date) return "";
    return toTimeWithTimeZone(date, timezone).format("MMM DD, YYYY");
};

export const toNativeDate = (date, timezone) => {
    if (!timezone) throw new Error("Timezone is required!");
    if (!date) return "";
    return toTimeWithTimeZone(date, timezone).format("YYYY-MM-DD");
};

export const createTimeFromDate = (start, end, timezone, mini = false) => {
    if (!start || !end)
        return (
            <span className="fade" style={{ fontWeight: "normal" }}>
                Not Specified
            </span>
        );
    if (!timezone) throw new Error("Timezone is required!");
    const defformat = "hh:mm A";
    start = start ? toTimeWithTimeZone(start, timezone).format(mini ? "h:mm" : defformat) : "";
    end = end ? toTimeWithTimeZone(end, timezone).format(mini ? "h:mm A" : defformat) : "";
    const time = `${start} - ${end}`;
    return time;
};

export const isTimePassed = (someDate, hours = 1) => {
    // Check if someDate is not null or undefined
    if (someDate) {
        // Use Moment.js to get the current time
        const currentTime = moment();
        // Calculate the difference between the current time and someDate in milliseconds
        const timeDifference = currentTime.diff(moment(someDate));
        // Convert the time difference to hours
        const hoursDifference = moment.duration(timeDifference).asHours();
        // If the hours difference is greater than or equal to the specified hours, time has passed
        return hoursDifference >= hours;
    } else {
        // Handle the case when someDate is null or undefined
        return false;
    }
};

/**
 * @description
 * Use for displaying proper UTC date from timezone
 */
export const renderUTCdate = (time = null, timezone) => {
    if (!time) {
        return null;
    }
    return moment.utc(moment(new Date(time)).format("YYYY-MM-DD HH:mm:ss")).tz(timezone);
};

export const toTimeWithTimeZone = (date, timezone) => {
    if (!date) return "";
    if (!timezone) return moment(date);
    return moment(moment(new Date(date)).tz(timezone).format());
};

export const toTimeFormat = (date, timezone) => {
    const result = toTimeWithTimeZone(date, timezone);
    return result ? result.format("hh:mm A") : result;
};

export const hoursDiff = (time1, time2) => {
    if (!time1 || !time2) return null;
    time1 = moment(time1);
    time2 = moment(time2);
    return time2.diff(time1, "minutes") / 60;
};

export const isValidDateNative = (date) => !new Date(date).toString().includes("Invalid Date");

export const formatTimeToTwoDigits = (num) => {
    const split = num.split(":");
    const toDigits = (digit) => (digit < 10 ? "0" + digit : digit);
    return split.map((time) => toDigits(Number(time))).join(":");
};

export const createTimeFormat = (t) =>
    moment().set({
        hour: t.hours(),
        minute: t.minutes(),
        second: t.seconds(),
        millisecond: t.milliseconds()
    });

export const createEditingInfo = ({ start, end, ot, timezone }) => {
    const retval = { beforeTime: null, afterTime: null, isEditAllowed: true };
    if (start) {
        const allowedBeforeStartTime = toTimeWithTimeZone(start, timezone);
        const createCorrectTimeWithStartTime = createTimeFormat(allowedBeforeStartTime);
        const beforeTime = createCorrectTimeWithStartTime.format("hh:mm A");
        const isBefore = moment().isBefore(createCorrectTimeWithStartTime);
        retval.beforeTime = beforeTime;
        retval.isEditAllowed = isBefore;
    }
    if (end) {
        const allowedAfterEndTime = toTimeWithTimeZone(end, timezone);
        const createCorrectTimeWithEndTime = createTimeFormat(allowedAfterEndTime);
        const afterTime = createCorrectTimeWithEndTime.add(ot, "hours");
        const afterHour = afterTime.format("hh:mm A");
        const tommorow = toTimeWithTimeZone(moment(), timezone).add(1, "day");
        tommorow.set("hour", 0);
        tommorow.set("minutes", 0);
        tommorow.set("seconds", 0);
        tommorow.set("ms", 0);
        const isBeforeEarlyMorning = toTimeWithTimeZone(afterTime, timezone).isBefore(tommorow);
        if (isBeforeEarlyMorning) {
            retval.afterTime = tommorow.format("hh:mm A");
            retval.isEditAllowed = false;
        } else {
            const isAfter = moment().isAfter(createCorrectTimeWithEndTime);
            retval.afterTime = afterHour;
            retval.isEditAllowed = isAfter;
        }
    }
    //!NOTE: TEMPORARY DISABLE
    retval.isEditAllowed = true;
    return retval;
};

export const getDateInfo = (date, timezone) => {
    if (!date || !timezone) return null;
    const newdate = new Date(date);
    const firstDate = toTimeWithTimeZone(newdate, timezone);

    return {
        year: firstDate.format("YYYY"),
        month: firstDate.format("MMM"),
        day: firstDate.format("DD"),
        hour: firstDate.format("hh"),
        minute: firstDate.format("mm"),
        ampm: firstDate.format("A")
    };
};

export const createTextFromDate = (dateOne, dateTwo, timezone) => {
    const d1 = getDateInfo(dateOne, timezone);
    const d2 = getDateInfo(dateTwo, timezone);

    const result = { date: null, time: null };

    if (d1 && d2) {
        if (d1.month == d2.month) {
            if (d1.day !== d2.day) {
                result.date = `${d1.month} ${d1.day} - ${d2.day}, ${d2.year}`;
            } else {
                result.date = `${d1.month} ${d1.day}, ${d2.year}`;
            }
        } else {
            if (d1.year !== d2.year) {
                result.date = `${d1.month} ${d1.day}, ${d1.year} - ${d2.month} ${d2.day}, ${d2.year}`;
            } else {
                result.date = `${d1.month} ${d1.day} - ${d2.month} ${d2.day}, ${d2.year}`;
            }
        }
    } else if (!d1 && d2) {
        result.date = `${d2.month} ${d2.day}, ${d2.year}`;
    } else if (d1 && !d2) {
        result.date = `${d1.month} ${d1.day}, ${d1.year}`;
    } else {
        return result;
    }

    result.time = `${toTimeFormat(dateOne, timezone)} - ${toTimeFormat(dateTwo, timezone)}`;

    return result;
};

export const changeToBaseDate = (baseTime, shiftTime, timezone) => {
    if (!baseTime || !shiftTime || !timezone) return null;
    const date = toTimeWithTimeZone(baseTime, timezone).clone();
    const shiftDate = toTimeWithTimeZone(shiftTime, timezone).clone();
    return date
        .set("hours", shiftDate.hours())
        .set("minutes", shiftDate.minutes())
        .set("seconds", shiftDate.seconds())
        .set("milliseconds", shiftDate.milliseconds());
};

export const sanitizeTimestamp = (time1, time2, timezone) => {
    if (!time1 || !time2 || !timezone) return "";
    return toReadableFromDate(toReadableFromDate(time1, timezone) == toReadableFromDate(time2, timezone) ? "" : time1, timezone) || "";
};

// ############################################################################# PHONE RELATED HELPERS

/**
 * @param {String} number
 * @param {String} countryCode
 * @returns {{isValid: Boolean, phoneNumber: String }}
 */
export const isPhoneValid = (number, countryCode) => {
    const config = {};
    if (countryCode) {
        config.country = countryCode;
    }
    return phone(number, config);
};

export const toReadablePhone = (phone) => {
    if (!phone) return "";
    const phoneNumber = parsePhoneNumber(phone);
    return phoneNumber.formatInternational();
};

// ############################################################################# RENDER HELPERS

export const createClass = (newStr = "", base = "") => `${base}${newStr}`;

export const createGroup = ({ title, body, hiddenTitle, base = "" }) => (
    <div className={createClass("__group", base)}>
        <div className={createClass("__group__header", base)} style={{ marginBottom: "1rem" }}>
            <h3 style={{ opacity: hiddenTitle ? 0 : 1 }}>{title}</h3>
        </div>
        <div className={createClass("__group__body", base) + " flex column gap-05"}>{body}</div>
    </div>
);

export const toFilterOptions = (obj) => toReadableSelectOptions(obj).concat(FILTER_ALL_VALUE);
