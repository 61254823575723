import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { createConfirmAlert, capitalizeWords } from "../../../common/utilities/helper";
import Loader from "../../../common/components/extra/Loader";
import Modal from "../../../common/components/extra/Modal";
import Input from "../../../common/components/extra/Input";
import Select from "../../../common/components/extra/select/Select";
import { useGetAllPermissionMutation, useCreateRoleMutation, useUpdateRoleMutation } from "./api";
import { ROLE_LEVEL, ROLE_TYPE } from "../../../common/utilities/const";
import { useAppDispatch, useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectLocalPages, selectRoleLevelDefaultPermissions, selectRoleTypeDefaultPermissions, setState } from "./slice";

function UpsertModal({ open, onChange, onFinish, update = {}, onCancel }) {
    const [selected, setSelected] = useState(null);
    const [form, setForm] = useState({
        name: (update && update.name) || "",
        type: (update && update.type) || "",
        level: (update && update.level) || "",
        permissions: [...((update && update.permissions) || [])]
    });
    const dispatch = useAppDispatch();
    const localPages = useAppSelector(selectLocalPages);
    const roleLevelDefaultPermissions = useAppSelector(selectRoleLevelDefaultPermissions);
    const roleTypeDefaultPermissions = useAppSelector(selectRoleTypeDefaultPermissions);

    const [loadPermissions, { isLoading: loadPermissionIsLoading }] = useGetAllPermissionMutation();
    const [createRole, { isLoading: createRoleIsLoading, isSuccess: createRoleIsSuccess, error: createRoleError }] = useCreateRoleMutation();
    const [updateRole, { isLoading: updateRoleIsLoading, isSuccess: updateRoleIsSuccess, error: updateRoleError }] = useUpdateRoleMutation();

    const title = update ? "Update Role" : "Create Role";
    const isLoading = loadPermissionIsLoading || createRoleIsLoading || updateRoleIsLoading;
    const error = createRoleError || updateRoleError;
    const success = createRoleIsSuccess || updateRoleIsSuccess;

    const createDefaultPermissions = (type = form.type, level = form.level) => {
        const defaultRoleTypePermissions = (roleTypeDefaultPermissions && type && roleTypeDefaultPermissions[type]) || [];
        const defaultRoleLevelPermissions = (roleLevelDefaultPermissions && type && level && roleLevelDefaultPermissions[type][level]) || [];
        const defaultPermissions = [...new Set([...defaultRoleLevelPermissions, ...defaultRoleTypePermissions])];
        return defaultPermissions;
    };

    useEffect(() => {
        loadPermissions().then((res) => {
            if (res && res.data && res.data.data) {
                const pages = res.data.data.pages;
                const roleLevelDefaultPermissions = res.data.data.roleLevelDefaultPermissions;
                const roleTypeDefaultPermissions = res.data.data.roleTypeDefaultPermissions;
                dispatch(setState({ localPages: pages, roleLevelDefaultPermissions, roleTypeDefaultPermissions }));
            }
        });
    }, []);

    useEffect(() => {
        let msg = "Something went wrong. Please try again later!";
        if (error) {
            if (error.data.message) {
                if (error.data.message.errors) msg = Object.values(error.data.message.errors).join(" & ").trim();
                else msg = error.data.message;
            }
        }
        if (error) toast.error(msg);
    }, [error]);

    useEffect(() => {
        if (success) {
            if (createRoleIsSuccess) toast.success("Successfully created the role.");
            else if (updateRoleIsSuccess) toast.success("Successfully updated the role.");
        }
    }, [success]);

    useEffect(() => {
        if (!selected && localPages.length > 0) setSelected(localPages[0]);
    }, [localPages.length]);

    const handlePermissionCheckChange = (e) => {
        if (e.target.checked) setForm({ ...form, permissions: [...new Set([...form.permissions, e.target.value])] });
        else setForm({ ...form, permissions: form.permissions.filter((fp) => fp !== e.target.value) });
    };

    const handleSave = async () => {
        if (!form.name) return toast.error("Role name field is required.");
        if (!form.permissions.length) return toast.error("At least one permission is required.");

        createConfirmAlert({
            title,
            content: `Are you sure you want to ${update ? "update" : "create"} this role? This cannot be undone.`,
            onConfirm: async (close) => {
                close();
                if (!update) await createRole({ body: form });
                else await updateRole({ extraPath: update.id, body: form });
                onFinish();
                onCancel();
            }
        });
    };

    const handleMarkAllSelected = (isChecked, selectedPermissions) => {
        if (isChecked) {
            setForm({ ...form, permissions: [...new Set([...form.permissions, ...selectedPermissions, ...createDefaultPermissions()])] });
        } else {
            setForm({
                ...form,
                permissions: [...new Set([...form.permissions.filter((fp) => !selectedPermissions.includes(fp)), ...createDefaultPermissions()])]
            });
        }
    };

    const handleInputChange = (e) => {
        if (e.target.name === "type") {
            setForm({ ...form, [e.target.name]: e.target.value, permissions: createDefaultPermissions(e.target.value) });
        } else if (e.target.name === "level") {
            setForm({ ...form, [e.target.name]: e.target.value, permissions: createDefaultPermissions(form.type, e.target.value) });
        } else {
            setForm({ ...form, [e.target.name]: e.target.value });
        }
    };

    const ROLE_TYPE_OPTIONS = Object.values(ROLE_TYPE).map((r) => ({ label: r, value: r }));
    const ROLE_LEVEL_OPTIONS = Object.values(ROLE_LEVEL).map((r) => ({ label: r, value: r }));

    return (
        <Modal open={open} onChange={onChange} onSave={handleSave} isForm>
            <div className="tk-roles__modal-content">
                {isLoading && <Loader />}
                <div className="tk-roles__modal-content__header">{title}</div>
                <div className="tk-roles__modal-content__body">
                    <div className="tk-roles__modal-content__body__inner">
                        <div className="tk-roles__modal-content__body__top">
                            <Input
                                name="name"
                                label="Role Name"
                                placeholder="ex. Accounting"
                                value={form.name}
                                onChange={handleInputChange}
                                required
                            />
                            <Select
                                name="type"
                                label="Role Type"
                                placeholder=""
                                isOutlined
                                options={ROLE_TYPE_OPTIONS}
                                value={ROLE_TYPE_OPTIONS.find((r) => form.type === r.value) || ""}
                                isClearable={false}
                                onChange={(d) => handleInputChange({ target: { name: "type", value: d.value } })}
                                required
                            />
                            <Select
                                name="level"
                                label="Role Level"
                                placeholder=""
                                isOutlined
                                options={ROLE_LEVEL_OPTIONS}
                                value={ROLE_LEVEL_OPTIONS.find((r) => form.level === r.value) || ""}
                                isClearable={false}
                                onChange={(d) => handleInputChange({ target: { name: "level", value: d.value } })}
                                required
                            />
                        </div>
                        <div className="tk-roles__modal-content__body__bottom">
                            <div className="tk-roles__modal-content__body__sidebar">
                                <div className="tk-roles__modal-content__body__sidebar__header">
                                    <h4>Access</h4>
                                </div>
                                <ul>
                                    {localPages.map((p) => (
                                        <li
                                            key={p.name}
                                            onClick={() => setSelected(p)}
                                            className={`${selected && selected.name === p.name ? "modal-sidebar-active" : ""}`}
                                        >
                                            {capitalizeWords(p.name, "_")}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            {selected && (
                                <div className="tk-roles__modal-content__body__permissions">
                                    <div className="tk-roles__modal-content__body__permissions__header">
                                        <h4>
                                            Permissions: &nbsp;
                                            {capitalizeWords(selected.name, "_")}
                                        </h4>
                                        <Input
                                            type="checkbox"
                                            onChange={(e) => handleMarkAllSelected(e.target.checked, selected.permissions)}
                                            checked={selected.permissions
                                                .map((fp) => (typeof fp === "object" ? fp.name : fp))
                                                .every((sp) => form.permissions.map((fp) => (typeof fp === "object" ? fp.name : fp)).includes(sp))}
                                            disabled={selected.permissions.every((sp) => createDefaultPermissions().includes(sp))}
                                        />
                                    </div>
                                    <div className="tk-roles__modal-content__body__permissions__content">
                                        <ul>
                                            {selected.permissions.map((p) => {
                                                const isLocal = typeof p === "object" && p.isLocal;
                                                const val = isLocal ? p.name : p;
                                                const label = `${capitalizeWords(val, "_")} (${val})`;
                                                return (
                                                    <li key={val}>
                                                        <Input
                                                            type="checkbox"
                                                            value={val}
                                                            onChange={handlePermissionCheckChange}
                                                            checked={form.permissions
                                                                .map((fp) => (typeof fp === "object" ? fp.name : fp))
                                                                .includes(val)}
                                                            label={<span>{label}</span>}
                                                            disabled={createDefaultPermissions().includes(val)}
                                                        />
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

UpsertModal.propTypes = {
    open: PropTypes.bool,
    update: PropTypes.object,
    onChange: PropTypes.func,
    onFinish: PropTypes.func,
    onCancel: PropTypes.func
};

export default UpsertModal;
