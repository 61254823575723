import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import isEqual from "lodash/isEqual";
import isFinite from "lodash/isFinite";

const isNumber = (v) => /^[0-9]*\.?[0-9]*$/.test(v);

function InputNumber({ constraint, ...inputProps }) {
    const [value, setValue] = useState(inputProps.value);

    useEffect(() => {
        if (!isEqual(value, inputProps.value)) {
            setValue(inputProps.value || "");
        }
    }, [inputProps.value]);

    const validateConstraint = (current) => {
        const min = constraint?.min;
        const max = constraint?.max;
        const parsedCurrent = parseFloat(current);
        const validMin = isFinite(min) && parsedCurrent >= min;
        const validMax = isFinite(max) && parsedCurrent <= max;
        const hasZero = current.startsWith("0");
        const noConstraint = !validMin && !validMax;
        const hasConstraint = validMin && validMax;
        if (hasZero) {
            const items = current.split("");
            if (items.length >= 2) {
                const is2ndValPeriod = items[1] == ".";
                // handle this value 0000000...
                if (items.every((item) => item == "0")) {
                    return value;
                }
                // handle this value 02,03,...
                if (!is2ndValPeriod) {
                    return items[items.length - 1];
                }
            }
        }
        if (noConstraint || hasConstraint) {
            return current;
        } else {
            return value;
        }
    };

    const handleChange = (e) => {
        let newval = e.target.value;
        if (isNumber(newval) || newval === "") {
            if (newval !== "") {
                newval = validateConstraint(newval);
            }
        } else {
            newval = value;
        }
        setValue(newval);
        typeof inputProps.onChange === "function" && inputProps.onChange({ target: { name: e.target.name, value: newval } });
    };

    const handleBlur = (e) => {
        // const val = e.target.value;
        // if (val == 0 || val == "") {
        //     setValue(inputProps.value || constraint?.min || 0);
        // }
        typeof inputProps.onBlur === "function" && inputProps.onBlur(e);
    };

    return <input {...inputProps} type="text" onChange={handleChange} value={value} onBlur={handleBlur} />;
}

InputNumber.propTypes = {
    onChange: PropTypes.func,
    constraint: PropTypes.shape({
        max: PropTypes.number,
        min: PropTypes.number
    })
};

export default InputNumber;
