import React, { useState } from "react";
import PropTypes from "prop-types";
import Modal from "../../../common/components/extra/Modal";
import TableList from "../../../common/components/extra/table/TableList";
import Button from "../../../common/components/extra/Button";
import { SORT_ORDER } from "../../../common/utilities/const";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { performNativeSort } from "../../../common/utilities/helper";
import { selectUser } from "../../common/slice";
import ViewModal from "../employees/ViewModal";

const BASE_CLASS = "tk-bulk-upload__view-result";

const createClass = (newStr = "") => `${BASE_CLASS}${newStr}`;

function ResultModal({ open, onChange, data = {}, title, isViewIgnoredRows }) {
    const defKey = isViewIgnoredRows ? "rowNum" : "id";
    const defaultSort = { sortBy: defKey, order: SORT_ORDER.ASC };

    const [object, setObject] = useState({
        employees: performNativeSort(data, defaultSort),
        selected: null,
        sort: defaultSort
    });

    const user = useAppSelector(selectUser);
    const setting = user.Setting || {};
    const departments = setting.departments || [];

    let { data: headers } = CreateTableHeaders({ isViewIgnoredRows, onViewRecord: (row) => updateObject({ selected: row }) });

    if (isViewIgnoredRows) {
        headers = createIgnoredRowsHeader().data;
    }

    const updateObject = (newObj = {}) => setObject({ ...object, ...newObj });

    const modalHeader = (
        <div className="flex center">
            <span>{title}</span>
        </div>
    );

    const handleSort = ({ sortBy, order }) => {
        performNativeSort(object.employees, { sortBy, order });
        const sort = { sortBy, order };
        const sortedEmployees = performNativeSort(object.employees, sort);
        updateObject({ employees: sortedEmployees, sort });
    };

    return (
        <>
            <Modal
                title={modalHeader}
                open={open}
                onChange={onChange}
                styles={{
                    content: { width: "50vw", height: "max-content" },
                    body: { overflow: "auto" },
                    form: { padding: "0 5rem" },
                    footer: { paddingTop: "1rem" }
                }}
                hasHeaderStyle
            >
                <div className={createClass()}>
                    <div className={createClass("__inner")}>
                        <TableList
                            headers={headers}
                            data={object.employees}
                            onSort={handleSort}
                            activeSort={object.sort}
                            uniqueKey={defKey}
                            checked={{ keep: object.employees.filter((emp) => emp.keepOriginal).map((emp) => emp.id) }}
                        />
                    </div>
                </div>
            </Modal>
            {!!object.selected && (
                <ViewModal
                    open={!!object.selected}
                    onChange={(bool) => {
                        if (!bool) {
                            updateObject({ selected: null });
                        }
                    }}
                    data={object.selected?.original || {}}
                    departments={departments}
                    setting={setting}
                    cachedCurrent={false}
                />
            )}
        </>
    );
}

const CreateTableHeaders = ({ onViewRecord }) => {
    const headers = {
        ID: {
            key: "id",
            sortKey: "id",
            label: <span style={{ whiteSpace: "nowrap" }}>ID</span>,
            width: "20%",
            render: (row) => <span className="bold">{row.id}</span>
        },
        EMPLOYEE: {
            key: "employee",
            sortKey: "original.residenceID",
            label: "Employee",
            width: "60%",
            render: (row) => (
                <span className="text-ellipsis">
                    <span className="bold">{row.original.residenceID}</span>
                    <span className="fade">
                        &nbsp;- {row.original.first_name} {row.original.last_name}
                    </span>
                </span>
            )
        },
        ACTION: {
            key: "action",
            width: "20%",
            render: (row) => {
                return (
                    <Button
                        onClick={() => typeof onViewRecord === "function" && onViewRecord(row)}
                        options={{ style: { padding: 0, boxShadow: "unset" } }}
                        transparent
                    >
                        <span className="bold" style={{ textDecoration: "underline" }}>
                            View Record
                        </span>
                    </Button>
                );
            }
        }
    };
    return { data: Object.values(headers), original: headers };
};

const createIgnoredRowsHeader = () => {
    const headers = {
        ID: {
            key: "rowNum",
            sortKey: "rowNum",
            label: <span style={{ whiteSpace: "nowrap" }}>ROW #</span>,
            width: "20%",
            render: (row) => <span className="bold">{row.rowNum}</span>
        },
        EMPLOYEE: {
            key: "employee",
            sortKey: "employee.residenceID",
            label: "Employee",
            width: "60%",
            render: (row) => (
                <span className="text-ellipsis">
                    <span className="bold">{row.employee.residenceID}</span>
                    <span className="fade">
                        &nbsp;- {row.employee.first_name} {row.employee.last_name}
                    </span>
                </span>
            )
        }
    };
    return { data: Object.values(headers), original: headers };
};

ResultModal.propTypes = {
    open: PropTypes.bool,
    data: PropTypes.array,
    onChange: PropTypes.func,
    onFinish: PropTypes.func,
    isViewIgnoredRows: PropTypes.bool,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node])
};

export default ResultModal;
