import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../common/hooks/reduxHooks";
import WorkDetails from "../../features/company/employeeWorkDetails/WorkDetails";
import { setTableConfig, selectTableConfig, selectLoading } from "../../features/company/employeeWorkDetails/slice";
import PageLayout from "../../common/components/layout/PageLayout";

function EmployeeWorkTypesPage() {
    const [openAddModal, setOpenAddModal] = useState(false);

    const dispatch = useAppDispatch();
    const loading = useAppSelector(selectLoading);
    const config = useAppSelector(selectTableConfig);

    const handleSearch = (value) => dispatch(setTableConfig({ search: (value && value.toLowerCase().trim()) || "" }));
    const handleAdd = () => setOpenAddModal(true);

    return (
        <PageLayout
            className="tk-employees-work-type"
            title="Manage Work Types"
            isLoading={loading}
            search={{ onSearch: handleSearch, value: config.search }}
            onAdd={handleAdd}
            styles={{
                body: {
                    height: "calc(100vh - 20.5rem)"
                }
            }}
        >
            <WorkDetails openAddModal={openAddModal} onAddModalClose={() => setOpenAddModal(false)} />
        </PageLayout>
    );
}

export default EmployeeWorkTypesPage;
