import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { useMapsLibrary } from "@vis.gl/react-google-maps";
import { LIBRARIES, AUTO_COMPLETE_EVENT } from "./const";

const SUPPORTED_FIELDS = ["place_id", "geometry", "formatted_address", "name"];

function PlaceSearch({ map, maps }) {
    let tracklisteners = [];

    const searchRef = useRef(null);
    const placesLib = useMapsLibrary(LIBRARIES.PLACES);

    useEffect(() => {
        if (!placesLib || !map) return;
        const autocomplete = new placesLib.Autocomplete(searchRef.current, {
            fields: SUPPORTED_FIELDS
        });
        autocomplete.bindTo("bounds", map);
        map.controls[maps.ControlPosition.TOP_LEFT].push(searchRef.current);
        tracklisteners.push(
            autocomplete.addListener(AUTO_COMPLETE_EVENT.PLACE_CHANGE, () => {
                const place = autocomplete.getPlace();
                if (!place.geometry || !place.geometry.location) {
                    return;
                }
                if (place.geometry.viewport) {
                    map.fitBounds(place.geometry.viewport);
                } else {
                    map.setCenter(place.geometry.location);
                    map.setZoom(17);
                }
            })
        );
        return () => {
            tracklisteners.length && tracklisteners.forEach((listener) => listener.remove());
        };
    }, [placesLib, map]);

    return <input className="tk-input tk-map-search" ref={searchRef} style={{ width: "auto" }} />;
}
export default PlaceSearch;

PlaceSearch.propTypes = {
    map: PropTypes.object,
    maps: PropTypes.object
};
