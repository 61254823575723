import React, { useEffect, useState } from "react";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import { TOAST_TYPE, createToast, toReadableSelectOptions } from "../../../common/utilities/helper";
import { useAppDispatch, useAppSelector } from "../../../common/hooks/reduxHooks";
import {
    selectCurrent,
    selectTableConfig,
    setCurrent,
    setState,
    selectLoading,
    setLoading,
    selectCompanyHolidaysData,
    selectSearching,
    setSearching,
    setCompanyHolidaysData
} from "./slice";
import {
    useCreateCompanyHolidaysMutation,
    useDeleteCompanyHolidaysMutation,
    useGetCompanyHolidaysMutation,
    useLoadAllCompanyHolidaysMutation,
    useUpdateCompanyHolidaysMutation
} from "./api";
import Tag from "../../../common/components/extra/Tag";
import { COMPANY_HOLIDAY_CATEGORY, COMPANY_HOLIDAY_LEVEL, COMPANY_HOLIDAY_TYPE } from "./const";
import { FIELD } from "./const";
import { selectUserSetting } from "../../common/slice";
import { createWorkShiftInfo } from "../employeeWorkShift/helper";

const { NAME, TYPE, LEVEL, CATEGORY, START_DATE, END_DATE, IS_YEARLY, NOTES, WORK_SHIFT, WORK_SITES, WORK_SHIFTS, WORK_TYPES } = FIELD;

export const useGetCompanyHolidays = (id, callback) => {
    const [isMounted, setMounted] = useState(false);
    const [fetching, setFetching] = useState(!!id);
    const [getDetails] = useGetCompanyHolidaysMutation();

    const dispatch = useAppDispatch();
    const current = useAppSelector(selectCurrent);
    // this means it only have the id key inside means we have to fetch more
    const isInitial = current && Object.keys(current).length == 1;
    const setting = useAppSelector(selectUserSetting);
    const timezone = setting.timezone;

    const createVars = (data) => {
        if (!data) return {};

        return {
            isWorkTypeAffected: data?.level == COMPANY_HOLIDAY_LEVEL.BY_WORK_TYPE,
            isWorkSiteAffected: data?.level == COMPANY_HOLIDAY_LEVEL.BY_WORK_SITE,
            isWorkShiftAffected: data?.level == COMPANY_HOLIDAY_LEVEL.BY_WORK_SHIFT,
            workShift: data[WORK_SHIFT] && createWorkShiftInfo(data[WORK_SHIFT], timezone)
        };
    };

    const fetch = async () => {
        if (!id) return;

        try {
            if (!isInitial && current.id === id) {
                setFetching(false);
                return Promise.resolve();
            }
            const result = await getDetails({ extraPath: id });
            if (result.error) {
                throw new Error("Failed to fetch holiday. Please try again later");
            }
            dispatch(setCurrent(result.data.data));
            callback?.(result.data.data);
            return result.data.data;
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
            return {};
        } finally {
            setFetching(false);
        }
    };

    const updateCurrent = (newCurrent = {}) => {
        dispatch(setCurrent({ ...current, ...(newCurrent || {}) }));
    };

    useEffect(() => {
        setMounted(true);
    }, []);

    useEffect(() => {
        if (isMounted) {
            fetch();
        }
    }, [isMounted]);

    return [current, { isLoading: fetching, config: createVars(current), update: updateCurrent, fetch }];
};

export const usePaginateCompanyHolidays = () => {
    const [isLoading, setIsLoading] = useState(true);

    const dispatch = useAppDispatch();
    const data = useAppSelector(selectCompanyHolidaysData);
    const tableConfig = useAppSelector(selectTableConfig);
    const searching = useAppSelector(selectSearching);

    const [load] = useLoadAllCompanyHolidaysMutation();

    const fetch = async (config) => {
        if (searching) {
            return;
        }
        if (!isLoading) {
            setIsLoading(true);
        }
        try {
            const response = await load({ body: { ...tableConfig, ...(config || {}) } });
            if (response.data && response.data.data) {
                if (typeof setState === "function") {
                    const result = response.data.data;
                    const oldConfig = { ...tableConfig, ...(config || {}) };
                    dispatch(
                        setState({
                            data: result.data,
                            tableConfig: {
                                ...oldConfig,
                                totalPage: result.totalPage,
                                totalCount: result.totalCount
                            }
                        })
                    );
                }
            }
            if (response.error) {
                throw new Error("Failed to fetch holidays. Please try again later.");
            }
            return response;
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSearchFetching = async () => {
        try {
            dispatch(setSearching(true));
            await fetch();
        } finally {
            dispatch(setSearching(false));
        }
    };

    useEffect(() => {
        handleSearchFetching();
    }, [tableConfig.search]);

    useEffect(() => {
        if (!data.length) {
            fetch();
        } else {
            setIsLoading(false);
        }
    }, []);

    const handleUpdate = (id, newObject = {}) => {
        const newdata = data.map((record) => {
            if (record.id == id) {
                record = {
                    ...record,
                    ...(newObject || {})
                };
            }
            return record;
        });
        dispatch(setCompanyHolidaysData(newdata));
    };

    const handleFilter = async (filter) => {
        try {
            dispatch(setSearching(true));
            await fetch(filter ? { filter } : {}, true);
        } finally {
            dispatch(setSearching(false));
        }
    };

    return [data, { isLoading, fetch, update: handleUpdate, isSearching: searching, onFilter: handleFilter }];
};

export const useUpsertCompanyHolidays = (updateId, callback) => {
    const isCreate = !updateId;

    const [old, setOld] = useState(null);
    const [form, setForm] = useState({
        [NAME]: "",
        [TYPE]: "",
        [LEVEL]: "",
        [CATEGORY]: "",
        [START_DATE]: "",
        [END_DATE]: "",
        [IS_YEARLY]: "",
        [NOTES]: "",
        [WORK_SHIFT]: "",
        [WORK_SITES]: "",
        [WORK_SHIFTS]: "",
        [WORK_TYPES]: ""
    });

    const [data, { isLoading }] = useGetCompanyHolidays(updateId, callback);

    const [create, { isLoading: createIsLoading }] = useCreateCompanyHolidaysMutation();
    const [update, { isLoading: updateIsLoading }] = useUpdateCompanyHolidaysMutation();
    const current = useAppSelector(selectCurrent);

    const createVars = () => {
        const typeOpt = toReadableSelectOptions(COMPANY_HOLIDAY_TYPE).map((t) => ({
            ...t,
            label: <Tag className={`flex ${t.value == COMPANY_HOLIDAY_TYPE.CHANGE_TIMING ? "yellow" : "red"}`}>{t.label}</Tag>
        }));
        const levelOpt = toReadableSelectOptions(COMPANY_HOLIDAY_LEVEL).map((t) => ({
            ...t,
            label: <Tag className="flex">{t.label}</Tag>
        }));
        const categoryOpt = toReadableSelectOptions(COMPANY_HOLIDAY_CATEGORY).map((t) => ({
            ...t,
            label: <Tag className="flex">{t.value == COMPANY_HOLIDAY_CATEGORY.COMPANY_SPECIFIC ? "custom" : t.label}</Tag>
        }));

        const type = typeOpt.find((type) => type.value == form[TYPE]) || "";
        const level = levelOpt.find((level) => level.value == form[LEVEL]) || "";
        const category = categoryOpt.find((category) => category.value == form[CATEGORY]) || "";
        const workshift = (data && { id: data?.workshift || "" }) || "";

        return {
            typeOpt,
            levelOpt,
            categoryOpt,
            type,
            level,
            category,
            workshift
        };
    };

    const upsert = async () => {
        let result = null;
        try {
            const clonedform = cloneDeep(form);

            clonedform[WORK_SHIFT] = clonedform[WORK_SHIFT]?.id;
            clonedform[WORK_SITES] = clonedform[WORK_SITES].map((rec) => rec.id);
            clonedform[WORK_SHIFTS] = clonedform[WORK_SHIFTS].map((rec) => rec.id);
            clonedform[WORK_TYPES] = clonedform[WORK_TYPES].map((rec) => rec.id);

            if (!clonedform[END_DATE]) {
                clonedform[END_DATE] = null;
            }
            if (isCreate) {
                result = await create({ body: clonedform });
            } else {
                result = await update({ body: clonedform, extraPath: updateId });
            }
            if (result.error) {
                throw new Error(result.error?.data?.message);
            }
            if (result.data) {
                if (result.data?.data) {
                    createToast(`Company holiday ${isCreate ? "created" : "updated"} succesfully.`, TOAST_TYPE.SUCCESS);
                } else {
                    createToast(result.data.message, TOAST_TYPE.SUCCESS);
                }
            }
            return result.data.data;
        } catch (error) {
            createToast(
                `Failed to ${!isCreate ? "update" : "create"} Company holiday. ${error?.message || "Please try again later or contact support."} `,
                TOAST_TYPE.ERROR
            );
            return { error };
        }
    };

    const updateForm = (config = {}) => setForm({ ...form, ...config });

    useEffect(() => {
        const temp = {
            [NAME]: current?.[NAME] || form[NAME],
            [TYPE]: current?.[TYPE] || form[TYPE],
            [LEVEL]: current?.[LEVEL] || form[LEVEL],
            [CATEGORY]: current?.[CATEGORY] || form[CATEGORY],
            [START_DATE]: current?.[START_DATE] || form[START_DATE],
            [END_DATE]: current?.[END_DATE] || form[END_DATE],
            [IS_YEARLY]: current ? current?.[IS_YEARLY] : false,
            [NOTES]: current?.[NOTES] || form[NOTES],
            [WORK_SHIFT]: current?.[WORK_SHIFT]?.id ? { id: current?.[WORK_SHIFT]?.id, value: current?.[WORK_SHIFT]?.id } : form[WORK_SHIFT],
            [WORK_SITES]: (current?.[WORK_SITES] || form[WORK_SITES] || []).map((record) => ({ id: record, value: record })),
            [WORK_SHIFTS]: (current?.[WORK_SHIFTS] || form[WORK_SHIFTS] || []).map((record) => ({ id: record, value: record })),
            [WORK_TYPES]: (current?.[WORK_TYPES] || form[WORK_TYPES] || []).map((record) => ({ id: record, value: record }))
        };
        setForm(temp);
        setOld(temp);
    }, []);

    return [
        form,
        updateForm,
        {
            upsert,
            isGettingHoliday: isLoading,
            isUpserting: createIsLoading || updateIsLoading,
            config: createVars(),
            old,
            hasChanges: !!(old && !isCreate && !isEqual(form, old))
        }
    ];
};

export const useDeleteCompanyHolidays = () => {
    const dispatch = useAppDispatch();

    const [deleteHoliday] = useDeleteCompanyHolidaysMutation();

    const isLoading = useAppSelector(selectLoading);

    const remove = async (id) => {
        if (!isLoading) {
            dispatch(setLoading(true));
        }
        try {
            const response = await deleteHoliday({ extraPath: id });
            if (response.error) {
                throw new Error(response.error?.data?.message || "Failed to delete holiday.");
            }
            return response.data.data;
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
        } finally {
            dispatch(setLoading(false));
        }
    };

    return [remove, isLoading];
};
