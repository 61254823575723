import { defaultColors } from "./const";

export const sumChars = (str) => {
    let sum = 0;
    for (let i = 0; i < str.length; i++) {
        sum += str.charCodeAt(i);
    }
    return sum;
};

export const createInitials = (name) => {
    let initials = "";
    const names = name.split(" ");
    initials = names[0].substring(0, 1).toUpperCase();
    if (names.length > 1) {
        // only get the last letter so we limit initials to 2 characters
        initials += names.pop().substring(0, 1).toUpperCase();
    }
    return initials;
};

export const createBackground = (name = "", initials = "", backgroundColors = []) => {
    let defaultBackground = "";
    if (/[A-Z]/.test(initials)) {
        let index = initials.charCodeAt() - 65;
        if (backgroundColors && backgroundColors.length) {
            let i = sumChars(name) % backgroundColors.length;
            defaultBackground = backgroundColors[i];
        } else {
            defaultBackground = defaultColors[index];
        }
    } else if (/[\d]/.test(initials)) {
        defaultBackground = defaultColors[parseInt(initials)];
    } else {
        defaultBackground = "#051923";
    }
    return defaultBackground;
};
