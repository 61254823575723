import cloneDeep from "lodash/cloneDeep";
import { createSlice } from "@reduxjs/toolkit";
import { PAGE_OPTIONS } from "../../../common/components/extra/table/const";
import { VIEW_TYPES } from "../../../common/utilities/const";

const SLICE_NAME_KEY = "penalties";
const DEFAULT_PAGE_SIZE = PAGE_OPTIONS[0];
const INITIAL_STATE = {
    data: [],
    tableConfig: {
        page: 1,
        pageSize: DEFAULT_PAGE_SIZE,
        search: "",
        sortBy: "id",
        order: "ASC",
        totalPage: 0
    },
    current: null,
    isSearching: false,
    view: VIEW_TYPES.TABLE
};

export const penaltySlice = createSlice({
    name: SLICE_NAME_KEY,
    initialState: INITIAL_STATE,
    reducers: {
        setSearching: (state, action) => {
            const clone = cloneDeep(state);
            clone.isSearching = action.payload;
            return clone;
        },
        setData(state, action) {
            const clone = cloneDeep(state);
            const data = action.payload || [];
            Array.isArray(data) && (clone.data = data);
            return clone;
        },
        setTableConfig(state, action) {
            const clone = cloneDeep(state);
            const tableConfig = action.payload || {};
            clone.tableConfig = { ...state.tableConfig, ...tableConfig };
            return clone;
        },
        setView(state, action) {
            const clone = cloneDeep(state);
            const view = action.payload;
            const isChangingView = view !== state.view;
            view && isChangingView && (clone.view = view);
            return clone;
        },
        setCurrent(state, action) {
            const clone = cloneDeep(state);
            clone.current = action.payload;
            return clone;
        },
        setState(state, action) {
            const clone = cloneDeep(state);
            const payload = action.payload;
            Array.isArray(payload?.data) && (clone.data = payload.data);
            payload.tableConfig && typeof payload.tableConfig === "object" && (clone.tableConfig = { ...state.tableConfig, ...payload.tableConfig });
            payload.view && payload.view !== state.view && (clone.view = payload.view);
            "current" in payload && (clone.current = payload.current);
            return clone;
        },
        reset() {
            return INITIAL_STATE;
        }
    }
});

export const selectSearching = (state) => state[SLICE_NAME_KEY].isSearching;
export const selectData = (state) => state[SLICE_NAME_KEY].data;
export const selectTableConfig = (state) => state[SLICE_NAME_KEY].tableConfig;
export const selectView = (state) => state[SLICE_NAME_KEY].view;
export const selectCurrent = (state) => state[SLICE_NAME_KEY].current;
export const selectState = (state) => state[SLICE_NAME_KEY];
export const { setSearching, setData, setTableConfig, setView, setCurrent, setState, reset } = penaltySlice.actions;
export default penaltySlice.reducer;
