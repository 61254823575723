import cloneDeep from "lodash/cloneDeep";

export const defaultOptions = [
    {
        id: "very-weak",
        value: "Very weak",
        minDiversity: 0,
        minLength: 0
    },
    {
        id: "weak",
        value: "Weak",
        minDiversity: 2,
        minLength: 6
    },
    {
        id: "good",
        value: "Good",
        minDiversity: 4,
        minLength: 8
    },
    {
        id: "strong",
        value: "Strong",
        minDiversity: 4,
        minLength: 10
    }
];

export const calculateStrength = (password, options = defaultOptions, allowedSymbols = "!\"#$%&'()*+,-./:;<=>?@[\\\\\\]^_`{|}~") => {
    const clonedOptions = cloneDeep(options);
    let passwordCopy = password || "";
    const rules = [
        {
            regex: /[a-z]/,
            message: "lowercase"
        },
        {
            regex: /[A-Z]/,
            message: "uppercase"
        },
        {
            regex: /[0-9]/,
            message: "number"
        }
    ];
    if (allowedSymbols) {
        rules.push({
            regex: new RegExp(`[${allowedSymbols}]`),
            message: "symbol"
        });
    }
    let strength = {};
    strength.contains = rules.filter((rule) => rule.regex.test(passwordCopy)).map((rule) => rule.message);
    strength.length = passwordCopy.length;
    let fulfilledOptions = clonedOptions
        .filter((option) => strength.contains.length >= option.minDiversity)
        .filter((option) => strength.length >= option.minLength);
    fulfilledOptions.sort((a, b) => b.minLength - a.minLength);
    Object.assign(strength, fulfilledOptions.map((option) => ({ id: option.id, value: option.value }))[0]);
    return strength;
};
