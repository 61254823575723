import { useEffect, useState } from "react";

function useDetectOutsideClick(ref, onOutsideClick, cb) {
    const [isClickedOutside, setClickOutside] = useState(false);
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target) && !isClickedOutside) {
                setClickOutside(true);
                typeof onOutsideClick == "function" && onOutsideClick(event.target);
            } else {
                setClickOutside(false);
            }
            typeof cb == "function" && cb(event.target, ref.current && !ref.current.contains(event.target));
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);

    return [isClickedOutside];
}

export default useDetectOutsideClick;
