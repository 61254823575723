import React, { useEffect, useState } from "react";
import cloneDeep from "lodash/cloneDeep";
import Tag from "../../../common/components/extra/Tag";
import {
    TOAST_TYPE,
    createEditingInfo,
    createMockData,
    createToast,
    isEqualWithDates,
    sanitizeWords,
    toReadableSelectOptions
} from "../../../common/utilities/helper";
import { SHIFT_TYPE, SORT_ORDER } from "../../../common/utilities/const";
import { useAppDispatch, useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectUser, selectUserSetting } from "../../common/slice";
import { getTimeRangeValues, isNextDay, isReqHoursSatisfied, isBreakSatisfied } from "./helper";
import { FIELDS, FORM_FIELDS, HOL_PREFIX, MAX_BREAK_DURATION_VALUE, TIME_TYPE } from "./const";
import Filter from "./Filter";

import {
    useCreateWorkshiftMutation,
    useDeleteWorkshiftMutation,
    useGetWorkshiftByIdsMutation,
    useGetWorkshiftDetailsMutation,
    useLoadAllLazyWorkshiftMutation,
    useLoadAllWorkshiftMutation,
    useUpdateWorkshiftMutation
} from "../employeeWorkShift/api";

import {
    selectLoading as selectRWSLoading,
    selectSearching as selectRWSSearching,
    selectTableConfig as selectRWSTableConfig,
    selectRegularWorkShiftData,
    setLoading as setRWSLoading,
    setSearching as setRWSSearching,
    setState as setRWSState,
    selectFilter as selectRWSFilter,
    setRegularWorkShiftData,
    setTableConfig as setRWSTableConfig,
    setFilter as setRWSFilter,
    defaultFilter as defaultRWSFilter,
    resetFilter as resetRWSFilter,
    setClearCache as setRWSClearCache
} from "./regular/slice";

import {
    selectLoading as selectHWSLoading,
    selectSearching as selectHWSSearching,
    selectTableConfig as selectHWSTableConfig,
    selectHolidayWorkShiftData,
    setLoading as setHWSLoading,
    setSearching as setHWSSearching,
    setState as setHWSState,
    selectFilter as selectHWSFilter,
    setHolidayWorkShiftData,
    setTableConfig as setHWSTableConfig,
    setFilter as setHWSFilter,
    defaultFilter as defaultHWSFilter,
    resetFilter as resetHWSFilter,
    setClearCache as setHWSClearCache
} from "./holiday/slice";

export const rtkSwitcher = ({ isHoliday } = {}) => {
    return {
        selectData: isHoliday ? selectHolidayWorkShiftData : selectRegularWorkShiftData,
        selectTableConfig: isHoliday ? selectHWSTableConfig : selectRWSTableConfig,
        selectSearching: isHoliday ? selectHWSSearching : selectRWSSearching,
        setState: isHoliday ? setHWSState : setRWSState,
        setSearching: isHoliday ? setHWSSearching : setRWSSearching,
        setWorkShiftData: isHoliday ? setHolidayWorkShiftData : setRegularWorkShiftData,
        selectFilter: isHoliday ? selectHWSFilter : selectRWSFilter,
        setFilter: isHoliday ? setHWSFilter : setRWSFilter,
        setLoading: isHoliday ? setHWSLoading : setRWSLoading,
        selectLoading: isHoliday ? selectHWSLoading : selectRWSLoading,
        setTableConfig: isHoliday ? setHWSTableConfig : setRWSTableConfig,
        defaultFilter: isHoliday ? defaultHWSFilter : defaultRWSFilter,
        resetFilter: isHoliday ? resetHWSFilter : resetRWSFilter,
        setClearCache: isHoliday ? setHWSClearCache : setRWSClearCache
    };
};

export const useGetWorkShiftDetails = () => {
    const [data, setData] = useState({});
    const [getDetails, { isLoading }] = useGetWorkshiftDetailsMutation();

    const setting = useAppSelector(selectUserSetting);

    const fetch = async (id) => {
        try {
            const result = await getDetails({ extraPath: id });
            if (result.error) {
                throw new Error("Failed to fetch workshift. Please try again later");
            }
            setData(result.data.data);
            return result.data.data;
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
            return {};
        }
    };

    const checkAllowEditTime = () => {
        const hasEmployees = !!data.Employees?.length;
        const isSplit = data.shift_type === SHIFT_TYPE.SPLIT;
        const calculateMinStartTime = data.start_time;
        const calculateMaxEndTime = isSplit ? data.end_time_2 : data.end_time;

        const editInfo = createEditingInfo({
            start: calculateMinStartTime,
            end: calculateMaxEndTime,
            ot: data.max_overtime,
            timezone: setting.timezone
        });

        return {
            isAllowed: !hasEmployees || editInfo.isEditAllowed,
            allowedTime: {
                after: editInfo.afterTime,
                before: editInfo.beforeTime
            }
        };
    };

    return [fetch, { data, isLoading, allowEditInfo: checkAllowEditTime() }];
};

export const useGetWorkShiftByIds = (ids = []) => {
    const [object, setObject] = useState({
        data: [],
        mounted: false
    });

    const [getWorkShifts, { isLoading }] = useGetWorkshiftByIdsMutation();

    const updateObject = (newObj = {}) => setObject({ ...object, ...newObj });

    const fetch = async () => {
        try {
            const response = await getWorkShifts({ body: { ids } });
            if (response.error) {
                throw new Error(response.error?.data?.message);
            }
            if (response.data && response.data.data) {
                const resdata = response.data.data;
                updateObject({ data: resdata });
            }
        } catch (error) {
            createToast(error.message || "Something went wrong with the server. Please contact support.", TOAST_TYPE.ERROR);
            updateObject({ data: [] });
        }
    };

    useEffect(() => {
        updateObject({ mounted: true });
    }, []);

    useEffect(() => {
        if (object.mounted) {
            fetch();
        }
    }, [object.mounted]);

    return [object.data, isLoading, { isMounted: object.mounted }];
};

export const usePaginateWorkShift = ({ readOnly, isHoliday } = {}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isMounted, setMounted] = useState(false);

    const dispatch = useAppDispatch();

    const { selectData, selectTableConfig, selectSearching, setState, setWorkShiftData, setSearching, setClearCache } = rtkSwitcher({ isHoliday });

    const data = useAppSelector(selectData);
    const tableConfig = useAppSelector(selectTableConfig);
    const searching = useAppSelector(selectSearching);

    const [load] = useLoadAllWorkshiftMutation();

    const fetch = async (config) => {
        if (searching) {
            return;
        }
        if (!isLoading) {
            setIsLoading(true);
        }
        try {
            const response = await load({
                body: {
                    ...tableConfig,
                    ...(config || {}),
                    isHoliday
                }
            });
            if (response.data && response.data.data) {
                if (typeof setState === "function") {
                    const result = response.data.data;
                    const oldConfig = { ...tableConfig, ...(config || {}) };
                    dispatch(
                        setState({
                            data: result.data,
                            tableConfig: {
                                ...oldConfig,
                                totalPage: result.totalPage,
                                totalCount: result.totalCount
                            }
                        })
                    );
                }
            }
            if (response.error) {
                throw new Error("Failed to fetch workshift. Please try again later.");
            }
            return response;
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSearchFetching = async () => {
        try {
            dispatch(setSearching(true));
            await fetch();
        } finally {
            dispatch(setSearching(false));
        }
    };

    useEffect(() => {
        if (isMounted) {
            handleSearchFetching();
        }
    }, [tableConfig.search]);

    useEffect(() => {
        if (isMounted && !readOnly) {
            if (!data.length) {
                fetch();
            } else {
                setIsLoading(false);
            }
        }
    }, [isMounted]);

    useEffect(() => {
        setMounted(true);
    }, []);

    const handleUpdate = (id, newObject = {}) => {
        const newdata = data.map((record) => {
            if (record.id == id) {
                record = {
                    ...record,
                    ...(newObject || {})
                };
            }
            return record;
        });
        dispatch(setWorkShiftData(newdata));
    };

    const handleFilter = async (filter) => {
        try {
            dispatch(setSearching(true));
            await fetch(filter ? { filter } : {}, true);
        } finally {
            dispatch(setSearching(false));
        }
    };

    return [data, { isLoading, fetch, update: handleUpdate, isSearching: searching, onFilter: handleFilter, tableConfig, setClearCache }];
};

export const useLazyWorkShift = ({ startFrom = "", noHolidays, onlyHolidays } = {}) => {
    const LOAD_MORE_OFFSET = 5;
    const DEFAULT_SIZE = 15;

    const [object, setObject] = useState({
        data: [],
        sort: { sortBy: FIELDS.TITLE.name, order: SORT_ORDER.ASC },
        totalCount: 0,
        cursor: "",
        search: ""
    });

    const hasMore = object.totalCount > object.data?.length || 0;

    const [load, { isLoading }] = useLoadAllLazyWorkshiftMutation();

    const updateObject = (newObject = {}) => setObject((prev) => ({ ...prev, ...newObject }));

    const fetch = async ({ sort, ...config } = {}, isReset) => {
        if (!sort) {
            sort = object.sort;
        }
        if (isReset) {
            config.cursor = "";
        }
        if (noHolidays) {
            config.noHolidays = noHolidays;
        }
        if (onlyHolidays) {
            config.onlyHolidays = onlyHolidays;
        }
        try {
            const response = await load({
                body: {
                    pageSize: DEFAULT_SIZE,
                    more: isReset ? DEFAULT_SIZE : LOAD_MORE_OFFSET,
                    ...object.sort,
                    ...sort,
                    ...(config || {})
                }
            });
            if (response.error) {
                throw new Error(response.error?.data?.message);
            }
            if (response.data && response.data.data) {
                const resdata = response.data.data;
                const newdata = resdata.data.map((t) => ({
                    ...t,
                    value: t.id,
                    label: (
                        <div className="flex gap-05" style={{ alignItems: "center" }}>
                            <span className="text-ellipsis">{sanitizeWords(t.title)}</span>
                            {t?.CompanyHoliday?.id && !onlyHolidays && <Tag className="red">HOLIDAY</Tag>}
                        </div>
                    )
                }));
                const temp = {
                    data: isReset ? newdata : object.data.concat(newdata),
                    cursor: resdata.cursor,
                    totalCount: resdata.totalCount
                };
                sort && (temp.sort = sort);
                updateObject(temp);
            }
        } catch (error) {
            createToast(error.message || "Something went wrong with the server. Please contact support.", TOAST_TYPE.ERROR);
            sort && updateObject({ sort, data: [], totalCount: 0 });
        }
    };

    const loadMore = () => hasMore && fetch({ cursor: object.cursor });
    const reset = () => fetch({}, true);
    const search = (value = "") => fetch({ search: value }, true);

    useEffect(() => {
        fetch({ startFrom });
    }, []);

    return [object, updateObject, { isLoading, hasMore, fetch, reset, loadMore, search }];
};

export const useDeleteWorkShift = ({ isHoliday } = {}) => {
    const dispatch = useAppDispatch();

    const [deleteWorkShift] = useDeleteWorkshiftMutation();

    const { selectLoading, setLoading } = rtkSwitcher({ isHoliday });

    const isLoading = useAppSelector(selectLoading);

    const remove = async (id) => {
        if (!isLoading) {
            dispatch(setLoading(true));
        }
        try {
            const response = await deleteWorkShift({ extraPath: id });
            if (response.error) {
                throw new Error(response.error?.data?.message || "Failed to delete work shift.");
            }
            return response.data.data;
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
        } finally {
            dispatch(setLoading(false));
        }
    };

    return [remove, isLoading];
};

export const useUpsertWorkShift = (updateId) => {
    const isCreate = !updateId;

    const [old, setOld] = useState(FORM_FIELDS);
    const [form, setForm] = useState(FORM_FIELDS);
    const [fetching, setFetching] = useState(true);
    const [error, setError] = useState(null);

    const [getDetails, { isLoading: isGettingDetails, allowEditInfo }] = useGetWorkShiftDetails();
    const [create, { isLoading: createIsLoading }] = useCreateWorkshiftMutation();
    const [update, { isLoading: updateIsLoading }] = useUpdateWorkshiftMutation();

    const user = useAppSelector(selectUser);
    const setting = user.Setting;

    const createVars = () => {
        const isSplit = form.shift_type == SHIFT_TYPE.SPLIT;
        const isNormal = form.shift_type == SHIFT_TYPE.NORMAL;
        const hasShiftTwo = isNormal || (form.start_time_2 && form.end_time_2);
        const hasShiftOne = form.start_time && form.end_time;
        const title =
            (form?.[FIELDS.TITLE.name] && form[FIELDS.TITLE.name].startsWith(HOL_PREFIX) && form[FIELDS.TITLE.name].replace(HOL_PREFIX, "")) ||
            form?.[FIELDS.TITLE.name];
        const maxBreakDurationOption = createMockData(MAX_BREAK_DURATION_VALUE, (value) => ({
            value: value ? value / 2 : 0,
            label: <span className="semi-bold">{!value ? "No Break" : <>{(value ? value / 2 : 0).toFixed(1)}</>}</span>
        }));
        const maxBreakDuration = maxBreakDurationOption.find((ctr) => ctr.value === form[FIELDS.MAX_BREAK_DURATION.name]);
        return {
            isSplit,
            isNormal,
            isLoading: isGettingDetails || createIsLoading || updateIsLoading,
            shiftTypeOption: toReadableSelectOptions(SHIFT_TYPE),
            maxBreakDurationOption,
            maxBreakDuration: maxBreakDuration
                ? {
                      ...(maxBreakDuration || {}),
                      label: maxBreakDuration.value ? (
                          <span className="semi-bold">
                              {maxBreakDuration.value} <span className="fade semi-bold">Hour(s)</span>
                          </span>
                      ) : (
                          maxBreakDuration.label
                      )
                  }
                : "",
            hasShiftOne,
            hasShiftTwo,
            isSatisfied: hasShiftOne && hasShiftTwo && isReqHoursSatisfied(form.required_shift_time, form),
            isBreakSatisfied: isBreakSatisfied(form),
            shiftOneValue: getTimeRangeValues(form, isSplit, setting.timezone)[TIME_TYPE.SHIFT_ONE],
            shiftTwoValue: getTimeRangeValues(form, isSplit, setting.timezone)[TIME_TYPE.SHIFT_TWO],
            breakValue: getTimeRangeValues(form, isSplit, setting.timezone)[TIME_TYPE.BREAK],
            breakTwoValue: getTimeRangeValues(form, isSplit, setting.timezone)[TIME_TYPE.BREAK_TWO],
            isHoliday: form.is_holiday,
            title,
            isNextDay: isNextDay(
                {
                    startTimeOne: form[FIELDS.START_TIME.name],
                    endTimeOne: form[FIELDS.END_TIME.name]
                },
                {
                    startTimeTwo: form[FIELDS.START_TIME_2.name],
                    endTimeTwo: form[FIELDS.END_TIME_2.name]
                },
                isSplit
            )
        };
    };

    const vars = createVars();

    const updateForm = (config = {}) => setForm((prev) => ({ ...prev, ...config }));

    const upsert = async () => {
        let result = null;
        try {
            const clonedform = cloneDeep(form);
            if (!vars.isSatisfied) {
                throw new Error("Shift range must at least meet the required shift time.");
            }
            if (clonedform.is_holiday) {
                if (!clonedform.title.startsWith(HOL_PREFIX)) {
                    clonedform.title = HOL_PREFIX + clonedform.title;
                }
            } else {
                if (clonedform.title.startsWith(HOL_PREFIX)) {
                    clonedform.title = clonedform.title.replace(HOL_PREFIX, "");
                }
            }

            const maxBreakDuration = clonedform[FIELDS.MAX_BREAK_DURATION.name];

            if (!maxBreakDuration) {
                clonedform[FIELDS.BREAK_TIME.name] = null;
                clonedform[FIELDS.BREAK_END_TIME.name] = null;
                clonedform[FIELDS.BREAK_TIME_2.name] = null;
                clonedform[FIELDS.BREAK_END_TIME_2.name] = null;
            }

            if (isCreate) {
                result = await create({ body: clonedform });
            } else {
                result = await update({ body: clonedform, extraPath: form.id });
            }
            if (result.error) {
                throw new Error(result.error?.data?.message);
            }
            if (result.data) {
                if (result.data?.data) {
                    createToast(`Work Shift ${isCreate ? "created" : "updated"} succesfully.`, TOAST_TYPE.SUCCESS);
                } else {
                    createToast(result.data.message, TOAST_TYPE.SUCCESS);
                }
            }
            setError(null);
            return result.data.data;
        } catch (error) {
            createToast(
                `Failed to ${!isCreate ? "update" : "create"} Work Shift. ${error?.message || "Please try again later or contact support."} `,
                TOAST_TYPE.ERROR
            );
            setError(error.message);
            return { error };
        }
    };

    useEffect(() => {
        if (updateId) {
            getDetails(updateId)
                .then((result) => {
                    setForm(result);
                    setOld(result);
                    setFetching(false);
                })
                .catch(() => setFetching(false));
        } else {
            setFetching(false);
        }
    }, []);

    return [
        form,
        updateForm,
        {
            upsert,
            isLoading: vars.isLoading || fetching,
            config: vars,
            hasChanges: isEqualWithDates(form, old),
            isEditAllowed: isCreate || allowEditInfo.isAllowed,
            allowedTime: allowEditInfo.allowedTime,
            error,
            setError
        }
    ];
};

export const useRegularWorkShiftStates = () => {
    const [, { isSearching, onFilter }] = usePaginateWorkShift({
        readOnly: true,
        isHoliday: false
    });

    const { selectTableConfig, selectData, selectFilter, setTableConfig } = rtkSwitcher({ isHoliday: false });

    const config = useAppSelector(selectTableConfig);
    const filter = useAppSelector(selectFilter);
    const data = useAppSelector(selectData);

    return {
        Filter: <Filter onFilter={onFilter} isLoading={isSearching} filter={filter} />,
        config,
        filter,
        isSearching,
        onFilter,
        setTableConfig,
        searchPlaceholder: "Search workshift title...",
        data
    };
};

export const useHolidayWorkShiftStates = () => {
    const [, { isSearching, onFilter }] = usePaginateWorkShift({
        readOnly: true,
        isHoliday: true
    });

    const { selectTableConfig, selectData, selectFilter, setTableConfig } = rtkSwitcher({ isHoliday: true });

    const config = useAppSelector(selectTableConfig);
    const filter = useAppSelector(selectFilter);
    const data = useAppSelector(selectData);

    return {
        Filter: <Filter onFilter={onFilter} isLoading={isSearching} filter={filter} />,
        config,
        filter,
        isSearching,
        onFilter,
        setTableConfig,
        searchPlaceholder: "Search workshift title...",
        data
    };
};
