import React, { useState } from "react";
import PropTypes from "prop-types";
import InfoIcon from "@mui/icons-material/Info";
import { calculateStrength, defaultOptions } from "./helper";
import Input from "../Input";

function PasswordStrength({ value, onChange, noMeter, ...rest }) {
    const [capsLockActive, setCapsLockActive] = useState(false);
    const strength = calculateStrength(value);
    const strengthIndex = defaultOptions.findIndex((d) => d.id === strength.id);

    const handleKeyPress = (event) => {
        const capsLockIsOn = event.getModifierState && event.getModifierState("CapsLock");
        setCapsLockActive(capsLockIsOn);
    };

    return (
        <div className={`tk-password-strength ${strength.id}`} style={rest.parentStyle}>
            <Input
                type="password"
                placeholder="Password*"
                value={value}
                onChange={onChange}
                onKeyUp={handleKeyPress}
                autoComplete="current-password"
                maxLength={64}
                minLength={8}
                required
                isLarge
                {...rest}
            />
            {!noMeter && value && (
                <div className="tk-password-strength__meters">
                    <div className="tk-password-strength__meters__group">
                        {defaultOptions.map((d, index) => (
                            <div key={d.id} className={`meter ${(strengthIndex >= index && "active") || ""}`}></div>
                        ))}
                    </div>
                    <div className="tk-password-strength__meters__label flex gap-05" style={{ alignItems: "center" }}>
                        {capsLockActive && (
                            <strong className="small-font flex center" style={{ gap: ".2rem" }}>
                                <InfoIcon style={{ width: "1rem" }} />
                                Caps lock is on
                            </strong>
                        )}
                        <span className="fade">{strength.value}</span>
                    </div>
                </div>
            )}
        </div>
    );
}

export default PasswordStrength;

PasswordStrength.propTypes = {
    value: PropTypes.any,
    onChange: PropTypes.func,
    noMeter: PropTypes.bool
};
