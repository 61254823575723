import React from "react";
import AddIcon from "@mui/icons-material/Add";
import { ReactComponent as ImageUploadIcon } from "../../../../assets/images/image-upload-icon.svg";

function MobileImageUpload() {
    return (
        <div className="mobile-input mobile-input--file-upload">
            <div className="box-file">
                <ImageUploadIcon className="image" />
            </div>
            <div className="box-add">
                <AddIcon />
            </div>
        </div>
    );
}

export default MobileImageUpload;
