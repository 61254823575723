import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { createMockData } from "../../../utilities/helper";
import { PAGINATION_CONTROLS } from "./const";
import Select from "../select/Select";
import TotalRecords from "../filter/TotalRecords";

const END_STYLE = {
    justifyContent: "flex-end",
    margin: "unset",
    marginTop: "1rem"
};

function Pagination({
    page = { current: 1, total: 0, options: [5, 10, 15, 20] },
    onChange,
    onRowsChange,
    isLoading,
    styles: paginationStyles,
    isLazy,
    beforeExtra
}) {
    const styles = { ...(paginationStyles || {}) };
    const MAX_ROWS_OPTIONS = page.options.map((op) => ({ value: op, label: op }));

    const { START, END, NEXT, PREV, GOTO } = PAGINATION_CONTROLS;

    const [maxRows, setMaxRows] = useState(MAX_ROWS_OPTIONS[0]);
    const [pageOpt, setPageOpt] = useState([]);
    const [currentPage, setCurrentPage] = useState(page.current);

    const isEnd = page.current >= page.total;
    const isStart = page.current === 1;
    const canNext = page.current < page.total;
    const canPrev = page.current > 1;
    const pageSize = MAX_ROWS_OPTIONS.find((p) => maxRows.value === p.value);
    const currPage = pageOpt.find((po) => po.value == currentPage);
    const showAdvControl = page.totalCount > MAX_ROWS_OPTIONS[0].value;

    const updateCurrentPage = (value) => {
        const isMaxed = value > page.total;
        const isLess = value < 1;
        const isValueOutOfBounds = isMaxed || isLess;
        if (!isValueOutOfBounds) {
            setCurrentPage(value);
            return value;
        }
        return isMaxed ? page.total : page.current;
    };

    const handleControlChange = (type) => {
        let value = page.current;
        switch (type) {
            case START:
                value = updateCurrentPage(1);
                break;
            case END:
                value = updateCurrentPage(page.total);
                break;
            case NEXT:
                value = updateCurrentPage(currentPage + 1);
                break;
            case PREV:
                value = updateCurrentPage(currentPage - 1);
                break;
            default:
                break;
        }
        onChange({ type, page: { ...page, current: value } });
    };

    const handleGoto = (val) => {
        setCurrentPage(val);
        typeof onChange === "function" && onChange({ type: GOTO, page: { ...page, current: val } });
    };

    const handleRowsChange = (value) => {
        setMaxRows(value);
        onRowsChange(value);
    };

    useEffect(() => {
        setPageOpt(
            createMockData(page.total, (idx) => ({
                value: idx + 1,
                label: idx + 1
            }))
        );
    }, [page.total]);

    useEffect(() => {
        if (page.pageSize !== maxRows.value) {
            const opt = MAX_ROWS_OPTIONS.find((p) => page.pageSize === p.value) || MAX_ROWS_OPTIONS[0];
            setMaxRows(opt);
        }
    }, [page.pageSize]);

    useEffect(() => {
        if (currentPage !== page.current) {
            setCurrentPage(page.current);
        }
    }, [page.current]);

    if (!page.total && !isLazy) {
        return <></>;
    }

    if (!showAdvControl) {
        styles.parent = END_STYLE;
    }

    if (isLazy) {
        styles.parent = {
            margin: "0 0 0 0 auto",
            ...(styles.parent || {})
        };
    }

    return (
        <div className="tk-table__pagination" style={styles?.parent}>
            {beforeExtra}
            {showAdvControl && !isLazy && (
                <>
                    <div className="tk-table__pagination__rpp">
                        <Select
                            name="rpp"
                            options={MAX_ROWS_OPTIONS}
                            menuPlacement="top"
                            value={
                                isLoading
                                    ? { value: "", label: "" }
                                    : {
                                          ...pageSize,
                                          value: pageSize?.value || "",
                                          label: (
                                              <div className="flex gap-05" style={{ textAlign: "center", alignItems: "center" }}>
                                                  <span className="fade small-font" style={{ whiteSpace: "nowrap" }}>
                                                      Row(s):
                                                  </span>
                                                  <span className="bold">{pageSize?.value}</span>
                                              </div>
                                          )
                                      }
                            }
                            onChange={(val) => handleRowsChange(val.value)}
                            isClearable={false}
                            style={{
                                minWidth: "7rem",
                                height: "100%"
                            }}
                            isLoading={isLoading}
                            styles={{ control: { borderRadius: "25rem" } }}
                        />
                    </div>
                    <div className="tk-table__pagination__info">
                        <Select
                            name="page"
                            options={pageOpt}
                            menuPlacement="top"
                            value={
                                isLoading
                                    ? { value: "", label: "" }
                                    : {
                                          ...(currPage || {}),
                                          value: currPage?.value || "",
                                          label: (
                                              <div className="flex gap-05" style={{ textAlign: "center", alignItems: "center" }}>
                                                  <span className="fade small-font" style={{ whiteSpace: "nowrap" }}>
                                                      Page:
                                                  </span>
                                                  <span className="bold">{currPage?.value}</span>
                                              </div>
                                          )
                                      }
                            }
                            onChange={(val) => handleGoto(val.value)}
                            isClearable={false}
                            style={{
                                minWidth: "7rem",
                                height: "100%"
                            }}
                            isDisabled={!page.total || page.total == 1}
                            isLoading={isLoading}
                            styles={{ control: { borderRadius: "25rem" } }}
                        />
                        <span className="fade">of</span>
                        <span className="fade">{isLoading ? 0 : page.total}</span>
                    </div>
                    <div className="tk-table__pagination__controls">
                        <KeyboardDoubleArrowLeftIcon
                            onClick={() => handleControlChange(START)}
                            className={`${(isStart && "disabled") || ""}`.trim()}
                        />
                        <ChevronLeftIcon onClick={() => handleControlChange(PREV)} className={`${(!canPrev && "disabled") || ""}`.trim()} />
                        <ChevronRightIcon onClick={() => handleControlChange(NEXT)} className={`${(!canNext && "disabled") || ""}`.trim()} />
                        <KeyboardDoubleArrowRightIcon onClick={() => handleControlChange(END)} className={`${(isEnd && "disabled") || ""}`.trim()} />
                    </div>
                </>
            )}
            {!!page.totalCount && <TotalRecords value={page.totalCount} isLoading={isLoading} />}
        </div>
    );
}

export default Pagination;

Pagination.propTypes = {
    beforeExtra: PropTypes.any,
    page: PropTypes.shape({
        current: PropTypes.number,
        total: PropTypes.number,
        options: PropTypes.arrayOf(PropTypes.number),
        pageSize: PropTypes.number,
        totalCount: PropTypes.number
    }),
    onChange: PropTypes.func,
    isLoading: PropTypes.bool,
    isLazy: PropTypes.bool,
    onRowsChange: PropTypes.func,
    styles: PropTypes.shape({
        parent: PropTypes.object
    })
};
