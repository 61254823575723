import { apiSlice } from "../../../app/apiSlice";

const BASE_PATH = "/api/company/employee/work-details";

const END_POINTS = {
    createWorkDetails: { method: "post", path: "create" },
    updateWorkDetails: { method: "put", path: "update" },
    deleteWorkDetails: { method: "delete", path: "delete" },
    getWorkDetails: { method: "post", path: "get-details" },
    loadAllWorkDetails: { method: "post", path: "load-all" },
    loadAllLazyWorkDetails: { method: "post", path: "load-all-lazy" }
};

export const workDetailsAPI = apiSlice.injectEndpoints({
    reducerPath: "workDetailsAPI",
    endpoints: (builder) => {
        const temp = {};
        Object.entries(END_POINTS).forEach(([key, config]) => {
            temp[key] = builder.mutation({
                query: (queryParams = {}) => {
                    const path = `${BASE_PATH}/${config.path}`;
                    const conf = {
                        url: path,
                        method: config.method,
                        credentials: "include"
                    };
                    if (typeof queryParams.extraPath === "string" || typeof queryParams.extraPath === "number")
                        conf.url = `${path}/${queryParams.extraPath}`;
                    if (Array.isArray(queryParams.extraPaths)) conf.url = `${path}/${queryParams.extraPaths.join("/")}`;
                    if (queryParams.params) conf.params = queryParams.params;
                    if (queryParams.body) conf.body = queryParams.body;
                    return conf;
                }
            });
        });
        return temp;
    }
});

export const {
    useCreateWorkDetailsMutation,
    useUpdateWorkDetailsMutation,
    useDeleteWorkDetailsMutation,
    useGetWorkDetailsMutation,
    useLoadAllWorkDetailsMutation,
    useLoadAllLazyWorkDetailsMutation
} = workDetailsAPI;

export default workDetailsAPI.reducer;
