import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import isEqual from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import LockResetIcon from "@mui/icons-material/LockReset";
import { ReactComponent as ID_SVG } from "../../../assets/images/id-icon.svg";
import Input, { INPUT_TYPE } from "../../../common/components/extra/form/Input";
import { useChangeCompanyPasswordMutation, useCompanyDetailsMutation, useUpdateCompanyProfileMutation } from "../../company/auth/companyAPI";
import { useAppDispatch, useAppSelector } from "../../../common/hooks/reduxHooks";
import {
    TOAST_TYPE,
    createConfirmAlert,
    createToast,
    isPhoneValid,
    sanitizeWords,
    toReadablePhone,
    transformCountries
} from "../../../common/utilities/helper";
import { selectProfile, setProfile, updateProfile } from "./slice";
import { COMPANY_FILES } from "../../../common/utilities/const";
import Divider from "../../../common/components/extra/Divider";
import { selectCountries, selectIndustries, updateUser } from "../../common/slice";
import Button from "../../../common/components/extra/Button";
import { UPLOAD_FIELDS, VIEW_MODAL_TYPE } from "../../admin/companies/const";
import ViewModalSub from "../../admin/companies/ViewModalSub";
import LetteredAvatar from "../../../common/components/extra/avatar/LetteredAvatar";
import Card from "../../../common/components/extra/Card";
import Select from "../../../common/components/extra/select/Select";
import ChangePassword from "./ChangePassword";

const { LICENSE, LOGO, ESTABLISHMENT_ID } = UPLOAD_FIELDS;

const INITIAL_FIELDS = {
    name: "",
    email: "",
    address: "",
    industry_type: "",
    country: "",
    license_number: "",
    establishment_id: "",
    contact_name: "",
    contact_email: "",
    contact_position: "",
    contact_number: ""
};

const INITIAL_UPLOADS = Object.values(COMPANY_FILES).reduce((prev, curr) => ({ ...prev, [curr.key]: null }), {});

function ProfileCompanyTab({ setParentLoading, onLoading, styles }) {
    const [loading, setLoading] = useState(true);
    const [mobileCode, setMobileCode] = useState("");
    const [isEditing, setIsEditing] = useState(false);
    const [uploads, setUploads] = useState(INITIAL_UPLOADS);
    const [viewObject, setViewObject] = useState({ type: null, data: null });
    const [old, setOld] = useState(null);
    const [isChangePass, setChangePass] = useState(false);

    const dispatch = useAppDispatch();
    const [getDetails] = useCompanyDetailsMutation();
    const [updateCompany] = useUpdateCompanyProfileMutation();
    const [changePassword] = useChangeCompanyPasswordMutation();

    const profile = useAppSelector(selectProfile);
    const industries = useAppSelector(selectIndustries).map((ind) => ({ ...ind, value: ind.id, label: ind.name }));
    const supportedCountries = transformCountries(useAppSelector(selectCountries), { isRight: true });
    const hasUploads = !!Object.values(uploads).filter(Boolean).length;

    const getIndustry = (val) => industries.find((industry) => industry.value === val) || "";
    const getCountry = (cca2) => supportedCountries.find((country) => country.value === cca2) || "";

    const fetchDetails = async () => {
        try {
            const response = await getDetails();
            if (response.error) {
                throw new Error(response.error?.data?.message);
            }
            const data = response.data?.data || {};
            const contactNumber = data.contact_number;
            const isMobileNumberValid = isPhoneValid(contactNumber);
            isMobileNumberValid && setMobileCode(isMobileNumberValid.countryCode);
            dispatch(setProfile(data));
            if (!old) {
                setOld(data);
            }
        } catch (error) {
            createToast(error.message || "Failed to fetch profile, please try again later", TOAST_TYPE.ERROR);
        } finally {
            setLoading(false);
        }
    };

    const updateApi = async (body, removeLogo) => {
        typeof onLoading === "function" && onLoading(true);
        setParentLoading?.(true);
        const formData = new FormData();
        for (const field in uploads) {
            if (Object.hasOwnProperty.call(uploads, field)) {
                const file = uploads[field];
                file && formData.append(field, file);
            }
        }
        formData.append("others", JSON.stringify(body));
        const result = await updateCompany({
            formData: true,
            body: hasUploads ? formData : body,
            extraPaths: [profile.id, hasUploads ? "with-file" : "no-file"]
        });
        if (result.error) {
            setParentLoading?.(false);
            throw new Error(result?.error?.data?.message || "Failed to update profile, please try again later.");
        }
        const data = cloneDeep(result.data.data);
        const newdata = {};
        if (data.name) newdata.name = data.name;
        if (hasUploads) {
            newdata.logo = data.uploads.logo;
            newdata.logoSrc = data.uploads.tempLogoSrc;
        }
        if (removeLogo) {
            newdata.logo = "";
            newdata.logoSrc = "";
            if (!data.uploads) {
                data.uploads = {};
            }
            data.uploads.logo = "";
            data.uploads.tempLogoSrc = "";
        }
        dispatch(updateUser(newdata));
        setParentLoading?.(false);
        typeof onLoading === "function" && onLoading(false);
        return data;
    };

    useEffect(() => {
        if (!profile?.name) {
            fetchDetails();
        } else {
            const contactNumber = profile.contact_number;
            const isMobileNumberValid = isPhoneValid(contactNumber);
            isMobileNumberValid && setMobileCode(isMobileNumberValid.countryCode);
            setLoading(false);
        }
    }, []);

    const handleChange = (e) => {
        const config = {};
        const name = e.target.name;
        const value = e.target.value;
        const code = e.target?.mobileCode;
        config[name] = value;
        if (code) {
            setMobileCode(code);
        }
        handleFormChange(config);
    };

    const handleFormChange = (config = {}) => dispatch(updateProfile(config));
    const handleViewChange = (newObject = {}) => setViewObject({ ...viewObject, ...newObject });
    const updateUploads = (newObj = {}) => setUploads((prev) => ({ ...prev, ...newObj }));
    const handleUploadsChange = (e) => updateUploads({ [e.target.name]: e.target?.files?.[0] });

    const toggleEdit = ({ isCancel } = {}) => {
        if (isCancel && old) {
            dispatch(setProfile(old));
            updateUploads(INITIAL_UPLOADS);
        }
        setIsEditing((prev) => !prev);
    };

    const toggleChangePass = () => setChangePass(!isChangePass);

    const handleSave = async () => {
        try {
            const toSave = INITIAL_FIELDS;
            Object.keys(toSave).forEach((ks) => (toSave[ks] = profile[ks]));
            const removeLogo = !!profile.uploads?.removeLogo;
            toSave.removeLogo = removeLogo;
            // check if number is already in correct format
            const phone = isPhoneValid(toSave.contact_number);
            let isValid = phone.isValid;
            if (!isValid && mobileCode) {
                const newMobileNumber = mobileCode + toSave.contact_number;
                isValid = isPhoneValid(newMobileNumber).isValid;
                if (!isValid) {
                    throw new Error("Mobile number is not valid.");
                } else {
                    toSave.contact_number = newMobileNumber;
                }
            } else {
                const splitphone = phone.phoneNumber.split(phone.countryCode);
                splitphone[0] = mobileCode;
                const newphone = splitphone.join("");
                if (isPhoneValid(newphone).isValid) {
                    toSave.contact_number = newphone;
                } else {
                    throw new Error("Mobile number is not valid.");
                }
            }
            const result = await updateApi(toSave, removeLogo);
            setOld({ ...old, ...result });
            // contact_number is mutated so we need to update the global object
            handleFormChange(result);
            setUploads(INITIAL_UPLOADS);
            toggleEdit();
            createToast("Profile updated successfully.", TOAST_TYPE.SUCCESS);
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
        }
    };

    const transferToAnotherObj = (original = {}, toTransfer = {}) => {
        const newObj = {};
        Object.keys(original).forEach((ks) => (newObj[ks] = toTransfer[ks]));
        return newObj;
    };

    const checkHasChanges = () => {
        const fields = { ...INITIAL_FIELDS, removeLogo: false };
        const cloneProfile = cloneDeep(profile);
        const cloneOld = cloneDeep(old || {});
        cloneProfile.removeLogo = !!cloneProfile.uploads?.removeLogo;
        cloneOld.removeLogo = !!cloneOld.uploads?.removeLogo;
        const newProfile = transferToAnotherObj(fields, cloneProfile);
        const oldProfile = transferToAnotherObj(fields, cloneOld);
        const phone = isPhoneValid(profile.contact_number);
        let hasChanges = hasUploads || (!!old && !isEqual(newProfile, oldProfile));

        if (phone.isValid) {
            if (phone.countryCode !== mobileCode) {
                hasChanges = true;
            }
        }

        return hasChanges;
    };

    const handleLogoChange = (newsrc, fileObj) => {
        const isRemove = !newsrc;
        const toUpdate = { logo: newsrc, uploads: { ...profile.uploads } };
        if (isRemove) {
            // this means we will remove this file in the backend
            toUpdate.uploads.removeLogo = true;
        } else {
            toUpdate.uploads.removeLogo = false;
        }
        handleFormChange(toUpdate);
        handleUploadsChange({ target: { name: LOGO.key, files: [fileObj] } });
    };

    const handleFileChange = (e, fileKey) => {
        const fileObj = e.target?.files?.[0];
        if (fileObj) {
            const reader = new FileReader();
            reader.onloadend = () => {
                handleFormChange({ [fileKey]: reader.result });
                handleUploadsChange({ target: { name: fileKey, files: [fileObj] } });
            };
            reader.readAsDataURL(fileObj);
        } else {
            handleUploadsChange({ target: { name: fileKey, files: [fileObj] } });
        }
    };

    const inputPropsValue = (value, style = {}, { noSanitize } = {}) => {
        if (isEditing) {
            return {
                value,
                style
            };
        } else {
            return {
                renderValue: typeof value == "string" ? (noSanitize ? value : sanitizeWords(value)) : value,
                readOnly: true,
                parentStyle: style
            };
        }
    };

    const renderViewButton = (type, data) => (
        <div>
            <Button
                options={{ style: { textAlign: "right", padding: 0, textDecoration: "underline" } }}
                onClick={() => handleViewChange({ type, data })}
                transparent
                small
            >
                View
            </Button>
        </div>
    );

    const renderInput = ({ name, label, value, onChange, options, afterExtra, ...rest } = {}) => {
        if (isEditing) {
            return (
                <Select
                    options={options}
                    onChange={(val) => onChange({ target: { name, value: val.value } })}
                    value={value}
                    name={name}
                    label={label}
                    placeholder=""
                    style={{ flex: "30%" }}
                    isClearable={false}
                    menuPortalTarget={document.body}
                    isOutlined
                    disabledOutline
                    {...rest}
                />
            );
        }
        return (
            <Input
                type={INPUT_TYPE.TEXT}
                name={name}
                label={label}
                onChange={onChange}
                afterExtra={afterExtra}
                isLoading={loading}
                {...inputPropsValue(typeof value === "object" && "label" in value ? value.label : value)}
            />
        );
    };

    const renderControls = (
        <div className="flex gap-1" style={{ marginLeft: "auto" }}>
            {!isEditing && !isChangePass && (
                <Button
                    options={{ type: "button" }}
                    beforeExtra={<LockResetIcon style={{ color: "red" }} />}
                    onClick={toggleChangePass}
                    disabled={loading}
                    small
                >
                    Change Password
                </Button>
            )}
            {isEditing && (
                <Button
                    options={{ type: "button" }}
                    beforeExtra={<CancelIcon style={{ color: "red" }} />}
                    onClick={() => {
                        toggleEdit({ isCancel: true });
                        if (isChangePass) {
                            toggleChangePass();
                        }
                    }}
                    disabled={loading}
                    small
                >
                    Cancel
                </Button>
            )}
            {!isEditing && (
                <Button options={{ type: "button" }} beforeExtra={<ModeEditIcon />} className="primary" onClick={toggleEdit} disabled={loading} small>
                    Edit
                </Button>
            )}
            {isEditing && (
                <Button options={{ type: "submit" }} beforeExtra={<SaveIcon />} className="primary" disabled={loading || !checkHasChanges()} small>
                    Save
                </Button>
            )}
        </div>
    );

    if (isChangePass) {
        return <ChangePassword onCancel={toggleChangePass} onSubmit={(conf) => changePassword({ body: conf })} />;
    }

    return (
        <Card className="box-shadow-mini" styles={styles}>
            <form
                className="profile-tab flex column"
                onSubmit={(e) => {
                    e.preventDefault();
                    createConfirmAlert({
                        title: "Save Profile",
                        content: "Are you certain about this action? It will result in profile update, and once completed, cannot be reversed",
                        onConfirm: (onClose) => {
                            onClose();
                            handleSave();
                        }
                    });
                }}
            >
                <div className="flex column" style={{ gap: "2rem" }}>
                    <section className="flex column gap-05 center">
                        <LetteredAvatar
                            size={90}
                            name={profile.name}
                            isLoading={loading}
                            src={profile.logo}
                            onChange={handleLogoChange}
                            style={{ boxShadow: "0 2px 3px #c7c7c7" }}
                            editmode={isEditing}
                            fetchConfig={{
                                paths: [profile.id],
                                filename: profile?.uploads?.[LOGO.key]
                            }}
                            isCompany
                            hasborder
                        />
                        {!loading && (
                            <div className="name">
                                {isEditing ? (
                                    <Input
                                        type={INPUT_TYPE.TEXT}
                                        name="name"
                                        label="Company Name"
                                        onChange={handleChange}
                                        minLength={4}
                                        hidelabel
                                        autoFocus
                                        {...inputPropsValue(profile.name, { fontWeight: "600", textTransform: "capitalize", textAlign: "center" })}
                                    />
                                ) : (
                                    <span style={{ fontWeight: "600", textTransform: "capitalize", textAlign: "center" }}>
                                        {sanitizeWords(profile.name)}
                                    </span>
                                )}
                            </div>
                        )}
                    </section>
                    <section className="flex column gap-05">
                        {renderInput({
                            name: "industry_type",
                            label: "Industry",
                            onChange: handleChange,
                            value: getIndustry(profile?.industry_type),
                            options: industries
                        })}
                        {renderInput({
                            name: "country",
                            label: "Country",
                            onChange: handleChange,
                            value: getCountry(profile.country),
                            options: industries
                        })}
                        <Input
                            type={INPUT_TYPE.TEXT}
                            name="address"
                            label="Address"
                            onChange={handleChange}
                            {...inputPropsValue(profile.address)}
                            isLoading={loading}
                            required
                        />
                        {!isEditing && (
                            <Input
                                type={INPUT_TYPE.TEXT}
                                name="name"
                                label="Email Address"
                                renderValue={profile.email}
                                isLoading={loading}
                                readOnly
                            />
                        )}
                    </section>
                    <section className="flex column gap-05">
                        <Divider title="License Details" />
                        <Input
                            type={INPUT_TYPE.TEXT}
                            name="license_number"
                            label="Number (ID)"
                            onChange={handleChange}
                            minLength={3}
                            isLoading={loading}
                            required
                            {...inputPropsValue(profile.license_number, {}, { noSanitize: true })}
                        />
                        <Input
                            type={!isEditing ? INPUT_TYPE.TEXT : INPUT_TYPE.UPLOAD}
                            name={LICENSE.key}
                            icon={<ID_SVG />}
                            accept={LICENSE.accepts}
                            sizeLimit={LICENSE.size}
                            label="File"
                            onChange={(e) => handleFileChange(e, LICENSE.key)}
                            onView={() => {
                                handleViewChange({
                                    type: VIEW_MODAL_TYPE.LICENSE_COPY,
                                    data: {
                                        src: uploads[LICENSE.key] && URL.createObjectURL(uploads[LICENSE.key]),
                                        filename: uploads[LICENSE.key]?.name || profile?.uploads?.[LICENSE.key],
                                        id: profile.id
                                    }
                                });
                            }}
                            {...inputPropsValue(
                                isEditing
                                    ? !!profile?.uploads?.[LICENSE.key]
                                    : renderViewButton(VIEW_MODAL_TYPE.LICENSE_COPY, {
                                          id: profile.id,
                                          filename: profile?.uploads?.[LICENSE.key]
                                      })
                            )}
                            hidelabel={isEditing}
                            isLoading={!isEditing ? loading : false}
                        />
                    </section>
                    <section className="flex column gap-05">
                        <Divider title="Establishment Details" />
                        <Input
                            type={INPUT_TYPE.TEXT}
                            name="establishment_id"
                            label="Number (ID)"
                            onChange={handleChange}
                            minLength={7}
                            maxLength={8}
                            isLoading={loading}
                            required
                            {...inputPropsValue(profile.establishment_id, {}, { noSanitize: true })}
                        />
                        <Input
                            type={!isEditing ? INPUT_TYPE.TEXT : INPUT_TYPE.UPLOAD}
                            name={ESTABLISHMENT_ID.key}
                            icon={<ID_SVG />}
                            accept={ESTABLISHMENT_ID.accepts}
                            sizeLimit={ESTABLISHMENT_ID.size}
                            label="File"
                            onChange={(e) => handleFileChange(e, ESTABLISHMENT_ID.key)}
                            onView={() => {
                                handleViewChange({
                                    type: VIEW_MODAL_TYPE.ESTANLISHMENT_ID_COPY,
                                    data: {
                                        src: uploads[ESTABLISHMENT_ID.key] && URL.createObjectURL(uploads[ESTABLISHMENT_ID.key]),
                                        filename: uploads[ESTABLISHMENT_ID.key]?.name || profile?.uploads?.[ESTABLISHMENT_ID.key],
                                        id: profile.id
                                    }
                                });
                            }}
                            {...inputPropsValue(
                                isEditing
                                    ? !!profile?.uploads?.[LICENSE.key]
                                    : renderViewButton(VIEW_MODAL_TYPE.ESTANLISHMENT_ID_COPY, {
                                          id: profile.id,
                                          filename: profile?.uploads?.[ESTABLISHMENT_ID.key]
                                      })
                            )}
                            hidelabel={isEditing}
                            isLoading={!isEditing ? loading : false}
                        />
                    </section>
                    <section className="flex column gap-05">
                        <Divider title="Contact" />
                        <Input
                            type={INPUT_TYPE.TEXT}
                            name="contact_name"
                            label="Name"
                            onChange={handleChange}
                            isLoading={loading}
                            {...inputPropsValue(profile.contact_name)}
                        />
                        {isEditing ? (
                            <Input
                                type={INPUT_TYPE.MOBILE}
                                name="contact_number"
                                label="Mobile Number"
                                onChange={handleChange}
                                style={{ minWidth: "8rem" }}
                                value={profile.contact_number}
                                portalCodes
                                {...inputPropsValue(profile.contact_number)}
                            />
                        ) : (
                            <Input
                                type={INPUT_TYPE.TEXT}
                                name="contact_number"
                                label="Mobile Number"
                                onChange={handleChange}
                                isLoading={loading}
                                {...inputPropsValue(toReadablePhone(profile.contact_number))}
                            />
                        )}
                        <Input
                            type={INPUT_TYPE.TEXT}
                            nativeType="email"
                            name="contact_email"
                            label="Email"
                            onChange={handleChange}
                            isLoading={loading}
                            {...inputPropsValue(profile.contact_email, {}, { noSanitize: true })}
                        />
                        <Input
                            type={INPUT_TYPE.TEXT}
                            name="contact_position"
                            label="Position"
                            onChange={handleChange}
                            minLength={2}
                            isLoading={loading}
                            {...inputPropsValue(profile.contact_position)}
                        />
                    </section>
                    {renderControls}
                </div>
                {!!viewObject.type && (
                    <ViewModalSub
                        open={!!viewObject.type}
                        onChange={(bool) => handleViewChange({ type: bool ? viewObject.type : null })}
                        type={viewObject.type}
                        data={viewObject.data}
                    />
                )}
            </form>
        </Card>
    );
}

export default ProfileCompanyTab;

ProfileCompanyTab.propTypes = {
    loading: PropTypes.bool,
    setParentLoading: PropTypes.func,
    onLoading: PropTypes.func,
    styles: PropTypes.shape({
        parent: PropTypes.object,
        content: PropTypes.object
    })
};
