import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import TimePickerRange from "../../../common/components/extra/dateTime/TimePickerRange";
import Divider from "../../../common/components/extra/Divider";
import Select from "../../../common/components/extra/select/Select";
import WorkDetailSelectLazy from "../employeeWorkDetails/WorkDetailSelectLazy";
import WorkShiftSelectLazy from "../employeeWorkShift/WorkShiftSelectLazy";
import Input, { INPUT_TYPE } from "../../../common/components/extra/form/Input";
import SiteSelectLazy from "../companySites/SiteSelectLazy";
import { EDIT_TYPE, FIELDS, TIME_TYPE } from "./const";
import SectionCollapseInfo from "../../../common/components/extra/section/SectionCollapseInfo";
import { createTextFromDate, renderNA, sanitizeWords } from "../../../common/utilities/helper";
import Tag from "../../../common/components/extra/Tag";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectUserSetting } from "../../common/slice";
import { Others, Overtime, Shift } from "./ShiftStatus";
import { NOTES_MAX_LEN, STANDARD_DATE_FORMAT, WORK_HISTORY_SHIFT_STATUS } from "../../../common/utilities/const";

const { TYPE, WORK_TYPE_ID, WORK_SHIFT_ID, SITE_ONE_ID, SITE_TWO_ID, OVERTIME_SITE_ID, NOTE } = FIELDS;

const TIMEPICKER_PROPS = {
    menuPlacement: "top",
    isPortal: true,
    interval: 1,
    noResetOnChange: true
};

function FormUpdateInputs({
    form,
    config,
    disableFields,
    handleFormChange,
    handleTimeChange,
    setting,
    isOff,
    isUpdate,
    editType,
    date,
    isForceOTOffDay
}) {
    const [initialData, setInitialData] = useState({});

    const settings = useAppSelector(selectUserSetting);
    const timezone = settings.timezone;
    const firstShiftDoneIdx = config.shiftStatus.findIndex((status) => status == WORK_HISTORY_SHIFT_STATUS.FIRST_SHIFT_DONE);
    const secondShiftDoneIdx = config.shiftStatus.findIndex((status) => status == WORK_HISTORY_SHIFT_STATUS.SECOND_SHIFT_DONE);
    const isDefaultEditType = (isUpdate && editType == EDIT_TYPE.DEFAULT) || !isUpdate;
    const isOverTimeEditType = isUpdate && editType == EDIT_TYPE.OVERTIME;
    const isTimingEditType = isUpdate && editType == EDIT_TYPE.TIMING;
    const isReadOnly = isUpdate && editType == EDIT_TYPE.READ_ONLY;

    useEffect(() => {
        if (isUpdate) {
            setInitialData(form);
        }
    }, []);

    const createWorkShiftInfo = () => {
        const workshift = config.workShift;
        if (!workshift) return {};
        const name = sanitizeWords(workshift.title);
        const type = sanitizeWords(workshift.shift_type);
        const date = createTextFromDate(workshift.start_time, workshift.end_time_2, timezone).date;
        const shiftOneTime = createTextFromDate(workshift.start_time, workshift.end_time, timezone).time || renderNA("None");
        const shiftTwoTime = createTextFromDate(workshift.start_time_2, workshift.end_time_2, timezone).time || renderNA("None");
        const maxBreakDuration = workshift.max_break_duration ? `${workshift.max_break_duration} hour(s)` : renderNA("None");
        const shiftOneBreak = config.hasBreakOne
            ? createTextFromDate(workshift.break_time, workshift.break_end_time, timezone).time ||
              (workshift.max_break_duration ? "Flexible Break" : "No Break")
            : workshift.max_break_duration
              ? "Flexible Break"
              : "No Break";
        const shiftTwoBreak = config.hasBreakTwo
            ? createTextFromDate(workshift.break_time_2, workshift.break_end_time_2, timezone).time ||
              (workshift.max_break_duration ? "Flexible Break" : "No Break")
            : workshift.max_break_duration
              ? "Flexible Break"
              : "No Break";
        const maxOverTime = workshift.max_overtime ? `${workshift.max_overtime} hour(s)` : renderNA("None");
        const reqShiftHours = workshift.required_shift_time ? `${workshift.required_shift_time} hour(s)` : renderNA("None");
        return {
            name,
            type,
            date,
            shiftOneTime,
            shiftTwoTime,
            shiftOneBreak,
            shiftTwoBreak,
            maxOverTime,
            maxBreakDuration,
            reqShiftHours
        };
    };

    const info = createWorkShiftInfo();

    return (
        <div className="flex column gap-05">
            <Select
                label="History Type"
                value={config.type}
                options={config.typeOpt}
                onChange={(target) => handleFormChange({ name: TYPE.name, value: target.value })}
                isDisabled={disableFields || isOff || !isDefaultEditType || isForceOTOffDay}
                required
                isOutlined
                disabledOutline
            />
            {isOff && (
                <span className="fade small-font bold" style={{ color: "red", marginTop: ".3rem" }}>
                    Type will automatically be {"'OFF'"} if the data is affected.
                </span>
            )}
            <WorkDetailSelectLazy
                label="Work Type"
                value={form[WORK_TYPE_ID.name]}
                onChange={(target) => handleFormChange({ name: WORK_TYPE_ID.name, value: target })}
                isDisabled={disableFields || isUpdate}
                isSearchable={false}
                allowInUse
                required
                isOutlined
                disabledOutline
            />
            <WorkShiftSelectLazy
                label="Work Shift"
                value={form[WORK_SHIFT_ID.name]}
                onChange={(target) => handleFormChange({ name: WORK_SHIFT_ID.name, value: target })}
                isDisabled={disableFields || isUpdate}
                isSearchable={false}
                required
                isOutlined
                disabledOutline
            />
            {!config.workShift && (
                <span className="fade small-font bold" style={{ color: "red", marginTop: ".3rem" }}>
                    Please select a workshift to display additional input fields below.
                </span>
            )}
            {config.workShift && (
                <>
                    <SectionCollapseInfo title={<span className="small-font">Work Shift Info Reference (Recommended)</span>} show>
                        <ul className="flex column gap-05 small-font">
                            <li className="flex gap-05 wrap" style={{ marginLeft: ".5rem", marginTop: ".5rem", alignItems: "center" }}>
                                Name:
                                <span className="bold primary-color" style={{ alignItems: "center" }}>
                                    {info.name}
                                </span>
                                <Tag>{info.type}</Tag>
                            </li>
                            <li className="flex gap-05 wrap" style={{ marginLeft: ".5rem", alignItems: "center" }}>
                                Required Shift: <span className="primary-color bold">{info.reqShiftHours}</span>
                            </li>
                            <li className="flex gap-05 wrap" style={{ marginLeft: ".5rem", alignItems: "center" }}>
                                Max Break Duration: <span className="primary-color bold">{info.maxBreakDuration}</span>
                            </li>
                            <li className="flex gap-05 wrap" style={{ marginLeft: ".5rem", alignItems: "center" }}>
                                Max OT: <span className="primary-color bold">{info.maxOverTime}</span>
                            </li>
                            <li className="flex column gap-05 wrap" style={{ marginLeft: ".5rem" }}>
                                {config.isSplit ? "Shift 1" : "Shift"}:
                                <ul style={{ marginLeft: ".5rem", alignItems: "center" }}>
                                    <li>
                                        Time : <span className="primary-color bold">{info.shiftOneTime}</span>
                                    </li>
                                    <li>
                                        Break: <span className="primary-color bold">{info.shiftOneBreak}</span>
                                    </li>
                                </ul>
                            </li>
                            {config.isSplit && (
                                <li className="flex column gap-05 wrap" style={{ marginLeft: ".5rem" }}>
                                    Shift 2:
                                    <ul style={{ marginLeft: ".5rem", alignItems: "center" }}>
                                        <li>
                                            Time: <span className="primary-color bold">{info.shiftTwoTime}</span>
                                        </li>
                                        <li>
                                            Break: <span className="primary-color bold">{info.shiftTwoBreak}</span>
                                        </li>
                                    </ul>
                                </li>
                            )}
                        </ul>
                    </SectionCollapseInfo>
                    <div className="flex column" style={{ marginTop: "1rem" }}>
                        <Divider
                            title={
                                <div className="flex gap-05 center">
                                    {!config.isSplit ? "Shift" : "First Shift"}
                                    <Shift
                                        label={!config.isSplit ? "Shift" : "First Shift"}
                                        timeInOne={config.shiftOne.range.start}
                                        timeOutOne={config.shiftOne.range.end}
                                        isShiftDone={config.shiftStatus.includes(WORK_HISTORY_SHIFT_STATUS.FIRST_SHIFT_DONE)}
                                        isOnTime={config.shiftStatus.includes(WORK_HISTORY_SHIFT_STATUS.ON_TIME)}
                                        isLate={config.shiftStatus.includes(WORK_HISTORY_SHIFT_STATUS.LATE)}
                                        isIncomplete={config.shiftOneIsInc}
                                        dontShowEmpty
                                        simple
                                    />
                                    <Others
                                        statuses={[
                                            ...new Set(
                                                config.shiftStatus.filter(
                                                    (status, i) =>
                                                        i > secondShiftDoneIdx &&
                                                        firstShiftDoneIdx > -1 &&
                                                        [
                                                            WORK_HISTORY_SHIFT_STATUS.HALF_DAY,
                                                            WORK_HISTORY_SHIFT_STATUS.OVER_BREAK,
                                                            WORK_HISTORY_SHIFT_STATUS.UNDERTIME
                                                        ].includes(status)
                                                )
                                            )
                                        ]}
                                    />
                                </div>
                            }
                        />
                        <SiteSelectLazy
                            label="Site"
                            value={form[SITE_ONE_ID.name]}
                            onChange={(target) => handleFormChange({ name: SITE_ONE_ID.name, value: target })}
                            isDisabled={disableFields || !!(isUpdate && initialData[SITE_ONE_ID.name]) || isOverTimeEditType || isReadOnly}
                            required
                            isOutlined
                            disabledOutline
                        />
                        <TimePickerRange
                            label="Time"
                            range={config.shiftOne.range}
                            constraint={config.shiftOne.constraint}
                            onChange={(conf) => handleTimeChange(TIME_TYPE.SHIFT_ONE, conf)}
                            timezone={setting.timezone}
                            disabled={disableFields || isOverTimeEditType || isReadOnly}
                            baseDate={date && moment(date).format(STANDARD_DATE_FORMAT)}
                            required
                            {...TIMEPICKER_PROPS}
                        />
                        <TimePickerRange
                            label="Break"
                            range={config.breakOne.range}
                            constraint={config.breakOne.constraint}
                            onChange={(conf) => handleTimeChange(TIME_TYPE.BREAK_ONE, conf)}
                            timezone={setting.timezone}
                            disabled={disableFields || !config.hasBreakOne || isOverTimeEditType || isReadOnly}
                            required={!!config.breakOne.range.start}
                            baseDate={date && moment(date).format(STANDARD_DATE_FORMAT)}
                            noBorder
                            {...TIMEPICKER_PROPS}
                        />
                    </div>
                    {config.isSplit && (
                        <div className="flex column" style={{ marginTop: "1rem" }}>
                            <Divider
                                title={
                                    <div className="flex gap-05 center">
                                        Second Shift
                                        <Shift
                                            label="Second Shift"
                                            timeInOne={config.shiftTwo.range.start}
                                            timeOutOne={config.shiftTwo.range.end}
                                            isShiftDone={config.shiftStatus.includes(WORK_HISTORY_SHIFT_STATUS.SECOND_SHIFT_DONE)}
                                            isOnTime={config.shiftStatus.includes(WORK_HISTORY_SHIFT_STATUS.ON_TIME_SECOND_SHIFT)}
                                            isLate={config.shiftStatus.includes(WORK_HISTORY_SHIFT_STATUS.LATE_SECOND_SHIFT)}
                                            isIncomplete={config.shiftTwoIsInc}
                                            dontShowEmpty
                                            simple
                                        />
                                        <Others
                                            statuses={[
                                                ...new Set(
                                                    config.shiftStatus.filter(
                                                        (status, i) =>
                                                            i > secondShiftDoneIdx &&
                                                            [
                                                                WORK_HISTORY_SHIFT_STATUS.HALF_DAY,
                                                                WORK_HISTORY_SHIFT_STATUS.OVER_BREAK,
                                                                WORK_HISTORY_SHIFT_STATUS.UNDERTIME
                                                            ].includes(status)
                                                    )
                                                )
                                            ]}
                                        />
                                    </div>
                                }
                            />
                            <SiteSelectLazy
                                label="Site"
                                value={form[SITE_TWO_ID.name]}
                                onChange={(target) => handleFormChange({ name: SITE_TWO_ID.name, value: target })}
                                isDisabled={disableFields || !!(isUpdate && initialData[SITE_TWO_ID.name]) || !config.hasShiftTwo}
                                menuPlacement="top"
                                required={!!config.breakTwo.range.start || !!config.shiftTwo.range.start || isOverTimeEditType || isReadOnly}
                                isClearable
                                isOutlined
                                disabledOutline
                            />
                            <TimePickerRange
                                label="Time"
                                range={config.shiftTwo.range}
                                constraint={config.shiftTwo.constraint}
                                onChange={(conf) => handleTimeChange(TIME_TYPE.SHIFT_TWO, conf)}
                                timezone={setting.timezone}
                                disabled={disableFields || !config.hasShiftTwo || isOverTimeEditType || isReadOnly}
                                required={!!form[SITE_TWO_ID.name]}
                                baseDate={config.shiftOne.range.end && config.shiftOne.range.end.format(STANDARD_DATE_FORMAT)}
                                {...TIMEPICKER_PROPS}
                            />
                            <TimePickerRange
                                label="Break"
                                range={config.breakTwo.range}
                                constraint={config.breakTwo.constraint}
                                onChange={(conf) => handleTimeChange(TIME_TYPE.BREAK_TWO, conf)}
                                timezone={setting.timezone}
                                disabled={disableFields || !config.hasBreakTwo || !config.hasShiftTwo || isOverTimeEditType || isReadOnly}
                                required={!!config.breakTwo.range.start}
                                baseDate={config.shiftOne.range.end && config.shiftOne.range.end.format(STANDARD_DATE_FORMAT)}
                                noBorder
                                {...TIMEPICKER_PROPS}
                            />
                        </div>
                    )}
                    <div className="flex column" style={{ marginTop: "1rem" }}>
                        <Divider
                            title={
                                <div className="flex gap-05 center">
                                    Overtime
                                    <Overtime
                                        isOTStart={config.shiftStatus.includes(WORK_HISTORY_SHIFT_STATUS.OVERTIME)}
                                        isOTDone={config.shiftStatus.includes(WORK_HISTORY_SHIFT_STATUS.OVERTIME_DONE)}
                                        transform={(status) => (status.includes("Ongoing") ? "Incomplete" : status)}
                                        simple
                                    />
                                </div>
                            }
                        />
                        <SiteSelectLazy
                            label="Site"
                            value={form[OVERTIME_SITE_ID.name]}
                            onChange={(target) => handleFormChange({ name: OVERTIME_SITE_ID.name, value: target })}
                            isDisabled={disableFields || !!(isUpdate && initialData[OVERTIME_SITE_ID.name]) || isTimingEditType || isReadOnly}
                            menuPlacement="top"
                            required={!!config.overtime.range.start}
                            isClearable
                            isOutlined
                            disabledOutline
                        />
                        <TimePickerRange
                            label="Time"
                            range={config.overtime.range}
                            constraint={config.overtime.constraint}
                            onChange={(conf) => handleTimeChange(TIME_TYPE.OVERTIME, conf)}
                            timezone={setting.timezone}
                            disabled={disableFields || isTimingEditType || isReadOnly}
                            required={!!config.overtime.range.start || !!form[OVERTIME_SITE_ID.name]}
                            baseDate={
                                config.isSplit
                                    ? config.shiftTwo.range.end && config.shiftTwo.range.end.format(STANDARD_DATE_FORMAT)
                                    : config.shiftOne.range.end && config.shiftOne.range.end.format(STANDARD_DATE_FORMAT)
                            }
                            {...TIMEPICKER_PROPS}
                        />
                    </div>
                    <div style={{ marginTop: "1rem" }}>
                        <Input
                            type={INPUT_TYPE.TEXTAREA}
                            label="Note"
                            name={NOTE.name}
                            value={form[NOTE.name]}
                            placeholder="Your note here..."
                            onChange={(e) => handleFormChange({ name: e.target.name, value: e.target.value })}
                            disabled={disableFields || isReadOnly}
                            maxLength={NOTES_MAX_LEN}
                        />
                    </div>
                </>
            )}
        </div>
    );
}

export default FormUpdateInputs;

FormUpdateInputs.propTypes = {
    form: PropTypes.object,
    config: PropTypes.object,
    disableFields: PropTypes.bool,
    handleFormChange: PropTypes.func,
    handleTimeChange: PropTypes.func,
    setting: PropTypes.object,
    isOff: PropTypes.bool,
    isUpdate: PropTypes.bool,
    hasChanges: PropTypes.bool,
    hasDefaultEmployee: PropTypes.bool,
    editType: PropTypes.oneOf(Object.values(EDIT_TYPE)),
    date: PropTypes.any,
    isForceOTOffDay: PropTypes.bool
};
