import React from "react";
import CardPage from "../../../common/components/extra/CardPage";
import Input from "../../../common/components/extra/Input";
import SendIcon from "@mui/icons-material/Send";

function handleSendEmail() {
    window.location.href = "mailto:support@time-keeper.app";
}

function ReportBug() {
    return (
        <CardPage className="report-bug" contentStyle={{ display: "flex", flexDirection: "column", gap: "1rem" }} title="Report a Bug" danger>
            <span>We typically reply within 24 hrs</span>
            <Input afterExtra={<SendIcon style={{ color: "#0052CC" }} onClick={handleSendEmail} />} placeholder="I need assistance" />
        </CardPage>
    );
}

export default ReportBug;
