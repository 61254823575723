import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { capitalizeWords } from "../../utilities/helper";

function ButtonTab({ items, onSelect, onRender, header, style, disabledTabs = [] }) {
    const getActualDefValue = () => {
        const maxIndex = items.length - 1;
        const actualDefIndex = disabledTabs.length > maxIndex ? maxIndex : disabledTabs.length;
        return items[actualDefIndex];
    };

    const [selected, setSelected] = useState(getActualDefValue());

    const handleSelect = (x) => {
        setSelected(x);
        typeof onSelect === "function" && onSelect(x);
    };

    useEffect(() => {
        setSelected(getActualDefValue());
        typeof onSelect === "function" && onSelect(getActualDefValue());
    }, []);

    return (
        <div className="tk-button-tab" style={style || {}}>
            {header}
            <div className="tk-button-tab__inner">
                <ul>
                    {items.map((x) => {
                        const className = `tk-button-tab__item ${selected == x && "tk-button-tab--active"} ${
                            (disabledTabs.includes(x) && "disabled") || ""
                        }`.trim();
                        return (
                            <li key={x} onClick={() => handleSelect(x)} className={className}>
                                {(onRender && onRender(x)) || capitalizeWords(x)}
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
}

ButtonTab.propTypes = {
    header: PropTypes.any,
    items: PropTypes.array,
    onSelect: PropTypes.func,
    onRender: PropTypes.func,
    style: PropTypes.object,
    disabledTabs: PropTypes.arrayOf(PropTypes.string)
};

export default ButtonTab;
