import React from "react";
import { useNavigate } from "react-router-dom";
import SignUp from "../../features/company/auth/SignUp";
import { useAppDispatch } from "../../common/hooks/reduxHooks";
import { setSignup } from "../../features/common/slice";
import { TOAST_TYPE, createToast } from "../../common/utilities/helper";
import { SIGNUP_FLOW } from "../../features/common/const";
import Navigation from "../../common/classes/Navigation";

function SignUpPage() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const handleNext = (data) => {
        dispatch(setSignup({ current: SIGNUP_FLOW.VERIFICATION, data }));
        navigate(Navigation.Routes.SIGNUP_VERIFY.path);
        createToast("Check your inbox for an email with instructions to verify your email address.", TOAST_TYPE.INFO);
    };
    return (
        <div className="tk-signup-flow">
            <SignUp onNext={handleNext} />
        </div>
    );
}

export default SignUpPage;
