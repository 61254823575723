import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import BaseViewModal from "../../../common/components/layout/modalViewUpdateLayout/BaseViewModal";
import { useGetSubmittedForm, useUpdateSubmittedFormStatus } from "./hooks";
import { createClass, createConfirmAlert, createGroup, sanitizeWords, toTimeWithTimeZone } from "../../../common/utilities/helper";
import Input from "../../../common/components/extra/form/Input";
import Button from "../../../common/components/extra/Button";
import UpsertHistoryModal from "../employeeWorkHistories/UpdateModal";
import SectionCollapseInfo from "../../../common/components/extra/section/SectionCollapseInfo";
import { SUBMITTED_FORM_STATUS } from "./const";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectUser } from "../../common/slice";
import Tag from "../../../common/components/extra/Tag";
import { FORM_FIELD_TYPE } from "../../../common/utilities/const";
import { EDIT_TYPE } from "../employeeWorkHistories/const";

const BASE_CLASS = "tk-employees-submitted-forms__modal-content-view";

function ViewModal({ open, onClose, onEdit, id }) {
    const [updateHistory, setUpdateHistory] = useState(false);

    const [current, { isLoading, config, updateCurrent, clearCurrent }] = useGetSubmittedForm(id);
    const [updateStatus, isUpdatingStatus] = useUpdateSubmittedFormStatus(id);

    const setting = useAppSelector(selectUser);
    const timezone = setting.timezone;

    const isCreateHistory = !config.toUpdateHistoryId && config.toCreateHistoryDate;
    const isUpdatehistory = !!config.toUpdateHistoryId;
    const showInfoHistory = config.isPending && (isUpdatehistory || isCreateHistory);
    const showApprove = (config.isApproved || (!isUpdatehistory && !isCreateHistory)) && (config.isPending || config.isApproved);

    const COMMON_PROPS = {
        readOnly: true,
        isLoading
    };

    useEffect(() => {
        return () => clearCurrent();
    }, []);

    const handleUpsertHistoryBack = () => {
        setUpdateHistory(false);
    };

    const handleHistoryUpsert = (newresult) => {
        setUpdateHistory(false);
        handleUpdateStatus(false, newresult.id);
    };

    const handleUpdateHistory = () => {
        setUpdateHistory(true);
    };

    const handleUpdateStatus = async (isReject, historyId) => {
        const result = await updateStatus({ isReject, historyId });
        if (!result.error) {
            updateCurrent({ status: isReject ? SUBMITTED_FORM_STATUS.REJECTED : SUBMITTED_FORM_STATUS.APPROVED });
        }
    };

    return (
        <BaseViewModal
            open={open}
            onClose={onClose}
            onEdit={onEdit}
            disableEdit={false}
            styles={{ content: { height: "max-content", minHeight: "17rem" }, body: { flexGrow: 1 } }}
            isLoading={isUpdatingStatus || isLoading}
        >
            <div className={createClass(`${BASE_CLASS} flex column`)} style={{ flexGrow: 1 }}>
                <div className={createClass("__inner flex column", BASE_CLASS)} style={{ flexGrow: 1, justifyContent: "space-between" }}>
                    <div className={createClass("__inner-row", BASE_CLASS)} style={{ marginBottom: "3rem" }}>
                        {createGroup({
                            base: BASE_CLASS,
                            title: current && (
                                <span>
                                    <span className="fade">{sanitizeWords(current.formData.name)} - </span>
                                    {sanitizeWords(current.fullName)}
                                </span>
                            ),
                            body: (
                                <div className="flex column gap-05" style={{ marginTop: "1rem" }}>
                                    {current &&
                                        current.formData.fields.map((field, idx) => {
                                            if (Array.isArray(field.value)) {
                                                return (
                                                    <Input
                                                        key={idx}
                                                        label={field.label}
                                                        renderValue={
                                                            <div className="flex wrap gap-05" style={{ marginLeft: "2rem" }}>
                                                                {field.value.map((v, idxv) => (
                                                                    <Tag key={idxv}>{sanitizeWords(typeof v == "object" ? v.value : v)}</Tag>
                                                                ))}
                                                            </div>
                                                        }
                                                        {...COMMON_PROPS}
                                                    />
                                                );
                                            } else if (field?.value?.html) {
                                                return <div key={idx} dangerouslySetInnerHTML={{ __html: field.value.html }}></div>;
                                            } else {
                                                let val = field.value;
                                                const isTextArea = field.type == FORM_FIELD_TYPE.TEXT_AREA;
                                                if (!isTextArea && typeof val == "string") val = sanitizeWords(val);
                                                if (typeof val == "boolean") val = val ? "Yes" : "No";
                                                return (
                                                    <Input
                                                        key={idx}
                                                        label={field.label}
                                                        renderValue={<div style={{ marginLeft: "2rem" }}>{val}</div>}
                                                        {...COMMON_PROPS}
                                                    />
                                                );
                                            }
                                        })}
                                    {showInfoHistory && (config.toCreateHistoryDate || config.history.start_shift_time) && (
                                        <Input
                                            label={!isCreateHistory ? "Date to Modify" : "Date to Create"}
                                            renderValue={toTimeWithTimeZone(
                                                config.toCreateHistoryDate || config.history.start_shift_time,
                                                timezone
                                            ).format("ddd MMM DD, YYYY")}
                                            readOnly
                                        />
                                    )}
                                </div>
                            )
                        })}
                    </div>
                    {showInfoHistory && (
                        <div className={createClass("__inner-row", BASE_CLASS)} style={{ marginBottom: "3rem" }}>
                            <SectionCollapseInfo show>
                                Click {isCreateHistory ? "create" : "update"} history to {isCreateHistory ? "create" : "update"} the record that is
                                attached to the request. This will also approve the request.
                            </SectionCollapseInfo>
                        </div>
                    )}
                    <div className={createClass("__inner-row flex gap-05 w100", BASE_CLASS)} style={{ justifyContent: "space-between" }}>
                        <div className="left">
                            {(config.toUpdateHistoryId || (!config.isRejected && config.toCreateHistoryDate)) && (
                                <div className="flex gap-05 center">
                                    <Button className="secondary" onClick={handleUpdateHistory} small>
                                        {config.historyEditType == EDIT_TYPE.READ_ONLY ? "View" : isCreateHistory ? "Create" : "Update"} History
                                    </Button>
                                    {config.toUpdateHistoryId && config?.history?.updatedAt && (
                                        <span className="small-font fade">
                                            Last Updated: {toTimeWithTimeZone(config.history.updatedAt, timezone).fromNow()}
                                        </span>
                                    )}
                                </div>
                            )}
                        </div>
                        <div className="right flex gap-05">
                            {!isLoading && (config.isPending || config.isRejected) && (
                                <Button
                                    className="danger"
                                    onClick={() =>
                                        createConfirmAlert({
                                            title: "Reject Form?",
                                            content: "Are you sure you want to reject this form? Once confirmed, the status cannot be changed again.",
                                            onConfirm: async (close) => {
                                                close();
                                                handleUpdateStatus(true);
                                            }
                                        })
                                    }
                                    disabled={isUpdatingStatus || config.isRejected}
                                    transparent={showApprove}
                                    small
                                >
                                    {config.isRejected ? "Rejected" : "Reject"}
                                </Button>
                            )}
                            {!isLoading && showApprove && (
                                <Button
                                    className="primary"
                                    onClick={() =>
                                        createConfirmAlert({
                                            title: "Approve Form?",
                                            content:
                                                "Are you sure you want to approve this form? Once confirmed, the status cannot be changed again.",
                                            onConfirm: async (close) => {
                                                close();
                                                handleUpdateStatus(false);
                                            }
                                        })
                                    }
                                    disabled={isUpdatingStatus || config.isApproved}
                                    small
                                >
                                    {config.isApproved ? "Approved" : "Approve"}
                                </Button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {updateHistory && (
                <UpsertHistoryModal
                    onBack={handleUpsertHistoryBack}
                    open={updateHistory}
                    onClose={() => setUpdateHistory(false)}
                    onFinish={handleHistoryUpsert}
                    data={config.history}
                    newDate={!config.toUpdateHistoryId && config.toCreateHistoryDate}
                    editType={config.historyEditType}
                    employee={current && current.Employee}
                    noSuccessMessage
                />
            )}
        </BaseViewModal>
    );
}

ViewModal.propTypes = {
    open: PropTypes.bool,
    isLoading: PropTypes.bool,
    id: PropTypes.any,
    onClose: PropTypes.func,
    onEdit: PropTypes.func
};

export default ViewModal;
