import React, { useEffect, useState } from "react";
import isEqual from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";
import PreviewIcon from "@mui/icons-material/Visibility";
import PlaceIcon from "@mui/icons-material/Place";
import { FILTER_ALL_VALUE, ROLE_LEVEL, ROLE_TYPE, SORT_ORDER } from "../../../common/utilities/const";
import { TOAST_TYPE, createToast, renderNA, sanitizeWords } from "../../../common/utilities/helper";
import { useLoadEmployeesLazyMutation } from "./api";
import Tag, { TAG_TYPE } from "../../../common/components/extra/Tag";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectUser } from "../../common/slice";
import { createDefaultCheckId } from "./helper";
import { ASSIGN_TYPE } from "./const";
import Button from "../../../common/components/extra/Button";
import MyTooltip from "../../../common/components/extra/Tooltip";
import SeeMore from "../../../common/components/extra/SeeMore";

export const useLazyEmployees = ({
    value = null,
    allowOnShift,
    excludeIds = [],
    withoutShift,
    initializing,
    allowSelectOnShift,
    defaultValue,
    isFilter,
    workShiftId,
    excludeWorkShiftId,
    excludeWorkDetailIds,
    workDetailIds
} = {}) => {
    const LOAD_MORE_OFFSET = 10;
    const DEFAULT_SIZE = 20;

    const [isMounted, setMounted] = useState(false);
    const [object, setObject] = useState({
        data: [],
        sort: { sortBy: "index1", order: SORT_ORDER.ASC },
        totalCount: 0,
        cursor: "",
        search: ""
    });

    const hasMore = object.totalCount > object.data?.length || 0;

    const [load, { isLoading }] = useLoadEmployeesLazyMutation();

    const updateObject = (newObject = {}) => setObject((prev) => ({ ...prev, ...newObject }));

    const isCurrentValue = (id) => (Array.isArray(defaultValue) ? defaultValue.includes(id) : defaultValue == id);

    const fetch = async ({ sort, ...config } = {}, isReset) => {
        if (!sort) {
            sort = object.sort;
        }
        if (isReset) {
            config.cursor = "";
        }
        try {
            const response = await load({
                body: {
                    pageSize: DEFAULT_SIZE,
                    more: isReset ? DEFAULT_SIZE : LOAD_MORE_OFFSET,
                    excludeIds,
                    withoutShift,
                    workShiftId,
                    excludeWorkShiftId,
                    excludeWorkDetailIds,
                    workDetailIds,
                    ...object.sort,
                    ...sort,
                    ...(config || {})
                }
            });

            if (response.error) {
                throw new Error(response.error?.data?.message);
            }
            if (response.data && response.data.data) {
                let resdata = response.data.data || [];
                let newdata = [];
                if (isFilter) {
                    newdata = [...resdata.data].map((t) => {
                        const newObj = cloneDeep(t);
                        const fullName = sanitizeWords(`${newObj?.first_name || ""} ${newObj?.last_name || ""}`.trim()) || "Unknown";
                        newObj.value = newObj.id || "";
                        newObj.id = !newObj.id ? newObj.value : newObj.id;
                        newObj.label = !newObj.value ? "All Employees" : fullName;
                        newObj.fullName = newObj?.fullName || "";
                        newObj.index1 = newObj?.index1 || "";
                        return {
                            ...newObj,
                            id: newObj.id,
                            value: newObj.value,
                            label: (
                                <div className="flex gap-05" style={{ alignItems: "center" }}>
                                    <span className="text-ellipsis small-font bold">{sanitizeWords(newObj.label)}</span>
                                </div>
                            )
                        };
                    });
                } else {
                    newdata = resdata.data.map((t) => {
                        const isOnShift = !allowOnShift && t.isOnShift;
                        return {
                            ...t,
                            value: t.id,
                            label: (
                                <div className="flex gap-05" style={{ alignItems: "center" }}>
                                    {!allowOnShift && <Tag type={isOnShift ? TAG_TYPE.ON_SHIFT : TAG_TYPE.AVAILABLE} />}
                                    <span style={{ paddingRight: "4px" }}>{sanitizeWords(`${t.first_name} ${t.last_name}`)}</span>
                                </div>
                            ),
                            isFixed: allowSelectOnShift && !isCurrentValue(t.id) ? false : isOnShift,
                            isDisabled: allowSelectOnShift ? false : isOnShift
                        };
                    });
                }

                const sameCursor = isEqual(object.cursor, resdata.cursor);
                const temp = {
                    data: isReset ? newdata : !sameCursor ? object.data.concat(newdata) : object.data,
                    cursor: resdata.cursor,
                    totalCount: resdata.totalCount
                };
                if (isFilter) {
                    temp.data = temp.data
                        .filter((t) => t.value)
                        .concat({
                            id: FILTER_ALL_VALUE.value,
                            value: FILTER_ALL_VALUE.value,
                            label: (
                                <div className="flex gap-05" style={{ alignItems: "center" }}>
                                    <span className="text-ellipsis small-font bold">All Employees</span>
                                </div>
                            )
                        });
                }
                sort && (temp.sort = sort);
                updateObject(temp);
                return temp.data;
            }
        } catch (error) {
            createToast(error.message || "Something went wrong with the server. Please contact support.", TOAST_TYPE.ERROR);
            sort && updateObject({ sort, data: [], totalCount: 0 });
        }
    };

    const loadMore = () => hasMore && fetch({ cursor: object.cursor });
    const reset = () => fetch({}, true);
    const search = (value = "") => fetch({ search: value }, true);
    const sort = ({ sortBy, order }) => fetch({ sort: { sortBy, order } }, true);

    useEffect(() => {
        setMounted(true);
    }, []);

    useEffect(() => {
        if (isMounted && !initializing) {
            let val = value?.index1;
            if (Array.isArray(value)) {
                val = [...value.map((v) => v.index1)];
                val.sort((a, b) => a.localeCompare(b));
                val = val.pop();
            }
            fetch({ startFrom: val || "" });
        }
    }, [isMounted, initializing]);

    return [object, updateObject, { isLoading, hasMore, fetch, reset, loadMore, search, sort }];
};

export const useLazyEmployeeManager = ({
    excludeIds,
    withoutShift,
    enableUncheckedOnshift,
    uniqueKey,
    onChange,
    onMount,
    selected,
    readOnly,
    type = ASSIGN_TYPE.DEFAULT,
    workShiftId,
    hasInternalSelections,
    excludeWorkShiftId,
    excludeWorkDetailIds,
    workDetailIds,
    title,
    disableSupervisors,
    showSites
} = {}) => {
    const [object, setObject] = useState({
        selected: null,
        checked: [],
        unchecked: [],
        viewSites: false
    });

    const [config, , { loadMore, search, isLoading, sort, fetch, reset }] = useLazyEmployees({
        excludeIds,
        withoutShift,
        workShiftId,
        excludeWorkShiftId,
        excludeWorkDetailIds,
        workDetailIds
    });

    const user = useAppSelector(selectUser);
    const setting = user.Setting || {};
    const timezone = setting.timezone;

    // can be used to determine which values can be unchecked
    const defaultCheckedId = !hasInternalSelections ? createDefaultCheckId(config.data, selected, type) : [];

    let { data: headers } = CreateTableHeaders({
        type,
        onViewRecord: (row) => updateObject({ selected: row }),
        onViewSites: (row) => updateObject({ selected: row, viewSites: true }),
        readOnly,
        timezone,
        defaultCheckedId,
        enableUncheckedOnshift,
        title,
        workShiftId,
        disableSupervisors,
        showSites,
        selected
    });

    const updateObject = (newObj = {}) => setObject((prev) => ({ ...prev, ...newObj }));

    const handleCheckChange = (val, key, keys, type, ids, removedKeys) => {
        let temp = { [uniqueKey]: [] };
        if (keys[uniqueKey]) {
            temp[uniqueKey] = keys[uniqueKey];
        } else {
            temp[uniqueKey] = keys;
        }
        updateObject({ checked: temp[uniqueKey], unchecked: removedKeys });
        typeof onChange == "function" && onChange({ checked: temp[uniqueKey], unchecked: removedKeys });
    };

    const handleSearch = (e) => {
        const value = typeof e == "string" ? e : e.target.value;
        search((value && value.toLowerCase().trim()) || "");
    };

    useEffect(() => {
        fetch().then((result) => {
            if (result) {
                onMount?.({ defaultCheckedIds: !hasInternalSelections ? createDefaultCheckId(result, selected, type) : [] });
            }
        });
    }, []);

    return {
        unique: uniqueKey,
        headers,
        data: config.data,
        onSort: sort,
        sort: config.sort,
        defaultChecked: defaultCheckedId,
        onLoadMore: loadMore,
        onCheckChange: handleCheckChange,
        isLoading: isLoading,
        onSearch: handleSearch,
        object,
        updateObject,
        totalCount: config.totalCount,
        reset
    };
};

const CreateTableHeaders = ({
    onViewRecord,
    onViewSites,
    readOnly,
    defaultCheckedId,
    enableUncheckedOnshift,
    type,
    title,
    workShiftId,
    disableSupervisors,
    showSites,
    selected
}) => {
    const MAX_SITES_TO_SHOW = 5;
    const isWorkShift = type == ASSIGN_TYPE.WORK_SHIFT;
    const isSite = type == ASSIGN_TYPE.SITE;
    const headers = {
        CHECKBOX: {
            key: "id",
            width: "10%",
            style: { justifyContent: "flex-start" },
            render: () => "",
            isCheckbox: !readOnly,
            disableSelection: (row) => {
                const isSuperVisor = row.Role && row.Role?.type == ROLE_TYPE.EMPLOYEE && row.Role?.level == ROLE_LEVEL.HIGH;
                return (
                    (defaultCheckedId.includes(row.id) && row.isOnShift) ||
                    (!enableUncheckedOnshift && row.isOnShift && !defaultCheckedId.includes(row.id)) ||
                    (disableSupervisors && isSuperVisor)
                );
            }
        },
        EMPLOYEE: {
            key: "index1",
            sortKey: "index1",
            label: title || "Employee",
            width: "70%",
            render: (row) => {
                const fullName = `${row.first_name} ${row.last_name}`;
                const department = row.CompanyDepartment;
                const designation = row.CompanyDesignation;
                const isSuperVisor = row.Role && row.Role?.type == ROLE_TYPE.EMPLOYEE && row.Role?.level == ROLE_LEVEL.HIGH;
                return (
                    <div className="flex column gap-05">
                        <div className="flex column gap-05" style={{ justifyContent: "center" }}>
                            <span>
                                <span className="bold">{sanitizeWords(fullName)}</span>
                            </span>
                            <div className="flex gap-05 wrap">
                                {isWorkShift &&
                                    !defaultCheckedId.includes(row.id) &&
                                    row?.EmployeeWorkShift &&
                                    workShiftId == row?.EmployeeWorkShift?.id && <Tag className="red">{row?.EmployeeWorkShift?.title}</Tag>}
                                {row.isOnShift && <Tag type={TAG_TYPE.ON_SHIFT} />}
                                {isSuperVisor && <Tag type={TAG_TYPE.APP_SUPERVISOR} />}
                            </div>
                        </div>
                        <div className="flex gap-05 wrap small-font" style={{ alignItems: "center" }}>
                            <span className="small-font fade">Department:</span>
                            <span className="semi-bold">{sanitizeWords(department.title) || renderNA("No Department.")}</span>
                        </div>
                        <div className="flex gap-05 wrap small-font" style={{ alignItems: "center" }}>
                            <span className="small-font fade">Designation:</span>
                            <span className="semi-bold">{sanitizeWords(designation.title) || renderNA("No Designation.")}</span>
                        </div>
                        {isSite && showSites && row?.CompanySites && (
                            <div className="flex gap-05 wrap small-font" style={{ alignItems: "center" }}>
                                <span className="small-font fade">Sites:</span>
                                <SeeMore max={MAX_SITES_TO_SHOW}>
                                    {row?.CompanySites.map((site, idx) => (
                                        <MyTooltip key={idx} message={site.title}>
                                            <Tag className={site.id == selected.id ? "green" : ""} style={{ maxWidth: "7rem" }}>
                                                <span className="text-ellipsis">{site.title}</span>
                                            </Tag>
                                        </MyTooltip>
                                    ))}
                                </SeeMore>
                            </div>
                        )}
                    </div>
                );
            }
        },
        ACTION: {
            key: "action",
            width: "20%",
            style: { display: "flex", justifyContent: "flex-end" },
            render: (row) => {
                return (
                    <div className="flex gap-05">
                        <Button
                            onClick={() => typeof onViewRecord === "function" && onViewRecord(row)}
                            options={{ style: { padding: 0, boxShadow: "unset", marginLeft: "auto", maxWidth: "max-content" } }}
                            transparent
                        >
                            <div className="fade">
                                <PreviewIcon style={{ width: "1.5rem" }} />
                            </div>
                        </Button>
                        <Button
                            onClick={() => typeof onViewSites === "function" && onViewSites(row)}
                            options={{ style: { padding: 0, boxShadow: "unset", marginLeft: "auto", maxWidth: "max-content" } }}
                            transparent
                        >
                            <div className="fade">
                                <PlaceIcon style={{ width: "1.5rem" }} />
                            </div>
                        </Button>
                    </div>
                );
            }
        }
    };
    return { data: Object.values(headers), original: headers };
};
