import React from "react";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import PersonOnIcon from "@mui/icons-material/Person";
import PendingIcon from "@mui/icons-material/PendingActions";
import CompleteIcon from "@mui/icons-material/PriceCheck";

import { COMPANY_STATUS } from "../../../common/utilities/const";
import { MORE_ACTIONS, STATUS_ACTIONS, UPDATE_FILE_TYPE } from "./const";
import { toReadableFromDate } from "../../../common/utilities/helper";

export const checkCompanyStatus = (status = []) => {
    const obj = {
        color: "",
        label: "",
        isUnverifiedEmail: false,
        isWaitingForApproval: false,
        isDeactivatedAccount: false,
        isApproved: false
    };

    const isUserApproved = status.includes(COMPANY_STATUS.VERIFIED); // if user is approved by super admin user is verified
    const isUserActive = status.includes(COMPANY_STATUS.ACTIVE); // if email is verified user is active

    const isUnverifiedEmail = !isUserActive && !isUserApproved;
    const isWaitingForApproval = isUserActive && !isUserApproved;
    const isDeactivatedAccount = !isUserActive && isUserApproved;

    if (isUnverifiedEmail) {
        obj.label = "Email Unverified";
        obj.color = "red";
        obj.isUnverifiedEmail = true;
    } else if (isWaitingForApproval) {
        obj.label = "Wating For Approval";
        obj.color = "yellow";
        obj.isWaitingForApproval = true;
    } else if (isDeactivatedAccount) {
        obj.label = "Deactivated";
        obj.color = "red";
        obj.isDeactivatedAccount = true;
    } else {
        obj.label = "Approved";
        obj.color = "green";
        obj.isApproved = true;
    }
    return obj;
};

export const checkCompanyPaymentStatus = (status = []) => {
    const obj = { color: "", label: "", isPending: false, isCompleted: false };
    const isPendingPayment = status?.includes(COMPANY_STATUS.PENDING_PAYMENT);

    if (isPendingPayment) {
        obj.label = "Pending";
        obj.color = "yellow";
        obj.isPending = true;
    } else {
        return null;
    }

    return obj;
};

export const getActionItems = () => {
    return Object.values(MORE_ACTIONS).map((act) => ({ ...act, render: act.label }));
};

export const createStatusActionItems = ({ statuses = [] } = {}) => {
    const status = checkCompanyStatus(statuses);
    const paymentStatus = checkCompanyPaymentStatus(statuses);

    if (status.isUnverifiedEmail) {
        return [].filter((item) => !item.isFiltered);
    }

    return [
        {
            id: STATUS_ACTIONS.DISAPPROVE,
            render: <span style={{ marginLeft: ".2rem" }}>{status.isApproved ? "Disapprove Account" : "Approve Account"}</span>,
            icon: <ThumbDownIcon style={{ width: "1.2rem" }} />,
            isFiltered: status.isDeactivatedAccount || !status.isApproved
        },
        {
            id: status.isDeactivatedAccount ? STATUS_ACTIONS.ACTIVATE : STATUS_ACTIONS.DEACTIVATE,
            render: status.isDeactivatedAccount ? "Activate Account" : "Deactivate Account",
            icon: status.isDeactivatedAccount ? <PersonOnIcon /> : <PersonOffIcon />
        },
        {
            id: status.isPending ? STATUS_ACTIONS.COMPLETE_PAYMENT : STATUS_ACTIONS.PENDING_PAYMENT,
            render: paymentStatus && paymentStatus.isPending ? "Complete Payment" : "Pending Payment",
            icon: status.isPending ? <CompleteIcon /> : <PendingIcon style={{ width: "1.4rem" }} />,
            isFiltered: !paymentStatus
        }
    ].filter((item) => !item.isFiltered);
};

export const detectFileChanges = ({ last_file_upload_date, last_renewal_date } = {}) => {
    if (!!last_file_upload_date || !!last_renewal_date) {
        return {
            code: last_file_upload_date ? UPDATE_FILE_TYPE.FILE_CHANGED : UPDATE_FILE_TYPE.CONTRACT_RENEWAL,
            hasChanges: true,
            date: toReadableFromDate(last_file_upload_date || last_renewal_date)
        };
    }
};
