import React from "react";
import PreviewIcon from "@mui/icons-material/Visibility";
import ContactEmergencyIcon from "@mui/icons-material/ContactEmergency";
import { selectUser } from "../../common/slice";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import Tag from "../../../common/components/extra/Tag";
import { getCountryName, isTimePassed, renderNA, sanitizeWords, toReadableFromDate, toTimeWithTimeZone } from "../../../common/utilities/helper";
import { TABLE_ACTIONS, UPDATE_FILE_TYPE } from "./const";
import LetteredAvatar from "../../../common/components/extra/avatar/LetteredAvatar";
import { checkCompanyPaymentStatus, checkCompanyStatus, detectFileChanges, getActionItems } from "./helper";
import More from "../../../common/components/common/More";
import UpdateFiles from "./UpdateFiles";
import MyTooltip from "../../../common/components/extra/Tooltip";
import { TABLE_ACTION_TYPE_COMMON } from "../../../common/utilities/const";

const HEADERS = {
    NAME: "name",
    EMAIL: "email",
    COUNTRY: "country",
    LICENSE_EXPIRY: "license_expiry_date",
    EID_EXPIRY: "establishment_id_expiry_date",
    CONTRACT_EXPIRY: "contract_expiry_date",
    LAST_RENEWAL_DATE: "last_renewal_date",
    LAST_FILE_UPLOAD_DATE: "last_file_upload_date",
    LAST_LOGIN: "last_login",
    VERIFIED_BY: "verified_by",
    CREATED_AT: "createdAt",
    ACTIONS: "actions"
};

const HEADERS_TO_ARRAY = Object.values(HEADERS);

const CreateTableHeaders = ({ onAction }) => {
    const user = useAppSelector(selectUser);

    return HEADERS_TO_ARRAY.map((head) => {
        const temp = { key: head, style: {} };
        switch (head) {
            case HEADERS.NAME: {
                temp.sortKey = "name";
                temp.label = "Name";
                temp.style.width = "17rem";
                temp.fixed = true;
                temp.childStyle = {
                    alignItems: "center",
                    gap: "1rem",
                    margin: ".5rem 0"
                };
                temp.render = (_, row) => {
                    const companyName = row.name;
                    const status = checkCompanyStatus(row.status);
                    const paymentStatus = checkCompanyPaymentStatus(row.status);
                    const detected = detectFileChanges(row) || {};
                    const isWaitingForApproval = status.isWaitingForApproval;
                    isWaitingForApproval && (detected.code = UPDATE_FILE_TYPE.APPROVAL);

                    return (
                        <>
                            <div className="flex gap-1" style={{ alignItems: "center" }}>
                                <LetteredAvatar name={companyName} src={row.photo} size={35} small />
                                <div className="flex column gap-05">
                                    <div style={{ fontWeight: "bold" }}>{sanitizeWords(companyName)}</div>
                                    <div className="flex gap-05 small-font" style={{ alignItems: "center" }}>
                                        <span className="small-font fade">Employees:</span>
                                        <Tag>{row.employee_count}</Tag>
                                    </div>
                                    <div className="flex gap-05 small-font" style={{ alignItems: "center" }}>
                                        <span className="small-font fade">Status:</span>
                                        <Tag className={status.color}>{status.label}</Tag>
                                    </div>
                                    {paymentStatus && (
                                        <div className="flex gap-05 small-font" style={{ alignItems: "center" }}>
                                            <span className="small-font fade">Payment:</span>
                                            <Tag className={paymentStatus.color}>{paymentStatus.label}</Tag>
                                        </div>
                                    )}
                                    {detected && <UpdateFiles type={detected.code} date={detected.date} data={row} />}
                                </div>
                            </div>
                            {!isTimePassed(toTimeWithTimeZone(row.createdAt), 1) && (
                                <div className="new-tag">
                                    <Tag className="green">NEW</Tag>
                                </div>
                            )}
                        </>
                    );
                };
                break;
            }
            case HEADERS.EMAIL: {
                temp.label = "Email";
                temp.style.width = "13rem";
                temp.render = (_, row) => (
                    <div className="flex column gap-05 semi-bold">
                        <span>{row.email}</span>
                    </div>
                );
                break;
            }
            case HEADERS.COUNTRY: {
                temp.label = "Country";
                temp.center = true;
                temp.render = (_, row) => (
                    <div className="flex column gap-05 semi-bold">
                        <span>{getCountryName(row.country)}</span>
                    </div>
                );
                break;
            }
            case HEADERS.LICENSE_EXPIRY: {
                temp.sortKey = "index1";
                temp.label = "License Expiry";
                temp.style.width = "13rem";
                temp.render = (val) => (
                    <div className="flex column gap-05 fade">
                        <span className="small-font">{val ? toReadableFromDate(val) : renderNA()}</span>
                    </div>
                );
                break;
            }
            case HEADERS.EID_EXPIRY: {
                temp.sortKey = "index2";
                temp.label = "EID Expiry";
                temp.style.width = "13rem";
                temp.render = (val) => (
                    <div className="flex column gap-05 fade">
                        <span className="small-font">{val ? toReadableFromDate(val) : renderNA()}</span>
                    </div>
                );
                break;
            }
            case HEADERS.CONTRACT_EXPIRY: {
                temp.sortKey = "index3";
                temp.label = "Contract Expiry";
                temp.style.width = "13rem";
                temp.render = (val) => (
                    <div className="flex column gap-05 fade">
                        <span className="small-font">{val ? toReadableFromDate(val) : renderNA()}</span>
                    </div>
                );
                break;
            }
            case HEADERS.LAST_RENEWAL_DATE: {
                temp.sortKey = "index4";
                temp.label = "Last Renewal Date";
                temp.style.width = "13rem";
                temp.render = (val) => (
                    <div className="flex column gap-05 fade">
                        <span className="small-font">{val ? toReadableFromDate(val) : renderNA()}</span>
                    </div>
                );
                break;
            }
            case HEADERS.LAST_FILE_UPLOAD_DATE: {
                temp.sortKey = "index5";
                temp.label = "Last File Upload Date";
                temp.style.width = "13rem";
                temp.render = (val) => (
                    <div className="flex column gap-05 fade">
                        <span className="small-font">{val ? toReadableFromDate(val) : renderNA()}</span>
                    </div>
                );
                break;
            }
            case HEADERS.LAST_LOGIN: {
                temp.sortKey = "last_login";
                temp.label = "Last Login";
                temp.render = (val) => (
                    <div className="flex column gap-05 fade">
                        <span className="small-font">{val ? toReadableFromDate(val) : renderNA()}</span>
                    </div>
                );
                break;
            }
            case HEADERS.VERIFIED_BY: {
                temp.sortKey = "verified_by";
                temp.label = "Verified By";
                temp.render = (_, row) => {
                    const val = row.is_verified && (row.verified_by == user.id ? "Me" : row.SuperAdmin?.name);
                    return val ? (
                        <span className="fade" style={{ marginRight: "2rem" }}>
                            {sanitizeWords(val)}
                        </span>
                    ) : (
                        <div className="flex overflow-hidden">
                            <span className="text-ellipsis small-font">{renderNA()}</span>
                        </div>
                    );
                };
                break;
            }
            case HEADERS.CREATED_AT: {
                temp.sortKey = "createdAt";
                temp.label = "Created";
                temp.render = (val) => (
                    <div className="flex column gap-05 fade">
                        <span className="small-font">{toReadableFromDate(val)}</span>
                    </div>
                );
                break;
            }
            case HEADERS.ACTIONS: {
                temp.childStyle = {
                    display: "flex",
                    gap: ".5rem",
                    justifyContent: "flex-end",
                    paddingRight: "1rem"
                };
                temp.style.width = "13rem";
                temp.wrapperStyle = {
                    overflow: "unset"
                };
                temp.render = (_, row) => {
                    return (
                        <div className="flex gap-05 w100" style={{ justifyContent: "space-between" }}>
                            <div className="flex gap-05">
                                <MyTooltip message="View Contact Details">
                                    <ContactEmergencyIcon onClick={() => onAction(row, TABLE_ACTIONS.CONTACT_DETAILS)} />
                                </MyTooltip>
                                <PreviewIcon onClick={() => onAction(row, TABLE_ACTION_TYPE_COMMON.PREVIEW)} />
                            </div>
                            <More
                                items={getActionItems()}
                                styles={{ items: { paddingRight: ".3rem" } }}
                                onSelect={(item) => onAction(row, item.id)}
                            />
                        </div>
                    );
                };
                break;
            }
            default:
                temp.label = head.title;
                temp.render = (_, row) => {
                    return <div className="flex column gap-05">{row[head.id]}</div>;
                };
                break;
        }
        return temp;
    });
};

export default CreateTableHeaders;
