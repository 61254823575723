import React from "react";
import PropTypes from "prop-types";
import Modal from "../../../common/components/extra/Modal";
import GoogleMap from "../../../common/components/extra/map/Map";
import Empty from "../../../common/components/extra/Empty";

function ViewMapModal({ open, onClose, title, coordinates, isLoading }) {
    return (
        <Modal title={`View Site - ${title}`} open={open} onClose={onClose} small>
            {coordinates ? (
                <GoogleMap
                    title={title || "Map"}
                    styles={{
                        parent: {
                            height: "70vh",
                            width: "70vw",
                            minWidth: "30rem"
                        }
                    }}
                    coordinates={coordinates}
                    defaultZoom={12}
                    disabled={false}
                    editable={false}
                    isLoading={isLoading}
                />
            ) : (
                <Empty />
            )}
        </Modal>
    );
}

ViewMapModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    title: PropTypes.string,
    coordinates: PropTypes.array,
    isLoading: PropTypes.bool
};

export default ViewMapModal;
