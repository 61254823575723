import React, { useEffect } from "react";
import CardPage from "../../common/components/extra/CardPage";
import BulkUpload from "../../features/company/employeesBulkUpload/BulkUpload";
import { useAppSelector } from "../../common/hooks/reduxHooks";
import { selectUser } from "../../features/common/slice";
import useNavigateHooks from "../../common/hooks/useNavigate";
import Navigation from "../../common/classes/Navigation";

function EmployeesBulkUploadPage() {
    const navigate = useNavigateHooks();
    const user = useAppSelector(selectUser);
    const companySubscription = user?.CompanySubscription?.Subscription;
    const employeeLimit = companySubscription?.employee_limit || 0;
    const limitReached = user.totalEmployees >= employeeLimit;
    const navigateToEmployeeList = () => limitReached && navigate(Navigation.Routes.EMPLOYEES.subpaths.LIST.path);

    useEffect(() => {
        navigateToEmployeeList();
    }, []);
    useEffect(() => {
        navigateToEmployeeList();
    }, [employeeLimit]);

    return (
        <div className="tk-employees-bulk-upload">
            <div className="tk-employees-bulk-upload__inner">
                <CardPage title="Employees Bulk Upload" contentStyle={{ padding: 0, height: "calc(100vh - 12.3rem)" }}>
                    <div className="tk-employees-bulk-upload__content">
                        <div className="tk-employees-bulk-upload__body">
                            <BulkUpload />
                        </div>
                    </div>
                </CardPage>
            </div>
        </div>
    );
}

export default EmployeesBulkUploadPage;
