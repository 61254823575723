import React, { useState } from "react";
import PropTypes from "prop-types";
import isEqual from "lodash/isEqual";
import PenaltiesByLaw from "./PenaltiesByLaw";
import Modal from "../../../common/components/extra/Modal";
import { createConfirmAlert } from "../../../common/utilities/helper";

function AssignLawPenaltiesModal({ open, onClose, onSave, selectedIds = [], styles }) {
    const [newSelections, setNewSelections] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleSave = async () => {
        createConfirmAlert({
            title: "Confirm Selection",
            content: "Are you sure you want to apply the selected penalties? You can unapply them by clicking the remove button the on table.",
            onConfirm: async (close) => {
                close();
                setLoading(true);
                await onSave(newSelections);
                setLoading(false);
            }
        });
    };

    const handleSelectedPenalties = (data) => {
        setNewSelections(data);
    };

    return (
        <Modal
            open={open}
            onChange={(bool) => !bool && onClose()}
            onSave={handleSave}
            disableSave={
                !newSelections.length ||
                isEqual(
                    selectedIds,
                    newSelections.map((id) => ({ id }))
                )
            }
            isLoading={loading}
            styles={{
                content: { width: "80vw" },
                ...(styles || {})
            }}
            isForm
        >
            <div className="tk-assign-penalties-by-law">
                <div className="flex column gap-1">
                    <PenaltiesByLaw onChange={handleSelectedPenalties} removeShadow />
                </div>
            </div>
        </Modal>
    );
}

AssignLawPenaltiesModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onSave: PropTypes.func,
    selectedIds: PropTypes.array,
    styles: PropTypes.shape({
        parent: PropTypes.object,
        content: PropTypes.object,
        body: PropTypes.object,
        title: PropTypes.object,
        form: PropTypes.object,
        footer: PropTypes.object
    })
};

export default AssignLawPenaltiesModal;
