import React from "react";
import PropTypes from "prop-types";
import ErrorIcon from "@mui/icons-material/Error";
import SectionCollapse from "./SectionCollapse";

const styles = {
    icon: { color: "red" },
    header: { fontWeight: "600" },
    parent: { width: "100%", backgroundColor: "#FFD9DA", color: "red" }
};

const SectionCollapseError = ({ show, children, collapse = true, onCollapse, style, alwaysOpen }) => {
    if (!show) {
        return <></>;
    }

    return (
        <SectionCollapse
            header={
                <div className="flex center gap-05">
                    <ErrorIcon style={styles.icon} /> Error
                </div>
            }
            styles={{
                header: styles.header,
                parent: { ...styles.parent, ...(style || {}) }
            }}
            collapse={collapse}
            onCollapse={onCollapse}
            alwaysOpen={alwaysOpen}
        >
            {children}
        </SectionCollapse>
    );
};

SectionCollapseError.propTypes = {
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
    collapse: PropTypes.bool,
    show: PropTypes.bool,
    onCollapse: PropTypes.func,
    alwaysOpen: PropTypes.bool,
    style: PropTypes.object
};

export default SectionCollapseError;
