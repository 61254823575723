import React from "react";
import PropTypes from "prop-types";

export const BANNER_TYPE = {
    INFO: "info",
    WARNING: "warning",
    ERROR: "danger"
};

const { INFO, WARNING, ERROR } = BANNER_TYPE;

function Banner({ className, type, children, show, style }) {
    if (!show) return <></>;
    const getClassType = () => {
        switch (type) {
            default:
            case INFO:
                return INFO;
            case WARNING:
                return WARNING;
            case ERROR:
                return ERROR;
        }
    };
    return (
        <div style={style || {}} className={`tk-banner ${getClassType()} ${className}`}>
            {children}
        </div>
    );
}

export default Banner;

Banner.propTypes = {
    className: PropTypes.string,
    type: PropTypes.oneOf(Object.values(BANNER_TYPE)),
    children: PropTypes.any,
    show: PropTypes.bool,
    style: PropTypes.object
};
