import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import cloneDeep from "lodash/cloneDeep";
import ArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { SORT_ORDER } from "../../../utilities/const";
import { isScrollAtBottom } from "../../../utilities/helper";
import Checkbox from "../Checkbox";
import Loader from "../Loader";
import Tag from "../Tag";
import TotalRecords from "../filter/TotalRecords";

const { ASC, DESC } = SORT_ORDER;

const CHECKBOX_TYPE = { ALL: "check-all", INDIVIDUAL: "check-individual" };

const createTrackCheck = (headers = [], data = [], uniqueKey = "id") => {
    return headers
        .filter((h) => h.isCheckbox)
        .reduce((prev, curr) => ({ ...prev, [curr.key]: curr.checked ? data.map((d) => d[uniqueKey]) : [] }), {});
};

const createTrackUncheck = (headers = [], data = [], uniqueKey = "id") => {
    return headers
        .filter((h) => h.isCheckbox)
        .reduce((prev, curr) => ({ ...prev, [curr.key]: !curr.checked ? data.map((d) => d[uniqueKey]) : [] }), {});
};

function TableList({
    data = [],
    headers = [],
    height = 35,
    small,
    onSort,
    activeSort = {
        sortBy: "createdAt",
        order: DESC
    },
    onLoadMore,
    onCheckChange,
    uniqueKey = "id",
    checked,
    customEmptyEl,
    isLoadingMore,
    showCheckCount,
    isFixedHeight,
    totalCount,
    disableCheckbox,
    isLoading,
    isTotalRecordsLeft,
    footExtra
}) {
    const [trackUnChecked, setTrackUnchecked] = useState(createTrackUncheck(headers, data, uniqueKey));
    const [trackCheck, setTrack] = useState(createTrackCheck(headers, data, uniqueKey));

    const checkedItem = trackCheck?.[uniqueKey];
    const checkCount = checkedItem?.length;

    useEffect(() => {
        typeof onCheckChange === "function" &&
            onCheckChange(
                null,
                null,
                trackCheck,
                "mounting",
                data.map((d) => d[uniqueKey])
            );
    }, []);

    useEffect(() => {
        const clonechecked = cloneDeep(checked);
        if (!clonechecked) return;
        clonechecked[uniqueKey] = clonechecked[uniqueKey] ? clonechecked[uniqueKey].filter((c) => !trackUnChecked[uniqueKey].includes(c)) : [];
        typeof onCheckChange === "function" &&
            onCheckChange(
                null,
                null,
                clonechecked,
                "changing",
                data.map((d) => d[uniqueKey]),
                trackUnChecked[uniqueKey] || []
            );
        setTrack(clonechecked);
    }, [JSON.stringify(checked)]);

    // handle check events
    const updateTrack = (key = "", newTrackIds = []) => setTrack((prev) => ({ ...prev, [key]: newTrackIds }));
    const removeTrackIds = (key = "", removeIds = []) => setTrack((prev) => ({ ...prev, [key]: prev[key].filter((tc) => !removeIds.includes(tc)) }));
    // handle uncheck events
    const updateTrackUncheck = (key = "", uncheckids = []) => setTrackUnchecked((prev) => ({ ...prev, [key]: uncheckids }));
    const removeTrackUncheck = (key = "", removeIds = []) =>
        setTrackUnchecked((prev) => ({ ...prev, [key]: prev[key].filter((tc) => !removeIds.includes(tc)) }));

    const createRowStyle = () => {
        const totalHeaders = headers.length;
        const commonWidth = 100 / totalHeaders + "%";
        const getwidths = headers.map((h) => h.width || commonWidth);
        return {
            gridTemplateColumns: getwidths.join(" ")
        };
    };

    const handleScroll = (e) => {
        if (isScrollAtBottom(e.target, 100)) {
            typeof onLoadMore === "function" && onLoadMore();
        }
    };

    const handleCheckChange = (type, key, value, row, ids = [], dataObj, defaultChecked = []) => {
        let newkeys = [];
        let removedKeys = [];
        if (type === CHECKBOX_TYPE.ALL) {
            const disabledIds = dataObj.filter((d) => d.disabled).map((d) => d[uniqueKey]);
            const uncheckedDisabledIds = disabledIds.filter((k) => !defaultChecked.includes(k));
            const checkedDisabledIds = disabledIds.filter((k) => defaultChecked.includes(k));
            if (value === true) {
                newkeys = data.map((d) => d[uniqueKey]).filter((k) => !uncheckedDisabledIds.includes(k));
                updateTrack(key, newkeys);
                removeTrackUncheck(key, newkeys);
                removedKeys = trackUnChecked[key].filter((tc) => !newkeys.includes(tc));
            } else {
                newkeys = [
                    ...new Set(
                        data
                            .map((d) => d[uniqueKey])
                            .filter((id) => !ids.includes(id))
                            .concat(checkedDisabledIds)
                    )
                ];
                removedKeys = ids.filter((k) => !checkedDisabledIds.includes(k));
                updateTrack(key, newkeys);
                updateTrackUncheck(key, removedKeys);
            }
        } else if (type === CHECKBOX_TYPE.INDIVIDUAL) {
            if (value === true) {
                newkeys = [...trackCheck[key], row[uniqueKey]];
                updateTrack(key, newkeys);
                removeTrackUncheck(key, newkeys);
                removedKeys = trackUnChecked[key].filter((tc) => !newkeys.includes(tc));
            } else {
                newkeys = trackCheck[key].filter((tc) => tc !== row[uniqueKey]);
                removeTrackIds(key, [row[uniqueKey]]);
                updateTrackUncheck(key, [...new Set(ids.concat(trackUnChecked[key]))]);
                removedKeys = [...new Set(ids.concat(trackUnChecked[key]))];
            }
        }
        typeof onCheckChange === "function" && onCheckChange(value, key, newkeys, type, ids, removedKeys);
    };

    const handleSort = (order, col) => {
        if (col.sortKey == activeSort.sortBy && order == activeSort.order) return;
        typeof onSort === "function" && onSort({ sortBy: col.sortKey, order });
    };

    const parentStyle = { maxHeight: `calc(100vh - ${height}rem)`, minHeight: 22 + "rem" };

    if (isFixedHeight) {
        parentStyle.height = parentStyle.maxHeight;
    }

    if (isLoading) {
        parentStyle.opacity = 0.8;
    }

    return (
        <>
            <div className={"table-list" + (small ? " small" : "")} style={parentStyle} onScroll={handleScroll}>
                <div className="table-list__header">
                    <div className="table-list__item row head" style={createRowStyle()}>
                        {headers.map((col, i) => {
                            const currentActiveSort = col.sortKey && activeSort.sortBy === col.sortKey;
                            const getActiveOrder = (order) => currentActiveSort && activeSort.order === order;
                            const disabledSelections = col.isCheckbox && data.filter((d) => col?.disableSelection?.(d)).map((d) => d[uniqueKey]);
                            const allDisabledSelections = col.isCheckbox && data.every((d) => disabledSelections.includes(d[uniqueKey]));
                            const isCheckedAll =
                                col.isCheckbox &&
                                !!data.length &&
                                !allDisabledSelections &&
                                data.every((d) => trackCheck[col.key].includes(d[uniqueKey]));
                            return (
                                <div key={i} className="table-list__item__col head">
                                    <div
                                        className={`w100 col-value ${(col.isCheckbox && " has-checkbox") || ""}  ${
                                            (col.sortKey && " has-sort") || ""
                                        }`}
                                        style={col.style || {}}
                                    >
                                        {col.sortKey ? (
                                            <div className="sort">
                                                {col.label}
                                                <div className="sort-group">
                                                    {currentActiveSort && activeSort.order !== ASC ? (
                                                        <ArrowUpIcon
                                                            className={(getActiveOrder(ASC) && "active") || ""}
                                                            onClick={() => handleSort(ASC, col)}
                                                        />
                                                    ) : (
                                                        <ArrowDownIcon
                                                            className={(getActiveOrder(DESC) && "active") || ""}
                                                            onClick={() => handleSort(DESC, col)}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        ) : (
                                            col.label
                                        )}
                                        {col.isCheckbox && (
                                            <Checkbox
                                                checked={isCheckedAll}
                                                onChange={(bool) =>
                                                    !disableCheckbox &&
                                                    handleCheckChange(
                                                        CHECKBOX_TYPE.ALL,
                                                        col.key,
                                                        bool,
                                                        null,
                                                        data.filter((d) => !col?.disableSelection?.(d) || true).map((d) => d[uniqueKey]),
                                                        data.map((d) => ({ ...d, disabled: !!col?.disableSelection?.(d) })),
                                                        checked?.[uniqueKey] || []
                                                    )
                                                }
                                                disabled={disableCheckbox}
                                            />
                                        )}
                                    </div>
                                </div>
                            );
                        })}
                        {showCheckCount && !!checkCount && <Tag className="check-count red">{checkCount}</Tag>}
                    </div>
                </div>
                <div className="table-list__body" onScroll={handleScroll}>
                    <div className="table-list__inner">
                        {!isLoadingMore && !data.length && (
                            <div className="table-list__item row empty">{customEmptyEl || <span className="fade">No data available</span>}</div>
                        )}
                        {data.map((row, i) => (
                            <div key={i} className="table-list__item row" style={createRowStyle()}>
                                {headers.map((col, ci) => {
                                    const isDisabled = (typeof col?.disableSelection === "function" && col.disableSelection(row)) || disableCheckbox;
                                    return (
                                        <div key={col.key + i + ci} className="table-list__item__col">
                                            <div className={"col-value" + ((col.isCheckbox && " has-checkbox") || "")} style={col.style || {}}>
                                                {typeof col.render === "function"
                                                    ? col.render(row, i, trackCheck)
                                                    : typeof row === "object"
                                                      ? row?.[col.key] || ""
                                                      : ""}
                                                {col.isCheckbox && (
                                                    <Checkbox
                                                        checked={trackCheck[col.key].includes(row[uniqueKey])}
                                                        onChange={(bool) =>
                                                            !isDisabled &&
                                                            handleCheckChange(
                                                                CHECKBOX_TYPE.INDIVIDUAL,
                                                                col.key,
                                                                bool,
                                                                row,
                                                                [row[uniqueKey]],
                                                                [{ ...row[uniqueKey], disabled: !!col?.disableSelection?.(row) }]
                                                            )
                                                        }
                                                        disabled={isDisabled}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        ))}
                        {isLoadingMore && (
                            <div className="table-list__item row empty">
                                <div className="flex center">
                                    <Loader style={{ width: "2rem" }} relative />
                                    <span className="fade">Getting data...</span>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="flex gap-1 w100" style={{ marginLeft: "auto", justifyContent: "space-between" }}>
                <div className="flex center" style={{ order: isTotalRecordsLeft ? 2 : 1 }}>
                    {footExtra}
                </div>
                {!!totalCount && <TotalRecords value={totalCount} style={{ order: isTotalRecordsLeft ? 1 : 2 }} />}
            </div>
        </>
    );
}

TableList.propTypes = {
    data: PropTypes.array,
    headers: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string,
            sortKey: PropTypes.string,
            label: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
            width: PropTypes.string,
            render: PropTypes.func,
            style: PropTypes.object,
            isCheckbox: PropTypes.bool
        })
    ),
    checked: PropTypes.object,
    height: PropTypes.number,
    small: PropTypes.bool,
    onSort: PropTypes.func,
    onLoadMore: PropTypes.func,
    onCheckChange: PropTypes.func,
    activeSort: PropTypes.shape({
        sortBy: PropTypes.string,
        order: PropTypes.oneOf(Object.values(SORT_ORDER))
    }),
    uniqueKey: PropTypes.string,
    customEmptyEl: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
    isLoadingMore: PropTypes.bool,
    showCheckCount: PropTypes.bool,
    isFixedHeight: PropTypes.bool,
    isLoading: PropTypes.bool,
    disableCheckbox: PropTypes.bool,
    isTotalRecordsLeft: PropTypes.bool,
    totalCount: PropTypes.any,
    footExtra: PropTypes.any
};

export default TableList;
