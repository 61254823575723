import React from "react";
import PropTypes from "prop-types";
import cloneDeep from "lodash/cloneDeep";
import { ReactComponent as LoaderSVG } from "../../../assets/images/loader.svg";

const DEFAULT_COLOR = "#d9d9d9";

function MiniLoader({ classNames = { parent: "", loader: "" }, styles = { parent: {}, loader: {} }, color = DEFAULT_COLOR, show, size = 1.5 }) {
    const clonedStyles = cloneDeep(styles);
    if (!clonedStyles.parent) clonedStyles.parent = {};
    clonedStyles.parent.width = `${size}rem`;
    if (!show) {
        return <></>;
    }
    return (
        <div className={`tk-mini-loader ${classNames.parent || ""}`} style={clonedStyles.parent}>
            <LoaderSVG className={classNames.loader} style={clonedStyles.loader} color={color} />
        </div>
    );
}

MiniLoader.propTypes = {
    show: PropTypes.bool,
    color: PropTypes.string,
    size: PropTypes.number,
    classNames: PropTypes.shape({
        parent: PropTypes.string,
        loader: PropTypes.string
    }),
    styles: PropTypes.shape({
        parent: PropTypes.object,
        loader: PropTypes.object
    })
};

export default MiniLoader;
