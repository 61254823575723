import React from "react";
import useNavigate from "../../../common/hooks/useNavigate";
import { useLogoutMutation } from "../../common/api";
import Navigation from "../../../common/classes/Navigation";
import FormAuthTemplate from "../../../common/components/layout/FormAuthTemplate";

function DeactivatedAccount() {
    const navigate = useNavigate();
    const [logout] = useLogoutMutation();
    return (
        <div className="tk-deactivated-account">
            <div className="tk-deactivated-account__inner">
                <FormAuthTemplate
                    title="Account Deactivated"
                    footer={
                        <div className="tk-deactivated-account__footer flex gap-05 center">
                            Need help?
                            <strong>
                                <a href="mailto:support@time-keeper.app">Contact customer support</a>
                            </strong>
                        </div>
                    }
                    submitLabel="Goto Login"
                    onSubmit={async () => logout().then(() => navigate(Navigation.Routes.SIGNIN.path))}
                ></FormAuthTemplate>
            </div>
        </div>
    );
}

export default DeactivatedAccount;
