export const FIELDS = {
    TITLE: {
        name: "title",
        label: "Title",
        defaultValue: "",
        required: true
    },
    COUNTRY: {
        name: "country",
        label: "Country",
        defaultValue: "",
        required: true
    },
    NOTES: {
        name: "notes",
        label: "Notes",
        defaultValue: "",
        required: false
    },
    DESCRIPTION: {
        name: "description",
        label: "Description",
        defaultValue: "",
        required: true
    },
    PENALTY_MEASURES: {
        name: "penalty_measures",
        label: "Measures",
        defaultValue: [],
        required: true
    },
    DEDUCTION_TYPE: {
        name: "deduction_type",
        label: "Deduction Type",
        defaultValue: [],
        required: true
    }
};

export const MEASURE_FIELDS = {
    LABEL: {
        key: "label",
        label: "Label",
        defaultValue: "",
        required: true
    },
    LEVEL: {
        key: "level",
        label: "Level",
        defaultValue: "",
        required: true
    },
    OCCURENCE: {
        key: "occurence",
        label: "Occurence",
        defaultValue: "",
        required: true
    },
    VALUE: {
        key: "value",
        label: "Value",
        defaultValue: "",
        required: true
    }
};
