import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import isEqual from "lodash/isEqual";

function InputText({ onlyNumber, ...inputProps }) {
    const [value, setValue] = useState("");

    useEffect(() => {
        if (!isEqual(value, inputProps.value)) {
            setValue(inputProps.value || "");
        }
    }, [inputProps.value]);

    const handleChange = (e) => {
        let newValue = e.target.value;
        if (onlyNumber && isNaN(newValue)) {
            return;
        }
        setValue(newValue);
        typeof inputProps.onChange === "function" && inputProps.onChange(e);
    };

    return <input type="text" {...inputProps} onChange={handleChange} value={value} />;
}

InputText.propTypes = {
    onlyNumber: PropTypes.bool,
    onChange: PropTypes.func
};

export default InputText;
