import React from "react";
import PropTypes from "prop-types";

export const TAG_TYPE = {
    ON_SHIFT: 0x1,
    AVAILABLE: 0x2,
    APP_SUPERVISOR: 0x3,
    PENDING: 0x4,
    REJECTED: 0x5,
    VERIFIED: 0x6
};

function Tag({ children, className, style, type }) {
    let render = children;

    switch (type) {
        case TAG_TYPE.ON_SHIFT:
            className = "red";
            render = "ON SHIFT";
            break;
        case TAG_TYPE.AVAILABLE:
            className = "green";
            render = "AVAILABLE";
            break;
        case TAG_TYPE.APP_SUPERVISOR:
            className = "";
            render = "APP SUPERVISOR";
            break;
        case TAG_TYPE.PENDING:
            className = "yellow";
            render = "PENDING";
            break;
        case TAG_TYPE.REJECTED:
            className = "red";
            render = "REJECTED";
            break;
        case TAG_TYPE.VERIFIED:
            className = "green";
            render = render || "VERIFIED";
            break;
        default:
            break;
    }

    return (
        <div className={`tk-tag ${className}`.trim()} style={style}>
            {render}
        </div>
    );
}

Tag.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
    type: PropTypes.oneOf(Object.values(TAG_TYPE))
};

export default Tag;
