import { toFormFields } from "../../../common/utilities/helper";

export const BASE_CLASS = "tk-employees-work-type__modal-content-update";

export const FIELDS = {
    TITLE: {
        name: "title",
        label: "Title",
        default: "",
        required: true
    },
    LOCATION: {
        name: "location",
        label: "Location",
        default: "",
        required: true
    },
    COORDINATES: {
        name: "coordinates",
        label: "Coordinates",
        default: [],
        required: false
    },
    SUPERVISORS: {
        name: "supervisors",
        label: "Supervisors",
        default: [],
        required: false
    },
    MANAGERS: {
        name: "managers",
        label: "Managers",
        default: [],
        required: false
    },
    WORK_DETAIL: {
        name: "EmployeeWorkDetail",
        label: "Work Type",
        default: {}
    }
};

export const FORM_FIELDS = toFormFields(FIELDS, "default");
