import React, { useEffect, useState } from "react";
import SubscriptionPayment from "../../features/company/companySubscription/SubscriptionPayment";
import useNavigate from "../../common/hooks/useNavigate";
import Navigation from "../../common/classes/Navigation";
import { useCancelPaymentToken, useGetSubscriptionByPayment, useVerifyPaymentToken } from "../../features/company/companyPayment/hooks";
import { useParams } from "react-router-dom";
import Loader from "../../common/components/extra/Loader";
import { useAppDispatch, useAppSelector } from "../../common/hooks/reduxHooks";
import { selectSubscriptionToUpgradeId, setSubscriptionToUpgradeId } from "../../features/company/companyPayment/slice";
import { isTokenValidHex } from "../../features/company/companyPayment/helper";
import BlockNavigation from "../../common/components/extra/BlockNavigation";
import { selectUserSetting } from "../../features/common/slice";
import useLocalStorage from "../../common/hooks/useLocalStorage";
import { LOCAL_STORAGE_KEYS } from "../../features/company/companyPayment/const";

function SubscriptionPaymentPage() {
    const [subscription, setSubscription] = useState(null);
    const [timerExpired, setTimerExpired] = useState(false);
    const [proceeding, setProceeding] = useState(false);

    const [payment, setStorage] = useLocalStorage(LOCAL_STORAGE_KEYS.PAYMENT.key);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const params = useParams();

    const [verify, { result, verfying, setVerifying }] = useVerifyPaymentToken(params.token);
    const [cancelToken] = useCancelPaymentToken(params.token);

    const subscriptionId = useAppSelector(selectSubscriptionToUpgradeId);
    const setting = useAppSelector(selectUserSetting);

    const isInValidResult = (payload) => !!(!payload || payload?.error || !payload.PTL);

    const [, gettingSubscription] = useGetSubscriptionByPayment(subscriptionId, setSubscription);

    useEffect(() => {
        const handleMount = async () => {
            // this means that a user forced his way to enter a random token in the route
            if (!subscriptionId) {
                const result = await verify();
                if (isInValidResult(result)) {
                    navigate(Navigation.Routes.DASHBOARD.path, null, true); // dont include in the location
                }
            } else {
                if (params.token && !isTokenValidHex(params.token)) {
                    navigate(Navigation.Routes.DASHBOARD.path, null, true); // dont include in the location
                }
                setVerifying(false);
            }
            if (!payment.key || !payment.expires) {
                params.token && cancelToken();
                setStorage(LOCAL_STORAGE_KEYS.PAYMENT.default);
                dispatch(setSubscriptionToUpgradeId(null));
                navigate(Navigation.Routes.DASHBOARD.path, null, true); // dont include in the location
            }
        };
        handleMount();
    }, []);

    const handleSuccess = () => {
        setProceeding(true);
        dispatch(setSubscriptionToUpgradeId(null));
    };

    if (verfying || !subscriptionId || gettingSubscription) {
        return <Loader />;
    }

    return (
        <>
            <SubscriptionPayment
                onBack={() => navigate(Navigation.Routes.SUBSCRIPTION_UPGRADE.path, null, true)}
                onTimerEnd={() => cancelToken().finally(() => setTimerExpired(true))}
                subscriptionToUpgradeId={subscriptionId}
                subscription={subscription}
                expires={payment && payment.key == params.token && payment.expires}
                timezone={setting.timezone}
                onSuccess={handleSuccess}
            />
            {(!timerExpired || proceeding) && (
                <BlockNavigation
                    onLeave={() => {
                        !isInValidResult(result) && cancelToken();
                        dispatch(setSubscriptionToUpgradeId(null));
                        setStorage(LOCAL_STORAGE_KEYS.PAYMENT.default);
                    }}
                />
            )}
        </>
    );
}

export default SubscriptionPaymentPage;
