import React from "react";
import PageWrapperLayout from "../../../common/components/layout/PageWrapperLayout";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import CreateTableHeaders from "./CreateTableHeaders";
import { usePaginateCompanySubscriptions } from "./hooks";
import { selectTableConfig, selectView } from "./slice";

function CompanySubscriptions() {
    const tableConfig = useAppSelector(selectTableConfig);
    const view = useAppSelector(selectView);

    const [data, fetching, refetch] = usePaginateCompanySubscriptions();

    return (
        <>
            <PageWrapperLayout
                config={{
                    data,
                    refetch,
                    tableConfig,
                    view,
                    fetching,
                    CreateTableHeaders
                }}
            />
        </>
    );
}

export default CompanySubscriptions;
