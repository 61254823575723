import React from "react";
import PropTypes from "prop-types";
import Modal from "../../../common/components/extra/Modal";
import SubscriptionPlan from "./SubscriptionPlan";

function ModalSubscriptionPlan({ open, onChange, onBack, user, ...rest }) {
    return (
        <Modal open={open} onChange={onChange} styles={{ content: { width: "auto", minWidth: "50vw", maxWidth: "90vw" }, body: { marginTop: 0 } }}>
            <div className="tk-subscription-plan__modal" style={{ height: "100%" }}>
                <SubscriptionPlan
                    onSubscribe={() => typeof onBack === "function" && onBack()}
                    companySubscription={user.CompanySubscription}
                    {...rest}
                />
            </div>
        </Modal>
    );
}

ModalSubscriptionPlan.propTypes = {
    user: PropTypes.object,
    open: PropTypes.bool,
    data: PropTypes.object,
    onChange: PropTypes.func,
    onBack: PropTypes.func
};

export default ModalSubscriptionPlan;
