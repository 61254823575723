import React from "react";
import { isValidHex } from "../../../common/utilities/helper";

export const isTokenValidHex = (str = "") => {
    return isValidHex(str, 160);
};

export const formatBankTransferDetails = (message) => {
    // Split the message into separate lines
    const lines = message.split(";");

    // Map through each line and format it as a paragraph element
    const formattedDetails = lines.map((line, index) => {
        // Trim the line to remove leading and trailing spaces
        const trimmedLine = line.trim();
        // Skip empty lines
        if (trimmedLine === "") {
            return null;
        }
        return (
            <p className="w100" key={index} style={{ margin: ".5rem", marginLeft: ".3rem" }}>
                {trimmedLine}
            </p>
        );
    });
    return formattedDetails;
};
