import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import FilterListIcon from "@mui/icons-material/FilterList";
import FilterListOffIcon from "@mui/icons-material/FilterListOff";
import Button from "../Button";
import { selectViewport } from "../../../../features/common/slice";
import { useAppSelector } from "../../../hooks/reduxHooks";

const STYLE = { width: "1.2rem" };

function ButtonFilter({ show, onClose, onChange }) {
    const [open, setOpen] = useState(false);
    const viewport = useAppSelector(selectViewport);

    useEffect(() => {
        if (open != show) {
            setOpen(show);
        }
    }, [show]);

    const handleClick = () => {
        setOpen(!open);
        if (!open) {
            typeof onClose == "function" && onClose();
        }
        typeof onChange == "function" && onChange(!open);
    };

    return (
        <Button
            className="secondary"
            afterExtra={open ? <FilterListOffIcon style={STYLE} /> : <FilterListIcon style={STYLE} />}
            onClick={handleClick}
            options={{ style: { height: "2.2rem" } }}
            small
        >
            {(!viewport.isMobile || !open) && <span style={{ whiteSpace: "nowrap", lineHeight: ".5" }}>{open ? "Hide" : "Show"}</span>}
        </Button>
    );
}

export default ButtonFilter;

ButtonFilter.propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func,
    onChange: PropTypes.func
};
