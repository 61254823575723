import { createUniqueIDFromStr } from "../../../utilities/helper";

export const transformSteps = (steps, optionals, autoSkips) => {
    return steps.map((step, idx) => ({
        id: createUniqueIDFromStr(step),
        stepNumber: idx + 1,
        name: step,
        error: false,
        loading: false,
        optional: optionals.some((opt) => opt === idx + 1),
        skip: false,
        autoSkip: autoSkips.some((opt) => opt === idx + 1)
    }));
};
