import React from "react";
import PropTypes from "prop-types";
import isEqual from "lodash/isEqual";
import { TOAST_TYPE, createConfirmAlert, createToast, sanitizeWords } from "../../../common/utilities/helper";
import Modal from "../../../common/components/extra/Modal";
import Input, { INPUT_TYPE } from "../../../common/components/extra/form/Input";
import { useUpsertPenalties } from "./hooks";
import Group from "../../../common/components/extra/Group";
import Select from "../../../common/components/extra/select/Select";
import useFetchCountries from "../../../common/hooks/useFetchCountries";
import { PENALTY_DEDUCTION_TYPE } from "../../../common/utilities/const";
import Tag from "../../../common/components/extra/Tag";
import Measures from "./Measures";
import { FIELDS } from "./const";

const { TITLE, COUNTRY, NOTES, DESCRIPTION, DEDUCTION_TYPE, PENALTY_MEASURES } = FIELDS;
const DEDUC_OPT = Object.values(PENALTY_DEDUCTION_TYPE).map((deduction) => ({
    value: deduction,
    label: (
        <div className="flex" style={{ justifyContent: "flex-end", alignItems: "center" }}>
            <Tag style={{ marginLeft: "auto" }}>{sanitizeWords(deduction)}</Tag>
        </div>
    )
}));

function UpdateModal({ open, onClose, onBack, onFinish, data = {} }) {
    const isCreate = !data?.id;

    const [form, setForm, isLoading, onSave] = useUpsertPenalties({
        id: data?.id,
        initial: data || {}
    });

    const fetchedCountries = useFetchCountries({ rtl: true, mobile: true });
    const countries = fetchedCountries.nationalities;
    const country = countries.find((fc) => fc.value === form[COUNTRY.name]);
    const deductionType = DEDUC_OPT.find((deduction) => deduction.value === form[DEDUCTION_TYPE.name]);

    const handleSave = async () => {
        if (!form[PENALTY_MEASURES.name].length) {
            return createToast("At least one measure is required!", TOAST_TYPE.ERROR);
        }
        createConfirmAlert({
            title: !isCreate ? "Update Penalty" : "Create Penalty",
            content: `Are you sure you want to ${!isCreate ? "update" : "create"} this penalty? This cannot be undone.`,
            onConfirm: async (close) => {
                close();
                const result = await onSave();
                if (!result?.error) {
                    await onFinish(result, isCreate);
                }
            }
        });
    };

    const handleChange = (e) => setForm({ ...form, [e.target.name]: e.target.value });

    return (
        <Modal
            open={open}
            onClose={onClose}
            onBack={(!isCreate && onBack) || null}
            onSave={handleSave}
            disableSave={isEqual(form, data)}
            isLoading={isLoading}
            isForm
        >
            <div className="update-modal">
                <div className="update-modal__inner flex column gap-1">
                    <Group title="Penalty Details">
                        <Input
                            type={INPUT_TYPE.TEXT}
                            label={TITLE.label}
                            name={TITLE.name}
                            value={form[TITLE.name]}
                            onChange={handleChange}
                            required={TITLE.required}
                        />
                        <Select
                            name={COUNTRY.name}
                            label={COUNTRY.label}
                            value={country}
                            options={countries}
                            style={{ flex: "30%" }}
                            onChange={(d) => handleChange({ target: { name: COUNTRY.name, value: d.value } })}
                            isClearable={false}
                            required
                            isOutlined
                            disabledOutline
                        />
                        <Select
                            name={DEDUCTION_TYPE.name}
                            label={DEDUCTION_TYPE.label}
                            value={deductionType}
                            options={DEDUC_OPT}
                            style={{ flex: "30%" }}
                            onChange={(d) => handleChange({ target: { name: DEDUCTION_TYPE.name, value: d.value } })}
                            isClearable={false}
                            required
                            isOutlined
                            disabledOutline
                        />
                        <Input
                            type={INPUT_TYPE.TEXTAREA}
                            label={NOTES.label}
                            name={NOTES.name}
                            value={form[NOTES.name]}
                            onChange={handleChange}
                            parentStyle={{ marginTop: "1rem" }}
                            required={NOTES.required}
                        />
                        <Input
                            type={INPUT_TYPE.TEXTAREA}
                            label={DESCRIPTION.label}
                            name={DESCRIPTION.name}
                            value={form[DESCRIPTION.name]}
                            onChange={handleChange}
                            parentStyle={{ marginTop: "1rem" }}
                            required={DESCRIPTION.required}
                        />
                    </Group>
                    <Group title="Manage Measures">
                        <Measures
                            data={form[PENALTY_MEASURES.name]}
                            isLoading={isLoading}
                            onChange={(newMeasures) => handleChange({ target: { name: PENALTY_MEASURES.name, value: newMeasures } })}
                        />
                    </Group>
                </div>
            </div>
        </Modal>
    );
}

UpdateModal.propTypes = {
    open: PropTypes.bool,
    data: PropTypes.object,
    onClose: PropTypes.func,
    onFinish: PropTypes.func,
    onBack: PropTypes.func
};

export default UpdateModal;
