import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import PropTypes from "prop-types";
import isEqual from "lodash/isEqual";
import Select from "../select/Select";

const MONTHS = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

function populateYears() {
    const currentYear = moment().year();
    // Create an array to store the years
    const yearsArray = [];
    for (let i = 0; i <= 100; i++) {
        yearsArray.push(currentYear + i);
    }
    return yearsArray;
}

const DEF_MONTHS_OPT = MONTHS.map((month) => ({ label: month, value: month }));
const YEARS_OPT = populateYears().map((year) => ({ label: year, value: year }));
const MONTH_FORMAT = "MMMM";
const YEAR_FORMAT = "YYYY";

const currentYear = moment().year();
const currentMonth = moment().format(MONTH_FORMAT);

function InputMonthYear({ value, uptoCurrent, offset, ...inputProps }) {
    const [yearsOptions] = useState(YEARS_OPT);
    const [monthOptions, setMonthOptions] = useState(DEF_MONTHS_OPT);

    const [month, setMonth] = useState("");
    const [year, setYear] = useState("");
    const [date, setDate] = useState(value || "");

    const selectedMonth = month && monthOptions.find((m) => m.value.toString().toLowerCase().trim() == month.toLowerCase().trim());
    const selectedYear = year && yearsOptions.find((y) => y.value.toString().toLowerCase().trim() == year.toLowerCase().trim());

    useEffect(() => {
        if (!isEqual(date, value)) {
            const toMoment = moment(new Date(value));
            const formattedMonth = toMoment.clone().format(MONTH_FORMAT);
            const formattedYear = toMoment.clone().format(YEAR_FORMAT);
            const isInvalid = formattedMonth.includes("Invalid");
            setMonth(isInvalid ? "" : formattedMonth);
            setYear(isInvalid ? "" : formattedYear);
            setDate(value || "");
            if (!isInvalid && toMoment.year() == currentYear && uptoCurrent) {
                const selectedMonthIndex = monthOptions.findIndex((m) => m.value.toLowerCase() === currentMonth.toLowerCase());
                const filterPastMonths = monthOptions.filter((m, idx) => idx >= selectedMonthIndex + (offset || 0));
                setMonthOptions(filterPastMonths);
            }
        }
    }, [value]);

    useEffect(() => {
        if (month && year) {
            // Set monthyear from month and year values with the format mmyy
            const toMoment = inputProps.value ? moment(new Date(inputProps.value)) : moment();
            const formattedMonth = toMoment.month(month).format("MM"); // Get the month number in "MM" format
            const formattedYear = toMoment.year(year).format("YY"); // Get the last two digits of the year in "YY" format
            const newDate = moment().startOf("month").set({ month: toMoment.month(), year: toMoment.year() }).format();

            typeof inputProps.onChange === "function" &&
                inputProps.onChange({
                    target: {
                        name: inputProps.name,
                        value: newDate,
                        month: formattedMonth,
                        year: formattedYear,
                        monthYear: formattedMonth + formattedYear
                    }
                });
        }
    }, [month, year]);

    const handleYearChange = (selected) => {
        const newSelected = selected.value;
        setYear(newSelected.toString());
        if (uptoCurrent) {
            if (newSelected == currentYear) {
                const selectedMonthIndex = monthOptions.findIndex((m) => m.value.toLowerCase() === currentMonth.toLowerCase());
                const filterPastMonths = monthOptions.filter((m, idx) => idx >= selectedMonthIndex + (offset || 0));
                setMonthOptions(filterPastMonths);
                setMonth("");
                setDate("");
            } else {
                setMonthOptions(DEF_MONTHS_OPT);
            }
        }
    };

    return (
        <div className="flex gap-05" style={{ marginLeft: "auto", position: "relative" }}>
            <Select
                placeholder="Month"
                options={monthOptions}
                menuPortalTarget={document.body}
                isClearable={false}
                onChange={(selected) => setMonth(selected.value)}
                value={selectedMonth}
                isDisabled={inputProps.disabled}
                style={{ width: "6.5rem" }}
                isOutlined
                disabledOutline
                noborder
            />
            <Select
                placeholder="Year"
                options={yearsOptions}
                menuPortalTarget={document.body}
                isClearable={false}
                onChange={handleYearChange}
                value={selectedYear}
                isDisabled={inputProps.disabled}
                style={{ width: "5.5rem" }}
                isOutlined
                disabledOutline
                noborder
            />
            <input
                style={{ opacity: 0, position: "absolute", zIndex: -1, right: "0%" }}
                name="month"
                required={inputProps.required}
                value={date}
                onChange={(e) => setDate(e.target.value)}
                disabled={inputProps.disabled}
            />
        </div>
    );
}

InputMonthYear.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.any,
    uptoCurrent: PropTypes.bool,
    offset: PropTypes.number
};

export default InputMonthYear;
