import ROUTES, { COMMON_ROUTES, ADMIN_ROUTES, COMPANY_ROUTES, SUBSCRIPTION_PAY_ROUTES } from "../../pages/registry";
import { ROLES } from "../utilities/const";

class Navigation {
    #superAdminRoutes = [];
    #companyAdminRoutes = [];
    #commonRoutes = [];

    constructor() {
        this.#commonRoutes = this.addRolesProperty([ROLES.COMPANY_ADMIN, ROLES.SUPER_ADMIN], COMMON_ROUTES);
        this.#superAdminRoutes = this.addRolesProperty([ROLES.SUPER_ADMIN], ADMIN_ROUTES);
        this.#companyAdminRoutes = this.addRolesProperty([ROLES.COMPANY_ADMIN], COMPANY_ROUTES);
    }

    get Routes() {
        return ROUTES;
    }

    get AvailableRoutes() {
        const temp = [...this.#commonRoutes, ...this.#superAdminRoutes, ...this.#companyAdminRoutes]
            .filter((route) => !route.isDisabled)
            .map((route, idx) => ({
                ...route,
                id: route.id || `xxx${idx}`
            }));
        return temp;
    }

    get ProtectedRoutes() {
        return this.AvailableRoutes.filter((route) => route.isProtected);
    }

    get AdminUnauthRoutes() {
        return Object.values(ADMIN_ROUTES).filter((route) => !route.isProtected && !route.isDisabled);
    }

    get CompanyUnauthRoutes() {
        return Object.values(COMPANY_ROUTES).filter((route) => !route.isProtected && !route.isDisabled);
    }

    get SidebarRoutes() {
        return this.AvailableRoutes.filter((route) => route.isMenu).map((route) => {
            if (route.isGroup && route.subpaths) route.subpaths = Object.values(route.subpaths);
            return route;
        });
    }

    get SidebarAdminRoutes() {
        return this.SidebarRoutes.filter((route) => route.roles.includes(ROLES.SUPER_ADMIN));
    }

    get SidebarCompanyAdminRoutes() {
        return this.SidebarRoutes.filter((route) => route.roles.includes(ROLES.COMPANY_ADMIN));
    }

    get CompanySubscriptionUpgradeRoutes() {
        return SUBSCRIPTION_PAY_ROUTES;
    }

    addRolesProperty(roles, routes) {
        const toArray = Object.values(routes);
        return toArray.map((route) => ({ ...route, roles }));
    }
}

export default new Navigation();
