import React from "react";
import PropTypes from "prop-types";

function CardPage({ className, title, headExtra, children, contentStyle = {}, parentStyle = {}, danger }) {
    return (
        <div className={`tk-card-page ${(className && className) || ""} ${danger ? "danger" : ""}`.trim()} style={parentStyle}>
            <div className="tk-card-page__inner">
                <div className="tk-card-page__header">
                    <span>{title}</span>
                    {headExtra}
                </div>
                <div className="tk-card-page__content" style={contentStyle}>
                    {children}
                </div>
            </div>
        </div>
    );
}

CardPage.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    headExtra: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
    contentStyle: PropTypes.object,
    parentStyle: PropTypes.object,
    danger: PropTypes.bool,
    className: PropTypes.string
};

export default CardPage;
