import { apiSlice } from "../../../app/apiSlice";

const BASE_PATH = "/api/company/salary";

const END_POINTS = {
    loadPreviewEmployeeSalary: { method: "post", path: "load-preview" },
    generateEmployeeSalary: { method: "post", path: "generate" },
    confirmEmployeeSalary: { method: "post", path: "confirm" },
    loadLazyEmployeeSalary: { method: "post", path: "load-data-lazy" },
    downloadFileEmployeeSalary: { method: "post", path: "download-file" }
};

export const employeeSalaryAPI = apiSlice.injectEndpoints({
    reducerPath: "employeeSalaryAPI",
    endpoints: (builder) => {
        const temp = {};
        Object.entries(END_POINTS).forEach(([key, config]) => {
            temp[key] = builder.mutation({
                query: (queryParams = {}) => {
                    const path = `${BASE_PATH}/${config.path}`;
                    const conf = {
                        url: path,
                        method: config.method,
                        credentials: "include"
                    };
                    if (typeof queryParams.extraPath === "string" || typeof queryParams.extraPath === "number")
                        conf.url = `${path}/${queryParams.extraPath}`;
                    if (Array.isArray(queryParams.extraPaths)) conf.url = `${path}/${queryParams.extraPaths.join("/")}`;
                    if (queryParams.params) conf.params = queryParams.params;
                    if (queryParams.body) conf.body = queryParams.body;
                    if (queryParams.isBlob)
                        conf.responseHandler = async (response) => {
                            if (response.status == 500) {
                                return await response.json();
                            }
                            return {
                                headers: response.headers,
                                blob: await response.blob()
                            };
                        };
                    return conf;
                }
            });
        });
        return temp;
    }
});

export const {
    useLoadPreviewEmployeeSalaryMutation,
    useGenerateEmployeeSalaryMutation,
    useConfirmEmployeeSalaryMutation,
    useLoadLazyEmployeeSalaryMutation,
    useDownloadFileEmployeeSalaryMutation
} = employeeSalaryAPI;

export default employeeSalaryAPI.reducer;
