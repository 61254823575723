import React from "react";
import Loader from "../../../common/components/extra/Loader";

function VerifyingPayment() {
    return (
        <div className="loading">
            <Loader relative />
            <span className="fade">Verifying Payment</span>
        </div>
    );
}

export default VerifyingPayment;
