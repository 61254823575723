export const BASE_CLASS = "tk-employee-salary";

export const FIELD = {
    EMPLOYEE: "employee_id",
    COMPANY_PENALTY: "company_penalty_id",
    DEDUCTION_TYPE: "deduction_type",
    OCCURENCE_DATE: "occurence_date",
    EFFECTIVE_DATE: "effective_date",
    REASON: "reason",
    STATUS: "status",
    CONFIRMED_DATE: "confirmed_date"
};

export const FILTER_TYPE = {
    NATIONALITY: 0x1,
    DEPARTMENT: 0x2,
    DESIGNATION: 0x3,
    PAYMENT_MODE: 0x4
};

export const PAYMENT_MODE = {
    BANK_TRANSFER: "BANK",
    CASH: "CASH",
    PAYCARD: "PAYCARD",
    CHECK: "CHECK"
};

export const DOWNLOAD_TYPE = {
    SALARY: 0x1,
    WPS: 0x2
};

export const CLIENT_ERROR_CODE = {
    SALARY_GENERATE_NOT_ALLOWED: {
        code: 410,
        message: "Records are not yet ready to generate for this month."
    },
    SALARY_REPORT_NOT_FOUND: {
        code: 411,
        title: "Salary report not yet generated for the selected period."
    },
    SALARY_NO_RECORDS_AVAILABLE: {
        code: 412,
        title: "No available work history records to generate for salary."
    }
};

export const COMPANY_SALARY_FILETYPE = {
    SALARY_COPY: "SALARY_COPY",
    WPS_FILE: "WPS_FILE"
};

export const SAL_CSV_HEADERS = {
    SN: { id: "sn", title: "SN" },
    EMPLOYEE_NAME: { id: "empName", title: "Employee Name" },
    RESIDENCE_ID: { id: "empResidenceId", title: "Employee Residence ID" },
    VISA_ID: { id: "empVisaId", title: "Employee Visa ID" },
    NATIONALITY: { id: "empNationality", title: "Nationality" },
    DEPARTMENT: { id: "empDepartment", title: "Department" },
    DESIGNATION: { id: "empDesignation", title: "Designation" },
    BASIC_SALARY: { id: "empBasicSalary", title: "Basic Salary" },
    HOUSING_ALLOWANCE: { id: "empHousingAllowance", title: "Housing Allowance" },
    COMMUNICATION_ALLOWANCE: { id: "empCommunicationAllowance", title: "Communication Allowance" },
    FOOD_ALLOWANCE: { id: "empFoodAllowance", title: "Food Allowance" },
    TRANSPO_ALLOWANCE: { id: "empTransportationAllowance", title: "Transportation Allowance" },
    EDUC_ALLOWANCE: { id: "empEducationAllowance", title: "Education Allowance" },
    MEDICAL_ALLOWANCE: { id: "empMedicalAllowance", title: "Medical Allowance" },
    OTHER_ALLOWANCE: { id: "empOtherAllowance", title: "Other Allowance" },
    TOTAL_ALLOWANCE: { id: "empTotalAllowance", title: "Total Allowances" },
    GROSS_SALARY: { id: "empTotalMonthlyGrossSalary", title: "Gross Salary" },
    DAYS_WORKED: { id: "empDaysWorked", title: "Days Worked" },
    SALARY_PER_DAY: { id: "empSalaryPerDay", title: "Salary Per Day" },
    BONUSES: { id: "empTotalOtherIncome", title: "Bonuses/Incentives" },
    DEDUCTIONS: { id: "empTotalDeduction", title: "Penalties/Deductions/Advance Payments" },
    OT_HOURS: { id: "empOTHours", title: "OT Hours" },
    OT_AMOUNT: { id: "empOTAmount", title: "OT Amount" },
    HOT_HOURS: { id: "empHOTHours", title: "HOT Hours" },
    HOT_AMOUNT: { id: "empHOTAmount", title: "HOT Amount" },
    OVERALL_OT_HOURS: { id: "empOverallOTHours", title: "OT+HOT Hours" },
    OVERALL_OT_AMOUNT: { id: "empOverallOTAmount", title: "OT+HOT Amount" },
    NET_SALARY: { id: "empTotalNetSalary", title: "Net Salary" },
    SALARY_FREQUENCY: { id: "empSalaryFrequency", title: "Salary Frequency" },
    PAYMENT_MODE: { id: "empPaymentMode", title: "Payment Mode" },
    BANK_NUMBER: { id: "empBankAccountNumber", title: "Bank Account Number" },
    BANK_SHORT_NAME: { id: "empBankShortName", title: "Bank Short Name" },
    BANK_IBAN: { id: "empBankIban", title: "Bank IBAN" },
    OTHER_INCOME_NOTES: { id: "empOtherIncomeNotes", title: "Other Income Notes" },
    DEDUCTION_NOTES: { id: "empPenaltiesAndDeductionNotes", title: "Penalties/Deduction Notes" },
    REMARKS: { id: "empRemarks", title: "Remarks" }
};
export const COMPANY_SALARY_STATUS = {
    PENDING: "PENDING",
    CONFIRMED: "CONFIRMED"
};

export const INDEXES = {
    SYS_KEYWORDS: { id: "sysKeywords", title: "sys-keywords" },
    SYS_INDEX1: { id: "sysIdx1", title: "sys-idx1" }, // serialNo + empID + fullname
    SYS_INDEX2: { id: "sysIdx2", title: "sys-idx2" }, // serialNo + empID + basicAmount
    SYS_INDEX3: { id: "sysIdx3", title: "sys-idx3" }, // serialNo + empID + grossSalary
    SYS_INDEX4: { id: "sysIdx4", title: "sys-idx4" }, // serialNo + empID + netSalary
    SYS_INDEX5: { id: "sysIdx5", title: "sys-idx5" }, // serialNo + empID + daysWorked
    SYS_INDEX6: { id: "sysIdx6", title: "sys-idx6" } // serialNo + empID + paymentMode
};

export const WPS_DOWNLOAD_FIELDS = {
    // user input
    PAYER_EID: "payerEID",
    PAYER_QID: "payerQID",
    PAYER_BANK_SHORT_NAME: "payerBankShortName",
    PAYER_IBAN: "payerIban",
    // auto generated
    EMPLOYER_EID: "employerEID",
    FILE_CREATION_DATE: "fileCreationDate",
    FILE_CREATION_TIME: "fileCreationTime",
    SALARY_PERIOD: "salaryPeriod",
    TOTAL_SALARIES: "totalSalaries",
    TOTAL_RECORDS: "totalRecords"
};
