import { FORM_FIELD_TYPE } from "../../../../common/utilities/const";

import MobileAddress from "./MobileAddress";
import MobileCheckbox from "./MobileCheckbox";
import MobileDate from "./MobileDate";
import MobileDateTime from "./MobileDateTime";
import MobileDropdown from "./MobileDropdown";
import MobileTime from "./MobileTime";
import MobileEmailInput from "./MobileEmailInput";
import MobileFileUpload from "./MobileFileUpload";
import MobileImageUpload from "./MobileImageUpload";
import MobileLabel from "./MobileLabel";
import MobileNumberInput from "./MobileNumberInput";
import MobileParagraph from "./MobileParagraph";
import MobilePhone from "./MobilePhone";
import MobileRadio from "./MobileRadio";
import MobileTextArea from "./MobileTextArea";
import MobileTextInput from "./MobileTextInput";
import MobileToggle from "./MobileToggle";

const {
    PARAGRAPH,
    LABEL,
    TEXT_INPUT,
    TEXT_AREA,
    EMAIL,
    NUMBER_INPUT,
    DROPDOWN,
    RADIO,
    CHECKBOX,
    DATE,
    TIME,
    DATE_TIME,
    FILE_UPLOAD,
    IMAGE_UPLOAD,
    PHONE_NUMBER,
    ADDRESS,
    TOGGLE
} = FORM_FIELD_TYPE;

export default {
    [PARAGRAPH]: MobileParagraph,
    [LABEL]: MobileLabel,
    [TEXT_INPUT]: MobileTextInput,
    [TEXT_AREA]: MobileTextArea,
    [EMAIL]: MobileEmailInput,
    [NUMBER_INPUT]: MobileNumberInput,
    [DROPDOWN]: MobileDropdown,
    [RADIO]: MobileRadio,
    [CHECKBOX]: MobileCheckbox,
    [DATE]: MobileDate,
    [TIME]: MobileTime,
    [DATE_TIME]: MobileDateTime,
    [FILE_UPLOAD]: MobileFileUpload,
    [IMAGE_UPLOAD]: MobileImageUpload,
    [PHONE_NUMBER]: MobilePhone,
    [ADDRESS]: MobileAddress,
    [TOGGLE]: MobileToggle
};
