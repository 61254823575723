import React from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

function MobileDropdown() {
    return (
        <div className="mobile-input mobile-input--dropdown input-size">
            <div className="input-box flex center"></div>
            <div className="dropdown-indicator">
                <ArrowDropDownIcon className="primary-color" style={{ width: "3rem", height: "auto" }} />
            </div>
        </div>
    );
}

export default MobileDropdown;
