import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../common/hooks/reduxHooks";
import { selectLoading, setTableConfig, selectTableConfig } from "../../features/company/companySites/slice";
import WorkSite from "../../features/company/companySites/WorkSite";
import PageLayout from "../../common/components/layout/PageLayout";

function CompanySitesPage() {
    const [openAddModal, setOpenAddModal] = useState(false);

    const dispatch = useAppDispatch();
    const loading = useAppSelector(selectLoading);
    const config = useAppSelector(selectTableConfig);

    const handleSearch = (value) => dispatch(setTableConfig({ search: (value && value.toLowerCase().trim()) || "" }));
    const handleAdd = () => setOpenAddModal(true);

    return (
        <PageLayout
            className="tk-site"
            title="Manage Work Sites"
            isLoading={loading}
            search={{ onSearch: handleSearch, value: config.search }}
            onAdd={handleAdd}
        >
            <WorkSite openAddModal={openAddModal} onAddModalClose={() => setOpenAddModal(false)} />
        </PageLayout>
    );
}

export default CompanySitesPage;
