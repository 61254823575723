import React from "react";
import moment from "moment-timezone";
import { TIME_TYPE } from "./const";
import { changeToBaseDate, toTimeWithTimeZone } from "../../../common/utilities/helper";
import { WORK_HISTORY_SHIFT_STATUS } from "../../../common/utilities/const";
import Tag from "../../../common/components/extra/Tag";

const {
    LATE,
    ON_TIME,
    LATE_SECOND_SHIFT,
    ON_TIME_SECOND_SHIFT,
    UNDERTIME,
    OVERTIME,
    FIRST_SHIFT_DONE,
    SECOND_SHIFT_DONE,
    OVERTIME_DONE,
    OVER_BREAK
    // HALF_DAY
} = WORK_HISTORY_SHIFT_STATUS;

/**
 * @param {Form} form
 * @param {String} timezone
 * @returns {Object}
 */
export const getTimeRangeValues = (form = {}, timezone) => {
    const shiftOne = {
        range: {
            start: (form.timeInOne && moment.tz(form.timeInOne, timezone)) || null,
            end: (form.timeOutOne && moment.tz(form.timeOutOne, timezone)) || null
        },
        constraint: {}
    };

    const shiftTwo = {
        range: {
            start: (form.timeInTwo && moment.tz(form.timeInTwo, timezone)) || null,
            end: (form.timeOutTwo && moment.tz(form.timeOutTwo, timezone)) || null
        },
        constraint: {}
    };

    const breakTimeOne = {
        range: {
            start: (form.breakStartOne && moment.tz(form.breakStartOne, timezone)) || null,
            end: (form.breakEndOne && moment.tz(form.breakEndOne, timezone)) || null
        },
        constraint: {
            start: {
                min: shiftOne.range.start || null,
                max: shiftOne.range.end || null
            },
            end: {
                min: (form.breakStartOne && moment.tz(form.breakStartOne, timezone)) || null,
                max: shiftOne.range.end || null
            }
        }
    };

    const breakTimeTwo = {
        range: {
            start: (form.breakStartTwo && moment.tz(form.breakStartTwo, timezone)) || null,
            end: (form.breakEndTwo && moment.tz(form.breakEndTwo, timezone)) || null
        },
        constraint: {
            start: {
                min: shiftTwo.range.start || null,
                max: shiftTwo.range.end || null
            },
            end: {
                min: (form.breakStartTwo && moment.tz(form.breakStartTwo, timezone)) || null,
                max: shiftTwo.range.end || null
            }
        }
    };

    const overtime = {
        range: {
            start: (form.overTimeStart && moment.tz(form.overTimeStart, timezone)) || null,
            end: (form.overTimeEnd && moment.tz(form.overTimeEnd, timezone)) || null
        },
        constraint: {
            start: {
                min: shiftTwo.range.end || shiftOne.range.end || null,
                max: null
            }
        }
    };

    return {
        [TIME_TYPE.SHIFT_ONE]: shiftOne,
        [TIME_TYPE.SHIFT_TWO]: shiftTwo,
        [TIME_TYPE.BREAK_ONE]: breakTimeOne,
        [TIME_TYPE.BREAK_TWO]: breakTimeTwo,
        [TIME_TYPE.OVERTIME]: overtime
    };
};

export const chekIncStatus = (start, end, tz, identifier) => {
    const currentDay = toTimeWithTimeZone(moment(), tz);
    return !end && start && Math.abs(toTimeWithTimeZone(start, tz).diff(currentDay, "day")) >= identifier;
};

/**
 * @param {Object} param
 * @param {Shift} param.shiftOne
 * @param {Shift} param.shiftTwo
 * @param {Overtime} param.overtime
 * @param {WorkShift} workShift
 * @param {GracePeriod} gracePeriod
 * @param {String} timezone
 * @returns {Array<string>}
 */
export const createShiftStatus = ({ shiftOne, shiftTwo, overtime }, workShift, gracePeriod, timezone) => {
    let statuses = [];

    const determineShiftStatuses = (base, newShift, { complete, onTime, late, undertime, overbreak } = statuses || {}) => {
        const temp = [];

        const maxBreakDuration = workShift.max_break_duration; // in hours
        const hasBreak = !!(base.breakTime || maxBreakDuration);

        const shiftStart = toTimeWithTimeZone(base.startTime, timezone);
        const shiftEnd = toTimeWithTimeZone(base.endTime, timezone);
        const shiftBreakTime = toTimeWithTimeZone(base.breakTime, timezone);
        const shiftBreakEnd = toTimeWithTimeZone(base.breakEnd, timezone);

        const isNextDayNewShift =
            newShift.timeIn &&
            newShift.timeOut &&
            toTimeWithTimeZone(newShift.timeIn, timezone).format("MMM DD YYYY") !==
                toTimeWithTimeZone(newShift.timeOut, timezone).format("MMM DD YYYY");

        const startTimeOne = changeToBaseDate(shiftStart, newShift.timeIn, timezone);
        const endTimeOne = changeToBaseDate(shiftStart, newShift.timeOut, timezone);

        if (isNextDayNewShift && endTimeOne) {
            endTimeOne.add("1", "day");
        }

        const breakStartTime = changeToBaseDate(shiftStart, newShift.breakStart, timezone);
        const breakEndTime = changeToBaseDate(shiftStart, newShift.breakEnd, timezone);

        // check time in
        if (newShift.timeIn && base.startTime) {
            const diffMinutes = moment(shiftStart).diff(startTimeOne, gracePeriod.lateGP.format, true);
            if (Math.abs(diffMinutes) > gracePeriod.lateGP.value) {
                if (startTimeOne.isBefore(shiftStart)) {
                    temp.push(onTime);
                } else {
                    temp.push(late);
                }
            } else {
                temp.push(onTime);
            }
        }

        // check if time in complete
        if (newShift.timeIn && newShift.timeOut) {
            temp.push(complete);
        }

        // check if early out / undertime
        if (newShift.timeOut && base.endTime) {
            const diffMinutes = endTimeOne.diff(shiftEnd, gracePeriod.earlyTimeOutGP.format, true);
            if (endTimeOne.isBefore(shiftEnd)) {
                if (Math.abs(diffMinutes) > gracePeriod.earlyTimeOutGP.value) {
                    temp.push(undertime);
                }
            }
        }

        // check over break
        if (hasBreak) {
            if (base.breakTime && newShift.breakEnd && newShift.breakStart && maxBreakDuration) {
                if (breakStartTime.isBetween(shiftBreakTime, shiftBreakEnd)) {
                    const diffHours = moment(breakEndTime).diff(breakStartTime, "hours", true);
                    if (diffHours > maxBreakDuration) {
                        temp.push(overbreak);
                    }
                }
            } else if (base.breakTime && newShift.breakEnd && !maxBreakDuration) {
                if (breakEndTime.isAfter(shiftBreakEnd)) {
                    const diffMinutes = moment(breakEndTime).diff(shiftBreakEnd, gracePeriod.breakGP.format, true);
                    if (Math.abs(diffMinutes) > gracePeriod.breakGP.value) {
                        temp.push(overbreak);
                    }
                }
            }
        }

        // TODO: Add HALF DAY checking

        return temp;
    };

    if (shiftOne) {
        statuses = determineShiftStatuses(
            {
                startTime: workShift.start_time,
                endTime: workShift.end_time,
                breakTime: workShift.break_time,
                breakEnd: workShift.break_end_time
            },
            shiftOne,
            {
                complete: FIRST_SHIFT_DONE,
                onTime: ON_TIME,
                late: LATE,
                undertime: UNDERTIME,
                overbreak: OVER_BREAK
            }
        );
    }

    if (shiftTwo) {
        statuses = statuses.concat(
            determineShiftStatuses(
                {
                    startTime: workShift.start_time_2,
                    endTime: workShift.end_time_2,
                    breakTime: workShift.break_time_2,
                    breakEnd: workShift.break_end_time_2
                },
                shiftTwo,
                {
                    complete: SECOND_SHIFT_DONE,
                    onTime: ON_TIME_SECOND_SHIFT,
                    late: LATE_SECOND_SHIFT,
                    undertime: UNDERTIME,
                    overbreak: OVER_BREAK
                }
            )
        );
    }

    // overtime statuses
    if (overtime) {
        if (overtime.start) {
            statuses.push(OVERTIME);
            if (overtime.end) {
                statuses.push(OVERTIME_DONE);
            }
        }
    }

    return statuses;
};

export const getShiftStatus = (shiftStatus, isSplit) => {
    if (!shiftStatus || !Array.isArray(shiftStatus))
        return {
            isOnGoing: false
        };

    const { ON_TIME, LATE, ON_TIME_SECOND_SHIFT, LATE_SECOND_SHIFT, FIRST_SHIFT_DONE, SECOND_SHIFT_DONE, OVERTIME, OVERTIME_DONE } =
        WORK_HISTORY_SHIFT_STATUS;

    const onGoingShiftOne = (shiftStatus.includes(ON_TIME) || shiftStatus.includes(LATE)) && !shiftStatus.includes(FIRST_SHIFT_DONE);
    const onGoingShiftTwo =
        isSplit &&
        (shiftStatus.includes(ON_TIME_SECOND_SHIFT) || shiftStatus.includes(LATE_SECOND_SHIFT)) &&
        !shiftStatus.includes(SECOND_SHIFT_DONE);
    const onGoingOverTime = shiftStatus.includes(OVERTIME) && !shiftStatus.includes(OVERTIME_DONE);

    return {
        isOnGoing: !!(onGoingShiftOne || onGoingShiftTwo || onGoingOverTime)
    };
};

export const createdUpdateStatus = (row) => {
    const isCreatedByAdmin = row.isCreatedByAdmin;
    const isModifiedByAdmin = row.isModifiedByAdmin;
    const submittedForm = row.submittedForm;

    let text = "";
    if (!submittedForm) {
        if (!isCreatedByAdmin && isModifiedByAdmin) {
            text = "Modified By Admin";
        }
        if (isCreatedByAdmin) {
            text = "Created By Admin";
        }
    } else {
        if (submittedForm.isCreated) {
            text = "Creation Requested";
        } else {
            text = "Update Requested";
        }
    }
    return text && <Tag className="yellow">{text}</Tag>;
};

/**
 * @typedef {Object} Form
 * @property {String} form.timeInOne
 * @property {String} form.timeOutOne
 * @property {String} form.timeInTwo
 * @property {String} form.timeOutTwo
 * @property {String} form.breakStartOne
 * @property {String} form.breakEndOne
 * @property {String} form.breakStartTwo
 * @property {String} form.breakEndTwo
 */

/**
 * @typedef {Object} Shift
 * @property {String} Shift.timeIn
 * @property {String} Shift.timeOut
 * @property {String} Shift.breakStart
 * @property {String} Shift.breakEnd
 */

/**
 * @typedef {Object} Overtime
 * @property {String} Overtime.start
 * @property {String} Overtime.end
 */

/**
 * @typedef {Object} GP_VAL
 * @property {number} value - The value of the GP.
 * @property {string} format - The format of the GP value.
 */

/**
 * @typedef {Object} GracePeriod
 * @property {GP_VAL} GracePeriod.maxOT
 * @property {GP_VAL} GracePeriod.lateGP
 * @property {GP_VAL} GracePeriod.breakGP
 * @property {GP_VAL} GracePeriod.earlyTimeOutGP
 */

/**
 * @typedef {Object} WorkShift
 * @property {String} WorkShift.start_time
 * @property {String} WorkShift.end_time
 * @property {String} WorkShift.start_time_2
 * @property {String} WorkShift.end_time_2
 * @property {String} WorkShift.break_time
 * @property {String} WorkShift.break_end_time
 * @property {String} WorkShift.break_time_2
 * @property {String} WorkShift.break_end_time_2
 * @property {String} WorkShift.start_overtime
 * @property {String} WorkShift.end_overtime
 */
