import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as LeftArrow } from "../../../assets/images/arrow-left.svg";
import CancelIcon from "@mui/icons-material/Cancel";
import ButtonUpgrade from "../../../common/components/extra/ButtonUpgrade";

function SubscriptionPaymentExpired({ onBack }) {
    return (
        <div className="tk-subscription-plan tk-subscription-plan--confirmation">
            <div className="tk-subscription-plan__inner">
                <div className="tk-subscription-plan__header">
                    <div className="left">
                        {onBack && <LeftArrow onClick={onBack} />}
                        <span>
                            <span>Subscription Plan</span>
                            <span>/</span>
                            <span>Payment Summary</span>
                        </span>
                    </div>
                </div>
                <div className="tk-subscription-plan__items flex column gap-1">
                    <div className="confirmation-details">
                        <CancelIcon style={{ color: "red" }} />
                        <h3>Payment Expired</h3>
                        <div className="current-plan">
                            <span>Your session is expired. Please go back to upgrade page and try again.</span>
                        </div>
                    </div>
                    <ButtonUpgrade />
                </div>
            </div>
        </div>
    );
}

SubscriptionPaymentExpired.propTypes = {
    onBack: PropTypes.func
};

export default SubscriptionPaymentExpired;
