export const ROUTE_TYPE = {
    CUSTOM: "custom",
    LAW: "law",
    ALL: "all"
};

export const FIELDS = {
    TITLE: {
        name: "title",
        label: "Title",
        defaultValue: "",
        required: true
    },
    NOTES: {
        name: "notes",
        label: "Notes",
        defaultValue: "",
        required: false
    },
    DESCRIPTION: {
        name: "description",
        label: "Description",
        defaultValue: "",
        required: true
    },
    PENALTY_MEASURES: {
        name: "penalty_measures",
        label: "Measures",
        defaultValue: [],
        required: true
    },
    DEDUCTION_TYPE: {
        name: "deduction_type",
        label: "Deduction Type",
        defaultValue: [],
        required: true
    }
};
