import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import AssignmentEmpIcon from "@mui/icons-material/AssignmentInd";
import EditIcon from "@mui/icons-material/Edit";
import { createConfirmAlert, createTimeFromDate, renderNA, sanitizeWords, toReadableFromDate } from "../../../common/utilities/helper";
import Tag from "../../../common/components/extra/Tag";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectUser } from "../../common/slice";
import { HOL_PREFIX } from "./const";
import { SHIFT_TYPE } from "../../../common/utilities/const";

const HEADERS = {
    TITLE: "title",
    SHIFT_RANGE: "shift_range",
    BREAK_RANGE: "break_range",
    UPDATED_AT: "updatedAt",
    CREATED_AT: "createdAt",
    ACTIONS: "actions"
};

const HEADERS_TO_ARRAY = Object.values(HEADERS);

const CreateTableHeaders = ({ onEdit, onDelete, onAssignEmployees }) => {
    const user = useAppSelector(selectUser);
    const setting = user.Setting;
    return HEADERS_TO_ARRAY.map((head) => {
        const temp = { key: head, style: {} };
        switch (head) {
            case HEADERS.TITLE: {
                temp.sortKey = "title";
                temp.label = "Title";
                temp.style.width = "20rem";
                temp.style.margin = ".5rem 0";
                temp.render = (_, row) => {
                    const prefix = row.is_holiday ? <span className="fade">Hol - </span> : "";
                    const title = sanitizeWords(
                        (row.is_holiday && row.title.startsWith(HOL_PREFIX) && row.title.replace(HOL_PREFIX, "")) || row.title
                    );

                    return (
                        <div className="flex column gap-05 overflow-hidden">
                            <span>
                                {prefix}
                                <span style={{ fontWeight: "bold" }}>{title}</span>
                            </span>
                            <div className="flex gap-05 wrap" style={{ alignItems: "center" }}>
                                <span className="small-font fade">Type:</span>
                                <Tag>{sanitizeWords(row.shift_type)}</Tag>
                            </div>
                            <div className="flex gap-05 wrap" style={{ alignItems: "center" }}>
                                <span className="small-font fade">Max OT (Hours):</span>
                                {row.max_overtime ? <span className="semi-bold">{row.max_overtime}</span> : renderNA()}
                            </div>
                            <div className="flex gap-05 wrap" style={{ alignItems: "center" }}>
                                <span className="small-font fade">Required Shift (Hours):</span>
                                {row.required_shift_time ? <span className="semi-bold">{row.required_shift_time}</span> : renderNA()}
                            </div>
                        </div>
                    );
                };
                break;
            }
            case HEADERS.SHIFT_RANGE: {
                temp.label = "Shift Range";
                temp.render = (_, row) => {
                    const renderval = (
                        <div className="flex column gap-05 overflow-hidden" style={{ fontWeight: "500" }}>
                            {row.start_time && row.end_time && (
                                <span className="text-ellipsis">{createTimeFromDate(row.start_time, row.end_time, setting.timezone)}</span>
                            )}
                            {row.start_time_2 && row.end_time_2 && (
                                <span className="text-ellipsis">{createTimeFromDate(row.start_time_2, row.end_time_2, setting.timezone)}</span>
                            )}
                            {(!row.start_time || !row.end_time) && (!row.start_time_2 || !row.end_time_2) && renderNA()}
                        </div>
                    );

                    return renderval;
                };
                break;
            }
            case HEADERS.BREAK_RANGE: {
                temp.label = "Break Range";
                temp.render = (_, row) => {
                    const maxBreakDuration = row.max_break_duration;
                    const isSplit = row.shift_type == SHIFT_TYPE.SPLIT;
                    const isFlexibleBreak = !!maxBreakDuration && (isSplit ? !row.break_time && !row.break_end_time_2 : !row.break_time);
                    const hasNoBreak = !maxBreakDuration;
                    const renderval = (
                        <div className="flex column gap-05 overflow-hidden" style={{ fontWeight: "500" }}>
                            {isFlexibleBreak ? (
                                <span className="small-font fade semi-bold">Flexible Break Time</span>
                            ) : hasNoBreak ? (
                                <span className="small-font fade semi-bold">No Break Time</span>
                            ) : (
                                <>
                                    {row.break_time && row.break_end_time && (
                                        <span className="text-ellipsis">
                                            {createTimeFromDate(row.break_time, row.break_end_time, setting.timezone)}
                                        </span>
                                    )}
                                    {row.break_time_2 && row.break_end_time_2 && (
                                        <span className="text-ellipsis">
                                            {createTimeFromDate(row.break_time_2, row.break_end_time_2, setting.timezone)}
                                        </span>
                                    )}
                                    {(!row.break_time || !row.break_end_time) && (!row.break_time_2 || !row.break_end_time_2) && renderNA()}
                                </>
                            )}
                        </div>
                    );
                    return renderval;
                };
                break;
            }
            case HEADERS.UPDATED_AT: {
                temp.sortKey = "updatedAt";
                temp.label = "Updated";
                temp.render = (val) => (
                    <div className="flex column gap-05 overflow-hidden fade">
                        <span className="text-ellipsis small-font">{toReadableFromDate(val, setting.timezone)}</span>
                    </div>
                );
                break;
            }
            case HEADERS.CREATED_AT: {
                temp.sortKey = "createdAt";
                temp.label = "Created";
                temp.render = (val) => (
                    <div className="flex column gap-05 overflow-hidden fade">
                        <span className="text-ellipsis small-font">{toReadableFromDate(val, setting.timezone)}</span>
                    </div>
                );
                break;
            }
            case HEADERS.ACTIONS: {
                temp.childStyle = {
                    display: "flex",
                    gap: ".5rem",
                    justifyContent: "end",
                    paddingRight: "1rem",
                    alignItems: "center"
                };
                temp.render = (_, row) => (
                    <>
                        {!row.is_holiday && (
                            <AssignmentEmpIcon
                                className="hover-svg"
                                onClick={() => typeof onAssignEmployees === "function" && onAssignEmployees(row)}
                            />
                        )}
                        <EditIcon className="hover-svg" onClick={() => typeof onEdit === "function" && onEdit(row)} style={{ width: "1.4rem" }} />
                        <DeleteIcon
                            style={{ color: "red" }}
                            className="hover-svg"
                            onClick={async () => {
                                createConfirmAlert({
                                    title: "Remove Work Shift",
                                    content: "Are you sure you want to remove this work shift? This cannot be undone.",
                                    onConfirm: async (close) => {
                                        close();
                                        typeof onDelete === "function" && (await onDelete(row));
                                    }
                                });
                            }}
                        />
                    </>
                );
                break;
            }
            default:
                break;
        }
        return temp;
    });
};

export default CreateTableHeaders;
