import React from "react";
import PropTypes from "prop-types";
import useNavigate from "../../../common/hooks/useNavigate";
import { useLogoutMutation } from "../../common/api";
import FormAuthTemplate from "../../../common/components/layout/FormAuthTemplate";
import Navigation from "../../../common/classes/Navigation";
import Button from "../../../common/components/extra/Button";
import { RECURRENCE } from "../../../common/utilities/const";
import ButtonUpgrade from "../../../common/components/extra/ButtonUpgrade";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectUser } from "../../common/slice";

function ExpiredSubscriptionAccount() {
    const [logout] = useLogoutMutation();
    const navigate = useNavigate();
    const user = useAppSelector(selectUser);

    const companySubscription = user?.CompanySubscription;
    const isTrial = companySubscription && companySubscription.Subscription.recurrence === RECURRENCE.TRIAL;

    return (
        <div className="tk-sub-ended">
            <div className="tk-sub-ended__inner">
                <FormAuthTemplate
                    title={isTrial ? "Free Trial Ended" : "Subscription Ended"}
                    footer={
                        <div className="tk-sub-ended__footer flex gap-05 center">
                            Need help?
                            <strong>
                                <a href="mailto:support@time-keeper.app">Contact customer support</a>
                            </strong>
                        </div>
                    }
                    buttonExtra={
                        <>
                            <Button onClick={() => logout().then(() => navigate(Navigation.Routes.SIGNIN.path))}>Goto login</Button>
                            <ButtonUpgrade />
                        </>
                    }
                    noSubmit
                ></FormAuthTemplate>
            </div>
        </div>
    );
}

ExpiredSubscriptionAccount.propTypes = {
    user: PropTypes.object
};

export default ExpiredSubscriptionAccount;
