import React, { useState } from "react";
import PropTypes from "prop-types";
import cloneDeep from "lodash/cloneDeep";
import AddIcon from "@mui/icons-material/Add";
import AsyncSelectLazy from "../../../common/components/extra/AsyncSelectLazy";
import MyTooltip from "../../../common/components/extra/Tooltip";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectUser } from "../../common/slice";
import UpdateModal from "./UpdateModal";
import { useLazyWorkShift } from "./hooks";

function WorkShiftSelectLazy(props) {
    const [openAddModal, setOpenAddModal] = useState(false);

    const [object, , { loadMore, search, reset, isLoading }] = useLazyWorkShift({
        startFrom: props?.value?.title,
        noHolidays: !props.includeHolidays,
        onlyHolidays: props.onlyHolidays
    });

    const user = useAppSelector(selectUser);

    const setting = user.Setting;
    const isDisabled = props.isDisabled;
    const getValue = !props.isMulti
        ? props.value && Object.keys(props.value).length && object.data.find((item) => item.id === props.value.id)
        : object.data.filter((item) => props.value.map((v) => v.id).includes(item.id));

    const handleSearch = (newInput, type) => {
        const allowedActions = ["input-change"];
        if (allowedActions.includes(type.action)) {
            search(newInput);
        }
    };

    const handleChange = (val) => {
        let temp = val;
        if (temp) {
            temp = cloneDeep(temp);
            delete temp.label;
            delete temp.value;
        }
        typeof props.onChange === "function" && props.onChange(temp);
    };

    const handleFinish = async () => {
        await reset();
        setOpenAddModal(false);
    };

    return (
        <div className="flex gap-1 center">
            <AsyncSelectLazy
                {...props}
                filterOption={(options, newInputs) => {
                    const search = newInputs?.toLowerCase().trim();
                    if (options?.data?.keywords?.toLowerCase().includes(search)) {
                        return true;
                    } else {
                        return false;
                    }
                }}
                onChange={handleChange}
                value={getValue}
                options={object.data}
                onLoadMore={loadMore}
                onSearch={handleSearch}
                isFetching={isLoading}
                style={{ flex: 1 }}
            />
            <MyTooltip message={!isDisabled && "Add Work Shift"} style={{ display: "flex" }}>
                <AddIcon className={`hover-svg primary-color ${isDisabled ? "disabled" : ""}`} onClick={() => setOpenAddModal(!openAddModal)} />
            </MyTooltip>
            {openAddModal && (
                <UpdateModal open={openAddModal} onChange={(bool) => setOpenAddModal(bool)} onFinish={handleFinish} setting={setting} nosite />
            )}
        </div>
    );
}

WorkShiftSelectLazy.propTypes = {
    onChange: PropTypes.func,
    isOutlined: PropTypes.bool,
    label: PropTypes.string,
    noborder: PropTypes.bool,
    style: PropTypes.object,
    value: PropTypes.any,
    isMulti: PropTypes.bool,
    timezone: PropTypes.any,
    isDisabled: PropTypes.bool,
    includeHolidays: PropTypes.bool,
    onlyHolidays: PropTypes.bool
};

export default WorkShiftSelectLazy;
