import React from "react";
import PropTypes from "prop-types";
import ResetPassword from "../../features/company/auth/ResetPassword";
import ChangePassword from "../../features/company/auth/ChangePassword";
import { useParams } from "react-router-dom";

function ResetPasswordPage({ isChangePassword }) {
    const params = useParams();
    if (isChangePassword) {
        return <ChangePassword token={params.token || ""} />;
    }
    return <ResetPassword />;
}

export default ResetPasswordPage;

ResetPasswordPage.propTypes = {
    isChangePassword: PropTypes.bool
};
