import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import EditIcon from "@mui/icons-material/Edit";
import RemoveIcon from "@mui/icons-material/Delete";
import TableList from "../../../common/components/extra/table/TableList";
import Button, { BTN_TYPE } from "../../../common/components/extra/Button";
import { cloneDeep } from "lodash";
import { MEASURE_FIELDS } from "./const";
import MeasuresUpdateModal from "./MeasuresUpdateModal";
import { addCommasToMoney, performNativeSort, renderNA, sanitizeWords } from "../../../common/utilities/helper";
import Tag from "../../../common/components/extra/Tag";
import { PENALTY_LEVEL, SORT_ORDER } from "../../../common/utilities/const";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectUserSetting } from "../../common/slice";
import MyTooltip from "../../../common/components/extra/Tooltip";

const { LABEL, LEVEL, OCCURENCE, VALUE } = MEASURE_FIELDS;

function Measures({ data, onChange, readOnly, isLoading, isByLaw }) {
    const [original] = useState(data); // act as holder for original // dont update
    const [openModal, setOpenModal] = useState(false);
    const [measures, setMeasures] = useState(data || []);
    const [selected, setSelected] = useState(null);
    const [activeSort, setActiveSort] = useState({
        sortBy: OCCURENCE.key,
        order: SORT_ORDER.ASC
    });

    const setting = useAppSelector(selectUserSetting);
    const isLastMeasureIsTermination = measures.length > 0 && measures[measures.length - 1][LEVEL.key] == PENALTY_LEVEL.TERMINATION;

    useEffect(() => {
        if (!measures.length) {
            setMeasures(data || []);
        }
    }, [isLoading]);

    const upsertMeasures = (newArray = []) => {
        const toInsert = [];
        const newMeasures = cloneDeep(measures);
        newArray.forEach((newItem) => {
            const matchingItemIdx = newMeasures.findIndex((measure) => measure.occurence === newItem.occurence);
            if (matchingItemIdx >= 0) {
                newMeasures[matchingItemIdx] = { ...newMeasures[matchingItemIdx], ...newItem };
            } else {
                toInsert.push(newItem);
            }
        });
        setMeasures(newMeasures.concat(toInsert));
        return newMeasures.concat(toInsert);
    };

    const removeMeasure = (occurence) => {
        const newMeasures = measures.filter((measure) => measure.occurence !== occurence).map((measure, idx) => ({ ...measure, occurence: idx + 1 }));
        setMeasures(newMeasures);
        typeof onChange === "function" && onChange(newMeasures);
    };

    const { data: headers } = CreateTableHeaders({
        onUpdate: (row) => {
            setSelected(row);
            setOpenModal(true);
        },
        onRemove: (row) => removeMeasure(row.occurence),
        readOnly,
        isByLaw,
        setting
    });

    const handleSort = ({ sortBy, order }) => {
        setMeasures(performNativeSort(measures, { sortBy, order }));
        setActiveSort({ sortBy, order });
    };

    const handleFinish = async (result) => {
        setSelected(null);
        setOpenModal(false);
        const newMeasures = upsertMeasures([result]);
        const correctedMeasures = newMeasures.map((measure, idx) => ({ ...measure, occurence: idx + 1 }));
        setMeasures(correctedMeasures);
        typeof onChange === "function" && onChange(correctedMeasures);
    };

    const handleAdd = () => {
        setSelected(null);
        setOpenModal(true);
    };

    return (
        <div>
            <div className="flex column">
                <div className="flex column gap-05">
                    {!readOnly && !isByLaw && (
                        <div className="flex" style={{ justifyContent: "flex-end" }}>
                            <MyTooltip
                                message={
                                    isLastMeasureIsTermination ? "When termination is the final measure, no additional measures can be added." : ""
                                }
                            >
                                <Button
                                    options={{ style: { paddingTop: 0 } }}
                                    type={BTN_TYPE.ADD}
                                    onClick={handleAdd}
                                    disabled={isLastMeasureIsTermination}
                                >
                                    Add
                                </Button>
                            </MyTooltip>
                        </div>
                    )}
                    <TableList
                        headers={headers}
                        data={measures}
                        onSort={handleSort}
                        height={35}
                        activeSort={activeSort}
                        isLoadingMore={isLoading}
                        small
                    />
                </div>
            </div>
            {openModal && (
                <MeasuresUpdateModal
                    data={selected}
                    open={openModal}
                    onChange={setOpenModal}
                    onFinish={handleFinish}
                    measures={measures}
                    isByLaw={isByLaw}
                    setting={setting}
                    original={original}
                />
            )}
        </div>
    );
}

export default Measures;

Measures.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            level: PropTypes.oneOf(Object.values(PENALTY_LEVEL)),
            value: PropTypes.number,
            occurence: PropTypes.number
        })
    ),
    onChange: PropTypes.func,
    readOnly: PropTypes.bool,
    isLoading: PropTypes.bool,
    isByLaw: PropTypes.bool
};

const CreateTableHeaders = ({ onUpdate, onRemove, readOnly, isByLaw, setting }) => {
    const headers = {
        OCCURENCE: {
            key: OCCURENCE.key,
            sortKey: OCCURENCE.key,
            label: OCCURENCE.label,
            render: (row) => (
                <span className="text-ellipsis">
                    <span>{row[OCCURENCE.key]}</span>
                </span>
            )
        },
        LABEL: {
            key: LABEL.key,
            sortKey: LABEL.key,
            label: LABEL.label,
            render: (row) => (
                <span className="text-ellipsis">
                    <span style={{ whiteSpace: "break-spaces" }}>{sanitizeWords(row[LABEL.key]).replace("Qar", "QAR")}</span>
                </span>
            )
        },
        LEVEL: {
            key: LEVEL.key,
            sortKey: LEVEL.key,
            label: LEVEL.label,
            render: (row) => {
                const yellow = [PENALTY_LEVEL.WARNING].includes(row.level) ? "yellow" : "";
                const red = [PENALTY_LEVEL.CANCEL_DAY_RECORD, PENALTY_LEVEL.TERMINATION].includes(row.level) ? "red" : "";
                return (
                    <span className="text-ellipsis">
                        <span>{<Tag className={yellow || red}>{sanitizeWords(row[LEVEL.key])}</Tag>}</span>
                    </span>
                );
            }
        },
        VALUE: {
            key: VALUE.key,
            sortKey: VALUE.key,
            label: VALUE.label,
            render: (row) => {
                const isDayDeduc = row[LEVEL.key] == PENALTY_LEVEL.DAY_DEDUCTION;
                const isFixedValue = row[LEVEL.key] == PENALTY_LEVEL.FIX_VALUE;
                const suffix = isDayDeduc ? "Day" : isFixedValue && setting?.currency ? setting?.currency : "";
                const value = isFixedValue ? addCommasToMoney(row[VALUE.key]) : row[VALUE.key];
                return (
                    <span className="text-ellipsis">
                        <span>
                            {value ? (
                                <>
                                    -{value} <span className="fade">{suffix}</span>
                                </>
                            ) : (
                                renderNA("Not Applicable")
                            )}
                        </span>
                    </span>
                );
            }
        }
    };

    if (!readOnly) {
        headers.ACTION = {
            key: "action",
            style: { display: "flex", justifyContent: "flex-end", paddingRight: ".5rem" },
            render: (row) => {
                return (
                    <div className="flex gap-05">
                        {!readOnly && (
                            <>
                                <Button
                                    onClick={() => typeof onUpdate === "function" && onUpdate(row)}
                                    options={{ style: { padding: 0, boxShadow: "unset", marginLeft: "auto", maxWidth: "max-content" } }}
                                    transparent
                                >
                                    <EditIcon className="fade hover-svg" style={{ width: "1.4rem" }} />
                                </Button>
                                {!isByLaw && (
                                    <Button
                                        onClick={() => typeof onRemove === "function" && onRemove(row)}
                                        options={{ style: { padding: 0, boxShadow: "unset", marginLeft: "auto", maxWidth: "max-content" } }}
                                        disabled={!!row.usageCount}
                                        transparent
                                    >
                                        <RemoveIcon className="hover-svg" style={{ width: "1.4rem", color: "red" }} />
                                    </Button>
                                )}
                            </>
                        )}
                    </div>
                );
            }
        };
    }
    return { data: Object.values(headers), original: headers };
};
