import React from "react";
import { sanitizeWords } from "../../../common/utilities/helper";
import LetteredAvatar from "../../../common/components/extra/avatar/LetteredAvatar";
import { INDEXES, SAL_CSV_HEADERS } from "./const";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectViewport } from "../../common/slice";

const HEADERS_TO_ARRAY = Object.values(SAL_CSV_HEADERS);

const CreateTableHeaders = () => {
    const viewport = useAppSelector(selectViewport);
    const isMobile = viewport.isMobile;

    return HEADERS_TO_ARRAY.map((head) => {
        const temp = { key: head.id, style: {} };
        switch (head) {
            case SAL_CSV_HEADERS.SN: {
                temp.sortKey = head.id;
                temp.label = head.title;
                temp.style.width = "5rem";
                temp.fixed = true;
                temp.noResponsive = true;
                temp.render = (_, row) => {
                    return <div className="flex column gap-05">{row[head.id]}</div>;
                };
                break;
            }
            case SAL_CSV_HEADERS.EMPLOYEE_NAME: {
                temp.sortKey = INDEXES.SYS_INDEX1.id;
                temp.label = "Employee";
                temp.style.width = isMobile ? "13rem" : "15rem";
                temp.style.margin = ".5rem 0";
                temp.fixed = true;
                temp.render = (_, row) => {
                    const fullName = row[head.id];
                    const photo = row.photo;
                    return (
                        <>
                            <div className="flex gap-1" style={{ alignItems: "center" }}>
                                <LetteredAvatar name={fullName} src={photo} size={30} small />
                                <div className="flex column gap-05 overflow-hidden">
                                    <div style={{ fontWeight: "bold" }}>{sanitizeWords(fullName)}</div>
                                </div>
                            </div>
                        </>
                    );
                };
                break;
            }
            default:
                temp.label = head.title;
                temp.render = (_, row) => {
                    return <div className="flex column gap-05">{row[head.id]}</div>;
                };
                break;
        }
        return temp;
    });
};

export default CreateTableHeaders;
