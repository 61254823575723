export const FIELDS = {
    TITLE: {
        name: "title",
        label: "Title",
        defaultValue: "",
        required: true
    },
    AMOUNT: {
        name: "amount",
        label: "Amount",
        defaultValue: "",
        required: true
    },
    DESCRIPTION: {
        name: "description",
        label: "Description",
        defaultValue: "",
        required: false
    },
    EMPLOYEE_LIMIT: {
        name: "employee_limit",
        label: "Employee Limit",
        defaultValue: "",
        required: true
    },
    RECURRENCE: {
        name: "recurrence",
        label: "Recurrence",
        defaultValue: "",
        required: true
    },
    IS_ACTIVE: {
        name: "is_active",
        label: "Status",
        defaultValue: false
    }
};
