import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import CardPage from "../../../common/components/extra/CardPage";
import { toReadableFromDate } from "../../../common/utilities/helper";

function History({ historyLogs }) {
    const [displayedLogs, setDisplayedLogs] = useState([]);

    useEffect(() => {
        setDisplayedLogs(historyLogs);
    }, [historyLogs]);

    return (
        <CardPage className="history" title="History">
            {displayedLogs.length > 0 ? (
                <ul>
                    {displayedLogs.map((log) => (
                        <li key={log.id}>
                            <div className="message">{log.message}</div>
                            <div className="createdAt">{toReadableFromDate(log.createdAt * 1000)}</div>
                        </li>
                    ))}
                </ul>
            ) : (
                <span className="fade">No history logs available.</span>
            )}
        </CardPage>
    );
}

History.propTypes = {
    historyLogs: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            message: PropTypes.string,
            createdAt: PropTypes.number,
            timestamp: PropTypes.string
        })
    )
};

export default History;
