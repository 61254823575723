import React from "react";
import { useAppDispatch, useAppSelector } from "../../common/hooks/reduxHooks";
import { selectLoading, selectTableConfig, setTableConfig } from "../../features/company/employeeSubmittedForms/slice";
import { selectUser } from "../../features/common/slice";
import PageLayout from "../../common/components/layout/PageLayout";
import SubmittedForms from "../../features/company/employeeSubmittedForms/SubmittedForms";
import Filter from "../../features/company/employeeSubmittedForms/Filter";
import { usePaginateSubmittedForms } from "../../features/company/employeeSubmittedForms/hooks";

function EmployeeSubmittedForms() {
    const dispatch = useAppDispatch();
    const loading = useAppSelector(selectLoading);
    const user = useAppSelector(selectUser);
    const config = useAppSelector(selectTableConfig);

    const [, { isSearching, onFilter }] = usePaginateSubmittedForms();

    const handleSearch = (value) => dispatch(setTableConfig({ search: (value && value.toLowerCase().trim()) || "" }));
    const handleFilter = (filter) => onFilter(filter);

    return (
        <PageLayout
            className="tk-employees-submitted-forms"
            title="Manage Submitted Forms"
            isLoading={loading}
            search={{ onSearch: handleSearch, value: config.search }}
            filter={<Filter onFilter={handleFilter} isLoading={isSearching} />}
            styles={{
                body: {
                    height: "calc(100vh - 20.5rem)"
                }
            }}
            hasFilter
        >
            <SubmittedForms user={user} />
        </PageLayout>
    );
}

export default EmployeeSubmittedForms;
