import React, { useEffect } from "react";
import PropTypes from "prop-types";
import InfoCard from "../../../common/components/extra/card/InfoCard";
import { toProperMoneyFormat } from "../../../common/utilities/helper";
import { CURRENCIES, RECURRENCE } from "../../../common/utilities/const";
import { useGetUpgradeAmount } from "./hooks";
import Divider from "../../../common/components/extra/Divider";
import Input from "../../../common/components/extra/form/Input";

function Summary({ subscription }) {
    const [getUpgradeDetails, { result, isLoading }] = useGetUpgradeAmount();

    const isDiffActualAmount = result && result.amount && result.amount != subscription.amount;

    useEffect(() => {
        getUpgradeDetails(subscription.id);
    }, []);

    const createSubText = (type) => {
        switch (type) {
            case RECURRENCE.MONTHLY:
                return "month";
            case RECURRENCE.QUARTERLY:
                return "3 months";
            case RECURRENCE.SEMI_ANNUAL:
                return "6 months";
            case RECURRENCE.ANNUALLY:
                return "12 months";
            default:
                break;
        }
    };

    return (
        <InfoCard className="summary" header={<span className="bold">SUMMARY</span>}>
            <div className="flex column w100">
                <div className="flex w100" style={{ justifyContent: "space-between" }}>
                    <div className="left">
                        <strong style={{ textTransform: "uppercase" }}>{subscription.title}</strong>
                        <span className="bold" style={{ fontStyle: "italic" }}>
                            up to {subscription.employee_limit} users
                        </span>
                    </div>
                    <div className="right">
                        <div className="flex gap-05">
                            <h2 className="money" style={(isDiffActualAmount && { textDecoration: "line-through" }) || {}}>
                                {toProperMoneyFormat(subscription.amount, subscription.currency)}
                            </h2>
                        </div>
                        <span style={{ textAlign: "right" }}>*manual renewal per {createSubText(subscription.recurrence)}</span>
                    </div>
                </div>
                {!!(result && result.old_plan && isDiffActualAmount) && (
                    <>
                        <Divider style={{ width: "100%" }} />
                        <Input
                            label="New Plan"
                            renderValue={toProperMoneyFormat(result && result.new_plan, subscription.currency)}
                            isLoading={isLoading}
                            readOnly
                        />
                        <Input
                            label="Old Plan"
                            renderValue={toProperMoneyFormat(result && result.old_plan, subscription.currency)}
                            isLoading={isLoading}
                            readOnly
                        />
                        <Input
                            label="Total Amount"
                            renderValue={
                                <span className="primary-color normal-font" style={{ fontWeight: "bold" }}>
                                    {toProperMoneyFormat(result && result.amount, subscription.currency)}
                                </span>
                            }
                            isLoading={isLoading}
                            readOnly
                        />
                    </>
                )}
            </div>
        </InfoCard>
    );
}

export default Summary;

Summary.propTypes = {
    subscription: PropTypes.shape({
        id: PropTypes.any,
        title: PropTypes.string,
        employee_limit: PropTypes.number,
        amount: PropTypes.number,
        recurrence: PropTypes.string,
        currency: PropTypes.oneOf(Object.keys(CURRENCIES)),
        PTL: PropTypes.number,
        PTLtype: PropTypes.string
    })
};
