import React, { useState } from "react";
import PropTypes from "prop-types";
import PreviewIcon from "@mui/icons-material/Visibility";
import Modal from "../../../common/components/extra/Modal";
import { sanitizeWords, toReadableFromDate } from "../../../common/utilities/helper";
import Tag from "../../../common/components/extra/Tag";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectUser } from "../../common/slice";
import { useGetWorkDetailsByIds } from "./hooks";
import Empty from "../../../common/components/extra/Empty";
import Button from "../../../common/components/extra/Button";
import UpdateModal from "./UpdateModal";

function ViewWorkDetailsModal({ open, onChange, data }) {
    const [selected, setSelected] = useState(null);
    const [workdetails, isLoading, { isMounted }] = useGetWorkDetailsByIds(data);

    const user = useAppSelector(selectUser);
    const setting = user.Setting;
    const timezone = setting.timezone;

    const handleViewRecord = (id) => setSelected(id);

    return (
        <Modal
            title="View Work Types"
            open={open}
            onChange={onChange}
            styles={{ title: { textAlign: "left" }, content: { minHeight: "10rem", minWidth: "20rem" } }}
            small
        >
            <div className="tk-employees-work-type__modal-view-details w100" style={{ margin: workdetails.length ? "unset" : "auto" }}>
                {isMounted &&
                    (!isLoading && workdetails.length ? (
                        <ul>
                            {workdetails.map((wd) => (
                                <li key={wd.id} className="flex gap-1">
                                    <div className="left flex gap-1 column" style={{ maxWidth: "28rem" }}>
                                        <div className="flex gap-1 wrap">
                                            <span className="bold flex center">{sanitizeWords(wd.name)}</span>
                                            <div className="flex gap-05">
                                                <Tag>{wd.type}</Tag>
                                                {wd.code && <Tag className="brown">{wd.code}</Tag>}
                                            </div>
                                        </div>
                                        <span className="fade small-font">{toReadableFromDate(wd.createdAt, timezone)}</span>
                                    </div>
                                    <div className="right">
                                        <Button beforeExtra={<PreviewIcon />} onClick={() => handleViewRecord(wd.id)} mini transparent>
                                            <span>View</span>
                                        </Button>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <div className="flex center">
                            <Empty
                                style={{ height: "10rem" }}
                                iconStyle={{ height: "6rem" }}
                                loaderStyle={{ height: "7rem", marginTop: "-2rem" }}
                                message="No work type found."
                                isLoading={isLoading}
                            />
                        </div>
                    ))}
            </div>
            {!!selected && <UpdateModal open={!!selected} onClose={() => setSelected(null)} id={selected} readOnly transparentOverlay />}
        </Modal>
    );
}

ViewWorkDetailsModal.propTypes = {
    open: PropTypes.bool,
    data: PropTypes.arrayOf(PropTypes.number),
    onChange: PropTypes.func
};

export default ViewWorkDetailsModal;
