import { useEffect, useState } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { TOAST_TYPE, createToast } from "../utilities/helper";

const SCALE = 0.7;
const PX_TO_IN = 72;
const OFFSET = 0.93;

function useConvertHTMLtoPDF(id, filename = "download.pdf", bgcolor = "#fff", offset = null) {
    const [element, setElement] = useState(null);
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        const el = document.getElementById(id);
        if (el) {
            setElement(el);
        }
    }, [id]);

    const convertToPDF = async () => {
        if (!element) return;
        setLoading(true);
        try {
            const pdf = new jsPDF("p", "in", "letter", false);
            pdf.setFont("helvetica");

            const pageWidth = pdf.internal.pageSize.getWidth();
            const pageHeight = pdf.internal.pageSize.getHeight();
            const totalPages = pdf.internal.getNumberOfPages();

            for (let i = 1; i <= totalPages; i++) {
                pdf.setPage(i);
                pdf.setFillColor(bgcolor);
                pdf.rect(0, 0, pageWidth, pageHeight, "F");
            }

            const canvas = await html2canvas(element, {
                backgroundColor: "#fff",
                scale: 1,
                logging: false, // Disable logging for performance
                imageTimeout: 0, // Disable image timeout
                removeContainer: true, // Remove the canvas container after rendering
                useCORS: true // Use CORS to load
            });

            const imgWidth = (element.clientWidth * SCALE) / PX_TO_IN;
            const imgHeight = (element.clientHeight * SCALE) / PX_TO_IN;
            const pageCenterX = pageWidth / 2;
            const x = pageCenterX - imgWidth / 2;

            const imgData = canvas.toDataURL("image/jpeg", 1.0);

            pdf.addImage(imgData, "JPEG", x * (offset || OFFSET), 1, imgWidth, imgHeight);
            pdf.save(filename);
            setLoading(false);
        } catch (error) {
            createToast("Unable to download file, please try again later.", TOAST_TYPE.ERROR);
        } finally {
            setLoading(false);
        }
    };

    return [convertToPDF, { isLoading }];
}

export default useConvertHTMLtoPDF;
