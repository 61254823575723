import React, { useEffect, useState } from "react";
// import MonitorBarGraph from "./MonitorBarGraph";
import EmployeesLoginActivities from "./employeeLoginActivities/EmployeesLoginActivities";
import History from "./History";
import ReportBug from "./ReportBug";
import Notifications from "./Notifications";

import { useAppSelector } from "../../../common/hooks/reduxHooks";

import database from "../../../common/config/firebase";
import { ROLE_TYPE } from "../../../common/utilities/const";
import { selectRole, selectUser } from "../../common/slice";
// import { getDatabase, onValue } from "firebase/database";

function CompanyDashboard() {
    const user = useAppSelector(selectUser);
    const role = useAppSelector(selectRole);
    const isCompanyAdmin = role.type === ROLE_TYPE.COMPANY;
    const companyId = user.id;
    const [historyLogs, setHistoryLogs] = useState([]);
    const [notificationsLogs, setNotifications] = useState([]);
    // const [, setTimingLogs] = useState([]);

    useEffect(() => {
        if (isCompanyAdmin) {
            const notificationsRef = database.ref(`company_${companyId}/Notification/admin/default`);
            const historyLogsRef = database.ref(`company_${companyId}/ActivityLogs/admin`);
            // const timingLogsRef = database.ref(`company_${companyId}/TimingLogs/admin`);

            notificationsRef.limitToLast(20).on(
                "child_added",
                (snapshot) => {
                    const activityLogId = snapshot.key;
                    const activityLog = snapshot.val();
                    const createdAt = activityLog.createdAt;

                    const notifMessage = activityLog.body.message;
                    setNotifications((prevLogs) => [{ id: activityLogId, message: notifMessage, createdAt }, ...prevLogs]);
                },
                (error) => {
                    console.error("Error listening for new notification logs: ", error.message);
                }
            );

            historyLogsRef.limitToLast(20).on(
                "child_added",
                (snapshot) => {
                    const activityLogId = snapshot.key;
                    const activityLog = snapshot.val();
                    const type = activityLog.type;
                    const createdAt = activityLog.createdAt;

                    if (type.startsWith("timing_")) {
                        // Update timing logs
                        //setTimingLogs((prevLogs) => [...prevLogs, { id: activityLogId, message }]);
                    } else if (type.startsWith("history_")) {
                        // Update history logs
                        const historyMessage = activityLog.body.message;
                        setHistoryLogs((prevLogs) => [{ id: activityLogId, message: historyMessage, createdAt }, ...prevLogs]);
                    }
                },
                (error) => {
                    console.error("Error listening for new history logs: ", error.message);
                }
            );

            // timingLogsRef.limitToLast(1).on(
            //     "child_added",
            //     (snapshot) => {
            //         // const timingLogId = snapshot.key;
            //         const timingLog = snapshot.val();
            //         // const createdAt = timingLog.createdAt;
            //         const logData = timingLog?.body.data?.employeeWorkHistoryLog;
            //         setTimingLogs((prevLogs) => [{ logData }, ...prevLogs]);
            //     },
            //     (error) => {
            //         console.error("Error listening for new timing logs: ", error.message);
            //     }
            // );

            // Return cleanup function to stop listening when component unmounts
            return () => {
                notificationsRef.off();
                historyLogsRef.off();
                // timingLogsRef.off();
            };
        }
    }, [companyId]);

    return (
        <div className="tk-dashboard-company flex gap-05">
            <div className="tk-dashboard-company__inner">
                <div className="tk-dashboard-company__content">
                    <div className="tk-dashboard-company__content__inner">
                        <div className="tk-dashboard-company__title">
                            <h2>Dashboard</h2>
                        </div>
                        {/* <MonitorBarGraph /> */}
                        <EmployeesLoginActivities />
                    </div>
                </div>
            </div>
            <div className="tk-dashboard-company__right flex column gap-05">
                <Notifications notificationsLogs={notificationsLogs} />
                <History historyLogs={historyLogs} />
                <ReportBug />
            </div>
        </div>
    );
}

export default CompanyDashboard;
