import React, { useState } from "react";
import { DndContext, closestCenter, KeyboardSensor, useSensor, useSensors, MouseSensor, TouchSensor, PointerSensor } from "@dnd-kit/core";
import { SortableContext, arraySwap, sortableKeyboardCoordinates, verticalListSortingStrategy } from "@dnd-kit/sortable";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";

import SidePanel from "./SidePanel";
import MobileView from "./MobileView";
import Validations from "./Validations";
import { useGetActiveFields, useUpsertCompanyForm } from "./hooks";
import FormHeader from "./FormHeader";
import { useNavigate, useParams } from "react-router-dom";
import { TOAST_TYPE, arrayInsert, createConfirmAlert, createToast } from "../../../common/utilities/helper";
import Button from "../../../common/components/extra/Button";
import SectionCollapseError from "../../../common/components/extra/section/SectionCollapseError";
import { FORM_FIELD_TYPE } from "../../../common/utilities/const";
import Navigation from "../../../common/classes/Navigation";

function UpsertCompanyForm() {
    const mouseSensor = useSensor(MouseSensor, { activationConstraint: { distance: 8 } });
    const touchSensor = useSensor(TouchSensor, { activationConstraint: { delay: 200, tolerance: 6 } });
    const pointerSensor = useSensor(PointerSensor);
    const keyboardSensor = useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates });

    const sensors = useSensors(mouseSensor, pointerSensor, touchSensor, keyboardSensor);

    const [customError, setCustomError] = useState("");
    const [selectedItem, setSelectedItem] = useState(null);
    const [droppedItems, setDroppedItems] = useState([]);
    const [draggedItem, setDraggedItem] = useState(null);

    const navigate = useNavigate();
    const params = useParams();
    const updateId = params.id;

    const [form, setForm, { upsert, isUpserting, isGettingForm, hasChangesToBaseForm }] = useUpsertCompanyForm(updateId, (fetchedForms) => {
        setDroppedItems(fetchedForms.fields);
    });
    const [fields, isGettingFields, { onSearch }] = useGetActiveFields();

    const hasFieldsChanges = !!updateId && !isEqual(form.fields, droppedItems);
    const preventDrop = isUpserting || isGettingFields;
    const disableSave = (!!updateId && !hasChangesToBaseForm && !hasFieldsChanges) || !droppedItems.length || preventDrop;

    const checkDropErrors = (arr) => arr.map((item) => ({ id: item.id, error: item.error })).filter((item) => !!item.error);

    const handleDragStart = (e) => {
        setDraggedItem({ id: e.active.id, data: e.active?.data?.current });
    };

    const handleDragEnd = (e) => {
        const { active, over, collisions } = e;
        const isDroppableArea = !!collisions?.length;

        if (!isDroppableArea || preventDrop) return;

        const lastIndex = droppedItems.length - 1;
        // "xxx" is the temp id for the placeholder inside of the mobile view
        const isMainDropZone = over.id == "main-drop-zone" || over.id == "xxx";
        const isAlreadyExist = droppedItems.some((dropped) => dropped.id == active.id);

        if (isMainDropZone && !isAlreadyExist) {
            setDroppedItems(droppedItems.concat(draggedItem));
        } else {
            if (active && over && active.id !== over.id) {
                const oldIndex = droppedItems.map((it) => it.id).indexOf(draggedItem.id);
                const newIndex = droppedItems.map((it) => it.id).indexOf(over.id);
                const isNew = oldIndex < 0;
                if (isNew && !isAlreadyExist) {
                    const newArr = arrayInsert(droppedItems, newIndex, draggedItem);
                    setDroppedItems(newArr);
                } else {
                    const noNewIndex = newIndex < 0;
                    const newArr = arraySwap(droppedItems, oldIndex, noNewIndex ? lastIndex : newIndex);
                    setDroppedItems(newArr);
                }
            }
        }
        setDraggedItem(null);
    };

    const handleRemoveItem = (removeItem) => {
        setDroppedItems(droppedItems.filter((item) => item.id != removeItem.id));
        if (selectedItem && removeItem.id == selectedItem.id) {
            setSelectedItem(null);
        }
    };

    const handleChange = (key, value) => {
        setDroppedItems(
            droppedItems.map((item) => {
                const clone = cloneDeep(item);
                if (clone.id == selectedItem?.id) {
                    if (!clone?.data?.validation?.[key]) {
                        clone.data.validation = { ...item.data.validation, [key]: "" };
                    }
                    clone.data.validation[key] = value;
                }
                return clone;
            })
        );
    };

    const checkForDropErrors = () => {
        if (!form.name) {
            setCustomError("Form name is required!");
            createToast("Form name is required!", TOAST_TYPE.ERROR);
            return { hasError: true };
        }
        if (!form.type) {
            setCustomError("Form type is required!");
            createToast("Form type is required!", TOAST_TYPE.ERROR);
            return { hasError: true };
        }
        if (!droppedItems.length) {
            setCustomError("At least one element is required!");
            createToast("At least one element is required!", TOAST_TYPE.ERROR);
            return { hasError: true };
        }
        const requiredTextvalue = droppedItems
            .filter((item) => item.data.group == "text" && item.data.validation && !item?.data?.validation?.value)
            .map((item) => item.id);
        const requiredLabels = droppedItems
            .filter((item) => item.data.group != "text" && item.data.validation && !item?.data?.validation?.label)
            .map((item) => item.id);
        const requiredValues = droppedItems
            .filter(
                (item) =>
                    item.data.group == "multiple choice" &&
                    item.data.type !== FORM_FIELD_TYPE.TOGGLE &&
                    item.data.validation &&
                    !item.data.validation?.value?.length
            )
            .map((item) => item.id);
        const newDrop = droppedItems.map((item) => {
            const errors = [];
            if (requiredLabels.includes(item.id)) {
                if (!item.error) {
                    item.error = { message: "" };
                }
                errors.push("Label");
            }
            if (requiredValues.includes(item.id) || requiredTextvalue.includes(item.id)) {
                if (!item.error) {
                    item.error = { message: "" };
                }
                errors.push("Value");
            }
            if (errors.length) {
                item.error.message = `${errors.join(" and ")} is required!`;
            } else {
                item.error && delete item.error;
            }
            return item;
        });
        const hasError = !!checkDropErrors(newDrop).length;
        setDroppedItems(newDrop);
        setForm({ fields: newDrop });
        return { newDrop, hasError };
    };

    const handleSubmit = async (newDrop) => {
        const result = await upsert({ ...form, fields: newDrop });
        if (result.error) {
            setCustomError(result.error?.message || `Failed to ${updateId ? "create" : "update"} form.`);
        } else {
            navigate(Navigation.Routes.COMPANY_FORM.path);
        }
    };

    const handleReset = () => setDroppedItems([]);

    return (
        <DndContext onDragStart={handleDragStart} onDragEnd={handleDragEnd} sensors={sensors} collisionDetection={closestCenter}>
            <SortableContext items={droppedItems} strategy={verticalListSortingStrategy}>
                <div className="tk-company-forms-upsert__inner">
                    <SidePanel fields={fields} onSearch={onSearch} isLoading={isGettingFields} hasDroppedItems={!!droppedItems.length} />
                    <form
                        className="tk-company-forms-upsert__content"
                        onSubmit={(e) => {
                            e.preventDefault();
                            const result = checkForDropErrors();
                            if (result?.hasError) return;
                            createConfirmAlert({
                                title: "Save Changes",
                                content: `This action will ${updateId ? "update" : "create"} the current form.`,
                                onConfirm: async (close) => {
                                    close();
                                    handleSubmit(result.newDrop);
                                }
                            });
                        }}
                    >
                        <FormHeader name={form?.name} type={form?.type} onChange={setForm} status={form?.status} isLoading={isGettingForm} />
                        <div className="tk-company-forms-upsert__body">
                            <div className="tk-company-forms-upsert__body__left">
                                <MobileView
                                    activeId={draggedItem && draggedItem.id}
                                    data={draggedItem && draggedItem.data}
                                    items={droppedItems}
                                    onRemove={handleRemoveItem}
                                    onSelect={setSelectedItem}
                                    selected={selectedItem}
                                    setDroppedItems={setDroppedItems}
                                    isLoading={isUpserting || isGettingForm}
                                    loadingMessage={isGettingForm ? "Please wait, fetching data..." : "Please wait, saving data..."}
                                    isDisabled={isGettingFields}
                                />
                                <span className="mobile-view-title fade">Mobile View</span>
                            </div>
                            <div className="tk-company-forms-upsert__body__right flex column gap-1">
                                <SectionCollapseError show={!!checkDropErrors(droppedItems).length || !!customError}>
                                    {customError || "Invalid fields detected. Please complete all required fields."}
                                </SectionCollapseError>
                                {selectedItem && (
                                    <Validations
                                        key={selectedItem.id}
                                        id={selectedItem.id}
                                        data={selectedItem.data}
                                        onCancel={() => setSelectedItem(null)}
                                        onChange={handleChange}
                                    />
                                )}
                            </div>
                            <div className="main-controls flex gap-05">
                                <Button
                                    className="danger"
                                    options={{ type: "button" }}
                                    onClick={() =>
                                        createConfirmAlert({
                                            title: "Reset",
                                            content: "Are you sure you want to clear all dropped items? This cannot be undone.",
                                            onConfirm: async (close) => {
                                                close();
                                                handleReset();
                                            }
                                        })
                                    }
                                    disabled={preventDrop || !droppedItems.length}
                                    small
                                    transparent
                                >
                                    <span className="flex center">Reset</span>
                                </Button>
                                <Button options={{ type: "submit" }} className="primary" disabled={disableSave} isLoading={isUpserting} small>
                                    <span className="flex center">Save</span>
                                </Button>
                            </div>
                        </div>
                    </form>
                </div>
            </SortableContext>
        </DndContext>
    );
}

export default UpsertCompanyForm;
