import React, { useState } from "react";
import PropTypes from "prop-types";
import DeleteIcon from "@mui/icons-material/Delete";
import CancelIcon from "@mui/icons-material/DoDisturb";
import FormSection from "../../../common/components/extra/FormSection";
import Input from "../../../common/components/extra/form/Input";
import { useGetEmployeeDeductions, useManageActions } from "./hooks";
import BaseViewModal from "../../../common/components/layout/modalViewUpdateLayout/BaseViewModal";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectUserSetting } from "../../common/slice";
import {
    createClass,
    createConfirmAlert,
    sanitizeTimestamp,
    sanitizeWords,
    toReadableFromDate,
    toTimeWithTimeZone
} from "../../../common/utilities/helper";
import { BASE_CLASS, CUSTOM_ACTION, DEDUCTION_STATUS, FIELD } from "./const";
import Button from "../../../common/components/extra/Button";
import ViewEmployeeModal from "../employees/ViewModal";
import SectionCollapseInfo from "../../../common/components/extra/section/SectionCollapseInfo";
import CancelDeductionModal from "./CancelDeductionModal";
import { createStatusTag } from "./helper";

const { PAYMENT_TYPE, TYPE, AMOUNT, START_DATE, END_DATE, STATUS, CONFIRMED_DATE, NOTES } = FIELD;

function ViewModal({ open, onClose, onEdit, id }) {
    const [viewEmployee, setViewEmployee] = useState(false);
    const [current, { isLoading, config }] = useGetEmployeeDeductions(id);

    const { onAction: handleAction, cancelId, setCancelId } = useManageActions();

    const setting = useAppSelector(selectUserSetting);
    const timezone = setting.timezone;
    const currency = setting.currency;
    const isCancelled = current.status == DEDUCTION_STATUS.CANCELED;
    const isConfirmed = !!current?.[CONFIRMED_DATE];
    const disableEdit = isLoading || !current;
    const showCancel = !isLoading && !isConfirmed && !isCancelled;
    const showDelete = !isLoading && !isConfirmed;
    const COMMON_PROPS = { readOnly: true, isLoading };

    const handleViewEmployee = () => setViewEmployee(true);

    const renderEmployee = () => {
        return (
            <div className="flex" style={{ alignItems: "center" }}>
                <span>{sanitizeWords(current?.employee?.fullName)}</span>
                <Button
                    options={{ style: { textAlign: "right", paddingRight: 0, textDecoration: "underline" } }}
                    onClick={handleViewEmployee}
                    transparent
                    small
                >
                    View
                </Button>
            </div>
        );
    };

    const renderHeadExtra = (
        <div className="flex gap-05">
            {showCancel && (
                <Button className="danger" beforeExtra={<CancelIcon />} onClick={() => handleAction({ id }, CUSTOM_ACTION.CANCEL)} mini>
                    <span>Cancel</span>
                </Button>
            )}
            {showDelete && (
                <Button
                    className="danger"
                    beforeExtra={<DeleteIcon />}
                    onClick={() =>
                        createConfirmAlert({
                            title: "Remove Deduction",
                            content: "Are you sure you want to remove this record? This cannot be undone.",
                            onConfirm: async (close) => {
                                close();
                                handleAction({ id }, CUSTOM_ACTION.REMOVE).then(onClose);
                            }
                        })
                    }
                    mini
                >
                    <span>Remove</span>
                </Button>
            )}
        </div>
    );
    return (
        <BaseViewModal open={open} onClose={onClose} onEdit={onEdit} disableEdit={disableEdit} headExtra={renderHeadExtra}>
            <div className={createClass("__modal-content-view flex column gap-1", BASE_CLASS)}>
                <FormSection header="Deduction Details">
                    <Input label="Employee" renderValue={renderEmployee()} {...COMMON_PROPS} />
                    <Input label="Starts In" renderValue={toTimeWithTimeZone(current?.[START_DATE])?.format?.("MMM YYYY")} {...COMMON_PROPS} />
                    {!config.isOneTimePayment && (
                        <Input label="Ends In" renderValue={toTimeWithTimeZone(current?.[END_DATE])?.format?.("MMM YYYY")} {...COMMON_PROPS} />
                    )}
                    <Input label="Amount" renderValue={sanitizeWords(config?.[AMOUNT])} {...COMMON_PROPS} afterExtra={<span>{currency}</span>} />
                    <Input label="Type" renderValue={sanitizeWords(current?.[TYPE])} {...COMMON_PROPS} />
                    <Input label="Payment Type" renderValue={sanitizeWords(current?.[PAYMENT_TYPE])} {...COMMON_PROPS} />
                    <Input
                        label="Note"
                        renderValue={<p style={{ marginLeft: "2rem" }}>{current && sanitizeWords(current?.[NOTES])}</p>}
                        {...COMMON_PROPS}
                    />
                </FormSection>
                <FormSection header="Record Details">
                    <Input label="Status" renderValue={createStatusTag(current?.[STATUS])} {...COMMON_PROPS} />
                    <Input label="Confirmed Date" renderValue={toReadableFromDate(current?.[CONFIRMED_DATE], timezone)} {...COMMON_PROPS} />
                    <Input label="Created At" renderValue={current && toReadableFromDate(current?.createdAt, timezone)} {...COMMON_PROPS} />
                    <Input
                        label={isCancelled ? "Cancelled At" : "Updated At"}
                        renderValue={sanitizeTimestamp(current?.updatedAt, current?.createdAt, timezone)}
                        {...COMMON_PROPS}
                    />
                </FormSection>
                {!isLoading && !showDelete && (
                    <SectionCollapseInfo title="Reminder">
                        Delete option is unavailable once the record is confirmed or included in the salary computation.
                    </SectionCollapseInfo>
                )}
            </div>
            {!!viewEmployee && (
                <ViewEmployeeModal open={viewEmployee} onClose={() => setViewEmployee(false)} data={current.employee} setting={setting} readOnly />
            )}

            {!!cancelId && (
                <CancelDeductionModal open={!!cancelId} onClose={() => setCancelId(null)} onFinish={() => setCancelId(null)} id={cancelId} />
            )}
        </BaseViewModal>
    );
}

ViewModal.propTypes = {
    open: PropTypes.bool,
    id: PropTypes.any,
    onClose: PropTypes.func,
    onEdit: PropTypes.func
};

export default ViewModal;
