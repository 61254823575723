import React from "react";

function MobileAddress() {
    return (
        <div className="mobile-input mobile-input--text-input">
            <div className="input-box flex center">
                <span className="fade italic">Address</span>
            </div>
        </div>
    );
}

export default MobileAddress;
