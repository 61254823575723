import React from "react";
import PropTypes from "prop-types";
import { FORM_FIELD_TYPE } from "../../../common/utilities/const";

function DroppedItem({ children, data, onSelect, inline, error }) {
    if (!data.id) {
        return <></>;
    }

    const isTextGroup = data.group == "text";

    return (
        <div className={`dropped-item ${inline ? "dropped-item--inline" : ""}`.trim()} onClick={onSelect}>
            <div className="dropped-item__header text-ellipsis">
                {!isTextGroup && (
                    <div className="dropped-item__label">
                        <div className="label-title span fade">Label:</div>
                        <span>
                            {data.validation.label}
                            {data.validation.label && <span className="danger-color"> {data.validation.required && "*"}</span>}
                        </span>
                    </div>
                )}
            </div>
            <div className="dropped-item__content">{children}</div>
            {error && (
                <span className="danger-color" style={{ marginBottom: ".4rem" }}>
                    Error: {error?.message || "Please supply the required fields!"}
                </span>
            )}
        </div>
    );
}

export default DroppedItem;

DroppedItem.propTypes = {
    inline: PropTypes.bool,
    children: PropTypes.any,
    data: PropTypes.object,
    type: PropTypes.oneOf(Object.values(FORM_FIELD_TYPE)),
    onSelect: PropTypes.func,
    error: PropTypes.shape({
        message: PropTypes.string
    })
};
