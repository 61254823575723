import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { selectCurrent, selectTableConfig, setCurrent } from "./slice";
import { useAppDispatch, useAppSelector } from "../../../common/hooks/reduxHooks";
import CreateTableHeaders from "./CreateTableHeaders";
import TableView from "../../../common/components/extra/table/TableView";
import { useManageActions, usePaginateEmployeePenalties } from "./hooks";
import ViewModal from "./ViewModal";
import UpdateModal from "./UpdateModal";
import CancelPenaltyModal from "./CancelPenaltyModal";

function EmployeePenalties({ openAddModal, onAddModalClose, styles, height, ...rest }) {
    const [openEditModal, setOpenEditModal] = useState(openAddModal);

    const [data, { isLoading: isPaginating, fetch }] = usePaginateEmployeePenalties();
    const { onAction: handleAction, cancelId, setCancelId, openViewModal, setOpenViewModal } = useManageActions();

    const dispatch = useAppDispatch();
    const tableConfig = useAppSelector(selectTableConfig);
    const current = useAppSelector(selectCurrent);

    const showViewModal = !!(openViewModal && current);
    const showUpdateModal = openEditModal;

    useEffect(() => {
        if (openAddModal && openAddModal !== openEditModal) {
            setOpenEditModal(openAddModal);
            dispatch(setCurrent(null));
        }
    }, [openAddModal]);

    const handleModalBack = () => {
        setOpenViewModal(true);
        setOpenEditModal(false);
    };

    const handleModalEdit = () => {
        setOpenViewModal(false);
        setOpenEditModal(true);
    };

    const handleFinish = (newData) => {
        fetch();
        dispatch(setCurrent(newData));
        typeof onAddModalClose === "function" && onAddModalClose();
    };

    const handleViewModalClose = () => setOpenViewModal(false);

    const handleUpdateModalClose = () => {
        typeof onAddModalClose === "function" && onAddModalClose();
        setOpenEditModal(false);
    };

    return (
        <>
            <TableView
                data={data}
                headers={CreateTableHeaders({ onAction: handleAction })}
                refetch={fetch}
                tableConfig={tableConfig}
                isLoading={isPaginating}
                height={height}
                styles={styles}
                {...rest}
            />
            {showViewModal && <ViewModal id={current?.id} open={showViewModal} onClose={handleViewModalClose} onEdit={handleModalEdit} />}
            {showUpdateModal && (
                <UpdateModal
                    onBack={handleModalBack}
                    open={showUpdateModal}
                    onClose={handleUpdateModalClose}
                    onFinish={handleFinish}
                    id={current?.id}
                />
            )}
            {!!cancelId && (
                <CancelPenaltyModal
                    open={!!cancelId}
                    onClose={() => setCancelId(null)}
                    onFinish={() => {
                        setCancelId(null);
                        fetch();
                    }}
                    id={cancelId}
                />
            )}
        </>
    );
}

EmployeePenalties.propTypes = {
    openAddModal: PropTypes.bool,
    onAddModalClose: PropTypes.func,
    height: PropTypes.number,
    styles: PropTypes.shape({
        pagination: PropTypes.shape({
            parent: PropTypes.object
        }),
        table: PropTypes.shape({
            parent: PropTypes.object,
            body: PropTypes.object
        })
    })
};

export default EmployeePenalties;
