import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import isEqual from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";
import { selectEmployeeRoles, setEmployeeRoles } from "./slice";
import { useCreateEmployeeMutation, useEmployeeUploadFilesMutation, useLoadEmployeeRolesMutation, useUpdateEmployeeMutation } from "./api";
import ViewModalSub from "./ViewModalSub";
import { selectBanks, updateUser } from "../../common/slice";
import { PASSWORD_KEYS_TO_USE, VIEW_MODAL_TYPE } from "./const";
import SiteSelectLazy from "../companySites/SiteSelectLazy";
import { useAppDispatch, useAppSelector } from "../../../common/hooks/reduxHooks";
import useFetchCountries from "../../../common/hooks/useFetchCountries";
import Loader from "../../../common/components/extra/Loader";
import Divider from "../../../common/components/extra/Divider";
import Input from "../../../common/components/extra/Input";
import Select from "../../../common/components/extra/select/Select";
import { ReactComponent as ID_SVG } from "../../../assets/images/id-icon.svg";
import { ReactComponent as PHOTO_SVG } from "../../../assets/images/photo-icon.svg";
import { ReactComponent as PASSPORT_SVG } from "../../../assets/images/passport-icon.svg";
import { ReactComponent as CONTRACT_SVG } from "../../../assets/images/contract-icon.svg";
import Tag from "../../../common/components/extra/Tag";
import SectionCollapseWarning from "../../../common/components/extra/section/SectionCollapseWarning";
import WorkShiftSelectLazy from "../employeeWorkShift/WorkShiftSelectLazy";
import DepartmentsSelectLazy from "../departments/DepartmentsSelectLazy";
import DesignationsSelectLazy from "../designations/DesignationsSelectLazy";
import {
    CONTRACT_TERM,
    CONTRACT_TYPE,
    DAY_ABBREVIATION,
    GENDER,
    MARITAL_STATUS,
    SALARY_FREQUENCY,
    EMPLOYEE_FILES,
    SCHEDULE_TYPE
} from "../../../common/utilities/const";
import {
    TOAST_TYPE,
    createConfirmAlert,
    createToast,
    flattenObject,
    isPhoneValid,
    sanitizeWords,
    separateLastCharacters,
    toDate,
    toNativeDate,
    toReadableSelectOptions,
    transformStringToObject
} from "../../../common/utilities/helper";
import BaseUpdateModal from "../../../common/components/layout/modalViewUpdateLayout/BaseUpdateModal";
import SectionCollapseError from "../../../common/components/extra/section/SectionCollapseError";

const BASE_CLASS = "tk-employees__modal-content-update";

const createClass = (newStr = "") => `${BASE_CLASS}${newStr}`;

const FILE_SIZE_LIMIT = 3; // 3mb

const DATE_FIELDS = [
    "birthdate",
    "residenceID_expiration",
    "passportID_expiration",
    "visaID_expiration",
    "EmployeeContract.hiring_date",
    "EmployeeContract.joining_date",
    "EmployeeContract.end_date"
];

const EXCLUDE_FIELDS_FROM_SAVE = [
    "Role",
    "CompanyDepartment",
    "CompanyDesignation",
    "EmployeeWorkShift",
    "CompanySites",
    "device",
    "createdAt",
    "updatedAt",
    "uploads",
    "photo",
    "mobileCode"
];

const EXTRACT_ID_FROM_KEYS = ["CompanyDepartment", "CompanyDesignation"];

const transformDateFieldsToProperDate = (rawData = {}, setting) => {
    const flattened = flattenObject(rawData);
    let newObj = {};
    for (const key in flattened) {
        if (Object.hasOwnProperty.call(flattened, key)) {
            const value = flattened[key];
            const isDate = DATE_FIELDS.includes(key);
            if (isDate) {
                flattened[key] = toDate(value, setting.timezone);
            }
            newObj = transformStringToObject(key, value, rawData);
        }
    }
    return newObj;
};

function UpdateModal({ open, onChange, onClose, onBack, onFinish, data = {}, setting, photo }) {
    const [error, setError] = useState(null);
    const [avatar, setAvatar] = useState({ photo, isLoading: false });
    const [form, setForm] = useState({
        EmployeeBankDetail: {
            short_name: "",
            account_number: "",
            iban: ""
        },
        EmployeeContract: {
            contract_term: "",
            contract_type: "",
            hiring_date: "",
            joining_date: "",
            end_date: "",
            period: "",
            probation_period: "",
            basic_amount: "",
            salary_frequency: "",
            allowance: {
                accomodation: "",
                communication: "",
                education: "",
                food: "",
                air_ticket: "",
                transportation: "",
                other: ""
            },
            job_description: ""
        },
        CompanyDepartment: {
            id: "",
            title: ""
        },
        CompanyDesignation: {
            id: "",
            title: ""
        },
        companyID: "",
        first_name: "",
        last_name: "",
        gender: "",
        nationality: "",
        birthdate: "",
        marital_status: "",
        mobile_number: "",
        email_address: "",
        residenceID: "",
        residenceID_expiration: "",
        passportID: "",
        passportID_expiration: "",
        visaID: "",
        visaID_expiration: "",
        uploads: Object.values(EMPLOYEE_FILES).reduce((prev, curr) => ({ ...prev, [curr.key]: null }), {}),
        number_weekly_off_days: 1,
        off_days: [DAY_ABBREVIATION.FRIDAY],
        work_shift_id: "",
        mobileCode: "",
        role_id: "",
        passwordKeyToUse: "",
        ...(data || {})
    });
    const [viewObject, setViewObject] = useState({ type: null, data: null });

    const [createEmployee, { isLoading: createEmployeeLoading }] = useCreateEmployeeMutation();
    const [updateEmployee, { isLoading: updateEmployeeLoading }] = useUpdateEmployeeMutation();
    const [uploadEmployeeFiles, { isLoading: uploadFilesLoading }] = useEmployeeUploadFilesMutation();
    const [loadEmployeeRoles, { isLoading: employeeRolesLoading }] = useLoadEmployeeRolesMutation();

    const dispatch = useAppDispatch();
    const banks = useAppSelector(selectBanks);
    const employeeRoles = useAppSelector(selectEmployeeRoles);
    const fetchedCountries = useFetchCountries({ rtl: true, mobile: true, isDenonym: true });

    const countries = fetchedCountries.nationalities;
    const codes = fetchedCountries.codes;
    const isLoading = updateEmployeeLoading || uploadFilesLoading || createEmployeeLoading || employeeRolesLoading;
    const isCreate = !data;

    const fetchRoles = async () => {
        const response = await loadEmployeeRoles();
        if (response.data) {
            dispatch(setEmployeeRoles(response.data.data));
        }
        if (response.error) {
            createConfirmAlert("Failed to get roles for employees. Please try again later or contact support.");
        }
    };

    useEffect(() => {
        if (!employeeRoles.length) {
            fetchRoles();
        }
    }, []);

    const uploadFiles = async (uploads) => {
        if (Object.values(uploads).some((f) => f && typeof f !== "boolean")) {
            const formData = new FormData();
            for (const field in uploads) {
                if (Object.hasOwnProperty.call(uploads, field)) {
                    const file = uploads[field];
                    file && formData.append(field, file);
                }
            }
            const result = await uploadEmployeeFiles({ body: formData, extraPath: form.residenceID, formData: true });
            if (result.error) {
                throw new Error(result.error.data);
            }
            return result;
        }
    };

    const handleSave = async () => {
        try {
            let result = {};
            let clonedform = cloneDeep(form);

            if (clonedform.mobileCode) {
                const newMobileNumber = clonedform.mobileCode + clonedform.mobile_number;
                const isValid = isPhoneValid(newMobileNumber).isValid;
                if (!isValid) {
                    const message = "Mobile number is not valid.";
                    setError({ message });
                    throw new Error(message);
                } else {
                    clonedform.mobile_number = newMobileNumber;
                }
            }
            const hasWorkShift = clonedform.EmployeeWorkShift && !!Object.keys(clonedform.EmployeeWorkShift).length;
            const workShiftId = clonedform.EmployeeWorkShift && clonedform.EmployeeWorkShift.id;
            const siteIds = (clonedform.CompanySites || []).map((cs) => cs.id);
            const dateSiteIds = (data?.CompanySites || []).map((cs) => cs.id);
            const uploads = clonedform.uploads;

            EXCLUDE_FIELDS_FROM_SAVE.forEach((field) => {
                const hasval = clonedform[field];
                if (EXTRACT_ID_FROM_KEYS.includes(field) && hasval?.id) {
                    if (field === EXTRACT_ID_FROM_KEYS[0]) {
                        clonedform.department_id = hasval.id;
                    } else if (field === EXTRACT_ID_FROM_KEYS[1]) {
                        clonedform.designation_id = hasval.id;
                    }
                }
                hasval && delete clonedform[field];
            });

            if (!clonedform[clonedform.passwordKeyToUse]) {
                const currentPassKeyToUse = passwordOpt.find((opt) => opt.value == clonedform.passwordKeyToUse) || "";
                const message = `The key that you will use for the password should have a value. Found Empty for ${currentPassKeyToUse.clean}.`;
                setError({ message });
                throw new Error(message);
            }

            clonedform = transformDateFieldsToProperDate(clonedform, setting);

            const hasUploaded = await uploadFiles(uploads);

            if (hasWorkShift && form.workShiftId !== workShiftId) {
                clonedform.work_shift_id = workShiftId;
            } else {
                clonedform.work_shift_id = null;
            }

            if (!isEqual(siteIds, dateSiteIds)) {
                clonedform.site_ids = siteIds;
            }

            delete form.isOnShift;

            if (!isCreate) {
                clonedform.hasUploaded = !!hasUploaded;
                result = await updateEmployee({ body: clonedform, extraPath: data.id });
            } else {
                result = await createEmployee({ body: clonedform });
            }
            if (result.error) {
                throw new Error(result.error?.data?.message);
            }
            if (result.data) {
                if (result.data?.data) {
                    if (isCreate) {
                        const totalEmployees = result.data?.data?.employeeCount;
                        dispatch(updateUser({ totalEmployees }));
                    }
                    createToast(
                        `Employee [${sanitizeWords(clonedform?.first_name)} ${sanitizeWords(clonedform?.last_name)}] ${
                            isCreate ? "created" : "updated"
                        } succesfully.`,
                        TOAST_TYPE.SUCCESS
                    );
                } else {
                    createToast(result.data.message, TOAST_TYPE.SUCCESS);
                }
            }
            if (result?.data?.data && typeof onFinish === "function") {
                onFinish(result.data.data);
            }
            setError(null);
        } catch (error) {
            setError({ message: error?.message });
            return createToast(
                `Failed to ${!isCreate ? "update" : "create"} Employee: ${sanitizeWords(form.first_name)} ${sanitizeWords(form.last_name)}!. ${
                    error?.message || "Please try again later or contact support."
                } `,
                TOAST_TYPE.ERROR
            );
        }
    };

    const createGroup = ({ title, body, hiddenTitle }) => (
        <div className={createClass("__group")}>
            <div className={createClass("__group__header")}>
                <h3 style={{ opacity: hiddenTitle ? 0 : 1 }}>{title}</h3>
            </div>
            <div className={createClass("__group__body") + " flex column gap-05"}>{body}</div>
        </div>
    );

    const createOptions = () => {
        const rolesOption = employeeRoles.map((r) => ({
            ...r,
            value: r.id,
            label: (
                <div className="flex gap-05" style={{ alignItems: "center" }}>
                    <Tag>{sanitizeWords(r.name)}</Tag>
                </div>
            )
        }));
        const contractTypeOption = toReadableSelectOptions(CONTRACT_TYPE);
        const contractTermOption = toReadableSelectOptions(CONTRACT_TERM);
        const salaryFreqOption = toReadableSelectOptions(SALARY_FREQUENCY);
        const genderOption = toReadableSelectOptions(GENDER);
        const offdaysOption = toReadableSelectOptions(DAY_ABBREVIATION);
        const maritalStatusOption = toReadableSelectOptions(MARITAL_STATUS);
        const scheduleTypeOption = toReadableSelectOptions(SCHEDULE_TYPE);
        const passwordOpt = Object.values(PASSWORD_KEYS_TO_USE).map((opt) => ({
            ...opt,
            clean: separateLastCharacters(sanitizeWords(opt.value), 2, "id").toUpperCase(),
            label: <Tag className="brown">{separateLastCharacters(sanitizeWords(opt.value), 2, "id")}</Tag>
        }));

        return {
            scheduleTypeOption,
            contractTypeOption,
            contractTermOption,
            salaryFreqOption,
            genderOption,
            offdaysOption,
            maritalStatusOption,
            rolesOption,
            passwordOpt
        };
    };

    const {
        contractTypeOption,
        contractTermOption,
        salaryFreqOption,
        genderOption,
        offdaysOption,
        maritalStatusOption,
        rolesOption,
        scheduleTypeOption,
        passwordOpt
    } = createOptions();

    const handleFormChange = (config = {}) => setForm({ ...form, ...config });

    const handlePhotoChange = (e) => {
        const files = e.target?.files;
        if (files) {
            const file = e.target.files?.[0];
            if (file) {
                setAvatar((prev) => ({ ...prev, isLoading: true }));
                const reader = new FileReader();
                reader.onloadend = () => setAvatar({ photo: reader.result, isLoading: false });
                reader.readAsDataURL(file);
            } else {
                setAvatar({ photo: false, isLoading: false });
            }
        }
    };

    const handleChange = (e) => {
        let name = e.target.name,
            value = e.target.value,
            mobileCode = e.target?.mobileCode,
            config = { ...form };

        const isChainedObject = name.split(".").length > 1;
        const isWOffDays = name === "number_weekly_off_days";
        // reset off days when weekly off days is changed
        if (isWOffDays && config["off_days"]?.length) {
            config["off_days"] = [];
        }
        if (e.target?.files) {
            value = e.target.files[0];
        }
        if (isChainedObject) {
            const parsedObject = transformStringToObject(name, value, form);
            config = parsedObject;
        } else {
            if (name === "department_code") {
                config.designation = "";
            }
            config[name] = value;
        }
        if (mobileCode) {
            config.mobileCode = mobileCode;
        }
        config.grade && (config.grade = config.grade.toUpperCase());
        handleFormChange(config);
    };

    const handleViewChange = (newObject = {}) => setViewObject({ ...viewObject, ...newObject });

    const imageAccepts = "image/png,image/jpg,image/jpeg";
    const fileAccepts = "application/pdf";
    const imageFileAccepts = imageAccepts + "," + fileAccepts;
    const renderCurrencyExtra = <span>{setting.currency}</span>;
    const isMobileNumberValid = isPhoneValid(form.mobile_number);
    const mobileCountryCode = isMobileNumberValid.isValid && isMobileNumberValid.countryCode;
    const phoneNumberWithoutCode =
        (isMobileNumberValid.isValid && isMobileNumberValid.phoneNumber.split(mobileCountryCode).pop()) || form.mobile_number;
    const isShiftEditable = !data?.isOnShift;

    const isChangesAffectSalaryComputation = () => {
        const keysAffectSalaryComputation = ["schedule_type"];
        return keysAffectSalaryComputation.some((key) => !isEqual(form[key], data[key]));
    };

    const createConfirmAlertContent = () => {
        let def = `Are you sure you want to ${isCreate ? "create" : "update"} this employee? This cannot be undone.`;
        if (!isCreate && isChangesAffectSalaryComputation()) {
            def =
                "Are you sure you want to update this employee? Changing the schedule type will affect the salary computation. This cannot be undone.";
        }
        return def;
    };

    const avatarphoto = avatar.isLoading ? (
        <Loader style={{ width: "auto" }} relative />
    ) : avatar.photo ? (
        <img
            src={avatar.photo}
            alt=""
            width={10}
            height={10}
            className="responsive-img avatar medium"
            style={{ margin: "unset", height: "2.5rem", width: "3rem" }}
        />
    ) : (
        <PHOTO_SVG />
    );

    return (
        <BaseUpdateModal
            open={open}
            onChange={onChange}
            onClose={onClose}
            onBack={(!isCreate && onBack) || null}
            onSave={() =>
                createConfirmAlert({
                    title: !isCreate ? "Update Employee" : "Create Employee",
                    content: createConfirmAlertContent(),
                    onConfirm: async (close) => {
                        close();
                        const hasError = await handleSave();
                        if (!hasError) {
                            onBack();
                        }
                    }
                })
            }
            isLoading={isLoading}
            disableSave={isEqual(form, { mobileCode: "", ...data })}
            styles={{ content: { width: "70vw" } }}
            isForm
        >
            <div className={createClass()}>
                <div className={createClass("__inner")}>
                    <SectionCollapseWarning show={!isCreate && !isShiftEditable}>
                        Editing capabilities will be limited because employee currently in shift.
                    </SectionCollapseWarning>
                    <SectionCollapseError show={!!error}>{error && error.message}</SectionCollapseError>

                    <div className={createClass("__inner-row")} style={{ marginBottom: "2rem" }}>
                        {createGroup({
                            title: "Mobile App Access",
                            body: (
                                <>
                                    <Select
                                        label="Password to Use"
                                        value={passwordOpt.find((opt) => opt.value == form.passwordKeyToUse) || ""}
                                        options={passwordOpt}
                                        onChange={(val) => handleChange({ target: { name: "passwordKeyToUse", value: val.value } })}
                                        menuPlacement="bottom"
                                        isOutlined
                                        disabledOutline
                                        required
                                    />
                                    <Select
                                        label="App Role"
                                        options={rolesOption}
                                        value={rolesOption.find((r) => r.value === form.role_id)}
                                        onChange={(val) => handleChange({ target: { name: "role_id", value: val.value } })}
                                        menuPlacement="top"
                                        isOutlined
                                        disabledOutline
                                        required
                                    />
                                </>
                            )
                        })}
                    </div>
                    <div className={createClass("__inner-row")} style={{ marginBottom: "2rem" }}>
                        {createGroup({
                            title: "Personal Information",
                            body: (
                                <>
                                    <Input
                                        label="Add Photo"
                                        name="uploads.photo"
                                        icon={avatarphoto}
                                        onChange={(e) => {
                                            handlePhotoChange(e);
                                            handleChange(e);
                                        }}
                                        value={form.uploads[EMPLOYEE_FILES.PHOTO.key]}
                                        accept={imageAccepts}
                                        sizeLimit={FILE_SIZE_LIMIT}
                                        upload
                                        hide
                                    />
                                    <Input label="Employee No." name="companyID" onChange={handleChange} value={form.companyID} />
                                    <Input label="First Name" name="first_name" onChange={handleChange} value={form.first_name} required />
                                    <Input label="Last Name" name="last_name" onChange={handleChange} value={form.last_name} required />
                                    <Input
                                        label="Mobile"
                                        name="mobile_number"
                                        style={{ minWidth: "8rem" }}
                                        mobile={{ codes, code: mobileCountryCode }}
                                        onChange={handleChange}
                                        value={phoneNumberWithoutCode}
                                        minLength={8}
                                    />
                                    <Input
                                        label="Email Address"
                                        name="email_address"
                                        type="email"
                                        value={form.email_address}
                                        onChange={handleChange}
                                    />
                                    <Select
                                        label="Gender"
                                        name="gender"
                                        options={genderOption}
                                        onChange={(val) => handleChange({ target: { name: "gender", value: val.value } })}
                                        value={genderOption.find((gen) => gen.value === form?.gender)}
                                        isOutlined
                                        disabledOutline
                                        required
                                    />
                                    <Select
                                        label="Nationality"
                                        options={countries}
                                        onChange={(val) => handleChange({ target: { name: "nationality", value: val.value } })}
                                        value={countries.find((gen) => gen.value === form?.nationality)}
                                        filterOption={(options, newInputs) => {
                                            const search = newInputs.toLowerCase().trim();
                                            if (
                                                options.value.includes(search) ||
                                                options.data.name.common.toLowerCase().includes(search) ||
                                                options.data.demonyms?.eng?.m.toLowerCase().includes(search)
                                            ) {
                                                return true;
                                            } else {
                                                return false;
                                            }
                                        }}
                                        isOutlined
                                        disabledOutline
                                        required
                                    />
                                    <Input
                                        label="Birthdate"
                                        name="birthdate"
                                        type="date"
                                        onChange={(e) => handleChange({ target: { name: e.target.name, value: e.target.value } })}
                                        value={toNativeDate(form?.birthdate, setting.timezone)}
                                        required
                                    />
                                    <Select
                                        label="Marital Status"
                                        options={maritalStatusOption}
                                        name="marital_status"
                                        onChange={(val) => handleChange({ target: { name: "marital_status", value: val.value } })}
                                        value={maritalStatusOption.find((gen) => gen.value === form.marital_status)}
                                        isOutlined
                                        disabledOutline
                                        required
                                    />
                                    <Select
                                        label="Bank short name"
                                        options={banks}
                                        name="EmployeeBankDetail.short_name"
                                        onChange={(val) => handleChange({ target: { name: "EmployeeBankDetail.short_name", value: val.value } })}
                                        value={banks.find((gen) => gen.value === form.EmployeeBankDetail?.short_name)}
                                        isOutlined
                                        disabledOutline
                                    />
                                    <Input
                                        label="Bank Account No."
                                        name="EmployeeBankDetail.account_number"
                                        onChange={handleChange}
                                        value={form.EmployeeBankDetail?.account_number}
                                    />
                                    <Input
                                        label="IBAN"
                                        name="EmployeeBankDetail.iban"
                                        onChange={handleChange}
                                        value={form.EmployeeBankDetail?.iban}
                                    />
                                </>
                            )
                        })}
                        {createGroup({
                            hiddenTitle: true,
                            title: "Personal Information 2",
                            body: (
                                <>
                                    <Input
                                        label="Residence ID No."
                                        name="residenceID"
                                        onChange={handleChange}
                                        value={form.residenceID}
                                        minLength={11}
                                    />
                                    <Input
                                        label="Residence ID Exp. Date"
                                        name="residenceID_expiration"
                                        type="date"
                                        onChange={(e) => handleChange({ target: { name: e.target.name, value: e.target.value } })}
                                        value={toNativeDate(form?.residenceID_expiration, setting.timezone)}
                                    />
                                    <Input
                                        label="Attach ID Copy"
                                        name="uploads.residence"
                                        icon={<ID_SVG />}
                                        style={{ marginTop: ".5rem" }}
                                        onChange={handleChange}
                                        value={form.uploads[EMPLOYEE_FILES.RESIDENCE.key]}
                                        accept={imageFileAccepts}
                                        onView={() => handleViewChange({ type: VIEW_MODAL_TYPE.ID_COPY, data: form })}
                                        sizeLimit={FILE_SIZE_LIMIT}
                                        upload
                                        hide
                                    />
                                    <Input label="Passport No." name="passportID" onChange={handleChange} value={form.passportID} />
                                    <Input
                                        label="Passport Exp. Date"
                                        name="passportID_expiration"
                                        type="date"
                                        onChange={(e) => handleChange({ target: { name: e.target.name, value: e.target.value } })}
                                        value={toNativeDate(form?.passportID_expiration, setting.timezone)}
                                    />
                                    <Input
                                        label="Attach Passport Copy"
                                        name="uploads.passport"
                                        icon={<PASSPORT_SVG style={{ minWidth: "1.2rem", maxWidth: "1.2rem" }} />}
                                        style={{ marginTop: ".5rem" }}
                                        onChange={handleChange}
                                        value={form.uploads[EMPLOYEE_FILES.PASSPORT.key]}
                                        accept={imageFileAccepts}
                                        onView={() => handleViewChange({ type: VIEW_MODAL_TYPE.PASSPORT_COPY, data: form })}
                                        sizeLimit={FILE_SIZE_LIMIT}
                                        upload
                                        hide
                                    />
                                    <Input label="Visa No." name="visaID" onChange={handleChange} value={form.visaID} minLength={12} />
                                    <Input
                                        label="Visa Exp. date"
                                        name="visaID_expiration"
                                        type="date"
                                        onChange={(e) => handleChange({ target: { name: e.target.name, value: e.target.value } })}
                                        value={toNativeDate(form?.visaID_expiration, setting.timezone)}
                                    />
                                    <Input
                                        label="Attach Visa Copy"
                                        name="uploads.visa"
                                        icon={<ID_SVG />}
                                        style={{ marginTop: ".5rem" }}
                                        onChange={handleChange}
                                        value={form.uploads[EMPLOYEE_FILES.VISA.key]}
                                        accept={imageFileAccepts}
                                        onView={() => handleViewChange({ type: VIEW_MODAL_TYPE.VISA_COPY, data: form })}
                                        sizeLimit={FILE_SIZE_LIMIT}
                                        upload
                                        hide
                                    />
                                </>
                            )
                        })}
                    </div>
                    <Divider />
                    <div className={createClass("__inner-row")}>
                        {createGroup({
                            title: "Contract Details",
                            body: (
                                <>
                                    <Select
                                        label="Contract Type"
                                        options={contractTypeOption}
                                        value={contractTypeOption.find((ctr) => ctr.value === form?.EmployeeContract?.contract_type)}
                                        onChange={(val) => handleChange({ target: { name: "EmployeeContract.contract_type", value: val.value } })}
                                        isClearable={false}
                                        isOutlined
                                        disabledOutline
                                        required
                                    />
                                    <Select
                                        label="Contract Term"
                                        options={contractTermOption}
                                        value={contractTermOption.find((ctr) => ctr.value === form?.EmployeeContract?.contract_term)}
                                        onChange={(val) => handleChange({ target: { name: "EmployeeContract.contract_term", value: val.value } })}
                                        isClearable={false}
                                        isOutlined
                                        disabledOutline
                                        required
                                    />
                                    <Input
                                        label="Hiring Date"
                                        name="EmployeeContract.hiring_date"
                                        type="date"
                                        onChange={(e) => handleChange({ target: { name: e.target.name, value: e.target.value } })}
                                        value={toNativeDate(form?.EmployeeContract?.hiring_date, setting.timezone)}
                                        required
                                    />
                                    <Input
                                        label="Joining Date"
                                        name="EmployeeContract.joining_date"
                                        type="date"
                                        onChange={(e) => handleChange({ target: { name: e.target.name, value: e.target.value } })}
                                        value={toNativeDate(form?.EmployeeContract?.joining_date, setting.timezone)}
                                        required
                                    />
                                    <Input
                                        label="Contract Expiry Date"
                                        name="EmployeeContract.end_date"
                                        type="date"
                                        onChange={(e) => handleChange({ target: { name: e.target.name, value: e.target.value } })}
                                        value={toNativeDate(form?.EmployeeContract?.end_date, setting.timezone)}
                                        required
                                    />
                                    <Input
                                        label="Period"
                                        name="EmployeeContract.period"
                                        onChange={handleChange}
                                        value={form?.EmployeeContract?.period}
                                        afterExtra={<span>Month(s)</span>}
                                        isNumberConstraint
                                        required
                                    />
                                    <Input
                                        label="Probation Period"
                                        name="EmployeeContract.probation_period"
                                        onChange={handleChange}
                                        value={form?.EmployeeContract?.probation_period}
                                        afterExtra={<span>Month(s)</span>}
                                        isNumberConstraint
                                        required
                                    />
                                    <Input
                                        label="Basic Salary"
                                        name="EmployeeContract.basic_amount"
                                        onChange={handleChange}
                                        value={form?.EmployeeContract?.basic_amount}
                                        afterExtra={renderCurrencyExtra}
                                        isNumberConstraint
                                        required
                                    />
                                    <Select
                                        label="Salary Fequency"
                                        options={salaryFreqOption}
                                        value={salaryFreqOption.find((dept) => dept.value === form.EmployeeContract?.salary_frequency)}
                                        onChange={(val) => handleChange({ target: { name: "EmployeeContract.salary_frequency", value: val.value } })}
                                        isClearable={false}
                                        isOutlined
                                        disabledOutline
                                        required
                                    />
                                    <Input
                                        label="Accomodation"
                                        name="EmployeeContract.allowance.accomodation"
                                        onChange={handleChange}
                                        value={form?.EmployeeContract?.allowance?.accomodation || 0}
                                        afterExtra={renderCurrencyExtra}
                                        isNumberConstraint
                                    />
                                    <Input
                                        label="Food Allowance"
                                        name="EmployeeContract.allowance.food"
                                        onChange={handleChange}
                                        value={form?.EmployeeContract?.allowance?.food || 0}
                                        afterExtra={renderCurrencyExtra}
                                        isNumberConstraint
                                    />
                                    <Input
                                        label="Transportation Allowance"
                                        name="EmployeeContract.allowance.transportation"
                                        onChange={handleChange}
                                        value={form?.EmployeeContract?.allowance?.transportation || 0}
                                        afterExtra={renderCurrencyExtra}
                                        isNumberConstraint
                                    />
                                    <Input
                                        label="Communication Allowance"
                                        name="EmployeeContract.allowance.communication"
                                        onChange={handleChange}
                                        value={form?.EmployeeContract?.allowance?.communication || 0}
                                        afterExtra={renderCurrencyExtra}
                                        isNumberConstraint
                                    />
                                    <Input
                                        label="Education Allowance"
                                        name="EmployeeContract.allowance.education"
                                        onChange={handleChange}
                                        value={form?.EmployeeContract?.allowance?.education || 0}
                                        afterExtra={renderCurrencyExtra}
                                        isNumberConstraint
                                    />
                                    <Input
                                        label="Other Allowance"
                                        name="EmployeeContract.allowance.other"
                                        onChange={handleChange}
                                        value={form?.EmployeeContract?.allowance?.other || 0}
                                        afterExtra={renderCurrencyExtra}
                                        isNumberConstraint
                                    />
                                    <Input
                                        label="Attach Contract Copy"
                                        name="uploads.contract"
                                        icon={<CONTRACT_SVG style={{ minWidth: "1.5rem", maxWidth: "1.5rem" }} />}
                                        style={{ marginTop: "1.5rem" }}
                                        onChange={handleChange}
                                        value={form.uploads[EMPLOYEE_FILES.CONTRACT.key]}
                                        accept={imageFileAccepts}
                                        onView={() => handleViewChange({ type: VIEW_MODAL_TYPE.CONTRACT_COPY, data: form })}
                                        sizeLimit={FILE_SIZE_LIMIT}
                                        upload
                                        hide
                                    />
                                </>
                            )
                        })}
                        {createGroup({
                            title: "Work Details",
                            body: (
                                <>
                                    <DepartmentsSelectLazy
                                        label="Department"
                                        name="CompanyDepartment"
                                        onChange={(val) => handleChange({ target: { name: "CompanyDepartment", value: val } })}
                                        value={form.CompanyDepartment}
                                        isOutlined
                                        disabledOutline
                                        isClearable
                                        required
                                    />
                                    <DesignationsSelectLazy
                                        label="Designation"
                                        name="CompanyDesignation"
                                        onChange={(val) => handleChange({ target: { name: "CompanyDesignation", value: val } })}
                                        value={form.CompanyDesignation}
                                        isOutlined
                                        disabledOutline
                                        isClearable
                                        required
                                    />
                                    <Input label="Grade" name="grade" onChange={handleChange} value={form?.grade} />
                                    {!isShiftEditable && (
                                        <span className="small-font danger-color flex center bold" style={{ marginTop: "1rem" }}>
                                            Note: The folllowing fields are disabled because employee is currently on shift.
                                        </span>
                                    )}
                                    <SiteSelectLazy
                                        label="Work Sites"
                                        name="CompanySites"
                                        onChange={(val) => handleChange({ target: { name: "CompanySites", value: val } })}
                                        value={form.CompanySites}
                                        isReadableSelected={!isShiftEditable}
                                        isClearable={isShiftEditable}
                                        isSearchable={false}
                                        isMulti
                                        isOutlined
                                        disabledOutline
                                    />
                                    <Select
                                        label="Schedule"
                                        options={scheduleTypeOption}
                                        value={scheduleTypeOption.find((dept) => dept.value === form.schedule_type)}
                                        onChange={(val) => handleChange({ target: { name: "schedule_type", value: val.value } })}
                                        isClearable={false}
                                        isDisabled={!isShiftEditable}
                                        isOutlined
                                        disabledOutline
                                        required
                                    />
                                    <WorkShiftSelectLazy
                                        label="Work Shift"
                                        name="EmployeeWorkShift"
                                        onChange={(val) => handleChange({ target: { name: "EmployeeWorkShift", value: val } })}
                                        value={form.EmployeeWorkShift}
                                        timezone={setting.timezone}
                                        isDisabled={!isShiftEditable}
                                        isSearchable={false}
                                        isOutlined
                                        disabledOutline
                                        isClearable
                                    />
                                    <Input
                                        label="No. of Weekly Off Days"
                                        name="number_weekly_off_days"
                                        onChange={handleChange}
                                        value={form?.number_weekly_off_days}
                                        afterExtra={<span>Day(s)</span>}
                                        maxNum={6}
                                        disabled={!isShiftEditable}
                                        isNumberConstraint
                                        required
                                    />
                                    <Select
                                        label="Off Days"
                                        name="off_days"
                                        options={offdaysOption}
                                        value={offdaysOption.filter((offday) =>
                                            (form?.off_days || []).map((od) => od.toLowerCase().trim()).includes(offday?.value?.toLowerCase().trim())
                                        )}
                                        onChange={(od) => {
                                            const wod = form.number_weekly_off_days;
                                            const curr = od.map((o) => o.value);
                                            const len = curr.length;
                                            const withinBounds = len <= wod;
                                            handleChange({
                                                target: {
                                                    name: "off_days",
                                                    value: withinBounds ? curr : curr.slice(od.length - form.number_weekly_off_days)
                                                }
                                            });
                                        }}
                                        isDisabled={!isShiftEditable}
                                        isMulti
                                        isOutlined
                                        disabledOutline
                                        required
                                    />
                                </>
                            )
                        })}
                    </div>
                    <div className={createClass("__inner-row")}>
                        {createGroup({
                            title: "",
                            body: (
                                <>
                                    <Input
                                        label="Job Description"
                                        name="EmployeeContract.job_description"
                                        placeholder="Your job description here..."
                                        onChange={handleChange}
                                        value={form?.EmployeeContract?.job_description}
                                        textarea
                                    />
                                </>
                            )
                        })}
                    </div>
                </div>
            </div>
            {!!viewObject.type && (
                <ViewModalSub
                    open={!!viewObject.type}
                    onChange={(bool) => handleViewChange({ type: bool ? viewObject.type : null })}
                    type={viewObject.type}
                    data={viewObject.data}
                    countries={countries}
                />
            )}
        </BaseUpdateModal>
    );
}

UpdateModal.propTypes = {
    photo: PropTypes.string,
    open: PropTypes.bool,
    data: PropTypes.object,
    setting: PropTypes.object,
    onChange: PropTypes.func,
    onFinish: PropTypes.func,
    onBack: PropTypes.func,
    onClose: PropTypes.func,
    workshifts: PropTypes.array
};

export default UpdateModal;
