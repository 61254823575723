import React from "react";
import PropTypes from "prop-types";

function Group({ title, children }) {
    return (
        <div>
            <div style={{ marginBottom: "1rem" }}>
                <h3>{title}</h3>
            </div>
            <div>{children}</div>
        </div>
    );
}

export default Group;

Group.propTypes = {
    title: PropTypes.any,
    children: PropTypes.any
};
