import { apiSlice } from "../../../app/apiSlice";

const BASE_PATH = "/api/company/employee/workshift";

const END_POINTS = {
    createWorkshift: { method: "post", path: "create" },
    updateWorkshift: { method: "put", path: "update" },
    deleteWorkshift: { method: "delete", path: "delete" },
    getWorkshiftDetails: { method: "get", path: "get-details" },
    loadAllWorkshift: { method: "post", path: "load-all" },
    loadAllLazyWorkshift: { method: "post", path: "load-all-lazy" },
    getWorkshiftByIds: { method: "post", path: "get-by-ids" }
};

export const workShiftAPI = apiSlice.injectEndpoints({
    reducerPath: "workShiftAPI",
    endpoints: (builder) => {
        const temp = {};
        Object.entries(END_POINTS).forEach(([key, config]) => {
            temp[key] = builder.mutation({
                query: (queryParams = {}) => {
                    const path = `${BASE_PATH}/${config.path}`;
                    const conf = {
                        url: path,
                        method: config.method,
                        credentials: "include"
                    };
                    if (typeof queryParams.extraPath === "string" || typeof queryParams.extraPath === "number")
                        conf.url = `${path}/${queryParams.extraPath}`;
                    if (Array.isArray(queryParams.extraPaths)) conf.url = `${path}/${queryParams.extraPaths.join("/")}`;
                    if (queryParams.params) conf.params = queryParams.params;
                    if (queryParams.body) conf.body = queryParams.body;
                    return conf;
                }
            });
        });
        return temp;
    }
});

export const {
    useCreateWorkshiftMutation,
    useUpdateWorkshiftMutation,
    useDeleteWorkshiftMutation,
    useGetWorkshiftDetailsMutation,
    useLoadAllWorkshiftMutation,
    useLoadAllLazyWorkshiftMutation,
    useGetWorkshiftByIdsMutation
} = workShiftAPI;

export default workShiftAPI.reducer;
