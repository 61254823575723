import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ReactDatePicker from "react-datepicker";
import { useAppSelector } from "../../../hooks/reduxHooks";
import { selectUserSetting } from "../../../../features/common/slice";
import { STANDARD_REF_DATE } from "../../../utilities/const";
import { toTimeWithTimeZone } from "../../../utilities/helper";

const DATE_FORMAT = "hh:mm aa";
const SELECTED_CLASS = "react-datepicker__time-list-item--selected";

function InputTime({ minDate, maxDate, isFixed, onChange, interval = 30, timezone, baseDate = STANDARD_REF_DATE, selected, constraint, ...props }) {
    const [isMounted, setMounted] = useState(false);
    const [, setKeys] = useState("");
    const [, setCalendarOpen] = useState(false);

    const setting = useAppSelector(selectUserSetting);

    const minConstraint = constraint?.min;
    const maxConstraint = constraint?.max;

    const handleChange = (date, e, event) => {
        if (date) {
            const base = toTimeWithTimeZone(baseDate, timezone || setting.timezone);
            const tzdate = toTimeWithTimeZone(date, timezone || setting.timezone);

            const newDate = base.clone().set({
                hour: tzdate.hour(),
                minute: tzdate.minute(),
                second: tzdate.second(),
                millisecond: tzdate.millisecond()
            });

            date = newDate;

            if (event != "mounting") {
                const isInvalidMinConstraint = minConstraint && date.isSameOrBefore(minConstraint.toDate());
                const isInvalidMaxConstraint = maxConstraint && date.isSameOrAfter(maxConstraint.toDate());

                if (isInvalidMinConstraint || isInvalidMaxConstraint) {
                    date = "";
                }
            }
        } else {
            date = "";
        }
        onChange?.(date, event);
    };

    useEffect(() => {
        setMounted(true);
    }, []);

    useEffect(() => {
        // make sure to call onchange here so that date is always correct
        if (isMounted && selected) {
            handleChange(selected, null, "mounting");
        }
    }, [isMounted]);

    const handleKeyDown = (event) => setKeys(event.key);

    return (
        <div className="tk-input__time flex w100" style={{ justifyContent: "flex-end" }}>
            <ReactDatePicker
                {...props}
                selected={selected}
                wrapperClassName="date-picker-custom"
                style={{ width: "5rem", flex: "unset" }}
                placeholderText={props.placeholder || "00:00"}
                minDate={minDate}
                maxDate={maxDate}
                popperPlacement="bottom-left"
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                autoComplete="off"
                showPopperArrow={false}
                timeCaption="Time"
                dateFormat={DATE_FORMAT}
                timeIntervals={interval}
                className="custom-react-date-picker"
                onCalendarOpen={() => setCalendarOpen(true)}
                onCalendarClose={() => setCalendarOpen(false)}
                popperModifiers={[
                    {
                        name: "myModifier",
                        fn: (state) => {
                            const elements = state.elements;
                            const popper = elements.floating;
                            const selected = popper.querySelector("." + SELECTED_CLASS);
                            if (selected) {
                                selected.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
                            }
                            return state;
                        }
                    }
                ]}
                showTimeSelect
                showTimeSelectOnly
                disabledKeyboardNavigation
                {...(isFixed ? { popperProps: { strategy: "fixed", onOpenChange: console.log } } : {})}
            />
        </div>
    );
}

export default InputTime;

InputTime.propTypes = {
    minDate: PropTypes.any,
    maxDate: PropTypes.any,
    placeholder: PropTypes.string,
    isFixed: PropTypes.bool,
    onChange: PropTypes.func,
    interval: PropTypes.number,
    timezone: PropTypes.string,
    baseDate: PropTypes.string,
    selected: PropTypes.any,
    isLoading: PropTypes.bool,
    constraint: PropTypes.shape({
        min: PropTypes.any,
        max: PropTypes.any
    })
};
