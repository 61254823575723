import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import useDeepCompareEffect from "use-deep-compare-effect";
import isEqual from "lodash/isEqual";
import Marker from "./Marker";
import { calculateCoordinatesCenter, getApproximateCenter, mvcArrayGeoValues } from "./helper";
import { MOUSE_EVENTS, MVC_PATH_EVENT } from "./const";
import { InfoWindow } from "@vis.gl/react-google-maps";
import Button from "../Button";

export const POLYGON_CHANGE_TYPE = {
    INTIALIZE: 0x1,
    REMOVE: 0x2,
    PATH_CHANGE: 0x3
};

function Polygon({ centerTitle, maps, map, path, options, onChange, enableContextMenu, closeInfo }) {
    let tracklisteners = [];

    const [contextMenuOpt, setContextMenuOpt] = useState({ position: null, show: false });
    const [data, setData] = useState(null);
    const [center, setCenter] = useState(null);

    const showContextMenu = contextMenuOpt.position && contextMenuOpt.show && enableContextMenu && !closeInfo;
    const showCenterMarker = center && !!path.length;

    const updateContextMenu = (newObj = {}) => setContextMenuOpt((prev) => ({ ...prev, ...newObj }));

    const handlePathChange = (oldpath = [], instance) => {
        const newpaths = mvcArrayGeoValues(instance.getPath());
        if (!isEqual(newpaths, oldpath)) {
            const newCenter = calculateCoordinatesCenter(newpaths);
            setCenter(newCenter);
            updateContextMenu({ position: null, show: false });
            typeof onChange == "function" && onChange(data, newCenter, newpaths, POLYGON_CHANGE_TYPE.PATH_CHANGE);
        }
    };

    const handleRighClick = (event) => {
        const position = event.latLng.toJSON();
        setContextMenuOpt((prev) => ({ ...prev, position, show: true }));
    };

    const handleRemovePolygon = () => {
        data.setPath([]);
        updateContextMenu({ show: false });
        typeof onChange == "function" && onChange(data, null, [], POLYGON_CHANGE_TYPE.REMOVE);
    };

    useEffect(() => {
        const Polygon = maps?.Polygon;
        const instance = Polygon && new Polygon({ ...options, path });
        if (instance && map) {
            // if instance not yet exist create a new one
            if (!data) {
                instance.setMap(map);
                const center = calculateCoordinatesCenter(path);
                const oldpath = mvcArrayGeoValues(instance.getPath());

                setData(instance);
                setCenter(center);

                tracklisteners = [
                    // listen for right click to show a context menu
                    instance.addListener(MOUSE_EVENTS.RIGHT_CLICK, (event) => handleRighClick(event, instance)),
                    // listen for new path changes
                    instance.addListener(MOUSE_EVENTS.DRAG, () => contextMenuOpt.show && updateContextMenu({ show: false })),
                    instance.addListener(MOUSE_EVENTS.DRAG_END, () => handlePathChange(oldpath, instance)),
                    instance.getPath().addListener(MVC_PATH_EVENT.SET_AT, () => handlePathChange(oldpath, instance)),
                    instance.getPath().addListener(MVC_PATH_EVENT.INSERT_AT, () => handlePathChange(oldpath, instance)),
                    instance.getPath().addListener(MVC_PATH_EVENT.REMOVE_AT, () => handlePathChange(oldpath, instance))
                ];

                typeof onChange == "function" && onChange(instance, center, path, POLYGON_CHANGE_TYPE.INTIALIZE);
            }
        }
        return () => {
            data && data.setMap(null);
            tracklisteners.length && tracklisteners.forEach((listener) => listener.remove());
        };
    }, [maps?.Polygon]);

    useDeepCompareEffect(() => {
        if (data && path) {
            const oldpath = mvcArrayGeoValues(data.getPath());
            if (!isEqual(path, oldpath)) {
                data.setPath(path);
                const center = getApproximateCenter(maps, data);
                setCenter(center);
                typeof onChange == "function" && onChange(data, center, path, POLYGON_CHANGE_TYPE.PATH_CHANGE);
            }
        }
    }, [data, path]);

    useEffect(() => {
        if (closeInfo) {
            updateContextMenu({ show: false });
        }
    }, [closeInfo]);

    return (
        <>
            {showCenterMarker && (
                <Marker map={map} maps={maps} lat={center.lat} lng={center.lng} content={centerTitle || "Boundary"} closeInfo={closeInfo} isCenter />
            )}
            {showContextMenu && (
                <InfoWindow map={map} position={contextMenuOpt.position} onCloseClick={() => updateContextMenu({ show: false })} shouldFocus>
                    <Button className="primary" onClick={handleRemovePolygon}>
                        Remove Area
                    </Button>
                </InfoWindow>
            )}
        </>
    );
}

export default Polygon;

Polygon.propTypes = {
    centerTitle: PropTypes.any,
    map: PropTypes.object,
    path: PropTypes.arrayOf(
        PropTypes.shape({
            lat: PropTypes.number,
            lng: PropTypes.number
        })
    ),
    maps: PropTypes.object,
    onChange: PropTypes.func,
    options: PropTypes.object,
    enableContextMenu: PropTypes.bool,
    closeInfo: PropTypes.bool
};
