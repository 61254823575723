import React, { useState } from "react";
import PropTypes from "prop-types";
import isEqual from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";
import Modal from "../../../common/components/extra/Modal";
import { TOAST_TYPE, createConfirmAlert, createToast } from "../../../common/utilities/helper";
import Input, { INPUT_TYPE } from "../../../common/components/extra/form/Input";
import { useCreateDesignationMutation, useUpdateDesignationMutation } from "./api";

const BASE_CLASS = "tk-designations__modal-content-update";

const createClass = (newStr = "") => `${BASE_CLASS}${newStr}`;

const INITIAL_FORM = { title: "" };

function UpdateModal({ open, onChange, onBack, data, onFinish }) {
    const isCreate = !data;
    const [form, setForm] = useState({
        ...INITIAL_FORM,
        ...(data || {})
    });

    const [createWorkDetail, { isLoading: createLoading }] = useCreateDesignationMutation();
    const [updateWorkDetail, { isLoading: updateLoading }] = useUpdateDesignationMutation();
    const isLoading = createLoading || updateLoading;

    const updateForm = (config = {}) => setForm((prev) => ({ ...prev, ...config }));

    const createGroup = ({ title, body, hiddenTitle }) => (
        <div className={createClass("__group")}>
            <div className={createClass("__group__header")}>
                <h3 style={{ opacity: hiddenTitle ? 0 : 1 }}>{title}</h3>
            </div>
            <div className={createClass("__group__body") + " flex column gap-05"}>{body}</div>
        </div>
    );

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const config = { ...form };
        config[name] = value;
        updateForm(config);
    };

    const handleSave = async () => {
        let result = null;
        try {
            const clonedform = cloneDeep(form);
            if (clonedform.title) {
                clonedform.title = clonedform.title.toUpperCase();
            }
            if (isCreate) {
                result = await createWorkDetail({ body: clonedform });
            } else {
                result = await updateWorkDetail({ body: clonedform, extraPath: data.id });
            }
            if (result.error) {
                throw new Error(result.error?.data?.message);
            }
            if (result.data) {
                if (result.data?.data) {
                    createToast(`Designation ${isCreate ? "created" : "updated"} succesfully.`, TOAST_TYPE.SUCCESS);
                } else {
                    createToast(result.data.message, TOAST_TYPE.SUCCESS);
                }
            }
            result?.data?.data && typeof onFinish === "function" && onFinish(result.data.data, isCreate);
        } catch (error) {
            return createToast(
                `Failed to ${!isCreate ? "update" : "create"} Designation. ${error?.message || "Please try again later or contact support."} `,
                TOAST_TYPE.ERROR
            );
        }
    };

    return (
        <Modal
            open={open}
            onChange={onChange}
            onBack={(!isCreate && onBack) || null}
            onSave={() =>
                createConfirmAlert({
                    title: !isCreate ? "Update Designation" : "Create Designation",
                    content: `Are you sure you want to ${isCreate ? "create" : "update"} this designation? This cannot be undone.`,
                    onConfirm: async (close) => {
                        close();
                        await handleSave();
                    }
                })
            }
            disableSave={isEqual(form, data || INITIAL_FORM)}
            styles={{ content: { width: "50vw", maxWidth: "45rem", minWidth: "40rem", height: "max-content" } }}
            isLoading={isLoading}
            isForm
        >
            <div className={createClass()}>
                <div className={createClass("__inner")}>
                    <div className={createClass("__inner-row")}>
                        {createGroup({
                            title: !isCreate ? "Update Designation" : "Create Designation",
                            body: (
                                <Input
                                    type={INPUT_TYPE.TEXT}
                                    name="title"
                                    label="Title"
                                    onChange={handleChange}
                                    value={form.title || ""}
                                    minLength={4}
                                    maxLength={50}
                                    autoFocus
                                    required
                                />
                            )
                        })}
                    </div>
                </div>
            </div>
        </Modal>
    );
}

UpdateModal.propTypes = {
    open: PropTypes.bool,
    data: PropTypes.object,
    onChange: PropTypes.func,
    onFinish: PropTypes.func,
    onBack: PropTypes.func
};

export default UpdateModal;
