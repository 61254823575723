import React, { useEffect, useState } from "react";
import cloneDeep from "lodash/cloneDeep";
import PropTypes from "prop-types";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import isEqual from "lodash/isEqual";
import { selectUser } from "../../common/slice";
import { selectGeneral, setGeneral, updateGeneral } from "./slice";
import { useGetCompanySettingsMutation, useUpdateCompanySettingsMutation } from "./api";
import { useAppDispatch, useAppSelector } from "../../../common/hooks/reduxHooks";
import Input, { INPUT_TYPE } from "../../../common/components/extra/form/Input";
import Divider from "../../../common/components/extra/Divider";
import Button from "../../../common/components/extra/Button";
import Card from "../../../common/components/extra/Card";
import Select from "../../../common/components/extra/select/Select";
import { CURRENCIES, TIMEZONES, TIME_FORMAT } from "../../../common/utilities/const";
import {
    TOAST_TYPE,
    convertObjectToString,
    convertToObject,
    createConfirmAlert,
    createMockData,
    createToast,
    getObjectChanges,
    renderNA,
    transformStringToObject
} from "../../../common/utilities/helper";

const TIME_FORMATS = Object.values(TIME_FORMAT);
const CURRENCY_VALUES = Object.values(CURRENCIES);
const CURRENCY_KEYS = Object.keys(CURRENCIES);

const description =
    "The general tab holds vital settings like mobile configurations, time zone adjustments, and time-in/out setups for streamlined operations.";

const createOptions = ({ totalEmployees }) => {
    const label = (val, text) => (
        <div className="flex gap-05">
            {val} {renderNA(text)}
        </div>
    );
    return {
        currencies: CURRENCY_VALUES.map((currency, i) => ({
            ...currency,
            value: CURRENCY_KEYS[i],
            label: label(currency.name, `(${CURRENCY_KEYS[i]})`)
        })),
        hourFormats: TIME_FORMATS.map((tf) => ({ ...tf, label: label(tf.label, "hour") })),
        timezones: TIMEZONES.simiplified.map((tz) => ({ value: tz, label: tz })),
        maxTimeInOutSVOpt: createMockData(totalEmployees + 1, (idx) => ({ label: label(idx, "max"), value: idx })),
        maxTimeInOutEMPOpt: createMockData(31, (idx) => ({ label: label(idx, "max"), value: idx })),
        minutes: createMockData(121, (idx) => ({ label: label(idx, "min(s)"), value: idx }))
    };
};

function GeneralTab({ setParentLoading, onLoading }) {
    const [loading, setLoading] = useState(true);
    const [isEditing, setIsEditing] = useState(false);
    const [old, setOld] = useState(null);

    const dispatch = useAppDispatch();
    const user = useAppSelector(selectUser);
    const [getDetails] = useGetCompanySettingsMutation();
    const [update] = useUpdateCompanySettingsMutation();
    const general = useAppSelector(selectGeneral);
    const totalEmployees = user.totalEmployees;
    const { hourFormats, timezones, currencies, maxTimeInOutSVOpt, maxTimeInOutEMPOpt, minutes } = createOptions({ totalEmployees });
    const timeFormat = hourFormats.find((hf) => hf.value === general.time_format) || "";
    const timezone = timezones.find((tz) => tz.value === general.timezone) || "";
    const currency = currencies.find((currency) => currency.value === general.currency) || "";
    const maxEmpTimeInOutSv = maxTimeInOutSVOpt.find((opt) => opt.value == general.mobile_app?.maxEmpTimeInOutSv) || "";
    const maxEmpTimeInOutEmp = maxTimeInOutEMPOpt.find((opt) => opt.value == general.mobile_app?.maxSvTimeInOutEmp) || "";
    const findMinuteValue = (val) => minutes.find((min) => min.value == val) || "";

    const fetchDetails = async () => {
        try {
            const response = await getDetails();
            if (response.error) {
                throw new Error(response.error?.data?.message);
            }
            const data = response.data?.data || {};
            dispatch(setGeneral(data));
            if (!old) {
                setOld(data);
            }
        } catch (error) {
            createToast("Something went wrong with the server. Please contact support.", TOAST_TYPE.ERROR);
        } finally {
            setLoading(false);
        }
    };

    const updateApi = async (body) => {
        typeof onLoading === "function" && onLoading(true);
        setParentLoading?.(true);
        const result = await update({ body });
        if (result.error) {
            setParentLoading?.(false);
            throw new Error(result?.error?.data?.message || "Something went wrong!. Please try again later.");
        }
        const data = result.data.data;
        setParentLoading?.(false);
        typeof onLoading === "function" && onLoading(false);
        return data;
    };

    useEffect(() => {
        if (!general?.id) {
            fetchDetails();
        } else {
            setOld(general);
            setLoading(false);
        }
    }, []);

    const handleChange = (e) => {
        let config = { ...general };
        const name = e.target.name;
        const value = e.target.value;
        const isChainedObject = name.split(".").length > 1;
        if (isChainedObject) {
            const parsedObject = transformStringToObject(name, value, general);
            config = parsedObject;
        } else {
            config[name] = value;
        }
        handleFormChange(config);
    };

    const handleFormChange = (config = {}) => dispatch(updateGeneral(config));

    const toggleEdit = ({ isCancel } = {}) => {
        if (isCancel && old) {
            dispatch(setGeneral(old));
        }
        setIsEditing((prev) => !prev);
    };

    const inputPropsValue = (value) => {
        if (isEditing) {
            return { value };
        } else {
            return { renderValue: value, readOnly: true };
        }
    };

    const handleSave = async () => {
        try {
            const changes = getObjectChanges(convertObjectToString(general), convertObjectToString(old));
            const result = await updateApi(convertToObject(changes));
            if (result.error) {
                throw new Error(result?.error?.data?.message || "Something went wrong!. Please try again later.");
            }
            handleFormChange(result);
            setOld(result);
            toggleEdit();
            createToast("Settings updated successfully.", TOAST_TYPE.SUCCESS);
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
        }
    };

    const checkHasChanges = () => {
        const cloneNew = cloneDeep(general);
        const cloneOld = cloneDeep(old || {});
        return !!old && !isEqual(convertObjectToString(cloneNew), convertObjectToString(cloneOld));
    };

    const renderInput = ({ name, label, value, onChange, options, afterExtra, ...rest } = {}) => {
        if (isEditing) {
            return (
                <Select
                    options={options}
                    onChange={(val) => onChange({ target: { name, value: val.value } })}
                    value={value}
                    name={name}
                    label={label}
                    placeholder=""
                    style={{ flex: "30%" }}
                    isClearable={false}
                    menuPortalTarget={document.body}
                    isOutlined
                    disabledOutline
                    {...rest}
                />
            );
        }
        return (
            <Input
                type={INPUT_TYPE.TEXT}
                name={name}
                label={label}
                onChange={onChange}
                afterExtra={afterExtra}
                isLoading={loading}
                {...inputPropsValue(typeof value === "object" && "label" in value ? value.label : value)}
            />
        );
    };

    const renderControls = (
        <div className="flex gap-1" style={{ marginLeft: "auto" }}>
            {isEditing && (
                <Button
                    options={{ type: "button" }}
                    beforeExtra={<CancelIcon style={{ color: "red" }} />}
                    onClick={() => toggleEdit({ isCancel: true })}
                    disabled={loading}
                    small
                >
                    Cancel
                </Button>
            )}
            {!isEditing && (
                <Button options={{ type: "button" }} beforeExtra={<ModeEditIcon />} className="primary" onClick={toggleEdit} disabled={loading} small>
                    Edit
                </Button>
            )}
            {isEditing && (
                <Button options={{ type: "submit" }} beforeExtra={<SaveIcon />} className="primary" disabled={loading || !checkHasChanges()} small>
                    Save
                </Button>
            )}
        </div>
    );

    return (
        <Card className="box-shadow-mini">
            <form
                className="profile-tab flex column"
                style={{ gap: "2rem" }}
                onSubmit={(e) => {
                    e.preventDefault();
                    createConfirmAlert({
                        title: "Save Profile",
                        content: "Are you sure this will update your profile.",
                        onConfirm: (onClose) => {
                            onClose();
                            handleSave();
                        }
                    });
                }}
            >
                <div className="flex column">
                    <Divider title="Manage General Settings" />
                    <p className="fade small-font">{description}</p>
                    <div className="flex column gap-1" style={{ gap: "2rem" }}>
                        <section className="flex column gap-05">
                            {renderInput({
                                name: "timezone",
                                label: "Timezone",
                                onChange: handleChange,
                                value: timezone,
                                options: timezones,
                                autoFocus: true
                            })}
                            {renderInput({ name: "currency", label: "Currency", onChange: handleChange, value: currency, options: currencies })}
                            {renderInput({
                                name: "time_format",
                                label: "Time Format",
                                onChange: handleChange,
                                value: timeFormat,
                                options: hourFormats
                            })}
                        </section>
                        <section className="flex column gap-05">
                            <span className="bold" style={{ marginLeft: ".5rem" }}>
                                Mobile
                            </span>
                            <div className="flex column" style={{ marginLeft: "1rem" }}>
                                {renderInput({
                                    name: "mobile_app.earlyTimeIn",
                                    label: "Early Time In",
                                    onChange: handleChange,
                                    value: findMinuteValue(general.mobile_app?.earlyTimeIn),
                                    options: minutes
                                })}
                                {renderInput({
                                    name: "mobile_app.earlyTimeOut",
                                    label: "Early Time Out",
                                    onChange: handleChange,
                                    value: findMinuteValue(general.mobile_app?.earlyTimeOut),
                                    options: minutes
                                })}
                                {renderInput({
                                    name: "mobile_app.defaultLateGracePeriod",
                                    label: "Late Grace Period",
                                    onChange: handleChange,
                                    value: findMinuteValue(general.mobile_app?.defaultLateGracePeriod),
                                    options: minutes
                                })}
                                {renderInput({
                                    name: "mobile_app.bgLocationGracePeriod",
                                    label: "Location Grace Period",
                                    onChange: handleChange,
                                    value: findMinuteValue(general.mobile_app?.bgLocationGracePeriod),
                                    options: minutes
                                })}
                            </div>
                        </section>
                        <div className="flex column gap-05">
                            <span className="bold" style={{ marginLeft: ".5rem" }}>
                                Max Time In/Out by Supervisors
                            </span>
                            <div className="flex column" style={{ marginLeft: "1rem" }}>
                                {renderInput({
                                    name: "mobile_app.maxSvTimeInOutEmp",
                                    label: "For Employees",
                                    onChange: handleChange,
                                    value: maxEmpTimeInOutEmp,
                                    options: maxTimeInOutEMPOpt
                                })}
                                {renderInput({
                                    name: "mobile_app.maxEmpTimeInOutSv",
                                    label: "For Supervisors",
                                    onChange: handleChange,
                                    value: maxEmpTimeInOutSv,
                                    options: maxTimeInOutSVOpt
                                })}
                                {isEditing && (
                                    <span className="small-font fade" style={{ marginLeft: ".5rem", marginTop: ".5rem" }}>
                                        Note: 0 = unlimited
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                {renderControls}
            </form>
        </Card>
    );
}

export default GeneralTab;

GeneralTab.propTypes = {
    loading: PropTypes.bool,
    setParentLoading: PropTypes.func,
    onLoading: PropTypes.func
};
