import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import SearchIcon from "@mui/icons-material/Search";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Button from "./Button";
import { toast } from "react-toastify";
import { bytesToMegabytes, createConfirmAlert, renderNA } from "../../utilities/helper";
import { FALSE_VALUES } from "../../utilities/const";
import Select from "./select/Select";

function Input({
    icon,
    isLarge,
    label,
    isSearch,
    checked = false,
    value = "",
    onChange,
    isNumber,
    isNumberConstraint,
    renderValue,
    readOnly,
    isToggle,
    parentStyle = {},
    style,
    upload,
    mobile,
    textarea,
    onView,
    afterExtra,
    sizeLimit,
    haslabel,
    maxNum,
    portalCodes,
    hidelabel,
    hide,
    ...rest
}) {
    const inputFileRef = useRef(null);
    const isPassword = rest.type === "password";
    const isCheckbox = rest.type === "checkbox" || isToggle;
    const [isChecked, setIsChecked] = useState(!!checked);
    const [inValue, setValue] = useState(value || "");
    const [showPass, setShowPass] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const [mobileCode, setMobileCode] = useState("");

    useEffect(() => {
        if (mobile) {
            setMobileCode(mobile.code);
            setValue(value);
        }
    }, [mobile?.code]);

    useEffect(() => {
        if (isCheckbox && isChecked !== checked) setIsChecked(checked);
    }, [checked]);

    useEffect(() => {
        if (!isCheckbox && inValue !== value) setValue(value);
    }, [value]);

    const createInput = (config = {}) => {
        const pstyle = upload || textarea ? { ...(parentStyle || {}), borderBottom: "none", marginBottom: ".5rem" } : { ...(parentStyle || {}) };
        if (hidelabel) {
            pstyle.minWidth = "unset";
            if (!config?.options?.style) {
                config.options.style = {};
            }
            config.options.style.textAlign = "center";
            config.options.style.paddingRight = "8px";
        }
        const handleChange = (e) => {
            const value = e.target.value;
            const checked = e.target.checked;
            const files = e.target.files;
            const name = e.target.name;

            if (upload && files) {
                const file = files[0];
                if (file) {
                    const accepts = (rest.accept && rest.accept.split(",")) || [];
                    const isAllowed = accepts.includes(file.type);
                    const isLimitExceeded = bytesToMegabytes(file.size) > sizeLimit;
                    if (!isAllowed) {
                        toast.error(`Invalid file type. Only accepts the following: (${accepts.map((a) => a.split("/").pop()).join(", ")})`);
                        e.target.value = null;
                        return;
                    }
                    if (isLimitExceeded) {
                        toast.error(`Invalid file size. Must be less than ${sizeLimit}MB`);
                        e.target.value = null;
                        return;
                    }
                }
            }

            if (isCheckbox || isToggle) setIsChecked(checked);
            if (isNumber || isNumberConstraint) {
                let val = "";
                if (value !== "") {
                    val = value * 1;
                    if (val > maxNum || Number.isNaN(val)) return;
                }
                return onChange({ target: { name, value: val } });
            }
            if (!upload) {
                setValue(value);
            }
            if (mobile) {
                return onChange({ target: { name, value: e.target.value, mobileCode } });
            }
            onChange(e);
        };
        const defInput = (
            <input
                type="text"
                {...(config.options || {})}
                onChange={handleChange}
                checked={isChecked}
                value={FALSE_VALUES.includes(inValue) ? "" : inValue}
                readOnly={readOnly}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
            />
        );
        let defRender = (
            <>
                {config.icon && <div className="tk-input__icon-before">{config.icon}</div>}
                <div className="tk-input__inner">{readOnly ? renderValue || renderNA() : defInput}</div>
                {(readOnly ? renderValue : true) && config.afterIcon && <div className="tk-input__icon-after">{config.afterIcon}</div>}
            </>
        );
        if (upload && !readOnly) {
            const hasFile = !!inputFileRef.current?.value;
            const handleFileRemove = () => {
                inputFileRef.current.value = "";
                onChange({ target: { files: [false], name: rest.name } });
            };
            defRender = (
                <div className="tk-input__upload" style={style || {}}>
                    {label && <div>{label + ((rest.required && "*") || "")}</div>}
                    <div className="tk-input__upload__file">
                        <div className="left" onClick={() => inputFileRef.current && inputFileRef.current.click()}>
                            <input
                                ref={inputFileRef}
                                type="file"
                                onChange={handleChange}
                                {...rest}
                                required={value === true ? false : rest.required}
                                style={{ display: "none" }}
                            />
                            {icon && icon}
                            <span className="tk-input__upload__file-text">
                                {value && value.name ? (
                                    <span>
                                        Click or Drag files to replace - <strong>{value.name}</strong>
                                    </span>
                                ) : typeof value === "boolean" && value === true ? (
                                    `Click or Drag files to replace the existing file. Only accepts:(${rest.accept
                                        .split(",")
                                        .map((str) => str.split("/").pop())
                                        .join(", ")})`
                                ) : (
                                    `Click or Drag files here to upload. Only accepts:(${rest.accept
                                        .split(",")
                                        .map((str) => str.split("/").pop())
                                        .join(", ")})`
                                )}
                            </span>
                        </div>
                        {(onView || hasFile) && (
                            <div className="right flex gap-05" style={{ marginLeft: ".5rem" }}>
                                {onView && (
                                    <Button
                                        className="small-font"
                                        options={{ style: { textDecoration: "underline", padding: 0 } }}
                                        onClick={onView}
                                        transparent
                                    >
                                        View
                                    </Button>
                                )}
                                {hasFile && (
                                    <Button
                                        className="small-font"
                                        options={{ style: { color: "red", textDecoration: "underline", padding: 0 } }}
                                        onClick={() =>
                                            createConfirmAlert({
                                                title: "Are you sure?",
                                                content: "This will remove the file. Are you sure?",
                                                onConfirm: (onClose) => {
                                                    handleFileRemove();
                                                    onClose();
                                                }
                                            })
                                        }
                                        transparent
                                    >
                                        Remove
                                    </Button>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            );
        }

        if (mobile && mobile.codes && mobile.codes.length > 0 && !readOnly) {
            defRender = (
                <div className="tk-input__mobile">
                    {config.icon && <div className="tk-input__icon-before">{config.icon}</div>}
                    <div className="tk-input__inner">
                        <div className="tk-input__mobile-code">
                            <Select
                                options={mobile.codes}
                                value={mobile.codes.find((code) => code.value === mobileCode) || ""}
                                onChange={(val) => {
                                    setMobileCode(val.value);
                                    onChange({ target: { name, value, mobileCode: val.value } });
                                }}
                                {...(portalCodes ? { menuPortalTarget: document.body } : {})}
                                isOutlined
                                transparent
                                disabledOutline
                                noborder
                            />
                        </div>
                        {defInput}
                    </div>
                    {config.afterIcon && <div className="tk-input__icon-after">{config.afterIcon}</div>}
                </div>
            );
        }

        if (textarea && !readOnly) {
            defRender = (
                <div className="tk-input__textarea" style={style || {}}>
                    <div>{label + ((rest.required && <span className="danger-color bold">*</span>) || "")}</div>
                    <div className="tk-input__textarea__content">
                        <textarea {...rest} onChange={handleChange} value={inValue || ""} readOnly={readOnly} />
                    </div>
                </div>
            );
        }

        return (
            <div
                className={`tk-input ${config.className ? config.className : ""} ${isLarge ? "lg" : ""} ${isSearch ? "search" : ""}`.trim()}
                style={pstyle}
            >
                {defRender}
            </div>
        );
    };

    let newClassName = "";
    let newIcon = icon;
    let newOptions = rest;
    let afterIcon = afterExtra;

    if (isCheckbox) newClassName += " is-checkbox";
    if (isNumber) newClassName += " is-number";
    if (label || haslabel) {
        newClassName += " has-label";
        if (label && !hidelabel) {
            newIcon = (
                <div className="tk-input__label">
                    <span>
                        {label}
                        {rest.required ? "*" : ""}
                    </span>
                </div>
            );
        }
    }
    if (isSearch) {
        newIcon = <SearchIcon />;
        newOptions = { type: "search", placeholder: rest.placeholder || "Search" };
    }
    if (isToggle) {
        newClassName += " is-toggle";
        newOptions.type = "checkbox";
    }
    if (isPassword) {
        afterIcon = showPass ? <VisibilityOffIcon onClick={() => setShowPass(false)} /> : <VisibilityIcon onClick={() => setShowPass(true)} />;
        newOptions.type = showPass ? "text" : "password";
    }
    if (isFocused) newClassName += " is-focused";

    if (hide) {
        return <></>;
    }
    return createInput({ icon: newIcon, afterIcon, options: newOptions, className: newClassName });
}

Input.propTypes = {
    parentStyle: PropTypes.object,
    uploadStyle: PropTypes.object,
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
    afterExtra: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
    isLarge: PropTypes.bool,
    checked: PropTypes.bool,
    isNumber: PropTypes.bool,
    isNumberConstraint: PropTypes.bool,
    readOnly: PropTypes.bool,
    haslabel: PropTypes.bool,
    portalCodes: PropTypes.bool,
    hidelabel: PropTypes.bool,
    onChange: PropTypes.func,
    onView: PropTypes.func,
    sizeLimit: PropTypes.number,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
    mobile: PropTypes.shape({
        codes: PropTypes.array,
        code: PropTypes.any
    }),
    hide: PropTypes.bool,
    isSearch: PropTypes.bool,
    isToggle: PropTypes.bool,
    value: PropTypes.any,
    renderValue: PropTypes.any,
    style: PropTypes.object,
    upload: PropTypes.any,
    textarea: PropTypes.bool,
    maxNum: PropTypes.any
};

export default Input;
