import { toFormFields } from "../../../common/utilities/helper";

export const VIEW_MODAL_TYPE = {
    GEOZONE: 0x2
};

export const BASE_CLASS = "tk-employees-work-type__modal-content-update";

export const FIELDS = {
    NAME: {
        name: "name",
        label: "Name",
        default: "",
        required: true
    },
    TYPE: {
        name: "type",
        label: "Type",
        default: "",
        required: true
    },
    CODE: {
        name: "code",
        label: "Code",
        default: "",
        required: false
    },
    COMPANY_SITES: {
        name: "CompanySites",
        label: "Sites",
        default: [],
        required: false
    }
};

export const FORM_FIELDS = toFormFields(FIELDS, "default");
