import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useGetAllRolesMutation } from "./api";
import { selectTableConfig, selectData, setState, selectView } from "./slice";
import CreateTableHeaders from "./CreateTableHeaders";
import TableView from "../../../common/components/extra/table/TableView";
import { useAppDispatch, useAppSelector } from "../../../common/hooks/reduxHooks";
import { VIEW_TYPES } from "../../../common/utilities/const";
import UpsertModal from "./UpsertModal";
import { TOAST_TYPE, createToast } from "../../../common/utilities/helper";

function Roles({ openAddModal, onAddModalClose }) {
    const [fetching, setFetching] = useState(true);
    const [selected, setSelected] = useState(null);
    const [openModal, setOpenModal] = useState(openAddModal);

    const dispatch = useAppDispatch();
    const tableConfig = useAppSelector(selectTableConfig);
    const data = useAppSelector(selectData);
    const view = useAppSelector(selectView);

    const [load] = useGetAllRolesMutation();

    const fetch = async (config) => {
        if (!fetching) {
            setFetching(true);
        }
        const response = await load({ params: { ...tableConfig, ...(config || {}) } });
        if (response.data && response.data.data) {
            dispatch(
                setState({
                    data: response.data.data.data,
                    tableConfig: { ...tableConfig, ...(config || {}), totalPage: response.data.data.totalPage }
                })
            );
        }
        if (response.error) {
            createToast("Failed to fetch data. Please try again later.", TOAST_TYPE.ERROR);
        }
        setFetching(false);
        return response;
    };

    useEffect(() => {
        if (!data.length) fetch();
        else setFetching(false);
    }, []);

    useEffect(() => {
        if (openAddModal && openAddModal !== openModal) setOpenModal(openAddModal);
    }, [openAddModal]);

    const handleModalChange = (bool) => {
        setOpenModal(bool);
        if (!bool) {
            setSelected(null);
            typeof onAddModalClose === "function" && onAddModalClose();
        }
    };

    const handleModalCancel = () => {
        setOpenModal(false);
        setSelected(null);
        typeof onAddModalClose === "function" && onAddModalClose();
    };

    return (
        <>
            {(() => {
                switch (view) {
                    default:
                    case VIEW_TYPES.TABLE:
                        return (
                            <TableView
                                data={data}
                                headers={CreateTableHeaders({
                                    onEdit: (row) => {
                                        setOpenModal(true);
                                        setSelected(row);
                                    }
                                })}
                                refetch={fetch}
                                isLoading={fetching}
                                tableConfig={tableConfig}
                            />
                        );
                }
            })()}
            {openModal && (
                <UpsertModal open={openModal} onChange={handleModalChange} onCancel={handleModalCancel} onFinish={fetch} update={selected} />
            )}
        </>
    );
}

Roles.propTypes = {
    openAddModal: PropTypes.bool,
    onAddModalClose: PropTypes.func
};

export default Roles;
