import React from "react";
import MiniCard from "./MiniCard";

import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectUser } from "../../common/slice";

function SuperAdminDashboard() {
    const user = useAppSelector(selectUser);
    return (
        <div className="tk-dashboard-super flex gap-05">
            <div className="tk-dashboard-super__inner">
                <div className="tk-dashboard-super__content">
                    <div className="tk-dashboard-super__title">
                        <h2>SuperAdmin - Dashboard</h2>
                    </div>
                    <div className="tk-dashboard-super__content__inner">
                        <MiniCard title="Active Account Registration(s)" value={user.registeredAccounts} />
                        <MiniCard title="Verified Companie(s)" value={user.verifiedCompanies} />
                        <MiniCard title="Active Package Subscription(s)" value={user.activeSubscriptions} />
                        <MiniCard title="Registered Employee(s)" value={user.registeredEmployees} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SuperAdminDashboard;
