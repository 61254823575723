import React from "react";
import Company from "../../features/company/auth/Company";

function SignInPage() {
    return (
        <div className="tk-signin">
            <Company />
        </div>
    );
}

export default SignInPage;
