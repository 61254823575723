import { useEffect, useState } from "react";
import cloneDeep from "lodash/cloneDeep";
import { TOAST_TYPE, createToast } from "../../../common/utilities/helper";
import { useAppDispatch, useAppSelector } from "../../../common/hooks/reduxHooks";
import {
    selectCurrent,
    selectTableConfig,
    selectEmployeeSubmittedFormData,
    setCurrent,
    setEmployeeSubmittedFormData,
    setState,
    updateEmployeeSubmittedFormData,
    selectSearching,
    setSearching
} from "./slice";
import { useGetEmployeeSubmittedFormsMutation, useLoadAllEmployeeSubmittedFormsMutation, useApproveEmployeeSubmittedFormsMutation } from "./api";
import { SUBMITTED_FORM_STATUS } from "../../../common/utilities/const";
import { SUBMITTED_FORM_TYPE } from "./const";
import { useGetWorkHistory } from "../employeeWorkHistories/hooks";
import { EDIT_TYPE } from "../employeeWorkHistories/const";
import { defaultTableConfig, setState as setHistoryState } from "../employeeWorkHistories/slice";

export const useGetSubmittedForm = (id) => {
    const [fetching, setFetching] = useState(true);
    const [getDetails] = useGetEmployeeSubmittedFormsMutation();

    const [history, { fetch: fetchHistory, clearCurrent: clearHistory }] = useGetWorkHistory();

    const dispatch = useAppDispatch();
    const current = useAppSelector(selectCurrent);

    const getHistoryEditType = () => {
        let editType = EDIT_TYPE.DEFAULT;
        if (!current) {
            return editType;
        }
        if (current.type == SUBMITTED_FORM_TYPE.WH_TIMING) {
            editType = EDIT_TYPE.TIMING;
        }
        if (current.type == SUBMITTED_FORM_TYPE.WH_OVERTIME) {
            editType = EDIT_TYPE.OVERTIME;
        }
        if (current.status != SUBMITTED_FORM_STATUS.PENDING && current.type != SUBMITTED_FORM_TYPE.WH_ABSENT) {
            editType = EDIT_TYPE.READ_ONLY;
        }
        return editType;
    };

    const createVars = (data) => {
        if (!data) return {};
        return {
            toUpdateHistoryId: data.formData?.historyId,
            toCreateHistoryDate: data.formData?.date,
            isRejected: data.status == SUBMITTED_FORM_STATUS.REJECTED,
            isApproved: data.status == SUBMITTED_FORM_STATUS.APPROVED,
            isPending: data.status == SUBMITTED_FORM_STATUS.PENDING,
            history,
            historyEditType: getHistoryEditType()
        };
    };

    const fetch = async ({ force } = {}) => {
        try {
            if (!force && current && current.id === id) {
                setFetching(false);
                return Promise.resolve();
            }
            const result = await getDetails({ extraPath: id });
            if (result.error) {
                throw new Error("Failed to fetch submitted forms. Please try again later");
            }
            const finRes = cloneDeep(result.data.data);
            const historyId = finRes && finRes.formData?.historyId;
            await handleFetchHistory(historyId);
            dispatch(setCurrent(finRes));
            return finRes;
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
            return {};
        } finally {
            setFetching(false);
        }
    };

    const handleFetchHistory = (newid) => {
        const historyId = newid || (current && current.formData?.historyId);
        if (historyId) {
            return fetchHistory(historyId);
        }
    };

    const updateCurrent = (newCurrent = {}) => dispatch(setCurrent({ ...current, ...(newCurrent || {}) }));

    const clearCurrent = ({ onlyHistory } = {}) => {
        !onlyHistory && dispatch(setCurrent(null));
        clearHistory();
    };

    useEffect(() => {
        fetch();
    }, []);

    return [current, { isLoading: fetching, config: createVars(current), updateCurrent, fetch, clearCurrent, fetchHistory: handleFetchHistory }];
};

export const usePaginateSubmittedForms = () => {
    const [isLoading, setIsLoading] = useState(true);

    const dispatch = useAppDispatch();
    const data = useAppSelector(selectEmployeeSubmittedFormData);
    const tableConfig = useAppSelector(selectTableConfig);
    const searching = useAppSelector(selectSearching);

    const [load] = useLoadAllEmployeeSubmittedFormsMutation();

    const fetch = async (config, isReset) => {
        if (searching) {
            return;
        }
        if (!isLoading) {
            setIsLoading(true);
        }
        if (isReset) {
            config.cursor = "";
        }
        try {
            const response = await load({
                body: {
                    ...tableConfig,
                    ...(config || {}),
                    filter: { ...(tableConfig?.filter || {}), ...(config?.filter || {}) }
                }
            });
            if (response.data && response.data.data) {
                if (typeof setState === "function") {
                    const result = response.data.data;
                    const oldConfig = { ...tableConfig, ...(config || {}) };
                    dispatch(
                        setState({
                            data: result.data,
                            tableConfig: {
                                ...oldConfig,
                                totalPage: result.totalPage,
                                totalCount: result?.totalCount
                            }
                        })
                    );
                }
            }
            if (response.error) {
                throw new Error("Failed to fetch submitted forms. Please try again later.");
            }
            return response;
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSearchFetching = async () => {
        try {
            dispatch(setSearching(true));
            await fetch();
        } finally {
            dispatch(setSearching(false));
        }
    };

    useEffect(() => {
        if (!data.length) {
            fetch();
        } else {
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        handleSearchFetching();
    }, [tableConfig.search]);

    const handleUpdate = (id, newObject = {}) => {
        const newdata = data.map((record) => {
            if (record.id == id) {
                record = {
                    ...record,
                    ...(newObject || {})
                };
            }
            return record;
        });
        dispatch(setEmployeeSubmittedFormData(newdata));
    };

    const handleFilter = async (filter) => {
        try {
            dispatch(setSearching(true));
            await fetch(filter ? { filter } : {}, true);
        } finally {
            dispatch(setSearching(false));
        }
    };

    return [data, { isLoading, fetch, update: handleUpdate, isSearching: searching, onFilter: handleFilter }];
};

export const useUpdateSubmittedFormStatus = (updateId) => {
    const [update, { isLoading }] = useApproveEmployeeSubmittedFormsMutation();
    const [, { fetch: fetchHistory }] = useGetWorkHistory();

    const dispatch = useAppDispatch();

    const updateStatus = async (newObject = {}) => {
        try {
            if (!("isReject" in newObject)) {
                throw new Error("Select a status first.");
            }
            const clonedform = cloneDeep(newObject);
            const result = await update({ body: { isReject: clonedform.isReject, historyId: clonedform.historyId }, extraPath: updateId });

            if (result.error) {
                throw new Error(result.error?.data?.message);
            }
            if (result.data) {
                if (result.data?.data) {
                    createToast(`Submitted form status updated succesfully.`, TOAST_TYPE.SUCCESS);
                } else {
                    createToast(result.data.message, TOAST_TYPE.SUCCESS);
                }
            }
            dispatch(
                updateEmployeeSubmittedFormData({
                    id: updateId,
                    data: {
                        status: newObject.isReject ? SUBMITTED_FORM_STATUS.REJECTED : SUBMITTED_FORM_STATUS.APPROVED
                    }
                })
            );
            if (result.data.data) {
                const finRes = cloneDeep(result.data.data);
                const historyId = finRes && finRes.formData?.historyId;
                if (historyId) {
                    await fetchHistory(historyId, true);
                }
                dispatch(setCurrent(finRes));
                dispatch(
                    setHistoryState({
                        data: [],
                        tableConfig: defaultTableConfig
                    })
                );
            }
            return result.data.data;
        } catch (error) {
            createToast(
                `Failed to update the status of the submitted form. ${error?.message || "Please try again later or contact support."} `,
                TOAST_TYPE.ERROR
            );
            return { error };
        }
    };
    return [updateStatus, isLoading];
};
