import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../common/hooks/reduxHooks";
import PageLayout from "../../common/components/layout/PageLayout";
import Tabs from "../../common/components/extra/Tabs";

import { createWorkShiftTabs } from "../../features/company/employeeWorkShift/helper";
import { TABS } from "../../features/company/employeeWorkShift/const";
import { useRegularWorkShiftStates, useHolidayWorkShiftStates } from "../../features/company/employeeWorkShift/hooks";
import { selectSidebarCollapse } from "../../features/common/slice";

function EmployeeWorkShifts() {
    const [activeTab, setActiveTab] = useState(TABS.REGULAR_WORKSHIFT.id);
    const [openAddModal, setOpenAddModal] = useState(false);

    const dispatch = useAppDispatch();

    const regularWorkShiftStates = useRegularWorkShiftStates();
    const holidayWorkShiftStates = useHolidayWorkShiftStates();
    const isSidebarCollapse = useAppSelector(selectSidebarCollapse);

    const getActiveState = () => {
        switch (activeTab) {
            case TABS.REGULAR_WORKSHIFT.id:
                return regularWorkShiftStates;
            case TABS.HOLIDAY_WORKSHIFT.id:
                return holidayWorkShiftStates;
            default:
                return {};
        }
    };

    const handleAdd = () => setOpenAddModal(true);

    const handleSearch = (value) =>
        getActiveState().setTableConfig && dispatch(getActiveState().setTableConfig({ search: (value && value.toLowerCase().trim()) || "" }));

    const isLoading = getActiveState()?.isSearching;

    const TAB_PROPS = {
        loading: !!isLoading,
        openAddModal,
        onAddModalClose: () => setOpenAddModal(false),
        scale: 0.99
    };

    return (
        <PageLayout
            className="tk-employees-work-shift"
            title="Manage Work Shifts"
            search={{
                onSearch: handleSearch,
                value: getActiveState().config?.search,
                placeholder: getActiveState().searchPlaceholder
            }}
            filter={getActiveState().Filter}
            onAdd={handleAdd}
            hasFilter={false}
        >
            <Tabs
                styles={{
                    parent: {
                        width: getActiveState()?.data?.length ? "min-content" : "100%"
                    },
                    head: {
                        width: isSidebarCollapse ? "calc(100vw - 12rem)" : "calc(100vw - 24rem)",
                        minWidth: "15rem"
                    },
                    body: {
                        padding: 0,
                        overflow: "unset",
                        flexGrow: "1",
                        height: "calc(100vh - 27rem)"
                    },
                    content: {
                        padding: 0
                    }
                }}
                items={createWorkShiftTabs(TABS, TAB_PROPS, activeTab)}
                onChange={(tab) => setActiveTab(tab.id)}
                hasBackground={!getActiveState()?.data?.length}
            />
        </PageLayout>
    );
}

export default EmployeeWorkShifts;
