import React, { useState } from "react";
import PropTypes from "prop-types";
import cloneDeep from "lodash/cloneDeep";
import AddIcon from "@mui/icons-material/Add";
import AsyncSelectLazy from "../../../common/components/extra/AsyncSelectLazy";
import { setClearCache } from "./slice";
import MyTooltip from "../../../common/components/extra/Tooltip";
import UpdateModal from "./UpdateModal";
import { useAppDispatch } from "../../../common/hooks/reduxHooks";
import { useLazyWorkDetails } from "./hooks";

function WorkDetailSelectLazy(props) {
    const [openAddModal, setOpenAddModal] = useState(false);

    const [object, , { isLoading, loadMore, search, reset }] = useLazyWorkDetails({
        startFrom: props?.value?.name,
        allowInUse: props.allowInUse,
        defaultValue: Array.isArray(props.value) ? props.value.map((v) => v.id) : props?.value?.id
    });

    const dispatch = useAppDispatch();

    const isDisabled = props.isDisabled;
    const getValue = !props.isMulti
        ? props.value && Object.keys(props.value).length && object.data.find((item) => item.id === props.value.id)
        : object.data.filter((item) => props.value.map((v) => v.id).includes(item.id));

    const handleSearch = (newInput, type) => {
        const allowedActions = ["input-change"];
        if (allowedActions.includes(type.action)) {
            search(newInput);
        }
    };

    const handleChange = (val) => {
        let temp = val;
        if (temp) {
            temp = cloneDeep(temp);
            delete temp.label;
            delete temp.value;
        }
        typeof props.onChange === "function" && props.onChange(temp);
    };

    const handleFinish = async () => {
        dispatch(setClearCache());
        await reset();
        setOpenAddModal(false);
    };

    return (
        <div className="flex gap-1 center">
            <AsyncSelectLazy
                {...props}
                filterOption={(options, newInputs) => {
                    const search = newInputs?.toLowerCase().trim();
                    if (options?.data?.keywords?.toLowerCase().includes(search)) {
                        return true;
                    } else {
                        return false;
                    }
                }}
                onChange={handleChange}
                value={getValue}
                options={object.data}
                onLoadMore={loadMore}
                onSearch={handleSearch}
                isFetching={isLoading}
                style={{ flex: 1 }}
            />
            <MyTooltip message={!isDisabled && "Add Work Type"} style={{ display: "flex" }}>
                <AddIcon className={`hover-svg primary-color ${isDisabled ? "disabled" : ""}`} onClick={() => setOpenAddModal(!openAddModal)} />
            </MyTooltip>
            {openAddModal && <UpdateModal open={openAddModal} onChange={(bool) => setOpenAddModal(bool)} onFinish={handleFinish} nosite />}
        </div>
    );
}

WorkDetailSelectLazy.propTypes = {
    onChange: PropTypes.func,
    isOutlined: PropTypes.bool,
    label: PropTypes.string,
    noborder: PropTypes.bool,
    style: PropTypes.object,
    value: PropTypes.any,
    isMulti: PropTypes.bool,
    allowInUse: PropTypes.bool,
    isDisabled: PropTypes.bool
};

export default WorkDetailSelectLazy;
