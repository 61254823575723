import React from "react";
import PropTypes from "prop-types";
import { useDroppable } from "@dnd-kit/core";
import Empty from "../Empty";

function Droppable(props) {
    const { setNodeRef, isOver } = useDroppable({
        id: props.id,
        data: props.data,
        disabled: props.isDisabled
    });

    const createClassName = () => {
        let main = "drop-zone ";
        if (isOver) {
            main += "drop-zone--is-dropping ";
        }
        if (props.isDisabled) {
            main += "drop-zone--is-disabled ";
        }
        if (props.className) {
            main += `${props.className} `;
        }
        return main.trim();
    };

    return (
        <div ref={setNodeRef} className={createClassName()} style={props.style} id={props.id}>
            {props.isDisabled || props.isLoading ? (
                <Empty
                    className="abs-center"
                    iconStyle={{ width: "6rem" }}
                    message={
                        <span className="flex" style={{ marginTop: "-1.5rem" }}>
                            Dropping is not allowed.
                        </span>
                    }
                    loadingMessage={props.loadingMessage || "Please wait..."}
                    isLoading={props.isLoading}
                />
            ) : (
                props.children
            )}
        </div>
    );
}

export default Droppable;

Droppable.propTypes = {
    id: PropTypes.string,
    data: PropTypes.object,
    children: PropTypes.any,
    className: PropTypes.string,
    style: PropTypes.object,
    isDisabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    loadingMessage: PropTypes.string
};
