import React, { useState } from "react";
import PropTypes from "prop-types";
import { selectTableConfig } from "./slice";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import CreateTableHeaders from "./CreateTableHeaders";
import TableView from "../../../common/components/extra/table/TableView";
import { usePaginateSubmittedForms } from "./hooks";
import ViewModal from "./ViewModal";
import { TABLE_ACTION_TYPE_COMMON } from "../../../common/utilities/const";

function SubmittedForms() {
    const [openViewModal, setOpenViewModal] = useState(false);
    const [selected, setSelected] = useState(null);

    const [data, { isLoading: isPaginating, fetch }] = usePaginateSubmittedForms();

    const tableConfig = useAppSelector(selectTableConfig);

    const updateSelected = (row) => {
        setSelected(row);
        setOpenViewModal(!!row);
    };

    const handleAction = (row, actionType) => {
        switch (actionType) {
            case TABLE_ACTION_TYPE_COMMON.PREVIEW:
                updateSelected(row);
                break;
            default:
                break;
        }
    };

    const handleViewModalClose = () => updateSelected(null);

    return (
        <>
            <TableView
                data={data}
                headers={CreateTableHeaders({ onAction: handleAction })}
                refetch={fetch}
                tableConfig={tableConfig}
                isLoading={isPaginating}
            />
            {openViewModal && <ViewModal id={selected?.id} open={openViewModal} onClose={handleViewModalClose} />}
        </>
    );
}

SubmittedForms.propTypes = {
    openAddModal: PropTypes.bool,
    onAddModalClose: PropTypes.func
};

export default SubmittedForms;
