import React, { useEffect, useState } from "react";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import { TOAST_TYPE, createToast, toReadableSelectOptions } from "../../../common/utilities/helper";
import { useAppDispatch, useAppSelector } from "../../../common/hooks/reduxHooks";
import {
    selectCurrent,
    selectTableConfig,
    setCurrent,
    setState,
    selectLoading,
    setLoading,
    selectCompanyFormsData,
    setCompanyFormData,
    selectSearching,
    setSearching
} from "./slice";
import {
    useCreateCompanyFormsMutation,
    useUpdateCompanyFormsMutation,
    useDeleteCompanyFormsMutation,
    useGetCompanyFormsMutation,
    useLoadAllCompanyFormsMutation,
    useGetActiveFieldsMutation,
    useValidateCompanyFormsMutation
} from "./api";
import { FORM_TYPE, FORM_STATUS } from "../../../common/utilities/const";
import Tag from "../../../common/components/extra/Tag";
import { FIELD_OBJECT } from "./const";
import { FIELD } from "./const";
import { useLocation, useParams } from "react-router-dom";

const { NAME, TYPE, FIELDS, STATUS } = FIELD;

export const useGetCompanyForm = (id, callback) => {
    const [isMounted, setMounted] = useState(false);
    const [fetching, setFetching] = useState(!!id);
    const [getDetails] = useGetCompanyFormsMutation();

    const dispatch = useAppDispatch();
    const current = useAppSelector(selectCurrent);

    const createVars = (data) => {
        if (!data) return {};

        return {};
    };

    const fetch = async () => {
        if (!id) return;

        try {
            if (current && current.id === id) {
                setFetching(false);
                return Promise.resolve();
            }
            const result = await getDetails({ extraPath: id });
            if (result.error) {
                throw new Error("Failed to fetch work history. Please try again later");
            }
            dispatch(setCurrent(result.data.data));
            callback?.(result.data.data);
            return result.data.data;
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
            return {};
        } finally {
            setFetching(false);
        }
    };

    const updateCurrent = (newCurrent = {}) => {
        dispatch(setCurrent({ ...current, ...(newCurrent || {}) }));
    };

    useEffect(() => {
        setMounted(true);
    }, []);

    useEffect(() => {
        if (isMounted) {
            fetch();
        }
    }, [isMounted]);

    return [current, { isLoading: fetching, config: createVars(current), update: updateCurrent, fetch }];
};

export const usePaginateCompanyForm = () => {
    const [isLoading, setIsLoading] = useState(true);

    const dispatch = useAppDispatch();
    const data = useAppSelector(selectCompanyFormsData);
    const tableConfig = useAppSelector(selectTableConfig);
    const searching = useAppSelector(selectSearching);

    const [load] = useLoadAllCompanyFormsMutation();

    const fetch = async (config) => {
        if (searching) {
            return;
        }
        if (!isLoading) {
            setIsLoading(true);
        }
        try {
            const response = await load({ body: { ...tableConfig, ...(config || {}) } });
            if (response.data && response.data.data) {
                if (typeof setState === "function") {
                    const result = response.data.data;
                    const oldConfig = { ...tableConfig, ...(config || {}) };
                    dispatch(
                        setState({
                            data: result.data,
                            tableConfig: {
                                ...oldConfig,
                                totalPage: result.totalPage,
                                totalCount: result.totalCount
                            }
                        })
                    );
                }
            }
            if (response.error) {
                throw new Error("Failed to fetch company forms. Please try again later.");
            }
            return response;
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSearchFetching = async () => {
        try {
            dispatch(setSearching(true));
            await fetch();
        } finally {
            dispatch(setSearching(false));
        }
    };

    useEffect(() => {
        handleSearchFetching();
    }, [tableConfig.search]);

    useEffect(() => {
        if (!data.length) {
            fetch();
        } else {
            setIsLoading(false);
        }
    }, []);

    const handleUpdate = (id, newObject = {}) => {
        const newdata = data.map((record) => {
            if (record.id == id) {
                record = {
                    ...record,
                    ...(newObject || {})
                };
            }
            return record;
        });
        dispatch(setCompanyFormData(newdata));
    };

    return [data, { isLoading, fetch, update: handleUpdate }];
};

export const useUpsertCompanyForm = (updateId, callback) => {
    const isCreate = !updateId;

    const getStatusBool = (status) => !!(status == FORM_STATUS.ACTIVE);
    const getStatusConst = (status) => (status ? FORM_STATUS.ACTIVE : FORM_STATUS.INACTIVE);

    const [old, setOld] = useState(null);
    const [form, setForm] = useState({
        [NAME]: FIELD_OBJECT[NAME].default,
        [TYPE]: FIELD_OBJECT[TYPE].default,
        [FIELDS]: FIELD_OBJECT[FIELDS].default,
        [STATUS]: FIELD_OBJECT[STATUS].default
    });

    const [data, { isLoading }] = useGetCompanyForm(updateId, callback);

    const [create, { isLoading: createIsLoading }] = useCreateCompanyFormsMutation();
    const [update, { isLoading: updateIsLoading }] = useUpdateCompanyFormsMutation();

    const createVars = () => {
        const typeOpt = toReadableSelectOptions(FORM_TYPE).map((t) => ({
            ...t,
            label: <Tag className="flex">{t.label}</Tag>
        }));

        return {
            typeOpt,
            isLoading: isLoading || createIsLoading || updateIsLoading,
            type: typeOpt.find((type) => type.value == form[TYPE]) || ""
        };
    };

    const upsert = async (newForm) => {
        let result = null;
        try {
            const clonedform = cloneDeep(newForm);

            clonedform[STATUS] = getStatusConst(clonedform[STATUS]);

            if (isCreate) {
                result = await create({ body: clonedform });
            } else {
                result = await update({ body: clonedform, extraPath: updateId });
            }
            if (result.error) {
                throw new Error(result.error?.data?.message);
            }
            if (result.data) {
                if (result.data?.data) {
                    createToast(`Company Form ${isCreate ? "created" : "updated"} succesfully.`, TOAST_TYPE.SUCCESS);
                } else {
                    createToast(result.data.message, TOAST_TYPE.SUCCESS);
                }
            }
            return result.data.data;
        } catch (error) {
            createToast(
                `Failed to ${!isCreate ? "update" : "create"} Company Form. ${error?.message || "Please try again later or contact support."} `,
                TOAST_TYPE.ERROR
            );
            return { error };
        }
    };

    const updateForm = (config = {}) => setForm({ ...form, ...config });

    useEffect(() => {
        if (!isCreate && !isLoading) {
            const temp = {
                [NAME]: data?.[NAME],
                [TYPE]: data?.[TYPE],
                [FIELDS]: data?.[FIELDS],
                [STATUS]: getStatusBool(data?.[STATUS])
            };
            setForm(temp);
            setOld(temp);
        }
    }, [data, updateId, isLoading]);

    const vars = createVars();

    return [
        form,
        updateForm,
        {
            upsert,
            isGettingForm: isLoading,
            isUpserting: createIsLoading || updateIsLoading,
            isLoading: vars.isLoading,
            config: vars,
            old,
            hasChangesToBaseForm: !!(old && !isCreate && !isEqual(JSON.stringify(form), JSON.stringify(old)))
        }
    ];
};

export const useDeleteCompanyForm = () => {
    const dispatch = useAppDispatch();

    const [deleteCompanyForm] = useDeleteCompanyFormsMutation();

    const isLoading = useAppSelector(selectLoading);

    const remove = async (id) => {
        if (!isLoading) {
            dispatch(setLoading(true));
        }
        try {
            const response = await deleteCompanyForm({ extraPath: id });
            if (response.error) {
                throw new Error(response.error?.data?.message || "Failed to delete company form.");
            }
            return response.data.data;
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
        } finally {
            dispatch(setLoading(false));
        }
    };

    return [remove, isLoading];
};

export const useGetActiveFields = () => {
    const [fetching, setFetching] = useState(true);
    const [load] = useGetActiveFieldsMutation();
    const [fields, setFields] = useState([]);
    const [searchFields, setSearchFields] = useState([]);

    const fetch = async () => {
        if (!fetching) {
            setFetching(true);
        }
        try {
            const response = await load();
            if (response.error) {
                throw new Error(response.error?.data?.message || "Failed to load fields.");
            }
            const data = response.data.data;
            setFields(data);
            setSearchFields(data);
            return data;
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
        } finally {
            setFetching(false);
        }
    };

    useEffect(() => {
        if (!fields.length) {
            fetch();
        }
    }, []);

    const fieldsToGroup = () => {
        return searchFields.reduce(
            (prev, current) => ({
                ...prev,
                [current.group]: [...(prev?.[current.group] || [])].concat(current)
            }),
            {}
        );
    };

    const onSearch = (value) => {
        setSearchFields(fields.filter((field) => field.name.includes(value.toLowerCase().trim())));
    };

    return [fieldsToGroup(), fetching, { refetch: fetch, onSearch, original: fields }];
};

export const useValidateCompanyForms = () => {
    const [validating, setValidating] = useState(true);
    const [isValid, setValid] = useState(false);

    const [validateForm] = useValidateCompanyFormsMutation();

    const params = useParams();
    const updateId = params.id;
    const location = useLocation();
    const current = useAppSelector(selectCurrent);
    const isUpdateRoute = location.pathname.includes("/update");

    const checkValidity = async () => {
        if (!updateId || !!current || !isUpdateRoute) {
            setValidating(false);
            if (current) {
                setValid(true);
            }
            return;
        }
        if (!validating) {
            setValidating(true);
        }
        try {
            const response = await validateForm({ extraPath: updateId });
            const data = response.data.data;
            if (response.error) {
                setValid(false);
                return false;
            }
            setValid(data.isValid);
            return data.isValid;
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
        } finally {
            setValidating(false);
        }
    };

    useEffect(() => {
        checkValidity();
    }, []);

    return [isUpdateRoute ? isValid : true, validating];
};
