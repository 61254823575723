import { createSlice } from "@reduxjs/toolkit";
import { PAGE_OPTIONS } from "../../../common/components/extra/table/const";
import { VIEW_TYPES } from "../../../common/utilities/const";

const SLICE_NAME_KEY = "roles";

const initialState = {
    data: [],
    tableConfig: {
        page: 1,
        pageSize: PAGE_OPTIONS[0],
        search: "",
        sortBy: "createdAt",
        order: "DESC",
        totalPage: 0
    },
    loading: false,
    view: VIEW_TYPES.TABLE,
    localPages: [],
    roleLevelDefaultPermissions: null,
    roleTypeDefaultPermissions: null
};

export const rolesSlice = createSlice({
    name: SLICE_NAME_KEY,
    initialState,
    reducers: {
        setData: (state, action) => {
            const data = action.payload || [];
            Array.isArray(data) && (state.data = data);
        },
        setTableConfig: (state, action) => {
            const tableConfig = action.payload;
            tableConfig && (state.tableConfig = { ...state.tableConfig, ...(action.payload || {}) });
        },
        setLoading: (state, action) => {
            const loading = action.payload;
            typeof loading === "boolean" && loading !== state.loading && (state.loading = loading);
        },
        setView: (state, action) => {
            const view = action.payload;
            view && view !== state.view && (state.view = view);
        },
        setLocalPages: (state, action) => {
            const localPages = action.payload || [];
            Array.isArray(localPages) && (state.localPages = localPages);
        },
        setRoleLevelDefaultPermissions: (state, action) => {
            const roleLevelDefaultPermissions = action.payload;
            roleLevelDefaultPermissions && (state.roleLevelDefaultPermissions = roleLevelDefaultPermissions);
        },
        setRoleTypeDefaultPermissions: (state, action) => {
            const roleTypeDefaultPermissions = action.payload;
            roleTypeDefaultPermissions && (state.roleTypeDefaultPermissions = roleTypeDefaultPermissions);
        },
        setState: (state, action) => {
            const { tableConfig, view, data, loading, localPages, roleLevelDefaultPermissions, roleTypeDefaultPermissions } = action.payload;
            tableConfig && (state.tableConfig = { ...state.tableConfig, ...tableConfig });
            data && (state.data = data || []);
            view && view !== state.view && (state.view = view);
            typeof loading === "boolean" && loading !== state.loading && (state.loading = loading);
            Array.isArray(localPages) && (state.localPages = localPages);
            roleLevelDefaultPermissions && (state.roleLevelDefaultPermissions = roleLevelDefaultPermissions);
            roleTypeDefaultPermissions && (state.roleTypeDefaultPermissions = roleTypeDefaultPermissions);
        },
        reset: () => initialState
    }
});

export const selectData = (state) => state[SLICE_NAME_KEY].data;
export const selectTableConfig = (state) => state[SLICE_NAME_KEY].tableConfig;
export const selectLoading = (state) => state[SLICE_NAME_KEY].loading;
export const selectView = (state) => state[SLICE_NAME_KEY].view;
export const selectLocalPages = (state) => state[SLICE_NAME_KEY].localPages;
export const selectRoleLevelDefaultPermissions = (state) => state[SLICE_NAME_KEY].roleLevelDefaultPermissions;
export const selectRoleTypeDefaultPermissions = (state) => state[SLICE_NAME_KEY].roleTypeDefaultPermissions;
export const selectState = (state) => state[SLICE_NAME_KEY];
export const {
    setData,
    setTableConfig,
    setLoading,
    setView,
    setLocalPages,
    setRoleLevelDefaultPermissions,
    setRoleTypeDefaultPermissions,
    setState,
    reset
} = rolesSlice.actions;
export default rolesSlice.reducer;
