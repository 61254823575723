import React from "react";
import PropTypes from "prop-types";

function Divider({ title, style }) {
    return (
        <div className="tk-hr" style={style || {}}>
            {title}
            <hr style={{ margin: "auto" }} />
        </div>
    );
}

Divider.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    style: PropTypes.object
};

export default Divider;
