import React from "react";
import { useAppSelector } from "../../common/hooks/reduxHooks";
import { selectLoading } from "../../features/company/companySites/slice";
import PageLayout from "../../common/components/layout/PageLayout";
import UpsertCompanyForm from "../../features/company/companyForms/UpsertCompanyForm";
import { Navigate, useParams } from "react-router-dom";
import { useValidateCompanyForms } from "../../features/company/companyForms/hooks";
import Loader from "../../common/components/extra/Loader";
import Navigation from "../../common/classes/Navigation";

function CompanyFormUpsertPage() {
    const params = useParams();
    const loading = useAppSelector(selectLoading);

    const [isValid, isLoading] = useValidateCompanyForms();

    if (isLoading) {
        return <Loader />;
    }
    if (!isValid) {
        return <Navigate to={Navigation.Routes.COMPANY_FORM.path} />;
    }

    return (
        <PageLayout
            className="tk-company-forms-upsert"
            title={`${params.id ? "Update" : "Create"} Form`}
            isLoading={loading}
            styles={{
                body: {
                    height: "100%"
                },
                content: {
                    height: "calc(-12rem + 100vh)",
                    flex: "unset"
                }
            }}
            noControls
        >
            <UpsertCompanyForm />
        </PageLayout>
    );
}

export default CompanyFormUpsertPage;
