import React from "react";
import PropTypes from "prop-types";

function MobileTextInput() {
    return (
        <div className="mobile-input mobile-input--text-input">
            <div className="input-box flex center">
                <span className="fade italic">Input</span>
            </div>
        </div>
    );
}

export default MobileTextInput;

MobileTextInput.propTypes = {
    details: PropTypes.object
};
