import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as LogoSVG } from "../../../assets/images/logo.svg";

function Logo({ size = 3, classNames = {}, styles = {}, hide, svg }) {
    if (hide) {
        return <></>;
    }
    return (
        <div className={`tk-logo ${classNames.parent}`.trim()} style={styles.parent}>
            {svg ? (
                <LogoSVG className="responsive-img" style={{ ...styles.image, width: `${size}rem` }} />
            ) : (
                <img className="responsive-img" src="/images/logo-clean.png" alt="" style={{ ...styles.image, width: `${size}rem` }} />
            )}
        </div>
    );
}

export default Logo;

Logo.propTypes = {
    hide: PropTypes.bool,
    svg: PropTypes.bool,
    size: PropTypes.number,
    classNames: PropTypes.shape({
        parent: PropTypes.string,
        image: PropTypes.string
    }),
    styles: PropTypes.shape({
        parent: PropTypes.object,
        image: PropTypes.object
    })
};
