import React from "react"; // Import your CSS file for styling

import PropTypes from "prop-types";

function MiniCard({ title, value }) {
    return (
        <div className="mini-card">
            {/* <h2 className="title">{title}</h2>
            <p className="value">{value}</p> */}
            <h3 className="value">{value}</h3>
            <p className="title">{title}</p>
        </div>
    );
}

MiniCard.propTypes = {
    title: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};

export default MiniCard;
