import React, { forwardRef, useEffect, useLayoutEffect, useRef } from "react";
import Quill from "quill";
import PropTypes from "prop-types";

const DEFAULT_LIMIT = 1000;

const TOOLBAR = [[{ header: [1, 2, 3, 4, false] }], ["bold", "italic", "underline"], [{ list: "ordered" }, { list: "bullet" }]];

const Editor = forwardRef(({ readOnly, defaultValue, onTextChange, onSelectionChange, onRenderChange, limit }, ref) => {
    const CHAR_LIMIT = limit || DEFAULT_LIMIT;

    const containerRef = useRef(null);
    const defaultValueRef = useRef(defaultValue);
    const onTextChangeRef = useRef(onTextChange);
    const onSelectionChangeRef = useRef(onSelectionChange);

    useLayoutEffect(() => {
        onTextChangeRef.current = onTextChange;
        onSelectionChangeRef.current = onSelectionChange;
    });

    useEffect(() => {
        ref?.current?.enable(!readOnly);
    }, [ref, readOnly]);

    useEffect(() => {
        const container = containerRef.current;
        const editorContainer = container.appendChild(container.ownerDocument.createElement("div"));
        const quill = new Quill(editorContainer, {
            theme: "snow",
            modules: {
                toolbar: TOOLBAR
            }
        });

        "current" in ref && (ref.current = quill);

        if (defaultValueRef.current) {
            const delta = quill.clipboard.convert({ html: defaultValueRef.current });
            quill.setContents(delta);
        }

        quill.on(Quill.events.TEXT_CHANGE, (...args) => {
            if (quill.getLength() > CHAR_LIMIT) {
                quill.deleteText(limit, quill.getLength());
            }

            onTextChangeRef.current?.(
                args[0],
                {
                    html: quill.getSemanticHTML(),
                    text: quill.getText().replace("\n", "")
                },
                quill.getLength()
            );
        });

        quill.on(Quill.events.SELECTION_CHANGE, (...args) => {
            onSelectionChangeRef.current?.(...args);
        });

        quill.clipboard.addMatcher(Node.ELEMENT_NODE, function (node) {
            const plaintext = node.innerText;
            const Delta = Quill.import("delta");
            return new Delta().insert(plaintext);
        });

        typeof onRenderChange == "function" && onRenderChange(quill.container.innerText.trim());

        return () => {
            ref?.current && (ref.current = null);
            container.innerHTML = "";
        };
    }, [ref]);

    return <div className="tk-editor" ref={containerRef}></div>;
});

Editor.displayName = "Editor";

export default Editor;

Editor.propTypes = {
    readOnly: PropTypes.bool,
    defaultValue: PropTypes.any,
    onTextChange: PropTypes.func,
    onSelectionChange: PropTypes.func,
    limit: PropTypes.number,
    onRenderChange: PropTypes.func
};
