import React, { useState } from "react";
import CardPage from "../../common/components/extra/CardPage";
import Button, { BTN_TYPE } from "../../common/components/extra/Button";
import Penalty from "../../features/admin/penalties/Penalty";

function PenaltiesPage() {
    const [openAddModal, setOpenAddModal] = useState(false);
    return (
        <div className="tk-penalties tk-page-type-01">
            <div className="tk-penalties__inner">
                <CardPage title="Manage Penalties" contentStyle={{ padding: 0, height: "calc(100vh - 12.3rem)" }}>
                    <div className="content">
                        <div className="control">
                            <div className="search"></div>
                            <Button type={BTN_TYPE.ADD} onClick={() => setOpenAddModal(true)} />
                        </div>
                        <div className="tk-penalties__body">
                            <Penalty openAddModal={openAddModal} onAddModalClose={() => setOpenAddModal(false)} />
                        </div>
                    </div>
                </CardPage>
            </div>
        </div>
    );
}

export default PenaltiesPage;
