import React, { useState, useEffect, forwardRef } from "react";
import PropTypes from "prop-types";
import cloneDeep from "lodash/cloneDeep";
import NextIcon from "@mui/icons-material/KeyboardArrowRight";
import BackIcon from "@mui/icons-material/KeyboardArrowLeft";
import ReplayIcon from "@mui/icons-material/Replay";
import CancelIcon from "@mui/icons-material/Cancel";
import SkipRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import Button from "../Button";
import { createConfirmAlert } from "../../../utilities/helper";
import StepLine from "./StepLine";
import { STEP_TYPE } from "./const";
import { transformSteps } from "./helper";
import Steps from "./Steps";

const StepWizard = forwardRef(function StepWizard(
    { steps = [], step, onProceed, canNext, onChange, children, resetCached, onCancel, optionals, autoSkips, onSkip },
    ref
) {
    const transformedSteps = transformSteps(steps, optionals, autoSkips);
    const defaultStep = step || transformedSteps.length > 0 ? transformedSteps[0] : null;
    const index = transformedSteps.findIndex((ts) => defaultStep && ts.id === defaultStep.id);

    if (index === -1) {
        return "";
    }

    const [object, setObject] = useState({
        swsteps: transformedSteps,
        currentIndex: index,
        current: transformedSteps[index],
        totalSteps: transformedSteps.length
    });

    const [loading, setLoading] = useState(false);

    const { swsteps, currentIndex, current, totalSteps } = object;
    const isCompleted = swsteps.length - 1 === currentIndex;
    const isFirst = currentIndex <= 0;
    const hasError = step?.error;
    const hasRetry = step?.stepNumber == 2 && !hasError;
    const hasBack = !isCompleted && !isFirst;
    const hasNext = !isCompleted && (step?.stepNumber === 1 || !hasError);
    const hasCancel = step?.stepNumber !== 1 && (hasError || step?.showCancel);
    const hasSkip = step?.optional && !canNext;

    const handleObjectChange = (newval = {}) => {
        const newobj = { ...object, ...newval };
        setObject(newobj);
        typeof onChange === "function" && onChange(newobj.current, newobj.swsteps);
    };

    useEffect(() => {
        if (!step) {
            typeof onChange === "function" && onChange(current, swsteps);
        }
    }, []);

    useEffect(() => {
        if (step) {
            const clonesteps = cloneDeep(object.swsteps);
            const findStepIndex = clonesteps.findIndex((ts) => ts.id === step.id);
            if (findStepIndex !== -1 && findStepIndex <= totalSteps - 1) {
                clonesteps[findStepIndex] = step;
                handleObjectChange({ currentIndex: findStepIndex, current: clonesteps[findStepIndex], swsteps: clonesteps });
            }
        }
    }, [step]);

    useEffect(() => {
        if (step?.error) {
            let temparr = cloneDeep(swsteps);
            const tempidx = currentIndex;
            temparr[tempidx] = { ...temparr[tempidx], ...step };
            temparr[tempidx].loading = false;
            temparr[tempidx].isAttemptingNext = false;
            temparr[tempidx].isDone = false;
            temparr[tempidx].canNext = false;
            handleObjectChange({ currentIndex: tempidx, current: temparr[tempidx], swsteps: temparr });
        }
    }, [step?.error]);

    useEffect(() => {
        if (step?.warning && !step.cached) {
            let temparr = cloneDeep(swsteps);
            const tempidx = currentIndex;
            temparr[tempidx] = { ...temparr[tempidx], ...step };
            temparr[tempidx].loading = false;
            temparr[tempidx].isAttemptingNext = false;
            temparr[tempidx].isDone = false;
            temparr[tempidx].canNext = true;
            temparr[tempidx].cached = true;
            handleObjectChange({ currentIndex: tempidx, current: temparr[tempidx], swsteps: temparr });
        }
    }, [step?.warning, step?.cached]);

    useEffect(() => {
        // when done we set the current step to cached so that the next use effect wll run when the user go to this step again and if he press next again
        // this function will not run and will run the next use effect instead
        if (step?.isDone) {
            let temparr = cloneDeep(swsteps);
            const tempidx = currentIndex;
            temparr[tempidx] = { ...temparr[tempidx], ...step };
            temparr[tempidx].loading = false;
            temparr[tempidx].isAttemptingNext = false;
            temparr[tempidx].canNext = false;
            temparr[tempidx].cached = true;
            handleNext(temparr);
        }
    }, [step?.isDone]);

    useEffect(() => {
        // we directly go to the next step if the step is cached and if isDone is false that means we go back to the step.
        if (step?.cached && step?.isAttemptingNext && !step?.isDone) {
            let temparr = cloneDeep(swsteps);
            const tempidx = currentIndex;
            temparr[tempidx] = { ...temparr[tempidx], ...step };
            temparr[tempidx].loading = false;
            temparr[tempidx].isAttemptingNext = false;
            temparr[tempidx].canNext = false;
            handleNext(swsteps);
        }
    }, [step?.cached, step?.isAttemptingNext, step?.isDone]);

    useEffect(() => {
        if (resetCached) {
            const clonesteps = cloneDeep(swsteps).map((s) => ({ ...s, cached: false })); // reset all cached to false
            // we make sure we also get the updated value of step then we reset cache again since its possible that cache is true agaim here
            const current = { ...clonesteps[object.currentIndex], ...step, cached: false };
            handleObjectChange({ current, swsteps: clonesteps });
        }
    }, [resetCached]);

    useEffect(() => {
        if (step?.retry) {
            retry();
        }
    }, [step?.retry]);

    useEffect(() => {
        if (step?.autoSkip) {
            handleSkip();
        }
    }, [step?.autoSkip]);

    const handleProceed = async () => {
        let temparr = cloneDeep(swsteps);
        const tempidx = currentIndex;
        if (!isCompleted) {
            temparr[tempidx].loading = true;
            temparr[tempidx].isAttemptingNext = true;
            temparr[tempidx].isDone = false;
            handleObjectChange({ currentIndex: tempidx, current: temparr[tempidx], swsteps: temparr });
        }
    };

    const nextCurrentIndex = (newsteps = swsteps) => {
        const nxtidx = currentIndex + 1;
        const value = nxtidx <= totalSteps - 1 ? nxtidx : currentIndex;
        const isFinal = value === totalSteps - 1;
        const res = { currentIndex: value };
        const temparr = cloneDeep(newsteps);
        if (isFinal) {
            temparr[value].preventBack = true;
        }
        res.swsteps = temparr;
        res.current = temparr[res.currentIndex];
        handleObjectChange(res);
    };

    const handleNext = async (newarr) => {
        let temparr = cloneDeep(newarr || swsteps);
        const tempidx = currentIndex;
        if (isCompleted) {
            return;
        }
        try {
            if (typeof onProceed === "function") {
                setLoading(true);
                const res = await onProceed(temparr[tempidx]);
                if (res) {
                    temparr[tempidx] = res;
                }
                setLoading(false);
            }
        } catch (error) {
            temparr[tempidx].error = !!error;
            temparr[tempidx].loading = 0;
            typeof onChange === "function" && onChange(temparr[tempidx], temparr);
        } finally {
            if (!temparr[tempidx].error) {
                temparr[tempidx].loading = 100;
                nextCurrentIndex(temparr);
            } else {
                handleObjectChange({ currentIndex: tempidx, current: temparr[tempidx], swsteps: temparr });
            }
            setLoading(false);
        }
    };

    const prevCurrentIdex = () => {
        if (isFirst) {
            return;
        }
        const defobj = {
            loading: false,
            isAttemptingNext: false,
            canNext: false,
            isDone: false,
            error: false,
            skip: false
        };
        const temparr = cloneDeep(swsteps);
        const previdx = currentIndex - 1;
        let value = previdx >= 0 ? previdx : currentIndex;
        const prevVal = temparr?.[value];
        temparr[currentIndex] = {
            ...temparr[currentIndex],
            ...defobj
        };
        temparr[value] = {
            ...temparr[value],
            ...defobj,
            canNext: temparr[value].skip ? false : temparr[value].canNext
        };
        // temporary jump to step 1 if prev is autoskip
        if (prevVal && prevVal.autoSkip) {
            value = 0;
            temparr[value] = {
                ...temparr[value],
                ...defobj,
                canNext: temparr[value].skip ? false : temparr[value].canNext
            };
        }
        handleObjectChange({ currentIndex: value, current: temparr[value], swsteps: temparr });
    };

    const retry = () => {
        let temparr = cloneDeep(swsteps);
        const tempidx = 0;
        temparr = temparr.map((st) => ({
            ...st,
            loading: false,
            isAttemptingNext: false,
            canNext: false,
            error: false,
            isDone: false,
            cached: false
        }));
        handleObjectChange({ currentIndex: tempidx, current: temparr[tempidx], swsteps: temparr });
        typeof onCancel === "function" && onCancel();
    };

    const handleSkip = async () => {
        let temparr = cloneDeep(swsteps);
        const tempidx = currentIndex;
        temparr[tempidx].skip = true;
        temparr[tempidx].isDone = true;
        temparr[tempidx].loading = 100;
        typeof onSkip === "function" && onSkip({ ids: tempidx, steps: temparr, step: temparr[tempidx] });
        nextCurrentIndex(temparr);
    };

    const createControls = () => {
        return (
            <div className="controls">
                {hasRetry && (
                    <Button
                        className="danger"
                        onClick={() =>
                            createConfirmAlert({
                                title: "Retry the process from the start?",
                                content: "All your progress will be lost.",
                                onConfirm: (onClose) => {
                                    retry();
                                    onClose();
                                }
                            })
                        }
                        disabled={!!step?.loading}
                        afterExtra={<ReplayIcon />}
                    >
                        Retry
                    </Button>
                )}
                {hasBack && (
                    <Button onClick={() => prevCurrentIdex()} disabled={!!step?.loading} beforeExtra={<BackIcon style={{ width: "1.5rem" }} />}>
                        Back
                    </Button>
                )}
                {hasNext && (
                    <Button
                        ref={ref}
                        className="primary"
                        onClick={() =>
                            createConfirmAlert({
                                title: "Proceed to next step?",
                                content:
                                    totalSteps - 1 == step?.stepNumber
                                        ? "Proceeding will initiate the saving process for the new changes. You won't be able to go back."
                                        : "You can still navigate back to retry the process if needed.",
                                onConfirm: (onClose) => {
                                    handleProceed();
                                    onClose();
                                }
                            })
                        }
                        disabled={!!step?.loading || !canNext}
                        isLoading={step?.loading}
                        afterExtra={<NextIcon style={{ width: "1.5rem" }} />}
                    >
                        {step?.loading ? "Processing..." : "Next"}
                    </Button>
                )}
                {hasSkip && (
                    <Button
                        ref={ref}
                        className="primary"
                        onClick={() =>
                            createConfirmAlert({
                                title: "Skip step?",
                                content:
                                    totalSteps - 1 == step?.stepNumber
                                        ? "This will skip the current process and will proceed to the next step."
                                        : "You can still navigate back to retry the process if needed.",
                                onConfirm: (onClose) => {
                                    handleSkip();
                                    onClose();
                                }
                            })
                        }
                        afterExtra={<SkipRightIcon />}
                    >
                        Skip
                    </Button>
                )}
                {hasCancel && (
                    <Button
                        className="danger"
                        onClick={() =>
                            createConfirmAlert({
                                title: "Retry the process from the start?",
                                content: "All your progress will be lost.",
                                onConfirm: (onClose) => {
                                    retry();
                                    onClose();
                                }
                            })
                        }
                        disabled={!!step?.loading}
                        afterExtra={<CancelIcon />}
                    >
                        Cancel
                    </Button>
                )}
            </div>
        );
    };

    return (
        <div className="tk-step-wizard">
            <div className="tk-step-wizard__header">
                <Steps items={swsteps} currentIndex={currentIndex} total={totalSteps} />
            </div>
            <div className="tk-step-wizard__content" style={loading ? { pointerEvents: "none", cursor: "not-allowed" } : {}}>
                {children}
            </div>
            <div className="tk-step-wizard__footer">{createControls()}</div>
        </div>
    );
});

StepWizard.propTypes = {
    steps: PropTypes.arrayOf(PropTypes.string),
    step: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        stepNumber: PropTypes.number,
        name: PropTypes.string,
        error: PropTypes.bool,
        isDone: PropTypes.bool,
        retry: PropTypes.bool,
        isAttemptingNext: PropTypes.bool,
        canNext: PropTypes.bool,
        showCancel: PropTypes.bool,
        loading: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
        cached: PropTypes.bool,
        warning: PropTypes.bool,
        optional: PropTypes.bool,
        autoSkip: PropTypes.bool
    }),
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
    onProceed: PropTypes.func,
    onChange: PropTypes.func,
    onCancel: PropTypes.func,
    canNext: PropTypes.bool,
    resetCached: PropTypes.bool,
    optionals: PropTypes.array,
    autoSkips: PropTypes.array,
    onSkip: PropTypes.func
};

StepLine.propTypes = {
    step: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        stepNumber: PropTypes.number,
        name: PropTypes.string,
        error: PropTypes.bool,
        isDone: PropTypes.bool,
        isAttemptingNext: PropTypes.bool,
        canNext: PropTypes.bool,
        autoSkip: PropTypes.bool,
        loading: PropTypes.oneOfType([PropTypes.number, PropTypes.bool])
    }),
    type: PropTypes.oneOf(Object.values(STEP_TYPE)),
    hasNext: PropTypes.bool,
    active: PropTypes.bool,
    loading: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    styles: PropTypes.shape({
        item: PropTypes.object
    })
};

export default StepWizard;
