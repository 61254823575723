import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useEmployeeFileMutation } from "./api";
import Modal from "../../../common/components/extra/Modal";
import Input, { INPUT_TYPE } from "../../../common/components/extra/form/Input";
import FormSection from "../../../common/components/extra/FormSection";
import { VIEW_MODAL_TYPE } from "./const";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectUser } from "../../common/slice";
import { TOAST_TYPE, createToast, renderNA, addCommasToMoney } from "../../../common/utilities/helper";

const { PHOTO, ID_COPY, VISA_COPY, PASSPORT_COPY, CONTRACT_COPY, JOB_DESCRIPTION, SALARY } = VIEW_MODAL_TYPE;

function ViewModalSub({ open, onChange, data, type }) {
    const [b64file, setB64file] = useState(null);
    const [getFile] = useEmployeeFileMutation();
    const user = useAppSelector(selectUser);
    const setting = user.Setting;
    const contract = data?.EmployeeContract || {};
    const allowance = contract.allowance || {};
    const uploads = data?.uploads || {};

    const fetchFile = async (ftype) => {
        let current = null;

        if (!ftype) {
            return Promise.resolve();
        }
        const checkIfContinueFetch = (file) => {
            return typeof file === "boolean" && file === true;
        };
        if (ftype === PHOTO) {
            current = uploads.photo;
            ftype = "photo";
        } else if (ftype === ID_COPY) {
            current = uploads.residence;
            ftype = "residence";
        } else if (ftype === PASSPORT_COPY) {
            current = uploads.passport;
            ftype = "passport";
        } else if (ftype === CONTRACT_COPY) {
            current = uploads.contract;
            ftype = "contract";
        } else if (ftype === VISA_COPY) {
            current = uploads.visa;
            ftype = "visa";
        }
        if (checkIfContinueFetch(current)) {
            const residenceID = data.residenceID;
            const response = await getFile({ extraPaths: [residenceID, ftype] });
            if (response.data) {
                setB64file(response.data.data);
            }
            if (response.error) {
                createToast("Failed to get file. Please try again later or contact support.", TOAST_TYPE.ERROR);
            }
        } else {
            setB64file(current);
        }
    };

    useEffect(() => {
        fetchFile(type);
    }, []);

    const checkIfImage = (conf) => {
        return (typeof conf === "string" && conf.startsWith("image")) || (typeof conf === "string" && conf.startsWith("data:image"));
    };

    const checkIfPDF = (conf) => {
        return (
            (typeof conf === "string" && conf.startsWith("application/pdf")) || (typeof conf === "string" && conf.startsWith("data:application/pdf"))
        );
    };

    const fileRender = (type, file, style = {}, title) => {
        let isImage = checkIfImage(type || file);
        let isPDF = checkIfPDF(type || file);
        let isURI = !!type;

        if (!isImage && !isPDF) {
            return (
                <div className="empty">
                    <p className="fade">No {title} uploaded.</p>
                </div>
            );
        }

        if (isImage) {
            return <img src={isURI && file ? URL.createObjectURL(file) : file} alt="Preview" style={{ maxWidth: "100%", ...style }} />;
        }
        if (isPDF) {
            return (
                <embed
                    style={{
                        height: "80vh",
                        width: "70vw",
                        position: "relative",
                        minWidth: "20rem",
                        ...style
                    }}
                    src={isURI && file ? URL.createObjectURL(file) : file}
                    width="500"
                    height="375"
                    type="application/pdf"
                />
            );
        }
    };

    return (() => {
        let children,
            title,
            style = {};
        switch (type) {
            case PHOTO: {
                title = "Photo";
                children = (
                    <div className="tk-employees__modal-content-view__file">{fileRender(uploads.photo?.type, b64file, { width: "5rem" }, title)}</div>
                );
                style.maxWidth = "60rem";
                break;
            }
            case ID_COPY: {
                title = "ID Copy";
                children = (
                    <div className="tk-employees__modal-content-view__file">{fileRender(uploads.residenceID_copy?.type, b64file, {}, title)}</div>
                );
                checkIfImage(uploads.residenceID_copy?.type || b64file) && (style.maxWidth = "60rem");
                break;
            }
            case VISA_COPY: {
                title = "Visa Copy";
                children = <div className="tk-employees__modal-content-view__file">{fileRender(uploads.visa_copy?.type, b64file, {}, title)}</div>;
                checkIfImage(uploads.visaID_copy?.type || b64file) && (style.maxWidth = "60rem");
                break;
            }
            case PASSPORT_COPY: {
                title = "Passport Copy";
                children = (
                    <div className="tk-employees__modal-content-view__file">{fileRender(uploads.passport_copy?.type, b64file, {}, title)}</div>
                );
                checkIfImage(uploads.passportID_copy?.type || b64file) && (style.maxWidth = "60rem");
                break;
            }
            case CONTRACT_COPY: {
                title = "Contract Copy";
                children = (
                    <div className="tk-employees__modal-content-view__file">{fileRender(uploads.contract_copy?.type, b64file, {}, title)}</div>
                );
                checkIfImage(uploads.contract_copy?.type || b64file) && (style.maxWidth = "60rem");
                break;
            }
            case JOB_DESCRIPTION: {
                title = "Job Description";
                children = (
                    <p className="tk-employees__modal-content-view__job-desc">{contract.job_desicription || renderNA("No Job Description.")}</p>
                );
                break;
            }
            case SALARY: {
                const COMMON_INPUT_PROPS = { type: INPUT_TYPE.TEXT, readOnly: true };

                title = "Salary Details";
                children = (
                    <FormSection style={{ minWidth: "20rem" }} nohead>
                        <Input
                            label="Basic Salary"
                            renderValue={addCommasToMoney(contract.basic_amount)}
                            afterExtra={renderNA(setting.currency)}
                            {...COMMON_INPUT_PROPS}
                        />
                        <Input
                            label="Accomodation"
                            renderValue={addCommasToMoney(allowance.accomodation)}
                            afterExtra={renderNA(setting.currency)}
                            {...COMMON_INPUT_PROPS}
                        />
                        <Input
                            label="Food Allowance"
                            renderValue={addCommasToMoney(allowance.food)}
                            afterExtra={renderNA(setting.currency)}
                            {...COMMON_INPUT_PROPS}
                        />
                        <Input
                            label="Transportation Allowance"
                            renderValue={addCommasToMoney(allowance.transportation)}
                            afterExtra={renderNA(setting.currency)}
                            {...COMMON_INPUT_PROPS}
                        />
                        <Input
                            label="Communication Allowance"
                            renderValue={addCommasToMoney(allowance.communication)}
                            afterExtra={renderNA(setting.currency)}
                            {...COMMON_INPUT_PROPS}
                        />
                        <Input
                            label="Education Allowance"
                            renderValue={addCommasToMoney(allowance.education)}
                            afterExtra={renderNA(setting.currency)}
                            {...COMMON_INPUT_PROPS}
                        />
                        <Input
                            label="Other"
                            renderValue={addCommasToMoney(allowance.other)}
                            afterExtra={renderNA(setting.currency)}
                            {...COMMON_INPUT_PROPS}
                        />
                        <Input
                            label="Air Ticket"
                            renderValue={addCommasToMoney(allowance.air_ticket)}
                            afterExtra={renderNA(setting.currency)}
                            {...COMMON_INPUT_PROPS}
                        />
                    </FormSection>
                );
                break;
            }
            default:
                break;
        }
        return (
            <Modal title={title} open={open} onChange={onChange} styles={{ title: { textAlign: "left" }, content: style }} small>
                <div className="tk-employees__modal-content-view" style={{ padding: "1rem" }}>
                    {children}
                </div>
            </Modal>
        );
    })();
}

ViewModalSub.propTypes = {
    open: PropTypes.bool,
    data: PropTypes.object,
    onChange: PropTypes.func,
    type: PropTypes.oneOf(Object.values(VIEW_MODAL_TYPE))
};

export default ViewModalSub;
