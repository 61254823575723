import React from "react";
import PropTypes from "prop-types";
import Modal from "../../../common/components/extra/Modal";
import SiteCard from "./SiteCard";
import { useGetWorkSitesByIds } from "./hooks";
import Empty from "../../../common/components/extra/Empty";

function ViewGeozonesModal({ open, onChange, data, onClose }) {
    const [geozones, isLoading, { isMounted }] = useGetWorkSitesByIds(data);

    return (
        <Modal
            title="View Sites"
            open={open}
            onChange={onChange}
            onClose={onClose}
            styles={{ title: { textAlign: "left" }, content: { minWidth: "20rem" } }}
            small
        >
            <div
                style={{
                    overflow: "hidden auto",
                    maxHeight: "calc(100vh - 15rem)"
                }}
            >
                <div className="tk-site__view-geozones" style={{ padding: "1rem", display: geozones.length ? "grid" : "flex" }}>
                    {isMounted &&
                        (geozones.length ? (
                            geozones.map((site) => <SiteCard key={site.id} data={site} readOnly />)
                        ) : (
                            <div className="flex center" style={{ margin: "auto" }}>
                                <Empty
                                    style={{ height: "10rem" }}
                                    iconStyle={{ height: "6rem" }}
                                    loaderStyle={{ height: "7rem", marginTop: "-2rem" }}
                                    message="No work site found."
                                    isLoading={isLoading}
                                />
                            </div>
                        ))}
                </div>
            </div>
        </Modal>
    );
}

ViewGeozonesModal.propTypes = {
    open: PropTypes.bool,
    data: PropTypes.arrayOf(PropTypes.number),
    onChange: PropTypes.func,
    onClose: PropTypes.func
};

export default ViewGeozonesModal;
