import { selectRole } from "../../features/common/slice";
import { useAppSelector } from "./reduxHooks";
import { ROLES, ROLE_TYPE } from "../utilities/const";

function useGetUserType({ isBoolean = false } = {}) {
    const role = useAppSelector(selectRole);
    if (role) {
        const isAdmin = role.type === ROLE_TYPE.SUPER_ADMIN;
        if (isBoolean) {
            return { isAdmin };
        }
        return isAdmin ? ROLES.SUPER_ADMIN : ROLES.COMPANY_ADMIN;
    }
    return null;
}

export default useGetUserType;
