import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "./Button";

function SeeMore({ children, max }) {
    const [showAll, setShowAll] = useState(false);

    const handleSeeMore = () => setShowAll(!showAll);

    const flatChildren = React.Children.toArray(children);
    const childrenToDisplay = showAll ? flatChildren : flatChildren.slice(0, max);
    const isChildrenExceededMax = flatChildren.length > max;

    return (
        <div className="tk-see-more flex gap-05 wrap" style={{ alignItems: "center" }}>
            {childrenToDisplay}
            {isChildrenExceededMax && (
                <Button onClick={handleSeeMore} options={{ style: { padding: 0, height: "1rem" } }} small mini transparent>
                    {!showAll ? "See More..." : "See Less..."}
                </Button>
            )}
        </div>
    );
}

SeeMore.propTypes = {
    children: PropTypes.node.isRequired,
    max: PropTypes.number.isRequired
};

export default SeeMore;
