import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as LeftArrow } from "../../../assets/images/arrow-left.svg";
import { getTimeRemaining, millisecondsToMMSS, secondsToMs } from "../../../common/utilities/helper";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectUser } from "../../common/slice";
import BillingDetails from "./BillingDetails";
import PaymentDetails from "./PaymentDetails";
import Summary from "./Summary";
import useTimer from "../../../common/hooks/useTimer";
import SubscriptionPaymentExpired from "./SubscriptionPaymentExpired";
import { CURRENCIES } from "../../../common/utilities/const";

function SubscriptionPayment({ onBack, subscription, onTimerEnd, subscriptionToUpgradeId, expires, timezone, onSuccess }) {
    const remaningTime = (expires && timezone && getTimeRemaining(expires, timezone)) || secondsToMs(subscription?.PTL);
    const [timer] = useTimer(remaningTime, () => onTimerEnd && onTimerEnd());

    const user = useAppSelector(selectUser);
    const companySubscription = user.CompanySubscription;
    const userCurrentSubscription = companySubscription.Subscription;
    const isRenewal = subscriptionToUpgradeId == userCurrentSubscription.id;

    const handleConfirmPayment = async () => {
        typeof onSuccess == "function" && onSuccess();
    };

    if (!timer) {
        return <SubscriptionPaymentExpired onBack={onBack} />;
    }

    return (
        <div className="tk-subscription-plan tk-subscription-plan--summary">
            <div className="tk-subscription-plan__inner">
                <div className="tk-subscription-plan__header">
                    <div className="left">
                        {onBack && <LeftArrow onClick={onBack} />}
                        <span>
                            <span>Subscription Plan</span>
                            <span>/</span>
                            <span>Payment - {isRenewal ? "Renew Subscription" : "Upgrade Plan"}</span>
                        </span>
                    </div>
                    <div className="right">
                        <span className="fade">Time Limit:</span>
                        <span className="bold">{millisecondsToMMSS(timer)}</span>
                    </div>
                </div>
                <div className="tk-subscription-plan__items">
                    <section className="group">
                        <Summary subscription={subscription} />
                        <BillingDetails user={user} />
                    </section>
                    <PaymentDetails onSubmit={handleConfirmPayment} subscriptionToUpgradeId={subscriptionToUpgradeId} />
                </div>
            </div>
        </div>
    );
}

SubscriptionPayment.propTypes = {
    onBack: PropTypes.func,
    companySubscription: PropTypes.object,
    onSubscribe: PropTypes.func,
    isPromote: PropTypes.bool,
    isEnded: PropTypes.bool,
    subscription: PropTypes.shape({
        title: PropTypes.string,
        employee_limit: PropTypes.number,
        amount: PropTypes.number,
        recurrence: PropTypes.string,
        currency: PropTypes.oneOf(Object.keys(CURRENCIES)),
        PTL: PropTypes.number,
        PTLtype: PropTypes.string
    }),
    onTimerEnd: PropTypes.func,
    subscriptionToUpgradeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    expires: PropTypes.string,
    timezone: PropTypes.string,
    onSuccess: PropTypes.func
};

export default SubscriptionPayment;
