import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import LockResetIcon from "@mui/icons-material/LockReset";
import isEqual from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";
import Input, { INPUT_TYPE } from "../../../common/components/extra/form/Input";
import { useAdminChangePasswordMutation, useAdminDetailsMutation, useUpdateAdminProfileMutation } from "../auth/superAdminAPI";
import { useAppDispatch, useAppSelector } from "../../../common/hooks/reduxHooks";
import { TOAST_TYPE, createConfirmAlert, createToast, sanitizeWords } from "../../../common/utilities/helper";
import { selectProfile, setProfile, updateProfile } from "../../company/settings/slice";
import { updateUser } from "../../common/slice";
import Button from "../../../common/components/extra/Button";
import LetteredAvatar from "../../../common/components/extra/avatar/LetteredAvatar";
import Card from "../../../common/components/extra/Card";
import ChangePassword from "../../company/settings/ChangePassword";

const INITIAL_FIELDS = {
    name: "",
    email: "",
    address: "",
    industry_type: "",
    country: "",
    license_number: "",
    contact_name: "",
    contact_email: "",
    contact_position: "",
    contact_number: ""
};

function ProfileAdminTab({ setParentLoading, styles }) {
    const [loading, setLoading] = useState(true);
    const [isEditing, setIsEditing] = useState(false);
    const [old, setOld] = useState(null);
    const [isChangePass, setChangePass] = useState(false);

    const dispatch = useAppDispatch();
    const [getDetails] = useAdminDetailsMutation();
    const [update] = useUpdateAdminProfileMutation();
    const [changePassword] = useAdminChangePasswordMutation();

    const profile = useAppSelector(selectProfile);

    const fetchDetails = async () => {
        try {
            const response = await getDetails();
            if (response.error) {
                throw new Error(response.error?.data?.message);
            }
            const data = response.data?.data || {};
            dispatch(setProfile(data));
            if (!old) {
                setOld(data);
            }
        } catch (error) {
            createToast(error.message || "Failed to fetch profile, please try again later", TOAST_TYPE.ERROR);
        } finally {
            setLoading(false);
        }
    };

    const updateApi = async (body) => {
        setParentLoading(true);
        const result = await update({ body });
        if (result.error) {
            setParentLoading(false);
            throw new Error(result?.error?.data?.message || "Failed to update profile, please try again later.");
        }
        const data = result.data.data;
        dispatch(updateUser(data));
        setOld(data);
        setParentLoading(false);
        return data;
    };

    useEffect(() => {
        if (!profile?.name) {
            fetchDetails();
        } else {
            setLoading(false);
        }
    }, []);

    const handleChange = (e) => {
        const config = {};
        const name = e.target.name;
        const value = e.target.value;
        config[name] = value;
        handleFormChange(config);
    };

    const handleFormChange = (config = {}) => dispatch(updateProfile(config));

    const toggleEdit = ({ isCancel } = {}) => {
        if (isCancel && old) {
            dispatch(setProfile(old));
        }
        setIsEditing((prev) => !prev);
    };

    const toggleChangePass = () => setChangePass(!isChangePass);

    const handleSave = async () => {
        try {
            const toSave = INITIAL_FIELDS;
            Object.keys(toSave).forEach((ks) => (toSave[ks] = profile[ks]));
            const result = await updateApi(toSave);
            setOld(result);
            toggleEdit();
            createToast("Profile updated successfully.", TOAST_TYPE.SUCCESS);
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
        }
    };

    const transferToAnotherObj = (original = {}, toTransfer = {}) => {
        const newObj = {};
        Object.keys(original).forEach((ks) => (newObj[ks] = toTransfer[ks]));
        return newObj;
    };

    const checkHasChanges = () => {
        const fields = { ...INITIAL_FIELDS, removeLogo: false };
        const cloneProfile = cloneDeep(profile);
        const cloneOld = cloneDeep(old || {});
        cloneProfile.removeLogo = !!cloneProfile.uploads?.removeLogo;
        cloneOld.removeLogo = !!cloneOld.uploads?.removeLogo;
        const newProfile = transferToAnotherObj(fields, cloneProfile);
        const oldProfile = transferToAnotherObj(fields, cloneOld);
        let hasChanges = !!old && !isEqual(newProfile, oldProfile);
        return hasChanges;
    };

    const inputPropsValue = (value, style = {}) => {
        if (isEditing) {
            return { value, style };
        } else {
            return { renderValue: value, readOnly: true, parentStyle: style };
        }
    };

    const renderControls = (
        <div className="flex gap-1" style={{ marginLeft: "auto" }}>
            {!isEditing && !isChangePass && (
                <Button
                    options={{ type: "button" }}
                    beforeExtra={<LockResetIcon style={{ color: "red" }} />}
                    onClick={toggleChangePass}
                    disabled={loading}
                    small
                >
                    Change Password
                </Button>
            )}
            {isEditing && (
                <Button
                    options={{ type: "button" }}
                    beforeExtra={<CancelIcon style={{ color: "red" }} />}
                    onClick={() => toggleEdit({ isCancel: true })}
                    disabled={loading}
                    small
                >
                    Cancel
                </Button>
            )}
            {!isEditing && (
                <Button options={{ type: "button" }} beforeExtra={<ModeEditIcon />} className="primary" onClick={toggleEdit} disabled={loading} small>
                    Edit
                </Button>
            )}
            {isEditing && (
                <Button options={{ type: "submit" }} beforeExtra={<SaveIcon />} className="primary" disabled={loading || !checkHasChanges()} small>
                    Save
                </Button>
            )}
        </div>
    );

    if (isChangePass) {
        return <ChangePassword onCancel={toggleChangePass} onSubmit={(conf) => changePassword({ body: conf })} />;
    }

    return (
        <Card className="box-shadow-mini" styles={styles}>
            <form
                className="profile-tab flex column"
                onSubmit={(e) => {
                    e.preventDefault();
                    createConfirmAlert({
                        title: "Save Profile",
                        content: "Are you certain about this action? It will result in profile update, and once completed, cannot be reversed",
                        onConfirm: (onClose) => {
                            onClose();
                            handleSave();
                        }
                    });
                }}
            >
                <div className="flex column" style={{ gap: "2rem" }}>
                    <section className="flex column gap-05 center">
                        <LetteredAvatar size={90} name={profile.name} isLoading={loading} style={{ boxShadow: "0 2px 3px #c7c7c7" }} hasborder />
                    </section>
                    <section className="flex column gap-05">
                        {!isEditing && (
                            <Input
                                type={INPUT_TYPE.TEXT}
                                name="name"
                                label="Company Email"
                                renderValue={profile.email}
                                isLoading={loading}
                                readOnly
                            />
                        )}
                        <Input
                            type={INPUT_TYPE.TEXT}
                            name="name"
                            label="Name"
                            onChange={handleChange}
                            minLength={4}
                            isLoading={loading}
                            required={isEditing}
                            autoFocus
                            {...inputPropsValue(isEditing ? profile.name : sanitizeWords(profile.name))}
                        />
                    </section>
                    {renderControls}
                </div>
            </form>
        </Card>
    );
}

export default ProfileAdminTab;

ProfileAdminTab.propTypes = {
    loading: PropTypes.bool,
    setParentLoading: PropTypes.func,
    styles: PropTypes.shape({
        parent: PropTypes.object,
        content: PropTypes.object
    })
};
