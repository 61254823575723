import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apiSlice } from "../../../app/apiSlice";

const BASE_PATH = "/api/admin";

const END_POINTS = {
    createSubcription: { method: "post", path: "create-subscription" },
    updateSubcription: { method: "put", path: "update-subscription" },
    deleteSubcription: { method: "delete", path: "delete-subscription" },
    getAllSubscription: { method: "get", path: "all-subscriptions" },
    getSubscriptionDetails: { method: "get", path: "subscription-details" }
};

export const subscriptionsAPI = apiSlice.injectEndpoints({
    reducerPath: "subscriptionsAPI",
    baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_SERVER_URI }),
    endpoints: (builder) => {
        const temp = {};
        Object.entries(END_POINTS).forEach(([key, config]) => {
            temp[key] = builder.mutation({
                query: (queryParams = {}) => {
                    const path = `${BASE_PATH}/${config.path}`;
                    const conf = {
                        url: path,
                        method: config.method,
                        credentials: "include"
                    };
                    if (typeof queryParams.extraPath === "string" || typeof queryParams.extraPath === "number")
                        conf.url = `${path}/${queryParams.extraPath}`;
                    if (Array.isArray(queryParams.extraPaths)) conf.url = `${path}/${queryParams.extraPaths.join("/")}`;
                    if (queryParams.params) conf.params = queryParams.params;
                    if (queryParams.body) conf.body = queryParams.body;
                    return conf;
                }
            });
        });
        return temp;
    }
});

export const {
    useCreateSubcriptionMutation,
    useUpdateSubcriptionMutation,
    useDeleteSubcriptionMutation,
    useGetAllSubscriptionMutation,
    useGetSubscriptionDetailsMutation
} = subscriptionsAPI;

export default subscriptionsAPI.reducer;
