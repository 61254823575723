import React from "react";
import CompanyDashboard from "../features/company/dashboard/CompanyDashboard";
import SuperAdminDashboard from "../features/admin/dashboard/SuperAdminDashboard";
import { useAppSelector } from "../common/hooks/reduxHooks";
import { selectRole } from "../features/common/slice";
import { ROLE_TYPE } from "../common/utilities/const";

function Dashboard() {
    const role = useAppSelector(selectRole);
    const isAdmin = role?.type === ROLE_TYPE.SUPER_ADMIN;
    if (!role) return <></>;
    return <div className="tk-dashboard">{isAdmin ? <SuperAdminDashboard /> : <CompanyDashboard />}</div>;
}

export default Dashboard;
