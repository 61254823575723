import React from "react";
import PropTypes from "prop-types";

function InfoCard({ className, header, children, style }) {
    return (
        <div className={"info-card " + (className ? className : "")} style={style || {}}>
            <div className="info-card__header">{header}</div>
            <div className="info-card__content">{children}</div>
        </div>
    );
}

export default InfoCard;

InfoCard.propTypes = {
    header: PropTypes.any,
    children: PropTypes.any,
    className: PropTypes.string,
    style: PropTypes.object
};
