export const ERROR_TYPE = {
    CUSTOM: 0x0,
    FILE_TYPE: 0x1,
    FOLDER_FORMAT: 0x2,
    FILE_SIZE: 0x3,
    INVALID_DROP: 0x4,
    SERVER_ERROR: 0x5
};

export const BATCH_STATES = {
    PENDING: "pending",
    ADDED: "added",
    PROCESSING: "processing",
    UPLOADING: "uploading",
    CANCELLED: "cancelled",
    FINISHED: "finished",
    ABORTED: "aborted",
    ERROR: "error"
};

export const FILE_STATES = {
    PENDING: "pending",
    ADDED: "added",
    UPLOADING: "uploading",
    CANCELLED: "cancelled",
    FINISHED: "finished",
    ERROR: "error",
    ABORTED: "aborted"
};
