import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import debounce from "lodash/debounce";
import PreviewIcon from "@mui/icons-material/Visibility";
import { useLazyLawPenalties, useValidateFields } from "./hooks";
import { selectCountry } from "../../common/slice";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import ModalViewUpdateLayout from "../../../common/components/layout/modalViewUpdateLayout/ModalViewUpdateLayout";
import Card from "../../../common/components/extra/Card";
import Divider from "../../../common/components/extra/Divider";
import Input, { INPUT_TYPE } from "../../../common/components/extra/form/Input";
import TableList from "../../../common/components/extra/table/TableList";
import { getCountryName, getObjectChanges, sanitizeWords } from "../../../common/utilities/helper";
import { TABLE_ACTION_TYPE_COMMON } from "../../../common/utilities/const";
import Tag from "../../../common/components/extra/Tag";
import ViewModalContent from "./ViewModalContent";
import UpdateModalContent from "./UpdateModalContent";

const DESCRIPTIONS = `Law penalties are penalties that are based on the current country. Select law penalties to apply via checkbox below.`;
const CHECK_KEY = "apply";
const EXCLUDE_CHECK_TYPES = ["mounting", "changing"];

function PenaltiesByLaw({ defaultCheckedIds = [], onChange, removeShadow, readOnly }) {
    const [openPreviewModal, setOpenPreviewModal] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const [fetchedView, setFetchedView] = useState(null);
    const [formToSave, setFormToSave] = useState(null);
    const [checked, setChecked] = useState(defaultCheckedIds);

    const [onValidate] = useValidateFields();
    const [modifiedSelections, setModifiedSelections] = useState([]);
    const [object, isFetching, updateObject, { loadMore, onSort, onSearch }] = useLazyLawPenalties();

    const country = useAppSelector(selectCountry);
    const findSelected = selectedId && object.lawPenalties.find((obj) => obj.id == selectedId);

    useEffect(() => {
        // reset form when changing view
        if (formToSave && formToSave?.id !== selectedId) {
            setFormToSave(null);
        }
    }, [selectedId, formToSave]);

    const getPenaltiesToAdd = (newchecks = [], selections = []) =>
        object.lawPenalties
            .filter((penalty) => newchecks.includes(penalty.id))
            .map((penalty) => selections.find((p) => p.id == penalty.id) || { id: penalty.id });

    const { data: headers } = CreateTableHeaders({
        onAction: (data, type) => {
            switch (type) {
                case TABLE_ACTION_TYPE_COMMON.PREVIEW:
                    setSelectedId(data.id);
                    setOpenPreviewModal(true);
                    break;
                default:
                    break;
            }
        },
        modifiedIds: modifiedSelections.map((penalty) => penalty.id)
    });

    const handleCheckChange = (value, keys, newKeys, type) => {
        const ids = newKeys;
        if (!EXCLUDE_CHECK_TYPES.includes(type)) {
            setChecked(ids);
            typeof onChange === "function" && onChange(getPenaltiesToAdd(ids, modifiedSelections));
        }
    };

    const addChangestoModifiedSelections = () => {
        const changes = getObjectChanges(formToSave, fetchedView);
        changes.id = formToSave.id;
        const isAlreadyModified = modifiedSelections.some((penalty) => penalty.id == formToSave.id);
        let selections = [];
        if (isAlreadyModified) {
            selections = modifiedSelections.map((penalty) => {
                if (penalty.id == formToSave.id) {
                    return changes;
                }
                return penalty;
            });
        } else {
            selections = modifiedSelections.concat(changes);
        }
        setModifiedSelections(selections);
        return selections;
    };

    const handleUpdate = async () => {
        try {
            if (formToSave) {
                await onValidate({
                    description: formToSave.description,
                    title: formToSave.title,
                    penalty_id: formToSave.id
                });
                let newchecked = checked;
                const isInChecked = checked.includes(formToSave.id);
                if (!isInChecked) {
                    newchecked = checked.concat(formToSave.id);
                    setChecked(checked.concat(formToSave.id));
                }
                const newSelections = addChangestoModifiedSelections();
                setFetchedView(formToSave);
                updateObject({
                    lawPenalties: object.lawPenalties.map((penalty) => {
                        if (formToSave.id == penalty.id) {
                            penalty = { ...penalty, ...formToSave };
                        }
                        return penalty;
                    })
                });
                typeof onChange === "function" && onChange(getPenaltiesToAdd(newchecked, newSelections));
                return true;
            } else {
                return false;
            }
        } catch (error) {
            return false;
        }
    };

    const handleModalClose = ({ isViewModalOpen } = {}) => {
        if (isViewModalOpen == false) {
            setOpenPreviewModal(false);
        }
    };

    const renderCustomEmptyElement = object.isAllSelected && <span className="fade">No data to select. All penalties has been selected.</span>;

    return (
        <ModalViewUpdateLayout
            onUpdate={handleUpdate}
            updateConfig={{
                oldData: fetchedView,
                newData: formToSave,
                title: "Penalty",
                isCreate: false
            }}
            modal={{
                EditModalContent: <UpdateModalContent data={fetchedView} onChange={setFormToSave} isByLaw />,
                ViewModalContent: (
                    <ViewModalContent data={{ id: selectedId }} onLoad={(result) => setFetchedView(result)} newData={findSelected} isByLaw />
                ),
                updateStyles: { content: { width: "80vw" } },
                isViewModalOpen: openPreviewModal,
                onClose: handleModalClose
            }}
            readOnly={readOnly}
        >
            <Card className="penalties-settings box-shadow-mini" styles={{ parent: removeShadow ? { boxShadow: "unset" } : {} }}>
                <div className="flex column">
                    <Divider title={`Penalties - ${getCountryName(country, true)} Law`} />
                    <p className="fade small-font">{DESCRIPTIONS}</p>
                    <div className="flex column gap-05">
                        <div className="flex" style={{ alignItems: "center" }}>
                            <Input
                                type={INPUT_TYPE.SEARCH}
                                onChange={debounce(onSearch, 500)}
                                placeholder="Search title..."
                                parentStyle={{
                                    minHeight: "1rem",
                                    height: "1.6rem"
                                }}
                            />
                        </div>
                        <TableList
                            uniqueKey="id"
                            headers={headers}
                            data={object.lawPenalties}
                            onSort={onSort}
                            activeSort={object.sort}
                            onLoadMore={loadMore}
                            height={25}
                            isLoadingMore={isFetching}
                            onCheckChange={handleCheckChange}
                            checked={{ [CHECK_KEY]: checked }}
                            customEmptyEl={renderCustomEmptyElement}
                            small
                        />
                    </div>
                </div>
            </Card>
        </ModalViewUpdateLayout>
    );
}

export default PenaltiesByLaw;

PenaltiesByLaw.propTypes = {
    onChange: PropTypes.func,
    removeShadow: PropTypes.bool,
    defaultCheckedIds: PropTypes.arrayOf(PropTypes.number),
    readOnly: PropTypes.bool
};

const CreateTableHeaders = ({ onAction, modifiedIds }) => {
    const headers = {
        TITLE: {
            key: "title",
            sortKey: "id",
            label: "Title",
            render: (row) => (
                <span className="text-ellipsis">
                    <span className="bold">{sanitizeWords(row.title)}</span>
                </span>
            )
        },
        DEDUCTION: {
            key: "deduction_type",
            sortKey: "deduction_type",
            label: "Deduction",
            render: (row) => <Tag>{sanitizeWords(row.deduction_type)}</Tag>
        },
        DESCRIPTION: {
            key: "description",
            label: "Description",
            render: (row) => <p>{row.description}</p>
        },
        STATUS: {
            key: "status",
            label: <span style={{ maxWidth: "min-content" }}>Status</span>,
            style: { display: "flex", justifyContent: "flex-end" },
            render: (row) => {
                const isModified = modifiedIds.includes(row.id);
                return <Tag className={(isModified && "yellow") || ""}>{isModified ? "MODIFIED" : "ORIGINAL"}</Tag>;
            }
        },
        APPLY: {
            key: CHECK_KEY,
            isCheckbox: true,
            checked: true,
            label: <span style={{ maxWidth: "min-content" }}>Apply</span>,
            style: { display: "flex", justifyContent: "flex-end", paddingRight: ".5rem" },
            render: (row) => <PreviewIcon className="fade hover-svg" onClick={() => onAction(row, TABLE_ACTION_TYPE_COMMON.PREVIEW)} />
        }
    };
    return { data: Object.values(headers), original: headers };
};
