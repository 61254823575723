import React from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import { useEffect } from "react";
import { useState } from "react";
import { useAppSelector } from "../../hooks/reduxHooks";
import { selectUser } from "../../../features/common/slice";

function RealTimeClock({ isTwelveHour }) {
    const user = useAppSelector(selectUser);
    const timezone = user?.Setting?.timezone;
    const format = isTwelveHour ? "hh:mm:ss A" : "HH:mm:ss A";
    const updateClock = () => moment.tz(timezone).format(format);
    const [current, setCurrent] = useState(updateClock());

    useEffect(() => {
        let ref;
        if (timezone) {
            ref = setInterval(() => setCurrent(updateClock()), 1000);
        }
        return () => ref && clearInterval(ref);
    }, []);

    return <div className="tk-time-clock bold fade">{current}</div>;
}

RealTimeClock.propTypes = {
    isTwelveHour: PropTypes.bool
};

export default RealTimeClock;
