import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { setUser, reset } from "../features/common/slice";
import { createToast, TOAST_TYPE } from "../common/utilities/helper";

export const BASE_PATH_URI = process.env.REACT_APP_SERVER_URI;

const REFRESH_URI = "/api/auth/refresh";

const baseQuery = fetchBaseQuery({
    baseUrl: BASE_PATH_URI,
    credentials: "include",
    prepareHeaders: (headers, { getState }) => {
        const token = getState().auth.accessToken;
        if (token) {
            headers.set("authorization", `Bearer ${token}`);
        }
        return headers;
    }
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);
    const errorMessage = result?.error?.data?.message || "";
    const errorCode = (errorMessage && typeof errorMessage === "object" && "code" in errorMessage && errorMessage.code) || "";
    const accessTokenExpired = errorCode === "access-token-expired";
    const refreshTokenExpired = errorCode === "refresh-token-expired";
    const refreshTokenAttempt = args?.url === REFRESH_URI;
    const isLogoutAttempt = args?.url?.includes("logout");

    const user = api.getState().auth.user;

    const kickuser = () => {
        if (user) {
            api.dispatch(reset());
        }
    };

    const setUserInfo = (res) => {
        api.dispatch(setUser({ user, ...res.data.data }));
    };

    if (refreshTokenAttempt) {
        if (result && result.data && result.data.data) {
            setUserInfo(result);
        } else {
            kickuser();
        }
    }

    if (accessTokenExpired) {
        const res = await baseQuery(REFRESH_URI, api, extraOptions);
        if (res && res.data && res.data.data) {
            setUserInfo(res);
            result = await baseQuery(args, api, extraOptions);
        } else {
            if (res?.error) {
                createToast("Server is busy. Please try again later.", TOAST_TYPE.ERROR);
            }
            kickuser();
        }
    }

    if (refreshTokenExpired || isLogoutAttempt) {
        refreshTokenExpired && createToast("Session expired. Please relogin.", TOAST_TYPE.ERROR);
        kickuser();
    }

    return result;
};

export const apiSlice = createApi({ baseQuery: baseQueryWithReauth, endpoints: () => ({}) });
