import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import FormAuthTemplate from "../../../common/components/layout/FormAuthTemplate";
import { useResetCompanyPasswordMutation, useVerifyTokenMutation } from "./companyAPI";
import Loader from "../../../common/components/extra/Loader";
import Input from "../../../common/components/extra/Input";
import Navigation from "../../../common/classes/Navigation";
import { TOAST_TYPE, createConfirmAlert, createToast } from "../../../common/utilities/helper";
import { useNavigate } from "react-router-dom";
import Button from "../../../common/components/extra/Button";

function ChangePassword({ token = "" }) {
    const navigate = useNavigate();
    const [isVerifyingToken, setVerifyingToken] = useState(true);
    const [config, setConfig] = useState({ password: "", confirm_password: "", isExpired: false });
    const [resetPassword, { isLoading: passwordReqLoading }] = useResetCompanyPasswordMutation();
    const [verifyToken] = useVerifyTokenMutation();

    const isLoading = passwordReqLoading || isVerifyingToken;

    const updateConfig = (obj = {}) => setConfig((prev) => ({ ...prev, ...obj }));

    const checkTokenValidity = async (token = "") => {
        if (!token) {
            setVerifyingToken(false);
            return;
        }
        setVerifyingToken(true);
        try {
            const resp = await verifyToken({ extraPath: token });
            if (resp.error) {
                throw new Error(resp.error?.data?.message);
            }
        } catch (error) {
            updateConfig({ isExpired: true });
        } finally {
            setVerifyingToken(false);
        }
    };

    useEffect(() => {
        checkTokenValidity(token);
    }, []);

    const handleSubmit = async () => {
        try {
            if (config.password !== config.confirm_password) {
                throw new Error("Password do not match!");
            }
            const resp = await resetPassword({ body: { password: config.password }, extraPath: token });
            if (resp.error) {
                throw new Error(resp.error?.data?.message);
            }
            createToast("Password updated successfully. Login now!", TOAST_TYPE.SUCCESS);
            navigate(Navigation.Routes.SIGNIN.path);
        } catch (error) {
            toast.error(error.message || "Failed to reset password.");
        }
    };

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        updateConfig({ [name]: value });
    };

    return (
        <div className="tk-change-password" style={{ position: "relative" }}>
            {isLoading && <Loader />}
            <FormAuthTemplate
                title={config.isExpired ? "Token Expired" : "Change Password"}
                subtext={config.isExpired ? "Token is already expired/invalid" : "Enter your new password"}
                submitLabel={config.isExpired ? "Goto Login" : "Confirm"}
                onSubmit={() =>
                    config.isExpired
                        ? navigate(Navigation.Routes.SIGNIN.path)
                        : createConfirmAlert({
                              title: "Are you sure?",
                              content: "This will change your password.",
                              onConfirm: (onClose) => {
                                  handleSubmit();
                                  onClose();
                              }
                          })
                }
                footer={
                    <div className="tk-change-password__footer flex gap-05 center">
                        Need help?
                        <strong>
                            <a href="mailto:support@time-keeper.app">Contact customer support</a>
                        </strong>
                    </div>
                }
                buttonExtra={!config.isExpired && <Button onClick={() => navigate(Navigation.Routes.SIGNIN.path)}>Goto login</Button>}
                disabled={isLoading}
            >
                {!config.isExpired && (
                    <>
                        <Input
                            name="password"
                            type="password"
                            placeholder="New Password*"
                            value={config.password}
                            onChange={handleChange}
                            autoComplete="current-password"
                            parentStyle={{ flex: "50%" }}
                            required
                            isLarge
                        />
                        <Input
                            name="confirm_password"
                            type="password"
                            placeholder="Confirm Password*"
                            value={config.confirm_password}
                            onChange={handleChange}
                            autoComplete="current-password"
                            parentStyle={{ flex: "50%" }}
                            required
                            isLarge
                        />
                    </>
                )}
            </FormAuthTemplate>
        </div>
    );
}
ChangePassword.propTypes = {
    id: PropTypes.any,
    token: PropTypes.string,
    buttonExtra: PropTypes.any
};

export default ChangePassword;
