import { useState, useEffect } from "react";

const isObject = (item) => item !== null && typeof item === "object";

// Custom hook to manage local storage
const useLocalStorage = (key, initialValue) => {
    // State to store the current value
    const [storedValue, setStoredValue] = useState(() => {
        try {
            // Get from local storage by key
            const item = window.localStorage.getItem(key);
            const parsedItem = JSON.parse(item);
            // Parse stored json or if none return initialValue
            return item ? parsedItem : initialValue;
        } catch (error) {
            return initialValue;
        }
    });
    // Update the local storage whenever storedValue changes
    useEffect(() => {
        try {
            let temp = {};
            // Serialize state to string
            const item = JSON.parse(window.localStorage.getItem(key));
            if (isObject(item) && isObject(storedValue)) {
                temp = { ...item, ...storedValue };
            } else {
                temp = storedValue;
            }
            const serializedValue = JSON.stringify(temp);
            // Save to local storage
            window.localStorage.setItem(key, serializedValue);
        } catch (error) {
            window.localStorage.setItem(key, JSON.stringify(storedValue));
        }
    }, [key, storedValue]);

    return [storedValue, setStoredValue];
};

export default useLocalStorage;
