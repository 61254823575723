import { PENALTY_LEVEL } from "../../../common/utilities/const";
import { addCommasToMoney, floatToFraction } from "../../../common/utilities/helper";

const { WARNING, DAY_DEDUCTION, FIX_VALUE, TERMINATION, CANCEL_DAY_RECORD } = PENALTY_LEVEL;

export const generateLabel = (value, level, currency) => {
    let text = "";
    switch (level) {
        case WARNING: {
            text = "letter";
            break;
        }
        case TERMINATION: {
            text = "termination";
            break;
        }
        case CANCEL_DAY_RECORD: {
            text = "cancel record";
            break;
        }
        case DAY_DEDUCTION: {
            text = "day deduction";
            break;
        }
        case FIX_VALUE: {
            if (!value) {
                return "";
            }
            if (!currency) {
                text = "amount deduction";
            } else {
                text = `${addCommasToMoney(value)} ${currency} deduction`;
            }
            break;
        }
        default:
            break;
    }
    if (level === DAY_DEDUCTION) {
        if (!value) return "";
        value = floatToFraction(value).fraction;
    } else {
        return text;
    }
    return `${value} ${text}`;
};

export const getCorrectLevelOptions = (prevLevel = null, nextLevel = null, allowedOptions = []) => {
    const selections = Object.values(PENALTY_LEVEL);
    const options = [];
    if (prevLevel === null) {
        options.push(WARNING, DAY_DEDUCTION, FIX_VALUE);
    } else if (prevLevel === WARNING) {
        options.push(...selections);
    } else if (prevLevel === DAY_DEDUCTION || prevLevel === FIX_VALUE) {
        options.push(DAY_DEDUCTION, FIX_VALUE);
    } else if (prevLevel === CANCEL_DAY_RECORD) {
        options.push(...[CANCEL_DAY_RECORD, TERMINATION]);
    } else if (prevLevel === TERMINATION) {
        options.push(CANCEL_DAY_RECORD);
    }

    if (nextLevel === null) {
        options.push(CANCEL_DAY_RECORD, TERMINATION);
    } else if (nextLevel === WARNING) {
        options.push(WARNING);
    } else if (nextLevel === DAY_DEDUCTION) {
        options.push(DAY_DEDUCTION);
    } else if (nextLevel === FIX_VALUE) {
        options.push(FIX_VALUE);
    } else if (nextLevel === CANCEL_DAY_RECORD) {
        options.push(...[CANCEL_DAY_RECORD, TERMINATION]);
    } else if (nextLevel === TERMINATION) {
        const index = options.indexOf(TERMINATION);
        if (index !== -1) {
            options.splice(index, 1);
        }
        options.push(CANCEL_DAY_RECORD);
    }
    if (allowedOptions.length) {
        return [...new Set(options)].filter((opt) => allowedOptions.includes(opt));
    }
    return [...new Set(options)];
};
