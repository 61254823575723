import React from "react";
import PreviewIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import ResetDeviceIcon from "@mui/icons-material/PhonelinkErase";
import {
    createConfirmAlert,
    isTimePassed,
    renderNA,
    sanitizeTimestamp,
    sanitizeWords,
    toReadableFromDate,
    toReadablePhone,
    toTimeWithTimeZone
} from "../../../common/utilities/helper";
import Tag, { TAG_TYPE } from "../../../common/components/extra/Tag";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import MyTooltip from "../../../common/components/extra/Tooltip";
import LetteredAvatar from "../../../common/components/extra/avatar/LetteredAvatar";
import { selectUser } from "../../common/slice";
import { ROLE_LEVEL, ROLE_TYPE } from "../../../common/utilities/const";

const HEADERS = {
    NAME: "name",
    DEPARTMENT: "department",
    DESIGNATION: "designation",
    UPDATED_AT: "updatedAt",
    CREATED_AT: "createdAt",
    ACTIONS: "actions"
};

const HEADERS_TO_ARRAY = Object.values(HEADERS);

const CreateTableHeaders = ({ onPreview, onDelete, onResetDevice }) => {
    const user = useAppSelector(selectUser);
    const setting = user.Setting;
    return HEADERS_TO_ARRAY.map((head) => {
        const temp = { key: head, style: {} };
        switch (head) {
            case HEADERS.NAME: {
                temp.childStyle = {
                    alignItems: "center",
                    gap: "1rem",
                    margin: ".5rem 0"
                };
                temp.sortKey = "index1";
                temp.label = "Name";
                temp.style.width = "15rem";
                temp.fixed = true;
                temp.render = (_, row) => {
                    const fullName = row.first_name + " " + row.last_name;
                    const isSuperVisor = row.Role && row.Role?.type == ROLE_TYPE.EMPLOYEE && row.Role?.level == ROLE_LEVEL.HIGH;
                    return (
                        <>
                            <div className="flex gap-1" style={{ alignItems: "center" }}>
                                <LetteredAvatar name={fullName} src={row.photo} size={30} small />
                                <div className="flex column gap-05">
                                    <div style={{ fontWeight: "bold" }}>{sanitizeWords(`${row.first_name} ${row.last_name}`)}</div>
                                    <div className="flex gap-05 wrap">
                                        {isSuperVisor && <Tag type={TAG_TYPE.APP_SUPERVISOR} />}
                                        {row.isOnShift && <Tag type={row.isOnShift ? TAG_TYPE.ON_SHIFT : TAG_TYPE.AVAILABLE} />}
                                    </div>
                                    <div className="flex gap-05 wrap small-font" style={{ alignItems: "center" }}>
                                        <span className="small-font fade">Email:</span>
                                        <span className="semi-bold">{row.email_address || renderNA("None")}</span>
                                    </div>
                                    <div className="flex gap-05 wrap small-font" style={{ alignItems: "center" }}>
                                        <span className="small-font fade">Contact:</span>
                                        <span className="semi-bold">{toReadablePhone(row.mobile_number) || renderNA("None")}</span>
                                    </div>
                                </div>
                            </div>
                            {!isTimePassed(toTimeWithTimeZone(row.createdAt, setting.timezone), 1) && (
                                <div className="new-tag">
                                    <Tag className="green">NEW</Tag>
                                </div>
                            )}
                        </>
                    );
                };
                break;
            }
            case HEADERS.DEPARTMENT: {
                temp.label = "Department";
                temp.render = (_, row) => {
                    const department = row.CompanyDepartment;
                    return <div className="flex gap-05">{department ? sanitizeWords(department.title) : renderNA()}</div>;
                };
                break;
            }
            case HEADERS.DESIGNATION: {
                temp.label = "Designation";
                temp.render = (_, row) => {
                    const designation = row.CompanyDesignation;
                    return <div className="flex gap-05">{designation ? sanitizeWords(designation.title) : renderNA()}</div>;
                };
                break;
            }
            case HEADERS.UPDATED_AT: {
                temp.sortKey = "updatedAt";
                temp.label = "Updated";
                temp.render = (_, row) => (
                    <div className="flex column gap-05 overflow-hidden fade">
                        <span className="text-ellipsis small-font">
                            {sanitizeTimestamp(row.updatedAt, row.createdAt, setting.timezone) || renderNA()}
                        </span>
                    </div>
                );
                break;
            }
            case HEADERS.CREATED_AT: {
                temp.sortKey = "createdAt";
                temp.label = "Created";
                temp.render = (val) => (
                    <div className="flex column gap-05 overflow-hidden fade">
                        <span className="text-ellipsis small-font">{toReadableFromDate(val, setting.timezone)}</span>
                    </div>
                );
                break;
            }
            case HEADERS.ACTIONS: {
                temp.childStyle = {
                    display: "flex",
                    gap: ".5rem",
                    justifyContent: "flex-end",
                    paddingRight: "1rem",
                    overflow: "unset"
                };
                temp.render = (_, row) => {
                    return (
                        <>
                            {row.device && (
                                <MyTooltip message="Reset Device" style={{ display: "flex", alignItems: "center" }}>
                                    <ResetDeviceIcon
                                        onClick={() => {
                                            createConfirmAlert({
                                                title: "Reset Device",
                                                content: (
                                                    <div className="flex column gap-05">
                                                        <span>Are you sure you want to reset this device:</span>
                                                        <div className="flex gap-05">
                                                            <span className="fade">Name:</span>
                                                            <span>{row.device.device_name}</span>
                                                        </div>
                                                        <div className="flex gap-05">
                                                            <span className="fade">Model:</span>
                                                            <span>{row.device.model}</span>
                                                        </div>
                                                    </div>
                                                ),
                                                onConfirm: async (close) => {
                                                    close();
                                                    typeof onResetDevice === "function" && (await onResetDevice(row));
                                                }
                                            });
                                        }}
                                    />
                                </MyTooltip>
                            )}
                            <PreviewIcon onClick={() => onPreview(row)} />
                            {!row.isOnShift && (
                                <DeleteIcon
                                    disabled
                                    style={{ color: "red" }}
                                    onClick={async () => {
                                        createConfirmAlert({
                                            title: "Remove Employee",
                                            content: "Are you sure you want to remove this employee? This cannot be undone.",
                                            onConfirm: async (close) => {
                                                close();
                                                typeof onDelete === "function" && (await onDelete(row));
                                            }
                                        });
                                    }}
                                />
                            )}
                        </>
                    );
                };
                break;
            }
            default:
                break;
        }
        return temp;
    });
};

export default CreateTableHeaders;
