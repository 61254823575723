import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import isEqual from "lodash/isEqual";
import ViewIcon from "@mui/icons-material/Visibility";
import RemoveIcon from "@mui/icons-material/RemoveCircle";
import Button from "../Button";
import { TOAST_TYPE, bytesToMegabytes, createConfirmAlert, createToast, isFileObject } from "../../../utilities/helper";
import MyTooltip from "../Tooltip";

function InputFile({ label, required, icon, style, accept, sizeLimit, name, onView, hidelabel, ...inputProps }) {
    const inputFileRef = useRef(null);
    const [value, setValue] = useState("");

    const hasFile = !!value?.name;

    useEffect(() => {
        const isBoolean = typeof inputProps.value == "boolean";
        const isFileObj = isFileObject(inputProps.value);
        if ((isBoolean || isFileObj) && !isEqual(value, inputProps.value)) {
            setValue(inputProps.value);
        }
    }, [inputProps.value]);

    const handleChange = (e) => {
        const files = e.target.files;
        const file = files[0];
        if (file) {
            const accepts = (accept && accept.split(",")) || [];
            const isAllowed = accepts.includes(file.type);
            const isLimitExceeded = bytesToMegabytes(file.size) > sizeLimit;
            if (!isAllowed) {
                createToast(
                    `Invalid file type. Only accepts the following: (${accepts.map((a) => a.split("/").pop()).join(", ")})`,
                    TOAST_TYPE.ERROR
                );
                e.target.value = null;
                return;
            }
            if (isLimitExceeded) {
                createToast(`Invalid file size. Must be less than ${sizeLimit}MB`, TOAST_TYPE.ERROR);
                e.target.value = null;
                return;
            }
        }
        setValue(file);
        typeof inputProps.onChange === "function" && inputProps.onChange(e);
    };

    const handleFileRemove = () => {
        inputFileRef.current.value = "";
        setValue("");
        typeof inputProps.onChange === "function" && inputProps.onChange({ target: { files: [false], name } });
    };

    return (
        <div className="tk-input__upload" style={style || {}}>
            {!hidelabel && label && (
                <div>
                    {label}
                    {(required && <span className="danger-color bold">*</span>) || ""}
                </div>
            )}
            <div className="tk-input__upload__file">
                <div className="left" onClick={() => inputFileRef.current && inputFileRef.current.click()}>
                    <input
                        ref={inputFileRef}
                        {...inputProps}
                        name={name}
                        value=""
                        type="file"
                        onChange={handleChange}
                        accept={accept}
                        required={value === true || isFileObject(value) ? false : required}
                        style={{ opacity: 0, zIndex: -1 }}
                    />
                    {icon}
                    <span className="tk-input__upload__file-text">
                        {value && value.name ? (
                            <span>
                                Click or Drag files to replace - <strong>{value.name}</strong>
                            </span>
                        ) : typeof value === "boolean" && value === true ? (
                            `Click or Drag files to replace the existing file. Only accepts:(${accept
                                .split(",")
                                .map((str) => str.split("/").pop())
                                .join(", ")})`
                        ) : (
                            `Click or Drag files here to upload. Only accepts:(${accept
                                .split(",")
                                .map((str) => str.split("/").pop())
                                .join(", ")})`
                        )}
                    </span>
                </div>
                {(onView || hasFile) && (
                    <div className="right flex gap-05" style={{ marginLeft: ".5rem" }}>
                        {onView && (
                            <Button
                                className="small-font view-icon"
                                options={{ style: { textDecoration: "underline", padding: 0 }, type: "button" }}
                                onClick={onView}
                                transparent
                            >
                                <MyTooltip className="flex center" message="View File">
                                    <ViewIcon />
                                </MyTooltip>
                            </Button>
                        )}
                        {hasFile && (
                            <Button
                                className="small-font remove-icon"
                                options={{ style: { color: "red", textDecoration: "underline", padding: 0 }, type: "button" }}
                                onClick={() =>
                                    createConfirmAlert({
                                        title: "Are you sure?",
                                        content: "This will remove the file. Are you sure?",
                                        onConfirm: (onClose) => {
                                            handleFileRemove();
                                            onClose();
                                        }
                                    })
                                }
                                transparent
                            >
                                <MyTooltip className="flex center" message="Remove File">
                                    <RemoveIcon />
                                </MyTooltip>
                            </Button>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}

InputFile.propTypes = {
    label: PropTypes.any,
    required: PropTypes.bool,
    icon: PropTypes.any,
    style: PropTypes.object,
    accept: PropTypes.any,
    sizeLimit: PropTypes.any,
    name: PropTypes.string,
    onView: PropTypes.func,
    hidelabel: PropTypes.bool
};

export default InputFile;
