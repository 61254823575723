import { PAYMENT_TYPE } from "../../../common/utilities/const";

export const PAYMENT_TABS = {
    CREDIT_CARD: {
        id: "credit-card", // use in forms/tabs
        label: "Credit Card",
        key: PAYMENT_TYPE.CREDIT_CARD // db enum
    },
    BANK_TRANSFER: {
        id: "bank-transfer", // use in forms/tabs
        label: "Bank Transfer",
        key: PAYMENT_TYPE.BANK_TRANSFER // db enum
    }
};

export const CREDIT_CARD_FIELDS = {
    CARD_HOLDER_NAME: "holder_name",
    CARD_NUMBER: "card_number",
    EXPIRY_DATE: "expiry_date",
    SECURITY_CODE: "cvv"
};

export const BANK_TRANSFER_FIELDS = {
    BANK_CODE: "bank_code",
    INSTRUCTIONS: "instructions"
};
