import React, { useState } from "react";
import Roles from "../../features/admin/roles/Roles";
import { selectLoading } from "../../features/admin/roles/slice";
import { useAppSelector } from "../../common/hooks/reduxHooks";
import CardPage from "../../common/components/extra/CardPage";
import Button, { BTN_TYPE } from "../../common/components/extra/Button";
import Loader from "../../common/components/extra/Loader";

function RolesPage() {
    const loading = useAppSelector(selectLoading);
    const [openAddModal, setOpenAddModal] = useState(false);
    return (
        <div className="tk-roles tk-page-type-01">
            {loading && <Loader />}
            <div className="tk-roles__inner">
                <CardPage title="Manage Roles" contentStyle={{ padding: 0, height: "calc(100vh - 12.3rem)" }}>
                    <div className="content">
                        <div className="control">
                            <div className="search"></div>
                            <Button type={BTN_TYPE.ADD} onClick={() => setOpenAddModal(true)} />
                        </div>
                        <div className="tk-roles__body">
                            <Roles openAddModal={openAddModal} onAddModalClose={() => setOpenAddModal(false)} />
                        </div>
                    </div>
                </CardPage>
            </div>
        </div>
    );
}

export default RolesPage;
