import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import isEqual from "lodash/isEqual";
import UpdateModal from "./UpdateModal";
import ViewModal from "./BaseViewModal";

function ModalViewUpdateLayout({ children, modal, onUpdate, onValidate, updateConfig, readOnly }) {
    const { isViewModalOpen, isEditModalOpen, ViewModalContent, EditModalContent, onClose, viewModalHeadExtra, viewStyles, updateStyles } =
        modal || {};

    const [openViewModal, setOpenViewModal] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);

    const showViewModal = openViewModal && ViewModalContent;
    const showEditModal = openEditModal && EditModalContent;

    useEffect(() => {
        if (!isEqual(!!isViewModalOpen, openViewModal)) {
            setOpenViewModal(isViewModalOpen);
        }
    }, [isViewModalOpen]);

    useEffect(() => {
        if (!isEqual(!!isEditModalOpen, openEditModal)) {
            setOpenEditModal(isEditModalOpen);
        }
    }, [isEditModalOpen]);

    const closeEditModal = () => {
        setOpenEditModal(false);
        typeof onClose == "function" && onClose({ isEditModalOpen: false });
    };

    const closeViewModal = () => {
        setOpenViewModal(false);
        typeof onClose == "function" && onClose({ isViewModalOpen: false });
    };

    const handleModalBack = () => {
        setOpenViewModal(true);
        closeEditModal();
    };

    const handleModalEdit = () => {
        setOpenEditModal(true);
        closeViewModal();
    };

    const handleSave = async (isCreate) => {
        if (typeof onUpdate == "function") {
            const isProceed = await onUpdate();
            // onUpdate must return a boolean since it is the only way to determine if there is an error or now.
            if (isProceed) {
                if (isCreate) {
                    closeEditModal();
                } else {
                    handleModalBack();
                }
            }
        }
    };

    return (
        <>
            {children}
            {showViewModal && (
                <ViewModal
                    open={openViewModal}
                    onEdit={!readOnly && handleModalEdit}
                    headExtra={viewModalHeadExtra}
                    onChange={(bool) => {
                        if (!bool) {
                            closeViewModal();
                        } else {
                            setOpenViewModal(bool);
                        }
                    }}
                    styles={viewStyles}
                >
                    {ViewModalContent}
                </ViewModal>
            )}
            {showEditModal && (
                <UpdateModal
                    open={openEditModal}
                    onChange={(bool) => {
                        if (!bool) {
                            closeEditModal();
                        } else {
                            setOpenEditModal(bool);
                        }
                    }}
                    onBack={handleModalBack}
                    onSave={handleSave}
                    onValidate={onValidate}
                    config={updateConfig}
                    styles={updateStyles}
                >
                    {EditModalContent}
                </UpdateModal>
            )}
        </>
    );
}

ModalViewUpdateLayout.propTypes = {
    onUpdate: PropTypes.func,
    onValidate: PropTypes.func,
    updateConfig: PropTypes.shape({
        oldData: PropTypes.any,
        newData: PropTypes.any,
        isCreate: PropTypes.bool,
        title: PropTypes.string
    }),
    modal: PropTypes.shape({
        EditModalContent: PropTypes.any,
        ViewModalContent: PropTypes.any,
        isViewModalOpen: PropTypes.bool,
        isEditModalOpen: PropTypes.bool,
        viewModalHeadExtra: PropTypes.any,
        viewStyles: PropTypes.shape({
            parent: PropTypes.object,
            content: PropTypes.object,
            body: PropTypes.object,
            title: PropTypes.object,
            form: PropTypes.object,
            footer: PropTypes.object
        }),
        updateStyles: PropTypes.shape({
            parent: PropTypes.object,
            content: PropTypes.object,
            body: PropTypes.object,
            title: PropTypes.object,
            form: PropTypes.object,
            footer: PropTypes.object
        })
    }),
    children: PropTypes.any,
    readOnly: PropTypes.bool
};

export default ModalViewUpdateLayout;
