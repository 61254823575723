import React from "react";
import AddIcon from "@mui/icons-material/Add";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

function MobileFileUpload() {
    return (
        <div className="mobile-input mobile-input--file-upload">
            <div className="box-file">
                <PictureAsPdfIcon />
            </div>
            <div className="box-add">
                <AddIcon />
            </div>
        </div>
    );
}

export default MobileFileUpload;
