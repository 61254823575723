import React, { useState } from "react";
import PropTypes from "prop-types";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import Button from "../../../common/components/extra/Button";
import { UPDATE_FILE_TYPE } from "./const";
import UpdateFilesModal from "./UpdateFilesModal";

const LINK_STYLE = {
    textDecoration: "underline",
    fontStyle: "italic",
    color: "red",
    cursor: "pointer"
};

function UpdateFiles({ type, onClick, data, noInternalModal, forceInline }) {
    const [isOpen, setOpen] = useState(false);

    let label;
    let message;
    let isInline;

    const isApproval = UPDATE_FILE_TYPE.APPROVAL == type;

    const handleOnClick = () => {
        setOpen(true);
        onClick?.();
    };

    switch (type) {
        case UPDATE_FILE_TYPE.STAND_ALONE: {
            label = "Update Files";
            break;
        }
        case UPDATE_FILE_TYPE.APPROVAL: {
            label = "Validate Files";
            message = "Requesting for Approval.";
            isInline = true;
            break;
        }
        case UPDATE_FILE_TYPE.CONTRACT_RENEWAL: {
            label = "Confirm Files";
            message = "Requesting for Contract Renewal.";
            isInline = true;
            break;
        }
        case UPDATE_FILE_TYPE.FILE_CHANGED: {
            label = "Confirm Files";
            message = "New file upload Detected.";
            isInline = true;
            break;
        }
        default:
            break;
    }

    if (forceInline) {
        isInline = true;
    }

    const renderButton = isInline ? (
        <span style={LINK_STYLE} className="semi-bold" onClick={handleOnClick}>
            Click to {label}
        </span>
    ) : (
        <Button
            onClick={handleOnClick}
            className="danger"
            afterExtra={<AttachFileIcon style={{ width: "1.1rem", pointerEvents: "none" }} />}
            small
            mini
        >
            <span>{label}</span>
        </Button>
    );

    if (!message && !label) {
        return <></>;
    }

    if (!message && label) {
        return renderButton;
    }

    return (
        <>
            <div className="small-font flex gap-05 wrap">
                <span className="semi-bold">{message}</span>
                {renderButton}
            </div>
            {isOpen && !noInternalModal && (
                <UpdateFilesModal open={isOpen} onClose={() => setOpen(false)} onFinish={() => setOpen(false)} data={data} isApproval={isApproval} />
            )}
        </>
    );
}

export default UpdateFiles;

UpdateFiles.propTypes = {
    type: PropTypes.oneOf(Object.values(UPDATE_FILE_TYPE)),
    onClick: PropTypes.func,
    onBack: PropTypes.func,
    data: PropTypes.object,
    noInternalModal: PropTypes.bool,
    forceInline: PropTypes.bool
};
