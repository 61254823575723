import React from "react";
import PreviewIcon from "@mui/icons-material/Visibility";
import { COMPANY_SUBSCRIPTION_STATUS, TABLE_ACTION_TYPE_COMMON } from "../../../common/utilities/const";
import Tag from "../../../common/components/extra/Tag";
import { toReadableFromDate } from "../../../common/utilities/helper";

const HEADERS = {
    COMPANY: "company",
    SUBSCRIPTION: "subscription",
    START_DATE: "effectivity_date",
    END_DATE: "expiry_date",
    STATUS: "status",
    CREATED_AT: "createdAt",
    ACTIONS: "actions"
};

const HEADERS_TO_ARRAY = Object.values(HEADERS);

const CreateTableHeaders = ({ onAction }) => {
    return HEADERS_TO_ARRAY.map((head) => {
        const temp = { key: head, style: { width: `${100 / HEADERS_TO_ARRAY.length}%` } };
        switch (head) {
            case HEADERS.COMPANY: {
                temp.label = "Company";
                temp.render = (_, row) => (
                    <div className="flex column gap-05 overflow-hidden">
                        <span className="text-ellipsis bold capitalize">{row.Company && row.Company.name}</span>
                    </div>
                );
                break;
            }
            case HEADERS.SUBSCRIPTION: {
                temp.label = "Subscription";
                temp.render = (_, row) => (
                    <div className="flex">
                        <div className="flex column gap-05 overflow-hidden">
                            <span className="text-ellipsis capitalize">{row.Subscription && row.Subscription.title}</span>
                        </div>
                    </div>
                );
                break;
            }
            case HEADERS.START_DATE: {
                temp.sortKey = HEADERS.START_DATE;
                temp.label = "Start";
                temp.render = (val) => toReadableFromDate(val);
                break;
            }
            case HEADERS.END_DATE: {
                temp.sortKey = HEADERS.END_DATE;
                temp.label = "End";
                temp.render = (val) => toReadableFromDate(val);
                break;
            }
            case HEADERS.STATUS: {
                temp.label = "Status";
                temp.render = (_, row) => {
                    const isActive = row.status == COMPANY_SUBSCRIPTION_STATUS.ACTIVE;
                    const isVerified = row.status == COMPANY_SUBSCRIPTION_STATUS.VERIFIED;
                    return (
                        <div className="flex gap-05 wrap">
                            <Tag className={(!isActive && "red") || "green"}>{isActive ? "active" : "inactive"}</Tag>
                            <Tag className={(!isVerified && "red") || ""}>{isVerified ? "verified" : "not verified"}</Tag>
                        </div>
                    );
                };
                break;
            }
            case HEADERS.CREATED_AT: {
                temp.sortKey = "createdAt";
                temp.label = "Created";
                temp.render = (val) => (
                    <div className="flex">
                        <div className="flex column gap-05 overflow-hidden fade">
                            <span className="text-ellipsis small-font">{toReadableFromDate(val)}</span>
                        </div>
                    </div>
                );
                break;
            }
            case HEADERS.ACTIONS: {
                temp.render = () => "";
                temp.render = (_, row) => (
                    <div style={{ display: "flex", gap: ".5rem" }}>
                        <PreviewIcon onClick={() => onAction(row, TABLE_ACTION_TYPE_COMMON.PREVIEW)} />
                    </div>
                );
                break;
            }
            default:
                break;
        }
        return temp;
    });
};

export default CreateTableHeaders;
