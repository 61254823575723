import React from "react";
import PropTypes from "prop-types";
import { MapControl } from "@vis.gl/react-google-maps";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import MyTooltip from "../Tooltip";
import { MAP_CONTROL_POSITION } from "./const";

function CustomMapControls({ position = MAP_CONTROL_POSITION.TOP_LEFT, style = {}, onGoToBoundary }) {
    const handleGoToBoundary = () => {
        typeof onGoToBoundary === "function" && onGoToBoundary();
    };
    return (
        <MapControl position={position}>
            {onGoToBoundary && (
                <div className="tk-map-controls flex center" style={style || {}}>
                    <MyTooltip message="Go to Boundary">
                        <FmdGoodIcon onClick={handleGoToBoundary} />
                    </MyTooltip>
                </div>
            )}
        </MapControl>
    );
}

export default CustomMapControls;

CustomMapControls.propTypes = {
    map: PropTypes.object,
    maps: PropTypes.object,
    style: PropTypes.object,
    onGoToBoundary: PropTypes.func,
    position: PropTypes.oneOf(Object.values(MAP_CONTROL_POSITION))
};
