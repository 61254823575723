import React from "react";
import PropTypes from "prop-types";
import InfoCard from "../../../common/components/extra/card/InfoCard";
import Input, { INPUT_TYPE } from "../../../common/components/extra/form/Input";
import { getCountryName, sanitizeWords } from "../../../common/utilities/helper";

function BillingDetails({ user }) {
    return (
        <InfoCard className="billing" header={<span className="bold">BILLING DETAILS</span>}>
            <div className="w100">
                <Input label="Company" renderValue={sanitizeWords(user.name)} readOnly />
                <Input label="Email" renderValue={user.email} readOnly />
                <Input label="Address" renderValue={user.address} readOnly />
                <Input type={INPUT_TYPE.TEXT} label="Country" renderValue={getCountryName(user.country.iso2Alpha, true)} readOnly />
                <Input label="Contact Name" renderValue={sanitizeWords(user.contactName)} readOnly />
            </div>
        </InfoCard>
    );
}

export default BillingDetails;

BillingDetails.propTypes = {
    user: PropTypes.shape({
        name: PropTypes.string,
        email: PropTypes.string,
        address: PropTypes.string,
        contactName: PropTypes.string,
        country: PropTypes.shape({
            iso2Alpha: PropTypes.string
        })
    })
};
