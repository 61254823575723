import React, { useState } from "react";
import PropTypes from "prop-types";
import Select from "../../../common/components/extra/select/Select";
import { formatBankTransferDetails } from "../companyPayment/helper";

function PaymentBankTransfer({ options, onChange }) {
    const [selectedBank, setSelectedBank] = useState(null);

    return (
        <div className="tk-bank-transfer">
            <Select
                options={options}
                onChange={(bank) => {
                    setSelectedBank(bank);
                    typeof onChange == "function" && onChange(bank.value, bank.instructions.bankInstructionsEN);
                }}
                menuPortalTarget={document.body}
                isClearable={false}
                placeholder="Select a Bank"
                isOutlined
                disabledOutline
                required
            />
            {selectedBank && <div style={{ padding: ".5rem" }}>{formatBankTransferDetails(selectedBank.instructions.bankInstructionsEN)}</div>}
        </div>
    );
}

export default PaymentBankTransfer;

PaymentBankTransfer.propTypes = {
    options: PropTypes.array,
    onChange: PropTypes.func
};
