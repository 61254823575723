import React from "react";
import PropTypes from "prop-types";

function MobileLabel(props) {
    return (
        <div className="mobile-input mobile-input--label">
            <div
                dangerouslySetInnerHTML={{
                    __html:
                        (props?.details?.value?.text && `<div>${props?.details?.value?.html}</div>`) || "<div class='fade'>Enter text here...</div>"
                }}
            ></div>
        </div>
    );
}

export default MobileLabel;

MobileLabel.propTypes = {
    details: PropTypes.object
};
