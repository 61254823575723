import React from "react";
import PropTypes from "prop-types";
import { FixedSizeList as List } from "react-window";

const DEF_ITEM_HEIGHT = 40;

export default class CustomMenuList extends React.Component {
    static propTypes = {
        options: PropTypes.array.isRequired,
        children: PropTypes.node.isRequired,
        maxHeight: PropTypes.number.isRequired,
        getValue: PropTypes.func.isRequired
    };

    render() {
        const { options, children, maxHeight, getValue } = this.props;
        const [value] = getValue();
        const initialOffset = options.indexOf(value) * DEF_ITEM_HEIGHT;

        return (
            <span className="virtual-select-list">
                <List height={maxHeight} itemCount={children.length} itemSize={DEF_ITEM_HEIGHT} initialScrollOffset={initialOffset}>
                    {({ index, style }) => <div style={style}>{children[index]}</div>}
                </List>
            </span>
        );
    }
}
