import React from "react";
import COUNTRIES from "../utilities/const.countries.json";

/**
 * @param {Object} param0
 * @param {Array<String>} param0.filterISOAlphas
 * @param {Array<Boolean>} param0.rtl
 * @param {Array<Boolean>} param0.mobile // changes the response to object { nationalities, codes } if true
 * @returns {Array<object>}
 */
function useFetchCountries({ filterISOAlphas = [], rtl = false, mobile = false, isDenonym } = {}) {
    const codes = COUNTRIES.filter((ctr) => ctr.idd.root).map((ctr) => {
        const suffixes = ctr.idd && Array.isArray(ctr.idd?.suffixes) ? ctr.idd?.suffixes?.[0] : ctr.idd?.suffixes;
        return { label: <div>{`${ctr.flag} ${ctr.idd.root}${suffixes}`}</div>, value: `${ctr.idd.root}${suffixes}` };
    });
    const nationalities = COUNTRIES.filter((ctr) => !filterISOAlphas.includes(ctr.cca2)).map((ctr) => ({
        ...ctr,
        value: ctr.cca2,
        label: (
            <div className="flex gap-05" style={{ justifyContent: rtl ? "flex-end" : "flex-start", textAlign: rtl ? "right" : "left" }}>
                <img src={ctr.flags.png} alt="flag" width={5} height={5} style={{ width: "1.5rem", height: "auto" }} />
                <span className="text-ellipsis" style={{ textAlign: "left" }}>
                    {isDenonym ? ctr?.demonyms?.eng?.m || ctr?.name?.common : ctr.name.common}
                </span>
            </div>
        )
    }));

    if (mobile) {
        codes.sort((a, b) => parseInt(a.value) - parseInt(b.value));
        return { nationalities, codes };
    }

    return nationalities;
}
export default useFetchCountries;
