import React, { useState } from "react";
import PropTypes from "prop-types";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Input, { INPUT_TYPE } from "../../../common/components/extra/form/Input";
import Button from "../../../common/components/extra/Button";
import { FORM_FIELD_TYPE } from "../../../common/utilities/const";
import { renderInfoText } from "./helper";
import { VALIDATION_FIELD, VALIDATION_LABEL } from "./const";
import Select from "../../../common/components/extra/select/Select";

const PARENT_STYLE = { minWidth: "auto", fontSize: "12px", overflow: "unset" };

const LABEL_MAX_LEN = 255;
const TEXT_MAX_LEN = 1000;
const MAX_OPTION_LIMIT = 100;
const CHAR_OPTION_LIMIT = 500;

const { LABEL, VALUE, MIN, MAX, REQUIRED } = VALIDATION_FIELD;

function Validations({ data, onCancel, onChange }) {
    const [error, setError] = useState({});
    const [form, setForm] = useState(data.validation || {});

    const validation = data.validation;
    const type = data.type;
    const description = data.description;
    const infoText = renderInfoText(type);
    const isMediaGroup = data.group == "media";
    const isFile = data.type == FORM_FIELD_TYPE.FILE_UPLOAD;

    const updateForm = (newObj = {}) => setForm({ ...form, ...newObj });

    const handleChange = (e) => {
        const name = e.target.name;
        let value = e.target.value;
        if (name == REQUIRED) {
            value = e.target.checked;
        }
        if (name == MIN) {
            if (Number(value) > Number(form[MAX])) {
                value = form[MAX];
            }
        }
        if (name == MAX) {
            if (Number(value) < Number(form[MIN])) {
                value = form[MIN];
            }
        }
        typeof onChange == "function" && onChange(name, value);
        updateForm({ [name]: value });
    };

    const handleSelectChange = (conf) => {
        if (conf.length >= MAX_OPTION_LIMIT) {
            setError({ [VALUE]: `A max of ${MAX_OPTION_LIMIT} options is allowed.` });
            return;
        }
        const values = conf.map((opt) => opt.value.trim().length);
        if (conf.length && values.find((charcount) => charcount > CHAR_OPTION_LIMIT)) {
            setError({ [VALUE]: `A max of ${CHAR_OPTION_LIMIT} characters is allowed.` });
            return;
        }
        if (error) {
            setError({});
        }
        typeof onChange == "function" && onChange(VALUE, conf);
        updateForm({ [VALUE]: conf });
    };

    return (
        <div className="form-validations">
            <span className="fade bold">Element Details</span>
            <div className="form-validations__box flex column gap-05">
                <div className="flex column gap-05">
                    <span className="fade small-font">Description:</span>
                    <p className="small-font" style={{ marginTop: "0" }}>
                        {description}
                    </p>
                </div>
                <div className="flex column gap-05">
                    <Input
                        name={LABEL}
                        label={VALIDATION_LABEL[LABEL]}
                        type={INPUT_TYPE.TEXT}
                        parentStyle={PARENT_STYLE}
                        value={validation.label || ""}
                        tooltip={infoText.label}
                        hide={!infoText.label}
                        maxLength={LABEL_MAX_LEN}
                        onChange={handleChange}
                        required
                    />
                    {infoText.array ? (
                        <>
                            <Select
                                name={VALUE}
                                label="Values"
                                tooltip={infoText.array}
                                value={form[VALUE] || ""}
                                hide={!infoText.array}
                                onChange={(conf) => handleSelectChange(conf)}
                                styles={{
                                    multiValueLabel: (provided) => ({
                                        ...provided,
                                        wordBreak: "break-word",
                                        overflow: "unset",
                                        textOverflow: "unset",
                                        whiteSpace: "normal"
                                    })
                                }}
                                isMulti
                                isClearable
                                disabledOutline
                                isOutlined
                                isCustom
                                required
                            />

                            {error[VALUE] ? (
                                <div className="fade small-font danger-color" style={{ marginLeft: ".5rem" }}>
                                    Error: {error[VALUE]}
                                </div>
                            ) : (
                                <span className="fade small-font" style={{ marginLeft: ".5rem" }}>
                                    A max of {MAX_OPTION_LIMIT} options is allowed.
                                </span>
                            )}
                        </>
                    ) : (
                        <>
                            <Input
                                name={VALUE}
                                label={VALIDATION_LABEL[VALUE]}
                                type={INPUT_TYPE.TEXTAREA}
                                parentStyle={{ ...PARENT_STYLE, maxHeight: "max-content" }}
                                value={validation.value?.html || ""}
                                tooltip={infoText.text}
                                hide={!infoText.text}
                                maxLength={LABEL_MAX_LEN}
                                onChange={handleChange}
                                isRichTextEditor
                                required
                            />
                            <Input
                                name={VALUE}
                                label={VALIDATION_LABEL[VALUE]}
                                type={INPUT_TYPE.TEXTAREA}
                                parentStyle={{ ...PARENT_STYLE, maxHeight: "max-content" }}
                                value={validation.value?.html || ""}
                                tooltip={infoText.longText}
                                hide={!infoText.longText}
                                maxLength={TEXT_MAX_LEN}
                                onChange={handleChange}
                                isRichTextEditor
                                required
                            />
                        </>
                    )}
                    <Input
                        name={MIN}
                        label={VALIDATION_LABEL[MIN]}
                        type={INPUT_TYPE.NUMBER}
                        parentStyle={PARENT_STYLE}
                        value={form[MIN] || ""}
                        tooltip={infoText.min}
                        hide={!infoText.min}
                        onChange={handleChange}
                        constraint={{ min: isMediaGroup ? 1 : 0, max: isMediaGroup ? (isFile ? 10 : 5) : 1000 }}
                    />
                    <Input
                        name={MAX}
                        label={VALIDATION_LABEL[MAX]}
                        type={INPUT_TYPE.NUMBER}
                        parentStyle={PARENT_STYLE}
                        value={form[MAX] || ""}
                        tooltip={infoText.max}
                        hide={!infoText.max}
                        onChange={handleChange}
                        constraint={{ min: isMediaGroup ? 1 : 0, max: isMediaGroup ? (isFile ? 10 : 5) : 1000 }}
                    />
                    <Input
                        name={REQUIRED}
                        label={VALIDATION_LABEL[REQUIRED]}
                        type={INPUT_TYPE.TOGGLE}
                        parentStyle={PARENT_STYLE}
                        value={validation.required || ""}
                        tooltip={infoText.required}
                        onChange={handleChange}
                        hide={!infoText.required}
                    />
                </div>
                <div className="flex gap-05 center" style={{ marginTop: "1rem" }}>
                    <Button
                        className="secondary small-font"
                        beforeExtra={<CheckCircleIcon />}
                        onClick={onCancel}
                        options={{
                            style: { padding: "0rem .5rem", height: "2rem" }
                        }}
                    >
                        Confirm
                    </Button>
                </div>
            </div>
        </div>
    );
}

Validations.propTypes = {
    type: PropTypes.oneOf(Object.values(FORM_FIELD_TYPE)),
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    data: PropTypes.shape({
        id: PropTypes.any,
        description: PropTypes.string,
        group: PropTypes.string,
        status: PropTypes.string,
        type: PropTypes.string,
        validation: PropTypes.shape({
            max: PropTypes.any,
            min: PropTypes.any,
            value: PropTypes.any,
            label: PropTypes.string,
            required: PropTypes.bool
        })
    })
};

export default Validations;
