import { selectUser } from "../../features/common/slice";
import Navigation from "../classes/Navigation";
import { useAppSelector } from "./reduxHooks";

/**
 * @returns {{loggedIn: Boolean, homePath: String}}
 */
function useHasUser() {
    const user = useAppSelector(selectUser);
    return {
        loggedIn: !!user,
        homePath: user ? Navigation.Routes.DASHBOARD.path : Navigation.Routes.SIGNIN.path
    };
}

export default useHasUser;
