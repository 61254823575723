import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import isEqual from "lodash/isEqual";

function InputSearch({ ...inputProps }) {
    const [value, setValue] = useState("");

    useEffect(() => {
        if (!isEqual(value || "", inputProps.value || "")) {
            setValue(inputProps.value || "");
        }
    }, [inputProps.value]);

    const handleSearch = (e) => {
        const value = e.target.value;
        setValue(value);
        typeof inputProps.onChange === "function" && inputProps.onChange(value);
    };
    return <input {...inputProps} type="search" value={value || ""} onChange={handleSearch} placeholder={inputProps.placeholder || "Search"} />;
}

InputSearch.propTypes = {
    onChange: PropTypes.func
};

export default InputSearch;
