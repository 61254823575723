import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apiSlice } from "../../app/apiSlice";

const BASE_PATH = "/api/auth";

const END_POINTS = {
    refresh: { method: "get", path: "refresh" },
    logout: { method: "get", path: "logout" },
    loadGlobal: { method: "get", path: "global", options: { keepUnusedDataFor: 10 } }
};

export const authAPI = apiSlice.injectEndpoints({
    reducerPath: "authAPI",
    baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_SERVER_URI }),
    endpoints: (builder) => {
        const temp = {};
        Object.entries(END_POINTS).forEach(([key, config]) => {
            temp[key] = builder.mutation({
                query: (queryParams = {}) => {
                    const path = `${BASE_PATH}/${config.path}`;
                    const conf = {
                        url: path,
                        method: config.method,
                        credentials: "include"
                    };
                    if (queryParams.extraPath) conf.url = `${path}/${queryParams.extraPath}`;
                    if (queryParams.params) conf.params = queryParams.params;
                    if (queryParams.body) conf.body = queryParams.body;
                    return conf;
                },
                ...(config.options || {})
            });
        });
        return temp;
    }
});

export const { useRefreshMutation, useLogoutMutation, useLoadGlobalMutation } = authAPI;
export default authAPI.reducer;
