import React from "react";
import PropTypes from "prop-types";
import useNavigate from "../../../common/hooks/useNavigate";
import { useLogoutMutation } from "../../common/api";
import EmailVerification from "./EmailVerification";
import Button from "../../../common/components/extra/Button";
import Navigation from "../../../common/classes/Navigation";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectUnverifiedStatus, selectUser } from "../../common/slice";
import { UNVERIFIED_TOKEN_TYPE } from "../../common/const";

function EmailUnverified() {
    const navigate = useNavigate();
    const [logout] = useLogoutMutation();

    const user = useAppSelector(selectUser);
    const renderType = useAppSelector(selectUnverifiedStatus);

    let title = "";
    let subtext = "";

    const handleLogout = () => logout().then(() => navigate(Navigation.Routes.SIGNIN.path));

    const renderme = () => {
        switch (renderType) {
            case UNVERIFIED_TOKEN_TYPE.EXPIRED: {
                title = "Verification Link Expired";
                subtext = "Link is not available and already expired.";
                break;
            }
            case UNVERIFIED_TOKEN_TYPE.INVALID: {
                title = "Invalid Verification Link";
                subtext = "Verification link is not valid.";
                break;
            }
            case UNVERIFIED_TOKEN_TYPE.VERIFED: {
                title = "Email Verified Successfully";
                subtext =
                    "Access to TimeKeeper is currently restricted until the associated account has received approval. A notification will be dispatched via email once the account approval process is completed.";
                break;
            }
            default: {
                title = "Verify Email";
                break;
            }
        }
        return (
            <EmailVerification
                title={title}
                subtext={subtext}
                {...((user && user.code) || {})}
                buttonExtra={<Button onClick={handleLogout}>Goto login</Button>}
                starting
            />
        );
    };
    return (
        <div className="tk-email-unverified">
            <div className="tk-email-unverified__inner">{renderme()}</div>
        </div>
    );
}

EmailUnverified.propTypes = {
    user: PropTypes.object
};

export default EmailUnverified;
