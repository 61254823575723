import React, { useState } from "react";
import PropTypes from "prop-types";
import isEqual from "lodash/isEqual";
import Modal from "../../extra/Modal";
import { createConfirmAlert } from "../../../utilities/helper";

function UpdateModal({
    open,
    onChange,
    onBack,
    onSave,
    children,
    onValidate,
    config = { oldData: [], newData: [], isCreate: false, title: "" },
    styles
}) {
    const { oldData, newData, isCreate, title } = config || {};

    const [loading, setLoading] = useState(false);

    const handleSave = async () => {
        let proceed = true;
        if (typeof onValidate === "function") {
            proceed = onValidate();
        }
        if (!proceed) {
            return false;
        }
        createConfirmAlert({
            title: `${isCreate ? "Create" : "Update"} ${title}`,
            content: `Are you sure you want to ${!isCreate ? "update" : "create"} this ${title}?`,
            onConfirm: async (close) => {
                close();
                setLoading(true);
                await onSave(isCreate);
                setLoading(false);
            }
        });
    };

    return (
        <Modal
            open={open}
            onChange={onChange}
            onBack={(!isCreate && onBack) || null}
            onSave={handleSave}
            disableSave={!newData || isEqual(oldData, newData)}
            isLoading={loading}
            styles={styles}
            isForm
        >
            <div className="update-modal">
                <div className="update-modal__inner flex column gap-1">{children}</div>
            </div>
        </Modal>
    );
}

UpdateModal.propTypes = {
    open: PropTypes.bool,
    onChange: PropTypes.func,
    onBack: PropTypes.func,
    onSave: PropTypes.func,
    children: PropTypes.any,
    onValidate: PropTypes.func,
    config: PropTypes.shape({
        oldData: PropTypes.any,
        newData: PropTypes.any,
        isCreate: PropTypes.bool,
        title: PropTypes.string
    }),
    styles: PropTypes.shape({
        parent: PropTypes.object,
        content: PropTypes.object,
        body: PropTypes.object,
        title: PropTypes.object,
        form: PropTypes.object,
        footer: PropTypes.object
    })
};

export default UpdateModal;
