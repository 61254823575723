import React from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import moment from "moment-timezone";

function MobileTime() {
    return (
        <div className="mobile-input mobile-input--dropdown input-size">
            <div className="input-box flex center">
                <span className="fade italic">{moment().format("hh:mm:ss a")}</span>
            </div>
            <div className="dropdown-indicator">
                <ArrowDropDownIcon className="primary-color" style={{ width: "3rem", height: "auto" }} />
            </div>
        </div>
    );
}

export default MobileTime;
