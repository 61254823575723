import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import InfoIcon from "@mui/icons-material/Info";
import { ReactComponent as ExpandSVG } from "../../../assets/images/expand-sidebar.svg";
import { ReactComponent as HideSVG } from "../../../assets/images/hide-sidebar.svg";
import MyTooltip, { PLACES } from "./Tooltip";
import { useAppSelector } from "../../hooks/reduxHooks";
import { selectSidebarCollapse } from "../../../features/common/slice";

function Sidebar({ head, items = [], extraItems, onItemSelect, footer, onChange, isMobile }) {
    const sidebarRef = useRef(null);

    const [toggle, setToggle] = React.useState(isMobile);

    useEffect(() => {
        setToggle(isMobile);
        onChange(isMobile);
    }, [isMobile]);

    const handleToggle = () => {
        setToggle(!toggle);
        onChange(!toggle);
    };

    const renderItems = (data = []) => {
        return data.map((item) => {
            let classname = "tk-sidebar__item";
            const isGrouped = Array.isArray(item.subItems);
            const isDisabled = item.isDisabled;
            const isActive = item.isActive;
            if (isActive) {
                classname += " tk-sidebar__item--active ";
            }
            if (isDisabled) {
                classname += " tk-sidebar__item--disabled ";
            }
            const WrapperElement = !isDisabled ? MyTooltip : "li";
            const createDefaultItem = (item) => (
                <WrapperElement
                    element="li"
                    className={classname.trim()}
                    key={item.id}
                    style={item.style ? item.style : {}}
                    onClick={() => !isDisabled && onItemSelect(item)}
                    position={PLACES.TOP}
                    message={toggle ? item.label : ""}
                >
                    <div className="tk-sidebar__item__inner w100 flex" style={{ justifyContent: "space-between" }}>
                        {item.render}
                        {isDisabled && (
                            <MyTooltip
                                style={{ width: "1.3rem", cursor: "pointer" }}
                                position={PLACES.RIGHT}
                                message={item.warningMessage || "Disabled"}
                            >
                                <InfoIcon style={{ color: "red" }} />
                            </MyTooltip>
                        )}
                    </div>
                </WrapperElement>
            );
            if (!isGrouped) return createDefaultItem(item);
            return <GroupedItem key={item.id} item={item} onItemSelect={onItemSelect} />;
        });
    };

    return (
        <div className={`tk-sidebar  ${!toggle ? "tk-sidebar--default" : "tk-sidebar--compact"}`}>
            <div ref={sidebarRef} className="tk-sidebar__inner">
                {head && <div className="tk-sidebar__head">{head}</div>}
                <div className="tk-sidebar__content">
                    <ul className="tk-sidebar__list">{renderItems(items)}</ul>
                </div>
                <div className="tk-sidebar__footer">
                    <ul className="tk-sidebar__list">
                        {!isMobile && (
                            <MyTooltip
                                element="li"
                                className="tk-sidebar__item collapse"
                                onClick={handleToggle}
                                position={PLACES.TOP}
                                message={toggle ? "Toggle Sidebar" : ""}
                            >
                                <div className="tk-sidebar__item__inner">
                                    <div>{toggle ? <HideSVG style={{ width: "23px" }} /> : <ExpandSVG style={{ width: "29px" }} />}</div>
                                    {!toggle && <span>Collapse</span>}
                                </div>
                            </MyTooltip>
                        )}
                        {renderItems(extraItems)}
                        {footer}
                    </ul>
                </div>
            </div>
        </div>
    );
}

const GroupedItem = ({ item = { render: "", subItems: [] }, onItemSelect }) => {
    const [active, setActive] = useState(true);
    const isSidebarCollapsed = useAppSelector(selectSidebarCollapse);

    return (
        <li className={`tk-sidebar__item-grouped ${active ? "active" : ""}`.trim()}>
            <MyTooltip
                className="tk-sidebar__item-grouped__title"
                onClick={() => setActive(!active)}
                position={PLACES.RIGHT}
                message={isSidebarCollapsed ? (active ? "Hide " : "Show ") + item.label : ""}
            >
                {item.render}
                <div className={`icon flex ${active ? "active" : ""}`}>
                    <KeyboardArrowUpIcon />
                </div>
            </MyTooltip>
            {active && (
                <ul className="tk-sidebar__list tk-sidebar__item-grouped__list">
                    {item.subItems.map((d) => {
                        let classname = "tk-sidebar__item";
                        const isDisabled = d.isDisabled;
                        const isActive = d.isActive;
                        if (isActive) {
                            classname += " tk-sidebar__item--active ";
                        }
                        if (isDisabled) {
                            classname += " tk-sidebar__item--disabled ";
                        }
                        const WrapperElement = !isDisabled ? MyTooltip : "li";
                        return (
                            <WrapperElement
                                element="li"
                                className={classname.trim()}
                                key={d.id}
                                style={d.style ? d.style : {}}
                                onClick={() => !isDisabled && onItemSelect(d)}
                                position={PLACES.TOP}
                                message={isSidebarCollapsed ? d.label : ""}
                            >
                                <div className="tk-sidebar__item__inner flex w100" style={{ justifyContent: "space-between" }}>
                                    {d.render}
                                    {isDisabled && (
                                        <MyTooltip
                                            style={{ width: "1.3rem", cursor: "pointer" }}
                                            position={PLACES.RIGHT}
                                            message={d.warningMessage || "Disabled"}
                                        >
                                            <InfoIcon style={{ color: "red" }} />
                                        </MyTooltip>
                                    )}
                                </div>
                            </WrapperElement>
                        );
                    })}
                </ul>
            )}
        </li>
    );
};

GroupedItem.propTypes = {
    item: PropTypes.shape({
        render: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
        subItems: PropTypes.array,
        label: PropTypes.string
    }),
    onItemSelect: PropTypes.func
};

Sidebar.propTypes = {
    onChange: PropTypes.func,
    head: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
    footer: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
    items: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            render: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node])
        })
    ),
    extraItems: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            render: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node])
        })
    ),
    onItemSelect: PropTypes.func,
    isMobile: PropTypes.bool
};

export default Sidebar;
