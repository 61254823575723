import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import useDetectOutsideClick from "../../hooks/useDetectOutsideClick";

export const POPOVER_POSITION = {
    TOP: "top",
    TOP_LEFT: "top-left",
    TOP_RIGHT: "top-right",
    LEFT: "left",
    RIGHT: "right",
    BOTTOM: "bottom",
    BOTTOM_LEFT: "bottom-left",
    BOTTOM_RIGHT: "bottom-right"
};

function Popover({ children, content, position = POPOVER_POSITION.BOTTOM_LEFT, styles = {}, onChange }) {
    const ref = useRef(null);
    const [object, setObject] = useState({ open: false });
    const [isClickedOutside] = useDetectOutsideClick(ref);

    const updateObject = (newObj = {}) => {
        setObject((prev) => ({ ...prev, ...newObj }));
        typeof onChange == "function" && onChange(newObj.open);
    };

    const isActive = !!object.open;

    useEffect(() => {
        if (object.open && isClickedOutside) {
            updateObject({ open: false });
        }
    }, [isClickedOutside]);

    return (
        <div ref={ref} className={`tk-popover ${(isActive && "active") || ""}`.trim()} style={{ ...styles.parent, position: "relative" }}>
            {React.Children.map(children, (child) => {
                if (React.isValidElement(child)) {
                    return React.cloneElement(child, {
                        onClick: () => updateObject({ open: !object.open })
                    });
                }
                return child;
            })}
            {object.open && (
                <div className={`tk-popover__content tk-popover--${position}`} style={{ ...styles.content }}>
                    {content}
                </div>
            )}
        </div>
    );
}

Popover.propTypes = {
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.element, PropTypes.string]),
    content: PropTypes.oneOfType([PropTypes.node, PropTypes.element, PropTypes.string]),
    position: PropTypes.oneOf(Object.values(POPOVER_POSITION)),
    styles: PropTypes.shape({
        parent: PropTypes.object,
        content: PropTypes.object
    }),
    onChange: PropTypes.func
};

export default Popover;
