import React from "react";
import ListIcon from "@mui/icons-material/FormatListNumbered";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import SubscriptionsIcon from "@mui/icons-material/Bookmarks";
import UploadIcon from "@mui/icons-material/Upload";
import CompanySubscriptionsIcon from "@mui/icons-material/Beenhere";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import TimelineIcon from "@mui/icons-material/Timeline";
import ShiftIcon from "@mui/icons-material/Update";
import GroupsIcon from "@mui/icons-material/Groups";
import SiteIcon from "@mui/icons-material/Place";
import WorkIcon from "@mui/icons-material/Work";
import PenaltyIcon from "@mui/icons-material/GppMaybe";
import FormIcon from "@mui/icons-material/Summarize";
import PublishIcon from "@mui/icons-material/Publish";
import HolidayIcon from "@mui/icons-material/EventNote";
import CurrencyIcon from "@mui/icons-material/AttachMoney";
import { ReactComponent as DashboardSVG } from "../assets/images/dashboard.svg";
import { ReactComponent as PaperSVG } from "../assets/images/paper.svg";
import { ReactComponent as SettingsSVG } from "../assets/images/settings.svg";

// common pages
import Dashboard from "./Dashboard";
import Settings from "./Settings";

// admin pages
import AuthAdmin from "./admin/AuthAdmin";
import Roles from "./admin/Roles";
import Subscription from "./admin/Subscription";
import ClientSupport from "./admin/ClientSupport";
import Companies from "./admin/Companies";
import ActivityLogs from "./admin/ActivityLogs";
import DataReports from "./admin/DataReports";
import Forms from "./admin/Forms";
import CompanySubscription from "./admin/CompanySubscription";
import Penalties from "./admin/Penalties";

// company pages
import ResetPassword from "./company/ResetPassword";
import SignIn from "./company/SignIn";
import SignUp from "./company/SignUp";
import VerifyCompanyEmail from "./company/VerifyCompanyEmail";
import Employees from "./company/Employees";
import EmployeesBulkUpload from "./company/EmployeesBulkUpload";
import EmployeeWorkTypes from "./company/EmployeeWorkTypes";
import EmployeeWorkShifts from "./company/EmployeeWorkShifts";
import CompanySites from "./company/CompanySites";
import SubscriptionUpgrade from "./company/SubscriptionUpgrade";
import SubscriptionPayment from "./company/SubscriptionPayment";
import SubscriptionConfirmation from "./company/SubscriptionConfirmation";
import EmployeesWorkHistories from "./company/EmployeesWorkHistories";
import SignUpVerify from "./company/SignUpVerify";
import SettingsSetup from "./company/SettingsSetup";
import EmailUnverified from "../features/company/auth/EmailUnverified";
import WaitingApproval from "../features/company/auth/WaitingApproval";
import DeactivatedAccount from "../features/company/auth/DeactivatedAccount";
import ExpiredSubscriptionAccount from "../features/company/auth/ExpiredSubscriptionAccount";
import PendingPayment from "./company/PendingPayment";
import CompanyForms from "./company/CompanyForms";
import CompanyFormUpsert from "./company/CompanyFormUpsert";
import EmployeeSubmittedForms from "./company/EmployeeSubmittedForms";
import CompanyHolidays from "./company/CompanyHolidays";
import CompanyPayroll from "./company/CompanyPayroll";

export const COMMON_ROUTES = {
    DASHBOARD: {
        id: 1,
        path: "/dashboard",
        label: "Dashboard",
        component: <Dashboard />,
        image: <DashboardSVG style={{ width: "18px" }} />,
        isMenu: true,
        isProtected: true
    },
    SETTINGS: {
        path: "/settings",
        label: "Settings",
        component: <Settings />,
        image: <SettingsSVG style={{ width: "18px" }} />,
        isMenu: true,
        isBottom: true,
        isProtected: true
    }
};

export const ADMIN_ROUTES = {
    ADMIN_AUTH: {
        path: "/admin",
        component: <AuthAdmin />
    },
    penaltiesAPI: {
        path: "/penalties",
        label: "Penalties",
        component: <Penalties />,
        image: <PenaltyIcon />,
        isMenu: true,
        isProtected: true
    },
    COMPANIES: {
        id: 2,
        path: "",
        image: <CorporateFareIcon />,
        label: "Companies",
        isGroup: true,
        isMenu: true,
        isProtected: true,
        subpaths: {
            LIST: {
                path: "/companies",
                label: "List",
                component: <Companies />,
                image: <ListIcon />
            },
            COMPANY_SUBSCRIPTION: {
                path: "/company-subscription",
                label: "Subscription",
                component: <CompanySubscription />,
                image: <CompanySubscriptionsIcon />
            }
        }
    },
    MANAGEMENT: {
        id: 3,
        path: "",
        image: <ManageAccountsIcon />,
        label: "Management",
        isGroup: true,
        isMenu: true,
        isProtected: true,
        subpaths: {
            ROLES: {
                path: "/roles",
                label: "Roles",
                component: <Roles />,
                image: <LockOpenIcon />
            },
            SUBSCRIPTION: {
                path: "/subscription",
                label: "Subscription",
                component: <Subscription />,
                image: <SubscriptionsIcon />
            }
        }
    },
    CLIENT_SUPPORT: {
        path: "/client-support",
        label: "Client Support",
        component: <ClientSupport />,
        image: <PaperSVG />,
        isMenu: true,
        isDisabled: true,
        isProtected: true
    },
    ACTIVITY_LOGS: {
        path: "/activity-logs",
        label: "Activity Logs",
        component: <ActivityLogs />,
        image: <PaperSVG />,
        isMenu: true,
        isDisabled: true,
        isProtected: true
    },
    DATA_REPORTS: {
        path: "/data-reports",
        label: "Data Reports",
        component: <DataReports />,
        image: PaperSVG,
        isMenu: true,
        isDisabled: true,
        isProtected: true
    },
    FORMS: {
        path: "/forms",
        label: "Forms",
        component: <Forms />,
        image: <PaperSVG />,
        isMenu: true,
        isDisabled: true,
        isProtected: true
    }
};

export const COMPANY_ROUTES = {
    // auth routes
    SIGNIN: {
        path: "/",
        default: true,
        component: <SignIn />
    },
    SIGNUP: {
        path: "/signup",
        component: <SignUp />
    },
    SIGNUP_VERIFY: {
        path: "/signup/verify",
        component: <SignUpVerify />
    },
    RESET_PASSWORD: {
        path: "/reset-password-request",
        component: <ResetPassword />
    },
    CONFIRM_RESET_PASSWORD: {
        path: "/reset-password/:token",
        component: <ResetPassword isChangePassword />,
        isProtected: false
    },
    VERIFY_EMAIL: {
        path: "/verify/:token",
        component: <VerifyCompanyEmail />,
        isProtected: false
    },
    // account check routes
    UNVERIFIED_EMAIL: {
        path: "/check/verify-email",
        component: <EmailUnverified />,
        isProtected: true
    },
    WAITING_APPROVAL: {
        path: "/check/waiting-for-approval",
        component: <WaitingApproval />,
        isProtected: true
    },
    DEACTIVATED_ACCOUNT: {
        path: "/check/account-deactivated",
        component: <DeactivatedAccount />,
        isProtected: true
    },
    EXPIRED_SUBSCRIPTION: {
        path: "/check/expired-subscription",
        component: <ExpiredSubscriptionAccount />,
        isProtected: true
    },
    PENDING_PAYMENT: {
        path: "/check/pending-payment",
        isProtected: true,
        component: <PendingPayment />
    },
    SETTINGS_SETUP: {
        path: "/settings/setup",
        isProtected: true,
        component: <SettingsSetup />
    },
    // main routes
    SITES: {
        path: "/sites",
        label: "Sites",
        component: <CompanySites />,
        image: <SiteIcon />,
        isMenu: true,
        isProtected: true
    },
    SHIFT: {
        path: "/work-shifts",
        label: "Shifts",
        component: <EmployeeWorkShifts />,
        image: <ShiftIcon />,
        isMenu: true,
        isProtected: true
    },
    WORK_TYPES: {
        path: "/work-types",
        label: "Work Types",
        component: <EmployeeWorkTypes />,
        image: <WorkIcon />,
        isMenu: true,
        isProtected: true
    },
    COMPANY_FORM: {
        path: "/forms",
        label: "Forms",
        component: <CompanyForms />,
        image: <FormIcon />,
        isMenu: true,
        isProtected: true
    },
    COMPANY_FORM_CREATE: {
        path: "/forms/create",
        label: "Forms",
        component: <CompanyFormUpsert />,
        image: <FormIcon />,
        isMenu: false,
        isProtected: true
    },
    COMPANY_FORM_UPDATE: {
        path: "/forms/update/:id",
        label: "Forms",
        component: <CompanyFormUpsert isUpdate />,
        isMenu: false,
        isProtected: true
    },
    COMPANY_HOLIDAY: {
        path: "/holidays",
        label: "Holidays",
        component: <CompanyHolidays />,
        image: <HolidayIcon />,
        isMenu: true,
        isProtected: true
    },
    PAYROLL: {
        path: "/payroll",
        label: "Payroll",
        component: <CompanyPayroll />,
        image: <CurrencyIcon />,
        isMenu: true,
        isProtected: true
    },
    EMPLOYEES: {
        path: "/",
        label: "Employees",
        image: <GroupsIcon />,
        isMenu: true,
        isProtected: true,
        isGroup: true,
        subpaths: {
            LIST: {
                path: "/employees-list",
                label: "List",
                component: <Employees />,
                image: <ListIcon />
            },
            BULK_UPLOAD: {
                path: "/bulk-add",
                label: "Bulk Upload",
                component: <EmployeesBulkUpload />,
                image: <UploadIcon />
            },
            WORK_HISTORY: {
                path: "/work-histories",
                label: "Work Histories",
                component: <EmployeesWorkHistories />,
                image: <TimelineIcon />
            },
            SUBMITTED_FORM: {
                path: "/submitted-forms",
                label: "Submitted Forms",
                component: <EmployeeSubmittedForms />,
                image: <PublishIcon />
            }
        }
    },
    SUBSCRIPTION_UPGRADE: {
        path: "/subscription-plan/upgrade",
        isMenu: false,
        isProtected: true,
        isGroup: false,
        component: <SubscriptionUpgrade />
    },
    SUBSCRIPTION_PAY: {
        path: "/subscription-plan/pay/:token",
        isMenu: false,
        isProtected: true,
        isGroup: false,
        component: <SubscriptionPayment />
    },
    SUBSCRIPTION_PAY_CONFIRM: {
        path: "/subscription-plan/pay-confirm/:token",
        isMenu: false,
        isProtected: true,
        isGroup: false,
        component: <SubscriptionConfirmation />
    }
};

export const SUBSCRIPTION_PAY_ROUTES = [
    COMPANY_ROUTES.SUBSCRIPTION_UPGRADE,
    COMPANY_ROUTES.SUBSCRIPTION_PAY,
    COMPANY_ROUTES.SUBSCRIPTION_PAY_CONFIRM
];

export default {
    ...COMMON_ROUTES,
    ...ADMIN_ROUTES,
    ...COMPANY_ROUTES
};
