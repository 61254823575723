import React from "react";
import PropTypes from "prop-types";
import ReactSelect from "react-select";
import CustomOption from "./CustomOption";
import CustomMenuList from "./CustomMenuList";

function SelectVirtual({ ...props }) {
    return <ReactSelect {...props} components={{ Option: CustomOption, MenuList: CustomMenuList }} />;
}

SelectVirtual.propTypes = {
    onChange: PropTypes.func,
    label: PropTypes.string,
    style: PropTypes.object,
    isClearable: PropTypes.bool,
    noborder: PropTypes.bool,
    isCustom: PropTypes.bool,
    async: PropTypes.bool,
    isOutlined: PropTypes.bool,
    disabledOutline: PropTypes.bool,
    customNoOption: PropTypes.any,
    virtualize: PropTypes.bool
};

export default SelectVirtual;
