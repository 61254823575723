import React, { useState } from "react";
import Subscriptions from "../../features/admin/subscriptions/Subscription";
import CardPage from "../../common/components/extra/CardPage";
import { selectLoading } from "../../features/admin/subscriptions/slice";
import Button, { BTN_TYPE } from "../../common/components/extra/Button";
import { useAppSelector } from "../../common/hooks/reduxHooks";
import Loader from "../../common/components/extra/Loader";

function SubscriptionPage() {
    const loading = useAppSelector(selectLoading);
    const [openAddModal, setOpenAddModal] = useState(false);

    return (
        <div className="tk-subscription tk-page-type-01">
            {loading && <Loader />}
            <div className="tk-subscription__inner">
                <CardPage title="Subscriptions" contentStyle={{ padding: 0, height: "calc(100vh - 12.3rem)" }}>
                    <div className="content">
                        <div className="control">
                            <div className="search"></div>
                            <Button type={BTN_TYPE.ADD} onClick={() => setOpenAddModal(true)} />
                        </div>
                        <div className="tk-subscription__body">
                            <Subscriptions openAddModal={openAddModal} onAddModalClose={() => setOpenAddModal(false)} />
                        </div>
                    </div>
                </CardPage>
            </div>
        </div>
    );
}

export default SubscriptionPage;
