import React from "react";
import PropTypes from "prop-types";
import ClearIcon from "@mui/icons-material/Clear";
import ConfirmFilter from "@mui/icons-material/FilterAlt";
import Button from "../Button";
import { useAppSelector } from "../../../hooks/reduxHooks";
import { selectViewport } from "../../../../features/common/slice";

function FilterControl({ onReset, onFilter, isClearDisabled, isFilterDisabled, isLoading }) {
    const viewport = useAppSelector(selectViewport);
    const isMobile = viewport.isMobile;
    return (
        <div className={"flex gap-05 center" + (isMobile ? " column" : "")}>
            <Button
                className="danger"
                beforeExtra={<ClearIcon />}
                disabled={isClearDisabled}
                onClick={onReset}
                options={{ style: { height: "2.2rem" } }}
                small
            >
                {!isMobile && <span style={{ whiteSpace: "nowrap", lineHeight: ".5" }}>Clear</span>}
            </Button>
            <Button
                className="primary"
                beforeExtra={<ConfirmFilter />}
                disabled={isFilterDisabled}
                onClick={onFilter}
                options={{ style: { height: "2.2rem" } }}
                isLoading={isLoading}
                small
            >
                {!isMobile && <span style={{ whiteSpace: "nowrap", lineHeight: ".5" }}>Filter</span>}
            </Button>
        </div>
    );
}

export default FilterControl;

FilterControl.propTypes = {
    onReset: PropTypes.func,
    onFilter: PropTypes.func,
    isClearDisabled: PropTypes.bool,
    isFilterDisabled: PropTypes.bool,
    isLoading: PropTypes.bool
};
