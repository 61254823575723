import React from "react";

function MobileEmailInput() {
    return (
        <div className="mobile-input mobile-input--text-input">
            <div className="input-box flex center">
                <span className="fade">Email Address</span>
            </div>
        </div>
    );
}

export default MobileEmailInput;
