import React from "react";
import PropTypes from "prop-types";
import { selectTableConfig, selectView } from "./slice";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import PageWrapperLayout from "../../../common/components/layout/PageWrapperLayout";
import ViewModal from "./ViewModal";
import UpdateModal from "./UpdateModal";
import CreateTableHeaders from "./CreateTableHeaders";
import { usePaginateSubscriptions, useRemoveSubscription } from "./hooks";
import { TABLE_ACTION_TYPE_COMMON } from "../../../common/utilities/const";

function Subscription({ openAddModal, onAddModalClose }) {
    const tableConfig = useAppSelector(selectTableConfig);
    const view = useAppSelector(selectView);

    const [data, fetching, refetch] = usePaginateSubscriptions();
    const [onRemove] = useRemoveSubscription();

    const onAction = (row, type) => {
        if (type === TABLE_ACTION_TYPE_COMMON.REMOVE) {
            onRemove(row.id).then(refetch);
        }
    };

    return (
        <>
            <PageWrapperLayout
                config={{
                    data,
                    refetch,
                    tableConfig,
                    view,
                    fetching,
                    CreateTableHeaders
                }}
                modal={{
                    View: ViewModal,
                    Update: UpdateModal
                }}
                openAddModal={openAddModal}
                onAddModalClose={onAddModalClose}
                onAction={onAction}
            />
        </>
    );
}

Subscription.propTypes = {
    openAddModal: PropTypes.bool,
    onAddModalClose: PropTypes.func
};

export default Subscription;
