import React from "react";
import PropTypes from "prop-types";
import { createClass, createConfirmAlert, createGroup, sanitizeWords } from "../../../common/utilities/helper";
import { SHIFT_TYPE, STANDARD_DATE_FORMAT } from "../../../common/utilities/const";
import Select from "../../../common/components/extra/select/Select";
import Input, { INPUT_TYPE } from "../../../common/components/extra/form/Input";
import TimePickerRange, { EVENT } from "../../../common/components/extra/dateTime/TimePickerRange";
import SectionCollapseError from "../../../common/components/extra/section/SectionCollapseError";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectUser } from "../../common/slice";
import SectionCollapseWarning from "../../../common/components/extra/section/SectionCollapseWarning";
import { BASE_CLASS, FIELDS, TIME_TYPE } from "./const";
import { useUpsertWorkShift } from "./hooks";
import BaseUpdateModal from "../../../common/components/layout/modalViewUpdateLayout/BaseUpdateModal";
import Divider from "../../../common/components/extra/Divider";
import SectionCollapseInfo from "../../../common/components/extra/section/SectionCollapseInfo";

const {
    TITLE,
    SHIFT_TYPE: TYPE,
    MAX_OVERTIME,
    MAX_BREAK_DURATION,
    REQ_SHIFT_TIME,
    START_TIME,
    END_TIME,
    START_TIME_2,
    END_TIME_2,
    BREAK_TIME,
    BREAK_END_TIME,
    BREAK_TIME_2,
    BREAK_END_TIME_2,
    IS_HOLIDAY
} = FIELDS;

function UpdateModal({ open, onChange, onClose, onBack, id, onFinish, readOnly, fromHoliday, transparentOverlay }) {
    const [form, updateForm, { upsert, isLoading, config, hasChanges, isEditAllowed, allowedTime, error, setError }] = useUpsertWorkShift(id);

    const user = useAppSelector(selectUser);
    const setting = user.Setting;
    const isCreate = !id;

    const {
        shiftTypeOption,
        isSplit,
        hasShiftOne,
        hasShiftTwo,
        isSatisfied,
        shiftOneValue,
        shiftTwoValue,
        breakValue,
        breakTwoValue,
        isNextDay,
        isBreakSatisfied,
        maxBreakDurationOption,
        maxBreakDuration
    } = config;

    const reqShiftHrsNotMet = !!(!isSatisfied && hasShiftOne && hasShiftTwo);

    const handleSave = async () => {
        const result = await upsert();
        if (!result.error) {
            typeof onFinish === "function" && onFinish(result, isCreate);
        }
    };

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        const config = { ...form };
        config[name] = value;

        switch (name) {
            case FIELDS.MAX_BREAK_DURATION.name:
            case FIELDS.REQ_SHIFT_TIME.name:
            case FIELDS.MAX_OVERTIME.name: {
                config[name] = value ? parseFloat(value) : 0;
                break;
            }
            case FIELDS.SHIFT_TYPE.name: {
                if (value === SHIFT_TYPE.SPLIT) {
                    config[START_TIME_2.name] = null;
                    config[END_TIME_2.name] = null;
                    config[BREAK_TIME_2.name] = null;
                    config[BREAK_END_TIME_2.name] = null;
                }
                break;
            }
            default:
                break;
        }
        setError(null);
        updateForm(config);
    };

    const handleTimeChange = (key, conf = {}, event) => {
        const temp = {};
        const start_time = conf.start;
        const end_time = conf.end;
        const isChanging = event == EVENT.CHANGING;
        switch (key) {
            case TIME_TYPE.SHIFT_ONE: {
                temp[START_TIME.name] = start_time;
                temp[END_TIME.name] = end_time;
                if (isChanging) {
                    if (isSplit) {
                        // reset shift two
                        temp[BREAK_TIME.name] = null;
                        temp[BREAK_END_TIME.name] = null;
                        temp[START_TIME_2.name] = null;
                        temp[END_TIME_2.name] = null;
                        temp[BREAK_TIME_2.name] = null;
                        temp[BREAK_END_TIME_2.name] = null;
                    } else {
                        // reset break
                        temp[BREAK_TIME.name] = null;
                        temp[BREAK_END_TIME.name] = null;
                    }
                }
                break;
            }
            case TIME_TYPE.SHIFT_TWO: {
                temp[START_TIME_2.name] = start_time;
                temp[END_TIME_2.name] = end_time;
                if (isChanging) {
                    // reset break
                    temp[BREAK_TIME_2.name] = null;
                    temp[BREAK_END_TIME_2.name] = null;
                }
                break;
            }
            case TIME_TYPE.BREAK: {
                temp[BREAK_TIME.name] = start_time;
                temp[BREAK_END_TIME.name] = end_time;
                break;
            }
            case TIME_TYPE.BREAK_TWO: {
                temp[BREAK_TIME_2.name] = start_time;
                temp[BREAK_END_TIME_2.name] = end_time;
                break;
            }
            default:
                break;
        }
        updateForm(temp);
    };

    const handleToggle = (name, checked) => {
        updateForm({ [name]: checked });
    };

    return (
        <BaseUpdateModal
            open={open}
            onChange={onChange}
            onClose={onClose}
            onBack={(!isCreate && onBack) || null}
            onSave={
                readOnly
                    ? null
                    : () =>
                          createConfirmAlert({
                              title: !isCreate ? "Update Work Shift" : "Create Work Shift",
                              content: `Are you sure you want to ${isCreate ? "create" : "update"} this work Shift? This cannot be undone.`,
                              onConfirm: async (close) => {
                                  close();
                                  await handleSave();
                              }
                          })
            }
            disableSave={!isSatisfied || hasChanges || !isBreakSatisfied?.isSatisfied}
            styles={{ content: { width: "50vw", maxWidth: "45rem", minWidth: "40rem" } }}
            isForm={!readOnly}
            transparentOverlay={transparentOverlay}
        >
            <div className={createClass(BASE_CLASS)}>
                <div className={createClass("__inner", BASE_CLASS)}>
                    <div className={createClass("__inner-row")} style={{ marginBottom: "3rem" }}>
                        {createGroup({
                            base: BASE_CLASS,
                            title: "Work Shift Information",
                            body: (
                                <>
                                    {!fromHoliday && (
                                        <Input
                                            type={INPUT_TYPE.TOGGLE}
                                            value={form[IS_HOLIDAY.name]}
                                            onChange={(e) => handleToggle(IS_HOLIDAY.name, Boolean(e.target.checked))}
                                            isLoading={isLoading}
                                            readOnly={readOnly}
                                            renderValue={readOnly ? (form[IS_HOLIDAY.name] ? "Yes" : "No") : null}
                                            {...IS_HOLIDAY}
                                        />
                                    )}
                                    <Input
                                        type={INPUT_TYPE.TEXT}
                                        onChange={handleChange}
                                        value={config[TITLE.name] || ""}
                                        isLoading={isLoading}
                                        readOnly={readOnly}
                                        renderValue={readOnly && sanitizeWords(form[TITLE.name])}
                                        {...TITLE}
                                    />
                                    <Select
                                        value={shiftTypeOption.find((ctr) => ctr.value === form[TYPE.name]) || ""}
                                        options={shiftTypeOption}
                                        onChange={(val) => handleChange({ target: { name: TYPE.name, value: val.value } })}
                                        isDisabled={!isEditAllowed}
                                        isLoading={isLoading}
                                        readOnly={readOnly}
                                        isOutlined
                                        disabledOutline
                                        {...TYPE}
                                    />
                                    <Input
                                        type={INPUT_TYPE.NUMBER}
                                        onChange={handleChange}
                                        value={form[REQ_SHIFT_TIME.name] || ""}
                                        afterExtra={<span>Hour(s)</span>}
                                        disabled={!isEditAllowed}
                                        isLoading={isLoading}
                                        readOnly={readOnly}
                                        renderValue={readOnly && form[REQ_SHIFT_TIME.name]}
                                        {...{ ...REQ_SHIFT_TIME, default: null }}
                                    />
                                    <Input
                                        type={INPUT_TYPE.NUMBER}
                                        onChange={handleChange}
                                        value={form[MAX_OVERTIME.name] || 0}
                                        afterExtra={<span>Hour(s)</span>}
                                        disabled={!isEditAllowed}
                                        isLoading={isLoading}
                                        readOnly={readOnly}
                                        renderValue={readOnly && form[MAX_OVERTIME.name]}
                                        {...{ ...MAX_OVERTIME, default: null }}
                                    />
                                    <Select
                                        value={maxBreakDuration}
                                        options={maxBreakDurationOption}
                                        onChange={(val) => handleChange({ target: { name: MAX_BREAK_DURATION.name, value: val.value } })}
                                        isDisabled={!isEditAllowed}
                                        isLoading={isLoading}
                                        readOnly={readOnly}
                                        subtext={{
                                            message: "Note: No scheduled break period; break time will be flexible.",
                                            className: "semi-bold fade",
                                            hide: !!(
                                                (isSplit ? breakValue.range.start || breakTwoValue.range.start : breakValue.range.start) ||
                                                !maxBreakDuration.value
                                            )
                                        }}
                                        isOutlined
                                        disabledOutline
                                        {...{ ...MAX_BREAK_DURATION, default: null }}
                                    />

                                    {isSplit && <Divider title="Shift One" style={{ marginTop: "1rem" }} />}

                                    <TimePickerRange
                                        label="Time"
                                        range={shiftOneValue.range}
                                        constraint={shiftOneValue.constraint}
                                        onChange={(conf, event) => handleTimeChange(TIME_TYPE.SHIFT_ONE, conf, event)}
                                        disabled={!isEditAllowed}
                                        timezone={setting.timezone}
                                        isLoading={isLoading}
                                        readOnly={readOnly}
                                        menuPlacement="top"
                                        noBorder={isSplit && !maxBreakDuration.value}
                                        required
                                    />

                                    {!!maxBreakDuration.value && (
                                        <TimePickerRange
                                            label="Break"
                                            range={breakValue.range}
                                            constraint={breakValue.constraint}
                                            onChange={(conf, event) => handleTimeChange(TIME_TYPE.BREAK, conf, event)}
                                            disabled={!isEditAllowed || !shiftOneValue.range.start || !shiftOneValue.range.end}
                                            timezone={setting.timezone}
                                            isLoading={isLoading}
                                            noBorder={isSplit}
                                            readOnly={readOnly}
                                            menuPlacement="top"
                                            subtext={{
                                                message: "Note: Must satisfy the max break hours.",
                                                className: "semi-bold fade",
                                                hide: !breakValue.range.start || !form[MAX_BREAK_DURATION.name] || !!isSplit
                                            }}
                                        />
                                    )}

                                    {isSplit && (
                                        <>
                                            <Divider title="Shift Two" style={{ marginTop: "1rem" }} />
                                            <TimePickerRange
                                                label="Time"
                                                range={shiftTwoValue.range}
                                                constraint={shiftTwoValue.constraint}
                                                onChange={(conf, event) => handleTimeChange(TIME_TYPE.SHIFT_TWO, conf, event)}
                                                disabled={!isEditAllowed || !shiftOneValue.range.start || !shiftOneValue.range.end}
                                                timezone={setting.timezone}
                                                isLoading={isLoading}
                                                menuPlacement="top"
                                                readOnly={readOnly}
                                                baseDate={shiftOneValue.range.end && shiftOneValue.range.end.format(STANDARD_DATE_FORMAT)}
                                                noBorder={!maxBreakDuration.value}
                                                required
                                            />
                                            {!!maxBreakDuration.value && (
                                                <TimePickerRange
                                                    label="Break"
                                                    range={breakTwoValue.range}
                                                    constraint={breakTwoValue.constraint}
                                                    onChange={(conf, event) => handleTimeChange(TIME_TYPE.BREAK_TWO, conf, event)}
                                                    disabled={!isEditAllowed || !shiftTwoValue.range.start || !shiftTwoValue.range.end}
                                                    timezone={setting.timezone}
                                                    isLoading={isLoading}
                                                    menuPlacement="top"
                                                    readOnly={readOnly}
                                                    baseDate={shiftOneValue.range.end && shiftOneValue.range.end.format(STANDARD_DATE_FORMAT)}
                                                    subtext={{
                                                        message:
                                                            "Note: Must satisfy the max break hours. It is the total selected time of the break for shift one and shift two.",
                                                        className: "semi-bold fade",
                                                        hide: !breakTwoValue.range.start || !form[MAX_BREAK_DURATION.name] || !isSplit
                                                    }}
                                                    noBorder
                                                />
                                            )}
                                        </>
                                    )}
                                </>
                            )
                        })}
                    </div>
                    {!readOnly && !isLoading && (
                        <div className="flex column gap-05">
                            <SectionCollapseWarning show={!isCreate && !isEditAllowed}>
                                Editing capabilities are limited while the work shift is active. Editing will be available before {allowedTime.before}{" "}
                                or after {allowedTime.after}.
                            </SectionCollapseWarning>
                            <SectionCollapseError show={!!error || reqShiftHrsNotMet || !isBreakSatisfied?.isSatisfied}>
                                {reqShiftHrsNotMet ? (
                                    <div className="flex column">
                                        <span>Shift range must at least meet the required shift hours.</span>
                                        <span>(Total Shift Hours - Max Break hours)</span>
                                    </div>
                                ) : !isBreakSatisfied?.isSatisfied ? (
                                    isBreakSatisfied?.isNotMet ? (
                                        <span>Total break hours must meet the max break hours.</span>
                                    ) : (
                                        <span>Total break hours must not exceed the max break hours.</span>
                                    )
                                ) : error ? (
                                    <span>{error}</span>
                                ) : (
                                    ""
                                )}
                            </SectionCollapseError>
                            <SectionCollapseInfo title="Notice" show={!!isNextDay}>
                                <span>The selected shift spans from the current day to the next day.</span>
                            </SectionCollapseInfo>
                        </div>
                    )}
                </div>
            </div>
        </BaseUpdateModal>
    );
}

UpdateModal.propTypes = {
    open: PropTypes.bool,
    id: PropTypes.any,
    onChange: PropTypes.func,
    onFinish: PropTypes.func,
    onBack: PropTypes.func,
    onClose: PropTypes.func,
    readOnly: PropTypes.bool,
    fromHoliday: PropTypes.bool,
    setting: PropTypes.object,
    transparentOverlay: PropTypes.bool
};

export default UpdateModal;
