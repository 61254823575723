import React from "react";
import PropTypes from "prop-types";
import InfoIcon from "@mui/icons-material/Info";
import SectionCollapse from "./SectionCollapse";

const styles = {
    icon: {},
    header: { fontWeight: "600" },
    parent: { width: "100%", backgroundColor: "#E1EDFF" }
};

const SectionCollapseInfo = ({ title = "Instructions:", show, children, collapse = true, onCollapse, alwaysOpen, style }) => {
    if (!show) {
        return <></>;
    }

    return (
        <SectionCollapse
            header={
                <div className="flex center gap-05">
                    <InfoIcon className="primary-color" style={styles.icon} /> {title}
                </div>
            }
            styles={{
                header: styles.header,
                parent: { ...styles.parent, ...(style || {}) }
            }}
            collapse={collapse}
            onCollapse={onCollapse}
            alwaysOpen={alwaysOpen}
        >
            <div className="fade">{children}</div>
        </SectionCollapse>
    );
};

SectionCollapseInfo.propTypes = {
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
    collapse: PropTypes.bool,
    show: PropTypes.bool,
    alwaysOpen: PropTypes.bool,
    onCollapse: PropTypes.func,
    style: PropTypes.object
};

export default SectionCollapseInfo;
