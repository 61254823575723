import React from "react";
import PropTypes from "prop-types";
import Field from "./Field";
import { sanitizeWords } from "../../../common/utilities/helper";
import Loader from "../../../common/components/extra/Loader";
import Empty from "../../../common/components/extra/Empty";

function ControlFields({ fields, isLoading }) {
    const toProperClassName = (type) => type && type.toLowerCase().split("_").join("-");
    const entries = Object.entries(fields);
    const isEmpty = !entries.length;
    return (
        <div className="form-control-fields">
            {isLoading ? (
                <div className="flex gap-05 center" style={{ marginTop: "1rem" }}>
                    <Loader style={{ width: "3rem" }} relative />
                    <span className="fade">Fetching Elements...</span>
                </div>
            ) : !isEmpty ? (
                entries.map(([group, rows]) => (
                    <section key={group} className="form-control-fields__group">
                        <span className="fade">{sanitizeWords(group)}</span>
                        <div className="form-control-fields__items">
                            {rows.map((row) => (
                                <Field key={row.type} className={toProperClassName(row.type)} type={row.type} data={row}>
                                    <span className="small-font bold">{sanitizeWords(row.name)}</span>
                                </Field>
                            ))}
                        </div>
                    </section>
                ))
            ) : (
                <Empty message="No Fields Available" style={{ margin: "auto", marginTop: "5rem" }} />
            )}
        </div>
    );
}

export default ControlFields;

ControlFields.propTypes = {
    fields: PropTypes.object,
    isLoading: PropTypes.bool
};
