import { useState } from "react";
import { useRefreshMutation } from "../../features/common/api";
import { useAppSelector } from "./reduxHooks";
import { selectUser } from "../../features/common/slice";

function useVerifySessionHooks() {
    const [isLoading, setLoading] = useState(true);
    const user = useAppSelector(selectUser);
    const [refresh] = useRefreshMutation();

    const verify = async () => {
        if (!user) {
            await refresh();
            setLoading(false);
        }
    };
    return [verify, isLoading];
}

export default useVerifySessionHooks;
