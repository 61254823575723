import React from "react";
import PropTypes from "prop-types";
import debounce from "lodash/debounce";
import TableList from "../../../common/components/extra/table/TableList";
import Input from "../../../common/components/extra/Input";
import ViewGeozonesModal from "../companySites/ViewGeozonesModal";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectUserSetting } from "../../common/slice";
import ViewModal from "./ViewModal";
import { useLazyEmployeeManager } from "./hooks";

const UNIQUE_KEY = "id";

export const ASSIGN_TYPE = {
    SITE: 0x1,
    WORK_SHIFT: 0x2,
    DEFAULT: 0x3
};

function EmployeesTableLazy({
    onChange,
    selected,
    readOnly,
    type = ASSIGN_TYPE.DEFAULT,
    excludeIds = [],
    withoutShift,
    onMount,
    enableUncheckedOnshift,
    workShiftId
}) {
    const setting = useAppSelector(selectUserSetting);

    const {
        unique,
        headers,
        data,
        onSort,
        sort,
        defaultChecked,
        onLoadMore,
        onCheckChange,
        isLoadingMore,
        isLoading,
        onSearch,
        object,
        updateObject
    } = useLazyEmployeeManager({
        excludeIds,
        withoutShift,
        enableUncheckedOnshift,
        uniqueKey: UNIQUE_KEY,
        onChange,
        onMount,
        selected,
        readOnly,
        type,
        workShiftId
    });

    return (
        <>
            <div className="flex column gap-1">
                <Input parentStyle={{ maxWidth: "25rem" }} onChange={debounce(onSearch, 1000)} isSearch />
                <TableList
                    headers={headers}
                    data={data}
                    onSort={onSort}
                    activeSort={sort}
                    uniqueKey={unique}
                    checked={{ id: defaultChecked }}
                    onLoadMore={onLoadMore}
                    onCheckChange={onCheckChange}
                    height={35}
                    isLoadingMore={isLoadingMore}
                    isLoading={isLoading}
                />
            </div>
            {!!object.selected && !object.viewSites && (
                <ViewModal
                    open={!!object.selected}
                    onChange={(bool) => {
                        if (!bool) {
                            updateObject({ selected: null, viewSites: false });
                        }
                    }}
                    data={object.selected || {}}
                    setting={setting}
                    cachedCurrent={false}
                />
            )}
            {object.viewSites && (
                <ViewGeozonesModal
                    open={object.viewSites}
                    onChange={(bool) => {
                        if (!bool) {
                            updateObject({ viewSites: false, selected: null });
                        }
                    }}
                    data={object.selected.CompanySites.map((cs) => cs.id) || []}
                />
            )}
        </>
    );
}

EmployeesTableLazy.propTypes = {
    open: PropTypes.bool,
    selected: PropTypes.any,
    onChange: PropTypes.func,
    onConfirm: PropTypes.func,
    onMount: PropTypes.func,
    readOnly: PropTypes.bool,
    type: PropTypes.oneOf(Object.values(ASSIGN_TYPE)),
    excludeIds: PropTypes.array,
    withoutShift: PropTypes.bool,
    enableUncheckedOnshift: PropTypes.bool,
    workShiftId: PropTypes.any
};

export default EmployeesTableLazy;
