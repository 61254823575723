import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import Tag from "../../../common/components/extra/Tag";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectUser } from "../../common/slice";
import { toReadableFromDate } from "../../../common/utilities/helper";

const HEADERS = {
    NAME: "name",
    PERMISSIONS: "permissions",
    CREATED_AT: "createdAt",
    ACTIONS: "actions"
};

const HEADERS_TO_ARRAY = Object.values(HEADERS);

const CreateTableHeaders = ({ onEdit }) => {
    const user = useAppSelector(selectUser);
    const role_id = user.role_id;
    return HEADERS_TO_ARRAY.map((head) => {
        const temp = { key: head, style: { width: `${100 / HEADERS_TO_ARRAY.length}%` } };
        switch (head) {
            case HEADERS.NAME: {
                temp.sortKey = "name";
                temp.label = "Title";
                temp.render = (val) => (
                    <div className="flex column gap-05 overflow-hidden">
                        <span className="text-ellipsis bold capitalize">{val}</span>
                    </div>
                );
                break;
            }
            case HEADERS.PERMISSIONS: {
                temp.label = "Permissions";
                temp.style.width = "50%";
                temp.render = (val) => (
                    <div className="tk-roles__permissions">
                        {val.map((x) => (
                            <Tag key={x}>{x}</Tag>
                        ))}
                    </div>
                );
                break;
            }
            case HEADERS.CREATED_AT: {
                temp.sortKey = "createdAt";
                temp.label = "Created";
                temp.style.width = "15%";
                temp.render = (val) => (
                    <div className="flex">
                        <div className="flex column gap-05 overflow-hidden fade">
                            <span className="text-ellipsis small-font">{toReadableFromDate(val)}</span>
                        </div>
                    </div>
                );
                break;
            }
            case HEADERS.ACTIONS: {
                temp.childStyle = {
                    display: "flex",
                    gap: ".5rem",
                    justifyContent: "flex-end",
                    paddingRight: "1rem"
                };
                temp.style.width = "10%";
                temp.render = (_, row) => (
                    <div style={{ display: "flex", gap: ".5rem" }}>{row.id !== role_id && <EditIcon onClick={() => onEdit(row)} />}</div>
                );
                break;
            }
            default:
                break;
        }
        return temp;
    });
};

export default CreateTableHeaders;
