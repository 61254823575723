import React from "react";
import PreviewIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import Tag from "../../../common/components/extra/Tag";
import { addCommasToMoney, createConfirmAlert, renderNA, toReadableFromDate } from "../../../common/utilities/helper";
import { RECURRENCE, TABLE_ACTION_TYPE_COMMON } from "../../../common/utilities/const";

const HEADERS = {
    TITLE: "title",
    AMOUNT: "amount",
    EMPLOYEE_LIMIT: "employee_limit",
    IS_ACTIVE: "is_active",
    RECURRENCE: "recurrence",
    CREATED_AT: "createdAt",
    ACTIONS: "actions"
};

const HEADERS_TO_ARRAY = Object.values(HEADERS);

const CreateTableHeaders = ({ onAction }) => {
    return HEADERS_TO_ARRAY.map((head) => {
        const temp = { key: head, style: { width: `${100 / HEADERS_TO_ARRAY.length}%` } };
        switch (head) {
            case HEADERS.TITLE: {
                temp.sortKey = HEADERS.TITLE;
                temp.label = "Title";
                temp.render = (val) => (
                    <div className="flex column gap-05 overflow-hidden">
                        <span className="text-ellipsis bold capitalize">{val}</span>
                    </div>
                );
                break;
            }
            case HEADERS.AMOUNT: {
                temp.sortKey = HEADERS.AMOUNT;
                temp.label = "Amount";
                temp.render = (val) => (
                    <span>
                        {addCommasToMoney(val) || 0} {renderNA("QAR")}
                    </span>
                );
                break;
            }
            case HEADERS.EMPLOYEE_LIMIT: {
                temp.sortKey = HEADERS.EMPLOYEE_LIMIT;
                temp.label = "Employee Limit";
                temp.style.width = "18%";
                temp.render = (val) => (
                    <span>
                        Max of <span className="primary-color bold">{addCommasToMoney(val)}</span> Employees
                    </span>
                );
                break;
            }
            case HEADERS.IS_ACTIVE: {
                temp.sortKey = HEADERS.IS_ACTIVE;
                temp.label = "Status";
                temp.render = (_, row) => <Tag className={(!row.is_active && "red") || ""}>{row.is_active ? "active" : "not active"}</Tag>;
                break;
            }
            case HEADERS.RECURRENCE: {
                temp.sortKey = HEADERS.RECURRENCE;
                temp.label = "Recurrence";
                temp.render = (_, row) => <Tag>{row.recurrence}</Tag>;
                break;
            }
            case HEADERS.CREATED_AT: {
                temp.sortKey = HEADERS.CREATED_AT;
                temp.label = "Created";
                temp.render = (val) => (
                    <div className="flex">
                        <div className="flex column gap-05 overflow-hidden fade">
                            <span className="text-ellipsis small-font">{toReadableFromDate(val)}</span>
                        </div>
                    </div>
                );
                break;
            }
            case HEADERS.ACTIONS: {
                temp.childStyle = {
                    display: "flex",
                    gap: ".5rem",
                    justifyContent: "flex-end",
                    paddingRight: "1rem"
                };
                temp.render = (_, row) => (
                    <div style={{ display: "flex", gap: ".5rem" }}>
                        <PreviewIcon onClick={() => typeof onAction === "function" && onAction(row, TABLE_ACTION_TYPE_COMMON.PREVIEW)} />
                        {row.recurrence !== RECURRENCE.TRIAL && (
                            <DeleteIcon
                                style={{ color: "red" }}
                                onClick={async () => {
                                    createConfirmAlert({
                                        title: "Delete Subscription",
                                        content: "Are you sure you want to delete this subscription? This cannot be undone.",
                                        onConfirm: async (close) => {
                                            close();
                                            typeof onAction === "function" && onAction(row, TABLE_ACTION_TYPE_COMMON.REMOVE);
                                        }
                                    });
                                }}
                            />
                        )}
                    </div>
                );
                break;
            }
            default:
                break;
        }
        return temp;
    });
};

export default CreateTableHeaders;
