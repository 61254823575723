import React from "react";
import PropTypes from "prop-types";

function Text({ children, className, style }) {
    return (
        <span className={`small-font ${className || ""}`} style={style}>
            {children}
        </span>
    );
}

export default Text;

Text.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    children: PropTypes.any
};
