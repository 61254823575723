import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import EditIcon from "@mui/icons-material/Edit";
import EditOffIcon from "@mui/icons-material/EditOff";
import InfoIcon from "@mui/icons-material/Info";
import { ReactComponent as CheckIcon } from "../../../assets/images/check-mark.svg";
import Select from "../../../common/components/extra/select/Select";
import Input, { INPUT_TYPE } from "../../../common/components/extra/form/Input";
import { toReadableSelectOptions } from "../../../common/utilities/helper";
import { FORM_TYPE } from "../../../common/utilities/const";
import Tag from "../../../common/components/extra/Tag";
import MyTooltip from "../../../common/components/extra/Tooltip";

const CustomInput = ({ name, label, value, onChange, isSelect, options, isLoading }) => {
    const [newValue, setNewValue] = useState("");
    const [isEdit, setEdit] = useState(!value);

    const handleChange = (name, newVal) => {
        setNewValue(newVal);
    };

    const handleCancel = () => {
        setEdit(false);
        typeof onChange == "function" && onChange(name, value);
    };

    const handleConfirm = () => {
        setEdit(false);
        typeof onChange == "function" && onChange(name, newValue);
    };

    useEffect(() => {
        if (!isLoading) {
            setEdit(!value);
        }
    }, [isLoading, value]);

    const handleKeyDown = (e) => e.key === "Enter" && handleConfirm();

    const selValue = isSelect ? options.find((opt) => (newValue ? opt.value == newValue : opt.value == value)) : value;

    return (
        <div className="flex gap-05" style={{ alignItems: "center" }}>
            <span className="fade">{label}:</span>
            {!isEdit ? (
                <span className="bold">{isSelect ? selValue?.label : selValue}</span>
            ) : isSelect ? (
                <Select
                    value={selValue || ""}
                    options={options}
                    style={{ width: "15.4rem" }}
                    onChange={(conf) => handleChange(name, conf.value)}
                    menuPlacement="bottom"
                    menuPortalTarget={document.body}
                    isLoading={isLoading}
                    onKeyDown={handleKeyDown}
                    disabledOutline
                    isOutlined
                    required
                />
            ) : (
                <Input
                    type={INPUT_TYPE.TEXT}
                    parentStyle={{ maxWidth: "1rem" }}
                    value={value || ""}
                    onChange={(e) => handleChange(name, e.target.value)}
                    isLoading={isLoading}
                    onKeyDown={handleKeyDown}
                    outlined
                    required
                />
            )}
            {!isEdit ? (
                <EditIcon className="pointer primary-color hover-svg" style={{ width: "1.2rem", opacity: ".7" }} onClick={() => setEdit(true)} />
            ) : (
                <div className="flex gap-05 center">
                    <EditOffIcon className="pointer danger-color hover-svg" style={{ width: "1.2rem" }} onClick={handleCancel} />
                    <CheckIcon className="pointer hover-svg" style={{ width: "1.2rem", color: "green", marginTop: "2px" }} onClick={handleConfirm} />
                </div>
            )}
        </div>
    );
};

function FormHeader({ name, type, status, onChange, isLoading }) {
    const TYPE_OPT = toReadableSelectOptions(FORM_TYPE).map((item) => ({
        ...item,
        label: <Tag key={item.value}>{item.label}</Tag>
    }));

    return (
        <div className="tk-company-forms-upsert__header">
            <div className="group flex gap-1">
                <div className="left flex column gap-05">
                    <CustomInput
                        name="name"
                        label="Name"
                        value={name}
                        onChange={(name, newval) => onChange({ [name]: newval })}
                        isLoading={isLoading}
                    />
                    <CustomInput
                        name="type"
                        label="Type"
                        value={type}
                        options={TYPE_OPT}
                        onChange={(name, newval) => onChange({ [name]: newval })}
                        isLoading={isLoading}
                        isSelect
                    />
                </div>
                <div className="right flex gap-05">
                    <div className="flex center gap-05">
                        <span className="fade center flex">
                            <span>Status:</span>
                        </span>
                        <MyTooltip className="flex center" message="Indicates if the form will be active or not.">
                            <InfoIcon className="hover-svg" style={{ width: "1rem" }} />
                        </MyTooltip>
                        <Input
                            name="status"
                            type={INPUT_TYPE.TOGGLE}
                            value={status}
                            onChange={(e) => onChange({ status: e.target.checked })}
                            isLoading={isLoading}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FormHeader;

FormHeader.propTypes = {
    name: PropTypes.string,
    type: PropTypes.string,
    status: PropTypes.bool,
    onChange: PropTypes.func,
    isLoading: PropTypes.bool
};

CustomInput.propTypes = {
    name: PropTypes.string,
    label: PropTypes.any,
    value: PropTypes.any,
    onChange: PropTypes.func,
    isSelect: PropTypes.bool,
    options: PropTypes.array,
    isLoading: PropTypes.bool
};
