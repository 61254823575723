import cloneDeep from "lodash/cloneDeep";
import { createSlice } from "@reduxjs/toolkit";
import { FILTER_ALL_VALUE, VIEW_TYPES } from "../../../common/utilities/const";
import { FILTER_TYPE, INDEXES } from "./const";

const SLICE_NAME_KEY = "employeeSalary";

export const LOAD_MORE_OFFSET = 10;
export const DEFAULT_SIZE = 20;

export const defaultFilter = {
    [FILTER_TYPE.DEPARTMENT]: FILTER_ALL_VALUE.value,
    [FILTER_TYPE.DESIGNATION]: FILTER_ALL_VALUE.value,
    [FILTER_TYPE.PAYMENT_MODE]: FILTER_ALL_VALUE.value,
    [FILTER_TYPE.NATIONALITY]: FILTER_ALL_VALUE.value
};

export const defaultConfig = {
    pageSize: DEFAULT_SIZE,
    search: "",
    sortBy: INDEXES.SYS_INDEX1.id,
    order: "ASC",
    cursor: "",
    more: LOAD_MORE_OFFSET,
    totalCount: 0,
    filter: defaultFilter,
    dbSalary: null,
    salaryBasePeriod: null
};

const initialState = {
    data: [],
    tableConfig: defaultConfig,
    date: "",
    isPreview: false,
    error: null,
    current: null,
    loading: false,
    isSearching: false,
    view: VIEW_TYPES.TABLE
};

export const employeeSalarySlice = createSlice({
    name: SLICE_NAME_KEY,
    initialState,
    reducers: {
        setError: (state, action) => {
            const clone = cloneDeep(state);
            clone.error = action.payload;
            return clone;
        },
        setDate: (state, action) => {
            const clone = cloneDeep(state);
            clone.date = action.payload;
            return clone;
        },
        setPreview: (state, action) => {
            const clone = cloneDeep(state);
            clone.isPreview = !!action.payload;
            return clone;
        },
        setFilter: (state, action) => {
            const clone = cloneDeep(state);
            const { key, value, newObject } = action.payload || {};
            if (newObject) {
                clone.tableConfig.filter = newObject;
            } else {
                key && (clone.tableConfig.filter[key] = value);
            }
            return clone;
        },
        resetFilter: (state) => {
            const clone = cloneDeep(state);
            clone.tableConfig.filter = defaultFilter;
            return clone;
        },
        setSearching: (state, action) => {
            const clone = cloneDeep(state);
            clone.isSearching = action.payload;
            return clone;
        },
        setClearCache: (state) => {
            let clone = cloneDeep(state);
            clone.data = [];
            clone.current = null;
            clone.loading = false;
            clone.tableConfig = defaultConfig;
            return clone;
        },
        setEmployeeSalaryData: (state, action) => {
            let clone = cloneDeep(state);
            const data = action.payload;
            Array.isArray(data) && (clone.data = data || []);
            return clone;
        },
        updateEmployeeSalaryData: (state, action) => {
            let clone = cloneDeep(state);
            const updateId = action.payload.id;
            const newdata = action.payload.data || {};
            updateId && (clone.data = clone.data.map((d) => (d.id == updateId ? { ...d, ...newdata } : d)));
            return clone;
        },
        setTableConfig: (state, action) => {
            let clone = cloneDeep(state);
            const tableConfig = action.payload;
            tableConfig && (clone.tableConfig = { ...state.tableConfig, ...tableConfig });
            return clone;
        },
        setLoading: (state, action) => {
            let clone = cloneDeep(state);
            const loading = action.payload;
            typeof loading === "boolean" && loading !== state.loading && (clone.loading = loading);
            return clone;
        },
        setView: (state, action) => {
            let clone = cloneDeep(state);
            const view = action.payload;
            view && view !== state.view && (clone.view = view);
            return clone;
        },
        setCurrent: (state, action) => {
            let clone = cloneDeep(state);
            const current = action.payload;
            clone.current = current;
            return clone;
        },
        setState: (state, action) => {
            let clone = cloneDeep(state);
            const { tableConfig, view, data, current, loading } = action.payload;
            tableConfig && (clone.tableConfig = { ...state.tableConfig, ...tableConfig });
            data && (clone.data = data || []);
            view && view !== state.view && (clone.view = view);
            typeof loading === "boolean" && loading !== state.loading && (clone.loading = loading);
            current && (clone.current = current);
            return clone;
        },
        reset: () => initialState
    }
});

export const selectError = (state) => state[SLICE_NAME_KEY].error;
export const selectDate = (state) => state[SLICE_NAME_KEY].date;
export const selectPreview = (state) => state[SLICE_NAME_KEY].isPreview;
export const selectFilter = (state) => state[SLICE_NAME_KEY].tableConfig.filter;
export const selectSearching = (state) => state[SLICE_NAME_KEY].isSearching;
export const selectEmployeeSalaryData = (state) => state[SLICE_NAME_KEY].data;
export const selectTableConfig = (state) => state[SLICE_NAME_KEY].tableConfig;
export const selectLoading = (state) => state[SLICE_NAME_KEY].loading;
export const selectView = (state) => state[SLICE_NAME_KEY].view;
export const selectCurrent = (state) => state[SLICE_NAME_KEY].current;
export const selectState = (state) => state[SLICE_NAME_KEY];
export const {
    setError,
    setPreview,
    setDate,
    setFilter,
    resetFilter,
    updateEmployeeSalaryData,
    setSearching,
    setClearCache,
    setEmployeeSalaryData,
    setTableConfig,
    setLoading,
    setView,
    setCurrent,
    setState,
    reset
} = employeeSalarySlice.actions;
export default employeeSalarySlice.reducer;
