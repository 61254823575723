import React from "react";
import { FORM_FIELD_TYPE } from "../../../common/utilities/const";

import TitleIcon from "@mui/icons-material/Title";
import ParagraphIcon from "@mui/icons-material/Notes";
import AddressIcon from "@mui/icons-material/Place";
import PhoneIcon from "@mui/icons-material/ContactPhone";
import NumbersIcon from "@mui/icons-material/Numbers";
import TextInputIcon from "@mui/icons-material/TextFields";
import EmailIcon from "@mui/icons-material/Email";
import DropdownIcon from "@mui/icons-material/ArrowDropDownCircle";
import RadioIcon from "@mui/icons-material/RadioButtonChecked";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DateIcon from "@mui/icons-material/Today";
import TimeIcon from "@mui/icons-material/Schedule";
import { ReactComponent as DateTimeIcon } from "../../../assets/images/date-time-icon.svg";
import { ReactComponent as ImageUploadIcon } from "../../../assets/images/image-upload-icon.svg";
import TextAreaIcon from "@mui/icons-material/Rtt";
import FileUploadIcon from "@mui/icons-material/UploadFile";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";

const {
    PARAGRAPH,
    LABEL,
    TEXT_INPUT,
    TEXT_AREA,
    EMAIL,
    NUMBER_INPUT,
    DROPDOWN,
    RADIO,
    CHECKBOX,
    DATE,
    TIME,
    DATE_TIME,
    FILE_UPLOAD,
    IMAGE_UPLOAD,
    PHONE_NUMBER,
    ADDRESS,
    TOGGLE
} = FORM_FIELD_TYPE;

export const getFieldIcon = (type) => {
    switch (type) {
        case PARAGRAPH:
            return <ParagraphIcon />;
        case LABEL:
            return <TitleIcon />;
        case TEXT_INPUT:
            return <TextInputIcon />;
        case TEXT_AREA:
            return <TextAreaIcon />;
        case EMAIL:
            return <EmailIcon />;
        case NUMBER_INPUT:
            return <NumbersIcon />;
        case DROPDOWN:
            return <DropdownIcon />;
        case RADIO:
            return <RadioIcon />;
        case CHECKBOX:
            return <CheckBoxIcon />;
        case DATE:
            return <DateIcon />;
        case TIME:
            return <TimeIcon />;
        case DATE_TIME:
            return <DateTimeIcon style={{ width: "1.5rem" }} />;
        case FILE_UPLOAD:
            return <FileUploadIcon />;
        case IMAGE_UPLOAD:
            return <ImageUploadIcon style={{ width: "1.5rem" }} />;
        case PHONE_NUMBER:
            return <PhoneIcon />;
        case ADDRESS:
            return <AddressIcon />;
        case TOGGLE:
            return <ToggleOnIcon />;
        default:
            break;
    }
};

export const renderInfoText = (type) => {
    const temp = {
        required: "Indicates that the selected element must have a value.",
        min: null,
        max: null,
        text: null,
        longText: null,
        label: "A label for input.",
        array: null,
        boolean: null
    };

    switch (type) {
        case PARAGRAPH:
            temp.longText = "An additional description to show in the form.";
            temp.label = null;
            temp.required = null;
            break;
        case LABEL:
            temp.text = "An additional text to show in the form.";
            temp.label = null;
            temp.required = null;
            break;
        case ADDRESS:
        case TEXT_AREA:
        case TEXT_INPUT: {
            temp.min = "Indicates the minimum number of characters required.";
            temp.max = "Indicates the maximum number of characters allowed.";
            break;
        }
        case NUMBER_INPUT: {
            temp.min = "Defines the minimum acceptable number.";
            temp.max = "Defines the maximum acceptable number.";
            break;
        }
        case IMAGE_UPLOAD:
        case FILE_UPLOAD: {
            temp.min = "Indicates the minimum number of files that can be uploaded.";
            temp.max = "Indicates the maximum number of files that can be uploaded.";
            break;
        }
        case DROPDOWN:
        case RADIO:
        case CHECKBOX:
            temp.array = "Indicates the values of the element.";
            break;
        case TOGGLE:
            temp.boolean = "Indicates the value of the toggled element";
            break;
        default:
        case PHONE_NUMBER:
        case EMAIL:
            temp.min = null;
            temp.max = null;
            break;
    }
    return temp;
};

export const getDraggedItem = (type) => {
    switch (type) {
        case PARAGRAPH:
            return <ParagraphIcon />;
        case LABEL:
            return <TitleIcon />;
        case TEXT_INPUT:
            return <TextInputIcon />;
        case TEXT_AREA:
            return <TextAreaIcon />;
        case EMAIL:
            return <EmailIcon />;
        case NUMBER_INPUT:
            return <NumbersIcon />;
        case DROPDOWN:
            return <DropdownIcon />;
        case RADIO:
            return <RadioIcon />;
        case CHECKBOX:
            return <CheckBoxIcon />;
        case DATE:
            return <DateIcon />;
        case TIME:
            return <TimeIcon />;
        case DATE_TIME:
            return <DateTimeIcon style={{ width: "1.5rem" }} />;
        case FILE_UPLOAD:
            return <FileUploadIcon />;
        case IMAGE_UPLOAD:
            return <ImageUploadIcon style={{ width: "1.5rem" }} />;
        case PHONE_NUMBER:
            return <PhoneIcon />;
        case ADDRESS:
            return <AddressIcon />;
        case TOGGLE:
            return <ToggleOnIcon />;
        default:
            break;
    }
};
