export const FIELD = {
    STATUS: "name"
};

export const FIELD_OBJECT = {
    [FIELD.STATUS]: {
        label: "Status",
        default: "",
        required: true
    }
};

export const SUBMITTED_FORM_CLASS = {
    NORMAL: "NORMAL",
    WORK_HISTORY: "WORK_HISTORY"
};

export const SUBMITTED_FORM_TYPE = {
    CUSTOM: "CUSTOM",
    WH_OVERTIME: "WH_OVERTIME",
    WH_ABSENT: "WH_ABSENT",
    WH_TIMING: "WH_TIMING"
};

export const SUBMITTED_FORM_STATUS = {
    PENDING: "PENDING",
    APPROVED: "APPROVED",
    REJECTED: "REJECTED"
};

export const FILTER_TYPE = {
    FORM_TYPE: 0x1,
    FORM_STATUS: 0x2,
    DATE: 0x3,
    EMPLOYEE: 0x4
};
