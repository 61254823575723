import React, { useState } from "react";
import { toast } from "react-toastify";
import PersonIcon from "@mui/icons-material/Person";
import LockIcon from "@mui/icons-material/Lock";
import useNavigate from "../../../common/hooks/useNavigate";
import { useLoginMutation } from "./superAdminAPI";
import { setUser } from "../../common/slice";
import { useAppDispatch } from "../../../common/hooks/reduxHooks";
import Input, { INPUT_TYPE } from "../../../common/components/extra/form/Input";
import Navigation from "../../../common/classes/Navigation";
import Button from "../../../common/components/extra/Button";

const smallIconStyle = {
    fontSize: "1.2rem",
    color: "#768192",
    padding: ".3rem .5rem"
};

function Admin() {
    const [login, { isLoading }] = useLoginMutation();
    const [form, setForm] = useState({ email: "", password: "" });
    const dispatch = useAppDispatch();
    const navigation = useNavigate();

    const handleChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value });
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const { email, password } = form;
            if (!email || !password) throw new Error("Email and Password cannot be empty!");
            const response = await login({ body: { email, password } });
            if (!response.error) {
                const { user, role, accessToken } = response.data.data;
                dispatch(setUser({ user, role, accessToken }));
                navigation(Navigation.Routes.DASHBOARD.path);
            } else throw new Error(response.error?.data?.message || "Login Failed. User not found!");
        } catch (error) {
            toast.error(error.message);
        }
    };

    return (
        <form className="tk-auth-admin__form" onSubmit={handleSubmit}>
            <Input
                type={INPUT_TYPE.EMAIL}
                icon={<PersonIcon sx={smallIconStyle} />}
                name="email"
                placeholder="Email"
                onChange={handleChange}
                value={form.email || ""}
                maxLength={80}
                autoComplete="username"
                disabled={isLoading}
                autoFocus
                required
            />
            <Input
                type={INPUT_TYPE.PASSWORD}
                icon={<LockIcon sx={smallIconStyle} />}
                name="password"
                placeholder="Password"
                onChange={handleChange}
                value={form.password || ""}
                autoComplete="username"
                disabled={isLoading}
                required
            />
            <Button options={{ type: "submit" }} className="primary with-shadow" isLoading={isLoading}>
                {isLoading ? "Logging in..." : "Login"}
            </Button>
        </form>
    );
}

export default Admin;
