import React from "react";
import PropTypes from "prop-types";
import MobileInputs from "./mobileInputs";
import { FORM_FIELD_TYPE } from "../../../common/utilities/const";

function MobileInputsPreview({ type, ...rest }) {
    const Component = MobileInputs[type];
    return (
        <div className="form-mobile-inputs">
            <Component {...rest} />
        </div>
    );
}

export default MobileInputsPreview;

MobileInputsPreview.propTypes = {
    type: PropTypes.oneOf(Object.values(FORM_FIELD_TYPE))
};
