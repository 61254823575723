import React from "react";
import Admin from "../../features/admin/auth/Admin";
import Card from "../../common/components/extra/Card";
import { ReactComponent as LogoSVG } from "../../assets/images/logo.svg";

function AuthAdminPage() {
    const renderTitle = (
        <div className="tk-auth-admin__title">
            <LogoSVG />
        </div>
    );
    return (
        <div className="tk-auth-admin">
            <div className="tk-auth-admin__inner">
                <Card title={renderTitle}>
                    <Admin />
                </Card>
            </div>
        </div>
    );
}

export default AuthAdminPage;
