import React from "react";
import SubscriptionConfirmation from "../../features/company/companySubscription/SubscriptionConfirmation";
import { Navigate, useParams } from "react-router-dom";
import { isTokenValidHex } from "../../features/company/companyPayment/helper";
import Navigation from "../../common/classes/Navigation";
import useLocalStorage from "../../common/hooks/useLocalStorage";
import { LOCAL_STORAGE_KEYS } from "../../features/company/companyPayment/const";

function SubscriptionConfirmationPage() {
    const params = useParams();
    const token = params.token;

    const [payment] = useLocalStorage(LOCAL_STORAGE_KEYS.PAYMENT.key);

    if (!token || !isTokenValidHex(token) || !payment.key || !payment.expires || payment.key != token) {
        return <Navigate to={Navigation.Routes.DASHBOARD.path} />;
    }

    return <SubscriptionConfirmation />;
}

export default SubscriptionConfirmationPage;
