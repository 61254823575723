import React from "react";
import PropTypes from "prop-types";
import WarningIcon from "@mui/icons-material/Warning";

function VerifyErrorPayment({ message }) {
    return (
        <div className="tk-verify-error flex column gap-1 center w100">
            <div className="flex column gap-1 center">
                <WarningIcon className="warning" />
                <h3>Failed to Verify Payment</h3>
                <p style={{ fontWeight: "500" }}>{message}</p>
            </div>
        </div>
    );
}

export default VerifyErrorPayment;

VerifyErrorPayment.propTypes = {
    message: PropTypes.string
};
