import React from "react";
import PropTypes from "prop-types";
import StepLine from "./StepLine";
import { STEP_TYPE } from "./const";

function Steps({ items = [], currentIndex, total, styles = {}, className, noFirstLine }) {
    if (!items.length) return <></>;
    return (
        <div className={`steps ${className || ""}`.trim()} style={styles.parent}>
            {!noFirstLine && (
                <div className="steps__item" style={{ width: "25%", ...styles.item }}>
                    <div className="steps__item__line">
                        <div className="loading" style={{ width: "100%", borderTop: "6px solid #0052CC" }}></div>
                        <div className="placeholder"></div>
                    </div>
                </div>
            )}
            {items.map((step, idx) => (
                <StepLine
                    key={idx}
                    step={step}
                    type={
                        step.error
                            ? STEP_TYPE.ERROR
                            : total - 1 === idx && currentIndex === idx
                              ? STEP_TYPE.COMPLETED
                              : currentIndex > idx
                                ? STEP_TYPE.CHECK
                                : STEP_TYPE.NORMAL
                    }
                    active={currentIndex === idx}
                    hasNext={idx < total - 1}
                    loading={step.loading}
                    styles={{ item: { ...styles.step, width: idx + 1 === items.length ? "auto" : 100 / total + "%" } }}
                />
            ))}
        </div>
    );
}

export default Steps;

Steps.propTypes = {
    className: PropTypes.string,
    styles: PropTypes.shape({
        parent: PropTypes.object,
        item: PropTypes.object,
        step: PropTypes.object
    }),
    items: PropTypes.arrayOf(
        PropTypes.shape({
            error: PropTypes.bool,
            loading: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
            stepNumber: PropTypes.number,
            name: PropTypes.string
        })
    ),
    currentIndex: PropTypes.number,
    total: PropTypes.number,
    noFirstLine: PropTypes.bool
};
