import React, { useState } from "react";
import PropTypes from "prop-types";
import Select from "../../../common/components/extra/select/Select";
import { sanitizeWords, toFilterOptions } from "../../../common/utilities/helper";
import { DATE_RANGE } from "../../../common/utilities/const";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import DateFilter from "../../../common/components/extra/filter/DateFilter";
import { FILTER_TYPE, PENALTY_DEDUCTION_TYPE, PENALTY_STATUS } from "./const";
import { defaultFilter, resetFilter, selectFilter, setFilter } from "./slice";
import EmployeeSelectLazy from "../employees/EmployeeSelectLazy";
import useFilterManager from "../../../common/hooks/useFilterManager";
import CompanyPenaltiesSelectLazy from "../companyPenalties/CompanyPenaltiesSelectLazy";
import FilterControl from "../../../common/components/extra/filter/FilterControl";

const COMMON_PROPS = {
    style: { minWidth: "12rem", height: "2rem", maxWidth: "12rem" },
    styles: { control: { borderRadius: "25rem" } },
    menuPortalTarget: document.body,
    isClearable: true
};

function Filter({ onFilter, isLoading }) {
    const [filterConfig] = useState({
        [FILTER_TYPE.STATUS]: {
            options: toFilterOptions(PENALTY_STATUS).map((opt) => ({
                value: opt.value,
                label: <span className="bold small-font">{sanitizeWords(!opt.value ? "All Status" : opt.label)}</span>
            }))
        },
        [FILTER_TYPE.TYPE]: {
            options: toFilterOptions(PENALTY_DEDUCTION_TYPE).map((opt) => ({
                value: opt.value,
                label: <span className="bold small-font">{sanitizeWords(!opt.value ? "All Deduction Type" : opt.label)}</span>
            }))
        }
    });

    const recordFilter = useAppSelector(selectFilter) || {};

    const [filter, { isClearDisabled, isFilterDisabled, getValueFromOpt, handleChange, handleReset, handleCustomDateChange, handleFilter }] =
        useFilterManager({ onFilter, filterConfig, defaultFilter, filter: recordFilter, isLoading, setFilter, resetFilter });

    return (
        <div className="tk-filter flex gap-05 w100" style={{ alignItems: "center" }}>
            <div className="flex gap-05 wrap" style={{ alignItems: "center" }}>
                <EmployeeSelectLazy
                    {...COMMON_PROPS}
                    value={{ value: filter[FILTER_TYPE.EMPLOYEE], id: filter[FILTER_TYPE.EMPLOYEE] }}
                    onChange={(conf) => handleChange(FILTER_TYPE.EMPLOYEE, conf?.id || "")}
                    parentStyle={{ ...COMMON_PROPS.style }}
                    placeholder="Employee"
                    isClearable={!!filter[FILTER_TYPE.EMPLOYEE]}
                    isDisabled={isLoading}
                    isFilter
                    allowOnShift
                />
                <CompanyPenaltiesSelectLazy
                    {...COMMON_PROPS}
                    value={{ value: filter[FILTER_TYPE.COMPANY_PENALTY], id: filter[FILTER_TYPE.COMPANY_PENALTY] }}
                    onChange={(conf) => handleChange(FILTER_TYPE.COMPANY_PENALTY, conf?.id || "")}
                    parentStyle={{ ...COMMON_PROPS.style }}
                    placeholder="Employee"
                    isClearable={!!filter[FILTER_TYPE.COMPANY_PENALTY]}
                    isDisabled={isLoading}
                    isFilter
                    allowOnShift
                />
                <Select
                    {...COMMON_PROPS}
                    options={filterConfig[FILTER_TYPE.STATUS].options}
                    value={getValueFromOpt(FILTER_TYPE.STATUS, filter[FILTER_TYPE.STATUS])}
                    onChange={(conf) => handleChange(FILTER_TYPE.STATUS, conf.value)}
                    placeholder="Status"
                    isClearable={!!filter[FILTER_TYPE.STATUS]}
                    isDisabled={isLoading}
                />
                <Select
                    {...COMMON_PROPS}
                    options={filterConfig[FILTER_TYPE.TYPE].options}
                    value={getValueFromOpt(FILTER_TYPE.TYPE, filter[FILTER_TYPE.TYPE])}
                    onChange={(conf) => handleChange(FILTER_TYPE.TYPE, conf.value)}
                    placeholder="Type"
                    isClearable={!!filter[FILTER_TYPE.TYPE]}
                    isDisabled={isLoading}
                />
                <DateFilter
                    filterProps={{
                        ...COMMON_PROPS,
                        value: filter[FILTER_TYPE.EFFECTIVE_DATE]?.type,
                        onChange: (newvalue) => handleCustomDateChange(DATE_RANGE.TYPE, newvalue, FILTER_TYPE.EFFECTIVE_DATE),
                        isDisabled: isLoading
                    }}
                    dateProps={{
                        isMonthYear: true,
                        onChange: (name, value) => handleCustomDateChange(name, value, FILTER_TYPE.EFFECTIVE_DATE),
                        value: filter[FILTER_TYPE.EFFECTIVE_DATE],
                        styles: {
                            date: {
                                ...COMMON_PROPS.style,
                                width: COMMON_PROPS.style.minWidth,
                                borderRadius: COMMON_PROPS.styles.control.borderRadius
                            }
                        }
                    }}
                    label="Effective Date"
                />
                <DateFilter
                    filterProps={{
                        ...COMMON_PROPS,
                        value: filter[FILTER_TYPE.OCCURENCE_DATE]?.type,
                        onChange: (newvalue) => handleCustomDateChange(DATE_RANGE.TYPE, newvalue, FILTER_TYPE.OCCURENCE_DATE),
                        isDisabled: isLoading
                    }}
                    dateProps={{
                        isMonthYear: false,
                        onChange: (name, value) => handleCustomDateChange(name, value, FILTER_TYPE.OCCURENCE_DATE),
                        value: filter[FILTER_TYPE.OCCURENCE_DATE],
                        styles: {
                            date: {
                                ...COMMON_PROPS.style,
                                width: COMMON_PROPS.style.minWidth,
                                borderRadius: COMMON_PROPS.styles.control.borderRadius
                            }
                        }
                    }}
                    label="Occurence Date"
                />
                <DateFilter
                    filterProps={{
                        ...COMMON_PROPS,
                        value: filter[FILTER_TYPE.CONFIRMED_DATE]?.type,
                        onChange: (newvalue) => handleCustomDateChange(DATE_RANGE.TYPE, newvalue, FILTER_TYPE.CONFIRMED_DATE),
                        isDisabled: isLoading
                    }}
                    dateProps={{
                        onChange: (name, value) => handleCustomDateChange(name, value, FILTER_TYPE.CONFIRMED_DATE),
                        value: filter[FILTER_TYPE.CONFIRMED_DATE],
                        styles: {
                            date: {
                                ...COMMON_PROPS.style,
                                width: COMMON_PROPS.style.minWidth,
                                borderRadius: COMMON_PROPS.styles.control.borderRadius
                            }
                        }
                    }}
                    label="Confirmed Date"
                />
            </div>
            <FilterControl
                isClearDisabled={isClearDisabled}
                isFilterDisabled={isFilterDisabled}
                onReset={handleReset}
                onFilter={handleFilter}
                isLoading={isLoading}
            />
        </div>
    );
}

export default Filter;

Filter.propTypes = {
    onFilter: PropTypes.func,
    isLoading: PropTypes.bool
};
