import React, { useState } from "react";
import PropTypes from "prop-types";
import { createClass, createConfirmAlert, createGroup, toTimeWithTimeZone } from "../../../common/utilities/helper";
import BaseUpdateModal from "../../../common/components/layout/modalViewUpdateLayout/BaseUpdateModal";
import { useUpsertCompanyHolidays } from "./hooks";
import { BASE_CLASS, COMPANY_HOLIDAY_LEVEL, COMPANY_HOLIDAY_TYPE, FIELD } from "./const";
import Input, { INPUT_TYPE } from "../../../common/components/extra/form/Input";
import Select from "../../../common/components/extra/select/Select";
import WorkShiftSelectLazy from "../employeeWorkShift/WorkShiftSelectLazy";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectUserSetting } from "../../common/slice";
import SiteSelectLazy from "../companySites/SiteSelectLazy";
import WorkDetailSelectLazy from "../employeeWorkDetails/WorkDetailSelectLazy";
import SectionCollapseError from "../../../common/components/extra/section/SectionCollapseError";
import { NOTES_MAX_LEN } from "../../../common/utilities/const";

function UpdateModal({ open, onClose, onBack, onFinish, id }) {
    const isCreate = !id;

    const [form, updateForm, { upsert, hasChanges, isGettingHoliday, isUpserting, config }] = useUpsertCompanyHolidays(id);
    const [error, setError] = useState({ [FIELD.END_DATE]: null, all: null });

    const disableSave = isGettingHoliday || (!isCreate && !hasChanges) || isUpserting;

    const setting = useAppSelector(selectUserSetting);
    const timezone = setting.timezone;
    const isChangeTiming = form.type == COMPANY_HOLIDAY_TYPE.CHANGE_TIMING;
    const isCompanyWide = form.level == COMPANY_HOLIDAY_LEVEL.COMPANY_WIDE;

    const handleSave = async () => {
        const result = await upsert();
        if (!result.error) {
            if (error.all) {
                setError({ ...error, all: null });
            }
            typeof onFinish === "function" && onFinish(result);
        }
        if (result.error) {
            setError({ ...error, all: result.error.message });
        }
        return result.error;
    };

    const handleFormChange = ({ name, value } = {}) => {
        const temp = { [name]: value };
        switch (name) {
            case FIELD.START_DATE: {
                const start = toTimeWithTimeZone(value, timezone);
                const end = toTimeWithTimeZone(form[FIELD.END_DATE], timezone);
                if (end && start && start.isSameOrAfter(end)) {
                    temp[FIELD.END_DATE] = "";
                    setError({
                        ...error,
                        [FIELD.END_DATE]: "The end date has been reset. The start date cannot exceed the end date."
                    });
                }
                break;
            }
            case FIELD.END_DATE: {
                const start = toTimeWithTimeZone(form[FIELD.START_DATE], timezone);
                const end = toTimeWithTimeZone(value, timezone);
                if (start && end && end.isSame(start)) {
                    temp[FIELD.END_DATE] = ""; // just reset to show the placeholder
                }
                if (error[FIELD.END_DATE]) {
                    setError({ ...error, [FIELD.END_DATE]: null });
                }
                break;
            }
            default:
                if (error[FIELD.END_DATE]) {
                    setError({ ...error, [FIELD.END_DATE]: null });
                }
                break;
        }
        updateForm(temp);
    };

    const renderApplyTo = () => {
        switch (form.level) {
            case COMPANY_HOLIDAY_LEVEL.BY_WORK_SHIFT:
                return (
                    <WorkShiftSelectLazy
                        label="Apply To"
                        value={form[FIELD.WORK_SHIFTS]}
                        onChange={(val) => handleFormChange({ name: FIELD.WORK_SHIFTS, value: val.map((v) => ({ id: v.id, value: v.id })) })}
                        required
                        forHoliday
                        isMulti
                        isOutlined
                        isClearable
                        disabledOutline
                    />
                );
            case COMPANY_HOLIDAY_LEVEL.BY_WORK_SITE:
                return (
                    <SiteSelectLazy
                        label="Apply To"
                        value={form[FIELD.WORK_SITES]}
                        onChange={(val) => handleFormChange({ name: FIELD.WORK_SITES, value: val.map((v) => ({ id: v.id, value: v.id })) })}
                        required
                        isMulti
                        isOutlined
                        isClearable
                        disabledOutline
                    />
                );
            case COMPANY_HOLIDAY_LEVEL.BY_WORK_TYPE:
                return (
                    <WorkDetailSelectLazy
                        label="Apply To"
                        value={form[FIELD.WORK_TYPES]}
                        onChange={(val) => handleFormChange({ name: FIELD.WORK_TYPES, value: val.map((v) => ({ id: v.id, value: v.id })) })}
                        allowInUse
                        required
                        isMulti
                        isOutlined
                        isClearable
                        disabledOutline
                    />
                );
            default:
                return;
        }
    };

    return (
        <BaseUpdateModal
            open={open}
            onClose={onClose}
            onBack={(!isCreate && onBack) || null}
            onSave={(e) =>
                createConfirmAlert({
                    title: !isCreate ? "Update Record" : "Create Record",
                    content: `Are you sure you want to ${isCreate ? "create" : "update"} this record? This cannot be undone.`,
                    onConfirm: async (close) => {
                        close();
                        const hasError = await handleSave(e);
                        if (!hasError) {
                            onBack();
                        }
                    }
                })
            }
            disableSave={disableSave}
            isLoading={isUpserting}
            isForm
        >
            {createGroup({
                base: createClass("__modal-content-update", BASE_CLASS),
                title: `${isCreate ? "Create" : "Update"} Holiday`,
                body: (
                    <div className="flex column gap-05">
                        <div className="flex gap-1 wrap">
                            <div className="flex column gap-05" style={{ flex: 1 }}>
                                <Input
                                    name={FIELD.NAME}
                                    value={form[FIELD.NAME]}
                                    label="Name"
                                    type={INPUT_TYPE.TEXT}
                                    onChange={(e) => handleFormChange({ name: FIELD.NAME, value: e.target.value })}
                                    required
                                />
                                <Select
                                    label="Category"
                                    value={config[FIELD.CATEGORY]}
                                    options={config.categoryOpt}
                                    onChange={(target) => handleFormChange({ name: FIELD.CATEGORY, value: target.value })}
                                    required
                                    isOutlined
                                    disabledOutline
                                />
                                <Select
                                    label="Type"
                                    value={config[FIELD.TYPE]}
                                    options={config.typeOpt}
                                    onChange={(target) => handleFormChange({ name: FIELD.TYPE, value: target.value })}
                                    required
                                    isOutlined
                                    disabledOutline
                                />
                                {isChangeTiming && (
                                    <WorkShiftSelectLazy
                                        label="Shift to Use"
                                        name={FIELD.WORK_SHIFT}
                                        onChange={(val) => handleFormChange({ name: FIELD.WORK_SHIFT, value: { id: val.id, value: val.id } })}
                                        value={form[FIELD.WORK_SHIFT]}
                                        timezone={timezone}
                                        startFrom={form[FIELD.WORK_SHIFT]?.title}
                                        isOutlined
                                        isClearable
                                        onlyHolidays
                                        disabledOutline
                                    />
                                )}
                                <Select
                                    label="Level"
                                    value={config[FIELD.LEVEL]}
                                    options={config.levelOpt}
                                    onChange={(target) => handleFormChange({ name: FIELD.LEVEL, value: target.value })}
                                    required
                                    isOutlined
                                    disabledOutline
                                />
                            </div>
                            <div className="flex column gap-05" style={{ flex: 1 }}>
                                <Input
                                    name={FIELD.START_DATE}
                                    label={<span style={{ whiteSpace: "nowrap" }}>Start Date</span>}
                                    onChange={(date) => handleFormChange({ name: FIELD.START_DATE, value: date })}
                                    type={INPUT_TYPE.DATE}
                                    selected={form[FIELD.START_DATE]}
                                    startDate={form[FIELD.START_DATE]}
                                    endDate={form[FIELD.END_DATE]}
                                    selectsStart
                                    required
                                />
                                <Input
                                    name={FIELD.END_DATE}
                                    label={<span style={{ whiteSpace: "nowrap" }}>End Date</span>}
                                    onChange={(date) => handleFormChange({ name: FIELD.END_DATE, value: date })}
                                    placeholder="Whole Day"
                                    type={INPUT_TYPE.DATE}
                                    selected={form[FIELD.END_DATE]}
                                    startDate={form[FIELD.START_DATE]}
                                    endDate={form[FIELD.END_DATE]}
                                    minDate={form[FIELD.START_DATE]}
                                    selectsEnd
                                />
                                {error[FIELD.END_DATE] && (
                                    <div className="span small-font bold" style={{ marginLeft: ".5rem" }}>
                                        Warning: {error[FIELD.END_DATE]}
                                    </div>
                                )}
                                <Input
                                    name={FIELD.IS_YEARLY}
                                    label="Repeat every year?"
                                    type={INPUT_TYPE.CHECKBOX}
                                    value={form[FIELD.IS_YEARLY]}
                                    onChange={(e) => handleFormChange({ name: FIELD.IS_YEARLY, value: e.target.checked })}
                                />
                                <span className="fade small-font" style={{ marginLeft: ".5rem" }}>
                                    Note: Repeats the same Month and Date
                                </span>
                            </div>
                        </div>
                        {!isCompanyWide && <div style={{ marginTop: "1rem" }}>{renderApplyTo()}</div>}
                        <div className="flex column" style={{ marginTop: "1rem" }}>
                            <Input
                                name={FIELD.NOTES}
                                label="Notes/Description"
                                type={INPUT_TYPE.TEXTAREA}
                                value={form[FIELD.NOTES]}
                                parentStyle={{ height: "10rem", minHeight: "5rem" }}
                                onChange={(e) => handleFormChange({ name: FIELD.NOTES, value: e.target.value })}
                                maxLength={NOTES_MAX_LEN}
                            />
                        </div>
                        <SectionCollapseError show={!!error.all}>{error.all}</SectionCollapseError>
                    </div>
                )
            })}
        </BaseUpdateModal>
    );
}

UpdateModal.propTypes = {
    photo: PropTypes.string,
    open: PropTypes.bool,
    id: PropTypes.any,
    onClose: PropTypes.func,
    onFinish: PropTypes.func,
    onBack: PropTypes.func
};

export default UpdateModal;
