import cloneDeep from "lodash/cloneDeep";
import { createSlice } from "@reduxjs/toolkit";

const SLICE_NAME_KEY = "companyPayments";

export const LOAD_MORE_OFFSET = 5;
export const DEFAULT_SIZE = 15;

export const defaultConfig = {
    pageSize: DEFAULT_SIZE,
    search: "",
    sortBy: "paid_date",
    order: "DESC",
    cursor: "",
    more: LOAD_MORE_OFFSET,
    totalCount: 0
};

const initialState = {
    data: [],
    tableConfig: defaultConfig,
    current: null,
    loading: false,
    isSearching: false,
    subscriptionToUpgradeId: null
};

export const companyPaymentsSlice = createSlice({
    name: SLICE_NAME_KEY,
    initialState,
    reducers: {
        setSearching: (state, action) => {
            const clone = cloneDeep(state);
            clone.isSearching = action.payload;
            return clone;
        },
        setSubscriptionToUpgradeId(state, action) {
            const clone = cloneDeep(state);
            clone.subscriptionToUpgradeId = action.payload;
            return clone;
        },
        setCurrent(state, action) {
            const clone = cloneDeep(state);
            clone.current = action.payload;
            return clone;
        },
        setData: (state, action) => {
            const clone = cloneDeep(state);
            const data = action.payload;
            Array.isArray(data) && (clone.data = data || []);
            return clone;
        },
        setTableConfig: (state, action) => {
            const clone = cloneDeep(state);
            const tableConfig = action.payload || {};
            tableConfig && (clone.tableConfig = { ...state.tableConfig, ...tableConfig });
            return clone;
        },
        setLoading: (state, action) => {
            const clone = cloneDeep(state);
            const loading = action.payload;
            typeof loading === "boolean" && loading !== state.loading && (clone.loading = loading);
            return clone;
        },
        setState: (state, action) => {
            const clone = cloneDeep(state);
            const { tableConfig, data, loading, current } = action.payload;
            current && (clone.current = current);
            tableConfig && (clone.tableConfig = { ...state.tableConfig, ...tableConfig });
            data && (clone.data = data || []);
            typeof loading === "boolean" && loading !== state.loading && (clone.loading = loading);
            return clone;
        },
        reset: () => initialState
    }
});

export const selectSearching = (state) => state[SLICE_NAME_KEY].isSearching;
export const selectSubscriptionToUpgradeId = (state) => state[SLICE_NAME_KEY].subscriptionToUpgradeId;
export const selectCurrent = (state) => state[SLICE_NAME_KEY].current;
export const selectData = (state) => state[SLICE_NAME_KEY].data;
export const selectTableConfig = (state) => state[SLICE_NAME_KEY].tableConfig;
export const selectLoading = (state) => state[SLICE_NAME_KEY].loading;
export const selectState = (state) => state[SLICE_NAME_KEY];
export const { setSearching, setSubscriptionToUpgradeId, setCurrent, setData, setTableConfig, setLoading, setState, reset } =
    companyPaymentsSlice.actions;
export default companyPaymentsSlice.reducer;
