import { useNavigate, useLocation } from "react-router-dom";

function useNavigateHooks() {
    const location = useLocation();
    const navigate = useNavigate();

    return (path, options, raw = false) => {
        const state = {};
        if (!raw) {
            state.from = location;
        }
        navigate(path, { ...(options || {}), state });
    };
}

export default useNavigateHooks;
