import React, { useState } from "react";
import PropTypes from "prop-types";
import Select from "../../../common/components/extra/select/Select";
import { sanitizeWords, toFilterOptions } from "../../../common/utilities/helper";
import { COUNTRIES, FILTER_ALL_VALUE } from "../../../common/utilities/const";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { FILTER_TYPE, PAYMENT_MODE } from "./const";
import { defaultFilter, resetFilter, selectFilter, setFilter } from "./slice";
import useFilterManager from "../../../common/hooks/useFilterManager";
import FilterControl from "../../../common/components/extra/filter/FilterControl";
import DepartmentsSelectLazy from "../departments/DepartmentsSelectLazy";
import DesignationsSelectLazy from "../designations/DesignationsSelectLazy";

const COMMON_PROPS = {
    style: { minWidth: "12rem", height: "2rem", maxWidth: "12rem" },
    styles: { control: { borderRadius: "25rem" } },
    menuPortalTarget: document.body,
    isClearable: true
};

function Filter({ onFilter, isLoading }) {
    const [filterConfig] = useState({
        [FILTER_TYPE.NATIONALITY]: {
            options: COUNTRIES.map((opt) => ({
                value: opt.cca2,
                label: <span className="bold small-font">{sanitizeWords(opt.demonyms?.eng?.m) || opt.cca2}</span>
            })).concat({ value: FILTER_ALL_VALUE.value, label: <span className="bold small-font">All Nationality</span> })
        },
        [FILTER_TYPE.PAYMENT_MODE]: {
            options: toFilterOptions(PAYMENT_MODE).map((opt) => ({
                value: opt.value,
                label: <span className="bold small-font">{sanitizeWords(!opt.value ? "All Payment Mode" : opt.label)}</span>
            }))
        }
    });

    const recordFilter = useAppSelector(selectFilter) || {};

    const [filter, { isClearDisabled, isFilterDisabled, getValueFromOpt, handleChange, handleReset, handleFilter }] = useFilterManager({
        onFilter,
        filterConfig,
        defaultFilter,
        filter: recordFilter,
        isLoading,
        setFilter,
        resetFilter
    });

    return (
        <div className="tk-filter flex gap-05 w100" style={{ alignItems: "center" }}>
            <div className="flex gap-05 wrap" style={{ alignItems: "center" }}>
                <DepartmentsSelectLazy
                    {...COMMON_PROPS}
                    value={{ value: filter[FILTER_TYPE.DEPARTMENT], id: filter[FILTER_TYPE.DEPARTMENT] }}
                    onChange={(conf) => handleChange(FILTER_TYPE.DEPARTMENT, conf?.id || "")}
                    parentStyle={{ ...COMMON_PROPS.style }}
                    placeholder="Department"
                    isClearable={!!filter[FILTER_TYPE.DEPARTMENT]}
                    isDisabled={isLoading}
                    isFilter
                />
                <DesignationsSelectLazy
                    {...COMMON_PROPS}
                    value={{ value: filter[FILTER_TYPE.DESIGNATION], id: filter[FILTER_TYPE.DESIGNATION] }}
                    onChange={(conf) => handleChange(FILTER_TYPE.DESIGNATION, conf?.id || "")}
                    parentStyle={{ ...COMMON_PROPS.style }}
                    placeholder="Designation"
                    isClearable={!!filter[FILTER_TYPE.DESIGNATION]}
                    isDisabled={isLoading}
                    isFilter
                />
                <Select
                    {...COMMON_PROPS}
                    options={filterConfig[FILTER_TYPE.NATIONALITY].options}
                    value={getValueFromOpt(FILTER_TYPE.NATIONALITY, filter[FILTER_TYPE.NATIONALITY])}
                    onChange={(conf) => handleChange(FILTER_TYPE.NATIONALITY, conf.value)}
                    placeholder="Nationality"
                    isClearable={!!filter[FILTER_TYPE.NATIONALITY]}
                    isDisabled={isLoading}
                />
                <Select
                    {...COMMON_PROPS}
                    options={filterConfig[FILTER_TYPE.PAYMENT_MODE].options}
                    value={getValueFromOpt(FILTER_TYPE.PAYMENT_MODE, filter[FILTER_TYPE.PAYMENT_MODE])}
                    onChange={(conf) => handleChange(FILTER_TYPE.PAYMENT_MODE, conf.value)}
                    placeholder="Payment Mode"
                    isClearable={!!filter[FILTER_TYPE.PAYMENT_MODE]}
                    isDisabled={isLoading}
                />
            </div>
            <FilterControl
                isClearDisabled={isClearDisabled}
                isFilterDisabled={isFilterDisabled}
                onReset={handleReset}
                onFilter={handleFilter}
                isLoading={isLoading}
            />
        </div>
    );
}

export default Filter;

Filter.propTypes = {
    onFilter: PropTypes.func,
    isLoading: PropTypes.bool
};
