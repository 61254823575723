import React from "react";
import PropTypes from "prop-types";
import Modal from "../../../common/components/extra/Modal";
import Input, { INPUT_TYPE } from "../../../common/components/extra/form/Input";
import FormSection from "../../../common/components/extra/FormSection";
import { useFetchPenalty } from "./hooks";
import { FIELDS } from "./const";
import { getCountryName, sanitizeWords } from "../../../common/utilities/helper";
import Tag from "../../../common/components/extra/Tag";
import Measures from "./Measures";

const { TITLE, COUNTRY, NOTES, DESCRIPTION, DEDUCTION_TYPE } = FIELDS;

function ViewModal({ open, onClose, onEdit, data }) {
    const [result, isLoading] = useFetchPenalty({
        id: data.id,
        cached: true
    });

    return (
        <Modal open={open} onClose={onClose}>
            <div className="view-modal">
                <FormSection header="Penalty Details" onEdit={onEdit}>
                    <Input
                        type={INPUT_TYPE.TEXT}
                        label={TITLE.label}
                        renderValue={<span className="capitalize">{result[TITLE.name]}</span>}
                        isLoading={isLoading}
                        readOnly
                    />
                    <Input
                        type={INPUT_TYPE.TEXT}
                        label={COUNTRY.label}
                        renderValue={<span className="capitalize">{getCountryName(result[COUNTRY.name], true)}</span>}
                        isLoading={isLoading}
                        readOnly
                    />
                    <Input
                        type={INPUT_TYPE.TEXT}
                        label={DEDUCTION_TYPE.label}
                        renderValue={
                            <span className="capitalize">
                                <Tag>{sanitizeWords(result[DEDUCTION_TYPE.name])}</Tag>
                            </span>
                        }
                        isLoading={isLoading}
                        readOnly
                    />
                    <Input
                        type={INPUT_TYPE.TEXT}
                        label={NOTES.label}
                        renderValue={<p style={{ marginLeft: "3rem", fontWeight: "600" }}>{result[NOTES.name]}</p>}
                        isLoading={isLoading}
                        readOnly
                    />
                    <Input
                        type={INPUT_TYPE.TEXT}
                        label={DESCRIPTION.label}
                        renderValue={<p style={{ marginLeft: "3rem", fontWeight: "600" }}>{result[DESCRIPTION.name]}</p>}
                        isLoading={isLoading}
                        readOnly
                    />
                </FormSection>
                <FormSection header="Measures" onEdit={onEdit}>
                    <Measures data={result.penalty_measures} isLoading={isLoading} readOnly />
                </FormSection>
            </div>
        </Modal>
    );
}

ViewModal.propTypes = {
    open: PropTypes.bool,
    data: PropTypes.object,
    onClose: PropTypes.func,
    onEdit: PropTypes.func
};

export default ViewModal;
