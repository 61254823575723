import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { createPortal } from "react-dom";
import Button, { BTN_TYPE } from "./Button";
import Loader from "./Loader";

function Modal({
    children,
    open,
    onChange,
    onClose,
    onBack,
    onSave,
    isForm,
    disableSave,
    styles = {},
    small,
    className,
    title,
    hasHeaderStyle,
    saveLabel = "Save",
    saveStyle = {},
    saveClassName = "",
    isLoading,
    isPortal = true,
    transparentOverlay
}) {
    const [openModal, setOpenModal] = useState(open);

    useEffect(() => {
        if (openModal !== open) {
            setOpenModal(open);
        }
    }, [open]);

    useEffect(() => {
        if (openModal !== open) {
            typeof onChange == "function" && onChange(openModal);
            if (!openModal) {
                typeof onClose == "function" && onClose();
            }
        }
    }, [openModal]);

    const handleClose = () => setOpenModal(false);

    const handleSave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        typeof onSave == "function" && onSave(e);
    };

    const renderBody = (
        <>
            <div className="tk-modal__content__body" style={styles.body}>
                {children}
            </div>
            {typeof onSave === "function" && (
                <div className="tk-modal__content__footer" style={{ ...(styles.footer || {}), ...(small ? { marginBottom: "1rem" } : {}) }}>
                    <Button onClick={handleClose} small>
                        Cancel
                    </Button>
                    <Button
                        style={saveStyle}
                        options={{ type: isForm ? "submit" : "button" }}
                        className={`${saveClassName || "primary"}`.trim()}
                        small
                        onClick={!isForm ? onSave : null}
                        disabled={disableSave}
                    >
                        {saveLabel ? saveLabel : "Save"}
                    </Button>
                </div>
            )}
        </>
    );

    const createReturn = () => {
        if (!openModal) return "";
        return (
            <div className={`tk-modal ${small ? "small" : ""} ${className || ""}`.trim()} style={styles.parent}>
                <div className="tk-modal__overlay" style={transparentOverlay ? { backgroundColor: "transparent" } : {}}>
                    <div className="tk-modal__content" style={styles.content}>
                        {isLoading && (
                            <Loader
                                overlayStyle={{
                                    borderRadius: "12px"
                                }}
                                absolute
                                centered
                                hasOverlay
                            />
                        )}
                        <div
                            className={"tk-modal__content__inner" + ((hasHeaderStyle && " has-header") || "")}
                            style={(hasHeaderStyle && { paddingTop: 0 }) || {}}
                        >
                            <div className="tk-modal__title" style={styles.title}>
                                {title}
                            </div>
                            <div className="tk-modal__controls">
                                {onBack && <Button className="tk-modal__back" type={BTN_TYPE.BACK} onClick={onBack} />}
                                <Button className="tk-modal__close" type={BTN_TYPE.CLOSE} onClick={handleClose} />
                            </div>
                            {isForm ? (
                                <form style={styles.form} onSubmit={handleSave}>
                                    {renderBody}
                                </form>
                            ) : (
                                renderBody
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    };
    if (isPortal) return createPortal(createReturn(), document.body);
    return createReturn();
}

Modal.propTypes = {
    open: PropTypes.bool,
    className: PropTypes.string,
    styles: PropTypes.shape({
        parent: PropTypes.object,
        content: PropTypes.object,
        body: PropTypes.object,
        title: PropTypes.object,
        form: PropTypes.object,
        footer: PropTypes.object
    }),
    disableSave: PropTypes.bool,
    isForm: PropTypes.bool,
    onChange: PropTypes.func,
    onSave: PropTypes.func,
    onBack: PropTypes.func,
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
    saveLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
    small: PropTypes.bool,
    hasHeaderStyle: PropTypes.bool,
    isPortal: PropTypes.bool,
    transparentOverlay: PropTypes.bool,
    onClose: PropTypes.func,
    saveStyle: PropTypes.object,
    saveClassName: PropTypes.string
};

export default Modal;
