import React, { useState } from "react";
import Employees from "../../features/company/employees/Employees";
import { selectLoading, setTableConfig, selectTableConfig } from "../../features/company/employees/slice";
import { useAppDispatch, useAppSelector } from "../../common/hooks/reduxHooks";
import { selectUser } from "../../features/common/slice";
import ButtonUpgrade from "../../common/components/extra/ButtonUpgrade";
import PageLayout from "../../common/components/layout/PageLayout";

function EmployeesPage() {
    const [openAddModal, setOpenAddModal] = useState(false);

    const dispatch = useAppDispatch();
    const loading = useAppSelector(selectLoading);
    const user = useAppSelector(selectUser);
    const config = useAppSelector(selectTableConfig);
    const companySubscription = user?.CompanySubscription?.Subscription;
    const employeeLimit = companySubscription?.employee_limit || 0;
    const limitReached = user.totalEmployees >= employeeLimit;

    const handleSearch = (value) => dispatch(setTableConfig({ search: (value && value.toLowerCase().trim()) || "" }));
    const handleAdd = () => setOpenAddModal(true);

    return (
        <PageLayout
            className="tk-employees"
            title="Manage Employees"
            isLoading={loading}
            search={{ onSearch: handleSearch, value: config.search }}
            onAdd={handleAdd}
            headExtra={
                limitReached && (
                    <div className="tk-employees__warning">
                        <span>
                            You have reached your employee limit of <strong style={{ color: "inherit" }}> {employeeLimit}</strong>. Upgrade now to add
                            more.
                        </span>
                        <ButtonUpgrade />
                    </div>
                )
            }
            styles={{
                body: {
                    height: "calc(100vh - 20.5rem)"
                }
            }}
        >
            <Employees openAddModal={openAddModal} onAddModalClose={() => setOpenAddModal(false)} limitReached={limitReached} />
        </PageLayout>
    );
}

export default EmployeesPage;
