import cloneDeep from "lodash/cloneDeep";
import { createSlice } from "@reduxjs/toolkit";

const SLICE_NAME_KEY = "settings";

const initialState = {
    profile: {},
    general: {},
    work: {},
    billing: {},
    penalties: {},
    loading: false
};

export const settingsSlice = createSlice({
    name: SLICE_NAME_KEY,
    initialState,
    reducers: {
        setProfile: (state, action) => {
            const clone = cloneDeep(state);
            const data = action.payload;
            clone.profile = data;
            return clone;
        },
        updateProfile: (state, action) => {
            const clone = cloneDeep(state);
            const data = action.payload || {};
            clone.profile = { ...state.profile, ...data };
            return clone;
        },
        setGeneral: (state, action) => {
            const clone = cloneDeep(state);
            const data = action.payload;
            clone.general = data;
            return clone;
        },
        updateGeneral: (state, action) => {
            const clone = cloneDeep(state);
            const data = action.payload || {};
            clone.general = { ...state.general, ...data };
            return clone;
        },
        setWork: (state, action) => {
            const clone = cloneDeep(state);
            const data = action.payload;
            clone.work = data;
            return clone;
        },
        updateWork: (state, action) => {
            const clone = cloneDeep(state);
            const data = action.payload || {};
            clone.work = { ...state.work, ...data };
            return clone;
        },
        setBilling: (state, action) => {
            const clone = cloneDeep(state);
            const data = action.payload;
            clone.billing = data;
            return clone;
        },
        updateBilling: (state, action) => {
            const clone = cloneDeep(state);
            const data = action.payload || {};
            clone.billing = { ...state.billing, ...data };
            return clone;
        },
        setPenalties: (state, action) => {
            const clone = cloneDeep(state);
            const data = action.payload;
            clone.penalties = data;
            return clone;
        },
        updatePenalties: (state, action) => {
            const clone = cloneDeep(state);
            const data = action.payload || {};
            clone.penalties = { ...state.penalties, ...data };
            return clone;
        },
        setLoading: (state, action) => {
            const clone = cloneDeep(state);
            const data = action.payload;
            clone.loading = !!data;
            return clone;
        },
        resetWorkSettings: (state) => ({ ...cloneDeep(state), work: {} }),
        reset: () => initialState
    }
});

export const selectProfile = (state) => state[SLICE_NAME_KEY].profile;
export const selectGeneral = (state) => state[SLICE_NAME_KEY].general;
export const selectWork = (state) => state[SLICE_NAME_KEY].work;
export const selectBilling = (state) => state[SLICE_NAME_KEY].billing;
export const selectPenalties = (state) => state[SLICE_NAME_KEY].penalties;
export const selectLoading = (state) => state[SLICE_NAME_KEY].loading;
export const {
    setProfile,
    updateProfile,
    setGeneral,
    updateGeneral,
    setWork,
    updateWork,
    setBilling,
    updateBilling,
    setPenalties,
    updatePenalties,
    setLoading,
    resetWorkSettings,
    reset
} = settingsSlice.actions;
export default settingsSlice.reducer;
