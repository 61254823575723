import React, { useEffect, useRef, useState } from "react";
import debounce from "lodash/debounce";
import PropTypes from "prop-types";
import CardPage from "../extra/CardPage";
import Input, { INPUT_TYPE } from "../extra/form/Input";
import Button, { BTN_TYPE } from "../extra/Button";
import Loader from "../extra/Loader";
import ButtonFilter from "../extra/filter/ButtonFilter";
import TableFilter from "../extra/filter/TableFilter";
import { useAppSelector } from "../../hooks/reduxHooks";
import { selectViewport } from "../../../features/common/slice";
import { selectDate } from "../../../features/company/employeesSalary/slice";

function PageLayout({
    title,
    className,
    children,
    isLoading,
    search = {},
    onAdd,
    noControls,
    headExtra,
    controlsExtra,
    rightExtra,
    hasFilter,
    filter,
    styles = {}
}) {
    const topRef = useRef(null);
    const contentRef = useRef(null);
    const bodyRef = useRef(null);

    const [openFilter, setOpenFilter] = useState(false);

    const viewport = useAppSelector(selectViewport);
    const selectedSalaryDate = useAppSelector(selectDate); // only  use if current is on salary page we need to autoadjust when this changes

    const contentRect = contentRef.current && contentRef.current.getBoundingClientRect();
    const topRect = topRef.current && topRef.current.getBoundingClientRect();

    const handleSearch = (value) => {
        typeof search.onSearch == "function" && search.onSearch(value);
    };

    const handleAdd = () => {
        typeof onAdd == "function" && onAdd();
    };

    const renderFilter = (
        <div className="flex w100 gap-05">
            {openFilter && filter}
            <div className="flex center" style={{ marginLeft: "auto" }}>
                <ButtonFilter show={openFilter} onChange={setOpenFilter} />
            </div>
        </div>
    );

    const adjustHeightAuto = () => {
        if (topRect && contentRect && bodyRef.current) {
            const OFFSET = openFilter ? 170 : 130;
            const header = document.querySelector(".tk-auth-layout__header");
            const headerRect = header.getBoundingClientRect();
            const topHeight = topRect.height + headerRect.height + OFFSET;
            bodyRef.current.style.height = `calc(100vh - ${topHeight}px)`;
        }
    };

    useEffect(() => {
        if (viewport.isMobile && openFilter) {
            setOpenFilter(false);
        }
    }, [viewport.isMobile]);

    useEffect(() => {
        adjustHeightAuto();
    }, [topRect?.height, openFilter, selectedSalaryDate]);

    useEffect(() => {
        window.addEventListener("resize", adjustHeightAuto);
        return () => {
            window.removeEventListener("resize", adjustHeightAuto);
        };
    }, []);

    return (
        <div className={`tk-page-layout ${className || ""}`.trim()} style={styles.parent || {}}>
            {isLoading && <Loader />}
            <div className="tk-page-layout__inner">
                <CardPage
                    title={title}
                    parentStyle={{ padding: 0, height: "calc(100vh - 8rem)", ...(styles.cardParent || {}) }}
                    contentStyle={{ padding: 0, flex: 1, ...(styles.content || {}) }}
                >
                    <div ref={contentRef} className="tk-page-layout__content">
                        <div ref={topRef} className="tk-page-layout__content__top">
                            {headExtra}
                            {!noControls && (
                                <div
                                    className={"tk-page-layout__control flex gap-05" + (viewport.isMobile ? " ctrl-compact" : "")}
                                    style={styles.control || {}}
                                >
                                    <div className="tk-page-layout__left">
                                        <Input
                                            type={INPUT_TYPE.SEARCH}
                                            value={search?.value || ""}
                                            onChange={debounce(handleSearch, 1000)}
                                            placeholder={search.placeholder || "Search here..."}
                                            disabled={isLoading}
                                        />
                                        {controlsExtra}
                                    </div>
                                    {(rightExtra || onAdd) && (
                                        <div className="tk-page-layout__right">
                                            {rightExtra}
                                            {handleAdd && (
                                                <Button
                                                    type={BTN_TYPE.ADD}
                                                    onClick={handleAdd}
                                                    noLabel={viewport.isMobile}
                                                    transparent={viewport.isMobile}
                                                />
                                            )}
                                        </div>
                                    )}
                                </div>
                            )}
                            {hasFilter && <TableFilter filter={renderFilter} />}
                        </div>
                        <div ref={bodyRef} className={`tk-page-layout__body ${hasFilter ? "has-filter" : ""}`.trim()} style={styles.body}>
                            {children}
                        </div>
                    </div>
                </CardPage>
            </div>
        </div>
    );
}

export default PageLayout;

PageLayout.propTypes = {
    children: PropTypes.any,
    title: PropTypes.any,
    className: PropTypes.string,
    isLoading: PropTypes.bool,
    search: PropTypes.shape({
        value: PropTypes.string,
        onSearch: PropTypes.func,
        placeholder: PropTypes.string
    }),
    onAdd: PropTypes.func,
    styles: PropTypes.shape({
        cardParent: PropTypes.object,
        parent: PropTypes.object,
        content: PropTypes.object,
        control: PropTypes.object,
        body: PropTypes.object
    }),
    noControls: PropTypes.bool,
    headExtra: PropTypes.any,
    controlsExtra: PropTypes.any,
    rightExtra: PropTypes.any,
    hasFilter: PropTypes.bool,
    filter: PropTypes.any
};
