import React from "react";
import PropTypes from "prop-types";
import Input, { INPUT_TYPE } from "../form/Input";
import { DATE_RANGE } from "../../../utilities/const";

const DATE_TYPE = INPUT_TYPE.DATE;
const COMMON_STYLE = { minWidth: "10rem", width: "12rem" };

function DatePickerRange({ styles, onChange, value, isMonthYear, isFixed }) {
    const handleChange = (name, value) => {
        typeof onChange === "function" && onChange(name, value);
    };

    const startDate = value?.from || "";
    const endDate = value?.to || "";

    return (
        <div className="tk-date-picker-range flex gap-05 center" style={styles?.parent || {}}>
            <Input
                type={DATE_TYPE}
                parentStyle={{ ...COMMON_STYLE, ...(styles?.date || {}) }}
                onChange={(date) => handleChange(DATE_RANGE.FROM, date)}
                selected={startDate}
                startDate={startDate}
                endDate={endDate}
                isMonthYear={isMonthYear}
                isFixed={isFixed}
                noFuture
                selectsStart
            />
            <span className="fade">to</span>
            <Input
                type={DATE_TYPE}
                parentStyle={{ ...COMMON_STYLE, ...(styles?.date || {}) }}
                onChange={(date) => handleChange(DATE_RANGE.TO, date)}
                selected={endDate}
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                isMonthYear={isMonthYear}
                isFixed={isFixed}
                selectsEnd
                noFuture
            />
        </div>
    );
}

export default DatePickerRange;

DatePickerRange.propTypes = {
    styles: PropTypes.shape({
        parent: PropTypes.object,
        date: PropTypes.object
    }),
    onChange: PropTypes.func,
    value: PropTypes.shape({
        from: PropTypes.any,
        to: PropTypes.any
    }),
    isMonthYear: PropTypes.bool,
    isFixed: PropTypes.bool,
    isTimePicker: PropTypes.bool
};
