import React from "react";
import PropTypes from "prop-types";
import isEqual from "lodash/isEqual";
import { createConfirmAlert } from "../../../common/utilities/helper";
import Modal from "../../../common/components/extra/Modal";
import Input, { INPUT_TYPE } from "../../../common/components/extra/form/Input";
import Select from "../../../common/components/extra/select/Select";
import { RECURRENCE } from "../../../common/utilities/const";
import Group from "../../../common/components/extra/Group";
import { useUpsertSubscription } from "./hooks";
import { FIELDS } from "./const";

const { TITLE, AMOUNT, DESCRIPTION, EMPLOYEE_LIMIT, RECURRENCE: FIELD_RECCURENCE, IS_ACTIVE } = FIELDS;

function UpdateModal({ open, onClose, onBack, onFinish, data = {} }) {
    const isCreate = !data?.id;

    const [form, setForm, isLoading, onSave] = useUpsertSubscription({
        id: data?.id,
        initial: data || {}
    });

    const isTrialAccount = !!(data && data.recurrence === RECURRENCE.TRIAL);

    const handleSave = async () => {
        createConfirmAlert({
            title: !isCreate ? "Update Subscription" : "Create Subscription",
            content: `Are you sure you want to ${!isCreate ? "update" : "create"} this subscription? This cannot be undone.`,
            onConfirm: async (close) => {
                close();
                const result = await onSave();
                if (!result?.error) {
                    await onFinish(result, isCreate);
                }
            }
        });
    };

    const handleChange = (e) => setForm({ ...form, [e.target.name]: e.target.value });
    const handleChecked = (e) => setForm({ ...form, [e.target.name]: e.target.checked });

    let recurrences = Object.values(RECURRENCE).map((r) => ({ label: r.toLocaleUpperCase(), value: r }));
    if (!data) {
        recurrences = recurrences.filter((r) => r.value !== RECURRENCE.TRIAL);
    }
    const recurrence = recurrences.find((r) => r.value.toLocaleLowerCase() === form.recurrence.toLocaleLowerCase()) || "";

    return (
        <Modal
            open={open}
            onClose={onClose}
            onBack={(!isCreate && onBack) || null}
            onSave={handleSave}
            disableSave={isEqual(form, data)}
            isLoading={isLoading}
            isForm
        >
            <div className="update-modal">
                <div className="update-modal__inner flex column gap-1">
                    <Group title="Subscription Details">
                        <Input
                            type={INPUT_TYPE.TEXT}
                            label={TITLE.label}
                            name={TITLE.name}
                            value={form[TITLE.name]}
                            onChange={handleChange}
                            required={TITLE.required}
                        />
                        <Input
                            type={INPUT_TYPE.NUMBER}
                            label={AMOUNT.label}
                            name={AMOUNT.name}
                            value={form[AMOUNT.name]}
                            required={AMOUNT.required}
                            onChange={handleChange}
                        />
                        <Input
                            type={INPUT_TYPE.NUMBER}
                            label={EMPLOYEE_LIMIT.label}
                            name={EMPLOYEE_LIMIT.name}
                            value={form[EMPLOYEE_LIMIT.name]}
                            required={EMPLOYEE_LIMIT.required}
                            onChange={handleChange}
                        />
                        <Select
                            label={FIELD_RECCURENCE.label}
                            name={FIELD_RECCURENCE.name}
                            placeholder=""
                            value={recurrence}
                            style={{ flex: "30%" }}
                            options={recurrences}
                            onChange={(d) => handleChange({ target: { name: FIELD_RECCURENCE.name, value: (d && d.value) || "" } })}
                            required
                            isOutlined
                            disabledOutline
                            isClearable={false}
                            isDisabled={isTrialAccount}
                        />
                        <Input
                            type={INPUT_TYPE.TEXTAREA}
                            label={DESCRIPTION.label}
                            name={DESCRIPTION.name}
                            value={form[DESCRIPTION.name]}
                            required={DESCRIPTION.required}
                            parentStyle={{ marginTop: "1rem" }}
                            onChange={handleChange}
                        />
                    </Group>
                    <section className="update-modal__bottom">
                        <Group title="Others">
                            <Input
                                type={INPUT_TYPE.TOGGLE}
                                label={IS_ACTIVE.label}
                                name={IS_ACTIVE.name}
                                value={form[IS_ACTIVE.name]}
                                onChange={handleChecked}
                            />
                        </Group>
                    </section>
                </div>
            </div>
        </Modal>
    );
}

UpdateModal.propTypes = {
    open: PropTypes.bool,
    data: PropTypes.object,
    onClose: PropTypes.func,
    onFinish: PropTypes.func,
    onBack: PropTypes.func
};

export default UpdateModal;
