import React from "react";
import PropTypes from "prop-types";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import CloseIcon from "@mui/icons-material/Close";
import { useSortable } from "@dnd-kit/sortable";
import { useDraggable } from "@dnd-kit/core";
import { CSS } from "@dnd-kit/utilities";
import { createConfirmAlert } from "../../../utilities/helper";

function SortableItem(props) {
    const { transform } = useDraggable({ id: props.id });

    const { attributes, listeners, isOver, isDragging, transition, setNodeRef, setActivatorNodeRef, isSorting } = useSortable({
        id: props.id,
        data: props.data,
        transition: {
            duration: 150,
            easing: "cubic-bezier(0.25, 1, 0.5, 1)"
        }
    });

    const style = {
        position: "relative",
        display: "flex",
        margin: `${props.spacing || 0}rem 0`,
        zIndex: isDragging ? 999 : 5,
        transform: CSS.Translate.toString(transform),
        transition
    };

    if (props.temp) {
        style.background = "transparent";
    }

    if (props.hasOverlay) {
        delete style.transform;
        delete style.transition;
    }

    const createClassNames = () => {
        let classNames = "sort-item ";
        if (isOver) {
            classNames += "sort-item--is-dropping ";
        }
        if (props.isActive) {
            classNames += "sort-item--is-active ";
        }
        if (props.temp) {
            classNames += "temp ";
        }
        if (props.hasError) {
            classNames += "sort-item--has-error ";
        }
        if (props.className) {
            classNames += props.className;
        }
        return classNames.trim();
    };

    return (
        <div ref={setNodeRef} data-sorting={isSorting} className={createClassNames()} style={style}>
            <div className="left" style={{ minWidth: "1.5rem" }}>
                {!props.temp && (
                    <DragIndicatorIcon
                        ref={setActivatorNodeRef}
                        className={`drag-icon primary-color hover-svg ${props.iconClassName || ""}`.trim()}
                        {...attributes}
                        {...listeners}
                    />
                )}
            </div>
            {props.onRemove && (
                <CloseIcon
                    className="pointer remove-icon danger-color hover-svg"
                    onClick={() =>
                        createConfirmAlert({
                            title: "Are you sure?",
                            content: "This action will remove the item and cannot be undone.",
                            onConfirm: async (close) => {
                                close();
                                props?.onRemove?.();
                            }
                        })
                    }
                />
            )}
            {props.children}
        </div>
    );
}

export default SortableItem;

SortableItem.propTypes = {
    id: PropTypes.string,
    data: PropTypes.object,
    children: PropTypes.any,
    className: PropTypes.string,
    style: PropTypes.object,
    spacing: PropTypes.number,
    hasOverlay: PropTypes.bool,
    iconClassName: PropTypes.string,
    isActive: PropTypes.bool,
    temp: PropTypes.bool,
    hasError: PropTypes.bool,
    onRemove: PropTypes.func
};
