import React from "react";
import CardPage from "../../common/components/extra/CardPage";
import Companies from "../../features/admin/companies/Companies";
import { VIEW_TYPES } from "../../common/utilities/const";

function CompaniesPage() {
    return (
        <div className="tk-companies tk-page-type-01">
            <div className="tk-companies__inner">
                <CardPage title="Manage companies" contentStyle={{ padding: 0, height: "calc(100vh - 12.3rem)" }}>
                    <div className="content">
                        <div className="control">
                            <div className="search"></div>
                        </div>
                        <div className="tk-companies__body">
                            <Companies view={VIEW_TYPES.TABLE} />
                        </div>
                    </div>
                </CardPage>
            </div>
        </div>
    );
}

export default CompaniesPage;
