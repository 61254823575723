import React from "react";
import PropTypes from "prop-types";
import AsyncSelectLazy from "../../../common/components/extra/AsyncSelectLazy";
import { useLazyDesignations } from "./hooks";

function DesignationsSelectLazy(props) {
    const [object, , { loadMore, search, isLoading }] = useLazyDesignations({
        initializing: props.isLoading,
        value: props?.value,
        isMulti: props?.isMulti,
        defaultValue: Array.isArray(props.value) ? props.value.map((v) => v.id) : props?.value?.id,
        isFilter: props.isFilter
    });

    const getValue = !props.isMulti
        ? props.value && Object.keys(props.value).length && object.data.find((item) => item.id === props.value.id)
        : object.data.filter((item) => props.value.map((v) => v.id).includes(item.id));

    const handleSearch = (newInput, type) => {
        const allowedActions = ["input-change"];
        if (allowedActions.includes(type.action)) {
            search(newInput);
        }
    };

    return (
        <AsyncSelectLazy
            {...props}
            filterOption={(options, newInputs) => {
                const index1 = options?.data?.title?.trim()?.toLowerCase() || "";
                const search = newInputs?.toLowerCase().trim();
                if (index1.includes(search)) {
                    return true;
                } else {
                    return false;
                }
            }}
            value={getValue}
            options={object.data}
            onLoadMore={loadMore}
            onSearch={handleSearch}
            isFetching={isLoading}
        />
    );
}

DesignationsSelectLazy.propTypes = {
    onChange: PropTypes.func,
    isOutlined: PropTypes.bool,
    label: PropTypes.string,
    noborder: PropTypes.bool,
    style: PropTypes.object,
    value: PropTypes.any,
    isMulti: PropTypes.bool,
    allowOnShift: PropTypes.bool,
    isLoading: PropTypes.bool,
    allowSelectOnShift: PropTypes.bool,
    isFilter: PropTypes.bool
};

export default DesignationsSelectLazy;
