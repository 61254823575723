import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";

function Tabs({ items = [], className, styles: tabStyles, onChange, disableNavigate, hasBackground }) {
    const styles = { parent: {}, head: {}, body: {}, table: {}, ...(tabStyles || {}) };

    const location = useLocation();
    const hash = (location.hash || "").replace("#", "");

    const [active, setActive] = useState(hash || items[0].id);

    const activeIdx = items.findIndex((item) => item.id === (hash || active));
    const item = items[activeIdx < 0 ? 0 : activeIdx];

    useEffect(() => {
        if (!disableNavigate && !hash && items.length) {
            updateUriHash(items[0].id);
        }
        if (item) {
            typeof onChange === "function" && onChange(item);
        }
    }, []);

    const updateUriHash = (newhash) => {
        if (newhash !== hash) window.location.hash = newhash;
    };

    const handleTabChange = (item) => {
        if (disableNavigate) {
            setActive(item.id);
        } else {
            updateUriHash(item.id);
        }
        typeof onChange === "function" && onChange(item);
    };

    const isActive = (id) => ((!disableNavigate ? hash == id : active == id) ? "active" : "");

    return (
        <div className={`tk-tabs ${className || ""}`.trim()} style={styles.parent}>
            <div className="tk-tabs__header" style={styles.head}>
                {items.map((item) => (
                    <div
                        key={item.id}
                        id={item.id}
                        className={`tk-tabs__item head ${isActive(item.id)}`.trim()}
                        onClick={() => handleTabChange(item)}
                    >
                        <span className="text-ellipsis">{item.label}</span>
                    </div>
                ))}
            </div>
            <div className={"tk-tabs__body" + (hasBackground ? " has-bg" : "")} style={styles.body}>
                <div className="tk-tabs__item content" style={styles.content}>
                    {item ? item.component : null}
                </div>
            </div>
        </div>
    );
}

export default Tabs;

Tabs.propTypes = {
    className: PropTypes.string,
    onChange: PropTypes.func,
    disableNavigate: PropTypes.bool,
    items: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            label: PropTypes.string,
            component: PropTypes.any
        })
    ),
    styles: PropTypes.shape({
        parent: PropTypes.object,
        head: PropTypes.object,
        body: PropTypes.object,
        content: PropTypes.object
    }),
    hasBackground: PropTypes.bool
};
