import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apiSlice } from "../../../app/apiSlice";

const BASE_PATH = "/api/company";

const END_POINTS = {
    companyLogin: { method: "post", path: "login" },
    signup: { method: "post", path: "create" },
    verifyToken: { method: "get", path: "verify-token" },
    resetCompanyPassword: { method: "put", path: "reset-password" },
    resetCompanyPasswordRequest: { method: "post", path: "reset-password-request" },
    updateCompanyProfile: { method: "put", path: "update-company" },
    changeCompanyPassword: { method: "put", path: "change-password" },
    companyDetails: { method: "get", path: "company-details" },
    verifyCompanyEmail: { method: "put", path: "verify-email" },
    sendVerifyEmailRequest: { method: "post", path: "send-verify-email-request" },
    companyUploadFiles: { method: "put", path: "upload-files" }
};

export const companyAPI = apiSlice.injectEndpoints({
    reducerPath: "companyAPI",
    baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_SERVER_URI }),
    endpoints: (builder) => {
        const temp = {};
        Object.entries(END_POINTS).forEach(([key, config]) => {
            temp[key] = builder.mutation({
                query: (queryParams = {}) => {
                    const path = `${BASE_PATH}/${config.path}`;
                    const conf = {
                        url: path,
                        method: config.method,
                        credentials: "include"
                    };
                    if (typeof queryParams.extraPath === "string" || typeof queryParams.extraPath === "number")
                        conf.url = `${path}/${queryParams.extraPath}`;
                    if (Array.isArray(queryParams.extraPaths)) conf.url = `${path}/${queryParams.extraPaths.join("/")}`;
                    if (queryParams.params) conf.params = queryParams.params;
                    if (queryParams.body) conf.body = queryParams.body;
                    if (queryParams.formData) conf.formData = true;
                    return conf;
                }
            });
        });
        return temp;
    }
});

export const {
    useCompanyLoginMutation,
    useSignupMutation,
    useVerifyTokenMutation,
    useResetCompanyPasswordMutation,
    useResetCompanyPasswordRequestMutation,
    useUpdateCompanyProfileMutation,
    useChangeCompanyPasswordMutation,
    useCompanyDetailsMutation,
    useVerifyCompanyEmailMutation,
    useSendVerifyEmailRequestMutation,
    useCompanyUploadFilesMutation
} = companyAPI;

export default companyAPI.reducer;
