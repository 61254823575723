import React from "react";
import PropTypes from "prop-types";
import { sanitizeWords } from "../../../../common/utilities/helper";

function MobileRadio(props) {
    return (
        <div className="mobile-input mobile-input--radio input-size">
            {!props?.details?.value?.length && (
                <div className="flex gap-05" style={{ alignItems: "center" }}>
                    <input type="radio" name="" id="" value="" readOnly checked />
                    <label className="italic fade">Supply values to populate this fields</label>
                </div>
            )}
            <div className="flex column items">
                {props?.details?.value?.map((conf, idx) => (
                    <div key={conf.value} className="flex gap-05" style={{ alignItems: "center" }}>
                        <input key={conf.value} type="radio" name={conf.label} id="" value={conf.value} checked={idx === 0} readOnly />
                        <label htmlFor={conf.value}>{sanitizeWords(conf.label)}</label>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default MobileRadio;

MobileRadio.propTypes = {
    details: PropTypes.object
};
