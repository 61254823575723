import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FILE_MIME_TYPES } from "../../utilities/const";
import { useAppSelector } from "../../hooks/reduxHooks";
import { selectAuth } from "../../../features/common/slice";
import { BASE_PATH_URI } from "../../../app/apiSlice";

const IMAGE_TYPES = Object.values(FILE_MIME_TYPES.IMAGE).map((m) => m.split("/").pop().toLowerCase());
const PDF_TYPE = FILE_MIME_TYPES.PDF.split("/").pop().toLowerCase();

export const FILE_FETCH_TYPE = { EMPLOYEE: 0x1, COMPANY: 0x2 };

const constructAPI = (type, paths, filename) => {
    if (!paths.length) {
        return "";
    }
    let api = `${BASE_PATH_URI}/api/`;
    if (type === FILE_FETCH_TYPE.COMPANY) {
        api += "comp_uploads";
    } else if (type === FILE_FETCH_TYPE.EMPLOYEE) {
        api += "uploads";
    } else {
        api = "";
    }
    paths.forEach((path) => (api += `/${path}`));
    return `${api}/${filename}`;
};

const FileRenderer = ({
    title,
    paths = [],
    filename,
    style,
    src,
    fetchType = FILE_FETCH_TYPE.COMPANY,
    onLoading,
    nofetch,
    noempty,
    onChange,
    emptyRender,
    ...rest
}) => {
    const [object, setObject] = useState({ isLoading: true, src: "", hasError: false });
    const auth = useAppSelector(selectAuth);

    const API_ENDPOINT = constructAPI(fetchType, paths, filename);
    const { isImage, isPDF } = determineFileType(src, filename);

    const updateObject = (newObj = {}) => setObject((prev) => ({ ...prev, ...newObj }));

    const load = async () => {
        let temp = "";
        typeof onLoading === "function" && onLoading(true);
        // we only run the fetch when we are only getting the file
        if (!paths.length || !filename || !!src) {
            typeof onLoading === "function" && onLoading(false);
            return updateObject({ hasError: true, isLoading: false });
        }
        try {
            // we use fetch instead of rtk so that we avoid caching the response for this
            const resp = await fetch(API_ENDPOINT, {
                method: "GET",
                credentials: "include",
                headers: new Headers({
                    Authorization: `Bearer ${auth.accessToken}`
                })
            });
            if (isImage) {
                resp.contentType = isImage;
            } else if (isPDF) {
                resp.contentType = isPDF;
            }
            if (resp.ok) {
                const blob = await resp.blob();
                const uri = URL.createObjectURL(blob);
                temp = uri;
                const res = { src: uri, hasError: false, isLoading: false };
                updateObject(res);
                typeof onChange === "function" && onChange(res);
            } else {
                throw new Error("Failed to fetch a file.");
            }
        } catch (error) {
            const res = { hasError: true, isLoading: false };
            updateObject(res);
            typeof onChange === "function" && onChange(res);
        } finally {
            typeof onLoading === "function" && onLoading(false);
        }
        return temp;
    };

    useEffect(() => {
        if (!nofetch) {
            load();
        } else {
            typeof onLoading === "function" && onLoading(false);
            updateObject({ hasError: true, isLoading: false });
        }
    }, []);

    useEffect(() => {
        if (object.src && filename && !nofetch) {
            load();
        }
    }, [filename]);

    useEffect(() => {
        if (object.src !== src) {
            updateObject({ src });
        }
    }, [src]);

    if (!object.src && !object.isLoading && !noempty) {
        if (emptyRender) {
            return emptyRender;
        }
        return (
            <div className="empty">
                <p className="fade">No {title} uploaded.</p>
            </div>
        );
    }
    if (isPDF && object.src) {
        return <embed style={style || {}} src={object.src} width="500" height="375" type="application/pdf" {...rest} />;
    }
    if (isImage && object.src) {
        return <img className="responsive-img" src={object.src} alt="image" width={10} height={10} {...rest} crossOrigin="anonymous" />;
    }
    if (!isPDF && !isImage && emptyRender) {
        return emptyRender;
    }
    return <></>;
};

const determineFileType = (src, filename) => {
    const isBase64 = src?.startsWith("data:");
    const imageTypes = Object.values(FILE_MIME_TYPES.IMAGE);
    const pdfType = FILE_MIME_TYPES.PDF;
    if (isBase64) {
        return { isImage: imageTypes.some((it) => src.includes(it)), isPDF: src.includes(pdfType) };
    } else {
        if (!filename) {
            return { isImage: false, isPDF: false };
        }
        const fileExt = filename.split(".").pop().toLowerCase();
        const isImage = IMAGE_TYPES.find((m) => m.split("/").pop().toLowerCase() === fileExt);
        const isPDF = PDF_TYPE === fileExt && FILE_MIME_TYPES.PDF;
        return { isImage, isPDF };
    }
};

export default FileRenderer;

FileRenderer.propTypes = {
    title: PropTypes.string,
    paths: PropTypes.array,
    filename: PropTypes.string,
    style: PropTypes.object,
    src: PropTypes.string,
    fetchType: PropTypes.oneOf(Object.values(FILE_FETCH_TYPE)),
    onLoading: PropTypes.func,
    onChange: PropTypes.func,
    nofetch: PropTypes.bool,
    noempty: PropTypes.bool,
    emptyRender: PropTypes.any
};
