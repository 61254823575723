import cloneDeep from "lodash/cloneDeep";
import { createSlice } from "@reduxjs/toolkit";

const SLICE_NAME_KEY = "companyPenalties";

export const LOAD_MORE_OFFSET = 5;
export const DEFAULT_SIZE = 15;

export const defaultConfig = {
    pageSize: DEFAULT_SIZE,
    search: "",
    sortBy: "title",
    order: "ASC",
    cursor: "",
    more: LOAD_MORE_OFFSET,
    totalCount: 0
};

const initialState = {
    data: [],
    tableConfig: defaultConfig,
    current: null,
    isSearching: false,
    loading: false
};

export const companyPenaltiesSlice = createSlice({
    name: SLICE_NAME_KEY,
    initialState,
    reducers: {
        setCPSearching: (state, action) => {
            const clone = cloneDeep(state);
            clone.isSearching = action.payload;
            return clone;
        },
        setCPData: (state, action) => {
            let clone = cloneDeep(state);
            const data = action.payload;
            Array.isArray(data) && (clone.data = action.payload || []);
            return clone;
        },
        setCPTableConfig: (state, action) => {
            let clone = cloneDeep(state);
            const tableConfig = action.payload;
            tableConfig && (clone.tableConfig = { ...state.tableConfig, ...tableConfig });
            return clone;
        },
        setCPLoading: (state, action) => {
            let clone = cloneDeep(state);
            const loading = action.payload;
            typeof loading === "boolean" && loading !== state.loading && (clone.loading = loading);
            return clone;
        },
        setCPCurrent: (state, action) => {
            let clone = cloneDeep(state);
            const current = action.payload;
            clone.current = current;
            return clone;
        },
        reset: () => initialState
    }
});

export const selectCPSearching = (state) => state[SLICE_NAME_KEY].isSearching;
export const selectCPData = (state) => state[SLICE_NAME_KEY].data;
export const selectCPTableConfig = (state) => state[SLICE_NAME_KEY].tableConfig;
export const selectCPLoading = (state) => state[SLICE_NAME_KEY].loading;
export const selectCPCurrent = (state) => state[SLICE_NAME_KEY].current;
export const { setCPSearching, setCPData, setCPTableConfig, setCPLoading, setCPCurrent, reset } = companyPenaltiesSlice.actions;
export default companyPenaltiesSlice.reducer;
