import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../common/hooks/reduxHooks";
import { selectLoading, setTableConfig, selectTableConfig } from "../../features/company/companyForms/slice";
import PageLayout from "../../common/components/layout/PageLayout";
import CompanyForms from "../../features/company/companyForms/CompanyForms";
import useNavigate from "../../common/hooks/useNavigate";
import Navigation from "../../common/classes/Navigation";

function CompanyFormsPage() {
    const [openAddModal, setOpenAddModal] = useState(false);

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const loading = useAppSelector(selectLoading);
    const config = useAppSelector(selectTableConfig);

    const handleSearch = (value) => dispatch(setTableConfig({ search: (value && value.toLowerCase().trim()) || "" }));
    const handleAdd = () => navigate(Navigation.Routes.COMPANY_FORM_CREATE.path);

    return (
        <PageLayout
            className="tk-company-forms"
            title="Manage Forms"
            isLoading={loading}
            search={{ onSearch: handleSearch, value: config.search }}
            onAdd={handleAdd}
            styles={{
                body: {
                    height: "calc(100vh - 20.5rem)"
                }
            }}
        >
            <CompanyForms openAddModal={openAddModal} onAddModalClose={() => setOpenAddModal(false)} />
        </PageLayout>
    );
}

export default CompanyFormsPage;
