import React from "react";
import PropTypes from "prop-types";
import debounce from "lodash/debounce";
import Divider from "../../../common/components/extra/Divider";
import Card from "../../../common/components/extra/Card";
import { renderNA, sanitizeWords, toProperMoneyFormat, toReadableFromDate } from "../../../common/utilities/helper";
import Input, { INPUT_TYPE } from "../../../common/components/extra/form/Input";
import TableList from "../../../common/components/extra/table/TableList";
import { useLazyCompanyPayments } from "./hooks";
import { PAYMENT_STATUS } from "../../../common/utilities/const";
import Tag from "../../../common/components/extra/Tag";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectUserSetting } from "../../common/slice";

const DESCRIPTION = `Gain insight into your payment history with ease, allowing you to track and analyze your financial transactions with precision and clarity`;

function Payments() {
    const [object, { loadMore, onSort, onSearch, isFetching }] = useLazyCompanyPayments();

    const setting = useAppSelector(selectUserSetting);

    const { data: headers } = CreateTableHeaders({
        timezone: setting.timezone
    });

    return (
        <Card className="payments-settings box-shadow-mini">
            <div className="flex column">
                <Divider title="View Payments" />
                <p className="fade small-font">{DESCRIPTION}</p>
                <div className="flex column gap-05">
                    <div className="flex" style={{ alignItems: "center" }}>
                        <Input
                            type={INPUT_TYPE.SEARCH}
                            onChange={debounce(onSearch, 500)}
                            placeholder="Search amount, reference, type and status..."
                            parentStyle={{
                                minHeight: "1rem",
                                height: "1.6rem"
                            }}
                        />
                    </div>
                    <TableList
                        headers={headers}
                        data={object.payments}
                        onSort={onSort}
                        activeSort={object.sort}
                        onLoadMore={loadMore}
                        height={35}
                        isLoadingMore={isFetching}
                        small
                    />
                </div>
            </div>
        </Card>
    );
}

export default Payments;

Payments.propTypes = {
    loading: PropTypes.bool,
    setParentLoading: PropTypes.func
};

const CreateTableHeaders = ({ timezone } = {}) => {
    const headers = {
        AMOUNT: {
            key: "amount",
            label: "Amount",
            render: (row) => <span className="text-ellipsis">{toProperMoneyFormat(row.amount, row.currency) || renderNA()}</span>
        },
        REF_NUMBER: {
            key: "ref_number",
            label: "Ref #",
            render: (row) => <span className="text-ellipsis">{row.ref_number || renderNA()}</span>
        },
        TRANS_REF_NUMBER: {
            key: "trans_ref_number",
            label: "Trans Ref #",
            render: (row) => <span className="text-ellipsis">{row.trans_ref_number || renderNA()}</span>
        },
        TYPE: {
            key: "type",
            label: "Type",
            render: (row) => <span className="text-ellipsis">{sanitizeWords(row.type) || renderNA()}</span>
        },
        STATUS: {
            key: "status",
            sortKey: "status",
            label: "Status",
            render: (row) => {
                let className = "green";
                if (row.status == PAYMENT_STATUS.CANCELED) {
                    className = "red";
                }
                if (row.status == PAYMENT_STATUS.PENDING) {
                    className = "yellow";
                }
                return (
                    <div className="flex">
                        <Tag className={className}>
                            <span className="text-ellipsis">{sanitizeWords(row.status) || renderNA()}</span>
                        </Tag>
                    </div>
                );
            }
        },
        PAID: {
            key: "paid_date",
            sortKey: "paid_date",
            label: "Paid Date",
            style: { display: "flex", justifyContent: "flex-end", paddingRight: ".5rem" },
            render: (row) => (
                <div className="flex column gap-05 overflow-hidden fade" style={{ fontWeight: "500" }}>
                    <span className="text-ellipsis">{toReadableFromDate(row.paid_date, timezone)}</span>
                </div>
            )
        }
    };
    return { data: Object.values(headers), original: headers };
};
