import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useMapsLibrary } from "@vis.gl/react-google-maps";
import { LIBRARIES, COMPLETED_DRAWING_EVENTS, DRAWING_MODE_EVENT, MAP_CONTROL_POSITION } from "./const";

function DrawingManager({ map, onChange, options, modes, onComplete, onDrawingChange }) {
    let tracklisteners = [];

    const [instance, setInstance] = useState(null);
    const drawingLib = useMapsLibrary(LIBRARIES.DRAWING);

    useEffect(() => {
        if (!drawingLib || !map || instance) return;
        const drawing = new drawingLib.DrawingManager({
            drawingControl: true,
            drawingControlOptions: {
                position: MAP_CONTROL_POSITION.LEFT_CENTER,
                drawingModes: modes
            },
            ...options
        });
        drawing.setMap(map);
        setInstance(drawing);
        typeof onChange === "function" && onChange(drawing);
    }, [drawingLib, map]);

    useEffect(() => {
        if (!instance) return;
        Object.values(COMPLETED_DRAWING_EVENTS).forEach((event) =>
            tracklisteners.push(
                instance.addListener(event, (conf) => {
                    typeof onComplete === "function" && onComplete(conf, event, instance);
                })
            )
        );
        tracklisteners.push(
            instance.addListener(DRAWING_MODE_EVENT, () => {
                typeof onDrawingChange === "function" &&
                    onDrawingChange({
                        drawingMode: instance.getDrawingMode(),
                        instance
                    });
            })
        );
        return () => {
            instance && instance.setMap(null);
            tracklisteners.length && tracklisteners.forEach((listener) => listener.remove());
        };
    }, [instance]);

    return <></>;
}

export default DrawingManager;

DrawingManager.propTypes = {
    map: PropTypes.object,
    maps: PropTypes.object,
    options: PropTypes.object,
    onChange: PropTypes.func,
    modes: PropTypes.array,
    onComplete: PropTypes.func,
    onDrawingChange: PropTypes.func
};
