import React from "react";
import PropTypes from "prop-types";
import Navigation from "../../classes/Navigation";
import Logo from "../extra/Logo";
import HeadLogo from "./HeadLogo";
import { useLocation } from "react-router-dom";
import ButtonUpgrade from "../extra/ButtonUpgrade";
import Banner, { BANNER_TYPE } from "../extra/Banner";
import ButtonRenew from "../extra/ButtonRenew";
import { useGetSubscriptionTimeRemaining } from "../../../features/company/auth/hooks";

function Header({ user, isSuperAdmin, hasLogo }) {
    const location = useLocation();
    const result = useGetSubscriptionTimeRemaining();

    const isSettingsSetup = location.pathname == Navigation.Routes.SETTINGS_SETUP.path;
    const isTrial = user.is_trial;

    return (
        <div className="tk-header">
            <Logo styles={{ parent: { marginBottom: "-.4rem" } }} size={4} hide={!hasLogo} svg />
            <Banner type={BANNER_TYPE.WARNING} show={result.showWarning}>
                <div className="flex w100" style={{ justifyContent: "space-between", alignItems: "center" }}>
                    {isTrial ? (
                        <>
                            <span>Your trial account is expiring in {result.text}. Upgrade now to ensure uninterrupted service.</span>
                            <ButtonUpgrade />
                        </>
                    ) : (
                        <>
                            <span>Your subscription is expiring in {result.text}. Renew now to ensure uninterrupted service.</span>
                            <ButtonRenew primary />
                        </>
                    )}
                </div>
            </Banner>
            <div className="tk-header__right">
                <div className="flex column" style={{ gap: ".2rem" }}>
                    <span className="attention" style={{ textAlign: "right", fontWeight: "bold", whiteSpace: "nowrap" }}>
                        {user.name}
                    </span>
                    {!isSettingsSetup && !isTrial && <div className="tk-header__upgrade">{!isSuperAdmin && <ButtonUpgrade />}</div>}
                </div>
                <HeadLogo user={user} />
            </div>
        </div>
    );
}

Header.propTypes = {
    user: PropTypes.object,
    isSuperAdmin: PropTypes.bool,
    hasLogo: PropTypes.bool
};

export default Header;
