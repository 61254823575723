export const BASE_CLASS = "tk-employees";

export const UNIQUE_KEY = "id";

export const VIEW_MODAL_TYPE = {
    PROJECT: 0x1,
    GEOZONE: 0x2,
    CONTRACT_COPY: 0x3,
    SALARY: 0x4,
    JOB_DESCRIPTION: 0x5,
    PHOTO: 0x6,
    ID_COPY: 0x7,
    VISA_COPY: 0x8,
    PASSPORT_COPY: 0x9
};

export const STATUS = {
    CUSTOM: {
        key: "custom",
        color: "yellow",
        label: "CUSTOM"
    },
    MIXED: {
        key: "modified",
        color: "brown",
        label: "MIXED"
    },
    ORIGINAL: {
        key: "original",
        color: "",
        label: "ORIGINAL"
    }
};

export const PASSWORD_KEYS_TO_USE = {
    RESIDENCE_ID: {
        value: "residenceID",
        label: "Residence ID"
    },
    PASSPORT_ID: {
        value: "passportID",
        label: "Password ID"
    },
    VISA_ID: {
        value: "visaID",
        label: "Visa ID"
    },
    MOBILE_NUMBER: {
        value: "mobile_number",
        label: "Mobile Number"
    }
};

export const ASSIGN_TYPE = {
    SITE: 0x1,
    WORK_SHIFT: 0x2,
    DEFAULT: 0x3
};
