import { ASSIGN_TYPE } from "./const";

export const customSortKeys = (a, b, priorityKeys = []) => {
    // Define the order of categories
    const order = ["priority", "ID", "contact", "personal", "work", "bank", "other"];

    // Function to get category of a key
    function getCategory(key) {
        if (priorityKeys.includes(key)) return "priority";
        if (key.endsWith("ID")) return "ID";
        if (key.includes("mobile_number") || key.includes("email_address")) return "contact";
        if (key.startsWith("employee.")) return "personal";
        if (key.startsWith("contract.")) return "work";
        if (key.startsWith("bank.")) return "bank";
        return "other";
    }

    // Get category of each key
    const categoryA = getCategory(a);
    const categoryB = getCategory(b);

    // Compare categories
    const indexA = order.indexOf(categoryA);
    const indexB = order.indexOf(categoryB);

    if (indexA !== indexB) {
        return indexA - indexB;
    }

    // If both keys belong to the same category, sort them alphabetically
    return a.localeCompare(b);
};

export const createDefaultCheckId = (arr = [], data, type) => {
    switch (type) {
        default:
        case ASSIGN_TYPE.DEFAULT: {
            return arr.filter((emp) => data.includes(emp.id)).map((emp) => emp.id);
        }
        case ASSIGN_TYPE.WORK_SHIFT: {
            return arr.filter((emp) => emp.work_shift_id === data.id).map((emp) => emp.id);
        }
        case ASSIGN_TYPE.SITE: {
            return arr.filter((emp) => emp.CompanySites.map((cs) => cs.id).includes(data.id)).map((emp) => emp.id);
        }
    }
};
