export const SIGNUP_FLOW = {
    SIGNUP: 0x1,
    VERIFICATION: 0x2
};

export const UNVERIFIED_TOKEN_TYPE = {
    INVALID: 0x1,
    EXPIRED: 0x2,
    VERIFED: 0x3,
    DEFAULT: 0x4
};
