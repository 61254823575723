import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apiSlice } from "../../../app/apiSlice";

const BASE_PATH = "/api/admin";

const END_POINTS = {
    getAllCompanies: { method: "get", path: "load-all-companies" },
    getCompanyDetailsByAdmin: { method: "get", path: "company-details" },
    deleteCompany: { method: "delete", path: "delete-company" },
    updateCompany: { method: "put", path: "update-company" },
    adminCompanyUploadFiles: { method: "put", path: "company-upload-files" }
};

export const companiesAPI = apiSlice.injectEndpoints({
    reducerPath: "companiesAPI",
    baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_SERVER_URI }),
    endpoints: (builder) => {
        const temp = {};
        Object.entries(END_POINTS).forEach(([key, config]) => {
            temp[key] = builder.mutation({
                query: (queryParams = {}) => {
                    const path = `${BASE_PATH}/${config.path}`;
                    const conf = {
                        url: path,
                        method: config.method,
                        credentials: "include"
                    };
                    if (typeof queryParams.extraPath === "string" || typeof queryParams.extraPath === "number")
                        conf.url = `${path}/${queryParams.extraPath}`;
                    if (Array.isArray(queryParams.extraPaths)) conf.url = `${path}/${queryParams.extraPaths.join("/")}`;
                    if (queryParams.params) conf.params = queryParams.params;
                    if (queryParams.body) conf.body = queryParams.body;
                    return conf;
                }
            });
        });
        return temp;
    }
});

export const {
    useGetAllCompaniesMutation,
    useGetCompanyDetailsByAdminMutation,
    useDeleteCompanyMutation,
    useUpdateCompanyMutation,
    useAdminCompanyUploadFilesMutation
} = companiesAPI;

export default companiesAPI.reducer;
