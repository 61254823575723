import "react-toastify/dist/ReactToastify.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import "react-loading-skeleton/dist/skeleton.css";
import "react-datepicker/dist/react-datepicker.css";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "../stylesheets/global.scss";

import React from "react";
import { ToastContainer } from "react-toastify";
import { Provider } from "react-redux";
import { APIProvider } from "@vis.gl/react-google-maps";
import Router from "./Router";
import { store } from "./store";

const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

function App() {
    return (
        <Provider store={store}>
            <APIProvider apiKey={GOOGLE_API_KEY}>
                <Router />
            </APIProvider>
            <ToastContainer limit={2} autoClose={3000} pauseOnHover newestOnTop />
        </Provider>
    );
}

export default App;
