import { createSlice } from "@reduxjs/toolkit";
import cloneDeep from "lodash/cloneDeep";
import { PAGE_OPTIONS } from "../../../common/components/extra/table/const";

const SLICE_NAME_KEY = "companies";

const defaultConfig = {
    page: 1,
    pageSize: PAGE_OPTIONS[0],
    search: "",
    sortBy: "createdAt",
    order: "DESC",
    totalPage: 0
};

const initialState = {
    data: [],
    tableConfig: defaultConfig,
    loading: false,
    isSearching: false,
    current: null
};

export const companiesSlice = createSlice({
    name: SLICE_NAME_KEY,
    initialState,
    reducers: {
        setSearching: (state, action) => {
            const clone = cloneDeep(state);
            clone.isSearching = action.payload;
            return clone;
        },
        setClearCache: (state) => {
            let newstate = cloneDeep(state);
            newstate.data = [];
            newstate.current = null;
            newstate.loading = false;
            newstate.tableConfig = defaultConfig;
            return newstate;
        },
        setCurrent: (state, action) => {
            let newstate = cloneDeep(state);
            newstate.current = action.payload;
            return newstate;
        },
        setData: (state, action) => {
            let newstate = cloneDeep(state);
            newstate.data = action.payload || [];
            return newstate;
        },
        updateData: (state, action) => {
            let newstate = cloneDeep(state);
            const payload = action.payload || {};
            const index = payload?.id && newstate.data.findIndex((item) => item.id == payload.id);
            if (!payload?.id) {
                return newstate;
            }
            if (typeof index != "number" || index < 0) {
                return newstate;
            }
            newstate.data[index] = { ...newstate.data[index], ...payload };
            return newstate;
        },
        setTableConfig: (state, action) => {
            let newstate = cloneDeep(state);
            newstate.tableConfig = { ...state.tableConfig, ...(action.payload || {}) };
            return newstate;
        },
        setLoading: (state, action) => {
            let newstate = cloneDeep(state);
            newstate.loading = action.payload;
            return newstate;
        },
        setState: (state, action) => {
            let newstate = cloneDeep(state);
            newstate.tableConfig = { ...state.tableConfig, ...(action.payload.tableConfig || {}) };
            action.payload.data && (newstate.data = action.payload.data || []);
            typeof action.loading === "boolean" && (state.loading = action.payload.loading);
            return newstate;
        },
        reset: () => initialState
    }
});

export const selectSearching = (state) => state[SLICE_NAME_KEY].isSearching;
export const selectState = (state) => state[SLICE_NAME_KEY];
export const selectLoading = (state) => state[SLICE_NAME_KEY].loading;
export const selectData = (state) => state[SLICE_NAME_KEY].data;
export const selectTableConfig = (state) => state[SLICE_NAME_KEY].tableConfig;
export const selectCurrent = (state) => state[SLICE_NAME_KEY].current;
export const { updateData, setSearching, setClearCache, setCurrent, setData, setTableConfig, setLoading, setError, setState, reset } =
    companiesSlice.actions;
export default companiesSlice.reducer;
