import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import FileUploader from "../../../common/components/extra/fileUploader/FileUploader";
import { EMPLOYEE_FILES, FILE_MIME_TYPES } from "../../../common/utilities/const";
import { bytesToReadable, getPreviousValue, megabytesToBytes } from "../../../common/utilities/helper";
import { ERROR_TYPE, FILE_STATES } from "../../../common/components/extra/fileUploader/const";
import Tag from "../../../common/components/extra/Tag";
import { BASE_PATH_URI } from "../../../app/apiSlice";

const UPLOAD_ENDPOINT = BASE_PATH_URI + "/api/company/employee/bulk-steps/upload-req-files";
const FILE_SIZE_LIMIT = 3; // 3MB

function BulkUploadRequiredFiles({ step, updateStep, token, employees, onSuccess, active, onRemoveUploads }) {
    const uploadRef = useRef(null);
    const [error, setError] = useState(null);
    const [trackedBatch, setTrackedBatch] = useState(null);

    const uploadRefBtn = uploadRef?.current?.processUploadsRef?.current;

    useEffect(() => {
        if (active && step && step.isAttemptingNext) {
            if (trackedBatch) {
                uploadRefBtn && uploadRefBtn.click();
            }
        }
    }, [active && step && step.isAttemptingNext]);

    useEffect(() => {
        if (!active) {
            setError(null);
        }
    }, [active]);

    const uploadConfig = {
        config: { method: "POST", params: { isTemp: true } }, // we need to upload the files on the temp folder first
        destination: {
            url: UPLOAD_ENDPOINT,
            headers: { authorization: `Bearer ${token}` }
        }
    };

    const handleUploadAdd = (batch) => {
        let error = "";
        let folderErrors = {};
        const notfoundEmployees = [];
        const reqdFiles = Object.values(EMPLOYEE_FILES).filter((emp) => emp.required);
        const files = batch.items.map((it) => ({
            ...it,
            file: it.file,
            hdcFullPath: it.file.hdcFullPath,
            name: it.file.name.split("/").pop(),
            residenceId: getPreviousValue(it.file.name)
        }));
        // must have valid folder structure
        files.forEach((item) => {
            const path = item.hdcFullPath;
            const splitpath = path ? path.split("/").map(Boolean) : [];
            if (splitpath.length < 3) {
                setError({
                    type: ERROR_TYPE.CUSTOM,
                    code: "custom",
                    message: `Invalid folder structure:[${path}]. Ex. {parentFolder}/{residenceId}/{required_files.(pdf/jpg/jpeg/png)}`
                });
                updateStep({ error: true });
                return false;
            }
        });
        // if no employees that means something weird happen and this shouldnt go to this step if employees is null so we throw error instead incase this happens
        if (!employees) {
            error = "Something went wrong. Please go back to the previous step.";
        }

        employees.forEach((residenceId) => {
            // Filter files related to the current employee residenceId
            const empFiles = files.filter((it) => it.hdcFullPath.includes(`/${residenceId}/`));

            if (empFiles.length) {
                const incompleteFiles = [];
                const invalidFileTypes = [];
                const invalidFileSize = [];

                // Iterate over required files
                reqdFiles.forEach((f) => {
                    // Check if any of the employee files match the required file
                    const matchingFiles = empFiles.filter((emp) => emp.hdcFullPath.toLowerCase().includes(f.key.toLowerCase()));
                    if (matchingFiles.length === 0) {
                        incompleteFiles.push(f);
                    } else {
                        const isValidFileType = matchingFiles.some((emp) => f.accepts.includes(emp.file.type));
                        const isValidFileSize = matchingFiles.every((emp) => emp.file.size <= megabytesToBytes(FILE_SIZE_LIMIT));
                        if (!isValidFileType) invalidFileTypes.push(f);
                        if (!isValidFileSize) invalidFileSize.push(f);
                    }
                });

                if (incompleteFiles.length || invalidFileTypes.length || invalidFileSize.length) {
                    folderErrors = { ...folderErrors, [residenceId]: {} };
                    if (incompleteFiles.length) folderErrors[residenceId]["hasIncompleteFiles"] = true;
                    if (invalidFileTypes.length) folderErrors[residenceId]["hasInvalidFileTypes"] = true;
                    if (invalidFileSize.length) folderErrors[residenceId]["hasInvalidFileSize"] = true;
                }
            } else {
                // Employee not found in the folder structure
                notfoundEmployees.push(residenceId);
            }
        });
        // throw the error when the not found employees has len.
        if (notfoundEmployees.length) {
            error = (
                <div className="bu-custom-error">
                    Required employees not found with the following residence ID:
                    <ol style={{ paddingLeft: "3rem" }}>
                        {notfoundEmployees.map((empresid, idx) => (
                            <li key={idx} className="flex gap-05 has-list-style" style={{ alignItems: "center" }}>
                                <span>{empresid}</span>
                            </li>
                        ))}
                    </ol>
                </div>
            );
        }
        // show the employees that has invalid files without pointing the specific wrong file instead point them to the instructions.
        if (Object.keys(folderErrors).length) {
            error = (
                <div className="bu-custom-error">
                    The following employee folders doesnt follow the required format. Please follow the instructions above.
                    <ol style={{ gap: ".2rem" }}>
                        {Object.entries(folderErrors).map(([key, value], idx) => (
                            <li key={idx} className="flex gap-05" style={{ alignItems: "center" }}>
                                <span>{key}</span>
                                <span>-</span>
                                <span className="flex gap-05">
                                    {value.hasIncompleteFiles && <Tag className="red">Incomplete Files</Tag>}
                                    {value.hasInvalidFileTypes && <Tag className="red">Invalid File Type</Tag>}
                                    {value.hasInvalidFileSize && <Tag className="red">Invalid File Size</Tag>}
                                </span>
                            </li>
                        ))}
                    </ol>
                </div>
            );
        }

        if (error) {
            setError({
                type: ERROR_TYPE.CUSTOM,
                code: "custom",
                message: error
            });
            updateStep({ error: true, canNext: false });
            return false;
        }

        batch.items = batch.items.filter((b) => employees.some((e) => b.file.hdcFullPath.includes(e)));

        setError(null);
        updateStep({ error: false, canNext: true });
        setTrackedBatch(batch);
        return batch;
    };

    const handleUploadComplete = (err, batch) => {
        setError(null);
        updateStep({ error: false });
        const item = (Array.isArray(batch.items) && batch.items.length > 0 && batch.items[0]) || {};
        const response = item?.uploadResponse?.data || {};
        const message = response.message;
        const errorResponse = response.status == 0;
        // throw an error incase if the response is an error
        if (err && errorResponse) {
            const isError = item.state === FILE_STATES.ERROR;
            if (isError) {
                setError({
                    type: ERROR_TYPE.CUSTOM,
                    code: "custom",
                    message: (
                        <ul>
                            <li
                                key={item.id}
                                style={{
                                    display: "grid",
                                    gridTemplateColumns: "auto 1fr",
                                    columnGap: ".5rem"
                                }}
                            >
                                <span style={{ fontWeight: 600 }}>File:</span>
                                <span>{item.file.name}</span>
                                <span style={{ fontWeight: 600 }}>Error:</span>
                                <span>{message || "Upload failed."} </span>
                            </li>
                        </ul>
                    )
                });
                updateStep({ error: true });
                return;
            }
        }
        typeof onSuccess === "function" &&
            onSuccess({ totalFileSize: bytesToReadable(batch.items.map((it) => it.file.size).reduce((prev, curr) => prev + curr, 0)) });
        // reset track batched since it is completed
        setTrackedBatch(null);
    };

    const handleItemStart = (item) => {
        // make sure to only add the item folder for the said employee to prevent from sending all files to the server.
        return employees.some((emp) => item.file.hdcFullPath.includes(`/${emp}/`));
    };

    const handleError = (error) => {
        // make sure to sync the stepwizard error with the file uploader
        setError(error);
        updateStep({ error: true });
    };

    const handleRemove = () => {
        updateStep({ canNext: false, cached: false });
        typeof onRemoveUploads === "function" && onRemoveUploads();
    };

    return (
        <div className="tk-bulk-upload__reqfile" style={{ display: active ? "flex" : "none" }}>
            <FileUploader
                ref={uploadRef}
                accepts={[FILE_MIME_TYPES.FOLDER]}
                onUploadComplete={handleUploadComplete}
                onAdd={handleUploadAdd}
                onItemStart={handleItemStart}
                error={error}
                onError={handleError}
                // we dont need the internal file type and size check because we already created our custom error handler for it.
                noFileTypeCheck={true}
                noFileSizeCheck={true}
                style={{ maxHeight: "calc(100vh - 23rem)" }}
                instruction={
                    <div className="instructions">
                        <ol>
                            <li>{"Must follow the required folder structure: “employees/{residence_id}/photo.png”"}</li>
                            <li>Each File should not exceed a {FILE_SIZE_LIMIT}MB file size.</li>
                            <li>
                                Each employee must contain the required files that has the correct filename:
                                <ul>
                                    <li>photo (.png,.jpg.jpeg) (required)</li>
                                    <li>residence (.png, .jpg, .jpeg, .pdf) (required)</li>
                                    <li>passport (.png, .jpg, .jpeg, .pdf) (required)</li>
                                    <li>contract (.png, .jpg, .jpeg, .pdf) (required)</li>
                                    <li>visa (.png, .jpg, .jpeg, .pdf) (optional)</li>
                                </ul>
                            </li>
                        </ol>
                    </div>
                }
                onRemove={handleRemove}
                {...uploadConfig}
            />
        </div>
    );
}

BulkUploadRequiredFiles.propTypes = {
    step: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        stepNumber: PropTypes.number,
        name: PropTypes.string,
        error: PropTypes.bool,
        isDone: PropTypes.bool,
        isAttemptingNext: PropTypes.bool,
        canNext: PropTypes.bool,
        optional: PropTypes.bool,
        loading: PropTypes.oneOfType([PropTypes.number, PropTypes.bool])
    }),
    employees: PropTypes.arrayOf(PropTypes.string),
    token: PropTypes.string,
    onSuccess: PropTypes.func,
    updateStep: PropTypes.func,
    onRemoveUploads: PropTypes.func,
    active: PropTypes.bool,
    destroyOnInactive: PropTypes.bool,
    currentData: PropTypes.any
};

export default BulkUploadRequiredFiles;
