import { apiSlice } from "../../../app/apiSlice";

const BASE_PATH = "/api/company/holiday";

const END_POINTS = {
    deleteCompanyHolidays: { method: "delete", path: "delete" },
    loadAllCompanyHolidays: { method: "post", path: "load-all" },
    loadAllLazyCompanyHolidays: { method: "post", path: "load-all-lazy" },
    getCompanyHolidays: { method: "get", path: "get-details" },
    createCompanyHolidays: { method: "post", path: "create" },
    updateCompanyHolidays: { method: "put", path: "update" }
};

export const companyHolidaysAPI = apiSlice.injectEndpoints({
    reducerPath: "companyHolidaysAPI",
    endpoints: (builder) => {
        const temp = {};
        Object.entries(END_POINTS).forEach(([key, config]) => {
            temp[key] = builder.mutation({
                query: (queryParams = {}) => {
                    const path = `${BASE_PATH}/${config.path}`;
                    const conf = {
                        url: path,
                        method: config.method,
                        credentials: "include"
                    };
                    if (typeof queryParams.extraPath === "string" || typeof queryParams.extraPath === "number")
                        conf.url = `${path}/${queryParams.extraPath}`;
                    if (Array.isArray(queryParams.extraPaths)) conf.url = `${path}/${queryParams.extraPaths.join("/")}`;
                    if (queryParams.params) conf.params = queryParams.params;
                    if (queryParams.body) conf.body = queryParams.body;
                    return conf;
                }
            });
        });
        return temp;
    }
});

export const {
    useApproveCompanyHolidaysMutation,
    useDeleteCompanyHolidaysMutation,
    useGetCompanyHolidaysMutation,
    useLoadAllCompanyHolidaysMutation,
    useLoadAllLazyCompanyHolidaysMutation,
    useCreateCompanyHolidaysMutation,
    useUpdateCompanyHolidaysMutation
} = companyHolidaysAPI;

export default companyHolidaysAPI.reducer;
