import React from "react";
import PropTypes from "prop-types";
import { DragOverlay } from "@dnd-kit/core";
import { sanitizeWords } from "../../../common/utilities/helper";
import Droppable from "../../../common/components/extra/DND/Droppable";
import SortableItem from "../../../common/components/extra/DND/SortableItem";
import DroppedItem from "./DroppedItem";
import Field from "./Field";
import MobileInputsPreview from "./MobileInputsPreview";
import { FORM_FIELD_TYPE } from "../../../common/utilities/const";

function MobileView({ activeId, data, items = [], onRemove, onSelect, selected, isLoading, isDisabled, loadingMessage }) {
    const isOld = activeId && items.some((item) => item.id === activeId);
    const inlineTypes = [FORM_FIELD_TYPE.TOGGLE];

    return (
        <div className="form-mobile-view">
            <Droppable
                className="form-mobile-view__box"
                id="main-drop-zone"
                data={data}
                isDisabled={isDisabled}
                isLoading={isLoading}
                loadingMessage={loadingMessage}
            >
                {(!isOld || !items.length) && (
                    <DragOverlay dropAnimation={null}>
                        {activeId && data && (
                            <Field type={data.type} normal>
                                <span className="small-font bold">{sanitizeWords(data.name)}</span>
                            </Field>
                        )}
                    </DragOverlay>
                )}
                {!!items.length &&
                    items.map((it) => (
                        <SortableItem
                            key={it.id}
                            id={it.id}
                            className="form-mobile-view__sortable-box"
                            spacing={0.5}
                            isActive={(selected && selected.id == it.id) || false}
                            onRemove={() => onRemove(it)}
                            hasError={!!it.error}
                        >
                            <DroppedItem
                                data={it.data}
                                onSelect={() => onSelect(selected && selected?.id == it?.id ? null : it)}
                                inline={inlineTypes.includes(it.data.type)}
                                error={it.error}
                            >
                                <MobileInputsPreview type={it.data.type} details={it.data.validation} />
                            </DroppedItem>
                        </SortableItem>
                    ))}
                <SortableItem
                    id="xxx"
                    className="form-mobile-view__sortable-box"
                    spacing={1}
                    isActive={(selected && selected.id == "xxx") || false}
                    temp
                >
                    <div className="dropped-item">
                        <div className="dropped-item__header text-ellipsis"></div>
                    </div>
                </SortableItem>
            </Droppable>
        </div>
    );
}

export default MobileView;

MobileView.propTypes = {
    activeId: PropTypes.string,
    data: PropTypes.object,
    selected: PropTypes.object,
    items: PropTypes.array,
    onRemove: PropTypes.func,
    onSelect: PropTypes.func,
    isLoading: PropTypes.bool,
    isDisabled: PropTypes.bool,
    loadingMessage: PropTypes.string
};
